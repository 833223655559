import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RoutingModule } from './routing/routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { UIModule } from './ui/ui.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RemoveFocusDirective } from './ui/directives/remove-focus.directive';
import { TableFilterPipe } from './shared/services/table-filter.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * Ensemble des modules d'angular matérial qui seront partagés avec
 * d'autres modules.
 */
@NgModule({
  imports: [MatRadioModule, MatTooltipModule, MatDatepickerModule, MatNativeDateModule, MatButtonModule, MatCheckboxModule, MatInputModule, MatAutocompleteModule,
    MatProgressSpinnerModule, MatDialogModule, MatSelectModule,
    FormsModule, ReactiveFormsModule, MatMenuModule,
    TranslateModule, RoutingModule, BrowserModule, HttpClientModule,
    UIModule, NzTableModule, BrowserAnimationsModule,
    MatBadgeModule, MatSelectModule, MatFormFieldModule, MatMenuModule, MatIconModule],
  exports: [MatRadioModule, MatTooltipModule, MatBadgeModule, MatDatepickerModule, MatMenuModule, RemoveFocusDirective, MatButtonModule, MatCheckboxModule,
    TableFilterPipe, MatInputModule,
    MatProgressSpinnerModule, MatSelectModule, MatDialogModule, FormsModule, ReactiveFormsModule,
    TranslateModule, MatAutocompleteModule, BrowserModule, RoutingModule, HttpClientModule,
    UIModule, NzTableModule, BrowserAnimationsModule, MatMenuModule, MatIconModule],
  declarations: [RemoveFocusDirective, TableFilterPipe]
})
export class MaterialModule {
}
