import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { fadeIn } from '../../../../animations/form-error';
import { Account } from 'src/app/model/account.model';
import { CUser, ICUser } from 'src/app/model/c-user.model';
import { IMobileAccount } from 'src/app/model/mobile-account.model';
import { ITransaction, Transaction } from 'src/app/model/transaction.model';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { FormService } from 'src/app/shared/services/form.service';
import { MobileMoneyService } from '../mobile-money.service';
import { Content } from '../../../../ui/interfaces/modal';
import { TIME_MEDIUM_REQUEST } from 'src/app/shared/constant/request.contant';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';

@Component({
  selector: 'app-depot',
  templateUrl: './depot.component.html',
  styleUrls: ['./depot.component.css'],
  animations: [fadeIn]
})
export class DepotComponent implements OnInit {
  successTransfert = false;
  errorTransfert = false;
  confirm: boolean;
  isSaving: boolean;
  loadingMessagesForm: boolean;

  accountNumber: string;
  userLogin: string;
  ReponseTransfert: any;

  account: Account | null = null;
  mAccounts: IMobileAccount[];
  currentCUser: CUser;
  authSubscription: Subscription;

  MobileMoneyDepot: UntypedFormGroup;


  itemsDepotMobileMoney = [];

  constructor(
    private fb: UntypedFormBuilder,
    private loginService: LoginServiceService,
    private mobileMoneyService: MobileMoneyService,
    private accountService: AccountService,
    private mAccountService: AccountUserService,
    private cUserService: CUserService,
    private formService: FormService,
    private modal: TCModalService
  ) {
    this.loadingMessagesForm = false;

  }

  ngOnInit(): void {
    this.confirm = false;
    this.isSaving = false;
    this.authSubscription = this.accountService.getAuthenticationState().subscribe(account => (this.account = account));
    this.accountService.identity().subscribe(account => {
      this.account = account;
      this.userLogin = account.login;
      this.mAccountService
        .findAllAccountsByCUserLogin(account.login)
        .pipe(
          filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
          map((res: HttpResponse<IMobileAccount[]>) => res.body)
        )
        .subscribe((res: IMobileAccount[]) => {
          this.mAccounts = res.filter(value => value.label === 'Main Account');
          this.accountNumber = this.mAccounts[0].numberAccount;
          console.log('account Number #######', this.accountNumber);
        });
      this.cUserService
        .findOneByUserLogin(account.login)
        .pipe(
          filter((res: HttpResponse<ICUser>) => res.ok),
          map((res: HttpResponse<ICUser>) => res.body)
        )
        .subscribe((res: ICUser) => {
          this.currentCUser = res;
        });
    });
    this.MobileMoneyDepot = this.fb.group({
      numberAccount: [''],
      serviceItemsId: ['', [Validators.required]],
      receiverNumTel: ['', [Validators.required, Validators.pattern('^([0-9]{9})$')]],
      sentAmount: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    this.itemsDepotMobileMoney = [
      {
        fs: 'Orange',
        itemName: 'ORANGE MONEY',
        serviceItemsId: 5
      },
      {
        fs: 'Free',
        itemName: 'FREE MONEY',
        serviceItemsId: 17
      },
      {
        fs: 'Expresso',
        itemName: 'E-MONEY',
        serviceItemsId: 14
      }
    ];
  }

  private createFromFormMobileMoneyDepot(): ITransaction {
    return {
      ...new Transaction(),
      serviceItemId: this.MobileMoneyDepot.get(['serviceItemsId']).value,
      receiverNumTel: this.MobileMoneyDepot.get(['receiverNumTel']).value,
      sentAmount: this.MobileMoneyDepot.get(['sentAmount']).value,
      numberAccount: this.accountNumber
    };
  }

  reset() {
    this.MobileMoneyDepot.reset();
  }

  close() {

  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  /**
   * Permet d'effectuer un depot OM.
   * @param forModal
   * @param forfooter
   * @constructor
   */
  onSubmitDepot(forModal: any, forfooter?: any) {
    this.isSaving = true;
    this.errorTransfert = false;
    this.loadingMessagesForm = true;
    const mobileMoney = this.createFromFormMobileMoneyDepot();

    console.log('mobileMoney before depot: ', mobileMoney);

    const data = new FormData();
    data.append('serviceItemId', '' + mobileMoney.serviceItemId);
    data.append('receiverNumTel', mobileMoney.receiverNumTel);
    data.append('sentAmount', '' +mobileMoney.sentAmount);
    data.append('numberAccount', mobileMoney.numberAccount);

    this.loginService
      .loginProcess({
        username: this.userLogin,
        password: this.MobileMoneyDepot.get('password').value
      })
      .subscribe(
        () => {
          this.mobileMoneyService
            .mobileMoney(data)
            .pipe(
              filter((res: HttpResponse<ITransaction>) => res.ok),
              map((res: HttpResponse<ITransaction>) => res.body)
            )
            .subscribe(
              res => {
                console.log('responseeeeeeeeee###', res);
                this.successTransfert = true;
                this.isSaving = false;
                this.manageMessageWhenSucceed(res);
                this.showDetailsDepotOM(forModal,
                  'Details Transaction', null,
                  {
                    closeButton: true,
                    overlayClose: false
                  }); //

                console.log('succefullll depot', res);
              },
              error => {
                this.manageMessageWhenError();
                this.responseFromServer();
                console.log('failedddd depot');
                console.error(error);
              }
            );
        },
        error => {
          this.responseFromServer();
          this.isSaving = false;
          console.log('passworddd incorrecttttttttttttt');
          console.error(error);
        }
      );
  }

  showDetailsDepotOM(modal, title: string, footer, options) {
    console.log('## showDetailsEnvoieCode');
    console.log('## modal: ', modal);
    console.log('## title: ', title);
    setTimeout(() => {
      this.openModal(modal, title, footer, options, 'zindex-result');
    }, TIME_MEDIUM_REQUEST);
  }

  manageMessageWhenError() {
    this.successTransfert = false;
    this.loadingMessagesForm = false;
    this.errorTransfert = true;
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenSucceed(response) {
    this.errorTransfert = false;
    this.successTransfert = true;
    this.loadingMessagesForm = false;
    this.ReponseTransfert = response;
    this.MobileMoneyDepot.reset();
  }


  openModal<T>(body: Content<T>, header: Content<T> = null,
               footer: Content<T> = null, options: any = null,
               theClass?: string) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options,
      theClass: theClass
    });
  }


  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.MobileMoneyDepot);
  }

}
