export enum ValidatorDefaultType {
  required = 'required',
  minLength = 'minLength',
  maxLength = 'maxLength',
  pattern = 'pattern',
  email = 'email'
}

export class Validators {
  constructor(
    public id?: number,
    public type?: string,
  ) {}
}
