import { Injectable } from '@angular/core';

export interface IMoneyColumnName {
  id: string;
  label: string;
  labelCourt: string;
  value: string;
  unit: string;
  codeMoney: string;
}

@Injectable({ providedIn: 'root' })
export class MoneyColumnName {
  getColumnName(): IMoneyColumnName {
    return {
      id: 'id',
      label: 'label',
      labelCourt: 'labelCourt',
      value: 'value',
      unit: 'unit',
      codeMoney: 'codeMoney',
    };
  }
}
