import {Injectable} from '@angular/core';

/**
 * Permet de récupérer les erreurs de validation de formulaire
 * côté serveur.
 */
@Injectable({providedIn: 'root'})
export class ErrorServConstant {
  readonly COUNTRY_RECEIVER_NOT_EQUAL_SENDER = 'country_receiver_not_equal_sender';
  readonly ALREADY_EXIST = 'already_exist';
  readonly NOT_EXIST = 'not_exist';
  readonly NEGATIVE_VALUE = 'negative_value';
  readonly BAD_REQUEST = 'bad_request';
}
