import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { ChampsFormulaireListComponent } from '../champs_formulaires/champs-formulaire-list.component';
import { FormulaireAddValidatorComponent } from '../champs_formulaires/add/validator/formulaire-add-validator.component';
import { ChampsFormulaireAddComponent } from '../champs_formulaires/add/champs-formulaire-add.component';
import { ChampsFormulaireUpdateComponent } from '../champs_formulaires/update/champs-formulaire-update.component';
import { ChampsFormulaireDeleteComponent } from '../champs_formulaires/delete/champs-formulaire-delete.component';
import { TypeInputFormListComponent } from './type-input-form-list.component';
import { TypeInputFormAddComponent } from './add/type-input-form-add.component';
import { TypeInputFormUpdateComponent } from './update/type-input-form-update.component';
import { TypeInputFormDeleteComponent } from './delete/type-input-form-delete.component';

/**
 * Ce module permet de gérer le model costRule.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [TypeInputFormListComponent, TypeInputFormAddComponent,
        TypeInputFormUpdateComponent, TypeInputFormDeleteComponent]
})
export class TypeInputFormModule {}
