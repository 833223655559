import { Routes } from '@angular/router';
import { ConstantCountries } from '../countries/countries.url';
import { VerticalLayoutComponent } from '../../../layout/vertical';
import { CountriesComponent } from '../countries';
import { ConstantMonies } from './monies.url';
import { MoniesComponent } from './monies.component';
import { GuardAuthService } from '../../../services/guard/auth/guard-auth.service';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';

/**
 * Ensemble des routes pour les monies.
 */
export const MONIES_ROUTE: Routes = [
  {
    path: ConstantMonies.url.MONIES.MONIES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantMonies.url.MONIES.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantMonies.url.MONIES.LIST,
        component: MoniesComponent
      }
    ]
  }
];
