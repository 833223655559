import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICUser } from 'src/app/model/c-user.model';
import { ITransaction } from 'src/app/model/transaction.model';
import { BASE_URL, BASE_URL_DYNAMIQ } from 'src/environments/environment';

type EntityResponseType = HttpResponse<ITransaction>;
@Injectable({
  providedIn: 'root'
})
export class MobileMoneyService {

  public resourceUrl = BASE_URL + 'credites-moi/consumer';
  public resourceUrlRetraitCode = BASE_URL + 'credites-moi/withdrawalCode';

  constructor(protected http: HttpClient) {}

  mobileMoney(transactionExp: any): Observable<EntityResponseType> {
    return this.http.post<ICUser>(BASE_URL_DYNAMIQ, transactionExp, { observe: 'response' });
  }

  retraitCode(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrlRetraitCode, transactionExp,
      { observe: 'response' });
  }
}
