import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


/**
 * va permettre de déterminer l'ouverture ou la fermeture
 * des différents popup retrait mobile money.
 */
 export interface RetraitPopupConstruct {
  key: string;
  value: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class RetraitCodeService {

  public popupTab: RetraitPopupConstruct[] = [];
  resultRetraitCode: any;

  constructor(protected http: HttpClient) {}

  getPopup() {
    return this.popupTab;
  }

  getCode() {
    return this.resultRetraitCode;
  }

  setCode(data) {
    this.resultRetraitCode = data;
  }

  clear() {
    this.popupTab = [];
  }

  setElement(key: string, value: boolean) {
    for(let p of this.popupTab) {
      if(p.key === key) {
        p.value = value;
        break;
      }
    }
    this.popupTab.push({
      key: key,
      value: value
    })
  }

  getElement(key: string): RetraitPopupConstruct {
    for(let p of this.popupTab) {
      if(p.key === key) {
        return p;
      }
    }
    return {
      key: '',
      value: undefined
    }
  }
}
