/**
 * Roles utilisateurs
 */
export const enum RoleType {
  'ROLE_ADMIN' = 'ROLE_ADMIN',
  'ROLE_PARTENER' = 'ROLE_PARTNER',
  'ROLE_TRADER' = 'ROLE_TRADER',
  'NONE' = 'NONE'
}

export const STATUS = {
  actived: 'ACTIVED',
  successful: 'SUCCESSFUL',
  deactived: 'DEACTIVED',
  failed: 'FAILED',
  failled: 'FAILLED'
}

export interface IRole {
  admin: string;
  partener: string;
  trader: string;
  none: string;
}
export const ROLE: IRole = {
  admin: 'ROLE_ADMIN',
  partener: 'ROLE_PARTNER',
  trader: 'ROLE_TRADER',
  none: 'NONE'
}

export const THE_ROLE_NAME = {
  partener: 'Partner',
  trader: 'Point Cash'
}

export const ROLE_NAME: IRole = {
  admin: 'admin',
  partener: 'partner',
  trader: 'trader',
  none: 'none'
}

export class Account {
  constructor(
    public activated: boolean,
    public authorities: string[],
    public email: string,
    public firstName: string,
    public langKey: string,
    public lastName: string,
    public login: string,
    public id: number,
    public imageUrl: string
  ) {}
}
