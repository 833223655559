import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BASE_URL } from 'src/environments/environment';
import { LoginServiceService } from '../../login-service.service';
import { TokenStorageService } from '../../storage/token-storage.service';
import { Observable } from 'rxjs';
import { AccountService } from '../../ServiceEntity/account.service';
import { Account } from '../../../model/account.model';
import { map } from 'rxjs/operators';
import { ROUTE_HOME_FULL } from '../../../layout/components/home.route';
import { GuardService } from './guard.service';

/**
 * Permet de vérifier si un utilisateur est authentifié
 * à fin de pouvoir décider si on lui donne accès à la route.
 */
@Injectable({
  providedIn: 'root'
})
export class GuardAuthService implements CanActivate {
  constructor(
    private guardService: GuardService
  ) {

  }

  /**
   * Si l'utilisateur est authentifié, alors on lui donne
   * accès à la route.
   * @param route
   * @param state
   * @Return true si l'utilisateur est authentifié,
   * false dans le cas contraire et il sera redirigé
   * vers la page d'authentification.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.guardService.isAuthenticate(route, state);
  }

}
