import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour la page d'accueil
 */
export namespace ConstantNotification {
  export const data = {
    title: 'Notifications',
    routing: 'notification'
  };
}

export const NOTIFICATION_ROUTING = Url.SLASH_ROUTE
  .concat(ConstantNotification.data.routing);
