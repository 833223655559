import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { ITransaction } from 'src/app/model/transaction.model';
import { HttpService } from 'src/app/services/http/http.service';
import { TransactionsService } from 'src/app/services/ServiceEntity/transactions.service';
import { BasePageComponent } from '../../base-page';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Subject } from 'rxjs';
import { FormService } from '../../../shared/services/form.service';
import { TranslateService } from '@ngx-translate/core';
import { ColorService } from '../../../shared/services/color.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent extends BasePageComponent implements OnInit,
  OnDestroy{
  searchValue: string;
  transactions: ITransaction[];
  transactionsSearch: ITransaction[];
  @ViewChild('basicTable') tabRef: any;
  private subs$ = new Subject<void>();

  constructor(
    private colorService: ColorService,
    private formService: FormService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,  
    private transactionService: TransactionsService,
    private translate : TranslateService) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      key: 'transactions',
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: 'home',
        },
        {
          title: '',
          key: 'transactions'
        },
      ],
    }; 
    if(this.pageData.key==="transactions"){
      translate.get('transactions').subscribe(res => this.pageData.title=res);
    }
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="transactions" ){
        translate.get('transactions').subscribe(res => value.title=res);
      }
     
    }); 
   
  }

  ngOnInit(): void {
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.ngOnInit();
        this.getData('assets/data/table.json', 'tableData',
          'setLoaded');
        this.transactionService.query().subscribe(res => {
          this.transactions = (res.body) ? res.body : [];
          this.transactionsSearch = this.transactions;
          super.setLoaded();
        });
      })
  }

  ngOnDestroy() {
    console.log('## destroy transaction');
    this.formService.free(this.subs$);
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  search() : void {
    const query = this.searchValue;
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val){
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.transactionsSearch].map(mapFn);
    const result = this.transactionsSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.transactions = [];
    result.forEach((c) => {
      this.transactions.push(c);
    })
  }

  getStatusBackground(status: number) {
    return this.colorService.getStatusBg(status);
  }

  getList(): ITransaction[] | any {
    return (this.tabRef) ? this.tabRef.data : [];
  }
  format(n: number) {
    return this.formService.format(n);
  }


}
