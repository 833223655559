import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IpAdressService } from '../ip-adress.service';
import { ApiKeyService } from '../api-key.service';
import { CUserApiKeyService } from '../../../../services/ServiceEntity/c-user-api-key.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { IIpAdressDTO, IpAdressApiKeyDTOS, IpAdressDTO, IpAdressDTOS } from '../../../../model/ipAdress.model';
import { ApiKeyCDTO, IApiKeyCDTO, IApiKeyDTO } from '../../../../model/api-key.model';
import { TIME_MIN_X1_REQUEST, TIME_MIN_X2_REQUEST } from '../../../../shared/constant/request.contant';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ColorService } from '../../../../shared/services/color.service';
import { ApiKeyDeleteComponent } from '../delete/api-key-delete.component';
import { IpAdressAddComponent } from '../../ip-adress/add/ip-adress-add.component';
import { IpAdressUpdateComponent } from '../../ip-adress/update/ip-adress-update.component';
import { STATUS_BD_CONSTANT } from '../../../../model/status.model';
import { IpAdressDeleteComponent } from '../../ip-adress/delete/ip-adress-delete.component';

/**
 * Permet de gérer l'ajout des api key.
 */
@Component({
  selector: 'app-api-key-list-ip-adress',
  templateUrl: './api-key-list-ip-adress.component.html',
  animations: [fadeIn]
})
export class ApiKeyListIpAdressComponent extends BasePageComponent
  implements OnInit, OnDestroy, AfterViewChecked{
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ApiKeyListIpAdressComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;
  idAccount: number;
  apiKey: IApiKeyDTO;

  //liste apiKey
  ipAdressList: IpAdressApiKeyDTOS[] = [];
  ipAdressSearch: IpAdressApiKeyDTOS[] = [];

  @ViewChild('basicTable') tabRef: any;
  searchValue: string;

  constructor(
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private ipAdressService: IpAdressService,
    private apiKeyService: ApiKeyService,
    private colorService: ColorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domainService: CUserApiKeyService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ApiKeyListIpAdressComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct api key list ip adress');
    this.theDialogRef = this.dialogRef;
    console.log('## idAccount: ', this.data.idAccount);
    this.apiKey = this.data.apiKey;
    this.idAccount = this.data.idAccount;
    this.ipAdressService.eventEmitter = new EventEmitter<string>();
  }

  ngOnInit(): void {
    console.log('## init api key list ip');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
          this.getDataList();
      });
    this.ipAdressService.eventEmitter.subscribe(res => {
      console.log('## emitting: ', res);
      this.getDataList();
    });
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy api key list ip adress');
    this.formService.free(this.subs$);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  delete(d: IpAdressApiKeyDTOS) {
    console.log('## delete ip adress:', d);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(IpAdressDeleteComponent, {
      data: {
        apiKey: this.apiKey,
        ipAdress: d,
      }, height: '600px', width: '800px'
    });
  }

  isActivated(data: IpAdressApiKeyDTOS): boolean {
    return data.statusId === STATUS_BD_CONSTANT.actived;
  }

  affectStatus(d: IpAdressApiKeyDTOS) {
    console.log('## open affect status: ', d);
    let a = {
      'id': d.id,
      'statusId': (d.statusId === STATUS_BD_CONSTANT.actived) ?
        STATUS_BD_CONSTANT.deactived
        : STATUS_BD_CONSTANT.actived
    }
    this.ipAdressService.updateStatus(a).subscribe(res => {
      console.log('## success change status ip address');
      setTimeout(() => {
        this.ipAdressService.eventEmitter.emit('list');
      }, TIME_MIN_X1_REQUEST);

    }, (res: HttpErrorResponse) => {
      console.log('## error get list status while adding: ' + res.message);
    })
  }

  getDataList() {
    let p = new HttpParams();
    p = p.set('apiKeyId', this.apiKey.id.toString());
    this.ipAdressService.list(p).subscribe(res => {
      console.log('## success get list ip adress: ', res);
      const body = res.body? res.body : [];
      this.ipAdressList = body;
      this.ipAdressSearch = body;
    }, error => {
      console.log('## error get list ip adress: ', error);
    });
  }

  getStatusBackground(statusId: number) {
    return this.colorService.getStatusBg(statusId);
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.ipAdressSearch].map(mapFn);
    const result = this.ipAdressSearch.filter(
      (_, i) => stringArr[i]
        .indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.ipAdressList = [];
    result.forEach((c) => {
      this.ipAdressList.push(c);
    });
  }

  update(i: IIpAdressDTO) {
    console.log('## liste ip adress');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(IpAdressUpdateComponent, {
      data: {
        ipAdress: i
      }, height: '600px', width: '800px'
    });
  }

  add() {
    console.log('## add ip adress');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(IpAdressAddComponent, {
      data: {
        apiKey: this.apiKey,
      }, height: '600px', width: '800px'
    });
  }

  /**
   * Retourne la liste des des api key
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IpAdressApiKeyDTOS[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  close() {
    this.dialogRef.close();
  }

}