import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Account } from '../../../model/account.model';
import { ChampsFormulaire } from '../../../model/champs_formulaire/champs-formulaire.model';
import { Observable, Subject } from 'rxjs';
import { IServiceItem } from '../../../model/service-item.model';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from '../base/base.service';
import { ServiceItemService } from '../service-items/service-item.service';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { ChampsFormulaireService } from '../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantChampsFormulaires } from '../champs_formulaires/champs_formulaires/champs-formulaire.url';
import { ConstantHome } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { takeUntil } from 'rxjs/operators';
import { Domain } from '../../../model/domain.model';
import { DomainLink } from './domain.url';
import { DomainService } from './domain.service';
import { ChampsFormulaireAddComponent } from '../champs_formulaires/champs_formulaires/add/champs-formulaire-add.component';
import { ChampsFormulaireUpdateComponent } from '../champs_formulaires/champs_formulaires/update/champs-formulaire-update.component';
import { DomainAddComponent } from './add/domain-add.component';
import { DomainUpdateComponent } from './update/domain-update.component';
import { FormulaireAddValidatorComponent } from '../champs_formulaires/champs_formulaires/add/validator/formulaire-add-validator.component';
import { DomainAffectStatusComponent } from './affect_status/domain-affect-status.component';
import { ConstantValidatorsAffected } from '../champs_formulaires/validator_affected/validator-affected.url';
import { StatusLink } from '../status/status.url';
import { DomainColumnName } from './domain-column-name';

/**
 * Gère l'affichage des domains.
 * see {@link Domain}
 */
@Component({
  selector: 'app-domain-list',
  templateUrl: './domain-list.component.html'
})
export class DomainListComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  countriesList: Domain[]; // utiliser pour afficher la liste des éléments
  countriesSearch: Domain[]; // utiliser pour rechercher parmi liste des éléments.
  searchValue: string;
  initByQueryParam = false;

  serviceItemTyped = '';
  subs$ = new Subject<void>();

  myControl = new UntypedFormControl();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public baseService: BaseService,
    private serviceItemService: ServiceItemService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private domainColumnName: DomainColumnName,
    private dialog: MatDialog,
    private domainService: DomainService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct domain list');
    this.pageData = {
      title: DomainLink.data.sub.list.title,
      key: 'domain.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: DomainLink.data.title,
          key: 'domain.name',
          route: Url.SLASH_ROUTE.concat(DomainLink.urlFull
            .FEES.LIST)
        },
        {
          title: DomainLink.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'domain.title') {
      translate.get('domain.title').subscribe(res => this.pageData.title = res);
    }

    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'domain.name') {
        translate.get('domain.name').subscribe(res => {
          console.log('## res: ', res);
          value.title = res;
        });
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }

    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init domain list');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.account = account;
          // récupération de la liste des domains.
          this.domainService.query(this.domainService.list_url)
            .subscribe(res => {
              console.log('## Success get domain list: ', res);
              this.countriesList = (res.body) ? res.body : [];
              this.countriesSearch = this.countriesList;
              super.setLoaded();
              // récupération de la liste des domains.
              this.domainService.getObjetObs().pipe(takeUntil(this.subs$))
                .subscribe((res: ChampsFormulaire[]) => {
                  console.log('## get list domain in list by subject: ', res);
                  this.countriesList = (res) ? res : [];
                  this.countriesSearch = this.countriesList;
                });
            });
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  affectStatus(d: Domain) {
    console.log('## open affect status');
    console.log('## the domain: ', d);
    this.domainService.setObjetSelectObs(d);
    this.baseService.openSmH2(this.dialog, DomainAffectStatusComponent);
  }

  add() {
    console.log('## open domain add');
    this.baseService.openSm(this.dialog, DomainAddComponent);
  }

  /**
   * Affiche la liste des status du domain.
   * @param d le domain.
   */
  show(d: Domain) {
    console.log('## show');
    console.log('## domainId: ', d.id);
    this.router.navigate(['/' + StatusLink.urlFull.FEES.LIST],
      { queryParams: { [DomainLink.url.FEES.FEES_]: d.id,
        [this.domainColumnName.getColumnNameRandom().domainLabel]: d.label} });
  }

  update(c: Domain) {
    this.domainService.setObjetSelectObs(c);
    this.baseService.openSm(this.dialog, DomainUpdateComponent);
  }

  /**
   * Retourne la liste des des domains
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): Domain[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  /**
   * Permet de rechercher un domain.
   */
  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.countriesSearch].map(mapFn);
    const result = this.countriesSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  ngOnDestroy() {
    console.log('## on destroy domain list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

}
