import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisée pour gérer les détails de coût.
 */
export namespace ConstantCostsDetails {
  export const data = {
    title: 'Détails',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Nouvelle description de coût'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des descriptions de coût'
      }
    }
  }
  export const url = {
    COSTS: {
      COSTS_: 'couts_detail',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    COSTS: {
      ADD: ConstantCostsDetails.url.COSTS.COSTS_
        .concat(Url.SLASH_ROUTE).concat(ConstantCostsDetails.url.COSTS.ADD),
      LIST: ConstantCostsDetails.url.COSTS.COSTS_
        .concat(Url.SLASH_ROUTE).concat(ConstantCostsDetails.url.COSTS.LIST),
    }
  };
}
