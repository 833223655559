import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { FEES_ROUTE } from '../fees/fees.route';
import { FeesUpdateComponent } from '../fees/update/fees-update.component';
import { FeesAddComponent } from '../fees/add/fees-add.component';
import { FeesComponent } from '../fees';
import { ExchangeRateComponent } from './exchange-rate.component';
import { EXCHANGE_RATE_ROUTE } from './exchange-rate.route';
import { ExchangeRateAddComponent } from './add/exchange-rate-add.component';
import { ExchangeUpdateComponent } from './update/exchange-rate-update.component';
import { ExchangeRateLiveAddComponent } from './live/add/exchange-rate-live-add.component';
import { ExchangeRateHistoricalAddComponent } from './historical/add/exchange-rate-historical-add.component';
import { ExchangeRateHistoricalComponent } from './historical/exchange-rate-historical.component';
import { ExchangeRateLiveComponent } from './live/exchange-rate-live.component';

/**
 * Ce module permet de gérer le model exchange rate.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [ExchangeRateHistoricalComponent,
        ExchangeRateLiveComponent, ExchangeRateHistoricalAddComponent,
        ExchangeRateLiveAddComponent, ExchangeUpdateComponent,
        ExchangeRateAddComponent, ExchangeRateComponent]
})
export class ExchangeRateModule {}
