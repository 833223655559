import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountsRessourceTypeComponent } from './list/acc-ress-type-list.component';
import { ACC_RESS_TYPE_ROUTE } from './acc-ress-type.route';
import { SharedModule } from '../../../shared.module';
import { AccountsRessourceTypeAddComponent } from './add/acc-ress-type-add.component';
import { AccountsRessourceTypeShowComponent } from './show/acc-ress-type-show.component';
import { AccountsRessourceTypeUpdateComponent } from './update/acc-ress-type-update.component';

/**
 * Ce module permet de gérer le model AccountRessType
 * pour les types de account ressource.
 */
@NgModule({
  imports: [SharedModule],
  declarations: [AccountsRessourceTypeUpdateComponent, AccountsRessourceTypeShowComponent,
    AccountsRessourceTypeAddComponent, AccountsRessourceTypeComponent]
})
export class AccRessTypeModule {}
