import { IMenuItem } from '../../interfaces/main-menu';
import { ConstantHome } from '../../pages/entities/home/home.url';
import { ConstantMonies } from '../../pages/entities/monies/monies.url';
import { ConstantFeesBenefits } from '../../pages/entities/fees/fees.url';
import { ConstantServiceItems } from '../../pages/entities/service-items/service-items.url';
import { ConstantExchangeRate } from '../../pages/entities/exchange-rate/exchange-rate.url';
import { ConstantCountries } from '../../pages/entities/countries/countries.url';
import { ConstantAccRessType } from '../../pages/entities/acc-ress-type/acc-ress-type.url';
import { CONSTANT_KEY_TRANSLATE } from './key-translate.constant';
import { ConstantNotification } from '../../pages/entities/notification/notification.url';

/**
 * Décrit les différents données utilisées
 * pour le menu.
 */
export const CONSTANT_MENU_PARTENER: IMenuItem[] = [
  {
    "title": ConstantHome.data.title,
    "key" :  CONSTANT_KEY_TRANSLATE.home,
    "icon": {
      "class": "icofont-home"
    },
    "routing": ConstantHome.data.routing,
    "image":""
  },
  {
    "title": 'account',
    "key" :  CONSTANT_KEY_TRANSLATE.mobileAccount,
    "icon": {
      "class": "icofont-home"
    },
    "routing": 'account',
    "image":""
  },

  {
    "title": "Point Cash",
    "key" : CONSTANT_KEY_TRANSLATE.trader,
    "icon": {
      "class": "icofont-home"
    },
    "image": "assets/content/trader.png",
    sub: [{
      title: "Lister",
      routing: 'c-user'
    }]
  },
  {
    "title": ConstantNotification.data.title,
    "key" :  CONSTANT_KEY_TRANSLATE.notification,
    "icon": {
      "class": "icofont-home"
    },
    "routing": ConstantNotification.data.routing,
    "image":""
  },
  {
    "title": "Api Key",
    "key" :  CONSTANT_KEY_TRANSLATE.apikey,
    "icon": {
      "class": "icofont-home"
    },
    "routing": "api-key",
    "image": "assets/content/apikey.png"
  }
]
