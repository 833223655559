import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IOption } from '../../../../ui/interfaces/option';
import { ICountry } from '../../../../model/country.model';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IMoneyColumnName, MoneyColumnName } from '../../monies/money-column-name';
import { MatDialogRef } from '@angular/material/dialog';
import { MoniesService } from '../../monies/monies.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { IMoney, Money } from '../../../../model/money.model';
import { CostsRule, ICostsRule } from '../../../../model/costs-rule.model';
import { CostsRuleService } from '../costs-rule.service';
import { CostsRuleColumnName, ICostsRuleColumnName } from '../fees-column-name';
import { IServiceItem } from '../../../../model/service-item.model';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CostsDetails, ICostsDetails } from '../../../../model/costs-details.model';
import { CostsDetailsService } from '../../costs-details/costs-details.service';

/**
 * Permet de gérer la modification des costs rule.
 */
@Component({
  selector: 'app-fees-liste',
  templateUrl: './fees-update.component.html',
  animations: [fadeIn]
})
export class FeesUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  // informations sur les coûts.
  costsSelected: CostsDetails;
  costsList: CostsDetails[] = [];
  filteredOptionsCostsDetails: Observable<CostsDetails[]>;

  private costsRuleSelected: ICostsRule;
  private subs$ = new Subject<void>();
  editForm: UntypedFormGroup;
  column: ICostsRuleColumnName;

  constructor(
    private costsDetailsService: CostsDetailsService,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<FeesUpdateComponent>,
    private costsRuleService: CostsRuleService,
    private formService: FormService,
    private columnName: CostsRuleColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct costs rule liste');
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init costs rule liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.costsRuleService.getCostRuleSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: ICostsRule) => {
          console.log('## get costs rule selected to liste');
          try {
            console.log('## data: ', res);
            this.costsRuleSelected = res;
            this.editForm.patchValue({
              [this.column.id]: res.id,
              [this.column.amountA]: res.amountA,
              [this.column.amountB]: res.amountB,
              [this.column.montant]: res.montant,
              [this.column.serviceItemId]: res.serviceItemsId,
              [this.column.moneyId]: res.moneyId,
              [this.column.countryAId]: res.countryAId,
              [this.column.countryBId]: res.countryBId,
            })
            this.getCostsDetailsList(); // after 500ms.
          }catch (e) {
            console.log('## error get costs rule selected: ' + e.message);
          }
        });
        super.setLoaded();
      })
  }

  ngOnDestroy() {
    console.log('## destroy costs rule liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  /**
   * Permet de mettre à jour le formulaire avec
   * le type de coût à modifier
   */
  selectCostDetails() {
      const res = this.costsRuleSelected;
      const id = res.typeDetailsId;
      let costsFind = this.costsList.find(p => p.id === id)
      this.editForm.patchValue({
        ['costsDetails']: costsFind,
      })
  }

  initForm(fb: UntypedFormBuilder) {
    try {
      this.editForm = this.fb.group({
        id: [],
        costsDetails: ['', [Validators.required]],
        amountA: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
        amountB: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
        montant: [null, [Validators.required,
          Validators.pattern('^[0-9xX\\+\\-()/.\\*]*$')]],
        moneyId: [null, [Validators.required]],
        serviceItemId: [null, [Validators.required]],
        countryAId: [null, [Validators.required]],
        countryBId: [null, [Validators.required]]
      });

    } catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  displayFnCosts(subject: ICostsDetails) {
    return subject ? subject.label : '';
  }

  /**
   * Permet de récupérer la liste des détails de coût
   */
  getCostsDetailsList() {
    setTimeout(() => {
      this.costsDetailsService
        .query(this.costsDetailsService.typesUrl)
        .pipe(
          filter((mayBeOk: HttpResponse<CostsDetails[]>) => mayBeOk.ok),
          map((response: HttpResponse<CostsDetails[]>) => response.body)
        )
        .subscribe((res: CostsDetails[]) => {
            console.log('## success get list costs details while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.costsList = rs;
            this.filteredOptionsCostsDetails = this.editForm.get('costsDetails').valueChanges
              .pipe(
                startWith(''),
                map(value => this._filterCostsDetails(value))
              );
            this.selectCostDetails();
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list costs details while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  /**
   * Effectue une recherche sur les détails de coût
   * @param value la valeur à rechercher
   * @private
   */
  private _filterCostsDetails(value: string): ICostsDetails[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.costsList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## update rule');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: ICostsRule = this.createFromForm();
    console.log('## c: ', c);
    this.costsRuleService.update(c).subscribe(res => {
        console.log('## success update rule');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.markAsPristine();
        this.successSave = true;
        this.costsRuleService.queryList().subscribe(res => {
          console.log('## Success get costs rule list after saving');
          const c: ICostsRule[] = (res.body) ? res.body : [];
          console.log('## size: ' + c.length);
          this.costsRuleService.setCostRuleObs(c);
          this.closeModal();
        });
      },
      err => {
        console.log('## error liste costs rule: ', err);
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, TIME_MIN_REQUEST);
  }

  private createFromForm(): ICostsRule {
    return {
      ...new CostsRule(),
      id: this.editForm.get(['id']).value,
      amountA: this.editForm.get(['amountA']).value,
      amountB: this.editForm.get(['amountB']).value,
      moneyId: this.editForm.get(['moneyId']).value,
      montant: this.editForm.get(['montant']).value,
      typeDetailsId: (this.editForm.get(['costsDetails']).value as
        ICostsDetails).id,
      serviceItemsId: this.editForm.get(['serviceItemId']).value,
      countryAId: this.editForm.get(['countryAId']).value,
      countryBId: this.editForm.get(['countryBId'])?.value
    };
  }

  closeModal() {
    console.log('## close costs rule add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
