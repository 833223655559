import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { FormService } from '../../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { BenefitRuleService } from '../../fees/benefits.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { CostsRuleService } from '../../fees/costs-rule.service';
import { CountryService } from '../../countries/countries.service';
import { MoniesService } from '../../monies/monies.service';
import { ServiceItemService } from '../../service-items/service-item.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantFeesBenefits } from '../../fees/fees.url';
import { ConstantHome } from '../../home/home.url';
import { Url } from '../../../../shared/constant/url.constant';
import { takeUntil } from 'rxjs/operators';
import { Account } from '../../../../model/account.model';
import { IServiceItem } from '../../../../model/service-item.model';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ICostsRule } from '../../../../model/costs-rule.model';
import { IMoney } from '../../../../model/money.model';
import { ICountry } from '../../../../model/country.model';
import { ConstantValidators } from './validator.url';
import { Validators } from '../../../../model/champs_formulaire/validators.model';
import { ValidatorService } from './validator.service';
import { BaseService } from '../../base/base.service';
import { CONSTANT_ICON } from '../../../../shared/constant/icon.constant';
import { CountryAddComponent } from '../../countries/add/country-add.component';
import { CountryShowComponent } from '../../countries/show/country-show.component';
import { CountryUpdateComponent } from '../../countries/update/country-update.component';
import { CountryDeleteComponent } from '../../countries/delete/country-delete.component';
import { ValidatorAddComponent } from './add/validator-add.component';
import { SIZE_H_MD_POPUP, SIZE_W_MD_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { ValidatorUpdateComponent } from './update/validator-update.component';
import { ValidatorDeleteComponent } from './delete/validator-delete.component';

@Component({
  selector: 'app-validator-list',
  templateUrl: './validator-list.component.html'
})
export class ValidatorListComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  countriesList: Validators[];
  countriesSearch: Validators[];
  searchValue: string;

  subs$ = new Subject<void>();

  constructor(
    public baseService: BaseService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private validatorService: ValidatorService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct validator list');
    this.pageData = {
      title: ConstantValidators.data.sub.list.title,
      key: 'validator.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantValidators.data.title,
          key: 'validator.name',
          route: Url.SLASH_ROUTE.concat(ConstantValidators.urlFull
            .FEES.LIST)
        },
        {
          title: ConstantValidators.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if(this.pageData.key==="validator.title"){
      translate.get('validator.title').subscribe(res => this.pageData.title=res);
    }

    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="validator.name" ){
        translate.get('validator.name').subscribe(res => {
          console.log('## res: ', res);
          value.title=res
        });
      }
      if( value.key==="lister" ){
        translate.get('lister').subscribe(res => value.title=res);
      }

    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init validator list');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.account = account;
          this.validatorService.query(this.validatorService.list_url).subscribe(res => {
            console.log('## Success get country list');
            this.countriesList = (res.body) ? res.body : [];
            this.countriesSearch = this.countriesList;
            super.setLoaded();
            this.validatorService.getObjetObs().pipe(takeUntil(this.subs$))
              .subscribe((res: Validators[]) => {
                console.log('## get list country in list by subject: ', res);
                this.countriesList = (res) ? res : [];
                this.countriesSearch = this.countriesList;
              });
          });
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    console.log('## on destroy validator list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.countriesSearch].map(mapFn);
    const result = this.countriesSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  add() {
    console.log('## open validator add');
    this.baseService.openSm(this.dialog, ValidatorAddComponent);
  }

  update(c: Validators) {
    this.validatorService.setObjetSelectObs(c);
    this.baseService.openSm(this.dialog, ValidatorUpdateComponent);
  }

  delete(c: Validators) {
    this.validatorService.setObjetSelectObs(c);
    this.baseService.openSm(this.dialog, ValidatorDeleteComponent);
  }

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): Validators[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }
}