import { Injectable } from '@angular/core';

export interface IVExchangeColumnName {
  id: string,
  version: string,
  nomVersion: string,
  description: string,
  etat: string
}

@Injectable({ providedIn: 'root' })
export class VExchangeColumnName {

  getColumnName(): IVExchangeColumnName {
    return {
      id: 'id',
      version: 'version',
      nomVersion: 'nomVersion',
      description: 'description',
      etat: 'etat'
    };
  }
}
