import { Pipe, PipeTransform } from '@angular/core';
import { IServiceItem } from '../../model/service-item.model';
import { ICostsRule } from '../../model/costs-rule.model';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  /**
   * Permet de vérifier si une valeur est continue dans une liste.
   * @param list
   * @param value
   * @param c1 nom de colonne dans la liste.
   * @param c2 nom de colonne dans la valeur.
   */
  transform(list: any[], value: any, c1: string, c2: string) {
    return value ? list.filter(item => item[c1] === value[c2]) : list;
  }
}
