import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import {
  BEFORE_ICON_RESET,
  BEFORE_ICON_VALIDATION,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALID_CLASS,
  BTN_VALIDATION_CLASS,
  CANCEL_BG_COLOR,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS,
  CONTAINER_POPUP_CLASS,
  FORM_CLASS,
  ICON_COLOR_REQUEST_CSS,
  ICON_FONT_REQUEST_CLASS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET,
  REMOVABLE_ICON_REQUEST,
  RESULT_REQUEST_ERROR_CLASS,
  RESULT_REQUEST_SUCCESS_CLASS,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS,
  SUFFIX_ICON_FORM,
  TITLE_POPUP_CLASS,
  VALIDATION_BTN2_POPUP_CLASS,
  VALIDATION_BTN3_POPUP_CLASS,
  VIEW_ERROR,
  VIEW_VALIDATION,
  WARNING_COLOR_CSS,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../constant/css-style/css-class.constant';
import { FormService } from '../../services/form.service';
import { UntypedFormGroup } from '@angular/forms';

/**
 * Réprésente le corps pour contenir différents formulaire.
 * Il utilise le matDialogContent de angular material.
 */
@Component({
  selector: 'app-body-form',
  templateUrl: './body-form.component.html',
  animations: [fadeIn]
})
export class BodyFormComponent implements OnInit {

  // le formulaire
  @Input('contentForm') contentForm: UntypedFormGroup;

  constructor(private formService: FormService) {
    console.log('## construct header form')
  }

  ngOnInit(): void {
    console.log("## init header form");
  }

  getInside2xContainerClass(): string {
    return CONTAINER_INSIDE2X_POPUP_CLASS;
  }

  getInsideContainerClass(): string {
    return CONTAINER_INSIDE_POPUP_CLASS;
  }
  getContainerClass() : string {
    return CONTAINER_POPUP_CLASS;
  }


  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.contentForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.contentForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.contentForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.contentForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.contentForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.contentForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.contentForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.contentForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.contentForm, column);
  }
}
