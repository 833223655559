export interface IReclamation {
  id?: number;
  idTransaction?: number;
  raison?: string;
  senderRequestId?: number;
  createdBy?: string;
  justificatif?: string;
  solverRequestId?: number;
  gereateBy?: string;
  statusOperaation?: string;
}

export class Reclamation implements IReclamation {
  constructor(
    public id?: number,
    public idTransaction?: number,
    public raison?: string,
    public senderRequestId?: number,
    public createdBy?: string,
    public justificatif?: string,
    public solverRequestId?: number,
    public gereateBy?: string,
    public statusOperaation?: string
  ) {}
}
