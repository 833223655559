import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VerticalLayoutComponent } from '../layout/vertical';
import { HorizontalLayoutComponent } from '../layout/horizontal';
import { PublicLayoutComponent } from '../layout/public';
import { ErrorLayoutComponent } from '../layout/error';

import { PageAnalyticsComponent } from '../pages/dashboards/analytics';
import { PageButtonsComponent } from '../pages/ui/components/buttons';
import { PageInputsComponent } from '../pages/ui/components/inputs';
import { PageSimpleTablesComponent } from '../pages/ui/tables/simple-tables';
import { PageSortingTableComponent } from '../pages/ui/tables/sorting-table';
import { PageSearchTableComponent } from '../pages/ui/tables/search-table';
import { PageFilterTableComponent } from '../pages/ui/tables/filter-table';
import { PagePaginationTableComponent } from '../pages/ui/tables/pagination-table';
import { PageFormElementsComponent } from '../pages/ui/forms/form-elements';
import { PageFormLayoutsComponent } from '../pages/ui/forms/form-layouts';
import { PageFormValidationComponent } from '../pages/ui/forms/form-validation';
import { PageIconsOptionsComponent } from '../pages/ui/icons/icons-options';
import { PageIconsIfComponent } from '../pages/ui/icons/icons-if';
import { PageIconsSliComponent } from '../pages/ui/icons/icons-sli';
import { Page404Component } from '../pages/errors/page-404';
import { Page500Component } from '../pages/errors/page-500';
import { Page503Component } from '../pages/errors/page-503';
import { Page505Component } from '../pages/errors/page-505';
import { PageNg2ChartsComponent } from '../pages/ui/charts/ng2-charts';
import { PageNgxChartsComponent } from '../pages/ui/charts/ngx-charts';
import { PageNgxEchartsComponent } from '../pages/ui/charts/ngx-echarts';
import { PageLeafletMapsComponent } from '../pages/ui/maps/leaflet-maps';
import { PageInvoiceComponent } from '../pages/apps/service-pages/invoice';
import { PageInvoicesComponent } from '../pages/apps/service-pages/invoices';
import { PagePricingComponent } from '../pages/apps/service-pages/pricing';
import { PageTimelineComponent } from '../pages/apps/service-pages/timeline';
import { PageUserProfileComponent } from '../pages/apps/user-pages/user-profile';
import { PageEditAccountComponent } from '../pages/apps/user-pages/edit-account';
import { PageCalendarComponent } from '../pages/apps/service-pages/calendar';
import { PageSignInComponent } from '../pages/apps/authentication/sign-in';
import { PageSignUpComponent } from '../pages/apps/authentication/sign-up';
import { PageSettingsComponent } from '../pages/settings';
import { PageSwitchersComponent } from '../pages/ui/components/switchers';
import { PageCardsComponent } from '../pages/ui/components/cards';
import { PageListsComponent } from '../pages/ui/components/lists/lists.component';
import { PageSelectsComponent } from '../pages/ui/components/selects';
import { PageAutocompletesComponent } from '../pages/ui/components/autocompletes';
import { PageTextareasComponent } from '../pages/ui/components/textareas';
import { PageAlertsComponent } from '../pages/ui/components/alerts';
import { PageProgressComponent } from '../pages/ui/components/progresses';
import { PageBadgesComponent } from '../pages/ui/components/badges';
import { PageCheckboxesComponent } from '../pages/ui/components/checkboxes';
import { PageCircleProgressesComponent } from '../pages/ui/components/circle-progresses';
import { PageRadioButtonsComponent } from '../pages/ui/components/radio-buttons';
import { PageRatingsComponent } from '../pages/ui/components/ratings';
import { PageTypographyComponent } from '../pages/ui/typography';
import { PageWorldMapComponent } from '../pages/ui/maps/world-map';
import { PageGoogleMapsComponent } from '../pages/ui/maps/google-maps';
import { PageDashboardComponent } from '../pages/dashboards/dashboard';
import { PageAvatarsComponent } from '../pages/ui/components/avatars';
import { PageDropdownsComponent } from '../pages/ui/components/dropdowns';
import { PageModalWindowsComponent } from '../pages/ui/components/modal-windows';
import { PageECommerceComponent } from '../pages/dashboards/e-commerce';
import { PageResetPasswordComponent } from '../pages/apps/authentication/reset-password';
import { PageVerifyAccountComponent } from '../pages/apps/authentication/verify-account';
import { PageConnectionsComponent } from '../pages/apps/user-pages/connections';
import { PageGroupsComponent } from '../pages/apps/user-pages/groups';
import { PageWidgetsComponent } from '../pages/widgets';
import { PageDatePickerComponent } from '../pages/ui/forms/date-picker';
import { PageUploadComponent } from '../pages/ui/forms/upload';
import { PageSliderComponent } from '../pages/ui/forms/slider';
import { HomeComponent } from '../pages/entities/home/home.component';
import { FeesComponent } from '../pages/entities/fees/fees.component';
import { MoniesComponent } from '../pages/entities/monies/monies.component';
import { CountriesComponent } from '../pages/entities/countries/countries.component';
import { CUserComponent } from '../pages/entities/c-user/c-user.component';
import { GuardAuthService } from '../services/guard/auth/guard-auth.service';
import { TransactionsComponent } from '../pages/entities/transactions/transactions.component';
import { ServiceItemsComponent } from '../pages/entities/service-items/service-items.component';
import { ExchangeRateComponent } from '../pages/entities/exchange-rate/exchange-rate.component';

import { CuserDetailManageComponent } from '../pages/entities/cuser-detail-manage/cuser-detail-manage.component';
import { ApiKeyComponent } from '../pages/entities/api-key/api-key.component';
import { ViewAccountComponent } from '../pages/entities/view-account/view-account.component';
import { CashComponent } from '../pages/entities/cash/cash.component';

import { ACC_RESS_TYPE_ROUTE } from '../pages/entities/acc-ress-type/acc-ress-type.route';
import { ROUTE_HOME_FULL, ROUTE_HOME_ } from '../layout/components/home.route';
import { GuardAuthChildService } from '../services/guard/auth/guard-auth-child.service';
import { MONIES_ROUTE } from '../pages/entities/monies/monies.route';

import { COUNTRIES_ROUTE } from '../pages/entities/countries/countries.route';
import { FEES_ROUTE } from '../pages/entities/fees/fees.route';
import { SERVICE_ITEMS_ROUTE } from '../pages/entities/service-items/service-item.route';
import { EXCHANGE_RATE_ROUTE } from '../pages/entities/exchange-rate/exchange-rate.route';
import { GuardLoginService } from '../services/guard/auth/guard-login.service';
import { Url } from '../shared/constant/url.constant';
import { GuardAdminService } from '../services/guard/authorities/guard-admin.service';
import { GuardPartenerService } from '../services/guard/authorities/guard-partener.service';
import { GuardTraderService } from '../services/guard/authorities/guard-trader.service';
import { GuardAdmPartService } from '../services/guard/authorities/guard-adm-part.service';
import { GuardPartTradService } from '../services/guard/authorities/guard-part-trad.service';
import { MobileMoneyComponent } from '../pages/entities/mobile-money/mobile-money.component';
import { VALIDATOR_ROUTE } from '../pages/entities/champs_formulaires/validators/validator.route';
import { ValidatorListComponent } from '../pages/entities/champs_formulaires/validators/validator-list.component';
import { CHAMPS_FORMULAIRE_ROUTE } from '../pages/entities/champs_formulaires/champs_formulaires/champs-formulaire.route';
import { VALIDATOR_AFFECTED_ROUTE } from '../pages/entities/champs_formulaires/validator_affected/validator-affected.route';
import { TYPE_INPUT_FORM_ROUTE } from '../pages/entities/champs_formulaires/type-input-form/type-input-form.route';
import { SOURCE_FORM_ROUTE } from '../pages/entities/champs_formulaires/source-form/source-form.route';
import { DOMAIN_ROUTE } from '../pages/entities/domain/domain.route';
import { STATUS_ROUTE } from '../pages/entities/status/status.route';
import { VERSION_EXCHANGE_RATE_ROUTE } from '../pages/entities/version-exch-rate/version-exchange-rate.route';
import { ConstantNotification } from '../pages/entities/notification/notification.url';
import { NotificationComponent } from '../pages/entities/notification/notification.component';
import { SERVICE_ITEMS_COMPOSE_ROUTE } from '../pages/entities/service-items-composes/service-item-composes.route';
import { FORMULAIRE_ROUTE } from '../pages/entities/formulaires/formulaire.route';
import { COSTS_ROUTE } from '../pages/entities/costs/costs.route';
import { COSTS_DETAILS_ROUTE } from '../pages/entities/costs-details/costs-details.route';

export const CHILD_ROUTES: Routes = [
  { path: ROUTE_HOME_.home, component: HomeComponent },
  { path: 'fees', component: FeesComponent },
  ...COSTS_DETAILS_ROUTE,
  ...COSTS_ROUTE,
  ...FORMULAIRE_ROUTE,
  ...VERSION_EXCHANGE_RATE_ROUTE,
  ...STATUS_ROUTE,
  ...SOURCE_FORM_ROUTE,
  ...TYPE_INPUT_FORM_ROUTE,
  ...DOMAIN_ROUTE,
  ...VALIDATOR_AFFECTED_ROUTE,
  ...CHAMPS_FORMULAIRE_ROUTE,
  ...VALIDATOR_ROUTE,
  ...MONIES_ROUTE,
  ...COUNTRIES_ROUTE,
  ...FEES_ROUTE,
  ...ACC_RESS_TYPE_ROUTE,
  ...SERVICE_ITEMS_ROUTE,
  ...SERVICE_ITEMS_COMPOSE_ROUTE,
  ...EXCHANGE_RATE_ROUTE,
  { path: 'c-user', canActivate: [GuardAdmPartService], component: CUserComponent },
  { path: 'c-user/cuserdetailmanage/:id', canActivate: [GuardAdmPartService],
    component: CuserDetailManageComponent },
  { path: 'transactions',
    canActivate: [GuardAdminService], component: TransactionsComponent },
  { path: 'api-key', canActivate: [GuardPartenerService], component: ApiKeyComponent },
  { path: 'account', canActivate: [GuardPartTradService],
    component: ViewAccountComponent },
  { path: ConstantNotification.data.routing, canActivate: [GuardPartTradService],
    component: NotificationComponent },
  { path: 'notification/:id', canActivate: [GuardPartTradService],
    component: NotificationComponent },
  { path: 'mobile-money', canActivate: [GuardTraderService], component:MobileMoneyComponent}

];

const PUBLIC_ROUTES: Routes = [
  { path: ROUTE_HOME_.signIn, component: PageSignInComponent },
  { path: ROUTE_HOME_.forgotPassword, component: PageResetPasswordComponent }
];

const ERROR_ROUTES: Routes = [
  { path: Url.ERR_404, component: Page404Component },
  { path: '**', component: Page404Component }
];
/* const CUSER_CHILD: Routes = [
{ path: 'cuserdetailmanage/:id', component: CuserDetailManageComponent },
  
]; */
export const ROUTES: Routes = [
  { path: '',
    redirectTo: ROUTE_HOME_FULL.signIn,
    pathMatch: 'full' },
  {
    path: '',
    canActivate: [GuardAuthService],
    canActivateChild: [GuardAuthChildService],
    component: VerticalLayoutComponent,
    children: CHILD_ROUTES
  },
  {
    path: '',
    component: PublicLayoutComponent,
    canActivate: [GuardLoginService],
    children: PUBLIC_ROUTES
  },
  {
    path: '**',
    component: ErrorLayoutComponent,
    children: ERROR_ROUTES
  }
];

@NgModule({
  imports: [],
  exports: [RouterModule],
  declarations: []
})
export class RoutingModule {
}
