import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Account } from '../../../model/account.model';
import { ICosts } from '../../../model/costs.model';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from '../base/base.service';
import { ServiceItemService } from '../service-items/service-item.service';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { CostsService } from '../costs/costs.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantCosts } from '../costs/costs.url';
import { ConstantHome } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { takeUntil } from 'rxjs/operators';
import { CostsAddComponent } from '../costs/add/costs-add.component';
import { CostsUpdateComponent } from '../costs/update/costs-update.component';
import { CostsDetails, ICostsDetails } from '../../../model/costs-details.model';
import { CostsDetailsService } from './costs-details.service';
import { ConstantCostsDetails } from './costs-details.url';
import { CostsDetailsAddComponent } from './add/costs-details-add.component';
import { CostsDetailsUpdateComponent } from './update/costs-details-update.component';

/**
 * Gère l'affichage des détails de coût.
 * see {@link ICostsDetails}
 */
@Component({
  selector: 'app-costs-details',
  templateUrl: './costs-details.component.html'
})
export class CostsDetailsComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  costsList: ICostsDetails[]; // utiliser pour afficher la liste des éléments
  costsSearch: ICostsDetails[]; // utiliser pour rechercher parmi liste des éléments.
  searchValue: string;
  initByQueryParam = false;

  serviceItemTyped = '';
  subs$ = new Subject<void>();

  myControl = new UntypedFormControl();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public baseService: BaseService,
    private serviceItemService: ServiceItemService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private costsDetailsService: CostsDetailsService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct costsDetails list');
    this.pageData = {
      title: ConstantCostsDetails.data.sub.list.title,
      key: 'costsDetails.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantCostsDetails.data.title,
          key: 'costsDetails.name',
          route: Url.SLASH_ROUTE.concat(ConstantCostsDetails.urlFull
            .COSTS.LIST)
        },
        {
          title: ConstantCostsDetails.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'costsDetails.title') {
      translate.get('costsDetails.title').subscribe(res => this.pageData.title = res);
    }

    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'costsDetails.name') {
        translate.get('costsDetails.name').subscribe(res => {
          console.log('## res: ', res);
          value.title = res;
        });
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }

    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init costsDetails list');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.account = account;
          // récupération de la liste des détails de coût.
          this.costsDetailsService.query(this.costsDetailsService.typesUrl)
            .subscribe(res => {
              console.log('## Success get costs details list: ', res);
              this.costsList = (res.body) ? res.body : [];
              this.costsSearch = this.costsList;
              super.setLoaded();
              // récupération de la liste des détails de coût.
              this.costsDetailsService.getObjetObs().pipe(takeUntil(this.subs$))
                .subscribe((res: ICosts[]) => {
                  console.log('## get list costs detail in list by subject: ', res);
                  this.costsList = (res) ? res : [];
                  this.costsSearch = this.costsList;
                });
            });
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  add() {
    console.log('## open costs details add');
    this.baseService.openSmH2(this.dialog, CostsDetailsAddComponent);
  }

  update(c: ICostsDetails) {
    console.log('## open costsDetails update');
    this.costsDetailsService.setObjetSelectObs(c);
    this.baseService.openSmH2(this.dialog, CostsDetailsUpdateComponent);
  }

  /**
   * Retourne la liste des des domains
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): ICostsDetails[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  /**
   * Permet de rechercher un coût.
   */
  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.costsSearch].map(mapFn);
    const result = this.costsSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.costsList = [];
    result.forEach((c) => {
      this.costsList.push(c);
    });
  }

  ngOnDestroy() {
    console.log('## on destroy costs list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

}
