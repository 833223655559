import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../animations/form-error';
import { BasePageComponent } from '../../../../base-page';
import { IOption } from '../../../../../ui/interfaces/option';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryColumnName, ICountryColumnName } from '../../../countries/country-column-name';
import { Country, ICountry } from '../../../../../model/country.model';
import { Observable, Subject } from 'rxjs';
import { AccountService } from '../../../../../services/ServiceEntity/account.service';
import { MoniesService } from '../../../monies/monies.service';
import { FormService } from '../../../../../shared/services/form.service';
import { TCModalService } from '../../../../../ui/services/modal/modal.service';
import { CountryService } from '../../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { HttpService } from '../../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../../shared/constant/css-style/css-class.constant';
import { ChampsFormulaireColumnName, IChampsFormulaireColumnName } from '../champs-formulaire-column-name';
import { ChampsFormulaire, LocationType, SourceType, TypeForm } from '../../../../../model/champs_formulaire/champs-formulaire.model';
import { ChampsFormulaireService } from '../champs-formulaire.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IServiceItem } from '../../../../../model/service-item.model';
import { TIME_MIN_REQUEST } from '../../../../../shared/constant/request.contant';
import { ServiceItemService } from '../../../service-items/service-item.service';
import { BaseService } from '../../../base/base.service';
import { LocationTypeModel, TypeInputFormModel } from '../../../../../model/champs_formulaire/type-input-form.model';
import { TypeInputFormService } from '../../type-input-form/type-input-form.service';
import { Formulaire } from '../../../../../model/champs_formulaire/formulaire.model';
import { FormulaireService } from '../../../formulaires/formulaire.service';

/**
 * Permet de gérer la modification des countries.
 */
@Component({
  selector: 'app-formulaire-update',
  templateUrl: './champs-formulaire-update.component.html',
  animations: [fadeIn]
})
export class ChampsFormulaireUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;
  monies: IOption[] = [];
  theDialogRef: MatDialogRef<ChampsFormulaireUpdateComponent>;
  editForm: UntypedFormGroup;
  column: IChampsFormulaireColumnName;
  private countrySelected: ChampsFormulaire;
  private subs$ = new Subject<void>();

  // service item.
  serviceItemsList: Formulaire[] = [];
  filteredOptions: Observable<Formulaire[]>;

  typeForms: IOption[] = [];
  sourceTypes: IOption[] = [];
  locationTypes: IOption[] = [];

  // TypeInputFormModel.
  typeInputFormList: TypeInputFormModel[] = [];
  filteredOptionsInput: Observable<TypeInputFormModel[]>;

  constructor(
    public baseService: BaseService,
    private champsFormulaireService: ChampsFormulaireService,
    private serviceItemService: ServiceItemService,
    private typeInputFormService: TypeInputFormService,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<ChampsFormulaireUpdateComponent>,
    private moniesService: MoniesService,
    private formService: FormService,
    private columnName: ChampsFormulaireColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private formulaireService: FormulaireService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct formulaire liste');
    this.theDialogRef = this.dialogRef;
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init formulaire liste');
    this.accountService.identity().pipe(takeUntil(this.subs$)).subscribe(res => {
      this.champsFormulaireService.getObjetSelectObs()
        .pipe(takeUntil(this.subs$)).subscribe((res: ChampsFormulaire) => {
        console.log('## get formulaire selected to liste: ', res);

        this.getServiceItemList(); // after 500ms.
        this.getTypeInputFormList(); // after 500ms.
        for(let item in TypeForm) {
          if(isNaN(Number(item))) {
            this.typeForms.push({
              ...new Option(),
              label: item?.toLocaleLowerCase(),
              value: item?.toString()
            })
          }
        }
        for(let item in SourceType) {
          if(isNaN(Number(item))) {
            this.sourceTypes.push({
              ...new Option(),
              label: item?.toLocaleLowerCase(),
              value: item?.toString()
            })
          }
        }

        // récupérer location type.
        this.champsFormulaireService.query(this.champsFormulaireService
          .resourceLocationUrl)
          .subscribe(dataLocationType => {
            console.log('## success get list location type: ', dataLocationType);
            const locationTypes: LocationTypeModel[] = dataLocationType
            && dataLocationType.body
              ? dataLocationType.body : [];
            for(const l of locationTypes) {
              this.locationTypes.push({
                ...new Option(),
                label: l.label,
                value: l.id.toString()
              })
            }


            try {
              console.log('## data: ', res);
              this.countrySelected = res;
              this.editForm.patchValue({
                [this.column.id]: res.id,
                [this.column.label]: res.label,
                [this.column.type]: res.type,
                [this.column.locationType]: res.locationType?.id.toString(),
                [this.column.formsOrder]: res.formsOrder,
                [this.column.typeInputForm]: res.typeInputForm,
                [this.column.keyJson]: res.keyJson,
                [this.column.sourceType]: res.sourceType,
                [this.column.valeurType]: res.valeurType,
                [this.column.comment]: res.comment,
                [this.column.placeholder]: res.placeholder,
                [this.column.formulaire]: res.formulaire
              })
            }catch (e) {
              console.log('## error get country selected: ' + e.message);
            }

          }, error => {
            console.log('## error get list location type');
          });
      });
    })
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy country liste');
    this.successSave = false;
    this.formService.free(this.subs$);
    this.formulaireService.setObjetSelectObs(undefined);
  }

  displayFnFormulaire(subject: Formulaire) {
    return subject ? subject.titre : '';
  }

  displayFnTypeInput(subject: TypeInputFormModel) {
    return subject ? subject.type : '';
  }

  getTypeInputFormList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.typeInputFormService
        .query(this.typeInputFormService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<TypeInputFormModel[]>) => mayBeOk.ok),
          map((response: HttpResponse<TypeInputFormModel[]>) => response.body)
        )
        .subscribe((res: TypeInputFormModel[]) => {
            console.log('## success get list TypeInputFormModel while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.typeInputFormList = rs;
            this.filteredOptionsInput = this.editForm.get(this.column.typeInputForm).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filterInput(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list TypeInputFormModel while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  private _filterInput(value: string): TypeInputFormModel[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.typeInputFormList.filter(option => option.type.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter TypeInputFormModel: ' + e.message);
    }
    return filteredList;
  }

  getServiceItemList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.formulaireService
        .query(this.formulaireService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<Formulaire[]>) => mayBeOk.ok),
          map((response: HttpResponse<Formulaire[]>) => response.body)
        )
        .subscribe((res: Formulaire[]) => {
            console.log('## success get list service item while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.serviceItemsList = rs;
            this.filteredOptions = this.editForm.get(this.column.formulaire).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list service item while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  private _filter(value: string): Formulaire[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option.titre?.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter champs formulaire: ' + e.message);
    }
    return filteredList;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  initForm(fb: UntypedFormBuilder, column: IChampsFormulaireColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.id]: [''],
        [column.label]: ['', [Validators.required]],
        [column.type]: ['', [Validators.required]],
        [column.typeInputForm]: ['', [Validators.required]],
        [column.keyJson]: ['', [Validators.required]],
        [column.formsOrder]: ['', [Validators.required]],
        [column.locationType]: ['', [Validators.required]],
        [column.sourceType]: ['', [Validators.required]],
        [column.valeurType]: [''],
        [column.comment]: [''],
        [column.placeholder]: [''],
        [column.formulaire]: ['', [Validators.required]]
      })
    }
  }

  /**
   * Enregistre un type de account ressource
   */
  save() {
    console.log('## liste country');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: ChampsFormulaire = this.createFromForm();
    console.log('## c: ', c);

    this.champsFormulaireService.update(c, this.champsFormulaireService.resourceUrl)
      .subscribe(res => {
        console.log('## success update champs formulaire');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.markAsPristine();
        // this.editForm.reset();
        this.successSave = true;
          this.champsFormulaireService.getAllObjet();
      },
      err => {
        console.log('## error update champs formulaire: ', err);
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300)
  }

  private createFromForm(): ChampsFormulaire {
    return {
      ...new ChampsFormulaire(),
      id: this.editForm.get([this.column.id]).value,
      label: this.editForm.get([this.column.label]).value,
      type: this.editForm.get([this.column.type]).value,
      typeInputForm: this.editForm.get([this.column.typeInputForm]).value,
      typeInputFormId: this.editForm.get([this.column.typeInputForm]).value?.id,
      keyJson: this.editForm.get([this.column.keyJson]).value,
      locationType: this.editForm.get([this.column.locationType]).value,
      locationTypeId: +this.editForm.get([this.column.locationType]).value,
      formsOrder: this.editForm.get([this.column.formsOrder]).value,
      sourceType: this.editForm.get([this.column.sourceType]).value,
      valeurType: this.editForm.get([this.column.valeurType]).value,
      comment: this.editForm.get([this.column.comment]).value,
      placeholder: this.editForm.get([this.column.placeholder]).value,
      formulaire: this.editForm.get([this.column.formulaire]).value,
      formulaireId: this.editForm.get([this.column.formulaire])?.value?.id
    };
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

}
