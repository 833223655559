import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { IOption } from '../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChampsFormulaireColumnName, IChampsFormulaireColumnName } from '../../champs_formulaires/champs_formulaires/champs-formulaire-column-name';
import { IServiceItem } from '../../../../model/service-item.model';
import { TypeInputFormModel } from '../../../../model/champs_formulaire/type-input-form.model';
import { ServiceItemService } from '../../service-items/service-item.service';
import { TypeInputFormService } from '../../champs_formulaires/type-input-form/type-input-form.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ChampsFormulaire, LocationType, SourceType, TypeForm } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST, TIME_MIN_X1_REQUEST, TIME_MIN_X2_REQUEST } from '../../../../shared/constant/request.contant';
import { ILinkDomainColumnName, IStatusColumnName, StatusColumnName } from '../status-column-name';
import { DomainColumnName, IDomainColumnName } from '../../domain/domain-column-name';
import { Domain } from '../../../../model/domain.model';
import { StatusModel } from '../../../../model/status.model';
import { DomainService } from '../../domain/domain.service';
import { StatusService } from '../status.service';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-status-add',
  templateUrl: './status-add.component.html',
  animations: [fadeIn]
})
export class StatusAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  domainList: Domain[] = [];
  init = false; // false, si le component n'est pas affiché.

  theDialogRef: MatDialogRef<StatusAddComponent>;
  private subs$ = new Subject<void>();
  editForm: UntypedFormGroup;
  column: IStatusColumnName;
  columnLinkDomain: ILinkDomainColumnName;

  constructor(
    private statusService: StatusService,
    private serviceItemService: ServiceItemService,
    private domainService: DomainService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<StatusAddComponent>,
    private columnName: StatusColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct status add');
    const column = this.columnName.getColumnName();
    this.columnLinkDomain = this.columnName.getColumnNameWhenLinkDomain();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, this.columnLinkDomain);
  }

  ngOnInit(): void {
    console.log('## init status add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
        this.getDomainList();
      });
  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  /**
   * Récupère la liste des domains.
   */
  getDomainList() {
    setTimeout(() => {
      this.domainService
        .query(this.domainService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<Domain[]>) => mayBeOk.ok),
          map((response: HttpResponse<Domain[]>) => response.body)
        )
        .subscribe((res: Domain[]) => {
            console.log('## success get list domains');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.domainList = rs;
            this.init = true;
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list domains: ' + res.message);
          });
    }, TIME_MIN_X1_REQUEST);
  }

  initForm(fb: UntypedFormBuilder, column: ILinkDomainColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.labelStatus]: ['', [Validators.required]],
        [column.descriptionStatus]: [''],
        [column.domainList]: ['']
      })
    }
  }

  getInit() {
    return this.init;
  }

  /**
   * Enregistre un status {@link StatusModel}
   */
  save() {
    console.log('## save status');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    // on affecte plusieurs domains au status à créer.
    let domainList: Domain[] = this.editForm.get([this.columnLinkDomain.domainList]).value;
    let domainIds = []; // liste des ids des domain.
    // récupération des ids des différents domain.
    for(let s of domainList) {
      domainIds.push(s.id);
    }
    const data = {
      [this.columnLinkDomain.domainList]: domainIds,
      [this.columnLinkDomain.labelStatus]: c.label,
      [this.columnLinkDomain.descriptionStatus]: c.description
    };
    this.domainService.update(data, this.domainService.link_domain)
      .subscribe(res => {
          console.log('## success create status');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.statusService.getAllObjet();
        },
        err => {
          console.log('## error create status: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): StatusModel {
    return {
      ...new StatusModel(),
      label: this.editForm.get([this.columnLinkDomain.labelStatus]).value,
      description: this.editForm.get([this.columnLinkDomain.descriptionStatus]).value
    };
  }

  closeModal() {
    console.log('## close formulaire add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}
