import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Account, ROLE, ROLE_NAME } from '../../../../model/account.model';
import { Observable, Subject } from 'rxjs';
import { FormService } from '../../../../shared/services/form.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { ConstantHome } from '../../home/home.url';
import { Url } from '../../../../shared/constant/url.constant';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CONSTANT_ICON } from '../../../../shared/constant/icon.constant';
import { ConstantAccountRessource } from '../acc-ress.url';
import { AccountRessourceService } from '../acc-ress.service';
import {
  ACCOUNT_DISPLAY,
  ACCOUNT_RESSOURCE_TYPE,
  AccountRessActivDTO,
  AccountRessource,
  IAccountRessource
} from '../../../../model/account-ressource.model';
import { IMobileAccount, MobileAccountCash } from '../../../../model/mobile-account.model';
import { fadeIn } from '../../../../animations/form-error';
import { AccRessColumnName } from '../acc-ress-column-name';
import { TIME_MIN_REQUEST, TIME_MIN_X_REQUEST } from '../../../../shared/constant/request.contant';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { AccountRessTypeService } from '../../acc-ress-type/account-ress-type.service';
import { IAccountRessType } from '../../../../model/acc_ress_type.model';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ColorService } from '../../../../shared/services/color.service';
import {
  BEFORE_ICON_RESET,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALIDATION_CLASS,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS,
  CONTAINER_POPUP_CLASS,
  ICON_COLOR_REQUEST_CSS,
  ICON_FONT_REQUEST_CLASS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET,
  REMOVABLE_ICON_REQUEST,
  RESULT_REQUEST_ERROR_CLASS,
  RESULT_REQUEST_SUCCESS_CLASS,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS,
  TITLE_POPUP_CLASS,
  WARNING_COLOR_CSS,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../../../shared/constant/css-style/css-class.constant';
import { SItemAccountRessTypeDTO } from '../../../../model/service-item.model';
import { STATUS_BD_CONSTANT } from '../../../../model/status.model';
import { FeesAddComponent } from '../../fees/add/fees-add.component';
import { ICostsRule } from '../../../../model/costs-rule.model';
import { CostsRuleService } from '../../fees/costs-rule.service';

@Component({
  selector: 'app-acc-ress-list',
  templateUrl: './acc-ress-list.component.html',
  animations: [fadeIn]
})
export class AccRessListComponent extends BasePageComponent implements OnInit,
  OnDestroy, AfterViewChecked {

  isActivating = false;
  successSave = false;
  successUpdateStatus = false;
  serverHasError = false;
  mobileAccountSelected: MobileAccountCash;
  isAdding = false;
  dataAcc: any;

  authority: any;

  readonly ROLE_NONE = 'NONE'; // pas de role.
  readonly ROLE_PARTENER = 'ROLE_PARTNER';
  readonly ROLE_ADMIN = 'ROLE_ADMIN';

  costsRulesList: ICostsRule[] = [];
  costsRulesListSearch: ICostsRule[] = [];

  addingCash = false;
  updatingStatus = false;
  /* the index to determine which account ressource is selected */
  indexCash = -1;
  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  accountRessourceList: AccountRessActivDTO[] = [];
  accountRessourceAllList: AccountRessActivDTO[] = [];
  accountRessTypesList: SItemAccountRessTypeDTO[] = [];
  accLIstFilter: IAccountRessType[] = [];
  newList: IAccountRessType[] = [];
  findAccountRessourceList = false;

  accRessTypeSelected: SItemAccountRessTypeDTO;

  cash = 0;
  filteredAccRessType: Observable<AccountRessActivDTO[]>;
  editForm: UntypedFormGroup;
  myControl = new FormControl();

  subs$ = new Subject<void>();

  constructor(
    protected costsRuleService: CostsRuleService,
    private colorService: ColorService,
    private cdRef: ChangeDetectorRef,
    private accRessColumnName: AccRessColumnName,
    private accountRessTypeService: AccountRessTypeService,
    private formService: FormService,
    private matDialog: MatDialog,
    private dialog: MatDialogRef<AccRessListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    protected accountRessourceService: AccountRessourceService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct account ressource list');
    this.pageData = {
      title: ConstantAccountRessource.data.sub.list.title,
      key: '',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: '',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantAccountRessource.data.title,
          key: '',
          route: Url.SLASH_ROUTE.concat(ConstantAccountRessource.urlFull
            .ACCOUNT_RESSOURCE.LIST)
        },
        {
          title: ConstantAccountRessource.data.sub.lister,
          key: ''
        }
      ]
    };
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init account ressource list');
    // super.ngOnInit();
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          console.log('## success get account:', account);
          try {
            //this.authority = response['authorities'][1];
            if (account['authorities'][1]) {
              this.authority = account['authorities'][1];
            } else {
              this.authority = account['authorities'][0];
            }
          } catch (e) {
            console.log('## error get authorities from account: ', e.message);
          }
          const m: MobileAccountCash = this.data; // the mobile account.
          console.log('## data: ', m);
          this.mobileAccountSelected = m;
          try {
            this.getAccountRessourceList(m.id);
            // this.getAllAccountRessType();
            this.getCostsRuleList();

          } catch (e) {
            console.log('## error get account:' + e.message);
          }
          const c = account;
          console.log('## account: ', c);
          this.account = c;
        } catch (e) {
          console.log('## error identity:', e.message);
        }

      });

  }

  ngOnDestroy() {
    console.log('## on destroy account ressource list list');
    this.formService.free(this.subs$);
  }

  getCostsRuleList() {
    this.costsRuleService.queryList().subscribe(res => {
      console.log('## Success get costs rule list');
      this.costsRulesList = (res.body) ? res.body : [];
      this.costsRulesListSearch = this.costsRulesList;
      console.log('## size: ' + this.costsRulesList.length);
    });
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  addRule() {
    console.log('## open fees add', this.costsRulesList);
    console.log('## mobileAccountSelected: ', this.mobileAccountSelected);
    this.matDialog.open(FeesAddComponent, {
      data: { costsRulesList: this.costsRulesList,
      cUserId: this.mobileAccountSelected?.cUserId,
        accountUserId: this.mobileAccountSelected?.id
      }, height: '700px', width: '800px'
    });
  }

  getAccountDisplay() {
    return ACCOUNT_DISPLAY;
  }

  getAccountRessType() {
    return ACCOUNT_RESSOURCE_TYPE;
  }

  getRole() {
    let role = 'none';
    if (this.canPartenerAccess()) {
      role = ROLE_NAME.partener;
    } else if (this.canAdminAccess()) {
      role = ROLE_NAME.admin;
    } else if (this.canTraderAccess()) {
      role = ROLE_NAME.trader;
    }
    return role;
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  isCancelBtnOutline(): boolean {
    return OUTLINE_CANCEL;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getContainerBtnClass() {
    return CONTAINER_BTN_POPUP_CLASS;
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  getResultRequestTextSuccess(): string {
    return RESULT_REQUEST_TEXT_SUCCESS_CLASS;
  }

  getWarningColor(): string {
    return WARNING_COLOR_CSS;
  }

  getInside2xContainerClass(): string {
    return CONTAINER_INSIDE2X_POPUP_CLASS;
  }

  getInsideContainerClass(): string {
    return CONTAINER_INSIDE_POPUP_CLASS;
  }

  getContainerClass(): string {
    return CONTAINER_POPUP_CLASS;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getIconColorRequestClass(): string {
    return ICON_COLOR_REQUEST_CSS;
  }

  isRemovable(): boolean {
    return REMOVABLE_ICON_REQUEST;
  }

  getIconRequestClass(): string {
    return ICON_FONT_REQUEST_CLASS;
  }

  getResultRequestErrorClass() {
    return RESULT_REQUEST_ERROR_CLASS;
  }

  getResultRequestSuccessClass() {
    return RESULT_REQUEST_SUCCESS_CLASS;
  }

  canPartenerAccess() {
    return this.authority === ROLE.partener;
  }

  canAdminAccess() {
    return this.authority === ROLE.admin;
  }

  canTraderAccess() {
    return this.authority === ROLE.trader;
  }

  getHeaderFormBg() {
    return this.colorService.getHeaderFormBg();
  }

  getTitleHeaderFormColor() {
    return this.colorService.getTitleHeaderFormColor();
  }

  getTitleClass(): string {
    return TITLE_POPUP_CLASS;
  }

  getStatusBackground(statusId: number) {
    return this.colorService.getStatusBg(statusId);
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  getAmountColBg() {
    return this.colorService.getAmountColBg();
  }

  getAccountRessToActivate(event) {
    try {
      const d: SItemAccountRessTypeDTO = event.option.value;
      console.log('## get account: ' + d.name);
      console.log('## get account: ' + d.serviceItemId);
      this.accRessTypeSelected = d;
    } catch (e) {
      console.log('## error when get account ress type: ' + e.message);
    }
  }

  addActRessource() {
    console.log('## is activating');
    this.isActivating = true;
    this.successSave = false;
    const a = this.accRessTypeSelected;
    console.log("## service data: ", a);

    const cUserId = this.mobileAccountSelected?.cUserId;
    const accountRessource = {
      'serviceItemId': a?.id,
      'cUserId': cUserId
    };

    this.accountRessourceService.activateService(accountRessource).subscribe(response => {
      console.log('## success activated account ressource: ' + a.type
        + ' with id: ' + a.id);
      this.successSave = true;
      this.myControl.reset();
      this.accRessTypeSelected = undefined;
      this.userCanResponse();
      this.getAccountRessourceListAsync(this.mobileAccountSelected?.id);
      this.getAccountRessourceListTypeAsync();


    }, error => {
      console.log('## error activated account ressource: '
        + a.type + ' with id: ' + a.id);
      this.successSave = false;
      this.userCanResponse();
    });
  }


  canUserAccess() {
    let result = false;
    try {
      let role;
      role = (this.account.authorities.indexOf(this.ROLE_ADMIN) !== -1)
        ? ROLE.admin : role;
      result = (role === ROLE.admin) ? true : result;
    } catch (e) {
      console.log('## error get role: ' + e.message);
    }
    return result;
  }

  displayFn(subject: SItemAccountRessTypeDTO) {
    return subject ? subject.name : '';
  }

  private _filter(value: string): SItemAccountRessTypeDTO[] {
    this.accRessTypeSelected = undefined;
    let filteredList;
    this.accountRessourceList = [];
    console.log('## value:', value);
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.accountRessTypesList
        .filter(
          option => {
            if(option && option.name && option.name.toLowerCase()
              .includes(filterValue) || (option && !option.name)) {
              this.accountRessourceList.push(option);
              return true;
            }
            return false;
          }

        );
    } catch (e) {
      console.log('## error filter acc ress type: ' + e.message);
    }
    return filteredList;
  }

  getAllAccountRessType() {
    let p = new HttpParams();
    console.log('## mobile account: ', this.mobileAccountSelected);
    p = p.set('cUserId', '' + this.mobileAccountSelected?.cUserId);
    this.accountRessTypeService.listServiceNonPermisByCUserId(p)
      .subscribe((res) => {
        console.log('## success get all account ress type: ', res.body);
        const l = res ? res.body : [];
        console.log('## size: ' + l.length);
        // On récupère la valeur display 1.
        let newList = [];
        newList = l.filter(e => e.accountRessTypeDisplay === this.getAccountDisplay().V1)
        this.accountRessTypesList = newList;
        this.filteredAccRessType = undefined;
        this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value =>
              this._filter(value)
            )
          ).subscribe(r => {
            console.log('## search');
          });

        console.log('filterAccResTypeeeeee: ', this.filteredAccRessType);
      }, err => {
        console.log('## error get all account ress type: ' + err.message);
      });
  }

  /**
   * Permet de modifier le status du accountRessource
   * d'un point cash.
   * @param ar
   */
  setActive(ar: AccountRessActivDTO) {
    console.log('## liste status account ressource');
    this.initMsgError();
    try {
      const id = ar.acountRessourceId;
      console.log('## id: ' + id);
      let status = ar.accountRessourceStatusId;
      const actived = STATUS_BD_CONSTANT.actived;
      status = (status === actived) ? STATUS_BD_CONSTANT.deactived : actived;
      const accRessource = { 'id': id, 'statusId': status };
      this.accountRessourceService.updateStatus(accRessource).subscribe(response => {
        console.log('## success liste status with account ressource: ', id);
        this.successUpdateStatus = true;
        this.accountRessourceService.findAllAccountRessourceByMAccountId(
          this.mobileAccountSelected.id)
          .subscribe((res) => {
            this.getResult(res);
            console.log('## ouf');
            this.userCanResponse();
          }, err => {
            console.log('## error get account ressource list: ' + err.message);
          });
      }, error => {
        console.log('## error liste status with account ressource: ' + id);
        this.successUpdateStatus = false;
        this.userCanResponse();
      });
    } catch (e) {
      console.log('## error liste status account ressoure: ' + e.message);
      this.successUpdateStatus = false;
      this.userCanResponse();
    }
  }

  format(n: number) {
    return this.formService.format(n);
  }

  getActived(): number {
    return STATUS_BD_CONSTANT.actived;
  }

  getDeactived(): number {
    return STATUS_BD_CONSTANT.deactived;
  }

  test(event) {
    console.log('## key presse');
    console.log('## key: ' + event);
  }

  /**
   *
   * @param s si true, affiche un message de
   * succès sinon n'affiche pas.
   */
  userCanResponse() {
    console.log('## user can response');
    this.userCanUpdateStatus();
    this.userCanActivateAccount();
    this.userCanRequest();
  }

  userCanActivateAccount() {
    setTimeout(() => {
      this.isActivating = false;
    }, TIME_MIN_REQUEST);
  }

  userCanUpdateStatus() {
    setTimeout(() => {
      this.updatingStatus = false;
    }, TIME_MIN_REQUEST);
  }

  private getResult(res: HttpResponse<IAccountRessource[]>) {
    console.log('## Success get account ressource list: ', res.body);
    const e = (res.body) ? res.body : [];
    console.log('## size: ' + e.length);
    this.accountRessourceList = e;
    this.accountRessourceAllList = e;
    this.findAccountRessourceList = true;

    this.accountRessTypesList = this.accountRessourceList;
    this.filteredAccRessType = undefined;
    this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value =>
          this._filter(value)
        )
      ).subscribe( r => {
        console.log('## search');
    });
  }


  /**
   * Find all account ressource
   * @param id the id of the account user.
   */
  getAccountRessourceList(id: number) {
    this.accountRessourceService.findAllAccountRessourceByMAccountId(id)
      .subscribe((res) => {
        this.getResult(res);
      }, err => {
        console.log('## error get account ressource list: ' + err.message);
      });
  }


  initCash() {
    console.log('## init cash');
    this.initForm();
  }

  isAuthorized() {
    const ac = this.account;
    if (!ac) {
      console.log('## account not exist');
      return false;
    }
    return this.getAuthoritie(ac) === this.ROLE_ADMIN
      || this.getAuthoritie(ac) === this.ROLE_PARTENER;
  }

  getAuthoritie(account: Account) {
    try {
      if (account.authorities.indexOf(this.ROLE_ADMIN) !== -1) {
        return this.ROLE_ADMIN;
      } else if (account.authorities.indexOf(this.ROLE_PARTENER) !== -1) {
        return this.ROLE_PARTENER;
      }
    } catch (e) {
      console.log('## error getAuthoritie: ' + e.message);
      return false;
    }
    return this.ROLE_NONE;
  }

  /**
   * si true, le montant est affiché sinon
   * le formulaire d'ajout.
   * @param i the index to determine which
   * account ressource is selected.
   */
  shouldIShowInputCash(i: number) {
    return this.addingCash && (this.indexCash === i);
  }

  /**
   * @param id the mobile account id.
   */
  async getAccountRessourceListAsync(id: number) {
    setTimeout(() => {
      this.getAccountRessourceList(id);
    }, TIME_MIN_X_REQUEST);
  }

  async getAccountRessourceListTypeAsync() {
    setTimeout(() => {
      this.getAllAccountRessType();
    }, TIME_MIN_X_REQUEST);
  }

  private initMsgError() {
    console.log('## init message error');
    this.serverHasError = false;
    this.successSave = false;
    this.isAdding = true;
    this.successUpdateStatus = false;
    this.updatingStatus = true;
  }

  addCash(d: AccountRessActivDTO) {
    console.log('## add cash (id): ' + d.id);
    console.log('## type: ' + d.type);
    console.log('## montant: ' + this.cash);
    this.initMsgError();
    const id = this.mobileAccountSelected.id;
    const role = this.getAuthoritie(this.account);
    if (role === this.ROLE_PARTENER) {
      console.log('## add cash by partener');
      const ac = new AccountRessource();
      ac.id = d.acountRessourceId;
      ac.amount = this.cash;
      ac.mobileAccountId = d.acountUserId;
      ac.accountRessTypeId = d.accountRessTypeId;
      ac.status = d.accountRessourceStatusLabel;
      this.accountRessourceService.updateByPartener(ac).subscribe(
        response => {
          console.log('## success add cash by partener');
          this.serverHasError = false;
          this.showMessageResult(true); // executed after 500ms.
          this.userCanResponse(); // executed after 500ms.
          this.initForm();
          this.cash = 0;
          this.getAccountRessourceList(id);
        },
        e => {
          console.log('## error add cash account ressource by partener: '
            + e.message);
          this.serverHasError = true;
          this.showMessageResult(false); // executed after 500ms.
          this.userCanResponse(); // executed after 500ms.
        }
      );
    } else if (role === this.ROLE_ADMIN) {
      console.log('## add cash by admin');
      const ac = new AccountRessource();
      ac.id = d.acountRessourceId;
      ac.amount = this.cash;
      ac.mobileAccountId = d.acountUserId;
      ac.accountRessTypeId = d.accountRessTypeId;
      ac.status = d.accountRessourceStatusLabel;
      this.accountRessourceService.update(ac).subscribe(
        response => {
          console.log('## success add cash by admin');
          this.serverHasError = false;
          this.showMessageResult(true); // executed after 500ms.
          this.userCanResponse(); // after 500ms.
          this.initForm();
          this.cash = 0;
          this.getAccountRessourceListAsync(id);
        },
        e => {
          console.log('## error add cash account ressource: ' + e.message);
          this.serverHasError = true;
          this.showMessageResult(false); // executed after 500ms.
          this.userCanResponse(); // executed after 500ms.
        }
      );
    }
  }

  showMessageResult(r: boolean) {
    setTimeout(() => {
      this.successSave = r;
    }, TIME_MIN_REQUEST);
  }

  userCanRequest() {
    setTimeout(() => {
      this.isAdding = false;
    }, TIME_MIN_REQUEST);
  }

  cancelAddCash() {
    console.log('## cancel adding cash: ' + this.addingCash);
    this.initForm();
  }

  private initForm() {
    this.addingCash = false;
    this.indexCash = -1;
  }

  /**
   * si true, le montant est affiché sinon
   * le formulaire d'ajout.
   * @param i the index to determine which
   * account ressource is selected.
   */
  shouldIShowAmount(i: number) {
    return (!this.addingCash) || (this.indexCash !== i);
  }

  wantToAddCash(i: number) {
    this.cash = 0;
    this.addingCash = true;
    this.indexCash = i;
  }

  dontWantToAddCash() {
    this.addingCash = false;
  }

  hasFound(): boolean {
    return this.findAccountRessourceList;
  }

  exchangeRateIsEmpty() {
    return this.accountRessourceList.length <= 0 || !this.accountRessourceList;
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    /*console.log('## open account ressource list add');
    this.dialog.open(ExchangeRateAddComponent, {
      height: '700px',
      width: '800px'
    });*/
  }

  show(c: IAccountRessource) {
    /*console.log('## open service show');
    this.benefitService.setServiceItemSelectObs(c);
    this.dialog.open(MoniesShowComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  update(c: IAccountRessource) {
    /*this.exchangeRateService.setExchangeRateSelectObs(c);
    this.dialog.open(ExchangeUpdateComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  delete(c: IAccountRessource) {
    /*this.benefitService.setServiceItemSelectObs(c);
    this.dialog.open(ServiceItemDeleteComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  /**
   * Retourne la liste des services items
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IAccountRessource[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

}
