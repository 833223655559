import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { CostsComponent } from '../costs/costs.component';
import { CostsAddComponent } from '../costs/add/costs-add.component';
import { CostsUpdateComponent } from '../costs/update/costs-update.component';
import { CostsDetailsComponent } from './costs-details.component';
import { CostsDetailsAddComponent } from './add/costs-details-add.component';
import { CostsDetailsUpdateComponent } from './update/costs-details-update.component';

/**
 * Ce module permet de gérer les détails de coûts.
 */
@NgModule({
  imports: [SharedModule],
  declarations: [CostsDetailsComponent, CostsDetailsUpdateComponent,
    CostsDetailsAddComponent]
})
export class CostsDetailsModule {}