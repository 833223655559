import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../animations/form-error';
import { BasePageComponent } from '../../../../base-page';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IOption } from '../../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IValidatorColumnName, ValidatorColumnName } from '../validator-column-name';
import { ValidatorService } from '../validator.service';
import { BaseService } from '../../../base/base.service';
import { AccountService } from '../../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../../shared/services/form.service';
import { TCModalService } from '../../../../../ui/services/modal/modal.service';
import { CountryService } from '../../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { HttpService } from '../../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { ICountry } from '../../../../../model/country.model';
import { Validators as ValidatorsModel } from '../../../../../model/champs_formulaire/validators.model';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-validator-liste',
  templateUrl: './validator-update.component.html',
  animations: [fadeIn]
})
export class ValidatorUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ValidatorUpdateComponent>;
  private subs$ = new Subject<void>();
  monies: IOption[] = [];
  editForm: UntypedFormGroup;
  column: IValidatorColumnName;
  anotherMoney: boolean;

  private countrySelected: ValidatorsModel;

  constructor(
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ValidatorUpdateComponent>,
    private columnName: ValidatorColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private validatorService: ValidatorService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct validator liste');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init validator liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
        this.validatorService.getObjetSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: ValidatorsModel) => {
          console.log('## get validator selected to liste');
          try {
            console.log('## data: ', res);
            this.countrySelected = res;
            this.editForm.patchValue({
              [this.column.id]: res.id,
              [this.column.type]: res.type,
            })
          }catch (e) {
            console.log('## error get validator selected: ' + e.message);
          }
        });
      });
  }

  ngOnDestroy() {
    console.log('## destroy validator liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }


  initForm(fb: UntypedFormBuilder, column: IValidatorColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.id]: [],
        [column.type]: [
          '',
          [Validators.required]
        ]
      })
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## liste validator');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.validatorService.update(c, this.validatorService.resourceUrl).subscribe(res => {
        console.log('## success create validator');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.validatorService.getAllObjet();
      },
      err => {
        console.log('## error create validator');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): ValidatorsModel {
    return {
      ...new ValidatorsModel(),
      id: this.editForm.get([this.column.id]).value,
      type: this.editForm.get([this.column.type]).value
    };
  }

  closeModal() {
    console.log('## close validator liste');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}