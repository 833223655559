import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { IOption } from '../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChampsFormulaireColumnName, IChampsFormulaireColumnName } from '../../champs_formulaires/champs_formulaires/champs-formulaire-column-name';
import { IServiceItem } from '../../../../model/service-item.model';
import { TypeInputFormModel } from '../../../../model/champs_formulaire/type-input-form.model';
import { ServiceItemService } from '../../service-items/service-item.service';
import { TypeInputFormService } from '../../champs_formulaires/type-input-form/type-input-form.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ChampsFormulaire, LocationType, SourceType, TypeForm } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';
import { DomainService } from '../domain.service';
import { DomainColumnName, IDomainColumnName } from '../domain-column-name';
import { Domain } from '../../../../model/domain.model';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-domain-add',
  templateUrl: './domain-add.component.html',
  animations: [fadeIn]
})
export class DomainAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<DomainAddComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;
  column: IDomainColumnName;

  constructor(
    private domainService: DomainService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<DomainAddComponent>,
    private columnName: DomainColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct domain add');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init domain add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy domain add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  initForm(fb: UntypedFormBuilder, column: IDomainColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.label]: ['', [Validators.required]]
      })
    }
  }

  /**
   * Enregistre un domain.
   */
  save() {
    console.log('## save domain');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.domainService.create(c, this.domainService.resourceUrl)
      .subscribe(res => {
          console.log('## success create domain');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.domainService.getAllObjet();
        },
        err => {
          console.log('## error create domain: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): Domain {
    return {
      ...new Domain(),
      label: this.editForm.get([this.column.label]).value
    };
  }

  closeModal() {
    console.log('## close domain add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}
