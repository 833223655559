import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from '../../../base-page';
import { IAccountRessType } from '../../../../model/acc_ress_type.model';
import { AccountRessTypeService } from '../account-ress-type.service';
import * as PageActions from '../../../../store/actions/page.actions';
import { ConstantHome } from '../../home/home.url';
import { ConstantAccRessType } from '../acc-ress-type.url';
import { Url } from '../../../../shared/constant/url.constant';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { AccountsRessourceTypeAddComponent } from '../add/acc-ress-type-add.component';
import { Content } from '../../../../ui/interfaces/modal';
import { CONSTANT_ICON } from '../../../../shared/constant/icon.constant';
import { UntypedFormGroup } from '@angular/forms';
import { AccRessTypeColumnName } from '../acc-ress-type-column-name';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormService } from '../../../../shared/services/form.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Permet de gérer l'affichage des types de account ressource.
 */
@Component({
  selector: 'app-accounts-ressource-type',
  templateUrl: './acc-ress-type-list.component.html',
  styleUrls: ['./acc-ress-type-list.component.css']
})
export class AccountsRessourceTypeComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  accRessTypeList: IAccountRessType[];
  @ViewChild('basicTable') tabRef: any;
  accRessTypeIsOpen = false;

  private subs$ = new Subject<void>();
  accRessTypeUpdateRef: any;
  accRessTypeAddRef: any;
  accRessTypeShowRef: any;
  dataSelect: IAccountRessType;
  updateForm: UntypedFormGroup;

  constructor(
    private formService: FormService,
    private accountService: AccountService,
    private modal: TCModalService,
    private columnName: AccRessTypeColumnName,
    private accountRessTypeService: AccountRessTypeService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct account ressource type list');
    console.log('this.pageData before##', this.pageData);
    this.pageData = {
      title: '',
      key: 'listAccRessType',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: '',
          key: 'accRessType',
          route: Url.SLASH_ROUTE.concat(ConstantAccRessType.urlFull.ACCOUNT_RESSOURCE_TYPE.LIST)
        },
        {
          title: '',
          key: 'lister'
        }
      ]
    };
    if(this.pageData.key==="listAccRessType"){
      translate.get('listAccRessType').subscribe(res => this.pageData.title=res);
    }
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="accRessType"){
        translate.get('accRessType').subscribe(res => value.title=res);
      }
      if( value.key==="exchangeRate" ){
        translate.get('exchangeRate').subscribe(res => value.title=res);
      }
      if( value.key==="lister" ){
        translate.get('lister').subscribe(res => value.title=res);
      }
     
     
    }); 
    console.log('this.pageData  after##', this.pageData);
    this.accRessTypeList = [];
  }

  ngOnInit(): void {
    console.log('## init account ressource type liste');
    this.accountService.identity(false)
      .pipe(takeUntil(this.subs$)).subscribe(account => {
      // Récupération de tous les types de account ressources.
      this.accountRessTypeService.query().subscribe(res => {
        console.log('## Success get account_ress_type list');
        this.accRessTypeList = (res.body) ? res.body : [];
        super.setLoaded();
      });
    })

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  getUpdateForm(event: UntypedFormGroup) {
    if(event) {
      this.updateForm = event;
    }
  }

 /* getUpdateForm(event: FormGroup, d: IAccountRessType) {
    if(event && d) {
      event.patchValue({
        id: d.id,
        type: d.type
      })
    }
  }*/

  update(d: IAccountRessType) {
    console.log('## data: ' + JSON.stringify(d));
    console.log('## open account ress type liste');
    this.dataSelect = d;
    const form = this.updateForm;
    if(form && d) {
      form.patchValue({
        id: d.id,
        type: d.type
      })
      form.get(this.columnName.getColumnName().type).markAsPristine();
    }
    const ref = this.accRessTypeUpdateRef;
    if(ref)  {
      console.log('## open liste');
      this.modal.open({
        body: ref,
        header: '',
        footer: null,
        options: null
      });
    }
  }

  show(d: IAccountRessType) {
    console.log('## data: ' + JSON.stringify(d));
    console.log('## open account ress type show');
    this.dataSelect = d;
    const ref = this.accRessTypeShowRef;
    if(ref)  {
      console.log('## open show');
      this.modal.open({
        body: ref,
        header: '',
        footer: null,
        options: null
      });
    }
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  getList(list: IAccountRessType[]) {
    if(list && list.length != 0) {
      this.accRessTypeList = list;
    }
  }

  /**
   * Récupère le template html AccountsRessourceTypeAddComponent
   * @param event le template.
   */
  getRefShow(event) {
    if(event) {
      this.accRessTypeShowRef = event;
    }
  }

  /**
   * Récupère le template html AccountsRessourceTypeAddComponent
   * @param event le template.
   */
  getRefUpdate(event) {
    if(event) {
      this.accRessTypeUpdateRef = event;
    }
  }

  /**
   * Récupère le template html AccountsRessourceTypeAddComponent
   * @param event le template.
   */
  getRefAdd(event) {
    if(event) {
      this.accRessTypeAddRef = event;
    }
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    console.log('## open account ress type add');
    const ref = this.accRessTypeAddRef;
    if(ref)  {
      console.log('## open');
      this.modal.open({
        body: ref,
        header: '',
        footer: null,
        options: null
      });
    }
  }

  /**
   * Retourne la liste des types de account ressource
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getAccountRessTypeList(): IAccountRessType[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }
}
