import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../animations/form-error';
import { BasePageComponent } from '../../../../base-page';
import { Validators as ValidatorsModel } from '../../../../../model/champs_formulaire/validators.model';
import { Subject } from 'rxjs';
import { BaseService } from '../../../base/base.service';
import { ValidatorService } from '../../validators/validator.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../../../../shared/services/form.service';
import { ValidatorColumnName } from '../../validators/validator-column-name';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { HttpService } from '../../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { ChampsFormulaire } from '../../../../../model/champs_formulaire/champs-formulaire.model';
import { ChampsFormulaireService } from '../champs-formulaire.service';
import { ChampsFormulaireColumnName } from '../champs-formulaire-column-name';

/**
 * Permet de gérer la suppression d'un country
 */
@Component({
  selector: 'app-champs-formulaire-delete',
  templateUrl: './champs-formulaire-delete.component.html',
  animations: [fadeIn]
})
export class ChampsFormulaireDeleteComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  countrySelected: ChampsFormulaire;
  private subs$ = new Subject<void>();
  successDelete = false;
  serverHasError = false;
  hasDelete = false;
  isDeleting = false;

  constructor(
    private baseService: BaseService,
    private formulaireService: ChampsFormulaireService,
    private dialogRef: MatDialogRef<ChampsFormulaireDeleteComponent>,
    private formService: FormService,
    private columnName: ChampsFormulaireColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct validator delete');
  }

  ngOnInit(): void {
    console.log('## init formulaire delete.');
    this.formulaireService.getObjetSelectObs()
      .pipe(takeUntil(this.subs$)).subscribe((res) => {
      console.log('## get formulaire selected');
      try {
        console.log('## data: ', res);
        this.countrySelected = res;
      } catch (e) {
        console.log('## error get formulaire delete: ' + e.message);
      }
      super.setLoaded();
    });
  }

  ngOnDestroy() {
    console.log('## destroy formulaire delete');
    this.formService.free(this.subs$);
    this.formulaireService.setObjetSelectObs(undefined);
  }

  closeModal() {
    console.log('## close delete formulaire');
    this.dialogRef.close();
  }

  delete() {
    console.log('## deleting formulaire');
    this.isDeleting = true;
    this.serverHasError = false;
    this.successDelete = false;
    this.formulaireService.delete(this.countrySelected.id,
      this.formulaireService.resourceUrl).subscribe(
      response => {
        console.log('## success delete country');
        this.serverHasError = false;
        this.successDelete = true;
        this.isDeleting = false;
        this.hasDelete = true;
        this.responseFromServer();
        this.formulaireService.getAllObjet();
      }, error => {
        console.log('## error delete country');
        this.serverHasError = true;
        this.successDelete = false;
        this.isDeleting = false;
        this.responseFromServer();
      });
  }

  private responseFromServer() {
    setTimeout(() => {
      this.isDeleting = false;
    }, 300);
  }
}
