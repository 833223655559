import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IApiKeyDTO } from '../../../../model/api-key.model';
import { Subject } from 'rxjs';
import { IpAdressService } from '../ip-adress.service';
import { ApiKeyService } from '../api-key.service';
import { CUserApiKeyService } from '../../../../services/ServiceEntity/c-user-api-key.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { DisplayValue } from '../../../../model/acc_ress_type.model';
import { ServiceItemService } from '../../service-items/service-item.service';
import { ServiceItemCompose, ServiceItemFils } from '../../../../model/service-item.model';
import { CashDataModel, IRetraitCode } from '../../cash/cash-data-model';
import { DynamicFormComponent } from '../../cash/dynamic-form/dynamic-form.component';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { CONTAINER_DIALOG_POPUP } from '../../../../shared/constant/css-style/css-class.constant';

/**
 * Permet de gérer les services.
 */
@Component({
  selector: 'app-api-key-service',
  templateUrl: './api-key-service.component.html',
  animations: [fadeIn]
})
export class ApiKeyServiceComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ApiKeyServiceComponent>;
  editForm: UntypedFormGroup;
  idAccount: number;
  numberAccount: string;
  currency: string;
  apiKey: IApiKeyDTO;

  serviceItemComposeList: ServiceItemCompose[] = [];

  private subs$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private serviceItemService: ServiceItemService,
    private ipAdressService: IpAdressService,
    private apiKeyService: ApiKeyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domainService: CUserApiKeyService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ApiKeyServiceComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct api key service');
    this.theDialogRef = this.dialogRef;
    // console.log('## idAccount: ', this.data.idAccount);
    this.apiKey = this.data.apiKey;
    this.numberAccount = this.data.numberAccount;
    this.currency = this.data.currency;
   // this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init api key service');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        // récupèration des services item.
        this.serviceItemService.findAllActiveApiKeyServices(this.apiKey.id)
          .subscribe(res => {
          console.log('## success get all services composes by api key: ', res);
          const data = res.body;
          for (let d of data) {
            if (d.parent.serviceId && d.parent.display === DisplayValue.ONE) {
              this.serviceItemComposeList.push(d);
            }
          }
        }, err => {
          console.log('## error get all services actifs: ', err);
        });
      });
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy api key service');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  closeModal() {
    console.log('## close delete country');
    this.dialogRef.close();
  }

  /**
   * Ouvre le component d'envoie code.
   * @param r informations du service parent.
   * @param fs liste des services fils du parent
   * @param serviceName
   */
  openTemplate(fs: ServiceItemFils[], r?: IRetraitCode, serviceName?: string) {
    console.log('## fils: ', fs);
    console.log('## parent: ', r.idServiceItem);
    let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    let idServiceItem;
    let serviceItemId;
    let f = fs.slice();
    // on vérifie si le parent n'a pas de fils.
    if (typeof f === 'undefined' || f.length <= 0) {
      idServiceItem = r.idServiceItem;
      serviceItemId = r.serviceItemId;
    } else {
      const f1 = f[0];
      idServiceItem = f1.serviceId;
      serviceItemId = f1.serviceItemId;
    }
    d.dataCode.idServiceItem = idServiceItem;
    d.dataCode.serviceItemId = serviceItemId;
    d.dataCode.serviceName = serviceName;
    d.dataCode.serviceItemFilsList = f;
    d.dataCode.serviceItemIdInter = serviceItemId;
    d.dataCode.apiKey = this.apiKey?.keyValue;
    console.log('## api key: ', d.dataCode.apiKey);
    this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        numberAccount: this.numberAccount,
        currency: this.currency,
        others: r,
        cashDataModel: d
      }
    });
  }
}