/**
 * état que peuvent prendre différentes tables.
 */
import { Domain } from './domain.model';

export const STATUS_BD_CONSTANT = {
  new_user: 1,
  actived: 2,
  deactived: 3,
  blocked: 4,
  successful: 5,
  pending: 6,
  failed: 7,
  in_progess: 8,
  removed: 9,
  approved: 11,
  rejected: 12
}

export class StatusModel {
  constructor(
    public id?: number,
    public label?: string,
    public description?: string
  ) {}
}

/**
 * état que peuvent prendre différentes tables.
 */
export class StatusDomainModel {
  constructor(
    public id?: number,
    public label?: string,
    public description?: string,
    public statusDomainId?: number,
    public domain?: Domain
  ) {}
}