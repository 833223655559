import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { BASE_URL, BASE_URL_ADMIN } from '../../../../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { IApikey, IApiKeyCDTO } from '../../../model/api-key.model';
import { Observable } from 'rxjs';
import { IIpAdressDTO, IIpAdressDTOS, IpAdressApiKeyDTOS, IpAdressDTOS } from '../../../model/ipAdress.model';

type EntityArrayResponseType = HttpResponse<IIpAdressDTOS[]>;

@Injectable({ providedIn: 'root' })
export class IpAdressService extends ApiService {

  public resourceUrlStatus = BASE_URL + 'ip-address/status';
  public resourceUrlAddOne = BASE_URL + 'ip-address';
  public resourceUrlAdd = BASE_URL + 'ip-address/list';
  public resourceUrlList = BASE_URL + 'ip-addresses';

  private _eventEmitter: EventEmitter<string>;

  constructor(protected http: HttpClient) {
    super(http);
  }

  get eventEmitter(): EventEmitter<string> {
    return this._eventEmitter;
  }

  set eventEmitter(value: EventEmitter<string>) {
    this._eventEmitter = value;
  }

  delete(ipAddressId: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrlAddOne}/${ipAddressId}`,
      { observe: 'response' });
  }

  updateStatus(a: any): Observable<any> {
    return this.http.put<any>(this.resourceUrlStatus, a,
      { observe: 'response' });
  }

  list(p: HttpParams): Observable<any> {
    return this.http.get<IpAdressApiKeyDTOS[]>(this.resourceUrlList,
      { params: p, observe: 'response' });
  }

  updateOne(a: IIpAdressDTO): Observable<any> {
    return this.http.put<any>(this.resourceUrlAddOne, a,
      { observe: 'response' });
  }

  createOne(a: IIpAdressDTO): Observable<any> {
    return this.http.post<any>(this.resourceUrlAddOne, a,
      { observe: 'response' });
  }

  create(a: IIpAdressDTOS): Observable<any> {
    return this.http.post<any>(this.resourceUrlAdd, a,
      { observe: 'response' });
  }
}