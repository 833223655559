import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { BASE_URL, BASE_URL_ADMIN } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { INotificationDTO } from '../../../model/notification.model';
import { StatusDomainModel } from '../../../model/status.model';

@Injectable({ providedIn: 'root' })
export class StatusService extends ApiService {
  // permet la gestion des status. voir {@link StatusModel}.
  public list_not_affected = BASE_URL_ADMIN + '/status/all/not-affected';
  public list_by_domain_Url = BASE_URL_ADMIN + '/status/all';
  public resourceUrl = BASE_URL_ADMIN + '/status';

  // liste les status
  public list_url = BASE_URL_ADMIN + '/status';
  public list_all_url = BASE_URL + 'status/all';

  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * Permet de lister les status par domaine
   * {@link StatusDomainModel[]}
   */
  listAllByDomain(req?: any): Observable<HttpResponse<StatusDomainModel[]>> {
    return this.http.get<StatusDomainModel[]>(this.list_all_url,
      { params: req, observe: 'response' });
  }

  /**
   * {@link ApiService#getAllObjet}
   */
  getAllObjet(req?: any) {
    super.getAllObjet(this.list_url, req);
  }
}
