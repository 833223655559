import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Account } from '../../../model/account.model';
import { Domain } from '../../../model/domain.model';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from '../base/base.service';
import { ServiceItemService } from '../service-items/service-item.service';
import { FormService } from '../../../shared/services/form.service';
import { DomainColumnName } from '../domain/domain-column-name';
import { MatDialog } from '@angular/material/dialog';
import { DomainService } from '../domain/domain.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { DomainLink } from '../domain/domain.url';
import { ConstantHome } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { takeUntil } from 'rxjs/operators';
import { ChampsFormulaire } from '../../../model/champs_formulaire/champs-formulaire.model';
import { DomainAffectStatusComponent } from '../domain/affect_status/domain-affect-status.component';
import { DomainAddComponent } from '../domain/add/domain-add.component';
import { StatusLink } from '../status/status.url';
import { DomainUpdateComponent } from '../domain/update/domain-update.component';
import { Costs, ICosts } from '../../../model/costs.model';
import { CostsService } from './costs.service';
import { ConstantCosts } from './costs.url';
import { CostsAddComponent } from './add/costs-add.component';
import { CostsUpdateComponent } from './update/costs-update.component';

/**
 * Gère l'affichage des coûts.
 * see {@link ICosts}
 */
@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html'
})
export class CostsComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  costsList: ICosts[]; // utiliser pour afficher la liste des éléments
  costsSearch: ICosts[]; // utiliser pour rechercher parmi liste des éléments.
  searchValue: string;
  initByQueryParam = false;

  serviceItemTyped = '';
  subs$ = new Subject<void>();

  myControl = new UntypedFormControl();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public baseService: BaseService,
    private serviceItemService: ServiceItemService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private costsService: CostsService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct costs list');
    this.pageData = {
      title: ConstantCosts.data.sub.list.title,
      key: 'costs.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantCosts.data.title,
          key: 'costs.name',
          route: Url.SLASH_ROUTE.concat(ConstantCosts.urlFull
            .COSTS.LIST)
        },
        {
          title: ConstantCosts.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'costs.title') {
      translate.get('costs.title').subscribe(res => this.pageData.title = res);
    }

    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'costs.name') {
        translate.get('costs.name').subscribe(res => {
          console.log('## res: ', res);
          value.title = res;
        });
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }

    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init costs list');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.account = account;
          // récupération de la liste des coûts.
          this.costsService.query(this.costsService.typesUrl)
            .subscribe(res => {
              console.log('## Success get costs list: ', res);
              this.costsList = (res.body) ? res.body : [];
              this.costsSearch = this.costsList;
              super.setLoaded();
              // récupération de la liste des coûts.
              this.costsService.getObjetObs().pipe(takeUntil(this.subs$))
                .subscribe((res: ICosts[]) => {
                  console.log('## get list costs in list by subject: ', res);
                  this.costsList = (res) ? res : [];
                  this.costsSearch = this.costsList;
                });
            });
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  add() {
    console.log('## open costs add');
    this.baseService.openSm(this.dialog, CostsAddComponent);
  }

  update(c: ICosts) {
    console.log('## open costs update');
    this.costsService.setObjetSelectObs(c);
    this.baseService.openSm(this.dialog, CostsUpdateComponent);
  }

  /**
   * Retourne la liste des des domains
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): ICosts[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  /**
   * Permet de rechercher un coût.
   */
  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.costsSearch].map(mapFn);
    const result = this.costsSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.costsList = [];
    result.forEach((c) => {
      this.costsList.push(c);
    });
  }

  ngOnDestroy() {
    console.log('## on destroy costs list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

}
