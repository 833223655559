import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from '../../base-page';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormService } from '../../../shared/services/form.service';

@Component({
  selector: 'app-achat-credit',
  templateUrl: './achat-credit.component.html',
  styleUrls: ['./achat-credit.component.css']
})
export class AchatCreditComponent extends BasePageComponent
  implements OnInit, OnDestroy {

  private subs$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    this.pageData = {
      title: 'Achat Credit',
      key: '',
      breadcrumbs: [
        {
          title: 'Home',
          key: '',
          route: 'home',
        },
        {
          title: 'Achat Credit',
          key: ''
        },
      ],
    };
   }

  ngOnInit(): void {
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
      this.getData('assets/data/table.json', 'tableData', 'setLoaded');
    })
  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
  }

}
