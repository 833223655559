import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Transaction } from 'src/app/model/transaction.model';
import { TransactionsService } from 'src/app/services/ServiceEntity/transactions.service';
import { ValidConfirmReclamationComponent } from '../valid-confirm-reclamation/valid-confirm-reclamation.component';

@Component({
  templateUrl: './confirm-reclamation.component.html',
  styleUrls: ['./confirm-reclamation.component.css']
})
export class ConfirmReclamationComponent implements OnInit {
  title: string;
  message: string;
  confirm = false;
  transaction:Transaction


  constructor(
    public dialogRef: MatDialogRef<ConfirmReclamationComponent>,
    public dialog: MatDialog,
   
    @Inject(MAT_DIALOG_DATA) data: {transaction: Transaction, title: string, message:string }) {
    // Update view with given values
    this.title = data.title;
    this.transaction= data.transaction
    this.message = data.message;
    console.log('transaction in confirmComponent', this.transaction);

  }

  ngOnInit() {
  }

  onConfirm(): void {
    this.confirm = true;
    if (this.message == "Transaction Marqué Success") {
      this.dialog.open(ValidConfirmReclamationComponent, {
        data: { message: this.message,transaction: this.transaction}, height: '200px',
        width: '700px'
      });
    }

    if (this.message == "Transaction Marqué Failled") {
      this.dialog.open(ValidConfirmReclamationComponent, {
        data: { message: this.message,transaction: this.transaction }, height: '200px',
        width: '700px'
      });
    }
    if (this.message == "Transaction Marqué PENDING") {
      this.dialog.open(ValidConfirmReclamationComponent, {
        data: { message: this.message,transaction: this.transaction }, height: '200px',
        width: '700px'
      });
    }
   // this.transactionService.getAllTransactions(this.transaction.numberAccount);
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
