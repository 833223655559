import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisée pour gérer les coûts.
 */
export namespace ConstantCosts {
  export const data = {
    title: 'Coûts',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un coût'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des coûts'
      }
    }
  }
  export const url = {
    COSTS: {
      COSTS_: 'couts',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    COSTS: {
      ADD: ConstantCosts.url.COSTS.COSTS_
        .concat(Url.SLASH_ROUTE).concat(ConstantCosts.url.COSTS.ADD),
      LIST: ConstantCosts.url.COSTS.COSTS_
        .concat(Url.SLASH_ROUTE).concat(ConstantCosts.url.COSTS.LIST),
    }
  };
}