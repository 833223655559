import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IApiKeyDTO } from '../../../../model/api-key.model';
import { IpAdressService } from '../../api-key/ip-adress.service';
import { ApiKeyService } from '../../api-key/api-key.service';
import { CUserApiKeyService } from '../../../../services/ServiceEntity/c-user-api-key.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { STATUS_BD_CONSTANT } from '../../../../model/status.model';
import { TIME_MIN_X1_REQUEST } from '../../../../shared/constant/request.contant';
import { HttpErrorResponse } from '@angular/common/http';
import { IpAdressApiKeyDTOS } from '../../../../model/ipAdress.model';

/**
 * Permet de gérer l'ajout des api key.
 */
@Component({
  selector: 'app-ip-adress-delete',
  templateUrl: './ip-adress-delete.component.html',
  animations: [fadeIn]
})
export class IpAdressDeleteComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<IpAdressDeleteComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;
  idAccount: number;

  apiKey: IApiKeyDTO;
  ipAdress: IpAdressApiKeyDTOS;

  isDeleting = false;
  successDelete = false;
  hasDelete = false;

  constructor(
    private ipAdressService: IpAdressService,
    private apiKeyService: ApiKeyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domainService: CUserApiKeyService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<IpAdressDeleteComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct ip adress add');
    this.theDialogRef = this.dialogRef;
    this.apiKey = this.data.apiKey;
    this.ipAdress = this.data.ipAdress;
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
    console.log('## init ip adress delete');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
      });
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy ip adress delete');
    this.formService.free(this.subs$);
  }

  close() {
    this.dialogRef.close();
  }

  delete() {
    console.log('## deleting ip adress: ', this.apiKey);
    this.isDeleting = true;
    this.serverHasError = false;
    this.successDelete = false;
    this.ipAdressService.delete(this.ipAdress.id).subscribe(res => {
      console.log('## success delete ip adress');
      this.serverHasError = false;
      this.successDelete = true;
      this.hasDelete = true;
      setTimeout(() => {
        this.ipAdressService.eventEmitter.emit('list');
      }, TIME_MIN_X1_REQUEST);
      this.responseFromServer();
    }, (res: HttpErrorResponse) => {
      console.log('## error get list status while adding: ' + res.message);
      this.serverHasError = true;
      this.successDelete = false;
      this.isDeleting = false;
    })
  }

  private responseFromServer() {
    setTimeout(() => {
      this.isDeleting = false;
      this.close();
    }, 300);
  }

}