import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { ExchangeUpdateComponent } from '../exchange-rate/update/exchange-rate-update.component';
import { ExchangeRateAddComponent } from '../exchange-rate/add/exchange-rate-add.component';
import { ExchangeRateComponent } from '../exchange-rate/exchange-rate.component';
import { VersionExchangeRateComponent } from './version-exchange-rate.component';
import { VExchangeRateAddComponent } from './add/v-exchange-rate-add.component';
import { VersionExchRateShowComponent } from './show/version-exch-rate-show.component';
import { VersionExchRateUpdateComponent } from './update/version-exch-rate-update.component';

/**
 * Ce module permet de gérer les version exchange rate.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [VersionExchRateShowComponent, VersionExchangeRateComponent,
        VExchangeRateAddComponent, VersionExchRateUpdateComponent]
})
export class VersionExchangeRateModule {}
