import { TypeInputFormModel } from './type-input-form.model';
import { IServiceItem } from '../service-item.model';
import { LocationType, SourceType, TypeForm } from './champs-formulaire.model';

export class Formulaire {
  constructor(
    public id?: number,
    public titre?: string,
    public formsOrder?: number,
    public description?: string,
    public serviceItemName?: string,
    public serviceFormulaireId?: number
  ) {}
}