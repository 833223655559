import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { ICountry } from '../../../model/country.model';
import { Subject } from 'rxjs';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { ColorService } from '../../../shared/services/color.service';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { CountryService } from '../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { Url } from '../../../shared/constant/url.constant';
import { ConstantHome } from '../home/home.url';
import { ConstantCountries } from '../countries/countries.url';
import { takeUntil } from 'rxjs/operators';
import { BTN_CANCEL_CLASS, CANCEL_BG_COLOR } from '../../../shared/constant/css-style/css-class.constant';
import { CONSTANT_ICON } from '../../../shared/constant/icon.constant';
import { CountryAddComponent } from '../countries/add/country-add.component';
import { CountryShowComponent } from '../countries/show/country-show.component';
import { CountryUpdateComponent } from '../countries/update/country-update.component';
import { CountryDeleteComponent } from '../countries/delete/country-delete.component';
import { IVersionExchangeRate } from '../../../model/version-exchange-rate.model';
import { ConstantExchangeRate } from '../exchange-rate/exchange-rate.url';
import { VersionExchangeRateService } from './version-exchange-rate.service';
import { VExchangeRateAddComponent } from './add/v-exchange-rate-add.component';
import { SIZE_H2_SM_POPUP, SIZE_H3_SM_POPUP, SIZE_H_SM_POPUP, SIZE_W_SM_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { VersionExchRateShowComponent } from './show/version-exch-rate-show.component';
import { VersionExchRateUpdateComponent } from './update/version-exch-rate-update.component';

/**
 * Permet de gérer les versions d'exchange rate.
 */
@Component({
  selector: 'app-version-exchange-rate',
  templateUrl: './version-exchange-rate.component.html'
})
export class VersionExchangeRateComponent extends BasePageComponent implements OnInit, OnDestroy {
  versionExchangeRateList: IVersionExchangeRate[] = [];
  versionExchangeRateSearch: IVersionExchangeRate[] = [];
  searchValue: string;
  @ViewChild('basicTable') tabRef: any;
  countriesTemp: IVersionExchangeRate[];
  countryAddRef: any;
  subs$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private colorService: ColorService,
    private formService: FormService,
    private dialog: MatDialog,
    private versionExchangeRateService: VersionExchangeRateService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct version exchange rate list');
    console.log('this.pageData before##', this.pageData);
    this.pageData = {
      title: '',
      key: 'listVersionExchangeRate',
      // fil d'ariane
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: '',
          key: 'VersionExchangeRate',
          route: Url.SLASH_ROUTE.concat(ConstantExchangeRate.urlFull
            .EXCHANGE_RATE.LIST)
        },
        {
          title: '',
          key: 'lister'
        }
      ]
    };
    if(this.pageData.key==="listVersionExchangeRate"){
      translate.get('listVersionExchangeRate').subscribe(res => this.pageData.title=res);
    }
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="VersionExchangeRate" ){
        translate.get('VersionExchangeRate').subscribe(res => value.title=res);
      }
      if( value.key==="lister" ){
        translate.get('lister').subscribe(res => value.title=res);
      }

    });
    console.log('this.pageData after##', this.pageData);
  }

  ngOnInit(): void {
    console.log('## init countries liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.ngOnInit();
        super.setLoaded();
        this.versionExchangeRateService.query().subscribe(res => {
          console.log('## Success get country list');
          this.versionExchangeRateList = (res.body) ? res.body : [];
          this.versionExchangeRateSearch = this.versionExchangeRateList;
          super.setLoaded();
          this.versionExchangeRateService.getVersionExchangeRateObs().pipe(takeUntil(this.subs$))
            .subscribe((res: IVersionExchangeRate[]) => {
              console.log('## get list country in list by subject');
              this.versionExchangeRateList = (res) ? res : [];
              this.versionExchangeRateSearch = this.versionExchangeRateList;
            });
        });
      });
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.versionExchangeRateSearch].map(mapFn);
    const result = this.versionExchangeRateSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.versionExchangeRateList = [];
    result.forEach((c) => {
      this.versionExchangeRateList.push(c);
    });
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    console.log('## open version exchange rate add');
    this.dialog.open(VExchangeRateAddComponent, {
      height: SIZE_H2_SM_POPUP,
      width: SIZE_W_SM_POPUP
    });
  }

  show(c: IVersionExchangeRate) {
    console.log('## open country show');
    this.versionExchangeRateService.setVersionExchangeRateSelectObs(c);
    this.dialog.open(VersionExchRateShowComponent, {
      height: SIZE_H_SM_POPUP,
      width: SIZE_W_SM_POPUP
    });
  }

  update(c: IVersionExchangeRate) {
    this.versionExchangeRateService.setVersionExchangeRateSelectObs(c);
    this.dialog.open(VersionExchRateUpdateComponent, {
      height: SIZE_H3_SM_POPUP,
      width: SIZE_W_SM_POPUP
    });
  }

  delete(c: ICountry) {
    this.versionExchangeRateService.setVersionExchangeRateSelectObs(c);
    this.dialog.open(CountryDeleteComponent, {
      height: '700px',
      width: '800px'
    });
  }

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IVersionExchangeRate[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }
}
