import { ITransaction } from 'src/app/model/transaction.model';

export interface IMobileAccount {
  id?: number;
  label?: string;
  preferenceCurrency?: string;
  numberAccount?: string;
  expiryDate?: Date;
  type?: string;
  status?: string;
  transactions?: ITransaction[];
  codeId?: number;
  cUserId?: number;
  phoneNumber?: number;
  countryLabel?: string;
}

export class MobileAccount implements IMobileAccount {
  constructor(
    public id?: number,
    public label?: string,
    public preferenceCurrency?: string,
    public numberAccount?: string,
    public expiryDate?: Date,
    public type?: string,
    public status?: string,
    public transactions?: ITransaction[],
    public codeId?: number,
    public cUserId?: number,
    public phoneNumber?: number,
    public countryLabel?: string
  ) {}
}


export class MobileAccountCash {
  constructor(
    public id?: number,
    public label?: string,
    public codeMoney?: string,
    public numberAccount?: string,
    public expiryDate?: Date,
    public createdDate?: Date,
    public amount?: number,
    public statusId?: number,
    public type?: string,
    public cUserId?: number,
    public statusLabel?: string,
  ) {}
}
