import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IOption } from '../../../../ui/interfaces/option';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IServiceItemColumnName, ServiceItemColumnName } from '../../service-items/service-item-column-name';
import { AccountRessTypeService } from '../../acc-ress-type/account-ress-type.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceItemService } from '../../service-items/service-item.service';
import { FormService } from '../../../../shared/services/form.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IAccountRessType } from '../../../../model/acc_ress_type.model';
import { BodyType, IServiceItem, ServiceItem } from '../../../../model/service-item.model';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { Account } from '../../../../model/account.model';
import { Observable, Subject } from 'rxjs';
import { ICountry } from '../../../../model/country.model';
import { CountryService } from '../../countries/countries.service';
import { CostsRuleColumnName, ICostsRuleColumnName } from '../fees-column-name';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IMoney } from '../../../../model/money.model';
import { MoniesService } from '../../monies/monies.service';
import { CostsRule, ICostsRule } from '../../../../model/costs-rule.model';
import { CostsRuleService } from '../costs-rule.service';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';
import { CostsRuleValidator } from '../fees-validator';
import { CostsDetails, ICostsDetails } from '../../../../model/costs-details.model';
import { CostsDetailsService } from '../../costs-details/costs-details.service';
import { ICosts } from '../../../../model/costs.model';

/**
 * Permet de gérer l'ajout des service items.
 */
@Component({
  selector: 'app-fees-add',
  templateUrl: './fees-add.component.html',
  animations: [fadeIn]
})
export class FeesAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  account: Account; // The account connected.

  checkedInternational = false;
  private iValidator = 'interValidator'; // key for validator country dest.

  cUserId: number;
  accountUserId: number;

  // money.
  moniesList: IMoney[] = [];
  filteredMoneyOptions: Observable<IMoney[]>;

  // service item.
  serviceItemsList: IServiceItem[] = [];
  filteredOptions: Observable<IServiceItem[]>;

  // country.
  countriesList: ICountry[] = [];
  filteredCountrySrcOptions: Observable<ICountry[]>;
  filteredCountryDestOptions: Observable<ICountry[]>;

  private subs$ = new Subject<void>();
  column: ICostsRuleColumnName;
  isSaving = false;
  successSave = false;
  serverHasError = false;
  failedSave : boolean;
  editForm: UntypedFormGroup;

  // informations sur les coûts.
  costsSelected: CostsDetails;
  costsList: CostsDetails[] = [];
  filteredOptionsCostsDetails: Observable<CostsDetails[]>;

  costsRuleListt: ICostsRule[];
  costsRuleList: ICostsRule[];

  constructor(
    private costsDetailsService: CostsDetailsService,
    private validator: CostsRuleValidator,
    private accountService: AccountService,
    private moniesService: MoniesService,
    protected costsRuleService: CostsRuleService,
    private countryService: CountryService,
    private columnName: CostsRuleColumnName,
    private dialogRef: MatDialogRef<FeesAddComponent>,
    private serviceItemService: ServiceItemService,
    private formService: FormService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: { costsRuleList },
    store: Store<IAppState>, httpSv: HttpService
  ) {
    super(store, httpSv);
    this.costsRuleListt = data?.costsRuleList;

    console.log('## construct costs rule add', data?.costsRuleList);
    console.log('## cUserId', (data as any)?.cUserId);
    this.cUserId = (data as any)?.cUserId;
    this.accountUserId = (data as any)?.accountUserId;
    this.column = this.columnName.getColumnName();
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init cost rule add');
    this.getCostsRuleList();
    console.log('costsRuleListt', this.costsRuleList);
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        if (account) {
          super.setLoaded();
          this.getServiceItemList(); // after 500ms.
          this.getCountryList(); // after 500ms.
          this.getMoneyList(); // after 500ms.
          this.getCostsDetailsList(); // after 500ms.
        } else {
          console.log('## account undefined');
        }
        this.account = account;
      });
  }

  ngOnDestroy() {
    console.log('## destroy costs rule add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }
  getCostsRuleList() {
    this.costsRuleService.queryList().subscribe(res => {
      console.log('## Success get costs rule list');
      this.costsRuleList = (res.body) ? res.body : [];
      console.log('## size: ' + this.costsRuleList.length);

      this.costsRuleService.getCostRuleObs().pipe(takeUntil(this.subs$))
        .subscribe((res: ICostsRule[]) => {
          console.log('## get list costs rule in list by subject');
          const rs = res ? res : [];
          console.log('## size: ' + rs.length);
          this.costsRuleList = rs;
        });
    });
  }

  getMoneyList() {
    setTimeout(() => {
      this.moniesService.query().subscribe(res => {
        console.log('## Success get monies list while adding fees');
        this.moniesList = (res.body) ? res.body : [];
        console.log('## size: ' + this.moniesList.length);
        this.filteredMoneyOptions = this.editForm.get('moneyId').valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterMoney(value))
          );
      });
    }, TIME_MIN_REQUEST)
  }

  displayFnCosts(subject: ICostsDetails) {
    return subject ? subject.label : '';
  }

  changeInter(event: MatCheckboxChange) {
    console.log('## change');
    const c = event.checked;
    console.log('## val: ' + c);
    this.checkedInternational = c;
    this.editForm.get(this.column.countryBId).reset();
    this.interValidator(this.editForm.get(this.column.countryBId));
  }

  interValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const c: string = control.value;
    console.log('## c: ' + c);
    console.log('## check: ' + this.checkedInternational);
    let val = (c !== undefined && c !== null) ? c : '';
    console.log('##val: ' + val);
    console.log('##val: ' + val.length);
    if (this.checkedInternational && (val.length <= 0)) {
      return { [this.iValidator]: true };
    }
    return null;
  }

  initForm(fb: UntypedFormBuilder) {
    try {
      this.editForm = this.fb.group({
        id: [],
        costsDetails: ['', [Validators.required]],
        montant: [null, [Validators.required,
          Validators.pattern('^[0-9xX\\+\\-()/.\\*]*$')]],
        moneyId: [null, [Validators.required]],
        serviceItemId: [null, [Validators.required]],
        countryAId: [null, [Validators.required]],
        countryBId: this.validator.getCountryBValidator(this)
      });

    } catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  displayFnMoney(subject: IMoney) {
    return subject ? subject.codeMoney : '';
  }

  displayFnServiceItem(subject: IServiceItem) {
    return subject ? subject.name : '';
  }

  displayFnCountry(subject: ICountry) {
    return subject ? subject.label : '';
  }

  private _filterCountry(value: string): ICountry[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.countriesList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter country: ' + e.message);
    }
    return filteredList;
  }

  private _filterMoney(value: string): IServiceItem[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.moniesList.filter(option => option.codeMoney.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter money: ' + e.message);
    }
    return filteredList;
  }

  /**
   * Permet de récupérer la liste des détails de coût
   */
  getCostsDetailsList() {
    setTimeout(() => {
      this.costsDetailsService
        .query(this.costsDetailsService.typesUrl)
        .pipe(
          filter((mayBeOk: HttpResponse<CostsDetails[]>) => mayBeOk.ok),
          map((response: HttpResponse<CostsDetails[]>) => response.body)
        )
        .subscribe((res: CostsDetails[]) => {
            console.log('## success get list costs details while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.costsList = rs;
            this.filteredOptionsCostsDetails = this.editForm.get('costsDetails').valueChanges
              .pipe(
                startWith(''),
                map(value => this._filterCostsDetails(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list costs details while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  private _filter(value: string): IServiceItem[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option.name.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  /**
   * Effectue une recherche sur les détails de coût
   * @param value la valeur à rechercher
   * @private
   */
  private _filterCostsDetails(value: string): ICostsDetails[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.costsList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  getServiceItemList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.serviceItemService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IServiceItem[]>) => mayBeOk.ok),
          map((response: HttpResponse<IServiceItem[]>) => response.body)
        )
        .subscribe((res: IServiceItem[]) => {
          console.log('## success get list service item while adding.');
          const rs = res ? res : [];
          console.log('## size: ' + rs.length);
          this.serviceItemsList = rs;
          this.filteredOptions = this.editForm.get('serviceItemId').valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        },
          (res: HttpErrorResponse) => {
            console.log('## error get list service item while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  getCountryList() {
    setTimeout(() => {
      // COUNTRY
      this.countryService.query().subscribe(res => {
        console.log('## Success get country list while adding.');
        this.countriesList = (res.body) ? res.body : [];
        console.log('## size: ' + this.countriesList.length);
        this.filteredCountrySrcOptions = this.editForm.get(this.column.countryAId).valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterCountry(value))
          );
        this.filteredCountryDestOptions = this.editForm.get(this.column.countryBId).valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterCountry(value))
          );
      });
    }, TIME_MIN_REQUEST);
  }

  /*getAllAccRessType() {
    setTimeout(() => {
      this.accountRessTypeService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IAccountRessType[]>) => mayBeOk.ok),
          map((response: HttpResponse<IAccountRessType[]>) => response.body)
        )
        .subscribe((res: IAccountRessType[]) => {
          console.log('## success get acc ress type list by add');
          let acc = (res) ? res : [];
          console.log('## size: ' + acc.length);
          this.accountRessTypes.push({
            ...new Option(),
            label: 'No account ressource type',
            value: ''
          })
          acc.forEach(a => {
            this.accountRessTypes.push({
              ...new Option(),
              label: (a.type) ? a.type : '',
              value: (a.id) ? a.id.toString() : undefined
            })
          })
        }),
        (res: HttpErrorResponse) => {
          console.log('## error get acc ress type list: ' +res.message);
        };
    }, TIME_MIN_REQUEST)
  }*/


  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save service item');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    this.failedSave = false;
    const c: ICostsRule = this.createFromForm();
    c.countryBId = (!this.checkedInternational) ? c.countryAId : c.countryBId;
    c.amountA = 0;
    c.amountB = -1;
    c.cUserId = this.cUserId;
    c.accountUserId = this.accountUserId;
    console.log('## data: ', c);
    const serviceItemsId = c.serviceItemsId;
    const countryIdB = c.countryBId;
    const countryAId = c.countryAId;
/*    const resultat = this.costsRuleList.find(service => {
      console.log('service ', service.serviceItemsId == serviceItemsId);
      return service.serviceItemsId === serviceItemsId
    });*/
    // console.log('resultat ', resultat);
/*    if (resultat && resultat.countryBId == countryIdB && resultat.countryAId == countryAId) {
      this.failedSave = true;
    } else {*/
      this.costsRuleService.create(c).subscribe(res => {
        console.log('## success create costs rule');
        this.serverHasError = false;
        this.failedSave = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.checkedInternational = false;
        this.getCountryList(); // after 500ms.
        this.getServiceItemList(); // after 500ms.
        this.getMoneyList(); // after 500ms.
        this.costsRuleService.queryList().subscribe(res => {
          console.log('## Success get costs rule list after saving');
          const c: ICostsRule[] = (res.body) ? res.body : [];
          console.log('## size: ' + c.length);
          this.costsRuleService.setCostRuleObs(c);
          this.closeModal();
        });
      },
        err => {
          console.log('## error create costs rule');
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
   // }

  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, TIME_MIN_REQUEST);
  }

  private createFromForm(): ICostsRule {
    return {
      ...new CostsRule(),
      id: this.editForm.get(['id']).value,

      moneyId: (this.editForm.get(['moneyId']).value as IMoney).id,
      montant: this.editForm.get(['montant']).value,
      typeDetailsId: (this.editForm.get(['costsDetails']).value as
        ICostsDetails).id,
      serviceItemsId: (this.editForm.get(['serviceItemId']).value as IServiceItem)
        .id,
      countryAId: (this.editForm.get(['countryAId']).value as ICountry).id,
      countryBId: (this.editForm.get(['countryBId'])?.value as ICountry)?.id
    };
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }
  reset(){
    this.editForm.reset();    
  }
 

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  hasErrorInterValidator(column: string): boolean {
    return this.formService.hasError(this.editForm, column, this.iValidator);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
