import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations/form-error';
import { MobileMoneyKey } from '../mobile-mobey-key';
import { RetraitCodeService } from '../retrait-code.service';

@Component({
  selector: 'app-retrait-code-valid',
  templateUrl: './retrait-code-valid.component.html',
  styleUrls: ['./retrait-code-valid.component.css'],
  animations: [fadeIn]
})
export class RetraitCodeValidComponent implements OnInit {
  errorTransfert:boolean;
  successTransfert:boolean;

  TabsRetraitCode: boolean;
  //canIShowAllCashOut:boolean;

  code:any;
  constructor(
    private retraitCodeService:RetraitCodeService,
    private mobileMoneyKey:MobileMoneyKey 
    
  )
  {
    this.TabsRetraitCode = true;   
    
   }

  ngOnInit(): void {
    //this.canIShowAllCashOut=false;
    this.code=this.retraitCodeService.getCode();

  }
  canIShowAllCashOut(): boolean {
    return this.retraitCodeService
      .getElement(this.mobileMoneyKey.appAllCashOut).value;
  } 

  canIShowPartialCashOut(): boolean {
    return this.retraitCodeService
      .getElement(this.mobileMoneyKey.appPartialCashOut).value;
  }

  AllcashOut() {
    this.retraitCodeService.setElement(this.mobileMoneyKey.appRetraitCodeValide, false);    
    this.retraitCodeService.setElement(this.mobileMoneyKey.appAllCashOut, true);    
  }
  PartialcashOut() {
    this.retraitCodeService.setElement(this.mobileMoneyKey.appRetraitCodeValide, false);
    this.retraitCodeService.setElement(this.mobileMoneyKey.appPartialCashOut, true); 
     
  }

  getRetraitCodeValid(): boolean {
    return this.retraitCodeService
      .getElement(this.mobileMoneyKey.appRetraitCodeValide).value;

  }
  DisableRetraitCodeSuccess(){
    this.retraitCodeService.setElement(this.mobileMoneyKey.retraitCodeValide, false);
    this.retraitCodeService.setElement(this.mobileMoneyKey.appTabsRetraitCode, true);
    //this.TabsRetraitCode = true;
  }
 
  getColorbyReponse() {
    if (this.retraitCodeService.getCode().status === 'ACTIVED') {
      return 'black';
    } else {
      return 'red';
    }
  }


}
