import { BodyFormat, BodyType, RequestType } from './service-item.model';

export interface IServiceItemCompose {
  name?: string;
  description?: string;
  serviceItemId?: number;
  serviceId?: number;
  statusId?: number;
  statusLabel?: string;
}

export class ServiceItemCompose implements IServiceItemCompose {
  constructor(
    public name?: string,
    public description?: string,
    public serviceItemId?: number,
    public serviceId?: number,
    public statusId?: number,
    public statusLabel?: string
  ) {}
}

/**
 * Décrit les services contenus dans les services composés.
 */
export class ServiceItemFilsDTO {
  constructor(
    public serviceItemFilsId?: number,
    public step?: number,
  ) {}
}

/**
 * Permet de décrire un service composé.
 */
export class CompositeCreateServiceItemDTO {
  constructor(
    public serviceItemParentId?: number,
    public serviceItemFilsList?: ServiceItemFilsDTO[],
  ) {}
}

export class ServiceItemFilsUpdateDTO {
  constructor(
    public compositeServiceItemId?: number,
    public serviceItemFilsId?: number,
    public step?: number)
  {
  }
}

export class ServiceItemFilsListDTO {
  constructor(
    public compositeServiceItemId?: number,
    public name?: string,
    public description?: string,
    public serviceItemId?: number,
    public serviceId?: number,
    public statusId?: number,
    public statusLabel?: string,
    public compositeServiceItemStatusId?: number,
    public step?: number,
  ) {
  }
}
