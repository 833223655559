import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { MONIES_ROUTE } from './monies.route';
import { MoniesComponent } from './monies.component';
import { MoniesShowComponent } from './show/monies-show.component';
import { MoniesAddComponent } from './add/monies-add.component';
import { MoniesUpdateComponent } from './update/monies-update.component';
import { MoneyDeleteComponent } from './delete/monies-delete.component';

/**
 * Ce module permet de gérer le model monies.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [MoneyDeleteComponent, MoniesUpdateComponent,
        MoniesAddComponent, MoniesShowComponent, MoniesComponent]
})
export class MoniesModule {}
