import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { ICountry } from '../../../../model/country.model';
import { Subject } from 'rxjs';
import { CountryService } from '../../countries/countries.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../../acc-ress-type/acc-ress-type-column-name';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { IMoney } from '../../../../model/money.model';
import { MoniesService } from '../monies.service';

/**
 * Permet de gérer l'ajout d'un money
 */
@Component({
  selector: 'app-monies-show',
  templateUrl: './monies-show.component.html',
  animations: [fadeIn]
})
export class MoniesShowComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  moneySelected: IMoney;
  private subs$ = new Subject<void>();

  constructor(
    private moneyService: MoniesService,
    private dialogRef: MatDialogRef<MoniesShowComponent>,
    private formService: FormService,
    private columnName: AccRessTypeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct money show');
  }

  ngOnInit(): void {
    console.log('## init money shows.');
    this.moneyService.getMoneySelectObs()
      .pipe(takeUntil(this.subs$)).subscribe((res: IMoney) => {
      console.log('## get money selected');
      try {
        console.log('## data: ' + JSON.stringify(res));
        this.moneySelected = res;
      }catch (e) {
        console.log('## error get money show: ' + e.message);
      }
      super.setLoaded();
    })
  }

  ngOnDestroy() {
    console.log('## destroy money show');
    this.formService.free(this.subs$);
    this.moneyService.setMoneySelectObs(undefined);
  }

  closeModal() {
    console.log('## close show money');
    this.dialogRef.close();
  }
}
