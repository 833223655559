import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { IOption } from 'src/app/ui/interfaces/option';
import { BASE_URL } from 'src/environments/environment';
import { BasePageComponent } from '../../base-page';
import * as PageActions from '../../../store/actions/page.actions';
import { filter, map } from 'rxjs/operators';
import { Content } from 'src/app/ui/interfaces/modal';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { CountryService } from '../countries/countries.service';
import { CostsRuleService } from '../fees/costs-rule.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Subject } from 'rxjs';
import { FormService } from '../../../shared/services/form.service';
import { IMobileAccount } from '../../../model/mobile-account.model';
import { STATUS } from '../../../model/account.model';
import { TIME_MEDIUM_REQUEST } from '../../../shared/constant/request.contant';
import { fadeIn } from '../../../animations/form-error';
import { MatDialog } from '@angular/material/dialog';
import { TransactionsService } from 'src/app/services/ServiceEntity/transactions.service';
import { CodeService } from 'src/app/services/ServiceEntity/code.service';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from 'src/app/shared/constant/css-style/css-size.constant';
import { CONTAINER_DIALOG_POPUP } from '../../../shared/constant/css-style/css-class.constant';
import { ConstantCash } from './cash.url';
import { HOME_ROUTING } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { TranslateService } from '@ngx-translate/core';
import { VerificationCodeComponent } from '../cash-dynamic/verification-code/verification-code.component';
import { RetraitCodePartielComponent } from '../cash-dynamic/retrait-code-partiel/retrait-code-partiel.component';
import { RetraitCodeCompletComponent } from '../cash-dynamic/retrait-code-complet/retrait-code-complet.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import {
  ID_SERVICE_ITEM_INFORMATION_CODE_COMPLET,
  ID_SERVICE_ITEM_INFORMATION_CODE_PARTIEL,
  ID_SERVICE_ITEM_RETRAIT_ALL_CODE_CASH,
  ID_SERVICE_ITEM_RETRAIT_CODE_CASH,
  ID_SERVICE_ITEM_TRANSFER_COMPTE,
  ID_SERVICE_ITEM_VERIFICATION_CODE_COMPLET,
  ID_SERVICE_ITEM_VERIFICATION_CODE_PARTIEL,
  SERVICE_ITEM_ID_INFORMATION_CODE_COMPLET,
  SERVICE_ITEM_ID_INFORMATION_CODE_PARTIEL,
  SERVICE_ITEM_ID_RETRAIT_CODE_CASH,
  SERVICE_ITEM_ID_TRANSFER_COMPTE,
  SERVICE_ITEM_ID_TRANSFER_COMPTE_INTER,
  SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET,
  SERVICE_ITEM_ID_VERIFICATION_CODE_INTER_PARTIEL,
  SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL
} from '../../../shared/constant/service-item.constant';
import { RetraitCodePartielInfosComponent } from './retrait-code-partiel-infos/retrait-code-partiel-infos.component';
import { OpenService } from './open.service';
import { CashDataModel } from './cash-data-model';

interface IForm {
  key: string;
  form: UntypedFormGroup;
}

export interface DialogCash {
  component: string
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-cash',
  templateUrl: './cash.component.html',
  styleUrls: ['./cash.component.css'],
  animations: [fadeIn]
})
export class CashComponent extends BasePageComponent implements OnInit, OnDestroy {
  transfertCode: UntypedFormGroup;
  retraitCode: UntypedFormGroup;
  allCachOutForm: UntypedFormGroup;
  PartialCachOutForm: UntypedFormGroup;
  TransfertCompteForm: UntypedFormGroup;

  loadingMessagesForm: boolean;
  loadingCodeForm: boolean;
  loadingAllCashOut: boolean;
  loadingPartialCashOut: boolean;
  loadingTransfertCompte: boolean;

  CurentUser: any;
  CurrentUserAuth: any;
  CurentUserCuser: any;
  currentMobileAccount: any;
  cUserMobileAccount: any;
  accountNumber: any;
  countrylist: IOption[];
  country: any;
  countries: any;
  SelectedContry: any;
  //
  sentAmount: number;
  countryTrader: string;
  moneySource: string;
  countryReceiver: string;
  moneyDest: string;
  error: string;
  //
  ReponseTransfert: any;
  successTransfert = false;
  errorTransfert = false;
  CountryDestID: any;
  //Tabs 
  TabsEnvoiCode: boolean;
  TabsRetraitCode: boolean;
  TabsTransFert: boolean;
  //retrait Code
  RetraitCodeValide = false;
  //All cash, partial cash and Transfert Compte
  AllcashOutPane = false;
  PartialCashPane = false;
  //transfertCompte
  transfertCompteSuccess = false;
  transfertCompteFail = false;
  transfertCompteERROR: any;

  private subs$ = new Subject<void>();

  //Identity type
  identityType: IOption[] = [
    {
      'label': 'CNI',
      'value': 'CNI'
    },
    {
      'label': 'Passe Port',
      'value': 'Passe Port'
    },
    {
      'label': 'Permis conduire',
      'value': 'Permis conduire'
    },
    {
      'label': 'Carte Résident',
      'value': 'Carte Résident'
    }
  ];
  //result 
  resultRetraitCode: any;
  finalAllCashOutVal: any;
  finalPartialCashOutVal: any;


  constructor(
    private openService: OpenService,
    private dialog: MatDialog,
    private formService: FormService,
    private transactionsService: TransactionsService,
    private codeService: CodeService,
    private cUserService: CUserService,
    private mAccountService: AccountUserService,
    private accountService: AccountService,
    store: Store<IAppState>,
    httpSv: HttpService,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private countryService: CountryService,
    private costsRuleService: CostsRuleService,
    private modal: TCModalService,
    private translate: TranslateService) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      key: 'cash',
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: HOME_ROUTING
        },
        {
          title: '',
          key: 'cash',
          route: Url.SLASH_ROUTE.concat(ConstantCash.urlFull.CASH.LIST)
        }
      ]
    };
    if (this.pageData.key === 'cash') {
      translate.get('cash').subscribe(res => this.pageData.title = res);
    }
    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'exchangeRate') {
        translate.get('exchangeRate').subscribe(res => value.title = res);
      }
      if (value.key === 'cash') {
        translate.get('cash').subscribe(res => value.title = res);
      }
    });
  }

  ngOnInit(): void {
    console.log('## init cash..');
    super.ngOnInit();
    super.setLoaded();
  }

  ngOnDestroy() {
    this.formService.free(this.subs$);
  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openRetraitCodePartialCashOut() {
    console.log('## open retrait code partial cash out');
    let d = new CashDataModel();
    d.dataCode.component = RetraitCodePartielComponent;
    d.dataCode.componentInfos = RetraitCodePartielInfosComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_RETRAIT_CODE_CASH;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_RETRAIT_CODE_CASH;
    d.dataCode.idServiceItemVerif = ID_SERVICE_ITEM_VERIFICATION_CODE_PARTIEL;
    d.dataCode.serviceItemIdVerif = SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL;
    d.dataCode.serviceItemIdInfos = SERVICE_ITEM_ID_INFORMATION_CODE_PARTIEL;
    d.dataCode.idServiceItemInfos = ID_SERVICE_ITEM_INFORMATION_CODE_PARTIEL;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_VERIFICATION_CODE_INTER_PARTIEL;
    this.dialog.open(VerificationCodeComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        cashDataModel: d
      }
    });

  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openRetraitCodeAllCashOut() {
    console.log('## open retrait code all cash out');
    let d = new CashDataModel();
    d.dataCode.component = RetraitCodeCompletComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_RETRAIT_ALL_CODE_CASH;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_RETRAIT_CODE_CASH;
    d.dataCode.idServiceItemVerif = ID_SERVICE_ITEM_VERIFICATION_CODE_COMPLET;
    d.dataCode.serviceItemIdVerif = SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET;
    d.dataCode.serviceItemIdInfos = SERVICE_ITEM_ID_INFORMATION_CODE_COMPLET;
    d.dataCode.idServiceItemInfos = ID_SERVICE_ITEM_INFORMATION_CODE_COMPLET;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET;
    this.dialog.open(VerificationCodeComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        cashDataModel: d
      }
    });

  }

  /**
   * Ouvre le template html {@link TransfertCompteComponent}
   * dans un popup.
   */
  openTransfertCompte() {
    console.log('## open tranfert compte');
    let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_TRANSFER_COMPTE;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_TRANSFER_COMPTE;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_TRANSFER_COMPTE_INTER;
    this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        cashDataModel: d
      }
    });

  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openEnvoiCode() {
    console.log('## open envoi code');
    this.openService.openEnvoieCodeService();
  }

  initTabs() {
    this.TabsEnvoiCode = false;
    this.TabsRetraitCode = false;
    this.TabsTransFert = false;
  }

  activeTabsEnvoiCode() {
    this.TabsEnvoiCode = true;
    this.TabsRetraitCode = false;
    this.TabsTransFert = false;
    //fils
    this.RetraitCodeValide = false;
    this.AllcashOutPane = false;
    this.PartialCashPane = false;
  }

  activeTabsRetraitCode() {
    this.TabsEnvoiCode = false;
    this.TabsRetraitCode = true;
    this.TabsTransFert = false;
    //fils
    this.RetraitCodeValide = false;
    this.AllcashOutPane = false;
    this.PartialCashPane = false;
  }

  activeTabsTransFert() {
    this.TabsTransFert = true;
    this.TabsEnvoiCode = false;
    this.TabsRetraitCode = false;
    //fils
    this.RetraitCodeValide = false;
    this.AllcashOutPane = false;
    this.PartialCashPane = false;
  }

  AllcashOut() {
    this.AllcashOutPane = true;
    this.PartialCashPane = false;
  }

  PartialcashOut() {
    this.AllcashOutPane = false;
    this.PartialCashPane = true;
  }

  initTransfertCode() {
    this.transfertCode = this.formBuilder.group({
      numberAccount: [''],
      senderfName: ['', [Validators.required]],
      senderlName: ['', [Validators.required]],
      senderNumTel: ['', [Validators.required]],
      password: ['', [Validators.required]],
      sentAmount: ['', [Validators.required]],
      //
      labelCountry: ['', Validators.required],
      receiverfName: ['', Validators.required],
      receiverlName: ['', Validators.required],
      receiverNumTel: ['', [Validators.required]],
      ReceivedAmount: [],
      serviceItemId: [102],
      preferenceCurrency: ['XOF']
    });
  }

  initRetraitCode() {
    this.retraitCode = this.formBuilder.group({
      envoiCode: ['', [Validators.required]]
    });
  }

  initAllCashOutForm() {
    this.allCachOutForm = this.formBuilder.group({
      identityType: ['', [Validators.required]],
      identityNumber: ['', [Validators.required]],
      password: ['', [Validators.required]],
      valueCode: [''],
      serviceItemId: [''],
      receivedAmount: [''],
      numberAccount: [''],
      receiverNumTel: [''],
      senderId: [''],
      receiverId: ['']
    });
  }

  //for partial Cash Out
  initPartialCashOutForm() {
    this.PartialCachOutForm = this.formBuilder.group({
      identityType: ['', [Validators.required]],
      identityNumber: ['', [Validators.required]],
      password: ['', [Validators.required]],
      valueCode: [''],
      serviceItemId: [''],
      receivedAmount: ['', [Validators.required]],
      numberAccount: [''],
      receiverNumTel: [''],
      senderId: [''],
      receiverId: ['']
    });
  }

  //for transfert Compte
  initTransfertCompteForm() {
    this.TransfertCompteForm = this.formBuilder.group({
      phoneNumberDest: ['', [Validators.required]],
      sentAmount: ['', [Validators.required]],
      password: ['', [Validators.required]]

    });
  }

  sendRetraitCode(valid: boolean) {
    if (valid) {
      this.loadingCodeForm = true;

      setTimeout(() => {
        this.loadingCodeForm = false;
        this.retraitCode.reset();
      }, 1000);
    }
  }

  resetRetraitCodeForm() {
    this.retraitCode.reset();
    this.loadingCodeForm = false;
  }

  //All Cash Out Reset
  ResetAllCashOut() {
    this.allCachOutForm.reset();
    this.loadingAllCashOut = false;
  }

  //partial Cash out
  ResetPartialCashOut() {
    this.PartialCachOutForm.reset();
    this.loadingPartialCashOut = false;


  }

  //For All cash out
  sendAllCashOut(valid: boolean) {
    if (valid) {
      this.loadingAllCashOut = true;

      setTimeout(() => {
        this.loadingAllCashOut = false;
        this.allCachOutForm.reset();
      }, 1000);
    }
  }

  //Partial Cash Out
  sendPartialCashOut(valid: boolean) {
    if (valid) {
      this.loadingPartialCashOut = true;

      setTimeout(() => {
        this.loadingPartialCashOut = false;
        this.PartialCachOutForm.reset();
      }, 1000);
    }
  }

  //Partial Cash Out
  sendTransfertCompte(valid: boolean) {
    if (valid) {
      this.loadingTransfertCompte = true;

      setTimeout(() => {
        this.loadingTransfertCompte = false;
        this.TransfertCompteForm.reset();
      }, 1000);
    }
  }

  //Valide All Cash Out
  DoneAllCachOutForm(modalRetaitCodeBody: any) {
    //init Val
    this.allCachOutForm.value.valueCode = this.resultRetraitCode.valueCode;
    this.allCachOutForm.value.serviceItemId = 102;
    this.allCachOutForm.value.receivedAmount = this.resultRetraitCode.amount;
    this.allCachOutForm.value.numberAccount = this.accountNumber;
    this.allCachOutForm.value.senderId = this.resultRetraitCode.senderId;
    this.allCachOutForm.value.receiverId = this.resultRetraitCode.receiverId;

    this.transactionsService.transferCode(this.allCachOutForm.value).subscribe((rep) => {
      console.log('### SUCCESS Retrait ALL CASH OUT', rep);
      //setTimeout(()=>{},1000);
      this.finalAllCashOutVal = rep;
      this.openModal(modalRetaitCodeBody, 'Resultats du retrait CODE All CASH', null, { closeButton: true, overlayClose: false });
      this.allCachOutForm.reset();
    }, err => {
      console.log('### ERROR Retrait ALL CASH OUT', err);
    });
  }

  //Valider Partial Cash OUT
  DonePartialCashOut(modalRetaitCodeBody) {
    //init Val
    this.PartialCachOutForm.value.valueCode = this.resultRetraitCode.valueCode;
    this.PartialCachOutForm.value.serviceItemId = 102;
    this.PartialCachOutForm.value.receiverNumTel = this.resultRetraitCode.receiverNumTel;
    //this.PartialCachOutForm.value.receivedAmount= this.resultRetraitCode.amount;
    this.PartialCachOutForm.value.numberAccount = this.accountNumber;
    this.PartialCachOutForm.value.senderId = this.resultRetraitCode.senderId;
    this.PartialCachOutForm.value.receiverId = this.resultRetraitCode.receiverId;

    this.transactionsService.withdrawalCode(this.PartialCachOutForm.value).subscribe((rep) => {
      console.log('### SUCCESS Retrait PARTIAL CASH OUT', rep);
      //setTimeout(()=>{},1000);
      this.finalAllCashOutVal = rep;
      this.openModal(modalRetaitCodeBody, 'Resultats du retrait CODE PARTIAL CASH', null, { closeButton: true, overlayClose: false });
      this.PartialCachOutForm.reset();
    }, err => {
      console.log('### ERROR Retrait PARTIAL CASH OUT', err);
    });

  }

  //Transfert Compte Valide
  TransfertCompteValide() {
    this.TransfertCompteForm.value.phoneNumberDest = 221 + this.TransfertCompteForm.value.phoneNumberDest;
    this.transactionsService.withdrawalv2(this.TransfertCompteForm.value).subscribe((rep) => {
      console.log('### SUCCESS Transfert compte ', rep);
      this.transfertCompteSuccess = true;
      //setTimeout(()=>{},1000);
      //this.finalAllCashOutVal = rep;
      //this.openModal(modalRetaitCodeBody,"Resultats du retrait CODE All CASH", null, { closeButton: true, overlayClose: false });
      this.TransfertCompteForm.reset();
    }, err => {
      this.transfertCompteFail = true;
      console.log('### ERROR Transfert compte', err);
      this.transfertCompteERROR = err;
    });
  }

  //for login
  login(pwd: any): any {
    const Auth = { 'username': this.CurentUserCuser.login, 'password': pwd };
    console.log('### DATA LOGIN', Auth);
    setTimeout(() => {
      this.accountService.login(Auth).subscribe((result) => {
        console.log('### LOGIN result', result);
        return true;
      }, Err => {
        console.log('### Mot De passe invalide', Err);
      });
    }, 500);
  }

  getCurrentCuser() {
    this.cUserService.findOneByUserLogin(this.CurentUser.login).subscribe((response) => {
      this.CurentUserCuser = response;
      console.log('CUSER POINT CASH', this.CurentUserCuser);
      //console.log("Current User",this.CurentUser);
      this.getCurrentMobileAccount();
    }, (err) => {
      console.log(err);
    });
  }

  getCurrentMobileAccount() {
    this.mAccountService.findAllAccountsByCUserId(this.CurentUserCuser.id)
      .pipe(
        filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
        map((res: HttpResponse<IMobileAccount[]>) => res.body)
      )
      .subscribe((val: IMobileAccount[]) => {
        console.log('## mobiles accounts: ', val);
        const res = val.filter(m => (m.status === STATUS.actived));
        console.log('## res:', res);
        this.cUserMobileAccount = res['0'];
        //  this.cUserMobileAccount = val['0'];
        this.accountNumber = this.cUserMobileAccount.numberAccount;
        console.log('NUMBER Account', this.accountNumber);
      }, err => {
        console.log(err);
      });
  }

  getCountryByLabel(label) {
    this.getData(`${BASE_URL}countries/label/${label}`, 'country', 'initCountryDestID');
  }

  initCountryDestID() {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );
    setTimeout(() => {
      this.CountryDestID = this.country.mId;
      console.log('### DESt Country ID', this.CountryDestID);
    }, 0);
  }

  /**
   * Permet d'effectuer un envoie code.
   * @param forModal
   * @param forfooter
   * @constructor
   */
  envoiCode(forModal: any, forfooter: any) {
    console.log('## envoie code');
    this.loadingMessagesForm = true;
    this.successTransfert = false;
    this.errorTransfert = false;
    this.getCountryByLabel(this.transfertCode.value.labelCountry);
    this.transfertCode.value.numberAccount = this.accountNumber;
    console.log('## number account: ', this.transfertCode.value.numberAccount);
    console.log('### POST:', this.transfertCode.value);
    setTimeout(
      () => {
        if (this.CurentUserCuser.countryId === this.CountryDestID) {//transfert National
          console.log('transfert NATIONAL');
          this.transactionsService.transferCode(this.transfertCode.value)
            .subscribe((rep) => {
              console.log('### SUCCESS Transfert Code', rep);
              this.manageMessageWhenSucceed(rep);
              this.showDetailsEnvoieCode(forModal,
                'Details Transaction', null,
                {
                  closeButton: true,
                  overlayClose: false
                }); // after 700ms
            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        } else {//transfert International api/transactions/transferCodeInter
          console.log('transfert INTERNATIONAL');
          console.log('CUSER CURRENT', this.CurentUserCuser.countryId);
          this.transactionsService.transferCodeInter(this.transfertCode.value)
            .subscribe((rep) => {
              console.log('### SUCCESS Transfert Code', rep);
              this.manageMessageWhenSucceed(rep);
              this.showDetailsEnvoieCode(forModal,
                'Details Transaction', null,
                {
                  closeButton: true,
                  overlayClose: false
                }); // after 700ms
            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        }
      },
      1000
    );
  }

  /**
   * Affiche les détails de la transaction
   * lorsqu'elle a réussi.
   * @param modal
   * @param title
   * @param footer
   * @param options
   */
  showDetailsEnvoieCode(modal, title: string, footer, options) {
    setTimeout(() => {
      this.openModal(modal, title, footer, options);
    }, TIME_MEDIUM_REQUEST);
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenError() {
    this.successTransfert = false;
    this.loadingMessagesForm = false;
    this.errorTransfert = true;
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenSucceed(response) {
    this.errorTransfert = false;
    this.loadingMessagesForm = false;
    this.successTransfert = true;
    this.ReponseTransfert = response;
    this.transfertCode.reset();
  }

  retraitCodeSuccess() {
    this.TabsRetraitCode = false;
    this.RetraitCodeValide = true;
  }

  DisableRetraitCodeSuccess() {
    this.TabsRetraitCode = true;

    this.RetraitCodeValide = false;
    this.AllcashOutPane = false;
    this.PartialCashPane = false;
  }

  retraitCash() {
    //GetMapping("api/codes/valueCode/{code}")
    console.log('### RETRAIT', this.retraitCode.value.envoiCode);
    this.codeService.findByCode(this.retraitCode.value.envoiCode).subscribe((result) => {
      console.log('### RETRAIT CASH RESULT', result);
      this.resultRetraitCode = result;
      this.retraitCode.reset();
      this.retraitCodeSuccess();
      //this.openModal(modalRetaitCodeBody,"Retrait Code Result");
    }, err => {
      console.log(err);
    });
  }

  getColorbyReponse() {
    if (this.resultRetraitCode.status === 'ACTIVED') {
      return 'black';
    } else {
      return 'red';
    }
  }

  //Initialiser les Country pour le Select
  initCountry() {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );
    setTimeout(() => {
      //console.log("COUNTRIES",this.countries);
      this.countries?.forEach(element => {
        this.countrylist.push({ 'label': element.label, 'value': element.label });
      });
    });
  }

  getCountrybyLabel(label) {
    this.countryService.findByLabel(label).subscribe((val) => {
      this.SelectedContry = val;
    });
  }

  resetMessagesForm() {
    this.transfertCode.reset();
    this.loadingMessagesForm = false;
  }

  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options
    });
  }
}
