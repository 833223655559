import { Routes } from '@angular/router';
import { ConstantCosts } from '../costs/costs.url';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';
import { CostsComponent } from '../costs/costs.component';
import { ConstantCostsDetails } from './costs-details.url';
import { CostsDetailsComponent } from './costs-details.component';

/**
 * Ensemble des routes pour gérer les détails de coûts.
 */
export const COSTS_DETAILS_ROUTE: Routes = [
  {
    path: ConstantCostsDetails.url.COSTS.COSTS_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantCostsDetails.url.COSTS.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantCostsDetails.url.COSTS.LIST,
        component: CostsDetailsComponent
      }
    ]
  }
];