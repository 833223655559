import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { AgmCoreModule } from '@agm/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FullCalendarModule } from '@fullcalendar/angular';

import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTableModule } from 'ng-zorro-antd/table';

import { DeleteOutline } from '@ant-design/icons-angular/icons';

const icons = [DeleteOutline];

import { MessageConfig, NzConfigService } from 'ng-zorro-antd/core/config';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';

import { HighlightModule } from 'ngx-highlightjs';

import { UIModule } from '../ui/ui.module';
import { LayoutModule } from '../layout/layout.module';
import { BasePageComponent } from './base-page';

import { PageAnalyticsComponent } from './dashboards/analytics';
import { PageButtonsComponent } from './ui/components/buttons';
import { PageSwitchersComponent } from './ui/components/switchers';
import { PageInputsComponent } from './ui/components/inputs';
import { PageSimpleTablesComponent } from './ui/tables/simple-tables';
import { PageSortingTableComponent } from './ui/tables/sorting-table';
import { PageSearchTableComponent } from './ui/tables/search-table';
import { PageFilterTableComponent } from './ui/tables/filter-table';
import { PagePaginationTableComponent } from './ui/tables/pagination-table';
import { PageFormElementsComponent } from './ui/forms/form-elements';
import { PageFormLayoutsComponent } from './ui/forms/form-layouts';
import { PageFormValidationComponent } from './ui/forms/form-validation';
import { PageGoogleMapsComponent } from './ui/maps/google-maps';
import { PageWorldMapComponent } from './ui/maps/world-map';
import { PageTypographyComponent } from './ui/typography';
import { PageIconsOptionsComponent } from './ui/icons/icons-options';
import { PageIconsIfComponent } from './ui/icons/icons-if';
import { PageIconsSliComponent } from './ui/icons/icons-sli';
import { Page404Component } from './errors/page-404';
import { Page500Component } from './errors/page-500';
import { Page503Component } from './errors/page-503';
import { Page505Component } from './errors/page-505';
import { PageLeafletMapsComponent } from './ui/maps/leaflet-maps';
import { PageInvoiceComponent } from './apps/service-pages/invoice';
import { PageInvoicesComponent } from './apps/service-pages/invoices';
import { PagePricingComponent } from './apps/service-pages/pricing';
import { PageTimelineComponent } from './apps/service-pages/timeline';
import { PageUserProfileComponent } from './apps/user-pages/user-profile';
import { PageEditAccountComponent } from './apps/user-pages/edit-account';
import { PageCalendarComponent } from './apps/service-pages/calendar';
import { PageSignInComponent } from './apps/authentication/sign-in';
import { PageSignUpComponent } from './apps/authentication/sign-up';
import { PageSettingsComponent } from './settings';
import { PageCardsComponent } from './ui/components/cards';
import { PageListsComponent } from './ui/components/lists';
import { PageSelectsComponent } from './ui/components/selects';
import { PageAutocompletesComponent } from './ui/components/autocompletes';
import { PageTextareasComponent } from './ui/components/textareas';
import { PageAlertsComponent } from './ui/components/alerts';
import { PageRadioButtonsComponent } from './ui/components/radio-buttons';
import { PageNg2ChartsComponent } from './ui/charts/ng2-charts';
import { PageNgxChartsComponent } from './ui/charts/ngx-charts';
import { PageNgxEchartsComponent } from './ui/charts/ngx-echarts';
import { PageBadgesComponent } from './ui/components/badges';
import { PageProgressComponent } from './ui/components/progresses';
import { PageCheckboxesComponent } from './ui/components/checkboxes';
import { PageCircleProgressesComponent } from './ui/components/circle-progresses';
import { PageRatingsComponent } from './ui/components/ratings';
import { PageECommerceComponent } from './dashboards/e-commerce';
import { PageDashboardComponent } from './dashboards/dashboard';
import { PageAvatarsComponent } from './ui/components/avatars';
import { PageDropdownsComponent } from './ui/components/dropdowns';
import { PageModalWindowsComponent } from './ui/components/modal-windows';
import { PageResetPasswordComponent } from './apps/authentication/reset-password';
import { PageVerifyAccountComponent } from './apps/authentication/verify-account';
import { PageConnectionsComponent } from './apps/user-pages/connections';
import { ErrorBasePageComponent } from './errors/error-base-page';
import { PageWidgetsComponent } from './widgets';
import { PageGroupsComponent } from './apps/user-pages/groups';
import { PageDatePickerComponent } from './ui/forms/date-picker';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { PageSliderComponent } from './ui/forms/slider';
import { PageUploadComponent } from './ui/forms/upload';
import { HomeComponent } from './entities/home/home.component';
import { CUserComponent } from './entities/c-user/c-user.component';
import { MoniesComponent } from './entities/monies/monies.component';
import { CountriesComponent } from './entities/countries/countries.component';
import { TransactionsComponent } from './entities/transactions/transactions.component';
import { ExchangeRateComponent } from './entities/exchange-rate/exchange-rate.component';
import { ServiceItemsComponent } from './entities/service-items/service-items.component';
import { AccountsRessourceTypeComponent } from './entities/acc-ress-type/list/acc-ress-type-list.component';
import { CuserDetailManageComponent } from './entities/cuser-detail-manage/cuser-detail-manage.component';
import { ApiKeyComponent } from './entities/api-key/api-key.component';
import { ViewAccountComponent } from './entities/view-account/view-account.component';
import { CashComponent } from './entities/cash/cash.component';
import { MobileMoneyComponent } from './entities/mobile-money/mobile-money.component';
import { AchatCreditComponent } from './entities/achat-credit/achat-credit.component';
import { MaterialModule } from '../material.module';
import { ShowTransactionComponent } from './entities/view-account/show-transaction/show-transaction.component';
import { CUserAddKeyComponent } from './entities/c-user/add/c-user-add-key/c-user-add-key.component';
import { DepotComponent } from './entities/mobile-money/depot/depot.component';
import { RetraitComponent } from './entities/mobile-money/retrait/retrait.component';
import { RetraitCodeComponent } from './entities/mobile-money/retrait-code/retrait-code.component';
import { RetraitCodeValidComponent } from './entities/mobile-money/retrait-code-valid/retrait-code-valid.component';
import { AllCashOutComponent } from './entities/mobile-money/all-cash-out/all-cash-out.component';
import { PartialCashOutComponent } from './entities/mobile-money/partial-cash-out/partial-cash-out.component';
import { HeaderFormComponent } from '../shared/layouts/header-form/header-form.component';
import { GroupFormComponent } from '../shared/layouts/group-form/group-form.component';
import { BodyFormComponent } from '../shared/layouts/body-form/body-form.component';
import { FooterFormComponent } from '../shared/layouts/footer-form/footer-form.component';
import { GroupFormSelectComponent } from '../shared/layouts/group-form-select/group-form-select.component';
import { CancelBtnComponent } from '../shared/layouts/cancel-btn/cancel-btn.component';
import { ErrorInputComponent } from '../shared/constant/error input/error-input.component';
import { CuserDetailManageModule } from './entities/cuser-detail-manage/cuser-detail-manage.module';
import { ReclamationComponent } from './entities/view-account/reclamation/reclamation.component';
import { RollBackReclamationComponent } from './entities/view-account/roll-back-reclamation/roll-back-reclamation.component';
import { ConfirmReclamationComponent } from './entities/view-account/confirm-reclamation/confirm-reclamation.component';
import { ValidConfirmReclamationComponent } from './entities/view-account/valid-confirm-reclamation/valid-confirm-reclamation.component';
import { ShowValueKeyComponent } from './entities/api-key/show-value-key/show-value-key.component';
import { DefaultValue } from '../shared/services/data-show.pipe';
import { UpperOneLetterPipe } from '../shared/services/upper-one-letter.pipe';
import { BaseDynamicComponent } from '../shared/layouts/base-dynamic.component';
import { MatSpinnerMediumComponent } from '../shared/layouts/mat-spinner-medium/mat-spinner-medium.component';
import { FooterFormNextComponent } from '../shared/layouts/footer-form-next/footer-form-next.component';
import { NzButtonModule } from 'ng-zorro-antd/button';



registerLocaleData(en);

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ChartsModule,
    NgxChartsModule,
    NgxEchartsModule,
    NzMessageModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAbIFQ5ffgouATqs-sp8hgQf3zV4dTLzaU',
    }),
    NzButtonModule,
    LeafletModule,
    HighlightModule,
    FullCalendarModule,
    UIModule,
    LayoutModule,
    NzTableModule
    
  ],
  exports: [NzButtonModule, MaterialModule, MatSpinnerMediumComponent, BaseDynamicComponent, CancelBtnComponent,
    HeaderFormComponent, UpperOneLetterPipe, DefaultValue, GroupFormComponent,
    BodyFormComponent, FooterFormNextComponent,
    FooterFormComponent, GroupFormSelectComponent, FooterFormNextComponent,
    ErrorInputComponent],
  declarations: [
    FooterFormNextComponent,
    MatSpinnerMediumComponent,
    BaseDynamicComponent,
    UpperOneLetterPipe,
    DefaultValue,
    ErrorInputComponent,
    CancelBtnComponent,
    BodyFormComponent,
    FooterFormComponent,
    GroupFormSelectComponent,
    GroupFormComponent,
    HeaderFormComponent,
    BasePageComponent,
    PageAnalyticsComponent,
    PageButtonsComponent,
    PageInputsComponent,
    PageSwitchersComponent,
    PageBadgesComponent,
    PageSimpleTablesComponent,
    PageSortingTableComponent,
    PageSearchTableComponent,
    PageFilterTableComponent,
    PagePaginationTableComponent,
    PageFormElementsComponent,
    PageFormLayoutsComponent,
    PageFormValidationComponent,
    PageGoogleMapsComponent,
    PageWorldMapComponent,
    PageIconsOptionsComponent,
    PageIconsIfComponent,
    PageIconsSliComponent,
    Page404Component,
    Page500Component,
    Page503Component,
    Page505Component,
    PageLeafletMapsComponent,
    PageInvoiceComponent,
    PageInvoicesComponent,
    PagePricingComponent,
    PageTimelineComponent,
    PageUserProfileComponent,
    PageEditAccountComponent,
    PageCalendarComponent,
    PageSignInComponent,
    PageTypographyComponent,
    PageSignUpComponent,
    PageSettingsComponent,
    PageCardsComponent,
    PageListsComponent,
    PageSelectsComponent,
    PageAutocompletesComponent,
    PageTextareasComponent,
    PageAlertsComponent,
    PageProgressComponent,
    PageCheckboxesComponent,
    PageCircleProgressesComponent,
    PageRadioButtonsComponent,
    PageRatingsComponent,
    PageECommerceComponent,
    PageDashboardComponent,
    PageAvatarsComponent,
    PageNg2ChartsComponent,
    PageNgxChartsComponent,
    PageNgxEchartsComponent,
    PageDropdownsComponent,
    PageModalWindowsComponent,
    PageResetPasswordComponent,
    PageVerifyAccountComponent,
    PageConnectionsComponent,
    ErrorBasePageComponent,
    PageWidgetsComponent,
    PageGroupsComponent,
    PageDatePickerComponent,
    PageSliderComponent,
    PageUploadComponent,
    HomeComponent,
    
    TransactionsComponent,
    
    ApiKeyComponent,
    ViewAccountComponent,
    CashComponent,
    MobileMoneyComponent,
    AchatCreditComponent,
    ShowTransactionComponent,
    DepotComponent,
    RetraitComponent,
    RetraitCodeComponent,
    RetraitCodeValidComponent,
    AllCashOutComponent,
    PartialCashOutComponent,
    ReclamationComponent,
    RollBackReclamationComponent,
    ConfirmReclamationComponent,
    ValidConfirmReclamationComponent,
    ShowValueKeyComponent
    
    
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },/*
    {
      provide: NZ_MESSAGE_CONFIG,
      useValue: {
        nzDuration: 300000,
        nzMaxStack: 7,
        nzPauseOnHover: true,
        nzAnimate: true,
      },
    },*/
  ],
})
export class PagesModule {}
