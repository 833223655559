import { HttpClient, HttpResponse } from '@angular/common/http';
import { IExchangeRate } from '../../../model/exchange-rate.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TIME_MIN_REQUEST } from '../../../shared/constant/request.contant';
import { createRequestOption } from '../../../util/request-util';
import { BASE_URL, BASE_URL_ADMIN } from '../../../../environments/environment';
import { IVersionExchangeRate } from '../../../model/version-exchange-rate.model';
import { ICountry } from '../../../model/country.model';

type EntityResponseType = HttpResponse<IVersionExchangeRate>;
type EntityArrayResponseType = HttpResponse<IVersionExchangeRate[]>;

/**
 * Permet de faire appel aux api permettant de gérer
 * les versions d'exchange rate.
 */
@Injectable({ providedIn: 'root' })
export class VersionExchangeRateService {

  public resourceUrl = BASE_URL_ADMIN + '/version-exchange-rates';
  public resourceNotLinkUrl = BASE_URL_ADMIN + '/version-exchange-rates/not-link';

  private exchangeRate = new Subject<IVersionExchangeRate[]>();
  // l'exchange Rate sélectionné dans la liste.
  private exchangeRateSelect = new BehaviorSubject<IVersionExchangeRate>(undefined);


  constructor(protected http: HttpClient) {}

  /**
   * Récupère la liste de versions d'exhcange rate n'ayant pas encore
   * été liées à un exchante rate.
   */
  getList(): Observable<EntityArrayResponseType> {
    return this.http.get<IVersionExchangeRate[]>(this.resourceNotLinkUrl,
      { observe: 'response' });
  }

  update(country: IVersionExchangeRate): Observable<HttpResponse<IVersionExchangeRate>> {
    return this.http.put<IVersionExchangeRate>(this.resourceUrl, country,
      { observe: 'response' });
  }

  create(exchangeRate: IVersionExchangeRate): Observable<EntityResponseType> {
    return this.http.post<IVersionExchangeRate>(this.resourceUrl, exchangeRate,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des exchange rate
   * pour être partagée.
   */
  getAllVersionExchangeRate() {
    setTimeout(() => {
      this.query().subscribe(res => {
        console.log('## Success get Version exchange rate list');
        this.setVersionExchangeRateObs((res.body) ? res.body : []);
      });
    }, TIME_MIN_REQUEST)
  }

  getVersionExchangeRateSelectObs() : Observable<IVersionExchangeRate>{
    return this.exchangeRateSelect.asObservable();
  }

  setVersionExchangeRateSelectObs(c: IVersionExchangeRate) {
    this.exchangeRateSelect.next(c);
  }

  getVersionExchangeRateObs() {
    return this.exchangeRate.asObservable();
  }

  setVersionExchangeRateObs(c: IVersionExchangeRate[]) {
    this.exchangeRate.next(c);
  }

  /**
   * Récupère la liste des versions d'exchange rate.
   * @param req
   */
  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IVersionExchangeRate[]>(this.resourceUrl,
      { params: options, observe: 'response' });
  }
}
