import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './show-value-key.component.html',
  styleUrls: ['./show-value-key.component.css']
})
export class ShowValueKeyComponent implements OnInit {
  apikeyValue:string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {apikeyValue: string}) 
  { 
    this.apikeyValue= data.apikeyValue;

  }

  ngOnInit(): void {
  }

}
