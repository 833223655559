import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appRemoveFocus]'
})
export class RemoveFocusDirective implements OnInit, AfterViewInit{
  private el: ElementRef;
  constructor(e: ElementRef) {
    this.el = e;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement.blur();
    }, 0)
  }

  ngOnInit(): void {
  }
}
