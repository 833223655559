import { BASE_URL, BASE_URL_ADMIN } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { createRequestOption } from '../../../util/request-util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAccountRessource } from '../../../model/account-ressource.model';

@Injectable({ providedIn: 'root' })
export class AccountRessourceService {
  public resourceStatusUrl = BASE_URL_ADMIN + '/account-ressource/status';
  public resourceStatusByUserUrl = BASE_URL + 'user/account-ressource/status';
  public resourceUrlActivate = BASE_URL_ADMIN + '/activate/account-ressource';
  public resourceUrlByUserActivate = BASE_URL + 'activate/account-ressource';
  public resourceUrlActivateService = BASE_URL_ADMIN + '/service_permis';

  public resourceUrl = BASE_URL + 'account-ressource';
  public resourceUrl3 = BASE_URL_ADMIN + '/account-ressources';
  public resourceUserUrl3 = BASE_URL + 'account-ressources';
  public resourcePartenerUrl = BASE_URL + 'account-ressource/partener';
  public resourceUrl2 = BASE_URL + 'account-ressource/code';

  constructor(protected http: HttpClient) {}

  create(accountRessource: IAccountRessource): Observable<HttpResponse<IAccountRessource>> {
    return this.http.post<IAccountRessource>(this.resourceUrl, accountRessource,
      { observe: 'response' });
  }

  activateService(accountRessource: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.resourceUrlActivateService, accountRessource,
      { observe: 'response' });
  }

  activateByUser(accountRessource: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.resourceUrlByUserActivate, accountRessource,
      { observe: 'response' });
  }

  activate(accountRessource: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.resourceUrlActivate, accountRessource,
      { observe: 'response' });
  }

  /**
   * Met à jour le account_Ressource d'un Point CASH. Il faut posséder
   * le ROLE_PARTENER pour effectuer la mise à jour. Elle ne s'effectue
   * que pour les POINT CASH créé par le PARTENER souhaitant effectuer
   * la modification.
   * @param accountRessource le account ressource contenant les informations
   * pour la modification.
   */
  updateByPartener(accountRessource: IAccountRessource): Observable<HttpResponse<IAccountRessource>> {
    return this.http.put<IAccountRessource>(this.resourcePartenerUrl, accountRessource,
      { observe: 'response' });
  }

  updateStatusByUser(accountRessource: any): Observable<HttpResponse<IAccountRessource>> {
    return this.http.put<IAccountRessource>(this.resourceStatusByUserUrl, accountRessource,
      { observe: 'response' });
  }

  updateStatus(accountRessource: any): Observable<HttpResponse<IAccountRessource>> {
    return this.http.put<IAccountRessource>(this.resourceStatusUrl, accountRessource,
      { observe: 'response' });
  }

  update(accountRessource: IAccountRessource): Observable<HttpResponse<IAccountRessource>> {
    return this.http.put<IAccountRessource>(this.resourceUrl, accountRessource,
      { observe: 'response' });
  }
  

  find(id: number): Observable<HttpResponse<IAccountRessource>> {
    return this.http.get<IAccountRessource>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }

  findAllAccountRessourceByMAccountUserId(id: number): Observable<HttpResponse<IAccountRessource[]>> {
    return this.http.get<IAccountRessource[]>(`${this.resourceUserUrl3}/${id}`,
      { observe: 'response' });
  }


  findAllAccountRessourceByMAccountId(id: number, req?: any): Observable<HttpResponse<IAccountRessource[]>> {
    const options = createRequestOption(req);
    return this.http.get<IAccountRessource[]>(`${this.resourceUrl3}/${id}`,
      { params: options, observe: 'response' });
  }

  query(req?: any): Observable<HttpResponse<IAccountRessource[]>> {
    const options = createRequestOption(req);
    return this.http.get<IAccountRessource[]>(this.resourceUrl,
      { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }
}
