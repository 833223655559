
export const CONTENT_BTN_CLASS = 'btn-cs-row'
export const MT_FORM_CASH_CLASS = 'mt-4'

export const CANCEL_BG_COLOR = '#ffffff'
export const CONTAINER_DIALOG_POPUP = 'container-dialog'
export const VIEW_ERROR = 'error'
export const VIEW_VALIDATION = 'info'
export const BEFORE_ICON_RESET = 'icofont-refresh'
export const OUTLINE_RESET = true;
export const OUTLINE_CANCEL = true;
export const BEFORE_ICON_VALIDATION = 'icofont-location-arrow'
export const BTN_POPUP_CLASS = 'buttonStyle mb-3'
export const BTN_VALIDATION_CLASS = 'btn btn-default'
export const BTN_VALID_CLASS = 'cs-valid'
export const BTN_CANCEL_CLASS = 'btn-default cancel'

export const CONTAINER_BTN_POPUP_CLASS = 'mt-3 pb-5'
/* class pour un conteneur contenant 3 boutons de validation */
export const VALIDATION_BTN3_POPUP_CLASS = 'buttons d-flex col-lg-12 flex-column ' +
  'flex-lg-row justify-content-between';
export const VALIDATION_BTN4_POPUP_CLASS = 'buttons d-flex col-lg-12 flex-column ' +
  'flex-lg-row justify-content-start';
export const VALIDATION_BTN2_POPUP_CLASS = 'col-lg-6 d-flex flex-column ' +
  'flex-lg-row justify-content-between'
export const TITLE_POPUP_CLASS = 'text-center';
export const LABEL_FORM_CLASS = 'little-gray-text';
export const RESULT_REQUEST_SUCCESS_CLASS = 'animated slideInDown faster ' +
  'alert-cs success mb-4 absolute-center';
export const RESULT_REQUEST_ERROR_CLASS = 'animated slideInDown faster ' +
  'alert-cs error mb-4 absolute-center';

export const FORM_CLASS = 'error ps-relative';
export const RESULT_REQUEST_TEXT_SUCCESS_CLASS = 'transaction success ps-relative mb-3';
export const RESULT_REQUEST_TEXT_ERROR_CLASS = 'error ps-relative mb-3';

export const ICON_FONT_REQUEST_CLASS = 'icofont-info-circle';
export const ICON_COLOR_REQUEST_CSS = '#cbf7e6';
export const WARNING_COLOR_CSS = '#ec5757';
export const SUFFIX_ICON_FORM = 'icofont-tick-boxed';
export const REMOVABLE_ICON_REQUEST = true;

export const WIDTH_MEDIUM_POPUP_CLASS = 'mw-md-form-min';
export const MW_MEDIUM_POPUP_CLASS = 'mw-md-form-medium';
export const CONTAINER_POPUP_CLASS = 'col-12 marginCard';
export const CONTAINER_INSIDE_POPUP_CLASS = 'shadow-none form-content';
export const CONTAINER_INSIDE2X_POPUP_CLASS = 'col-lg-12';

export const POPUP_CONTAINER_CLASS = 'popup-container';

