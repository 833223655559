export interface IIpAdressApiKeyDTOS {
  id?: number,
  label?: string,
  ipAdressId?: number,
  apiKeyId?: number,
  description?: string,
  statusId?: number,
  statusLabel?: string,
  createdDate?: string;
  lastModifiedDate?: string;
}

export class IpAdressApiKeyDTOS implements IIpAdressApiKeyDTOS {
  constructor(
    public id?: number,
    public label?: string,
    public ipAdressId?: number,
    public apiKeyId?: number,
    public description?: string,
    public statusId?: number,
    public statusLabel?: string,
    public createdDate?: string,
    public lastModifiedDate?: string
  ) {
  }
}

export interface IIpAdressDTOS {
  apiKeyId?: number;
  ipAddrList?: IIpAdressDTO[];
}

export class IpAdressDTOS implements IIpAdressDTOS {
  constructor(
    public apiKeyId?: number,
    public ipAddrList?: IIpAdressDTO[]
  ) {
  }
}

export interface IIpAdressDTO {
  id?: number;
  label?: string;
  description?: string;
  apiKeyId?: number;
}

export class IpAdressDTO implements IIpAdressDTO {
  constructor(
    public id?: number,
    public label?: string,
    public description?: string,
    public apiKeyId?: number
  ) {
  }
}

export interface IIpAdress {
  id?: number;
  idApiKey?: number;
  ipAddr?: string;
  status?: string;
  createdBy?: string;
  lastModifiedBy?: string;
}

export class IpAdress implements IIpAdress {
  constructor(
    public id?: number,
    public idApiKey?: number,
    public ipAddr?: string,
    public status?: string,
    public createdBy?: string,
    public lastModifiedBy?: string
  ) {
  }
}
