import { AfterViewChecked, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BaseDynamicComponent } from '../../../../shared/layouts/base-dynamic.component';
import { SelectPrefixService } from '../../../../shared/layouts/select-prefix.service';
import { Subject } from 'rxjs';
import { CountryColumnName, ICountryColumnName } from '../../countries/country-column-name';
import { IBuildingFormByStep, INextForm } from '../../cash-dynamic/dynamic-form.init';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IOption } from '../../../../ui/interfaces/option';
import { DynamicService } from '../../../../services/dynamic.service';
import { ColorService } from '../../../../shared/services/color.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { AccountUserService } from '../../../../services/ServiceEntity/account-user.service';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CostsRuleService } from '../../fees/costs-rule.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { DynamicFormModel } from '../../../../model/champs_formulaire/dynamic-form.model';
import { TIME_MEDIUM_REQUEST, TIME_MIN_X_REQUEST } from '../../../../shared/constant/request.contant';
import { IMobileAccount, MobileAccountCash } from '../../../../model/mobile-account.model';
import { STATUS } from '../../../../model/account.model';
import { BASE_URL } from '../../../../../environments/environment';
import { CONTAINER_DIALOG_POPUP, POPUP_CONTAINER_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { Content } from '../../../../ui/interfaces/modal';
import { FileExcelService } from '../../../../services/fileDownload/file-excel.service';
import { saveAs } from 'file-saver';
import {
  ID_SERVICE_ITEM_DEPOT_ORANGE_MONEY,
  ID_SERVICE_ITEM_TRANSFER_CODE,
  ID_SERVICE_ITEM_TRANSFER_COMPTE,
  SERVICE_ITEM_ID_RETRAIT_CODE_CASH,
  SERVICE_ITEM_ID_RETRAIT_CODE_COMPLET_ORANGE_MONEY,
  SERVICE_ITEM_ID_RETRAIT_CODE_ORANGE_MONEY,
  SERVICE_ITEM_ID_TRANSACTION_BASH,
  SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET, SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET_ORANGE_MONEY,
  SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL, SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY, SERVICE_ITEM_TRANSFER_COMPTE_SELF
} from '../../../../shared/constant/service-item.constant';
import { EnvoiCodeValidationService } from '../services/envoi-code-validation.service';
import { DataFormDynamic } from '../services/base-validation.service';
import { TransfertCompteValidationService } from '../services/transfert-compte-validation.service';
import { DepotOrangeMoneyValidationService } from '../../mobile-money/services/depot-orange-money-validation.service';
import { MatTooltip } from '@angular/material/tooltip';
import { PRINCIPAL } from '../../../../model/account-ressource.model';
import { ICashModel } from '../cash-data-model';
import { ServiceItemFils } from '../../../../model/service-item.model';
import { EnvoiCodeInfosTransacComponent } from '../envoi-code-infos-transac/envoi-code-infos-transac.component';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { AccountApiKeyComponent } from '../../account-api-key/account-api-key.component';

/**
 * Permet à une personne quelconque d'envoyer de l'argent
 * à une autre personne.
 */
@Component({
  selector:          'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  animations: [fadeIn],
})
export class DynamicFormComponent extends BaseDynamicComponent
  implements OnInit, OnDestroy, SelectPrefixService{

  private subs$ = new Subject<void>();
  column: ICountryColumnName;



  file: any;

  buildingFormData: IBuildingFormByStep;
  accountNumbersOption: IOption[];

  indicatif: string;

  theDialogRef: MatDialogRef<any>;
  accountsCash: MobileAccountCash[];

  CurentUserApiKey: any;
  CurentUser: any;
  currentMobileAccount: any;
  cUserMobileAccount: any;
  allMobileAccounts: IMobileAccount[];

  cUserMobileAccountFrom: any;

  numberAccountFrom: any;
  numberAccountTo: any;

  numberAccountSelected: any;
  accountNumber: any;
  currency: string;
  numberAccountApiKey: string;
  countrylist: IOption[];
  country: any;
  countries: any;
  SelectedContry: any;

  countryIdCUser: number;

  sentAmount: number;
  countryTrader: string;
  error: string;

  transfertCompteSuccess = false;
  transfertCompteFail = false;
  transfertCompteERROR: any;

  apiKey: string;

  // service fils du parent.
  filsList = [];

  // données à afficher dans le formulaire qui suit.
  dataServ = {};

  showNumberAccountField = false;

  dataForm = new FormData();
  dataDialog: MatDialogRef<any>;

//  @ViewChild('tooltip') manualTooltip: MatTooltip;

  /* Contient toutes les données pour pouvoir effectuer
  * n'importe quelle type de transaction */
  dataObj: DataFormDynamic;

  constructor(
    private dialog: MatDialog,
    protected mAccountService: AccountUserService,
    @Inject(MAT_DIALOG_DATA) public data: ICashModel,
    private depotOrangeMoneyValidationService: DepotOrangeMoneyValidationService,
    private transfertCompteValidationService: TransfertCompteValidationService,
    private envoiCodeValidationService: EnvoiCodeValidationService,
    private fileExcelService: FileExcelService,
    protected dynamicService: DynamicService,
    private colorService: ColorService,
    private accountService: AccountService,
    private formService: FormService,
    private cUserService: CUserService,
    private formulaireService: ChampsFormulaireService,
    private maccountService: AccountUserService,
    private transactionsService: TransactionsService,
    private dialogRef: MatDialogRef<any>,
    private dialogDynamicRef: MatDialogRef<DynamicFormComponent>,
    private columnName: CountryColumnName,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private modal: TCModalService,
    private costsRuleService: CostsRuleService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(dynamicService, store, httpSv);
    console.log('## construct dynamic form');
    console.log('## the data: ', this.data);
    console.log('## the data: ', this.data?.numberAccountSelected);
    this.numberAccountSelected = this.data?.numberAccountSelected;

    this.apiKey = this.data ? this.data.apiKey : this.apiKey;
    // console.log('## api key data: ', this.apiKey);
    this.dataDialog = this.data.theDialog;
    this.numberAccountApiKey = this.data ? this.data.numberAccount : this.numberAccountApiKey;
    this.currency = this.data ? this.data.currency : this.currency;
    this.data.cashDataModel.dialogRef = this.dialogDynamicRef;
    this.theDialogRef = this.dialogRef;
    this.filsList = this.data.cashDataModel.dataCode.serviceItemFilsList;
    this.initializeForm();
    console.log("## currency: ", this.currency);
  }

  ngOnInit(): void {
    console.log('## init dynamic form.');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## get account');
        this.CurentUser = account;
        console.log('POINT CASH', this.CurentUser);
        this.getCurrentUserApiKey(this.apiKey);
        this.getCurrentCuser();
        this.getData('assets/data/table.json', 'tableData',
          'setLoaded');
        let f = new ServiceItemFils();
        f.serviceId = this.data.cashDataModel.dataCode.idServiceItem;
        f.serviceItemId = this.data.cashDataModel.dataCode.serviceItemId;
        this.initializeDataForm(f);
      });
  }

  ngOnDestroy() {
    console.log('## destroy dynamic form');
    this.formService.free(this.subs$);
    if(this.dataDialog) {
     this.dataDialog.close();
    }
  //  this.cashService.clearAllData();
  }

  getAllMobileAccounts() {
    this.mAccountService.findAllCash()
      .subscribe((res: HttpResponse<MobileAccountCash[]>) => {
        console.log('## success find all account with cash');
        const d = (res && res.body) ? res.body : [];
        console.log("## data: ", d);
        console.log("## length: ", d.length);
        this.accountsCash = d;
        let list: IOption[];
        list = [];

        for(let e of this.accountsCash) {
          list.push({ 'label': e.label,
            'value': e.numberAccount});
        }

        this.accountNumbersOption = list;

        this.dataObj.nextFormData.formulaire.patchValue({
          'numberAccountFrom': this.numberAccountSelected
        });

      }, error => {
        console.log('## Error find all account with cash: ', error);
      });
  }

  /**
   * Initialise les données de formulaire.
   * @param f
   */
  initializeDataForm(f: ServiceItemFils) {
    let params = new HttpParams();
    params = params.set('idServiceItem', f.serviceId.toString());
    params = params.set('serviceItemId', f.serviceItemId.toString());
    this.data.cashDataModel.dataCode.idServiceItem = f.serviceId;
    this.data.cashDataModel.dataCode.serviceItemId = f.serviceItemId;
    this.formulaireService.query(this.formulaireService.dynamicFormApi, params)
      .pipe(map(res => res.body))
      .subscribe((res: DynamicFormModel[]) => {
        console.log('## success get list forms');
        console.log('## res: ', res);
        // on récupère les éléments du formulaire de la première étape.
        this.nextForm(res, this.dataObj.nextFormData);

        /* on affiche dans le formulaire le résultat
         de la requête précédente s'il existe. */
        let f = this.dataObj.nextFormData.formulaire;
        Object.keys(this.dataServ).forEach(k => {
          const d = this.dataServ;
          const v = d[k];
          if(v) {
            console.log('## control d: ', k);
            console.log('## control v: ', v);
            f.patchValue({[k]: v});
          }
        });
        this.data.cashDataModel.dataCode.dataServ = this.dataServ;

        this.getAllMobileAccounts();

      }, error => {
        console.log('## error get list forms: ', error);
      });
  }

  /**
   * Initialise les éléments qui vont servir à remplir
   * les données du formulaire.
   */
  initializeForm() {
    this.countrylist = [];
    let transfertCode = this.fb.group({});

    this.buildingFormData = {
      lastIndexForm: 0,
      previousIndexForm: 0,
      dynamicFormModels: [],
      bodyModels: [],
      headerModels: []
    };

    let nextFormData: INextForm = {
      init: false,
      formulaire: transfertCode,
      formBuilder: this.fb,
      dynamicFormModelsDefault: null,
      hasBuiltList: false,
      tabOption: [],
      buildingFormByStep: this.buildingFormData
    };

    this.dataObj = {
      curentUserCuser: undefined,
      countryDestID: undefined,
      data: undefined,
      errorTransfert: undefined,
      loadingMessagesForm: false,
      successTransfert: undefined,
      nextFormData: nextFormData,
      reponseTransfert: undefined,
      forModal: undefined
    };
  }

  getInit() {
    return this.dataObj.nextFormData.init;
  }

  getLabel(label: string) {
    if(this.data.cashDataModel.dataCode.serviceItemId === SERVICE_ITEM_ID_RETRAIT_CODE_CASH
        || this.data.cashDataModel.dataCode.serviceItemId === SERVICE_ITEM_ID_RETRAIT_CODE_ORANGE_MONEY) {
      const currency = (this.data && this.data.currencyValueCode)
        ? this.data.currencyValueCode : '';
      if(label &&
        (label.toLowerCase() === 'montant' || label.toLowerCase() === 'amount')) {
        return label && currency && (currency !== '') ? 'montant à retirer sur la carte'.concat(' (', currency, ') ')
          : 'montant à retirer sur la carte';
      }
    } else if (this.data.cashDataModel.dataCode.serviceItemId
      === SERVICE_ITEM_TRANSFER_COMPTE_SELF) {
      const currency = this.cUserMobileAccountFrom?.preferenceCurrency;
      console.log('## mobile account from:', this.cUserMobileAccountFrom);

      if(label &&
        (label.toLowerCase() === 'montant' || label.toLowerCase() === 'amount')) {
        return label && currency ? label.concat(' (', currency, ') ') : label;
      }

    } else {
      const currency = this.cUserMobileAccount?.preferenceCurrency;
      if(label &&
        (label.toLowerCase() === 'montant' || label.toLowerCase() === 'amount')) {
        return label && currency ? label.concat(' (', currency, ') ') : label;
      }
    }

    return label;
  }

  onFileChange(event) {
    console.log('## onFileChange:', event);
    try {
      if (event.target.files.length > 0) {
        const f = event.target.files['0'];
        this.file = f;
      }
    } catch (e) {
      console.log('## error load file:', e);
    }
  }

  shouldIShowNumberAccountForTransferCompteSelf() {
    const id = this.data.cashDataModel.dataCode.serviceItemId;
    if(id === SERVICE_ITEM_TRANSFER_COMPTE_SELF) {
      return true;
    }
    return false;
  }

  shouldIShowNumberAccountField() {
    const id = this.data.cashDataModel.dataCode.serviceItemId;
    if(id === SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL ||
      id === SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET ||
      id === SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET_ORANGE_MONEY ||
      id === SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY ||
      id === SERVICE_ITEM_TRANSFER_COMPTE_SELF) {
      return this.showNumberAccountField;
    }
    return true;
  }


  /**
   * Permet de récupérer les éléments du formulaire
   * de l'étape qui précède.
   * @param res tous les champs du formulaire.
   */
  buildPreviousForm(res: DynamicFormModel[]) {
    console.log('## not exec')
    this.dataObj.nextFormData.init = false;
    let formList = [];
    if (res.length > 0 && this.buildingFormData.previousIndexForm >= 0) {
      let i = this.buildingFormData.previousIndexForm;
      this.buildingFormData.lastIndexForm = i + 1;
      let next = res[i].formulaire.steps;
      let prev;
      for (; i >= 0; i--) {
        prev = res[i].formulaire.steps;
        if (prev != next) {
          break;
        } else {
          next = prev;
        }
        formList.push(res[i]);
      }
      if (this.buildingFormData.previousIndexForm === -1) {
        this.buildingFormData.previousIndexForm = 0;
      } else {
        this.buildingFormData.previousIndexForm = i;
      }
      this.buildingFormData.dynamicFormModels = formList;
    }
    this.buildAllForm(this.buildingFormData);
    setTimeout(r => {
      this.dataObj.nextFormData.init = true;
    }, TIME_MIN_X_REQUEST);
    this.buildFromEnum(null);
  }

  anyLastIndexForm(d: DynamicFormModel[]) {
    return (this.buildingFormData.lastIndexForm < d.length);
  }

  anyPreviousIndexForm(d: DynamicFormModel[]) {
    return (this.buildingFormData.previousIndexForm > 0);
  }

  getHeaders() {
    return this.buildingFormData.headerModels;
  }

  getPrefix(): string {
    return this.indicatif;
  }

  getPrefixModified(): string {
    if (this.indicatif) {
      return '+' + this.indicatif;
    }
    return '';
  }

  /**
   * Récupère l'utilisateur possédant l'api key
   * pour pouvoir effectuer le transfert d'argent.
   * @param apiKey
   */
  getCurrentUserApiKey(apiKey: string) {
    if(apiKey) {
      this.cUserService.findOneByApiKey(apiKey).subscribe((response) => {
        this.CurentUserApiKey = response.body;
      }, (err) => {
        console.log(err);
      });
    }
  }

  getCurrentCuser() {
    this.cUserService.findOneByUserLogin(this.CurentUser.login)
      .subscribe((response) => {
        this.dataObj.curentUserCuser = response.body;
        console.log('CUSER POINT CASH', this.dataObj.curentUserCuser);
        //console.log("Current User",this.CurentUser);
        this.countryIdCUser = this.dataObj.curentUserCuser?.countryId;
        console.log('## countryId:', this.countryIdCUser);
        this.getCurrentMobileAccount();
      }, (err) => {
        console.log(err);
      });
  }

  hasChange(ev) {
    console.log('## change:', ev);
    console.log('## mobile acc:', this.allMobileAccounts);
    let m: IMobileAccount = this.allMobileAccounts.find( e =>
      e.numberAccount === ev
    )
    console.log("## mobile account:", m);
    this.cUserMobileAccount = m;
    this.accountNumber = this.cUserMobileAccount?.numberAccount;
    this.numberAccountTo = this.cUserMobileAccount?.numberAccount;
  }

  hasChangeFrom(ev) {
    console.log('## change:', ev);
    console.log('## mobile acc:', this.allMobileAccounts);
    let m: IMobileAccount = this.allMobileAccounts.find( e =>
      e.numberAccount === ev
    )
    console.log("## mobile account:", m);
    this.cUserMobileAccountFrom = m;
    this.accountNumber = this.cUserMobileAccountFrom?.numberAccount;
    this.numberAccountFrom = this.cUserMobileAccountFrom?.numberAccount;
  }

  getCurrentMobileAccount() {
    this.maccountService.findAllAccountsByCUserId(this.dataObj.curentUserCuser.id)
      .pipe(
        filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
        map((res: HttpResponse<IMobileAccount[]>) => res.body)
      )
      .subscribe((val: IMobileAccount[]) => {
        console.log('## mobiles accounts: ', val);
        const res = val.filter(m => (m.status === STATUS.actived));
        console.log('## res:', res);
        this.allMobileAccounts = res;
        // this.cUserMobileAccount = res[0];
        // console.log('## res[0]: ', res[0]);
        // this.cUserMobileAccount = val['0'];
        // this.accountNumber = this.cUserMobileAccount?.numberAccount;
        // console.log('NUMBER Account', this.accountNumber);
      }, err => {
        console.log(err);
      });
  }

  getCountryByLabel(label) {
    this.getData(`${BASE_URL}countries/label/${label}`, 'country',
      'initCountryDestID');
  }

  initCountryDestID() {
    setTimeout(() => {
      this.dataObj.countryDestID = this.country.mId;
      console.log('### DESt Country ID', this.dataObj.countryDestID);
    }, 0);
  }

  copyInputMessage(inputElement) {
    console.log('## copy: ', inputElement);
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    console.log('## copy: ', inputElement.value);
  }

  getPrefixColorSelect(): string {
    return this.colorService.getPrefixColorSelect();
  }

  selectCountry(countrySelected) {
    console.log('## country selected: ', countrySelected);
    for (let c of this.countries) {
      const label = c.label;
      if (label && countrySelected) {
        if (label.toLocaleLowerCase() === countrySelected.toLocaleLowerCase()) {
          console.log('## c_id: ', c.id, ' match e_selected_id: ', countrySelected);
          this.indicatif = c.codePhone;
          console.log('## c_id indicatif: ', this.indicatif);
          break;
        }
      }
    }
  }

  isTheTransactionBash() {
    return this.data.cashDataModel.dataCode.serviceItemId === SERVICE_ITEM_ID_TRANSACTION_BASH;
  }

  downloadexcel(): void {
    this.fileExcelService.download().subscribe(blob => {
      console.log('file excel#####', blob);
      saveAs(blob, 'templateExcel.xlsx');
    });
  }

  format(n: any) {
    console.log('## type amount: ', typeof (+n));
    return this.formService.format(+n);
  }

  /**
   * Permet d'effectuer un envoie code.
   * @param forModal
   * @param forfooter
   * @constructor
   */
  envoiCode(forModal: any, forfooter?: any) {
    console.log('## envoie code');
    this.dataObj.countryDestID = this.dataObj.nextFormData
      .formulaire.value[this.columnName.getColumnName().labelCountry];

    console.log('## country test: ', this.dataObj.countryDestID);

    this.dataObj.successTransfert = false;
    this.dataObj.errorTransfert = false;
    // Récupération des données du formulaire.
    const data = this.getFormDataOld(this.dataObj.nextFormData.formulaire,
      this.dataForm);
    data.set('preferenceCurrency', this.cUserMobileAccount?.preferenceCurrency);
    data.set('principal', '' + PRINCIPAL.ZERO);



    // on vérifie si le service est de type transfer compte self
    // si oui on mets la valeur du numberAccount from

    if(this.data.cashDataModel.dataCode.serviceItemId === SERVICE_ITEM_TRANSFER_COMPTE_SELF) {
      data.set('numberAccount', this.accountNumber);

      data.set('numberAccountFrom', this.numberAccountFrom ?
      this.numberAccountFrom : this.numberAccountSelected);

      data.set('numberAccountTo', this.numberAccountTo);

      console.log('## country to add:', this.countryIdCUser);
      data.set('labelCountry', this.countryIdCUser?.toString());
    }



    try {
      console.log("## value code to send:", data.get('valueCode').toString())
      let p = new HttpParams();
      p = p.set('valueCode', data.get('valueCode').toString());
      this.transactionsService.getCurrency(p).subscribe(res => {
        console.log('## success get currency:', res);
        this.data.currencyValueCode = res && res.body ? res.body : null;
      }, error => {
        console.log('## error get currency:', error);
      });
    } catch (e) {
      console.log(e);
    }



    this.data.cashDataModel.getDataCode(data, 'serviceItemId', 'serviceItemId');
    this.data.cashDataModel.getDataCode(data, 'idServiceItem','idServiceItem');
    this.data.cashDataModel.getDataCode(data, 'valueApiKey', 'apiKey');

    // Vérifie si l'utilisateur a rentrée un fichier dans le formulaire.
    if (this.file) {
      data.set('file', this.file);
    }
    // affiche les données du formulaire.
    this.showKeys(data);
    console.log("## currency: ", this.cUserMobileAccount);
    this.dataObj.data = data;
    this.dataObj.forModal = forModal;

    // On vérifie si le service est de type transfert code.
    /*if (this.data.cashDataModel.dataCode.idServiceItem === ID_SERVICE_ITEM_TRANSFER_CODE) {
      this.envoiCodeValidationService.valider(this.dataObj, this.data);
      // on vérifie si le service est de type transfert compte.
    } else if (this.data.cashDataModel.dataCode.idServiceItem === ID_SERVICE_ITEM_TRANSFER_COMPTE) {
      this.transfertCompteValidationService.valider(this.dataObj, this.data);
      // on vérifie si le service est de type dépôt orange money.
    } else if (this.data.cashDataModel.dataCode.idServiceItem === ID_SERVICE_ITEM_DEPOT_ORANGE_MONEY) {
      this.depotOrangeMoneyValidationService.valider(this.dataObj, this.data);
    } else {*/
      console.log('## autres services');
      // this.dataObj.loadingMessagesForm = true;
      this.data.cashDataModel.dataForm = this.dataObj;
        console.log('transfert NATIONAL');

        // appel popup de confirmation s'il reste un seul fils.
        if(!this.filsList || this.filsList.length <= 1) {

          // appel popup de confirmation
          this.dialog.open(EnvoiCodeInfosTransacComponent, {
            minWidth: SIZE_MIN_POPUP,
            minHeight: SIZE_MEDIUM_POPUP,
            panelClass: CONTAINER_DIALOG_POPUP,
            data: this.data
          });
        } else {
          //  this.transfertCode.value.serviceItemId = 102;
          this.transactionsService.execute(data)
            .subscribe((rep) => {
              console.log('## transaction ok');
              console.log('### SUCCESS', rep);
              console.log('### is true');
              this.showNumberAccountField = true;

              // copie des données de 'data' et faire un 'set'.
              // affichage des données
              const d = rep.body ? rep.body.data : undefined;
              if(d) {
                console.log('## data: ', d);
                this.copyData(d, this.dataServ);
              }
              console.log('## data value: ', this.dataServ);

              //   this.dataServ = rep.body && rep.body.data ? rep.body.data : this.dataServ;


              this.filsList.shift();
              // on vérifie si le parent n'a pas de fils.
              if(typeof this.filsList === 'undefined' || this.filsList.length <= 0) {
                this.manageMessageWhenSucceed(rep);
                const body = rep.body;
                if (body && body.valueCode) {
                  this.dataObj.reponseTransfert = body;
                  this.data.cashDataModel.dataForm.reponseTransfert = body;
                  this.showDetailsEnvoieCode(forModal,
                    'Transaction details', null,
                    {
                      closeButton: true,
                      overlayClose: false
                    }); // after 700ms
                }
              } else {
                // le parent a un ou plusieurs fils.
                console.log('## other fils');
                this.initializeForm();
                this.initializeDataForm(this.filsList[0]);
                // this.filsList.shift();
              }

            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        }
  }


  /**
   * Affiche les détails de la transaction
   * lorsqu'elle a réussi.
   * @param modal
   * @param title
   * @param footer
   * @param options
   */
  showDetailsEnvoieCode(modal, title: string, footer, options) {
    console.log('## showDetailsEnvoieCode');
    setTimeout(() => {
      this.openModal(modal, title, footer, options, POPUP_CONTAINER_CLASS);
    }, TIME_MEDIUM_REQUEST);
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenError() {
    this.dataObj.successTransfert = false;
    this.dataObj.loadingMessagesForm = false;
    this.dataObj.errorTransfert = true;
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenSucceed(response) {
    this.dataObj.errorTransfert = false;
    this.dataObj.loadingMessagesForm = false;
    this.dataObj.successTransfert = true;
//    this.ReponseTransfert = response.body;
    this.dataObj.nextFormData.formulaire.reset();
  }

  //Initialiser les Country pour le Select
  initCountry() {
    console.log('## init country');
    setTimeout(() => {
      this.countrylist = [];
      this.countries?.forEach(element => {
        console.log('## country element: ', element);
        this.countrylist.push({ 'label': element.label, 'value': element.label });
      });
    });
  }

  resetMessagesForm() {
    this.dataObj.nextFormData.formulaire.reset();
    this.dataObj.loadingMessagesForm = false;
  }

  openModal<T>(body: Content<T>, header: Content<T> = null,
               footer: Content<T> = null, options: any = null,
               theClass?: string) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options,
      theClass: theClass
    });
  }

  closeModal() {
    console.log('## close dynamic form');
    this.dialogRef.close();
  }


}
