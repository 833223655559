import { Injectable } from '@angular/core';

export interface IChampsFormulaireColumnName {
  id: string;
  label: string;
  type: string;
  steps: string;
  formsOrder: string;
  locationType: string;
  typeInputForm: string;
  keyJson: string;
  sourceType: string;
  valeurType: string;
  comment: string;
  placeholder: string;
  formulaire: string;
}

@Injectable({ providedIn: 'root' })
export class ChampsFormulaireColumnName {
  getColumnName(): IChampsFormulaireColumnName {
    return {
      id: 'id',
      label: 'label',
      type: 'type',
      steps: 'steps',
      formsOrder: 'formsOrder',
      locationType: 'locationType',
      typeInputForm: 'typeInputForm',
      keyJson: 'keyJson',
      sourceType: 'sourceType',
      valeurType: 'valeurType',
      comment: 'comment',
      placeholder: 'placeholder',
      formulaire: 'formulaire'
    };
  }
}
