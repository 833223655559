import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { IAppState } from '../../interfaces/app-state';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { HttpService } from '../../services/http/http.service';
import { IOption } from '../../ui/interfaces/option';
import { TCModalService } from '../../ui/services/modal/modal.service';
import * as SettingsActions from '../../store/actions/app-settings.actions';
import { TokenStorageService } from 'src/app/services/storage/token-storage.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserRoleService } from '../../services/roleMapOp/user-role.service';
import { CONSTANT_MENU } from '../../shared/constant/menu.constant';
import { IMenuItem } from '../../interfaces/main-menu';
import { Account, IRole, ROLE, ROLE_NAME } from '../../model/account.model';
import { CONSTANT_MENU_PARTENER } from '../../shared/constant/menu-partener.constant';
import { LIMIT_CHAR_PROFIL } from '../../shared/constant/character.constant';
import { AccountService } from '../../services/ServiceEntity/account.service';
import { takeUntil } from 'rxjs/operators';
import { FormService } from '../../shared/services/form.service';
import { Subject } from 'rxjs';
import { CONSTANT_MENU_TRADER } from '../../shared/constant/menu-trader.constant';
import { ColorService } from '../../shared/services/color.service';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANT_LANG_DEFAULT } from 'src/app/app.component';
import { RoleService } from '../../shared/services/role.service';
import { INotificationDTO } from '../../model/notification.model';
import { NotificationService } from '../../pages/entities/notification/notification.service';
import { STATUS_BD_CONSTANT } from '../../model/status.model';


@Component({
  selector: 'vertical-layout',
  templateUrl: './vertical.component.html',
  styleUrls: [
    '../base-layout/base-layout.component.scss',
    './vertical.component.scss'
  ]
})
export class VerticalLayoutComponent extends BaseLayoutComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  gender: IOption[];
  currentAvatar: string | ArrayBuffer;
  defaultAvatar: string;
  response: Account;
  authority: any;
  Menu: any;

  notifications: INotificationDTO[] = [];
  tabPos = [];
  pos = 0;

  private subs$ = new Subject<void>();

  constructor(
    private cdRef:ChangeDetectorRef,
    private notificationService: NotificationService,
    private roleService: RoleService,
    private colorService: ColorService,
    private formService: FormService,
    private accountService: AccountService,
    store: Store<IAppState>,
    fb: UntypedFormBuilder,
    httpSv: HttpService,
    router: Router,
    elRef: ElementRef,
    modal: TCModalService,
    private http: HttpClient,
    private localStorage: TokenStorageService,
    private userrole: UserRoleService,
    private translate: TranslateService
  ) {
    super(store, fb, httpSv, router, elRef, modal);
  }

  ngOnInit() {
    super.ngOnInit();
    console.log('## init vertical');
    this.getMenuWithTranslate(CONSTANT_MENU);
    
    this.store.dispatch(new SettingsActions.Update({ layout: 'vertical' }));

    this.accountService.getAuthenticationState().pipe(takeUntil(this.subs$))
      .subscribe( (res: Account) => {
        console.log('## get account obs: ', res);
        this.response = res;
      });

    this.getListNotifications();
    this.notificationService.getObjetObs().pipe(takeUntil(this.subs$))
      .subscribe((res: INotificationDTO[]) => {
        console.log('## Success get notification list by subject: ', res);
        this.notifications = (res) ? res : [];
      });

    //************
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe((res: Account) => {
      try{
        console.log('## get account: ', res);
        try {
          //this.authority = response['authorities'][1];
          if (res['authorities'][1]) {
            this.authority = res['authorities'][1];
          } else {
            this.authority = res['authorities'][0];
          }
        }catch (e) {
          console.log('## error get authorities from account: ', e.message);
        }
      }catch (e) {
        console.log('## error identity:', e.message);
      }
    }, (err) => {
      console.log(err);
    });
  }

  getPos(i: number){
    this.pos += i;
    return this.pos + 'px';
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  removeAlert(item) {
    console.log('## remove: ', item);
    this.seeNotification(item);
  }

  getNotif() {
    return this.notifications;
  }

  seeNotification(n: INotificationDTO) {
    const m = (n.statusId !== STATUS_BD_CONSTANT.pending);
    console.log('## val: ', m);
    if (m) {
      console.log('## seen notification', n);
      let p = new HttpParams();
      p = p.set('corrId', n.correlationId);
      this.notificationService.markNotificationAsSeen(p).subscribe(res => {
        console.log('## success mark notification as seen: ', res);
        // récupération des notifications
       // this.getListNotifications();
        // this.getListNotifications();
        this.notificationService.listView()
          .subscribe(res => {
            console.log('## Success get notification list: ', res);
            this.notifications = (res.body) ? res.body : [];
            this.notificationService.setObjetObs(this.notifications);
          });
      }, error => {
        console.log('## error mark notification as seen: ', error);
      });
    }
  }

  getListNotifications() {
    this.notificationService.listView()
      .subscribe(res => {
        console.log('## Success get notification list: ', res);
        this.notifications = (res.body) ? res.body : [];
        let i = 0;
        let pos = -100;
        for(i = 0; i < this.notifications.length; i++) {
          this.tabPos.push(pos);
          pos += 100;
        }
      });
  }

  getSideBarBg() {
    return this.colorService.getSideBarBg2();
  }

  getInfoUserBg() {
    return this.colorService.getInfoUserBg();
  }


  format(n: number) {
    return this.formService.format(n);
  }


  canTraderAccess() {
    return this.roleService.canTraderAccess(this.authority);
  }

  getLimit() {
    return LIMIT_CHAR_PROFIL;
  }

  getRole() {
    return this.roleService.getRole(this.authority);
  }

  getName() {
    let f;
    let l;
    try {
      f = this.response.firstName;
      l = this.response.lastName;
    } catch (e) {
      console.log('## error get name user: ' + e.message);
      return '';
    }
    return f + ' ' + l;
  }

  canPartenerAccess() {
    return this.roleService.canPartenerAccess(this.authority);
  }

  canAdminAccess() {
    return this.roleService.canAdminAccess(this.authority);
  }

  getMenuTrader(): IMenuItem[] {
    return this.getMenuWithTranslate(CONSTANT_MENU_TRADER);
  }

  getMenuPartener(): IMenuItem[] {
    return this.getMenuWithTranslate(CONSTANT_MENU_PARTENER);
  }

  getMenu(): IMenuItem[] {
   // console.log('menuuuuuuuu',  this.getMenuWithTranslate(CONSTANT_MENU));
    return this.getMenuWithTranslate(CONSTANT_MENU);
  }

  getMenuWithTranslate(tab): IMenuItem[] {
    
    tab.forEach( (value) =>{
     //console.log('title##################### I am executed', value);
      if(value.key==="Home"){
        this.translate.get('home').subscribe(
          res => {
            value.title= res;
          })
      }
      if(value.key==="Mobile Account"){
        this.translate.get('mobileAccount').subscribe(res => value.title=res)
      }
      if(value.key==="Notification"){
        this.translate.get('notification').subscribe(res => value.title=res)
      }
      if(value.key==="Trader"){
        this.translate.get('trade').subscribe(res => value.title=res)
      }
      if(value.key==="Monies"){
        this.translate.get('money').subscribe(res => value.title=res)
      }
      if(value.key==="Fees & Benefits Rule"){
        this.translate.get('Fees&Benefit').subscribe(res => value.title= res)
      }
      if(value.key==="Transactions"){
        this.translate.get('transactions').subscribe(res => value.title= res)
      }
      if(value.key==="Service Items"){
        this.translate.get('service').subscribe(res => value.title= res)
      }
      if(value.key==="Exchange Rate"){
        this.translate.get('exchangeRate').subscribe(res => value.title=res)
      }
      if(value.key==="Countries"){
        this.translate.get('countries').subscribe(res => value.title=res)
      }
      if(value.key==="Account ressource type"){
        this.translate.get('accRessType').subscribe(res => value.title= res)
      }  
      if(value.key==="Cash"){
        this.translate.get('cash').subscribe(res => value.title= res)
      }    
    
    })
    return tab;
  }

  getSidebarStyles(): any {
    return {
      'background-color': `${this.appSettings.sidebarBg2}`,
      'color': this.appSettings.sidebarColor
    };
  }

  /*getSidebarStyles(): any {
    return {
      'background-image': `linear-gradient(188deg, ${this.appSettings.sidebarBg}, ${this.appSettings.sidebarBg2} 65%)`,
      'color': this.appSettings.sidebarColor
    };
  }*/

  ngOnDestroy(): void {
    this.formService.free(this.subs$);
  }
}
