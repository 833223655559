import { ChampsFormulaire } from './champs-formulaire.model';

export enum NameSourceFType {
  TABLE='table', COLUMN='column', FILTER='filter', API='api'
}

export class SourceFormModel {
  constructor(
    public id?: number,
    public nameSourceFType?: NameSourceFType,
    public valeur?: string,
    public comment?: string,
    public champsFormulaire?: ChampsFormulaire,
    public champsFormulaireId?: number,
    public serviceItemName?: string,
    public labelFormulaire?: string,
  ) {}
}
