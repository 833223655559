import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Account } from '../../../../model/account.model';
import { ExchangeRateDiff, IExchangeRate, IExchangeRateDiff } from '../../../../model/exchange-rate.model';
import { Subject } from 'rxjs';
import { FormService } from '../../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { ExchangeRateService } from '../exchange-rate.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { Url } from '../../../../shared/constant/url.constant';
import { ConstantHome } from '../../home/home.url';
import { ConstantExchangeRate } from '../exchange-rate.url';
import { takeUntil } from 'rxjs/operators';
import { CONSTANT_ICON } from '../../../../shared/constant/icon.constant';
import { ExchangeUpdateComponent } from '../update/exchange-rate-update.component';
import { Router } from '@angular/router';
import { BTN_CANCEL_CLASS, CANCEL_BG_COLOR } from '../../../../shared/constant/css-style/css-class.constant';
import { ColorService } from '../../../../shared/services/color.service';
import { ExchangeRateLiveAddComponent } from './add/exchange-rate-live-add.component';
import { SIZE_H2_SM_POPUP, SIZE_H_MD_POPUP, SIZE_W_SM_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { VExchangeRateAddComponent } from '../../version-exch-rate/add/v-exchange-rate-add.component';

@Component({
  selector: 'app-exchange-rate-live',
  templateUrl: './exchange-rate-live.component.html'
})
export class ExchangeRateLiveComponent extends BasePageComponent implements OnInit,
  OnDestroy, AfterViewChecked {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  exchangeRateList: IExchangeRateDiff[] = [];
  exchangeRateSearch: IExchangeRateDiff[] = [];
  findExchangeRateList = false;

  searchValue: string;
  subs$ = new Subject<void>();

  constructor(
    private colorService: ColorService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private accountService: AccountService,
    protected exchangeRateService: ExchangeRateService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct exchange rate list live');
    this.pageData = {
      title: '',
      key: 'listExchangeRateLive',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: '',
          key: 'exchangeRate',
          route: Url.SLASH_ROUTE.concat(ConstantExchangeRate.urlFull
            .EXCHANGE_RATE.LIVE)
        },
        {
          title: '',
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'listExchangeRateLive') {
      translate.get('listExchangeRateLive').subscribe(res => this.pageData.title = res);
    }
    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'exchangeRate') {
        translate.get('exchangeRateLive').subscribe(res => value.title = res);
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }


    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init exchange rate list live');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          if (account) {
            super.setLoaded();
            this.getExchangeRateList();
          }
          this.account = account;
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    console.log('## on destroy exchange rate list live');
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  goToDatabase() {
    this.router.navigate([Url.SLASH_ROUTE.concat(ConstantExchangeRate.urlFull
      .EXCHANGE_RATE.LIST)]);
  }

  goToHistorical() {
    this.router.navigate([Url.SLASH_ROUTE.concat(ConstantExchangeRate.urlFull
      .EXCHANGE_RATE.HISTORICAL)]);
  }

  getExchangeRateList() {
    this.exchangeRateService.getExchangeRate().subscribe(res => {
      console.log('## Success get exchange rate list from live: ', res);
      const dataLive = this.exchangeRateService.getExchangeRateList(res);
      this.getExchangeRateListUsed(dataLive);
    }, err => {
      console.log('## error get exchange rate live: ', err);
    });
  }

  /**
   * Récupère la liste d'exchange rate dont la version est utilisée.
   */
  getExchangeRateListUsed(exLive: IExchangeRate[]) {
    this.exchangeRateService.getListUsed().subscribe(res => {
      console.log('## Success get exchange rate list used: ', res);
      const exUsed = (res.body) ? res.body : [];
      console.log('## size: ' + exUsed.length);
      const exFinal: IExchangeRateDiff[] = [];
      for (const l of exLive) {
        const i = new ExchangeRateDiff();
        i.resultExchange = l.resultExchange;
        i.destMoney = l.destMoney;
        i.sourceMoney = l.sourceMoney;
        i.id = l.id;
        for (const e of exUsed) {
          if(e.sourceMoney === l.sourceMoney &&
          e.destMoney === l.destMoney) {
            const val = e.valeurModifie ? e.valeurModifie : e.resultExchange;
            i.resultExchangeUsed = val;
            let diff = (l.resultExchange - val);
            diff = Math.round(diff * 100000) / 100000;
            i.difference = diff;
            break;
          }
        }
        exFinal.push(i);
      }
      this.exchangeRateList = exFinal;
      this.findExchangeRateList = true;
      this.exchangeRateSearch = this.exchangeRateList;
    });
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.exchangeRateSearch].map(mapFn);
    const result = this.exchangeRateSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.exchangeRateList = [];
    result.forEach((c) => {
      this.exchangeRateList.push(c);
    });
  }

  hasFound(): boolean {
    return this.findExchangeRateList;
  }

  exchangeRateIsEmpty() {
    return this.exchangeRateList.length <= 0 || !this.exchangeRateList;
  }

  /* search(): void {
     const query = this.searchValue;
     console.log('## search: ' + query);
     const mapFn = (item) =>
       Object.values(item)
         .map(function(val) {
           return val?.toString().toLowerCase();
         })
         .join(' ');
     const stringArr = [...this.benefitSearch].map(mapFn);
     const result = this.benefitSearch.filter(
       (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
     );
     this.exchangeRateList = [];
     result.forEach((c) => {
       this.exchangeRateList.push(c);
     });
   }*/

  getIcon() {
    return CONSTANT_ICON;
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  addNewVersion() {
    console.log('## open version exchange rate add');
    this.dialog.open(VExchangeRateAddComponent, {
      height: SIZE_H2_SM_POPUP,
      width: SIZE_W_SM_POPUP
    });
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    console.log('## open exchange rate live add');
    this.dialog.open(ExchangeRateLiveAddComponent, {
      height: SIZE_H2_SM_POPUP,
      width: SIZE_H_MD_POPUP
    });
  }

  show(c: IExchangeRate) {
    /*console.log('## open service show');
    this.benefitService.setServiceItemSelectObs(c);
    this.dialog.open(MoniesShowComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  update(c: IExchangeRate) {
    this.exchangeRateService.setExchangeRateSelectObs(c);
    this.dialog.open(ExchangeUpdateComponent, {
      height: '700px',
      width: '800px'
    });
  }

  delete(c: IExchangeRate) {
    /*this.benefitService.setServiceItemSelectObs(c);
    this.dialog.open(ServiceItemDeleteComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  getAmountColBg() {
    return this.colorService.getAmountColBg();
  }

  /**
   * Retourne la liste des services items
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IExchangeRateDiff[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

}
