import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BaseDynamicComponent } from '../../../../shared/layouts/base-dynamic.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IOption } from '../../../../ui/interfaces/option';
import { IBuildingFormByStep, INextForm } from '../dynamic-form.init';
import { Subject } from 'rxjs';
import { DynamicService } from '../../../../services/dynamic.service';
import { CashKey } from '../../cash/cash-key';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { CodeService } from '../../../../services/ServiceEntity/code.service';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { AccountUserService } from '../../../../services/ServiceEntity/account-user.service';
import { CountryColumnName } from '../../countries/country-column-name';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CostsRuleService } from '../../fees/costs-rule.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BASE_URL } from '../../../../../environments/environment';
import { DynamicFormModel } from '../../../../model/champs_formulaire/dynamic-form.model';
import { TIME_LONG_REQUEST, TIME_MEDIUM_REQUEST, TIME_MIN_X_REQUEST } from '../../../../shared/constant/request.contant';
import { BTN_POPUP_CLASS, CONTAINER_DIALOG_POPUP, POPUP_CONTAINER_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { IMobileAccount } from '../../../../model/mobile-account.model';
import { STATUS } from '../../../../model/account.model';
import { Content } from '../../../../ui/interfaces/modal';
import { ITransaction, Transaction } from '../../../../model/transaction.model';
import {
  ID_SERVICE_ITEM_RETRAIT_CODE_CASH,
  SERVICE_ITEM_ID_INFORMATION_CODE_PARTIEL,
  SERVICE_ITEM_ID_RETRAIT_CODE_CASH
} from '../../../../shared/constant/service-item.constant';
import { MobileMoneyService } from '../../mobile-money/mobile-money.service';
import { DataFormDynamic } from '../../cash/services/base-validation.service';
import { EnvoiCodeInfosTransacComponent } from '../../cash/envoi-code-infos-transac/envoi-code-infos-transac.component';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { RetraitCodePartielInfosComponent } from '../../cash/retrait-code-partiel-infos/retrait-code-partiel-infos.component';
import { PRINCIPAL } from '../../../../model/account-ressource.model';
import { ICashModel } from '../../cash/cash-data-model';

/**
 * Permet à une personne de retirer une partie du montant de son
 * solde.
 */
@Component({
  selector: 'app-retrait-code-partiel',
  templateUrl: './retrait-code-partiel.component.html',
  animations: [fadeIn]
})
export class RetraitCodePartielComponent extends BaseDynamicComponent
  implements OnInit, OnDestroy {

  transfertCode: UntypedFormGroup;
  retraitCode: UntypedFormGroup;
  allCachOutForm: UntypedFormGroup;
  PartialCachOutForm: UntypedFormGroup;

  theDialogRef: MatDialogRef<any>;

  loadingCodeForm: boolean;
  loadingAllCashOut: boolean;
  loadingPartialCashOut: boolean;
  loadingTransfertCompte: boolean;

  CurentUser: any;
  CurentUserCuser: any;
  currentMobileAccount: any;
  cUserMobileAccount: any;
  accountNumber: any;
  countrylist: IOption[];
  country: any;
  countries: any;
  SelectedContry: any;
  //
  sentAmount: number;
  countryTrader: string;
  moneySource: string;
  moneyDest: string;
  error: string;
  //
  CountryDestID: any;
  //Tabs
  TabsEnvoiCode: boolean;
  TabsRetraitCode: boolean;
  TabsTransFert: boolean;
  retraitCodeForm: boolean;

  //transfertCompte
  transfertCompteSuccess = false;
  transfertCompteFail = false;
  transfertCompteERROR: any;

  buildingFormData: IBuildingFormByStep;

  private subs$ = new Subject<void>();

  /* Contient toutes les données pour pouvoir effectuer
  * n'importe quelle type de transaction */
  dataObj: DataFormDynamic = {};

  //Identity type
  identityType: IOption[] = [
    {
      'label': 'CNI',
      'value': 'CNI'
    },
    {
      'label': 'Passe Port',
      'value': 'Passe Port'
    },
    {
      'label': 'Permis conduire',
      'value': 'Permis conduire'
    },
    {
      'label': 'Carte Résident',
      'value': 'Carte Résident'
    }
  ];

  finalAllCashOutVal: any;


  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ICashModel,
    private mobileMoneyService: MobileMoneyService,
    protected dynamicService: DynamicService,
    private cashKey: CashKey,
    private accountService: AccountService,
    private formService: FormService,
    private formulaireService: ChampsFormulaireService,
    private transactionsService: TransactionsService,
    private codeService:CodeService,
    private cUserService:CUserService,
    private mAccountService:AccountUserService,
    private dialogRef: MatDialogRef<RetraitCodePartielComponent>,
    private columnName: CountryColumnName,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private modal: TCModalService,
    private costsRuleService: CostsRuleService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(dynamicService, store, httpSv);
    console.log('## construct retrait code valid');
    this.theDialogRef = this.dialogRef;
    this.data.cashDataModel.dialogRef = dialogRef;
    this.dataObj.loadingMessagesForm = false;
    this.TabsRetraitCode = true;
    this.retraitCodeForm = true;
    this.countrylist = [];

    let transfertCode = this.fb.group({});

    this.buildingFormData = {
      lastIndexForm: 0,
      previousIndexForm: 0,
      dynamicFormModels: [],
      bodyModels: [],
      headerModels: []
    }

    this.dataObj.nextFormData = {
      init: false,
      formulaire: transfertCode,
      formBuilder: this.fb,
      dynamicFormModelsDefault: null,
      hasBuiltList: false,
      tabOption: [],
      buildingFormByStep: this.buildingFormData
    };
  }

  ngOnInit(): void {
    console.log('## retrait code valid');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## get account');
        this.CurentUser = account;
        console.log('POINT CASH', this.CurentUser);
        this.initAllCashOutForm();
        this.initPartialCashOutForm();
        this.getCurrentCuser();
        this.getData('assets/data/table.json', 'tableData',
          'setLoaded');
        this.getData(`${BASE_URL}countries`, 'countries',
          'initCountry');

        // récupère infos sur le code.
        const code = this.data.cashDataModel.dataCode.code;
        const data = new FormData();
        console.log('## the code: ', code);
        data.set('valueCode', code);
        data.set('serviceItemId', '' + this.data.cashDataModel.dataCode.serviceItemIdInfos);
        this.showKeys(data);
        this.codeService.verifCodeDynamic(data)
          .subscribe((result) => {
            console.log('### RETRAIT CASH RESULT', result.body);
            this.data.cashDataModel.setCode(result.body);
          }, err => {
            console.log(err);
          });
        let params = new HttpParams();
        params = params.set('idServiceItem', String(this.data.cashDataModel.dataCode.idServiceItem));
        this.formulaireService.query(this.formulaireService.dynamicFormApi, params)
          .pipe(map(res => res.body))
          .subscribe((res: DynamicFormModel[]) => {
            console.log('## success get list forms');
            console.log('## res: ', res);
            this.nextForm(res, this.dataObj.nextFormData);
          }, error => {
            console.log('## error get list forms: ', error);
          });
      });
  }

  ngOnDestroy() {
    console.log('## destroy envoi code');
    this.formService.free(this.subs$);
  //  this.cashService.clearAllData();
  }

  initTransfertCode() {
    console.log('## init transfert code form');
    this.transfertCode = this.fb.group({
      numberAccount: [''],
      senderfName: ['', [Validators.required]],
      senderlName: ['', [Validators.required]],
      senderNumTel: ['', [Validators.required]],
      password: ['', [Validators.required]],
      sentAmount: ['', [Validators.required]],
      labelCountry: ['', Validators.required],
      receiverfName: ['', Validators.required],
      receiverlName: ['', Validators.required],
      receiverNumTel: ['', [Validators.required]],
      ReceivedAmount: [],
      serviceItemId: [102],
      preferenceCurrency: ['XOF']
    });
  }

  AllcashOut() {
 /*   this.cashService.setElement(this.cashKey.retraitCodeValide, false);
    this.cashService.setElement(this.cashKey.appAllCashOut, true);
    console.log('## popuptab2: ',this.cashService.popupTab);*/
    // this.AllcashOutPane = true;
    // this.PartialCashPane = false;
  }

  PartialcashOut() {
    /*this.cashService.setElement(this.cashKey.retraitCodeValide, false);
    this.cashService.setElement(this.cashKey.appPartialCashOut, true);*/

    //  this.AllcashOutPane = false;
    //  this.PartialCashPane = true;
  }

  getInit() {
    return this.dataObj.nextFormData.init;
  }

  /**
   * Permet de charger les éléments du formulaire
   * de l'étape suivante.
   * @param res tous les champs du formulaire.
   */
  buildPreviousForm(res: DynamicFormModel[]) {
    this.dataObj.nextFormData.init = false;
    let formList = [];
    if (res.length > 0 && this.buildingFormData.previousIndexForm >= 0) {
      let i = this.buildingFormData.previousIndexForm;
      this.buildingFormData.lastIndexForm = i+1;
      let next = res[i].formulaire.steps;
      let prev;
      for (; i >= 0; i--) {
        prev = res[i].formulaire.steps;
        if (prev != next) {
          break;
        } else {
          next = prev;
        }
        formList.push(res[i]);
      }
      if(this.buildingFormData.previousIndexForm === -1) {
        this.buildingFormData.previousIndexForm = 0;
      } else {
        this.buildingFormData.previousIndexForm = i;
      }
      this.buildingFormData.dynamicFormModels = formList;
    }
    setTimeout( r => {
      this.dataObj.nextFormData.init = true;
    }, TIME_MIN_X_REQUEST)
    this.buildFromEnum(null);
  }

  anyLastIndexForm(d: DynamicFormModel[]) {
    return (this.buildingFormData.lastIndexForm < d.length);
  }

  anyPreviousIndexForm(d: DynamicFormModel[]) {
    return (this.buildingFormData.previousIndexForm > 0);
  }

  initAllCashOutForm() {
    this.allCachOutForm = this.fb.group({
      identityType: ['', [Validators.required]],
      identityNumber: ['', [Validators.required]],
      password: ['', [Validators.required]],
      valueCode: [''],
      serviceItemId: [''],
      receivedAmount: [''],
      numberAccount: [''],
      receiverNumTel: [''],
      senderId: [''],
      receiverId: ['']
    });
  }

  sendRetraitCode(valid: boolean) {
    if (valid) {
      this.loadingCodeForm = true;

      setTimeout(() => {
        this.loadingCodeForm = false;
        this.retraitCode.reset();
      }, 1000);
    }
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  //For All cash out
  sendAllCashOut(valid: boolean) {
    if (valid) {
      this.loadingAllCashOut = true;

      setTimeout(() => {
        this.loadingAllCashOut = false;
        this.allCachOutForm.reset();
      }, 1000);
    }
  }

  //Partial Cash Out
  sendPartialCashOut(valid: boolean) {
    if (valid) {
      this.loadingPartialCashOut = true;

      setTimeout(() => {
        this.loadingPartialCashOut = false;
        this.PartialCachOutForm.reset();
      }, 1000);
    }
  }

  //Valide All Cash Out
  DoneAllCachOutForm(modalRetaitCodeBody: any) {
    //init Val
    // const code = this.cashService.getCode();
    const code = undefined;
    this.allCachOutForm.value.valueCode = code.valueCode;
    this.allCachOutForm.value.serviceItemId = 102;
    this.allCachOutForm.value.receivedAmount = code.amount;
    this.allCachOutForm.value.numberAccount = this.accountNumber;
    this.allCachOutForm.value.senderId = code.senderId;
    this.allCachOutForm.value.receiverId = code.receiverId;

    this.transactionsService.withdrawalCode(this.allCachOutForm.value).subscribe((rep) => {
      console.log('### SUCCESS Retrait ALL CASH OUT', rep);
      //setTimeout(()=>{},1000);
      this.finalAllCashOutVal = rep;
      this.openModal(modalRetaitCodeBody, 'Resultats du retrait CODE All CASH',
        null, { closeButton: true, overlayClose: false });
      this.allCachOutForm.reset();
    }, err => {
      console.log('### ERROR Retrait ALL CASH OUT', err);
    });
  }

  getColorbyReponse() {
    /*if (this.cashService.getCode().status === 'ACTIVED') {
      return 'black';
    } else {
      return 'red';
    }*/
    return 'red';
  }

  retraitCodeSuccess() {
    this.retraitCodeForm = false;
    // this.RetraitCodeValide = true;
  }

  DisableRetraitCodeSuccess() {
    /*this.cashService.setElement(this.cashKey.retraitCodeValide, false);
    this.cashService.setElement(this.cashKey.appTabsRetraitCode, true);*/

    this.TabsRetraitCode = true;
    /* this.AllcashOutPane = false;
     this.PartialCashPane = false;*/
  }

  retraitCash() {
    //GetMapping("api/codes/valueCode/{code}")
    console.log('### RETRAIT', this.retraitCode.value.envoiCode);
    this.codeService.findByCode(this.retraitCode.value.envoiCode).subscribe((result) => {
      console.log('### RETRAIT CASH RESULT', result);
     // this.cashService.setCode(result);
      this.retraitCode.reset();
      this.retraitCodeSuccess();
      //this.openModal(modalRetaitCodeBody,"Retrait Code Result");
    }, err => {
      console.log(err);
    });
  }

  getCode() {
   // return this.cashService.getCode();
    return 'a';
  }

  canIShowAllCashOut(): boolean {
    /*return this.cashService
      .getElement(this.cashKey.appAllCashOut).value;*/
    return true;
  }

  canIShowPartialCashOut(): boolean {
    /*return this.cashService
      .getElement(this.cashKey.appPartialCashOut).value;*/
    return true;
  }

  getRetraitCodeValid(): boolean {
    /*return this.cashService
      .getElement(this.cashKey.retraitCodeValide).value;*/
    return true;
  }

  //Valider Partial Cash OUT
  /*DonePartialCashOut(modalRetaitCodeBody: any) {
    //init Val
    const code = this.cashService.getCode();
    this.PartialCachOutForm.value.valueCode = code.valueCode;
    this.PartialCachOutForm.value.serviceItemId = 102;
    this.PartialCachOutForm.value.receiverNumTel = code.receiverNumTel;
    //this.PartialCachOutForm.value.receivedAmount= code.amount;
    this.PartialCachOutForm.value.numberAccount = this.accountNumber;
    this.PartialCachOutForm.value.senderId = code.senderId;
    this.PartialCachOutForm.value.receiverId = code.receiverId;

    this.transactionsService.withdrawalCode(this.PartialCachOutForm.value).subscribe((rep) => {
      console.log('### SUCCESS Retrait PARTIAL CASH OUT', rep);
      //setTimeout(()=>{},1000);
      this.finalAllCashOutVal = rep;
      this.openModal(modalRetaitCodeBody, 'Resultats du retrait CODE PARTIAL CASH', null,
      { closeButton: true, overlayClose: false });
      this.PartialCachOutForm.reset();
    }, err => {
      console.log('### ERROR Retrait PARTIAL CASH OUT', err);
    });

  }*/

  resetRetraitCodeForm() {
    this.retraitCode.reset();
    this.loadingCodeForm = false;
  }

  //All Cash Out Reset
  ResetAllCashOut() {
    this.allCachOutForm.reset();
    this.loadingAllCashOut = false;
  }

  //partial Cash out
  ResetPartialCashOut() {
    this.PartialCachOutForm.reset();
    this.loadingPartialCashOut = false;


  }

  //for partial Cash Out
  initPartialCashOutForm() {
    this.PartialCachOutForm = this.fb.group({
      identityType: ['', [Validators.required]],
      identityNumber: ['', [Validators.required]],
      password: ['', [Validators.required]],
      valueCode: [''],
      serviceItemId: [''],
      receivedAmount: ['', [Validators.required]],
      numberAccount: [''],
      receiverNumTel: [''],
      senderId: [''],
      receiverId: ['']
    });
  }


  getCurrentCuser() {
    this.cUserService.findOneByUserLogin(this.CurentUser.login).subscribe((response) => {
      this.CurentUserCuser = response.body;
      console.log('CUSER POINT CASH', this.CurentUserCuser);
      //console.log("Current User",this.CurentUser);
      this.getCurrentMobileAccount();
    }, (err) => {
      console.log(err);
    });
  }

  getCurrentMobileAccount() {
    this.mAccountService.findAllAccountsByCUserId(this.CurentUserCuser.id)
      .pipe(
        filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
        map((res: HttpResponse<IMobileAccount[]>) => res.body)
      )
      .subscribe((val: IMobileAccount[]) => {
        console.log('## mobiles accounts: ', val);
        const res = val.filter(m => (m.status === STATUS.actived));
        console.log('## res:', res);
        this.cUserMobileAccount = res['0'];
        //  this.cUserMobileAccount = val['0'];
        this.accountNumber = this.cUserMobileAccount.numberAccount;
        console.log('NUMBER Account', this.accountNumber);
      }, err => {
        console.log(err);
      });
  }

  getCountryByLabel(label) {
    this.getData(`${BASE_URL}countries/label/${label}`, 'country', 'initCountryDestID');
  }

  getHeaders() {
    return this.buildingFormData.headerModels;
  }

  initCountryDestID() {
    setTimeout(() => {
      this.CountryDestID = this.country.mId;
      console.log('### DESt Country ID', this.CountryDestID);
    }, 0);
  }

  copyInputMessage(inputElement){
    console.log('## copy: ', inputElement);
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    console.log('## copy: ', inputElement.value);
  }

  confirm(modalRetaitCodeBody: any) {
    console.log('## confirmer');
    this.DonePartialCashOut(modalRetaitCodeBody);
  }

  /**
   * @see ValidationFormService#valider
   */
  confirmer(d: DataFormDynamic, dialog: MatDialog,): any {
    console.log('## confirmation retrait code partiel');
    this.data.cashDataModel.dataForm = d;
    dialog.open(this.data.cashDataModel.dataCode.componentInfos, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: this.data
    });
    /*dialog.open(RetraitCodePartielInfosComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP
    });*/
  }

  //Valider Partial Cash OUT
  DonePartialCashOut(forModal: any) {
    const code = this.data.cashDataModel.dataCode.code;
    const data = this.getFormData(this.dataObj.nextFormData.formulaire);
    data.set('serviceItemId', '' + this.data.cashDataModel.dataCode.serviceItemId);
    if(!data.get('sentAmount')) {
      data.set('sentAmount', this.data.cashDataModel.getCode().amount);
    }
    data.set('valueCode', code);
    data.set('numberAccount', this.accountNumber);
    data.set('principal', PRINCIPAL.ZERO + '');
    this.showKeys(data);

    this.dataObj.data = data;
    this.dataObj.forModal = forModal;
    this.confirmer(this.dataObj, this.dialog);

   /* this.transactionsService.withdrawalv2(data).subscribe((rep) => {
      console.log('### SUCCESS Retrait PARTIAL CASH OUT', rep);
      //setTimeout(()=>{},1000);
     // this.finalAllCashOutVal = rep;
      this.manageMessageWhenSucceed(rep.body);
      setTimeout(() => {
        this.openModal(modalRetaitCodeBody, 'Resultats du retrait CODE PARTIAL CASH',
          null, { closeButton: true, overlayClose: false }, POPUP_CONTAINER_CLASS);
        this.dataObj.nextFormData.formulaire.reset();
      }, 0);
    }, err => {
      console.log('### ERROR Retrait PARTIAL CASH OUT', err);
      this.manageMessageWhenError();
    });*/
  }

  /**
   * Permet d'effectuer un envoie code.
   * @param forModal
   * @param forfooter
   * @constructor
   */
  envoiCode(forModal: any, forfooter?: any) {
    console.log('## envoie code');
    this.dataObj.loadingMessagesForm = true;
    this.dataObj.successTransfert = false;
    this.dataObj.errorTransfert = false;
    this.getCountryByLabel(this.transfertCode.value.labelCountry);
    this.transfertCode.value.numberAccount = this.accountNumber;
    console.log('## number account: ', this.transfertCode.value.numberAccount);
    console.log('### POST:', this.transfertCode.value);
    setTimeout(
      () => {
        if (this.CurentUserCuser.countryId === this.CountryDestID) {//transfert National
          console.log('transfert NATIONAL');
          this.transactionsService.transferCode(this.transfertCode.value)
            .subscribe((rep) => {
              console.log('### SUCCESS Transfert Code', rep);
              this.manageMessageWhenSucceed(rep);
              this.showDetailsEnvoieCode(forModal,
                'Details Transaction', null,
                {
                  closeButton: true,
                  overlayClose: false
                }); // after 700ms
            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        } else {//transfert International api/transactions/transferCodeInter
          console.log('transfert INTERNATIONAL');
          console.log('CUSER CURRENT', this.CurentUserCuser.countryId);
          this.transactionsService.transferCodeInter(this.transfertCode.value)
            .subscribe((rep) => {
              console.log('### SUCCESS Transfert Code', rep);
              this.manageMessageWhenSucceed(rep);
              this.showDetailsEnvoieCode(forModal,
                'Details Transaction', null,
                {
                  closeButton: true,
                  overlayClose: false
                }); // after 700ms
            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        }
      },
      1000
    );
  }

  /**
   * Affiche les détails de la transaction
   * lorsqu'elle a réussi.
   * @param modal
   * @param title
   * @param footer
   * @param options
   */
  showDetailsEnvoieCode(modal, title: string, footer, options) {
    console.log('## showDetailsEnvoieCode');
    console.log('## modal: ', modal);
    console.log('## title: ', title);
    setTimeout(() => {
      this.openModal(modal, title, footer, options, 'envoi-code');
    }, TIME_LONG_REQUEST);
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenError() {
    this.dataObj.successTransfert = false;
    this.dataObj.loadingMessagesForm = false;
    this.dataObj.errorTransfert = true;
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenSucceed(response) {
    this.dataObj.errorTransfert = false;
    this.dataObj.loadingMessagesForm = false;
    this.dataObj.successTransfert = true;
    this.dataObj.reponseTransfert = response;
    this.dataObj.nextFormData.formulaire.reset();
  }

  //Initialiser les Country pour le Select
  initCountry() {
    console.log('## init country');
    setTimeout(() => {
      console.log('COUNTRIES size: ', this.countries?.length);
      this.countrylist = [];
      this.countries?.forEach(element => {
        console.log('## country element: ', element);
        this.countrylist.push({ 'label': element.label, 'value': element.label });
      });
    });
  }

  resetMessagesForm() {
    this.dataObj.nextFormData.formulaire.reset();
    this.dataObj.loadingMessagesForm = false;
  }

  openModal<T>(body: Content<T>, header: Content<T> = null,
               footer: Content<T> = null, options: any = null,
               theClass?: string) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options,
      theClass: theClass
    });
  }

  closeModal() {
    console.log('## close envoi code');
    this.dialogRef.close();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.dataObj.nextFormData.formulaire);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.dataObj.nextFormData.formulaire, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.dataObj.nextFormData.formulaire,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.dataObj.nextFormData.formulaire, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.dataObj.nextFormData.formulaire, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.dataObj.nextFormData.formulaire, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.dataObj.nextFormData.formulaire, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.dataObj.nextFormData.formulaire, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.dataObj.nextFormData.formulaire, column);
  }
}
