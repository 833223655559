export const PRINCIPAL = {
  MAIN: 1,
  ZERO: 0
}

export const ACCOUNT_RESSOURCE_TYPE = {
  CASH: 1
}

export const ACCOUNT_DISPLAY = {
  V1: 1
}

export class AccountRessActivDTO {
  constructor(
    public id?: number,
    public acountUserId?: number,
    public acountRessourceId?: number,
    public principal?: number,
    public serviceItemId?: number,
    public accountRessourceStatusId?: number,
    public accountRessourceStatusLabel?: string,
    public accountRessTypeId?: number,
    public name?: string,
    public amount?: number,
    public type?: string
  ) {}
}

export interface IAccountRessource {
  id?: number;
  amount?: number;
  status?: string;
  accountRessTypeId?: number;
  type?: string;
  currency?: string;
  valueCode?: string;
  dateStarted?: Date;
  dateEnd?: Date;
  mobileAccountId?: number;
}

export class AccountRessource implements IAccountRessource {
  constructor(
    public id?: number,
    public amount?: number,
    public status?: string,
    public accountRessTypeId?: number,
    public type?: string,
    public currency?: string,
    public valueCode?: string,
    public dateStarted?: Date,
    public dateEnd?: Date,
    public mobileAccountId?: number
  ) {}
}
