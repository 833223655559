import { Injectable } from '@angular/core';

export interface IExchangeColumnName {
  id: string,
  sourceMoney: string,
  destMoney: string,
  resultExchange: string,
  valeurModifie: string,
  versionExchangeRateId: string
}

@Injectable({ providedIn: 'root' })
export class ExchangeColumnName {

  getColumnName(): IExchangeColumnName {
    return {
      id: 'id',
      sourceMoney: 'sourceMoney',
      destMoney: 'destMoney',
      resultExchange: 'resultExchange',
      valeurModifie: 'valeurModifie',
      versionExchangeRateId: 'versionExchangeRateId'
    };
  }
}
