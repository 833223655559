import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les versions Exchange Rate.
 */
export namespace ConstantVersionExchangeRate {
  export const data = {
    title: 'Version Exchange Rate',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'une Version Exchange Rate'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des Version Exchange Rate'
      }
    }

  }
  export const url = {
    EXCHANGE_RATE: {
      EXCHANGE_RATE_: 'version_exchange_rate',
      LIST: 'liste',
      ADD: 'add',
      LIVE: 'live',
      HISTORICAL: 'historical'
    }
  };

  export const urlFull = {
    EXCHANGE_RATE: {
      ADD: ConstantVersionExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_
        .concat(Url.SLASH_ROUTE).concat(ConstantVersionExchangeRate.url.EXCHANGE_RATE.ADD),
      LIVE: ConstantVersionExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_
        .concat(Url.SLASH_ROUTE).concat(ConstantVersionExchangeRate.url.EXCHANGE_RATE.LIVE),
      LIST: ConstantVersionExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_
        .concat(Url.SLASH_ROUTE).concat(ConstantVersionExchangeRate.url.EXCHANGE_RATE.LIST),
      HISTORICAL: ConstantVersionExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_
        .concat(Url.SLASH_ROUTE).concat(ConstantVersionExchangeRate.url.EXCHANGE_RATE.HISTORICAL),
    }
  };
}
