import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PasswordMatchingService } from '../../../pages/password/update/password-matching.service';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from '../../services/form.service';
import {
  BEFORE_ICON_RESET,
  BEFORE_ICON_VALIDATION,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALID_CLASS,
  BTN_VALIDATION_CLASS,
  CANCEL_BG_COLOR,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS,
  CONTAINER_POPUP_CLASS,
  ICON_COLOR_REQUEST_CSS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET, REMOVABLE_ICON_REQUEST,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS, VALIDATION_BTN2_POPUP_CLASS,
  VALIDATION_BTN3_POPUP_CLASS, VALIDATION_BTN4_POPUP_CLASS,
  WARNING_COLOR_CSS,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../constant/css-style/css-class.constant';

/**
 * Réprésente le footer pour différents formulaire.
 * Il contient plusieurs boutons.
 * Il utilise le matDialogActions de angular material.
 */
@Component({
  selector: 'app-footer-form-next',
  templateUrl: './footer-form-next.component.html',
  animations: [fadeIn]
})
export class FooterFormNextComponent implements OnInit {

  /* si l'évènement est généré, cela permettra soit de
  fermer un popup ou d'effectuer une autre action. */
  @Output('cancel') eventc = new EventEmitter<any>();

  /* si l'évènement est généré, cela permettra d'effectuer
  la transaction. */
  @Output('save') event = new EventEmitter<any>();
  @Output('nextForm') eventNext = new EventEmitter<any>();
  @Output('prevForm') eventPrev = new EventEmitter<any>();

  // le formulaire.
  @Input('transactionForm')   formGroup: UntypedFormGroup;
  // le popup de la transaction, va permettre la fermeture.
  @Input('dialogRef') dialogRef: MatDialogRef<any>

  @Input('isFirst') isFirst = true;
  @Input('isLast') isLast = false;

  @Input('loadingForm') loading = false;
  @Input('update') update = false;
  @Input('validate') validate = false;
  @Input('next') next = false;
  @Input('previous') previous = false;

  @Input('passwordMatchingService') passwordMatchingService: PasswordMatchingService;

  @Input('contentClass') contentClass = 'btn-medium-column';
  @Input('validationClass') validationClass = 'btn-medium-column';

  @Input('textCancel') cancelText = 'Cancel';
  @Input('textReset') reset = 'Reset';
  @Input('textValider') valider = 'Valider';
  @Input('textNext') nextText = 'Suivant';
  @Input('textPrev') previousText = 'Précédent';

  @Input('textValiderTr') validerTranslateTextKey;
  @Input('textNextTr') nextTranslateTextKey;
  @Input('textPrevTr') prevTranslateTextKey;

  constructor(
    private translateService: TranslateService,
    private formService: FormService) {
    console.log('## construct footer form');
  }

  ngOnInit(): void {
    console.log('## init footer form');
    if(!this.prevTranslateTextKey) {
      console.log('## trs cons: ', this.prevTranslateTextKey);
      this.translateService.get('next').subscribe((text:string) => {
        console.log("## tr from construct: ", text);
        this.nextText = text;
      });
    } else {
      console.log('## trs valid: ', this.prevTranslateTextKey);
      this.translateService.get(this.prevTranslateTextKey).subscribe((value:string) => {
        console.log("## tr from _valider: ", value);
        this.nextText = value;
      });
    }
    if(!this.nextTranslateTextKey) {
      console.log('## trs cons: ', this.nextTranslateTextKey);
      this.translateService.get('next').subscribe((text:string) => {
        console.log("## tr from construct: ", text);
        this.nextText = text;
      });
    } else {
      console.log('## trs valid: ', this.nextTranslateTextKey);
      this.translateService.get(this.nextTranslateTextKey).subscribe((value:string) => {
        console.log("## tr from _valider: ", value);
        this.nextText = value;
      });
    }
    if(!this.validerTranslateTextKey) {
      console.log('## trs cons: ', this.validerTranslateTextKey);
      this.translateService.get('apikey.test.valider').subscribe((text:string) => {
        console.log("## tr from construct: ", text);
        this.valider = text;
      });
    } else {
      console.log('## trs valid: ', this.validerTranslateTextKey);
      this.translateService.get(this.validerTranslateTextKey).subscribe((value:string) => {
        console.log("## tr from _valider: ", value);
        this.valider = value;
      });
    }
  }

  get _valider() {
    return this.valider;
  }

  set _valider(key: string) {
    console.log('## set valider: ', key);
    console.log('## set valider: ', this.validerTranslateTextKey);
  }

  cancel() {
    if(this.dialogRef) {
      this.dialogRef.close();
    } else {
      return this.eventc.emit();
    }
  }

  prevForm() {
    return this.eventPrev.emit();
  }

  nextForm() {
    return this.eventNext.emit();
  }

  save() {
    return this.event.emit();
  }

  isInvalidWhenSaving(): boolean {
    let result = true;
    if(this.passwordMatchingService) {
      result = (this.isInvalid() || !this.passwordMatchingService.isMatching()) || this.loading;
    } else {
      result = this.isInvalid() || this.loading;
    }
    return result;
  }

  thereIsNoChange(): boolean {
    const invalid = this.formService.isInvalidOrPristineForm(this.formGroup);
    return invalid || (this.passwordMatchingService && !this.passwordMatchingService.isMatching());
  }

  resetForm() {
    if(this.formGroup) {
      this.formGroup.reset();
    }
    // this.loadingTransfertCompte = false;
  }

  closeModal() {
    console.log('## close modal');
    if(this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getIconValidation(): string {
    return BEFORE_ICON_VALIDATION;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  isCancelBtnOutline(): boolean {
    return OUTLINE_CANCEL;
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getBtnValidClass(): string {
    return (!this.isInvalid()) ? BTN_VALID_CLASS : '';
  }

  getBtnValidUpdateClass(): string {
    return (!this.thereIsNoChange()) ? BTN_VALID_CLASS : '';
  }


  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getContainerBtnClass() {
    return CONTAINER_BTN_POPUP_CLASS;
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  getResultRequestTextSuccess(): string {
    return RESULT_REQUEST_TEXT_SUCCESS_CLASS;
  }

  getWarningColor(): string {
    return WARNING_COLOR_CSS;
  }

  getInside2xContainerClass(): string {
    return CONTAINER_INSIDE2X_POPUP_CLASS;
  }

  getInsideContainerClass(): string {
    return CONTAINER_INSIDE_POPUP_CLASS;
  }

  getContainerClass(): string {
    return CONTAINER_POPUP_CLASS;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getIconColorRequestClass(): string {
    return ICON_COLOR_REQUEST_CSS;
  }

  getBtnClass(): string {
    return VALIDATION_BTN3_POPUP_CLASS;
  }

  getBtnClass2(): string {
    return VALIDATION_BTN4_POPUP_CLASS;
  }

  getBtn2Class(): string {
    return VALIDATION_BTN2_POPUP_CLASS;
  }

  isRemovable(): boolean {
    return REMOVABLE_ICON_REQUEST;
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.formGroup);
  }

}
