import { Component, Input, OnInit } from '@angular/core';
import { LABEL_FORM_CLASS } from '../../../../../shared/constant/css-style/css-class.constant';

@Component({
  selector: 'app-retrait-code-infos',
  templateUrl: './retrait-code-infos.component.html',
  styleUrls: ['./retrait-code-infos.component.css']
})
export class RetraitCodeInfosComponent implements OnInit {
  // informations sur le code lors d'un retrait code.
  @Input('dataCode') code: any;

  constructor() { }

  ngOnInit(): void {
    console.log('## the code: ', this.code);
  }

  getColorbyReponse() {
    if (this.code.status === 'ACTIVED') {
      return 'black';
    } else {
      return 'red';
    }
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  getCode() {
    return this.code;
  }

}
