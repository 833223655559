export interface ICountry {
    id?: number;
    label?: string;
    codeCountry?: string;
    codePhone?: number;
    costsRuleId?: number;
    mId?: number;
    cUserId?: number;
    mLabel?: string;
    mLabelCourt?: string;
    mValue?: string;
    mUnit?: string;
    mCode?: string;
  }
  
  export class Country implements ICountry {
    constructor(
      public id?: number,
      public label?: string,
      public codeCountry?: string,
      public codePhone?: number,
      public costsRuleId?: number,
      public mId?: number,
      public cUserId?: number,
      public mLabel?: string,
      public mLabelCourt?: string,
      public mValue?: string,
      public mUnit?: string,
      public mCode?: string
    ) {}
  }
  