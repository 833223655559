export interface IVersionExchangeRate {
  id?: number;
  version?: string;
  description?: string;
  etat?: number;
}

export class VersionExchangeRate implements IVersionExchangeRate {
  constructor(public id?: number, public version?: string,
              public description?: string, public etat?: number) {}
}
