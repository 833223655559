import { Injectable } from '@angular/core';

export interface IValidatorColumnName {
  id: string;
  type: string;
}

@Injectable({ providedIn: 'root' })
export class ValidatorColumnName {
  getColumnName(): IValidatorColumnName {
    return {
      id: 'id',
      type: 'type'
    };
  }
}
