import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { MONIES_ROUTE } from '../monies/monies.route';
import { MoneyDeleteComponent } from '../monies/delete/monies-delete.component';
import { MoniesUpdateComponent } from '../monies/update/monies-update.component';
import { MoniesAddComponent } from '../monies/add/monies-add.component';
import { MoniesShowComponent } from '../monies/show/monies-show.component';
import { MoniesComponent } from '../monies';
import { FEES_ROUTE } from './fees.route';
import { FeesComponent } from './fees.component';
import { FeesAddComponent } from './add/fees-add.component';
import { FeesUpdateComponent } from './update/fees-update.component';
import { CreateComponent } from './create/create.component';

/**
 * Ce module permet de gérer le model costRule.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [FeesUpdateComponent, FeesAddComponent, FeesComponent, CreateComponent]
})
export class FeesModule {}
