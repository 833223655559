import { HttpClient, HttpResponse } from '@angular/common/http';
import { IServiceItem } from '../../../model/service-item.model';
import { Injectable } from '@angular/core';
import { BASE_URL, BASE_URL_ADMIN } from '../../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  CompositeCreateServiceItemDTO,
  IServiceItemCompose,
  ServiceItemFilsListDTO,
  ServiceItemFilsUpdateDTO
} from '../../../model/service-item-compose.model';

type EntityResponseType = HttpResponse<IServiceItem>;

@Injectable({ providedIn: 'root' })
export class ServiceItemComposeService {

  public resourceServiceFilsUrl = BASE_URL_ADMIN + '/service-item/fils';
  public resourceServiceComposeParentUrl = BASE_URL_ADMIN + '/service-item/compose/parent';
  public resourceServiceComposeUrl = BASE_URL_ADMIN + '/service-item/compose';
  public resourceServiceComposeFilsUrl = BASE_URL_ADMIN + '/service-item/compose/fils';

  constructor(protected http: HttpClient) {
  }

  // le serviceItemCompose sélectionné dans la liste.
  private serviceItemSelect = new BehaviorSubject<IServiceItemCompose>(undefined);

  private serviceItemFilsSelect = new BehaviorSubject<ServiceItemFilsListDTO>(undefined);

  private serviceItem = new Subject<IServiceItem[]>();

  private serviceItemFils = new Subject<ServiceItemFilsListDTO[]>();

  getServiceItemFilsSelectObs() : Observable<ServiceItemFilsListDTO>{
    return this.serviceItemFilsSelect.asObservable();
  }

  setServiceItemFilsSelectObs(c: ServiceItemFilsListDTO) {
    this.serviceItemFilsSelect.next(c);
  }

  deleteService(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceServiceFilsUrl}/${id}`,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer les services composes.
   * @param data
   */
  updateServiceCompose(data: any): Observable<HttpResponse<ServiceItemFilsUpdateDTO[]>> {
    return this.http.put<ServiceItemFilsUpdateDTO[]>(this.resourceServiceFilsUrl, data,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer les services composes.
   * @param data
   */
  createServiceCompose(data: any): Observable<HttpResponse<CompositeCreateServiceItemDTO[]>> {
    return this.http.post<CompositeCreateServiceItemDTO[]>(this.resourceServiceComposeUrl, data,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer les services fils.
   * @param req
   */
  getServiceFils(req?: any): Observable<HttpResponse<ServiceItemFilsListDTO[]>> {
    return this.http.get<ServiceItemFilsListDTO[]>(this.resourceServiceComposeFilsUrl,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de récupérer les services composes.
   * @param req
   */
  getServiceCompose(req?: any): Observable<HttpResponse<IServiceItemCompose[]>> {
    return this.http.get<IServiceItemCompose[]>(this.resourceServiceComposeParentUrl,
      { observe: 'response' });
  }

  getServiceItemSelectObs() : Observable<IServiceItemCompose>{
    return this.serviceItemSelect.asObservable();
  }

  setServiceItemSelectObs(c: IServiceItemCompose) {
    this.serviceItemSelect.next(c);
  }

  getServiceItemObs() {
    return this.serviceItem.asObservable();
  }

  setServiceItemObs(c: IServiceItem[]) {
    this.serviceItem.next(c);
  }

  getServiceItemFilsObs() {
    return this.serviceItemFils.asObservable();
  }

  setServiceItemFilsObs(c: IServiceItem[]) {
    this.serviceItemFils.next(c);
  }

  /**
   * Permet de récupérer la liste des country
   * pour être partagée.
   */
  getAllServiceItem() {
    setTimeout(() => {
      this.getServiceCompose().subscribe(res => {
        console.log('## Success get serviceItem list');
        this.setServiceItemObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  /**
   * Permet de récupérer la liste des country
   * pour être partagée.
   */
  getAllServiceItemFils(req?: any) {
    setTimeout(() => {
      this.getServiceFils(req).subscribe(res => {
        console.log('## Success get serviceItemFils list');
        this.setServiceItemFilsObs((res.body) ? res.body : []);
      });
    }, 300)
  }


}