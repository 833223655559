import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorageService } from './storage/token-storage.service';
import { FormService } from '../shared/services/form.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicService {

  constructor(private _formService: FormService) {
  }

  get formService(): FormService {
    return this._formService;
  }

  set formService(value: FormService) {
    this._formService = value;
  }
}