import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { IOption } from '../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ChampsFormulaireColumnName,
  IChampsFormulaireColumnName
} from '../../champs_formulaires/champs_formulaires/champs-formulaire-column-name';
import { IServiceItem } from '../../../../model/service-item.model';
import { TypeInputFormModel } from '../../../../model/champs_formulaire/type-input-form.model';
import { ServiceItemService } from '../../service-items/service-item.service';
import { TypeInputFormService } from '../../champs_formulaires/type-input-form/type-input-form.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ChampsFormulaire, LocationType, SourceType, TypeForm } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';
import { FormulaireColumnName, IFormulaireColumnName } from '../formulaire-column-name';
import { Formulaire } from '../../../../model/champs_formulaire/formulaire.model';
import { FormulaireService } from '../formulaire.service';


@Component({
  selector: 'app-formulaire-add',
  templateUrl: './formulaire-add.component.html',
  animations: [fadeIn]
})
export class FormulaireAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<FormulaireAddComponent>;
  column: IFormulaireColumnName;
  editForm: UntypedFormGroup;

  private subs$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceItemService: ServiceItemService,
    private typeInputFormService: TypeInputFormService,
    private formulaireService: FormulaireService,
    public baseService: BaseService,
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private dialogRef: MatDialogRef<FormulaireAddComponent>,
    private columnName: FormulaireColumnName,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct formulaire add');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init formulaire add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy formulaire add');
    this.formService.free(this.subs$);
  }

/*  private _filter(value: string): IServiceItem[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option.name.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }*/

  initForm(fb: UntypedFormBuilder, column: IFormulaireColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.titre]: ['', [Validators.required]],
        [column.description]: ['', []]
      });
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save form');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.formulaireService.create(c, this.formulaireService.resourceUrl)
      .subscribe(res => {
          console.log('## success create form');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.formulaireService.getAllObjet();
        },
        err => {
          console.log('## error create form: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): Formulaire {
    return {
      ...new Formulaire(),
      titre: this.editForm.get([this.column.titre]).value,
      description: this.editForm.get([this.column.description]).value
    };
  }

  closeModal() {
    console.log('## close formulaire add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }

}