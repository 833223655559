import { Routes } from '@angular/router';
import { ConstantFeesBenefits } from '../fees/fees.url';
import { VerticalLayoutComponent } from '../../../layout/vertical';
import { FeesComponent } from '../fees';
import { ConstantExchangeRate } from './exchange-rate.url';
import { ExchangeRateComponent } from './exchange-rate.component';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';
import { ExchangeRateLiveComponent } from './live/exchange-rate-live.component';
import { ExchangeRateHistoricalComponent } from './historical/exchange-rate-historical.component';

/**
 * Ensemble des routes pour les exchange rate.
 */
export const EXCHANGE_RATE_ROUTE: Routes = [
  {
    path: ConstantExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantExchangeRate.url.EXCHANGE_RATE.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantExchangeRate.url.EXCHANGE_RATE.LIST,
        component: ExchangeRateComponent
      },
      {
        path: ConstantExchangeRate.url.EXCHANGE_RATE.HISTORICAL,
        component: ExchangeRateHistoricalComponent
      },
      {
        path: ConstantExchangeRate.url.EXCHANGE_RATE.LIVE,
        component: ExchangeRateLiveComponent
      }
    ]
  }
];
