import { ChangeDetectorRef, Component, OnDestroy, OnInit, Pipe, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Account } from '../../../model/account.model';
import { ChampsFormulaire } from '../../../model/champs_formulaire/champs-formulaire.model';
import { Observable, Subject } from 'rxjs';
import { IServiceItem } from '../../../model/service-item.model';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseService } from '../base/base.service';
import { ServiceItemService } from '../service-items/service-item.service';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { ChampsFormulaireService } from '../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantChampsFormulaires } from '../champs_formulaires/champs_formulaires/champs-formulaire.url';
import { ConstantHome } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { Domain } from '../../../model/domain.model';
import { StatusDomainModel, StatusModel } from '../../../model/status.model';
import { StatusService } from './status.service';
import { StatusLink } from './status.url';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { DomainService } from '../domain/domain.service';
import { TIME_MIN_X2_REQUEST } from '../../../shared/constant/request.contant';
import { StatusColumnName } from './status-column-name';
import { StatusAddComponent } from './add/status-add.component';
import { DomainAddComponent } from '../domain/add/domain-add.component';
import { FormValidator } from '../../../model/champs_formulaire/form-validator.model';
import { DomainLink } from '../domain/domain.url';
import { DomainColumnName } from '../domain/domain-column-name';
import { StatusUpdateComponent } from './update/status-update.component';
import { StatusUpdateLinkComponent } from './update-link/status-update-link.component';

/**
 * Gère l'affichage des domains.
 * see {@link Domain}
 */
@Component({
  selector: 'app-status-list',
  templateUrl: './status-list.component.html'
})
export class StatusListComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  countriesList: StatusModel[]; // utiliser pour afficher la liste des éléments
  countriesSearch: StatusModel[]; // utiliser pour rechercher parmi liste des éléments.
  searchValue: string;
  initByQueryParam = false;

  subs$ = new Subject<void>();

  domainList: Domain[] = [];
  domainTyped = '';
  domainSelected: Domain;
  formControlDomain = new UntypedFormControl();
  filteredOptions: Observable<Domain[]>;

  myControl = new UntypedFormControl();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domainService: DomainService,
    private statusColumnName: StatusColumnName,
    public baseService: BaseService,
    private serviceItemService: ServiceItemService,
    private domainColumnName: DomainColumnName,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private statusService: StatusService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct status list');
    this.pageData = {
      title: StatusLink.data.sub.list.title,
      key: 'status.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: StatusLink.data.title,
          key: 'status.name',
          route: Url.SLASH_ROUTE.concat(StatusLink.urlFull
            .FEES.LIST)
        },
        {
          title: StatusLink.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'status.title') {
      translate.get('status.title').subscribe(res => this.pageData.title = res);
    }

    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'status.name') {
        translate.get('status.name').subscribe(res => {
          console.log('## res: ', res);
          value.title = res;
        });
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }

    });
  }

  ngAfterViewChecked(): void {
    console.log('## after view checked');
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init status list');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
              const n = Object.keys(params).length;
              console.log('## params lg: ', params);
              console.log('## params n: ', n);
              this.account = account;
              if(n > 0) {
                const formId = params['domain'];
                const domainLabel = params[this.domainColumnName.getColumnNameRandom()
                  .domainLabel]
                console.log('## val form: ', formId);
                const domainUrl = new Domain();
                domainUrl.id = formId;
                domainUrl.label = domainLabel;
                this.domainSelected = domainUrl;
                if (formId) {
                  console.log('## get all status by domain id: ', formId);
                  this.listStatusByDomainn(formId);
                }
              } else {
                this.listeAllStatus();
              }
            });
          // récupération de la liste des status.
          this.statusService.getObjetObs().pipe(takeUntil(this.subs$))
            .subscribe((res: StatusModel[]) => {
              console.log('## get list status in list by subject: ', res);
              this.countriesList = (res) ? res : [];
              this.countriesSearch = this.countriesList;
            });
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  listeAllStatus() {
    // récupération de la liste des status.
    this.domainSelected = undefined;
    this.statusService.query(this.statusService.list_url)
      .subscribe(res => {
        console.log('## Success get status list: ', res);
        this.countriesList = (res.body) ? res.body : [];
        this.countriesSearch = this.countriesList;
        super.setLoaded();
        this.getDomainList();
      });
  }


  /**
   * Récupère la liste des status d'un domain.
   */
  listStatusByDomainn(domainId: number): void {
    let p = new HttpParams();
    p = p.set(this.statusColumnName.getColumnNameWhenAffectStatus().domainId, domainId + '');
    this.statusService.query(this.statusService.list_by_domain_Url, p)
      .pipe( filter((mayBeOk: HttpResponse<StatusModel[]>) => mayBeOk.ok),
        map((response: HttpResponse<StatusModel[]>) => response.body))
      .subscribe((res: StatusModel[]) => {
          console.log('## list status of domain: ', res);
        this.countriesList = (res) ? res : [];
        this.countriesSearch = this.countriesList;
        super.setLoaded();
        this.getDomainList();
      });
  }

  /**
   * Récupère la liste des status d'un domain.
   */
  searchDomain(domainId: number): void {
    let p = new HttpParams();
    p = p.set(this.statusColumnName.getColumnNameWhenAffectStatus().domainId, domainId + '');
    this.statusService.query(this.statusService.list_by_domain_Url, p)
      .pipe( filter((mayBeOk: HttpResponse<StatusModel[]>) => mayBeOk.ok),
        map((response: HttpResponse<StatusModel[]>) => response.body))
      .subscribe((res: StatusModel[]) => {
        console.log('## list status of domain: ', res);
        this.countriesList = (res) ? res : [];
        this.countriesSearch = this.countriesList;
      });
  }

  addDomain() {
    console.log('## open domain add');
    this.baseService.openSm(this.dialog, DomainAddComponent);
  }

  changeDomain(event) {
    try {
      console.log('## select domain');
      const si = event.option.value;
      console.log('## value domain: ' + si.label);
      this.domainSelected = si;
      if(this.domainSelected.id) {
        const d =this.domainSelected;
        this.router.navigate(['/' + StatusLink.urlFull.FEES.LIST],
          { queryParams: { [DomainLink.url.FEES.FEES_]: d.id,
              [this.domainColumnName.getColumnNameRandom().domainLabel]: d.label} });
      }
    } catch (e) {
      console.log('## error selected value s item');
    }
  }

  displayFn(subject: Domain) {
    return subject ? subject.label : '';
  }

  /**
   * Récupère la liste des domains.
   */
  getDomainList() {
    setTimeout(() => {
      this.domainService
        .query(this.domainService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<Domain[]>) => mayBeOk.ok),
          map((response: HttpResponse<Domain[]>) => response.body)
        )
        .subscribe((res: Domain[]) => {
            console.log('## success get list domains');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.domainList = rs;
            this.filteredOptions = this.formControlDomain.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list domains: ' + res.message);
          });
    }, TIME_MIN_X2_REQUEST);
  }

  private _filter(value: string): Domain[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.domainList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter domain: ' + e.message);
    }
    return filteredList;
  }

  affectStatus(d: Domain) {
    console.log('## open affect status');
    console.log('## the status: ', d);
    /*this.statusService.setObjetSelectObs(d);
    this.baseService.openSmH2(this.dialog, DomainAffectStatusComponent);*/
  }

  add() {
    console.log('## open status add');
    this.baseService.open(this.dialog, StatusAddComponent);
  }

  linkStatus(c: any) {
    console.log('## select: ', c);
    this.statusService.setObjetSelectObs(c);
    this.baseService.openSmH2(this.dialog, StatusUpdateLinkComponent);
  }

  /**
   * Modifie le status.
   * @param c le status à modifier.
   */
  update(c: StatusModel) {
    this.statusService.setObjetSelectObs(c);
    this.baseService.openSmH2(this.dialog, StatusUpdateComponent);
  }

  /**
   * Retourne la liste des des status
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): StatusModel[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  /**
   * Permet de rechercher un status.
   */
  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.countriesSearch].map(mapFn);
    const result = this.countriesSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  ngOnDestroy() {
    console.log('## on destroy status list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

}
