import { DynamicFormModel } from '../../../model/champs_formulaire/dynamic-form.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

export interface IDynamicFormInit {
  valueCode: string
}

export interface INextForm {
  init: boolean;
  dynamicFormModelsDefault: DynamicFormModel[];
  tabOption: any[];
  hasBuiltList: boolean;
  formulaire: UntypedFormGroup;
  formBuilder: UntypedFormBuilder;
  buildingFormByStep: IBuildingFormByStep;
}

export interface IBuildingFormByStep {
  lastIndexForm: number;
  previousIndexForm: number;
  dynamicFormModels: DynamicFormModel[];
  bodyModels: DynamicFormModel[];
  headerModels: DynamicFormModel[];
}

@Injectable({ providedIn: 'root' })
export class DynamicFormInit {
  constructor() {
  }

  getFields(): IDynamicFormInit {
    return {
      valueCode: "valueCode"
    }
  }
}