import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { Account } from '../../../../model/account.model';
import { ExchangeColumnName, IExchangeColumnName } from '../../exchange-rate/exchange-rate-column-name';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { ExchangeRateService } from '../../exchange-rate/exchange-rate.service';
import { FormService } from '../../../../shared/services/form.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { ExchangeRate, IExchangeRate } from '../../../../model/exchange-rate.model';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';
import { IVExchangeColumnName, VExchangeColumnName } from '../vexchange-rate-column-name';
import { IVersionExchangeRate, VersionExchangeRate } from '../../../../model/version-exchange-rate.model';
import { VersionExchangeRateService } from '../version-exchange-rate.service';

/**
 * Permet de gérer l'ajout de version des exchange rate.
 */
@Component({
  selector: 'app-v-exchange-rate-add',
  templateUrl: './v-exchange-rate-add.component.html',
  animations: [fadeIn]
})
export class VExchangeRateAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  account: Account; // The account connected.

  column: IVExchangeColumnName;
  private subs$ = new Subject<void>();

  isSaving = false;
  successSave = false;
  serverHasError = false;
  editForm: UntypedFormGroup;

  theDialogRef: MatDialogRef<VExchangeRateAddComponent>;

  constructor(
    private accountService: AccountService,
    protected exchangeRateService: VersionExchangeRateService,
    private dialogRef: MatDialogRef<VExchangeRateAddComponent>,
    private formService: FormService,
    private columnName: VExchangeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct exchange rate add');
    this.theDialogRef = this.dialogRef;
    this.column = this.columnName.getColumnName();
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init exchange rate add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.account = account;
      });
  }

  ngOnDestroy() {
    console.log('## destroy exchange rate add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  initForm(fb: UntypedFormBuilder) {
    try {
      this.editForm = this.fb.group({
        id: [],
        version: [
          '',
          [Validators.required, Validators.minLength(1),
            Validators.maxLength(200)]
        ],
        description: [
          '',
          [Validators.maxLength(200)]
        ]
      });

    } catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save version exchange rate');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: IVersionExchangeRate = this.createFromForm();
    console.log('## data: ', c);
    this.exchangeRateService.create(c).subscribe(res => {
        console.log('## success create version exchange rate');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.exchangeRateService.query().subscribe(res => {
          console.log('## Success get version exchange rate list after saving');
          const c : IVersionExchangeRate[] = (res.body) ? res.body : [];
          console.log('## size: ' + c.length);
          this.exchangeRateService.setVersionExchangeRateObs(c);
        });
      },
      err => {
        console.log('## error create version exchange rate');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, TIME_MIN_REQUEST);
  }

  private createFromForm(): IVersionExchangeRate {
    return {
      ...new VersionExchangeRate(),
      id: this.editForm.get([this.column.id]).value,
      version: this.editForm.get([this.column.version]).value,
      description: this.editForm.get([this.column.description]).value,
    };
  }

  closeModal() {
    console.log('## close exchange rate add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }
  reset(){
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
