import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Subject } from 'rxjs';
import { Account, ROLE, ROLE_NAME } from '../../../../model/account.model';
import { CUser, ICUser } from '../../../../model/c-user.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IOption } from '../../../../ui/interfaces/option';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { RoleService } from '../../../../shared/services/role.service';
import { ColorService } from '../../../../shared/services/color.service';
import { FormService } from '../../../../shared/services/form.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { AccountUserService } from '../../../../services/ServiceEntity/account-user.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { BASE_URL } from '../../../../../environments/environment';
import {
  BTN_CANCEL_CLASS,
  CANCEL_BG_COLOR, CONTENT_BTN_CLASS, LABEL_FORM_CLASS,
  SUFFIX_ICON_FORM,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../../../shared/constant/css-style/css-class.constant';
import * as PageActions from '../../../../store/actions/page.actions';
import { PasswordMatchingService } from '../../../password/update/password-matching.service';
import { IMoney } from '../../../../model/money.model';
import { SelectPrefixService } from '../../../../shared/layouts/select-prefix.service';
import { PRINCIPAL } from '../../../../model/account-ressource.model';

@Component({
  selector: 'app-cuser-add',
  templateUrl: './cuser-add.component.html'
})
export class CuserAddComponent extends BasePageComponent implements OnInit, OnDestroy,
  PasswordMatchingService, SelectPrefixService {
  subs$ = new Subject<void>();
  indicatif: string;

  rolePartener = ROLE.partener;
  roleAdmin = ROLE.admin;
  roleTrader = ROLE.trader;

  //The Current User var
  CurentUser:any;
  CurrentUserAuth:any;
  thisUserId:number;
  thisUser:ICUser;
  iduser:string;
  //
  tableData: any[];
  searchData: any[];
  //
  messagesForm: UntypedFormGroup;
  loadingMessagesForm: boolean;
  theDialogRef: MatDialogRef<CuserAddComponent>;
  //
  countrylist: IOption[];
  authority: IOption[];
  //
  authorities: any;
  countries: any;
  //
  AttributId = 1;
  CuserToUpdate: any;
  //
  alertAddCuser:any;
  alertCuserExit:any;

  isSaving: boolean;
  act:string;
  title: string;
  role: string;

//  selectPrefixService: SelectPrefixService;

  constructor(store: Store<IAppState>, httpSv: HttpService,
              private roleService: RoleService,
              private colorService: ColorService,
              private formService: FormService,
              private formBuilder: UntypedFormBuilder,
              private http:HttpClient,
              private router:Router,
              private dialogRef: MatDialogRef<CuserAddComponent>,
              protected accountService: AccountService,
              protected cUserService: CUserService,
              private accountUserService:AccountUserService,
              private modal: TCModalService,
              private translate: TranslateService) {
    super(store, httpSv);
    this.theDialogRef = this.dialogRef;
    // this.selectPrefixService = this;
    this.tableData = [];
    this.countrylist = [];
    this.authority = [];
    this.loadingMessagesForm = false;
  //  this.initRole();
    this.initMessagesForm();
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  getContentClass() {
    return CONTENT_BTN_CLASS;
  }

  //status C-User
  StatusTrader(data: CUser){
    let act=data.activated;
    /*  let status = data.status;
     status = status === 'ACTIVED' ? 'DEACTIVED' : 'ACTIVED'; */
    this.isSaving = true;
    const id = (data.id.toString());
    const option={id,act}
    this.cUserService.updateStatusCUser( option).subscribe((response) => {
      console.log('## status modifié avec succès', response);
      const cuser = response.body;
      console.log('responseeeeee', cuser);
      data.activated=cuser.activated
      //data.activated ===(cuser && cuser.activated) ? cuser.activated : data.activated;

    });
  }

  ngOnInit(): void {
    console.log('## init cuser add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## account: ', account);
        this.role = this.roleService.getTheRole(account?.authorities);
       // this.getCurrentUser(account);
        console.log('accounttttt', this.CurentUser);
        this.getData(`${BASE_URL}Authority/getAuthority`,
          'authorities', 'initRole');
        this.getData(`${BASE_URL}countries`,
          'countries', 'initCountry');
        /*        this.initMessagesForm();
                this.initUpdateForm();*/
      });
  }

  ngOnDestroy() {
    console.log('## destroy cuser add');
    this.formService.free(this.subs$);
  }

  getRole(authority: string) {
    return this.roleService.getRole(authority);
  }


  isMatching() {
    const newPass = this.messagesForm.get('password').value;
    const confirmPass = this.messagesForm.get('ConfirmPassword').value;
  //  console.log('## password: ', newPass);
  //  console.log('## confirmPass: ', confirmPass);
    return ((newPass && confirmPass) && (newPass === confirmPass));
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }
  // initCountryAuthority() {

  getThisUser(account: ICUser) {
    this.cUserService
      .query({})
      .subscribe((res: HttpResponse<ICUser[]>) => {
        this.thisUser = res.body.find(resul => {
          return resul.userId === account.id;
        });
      });
  }

  initCountryAuthority() {
    this.initCountry();
    this.initRole();
  }

  private getDataServ(res: HttpResponse<ICUser[]>) {
    try {
      const data = res.body;
      console.log('## data without filter: ', data);
      console.log('## size: ', data.length);
      const dataFilter = data.filter(resul => resul.authority === this.roleTrader
        || resul.authority === this.rolePartener);
      console.log('## data filter: ', dataFilter);
      console.log('## size: ', dataFilter.length);
      this.tableData = dataFilter;
      this.searchData = dataFilter;
/*      this.cUserService.getIcuserObs().pipe(takeUntil(this.subs$))
        .subscribe((res: IMoney[]) => {
          console.log('## get list money in list by subject');
          this.tableData = (res) ? res : [];
          this.searchData = this.searchData;
        })*/
    } catch (e) {
      console.log('## error get data:', e.message);
    }
  }

  /**
   * Récupère tous les utilisateurs créés par l'utilisateur
   * connecté.
   */
  loadAllList() {
    this.cUserService
      .queryList()
      .subscribe((res: HttpResponse<ICUser[]>) => {
          this.getDataServ(res);
        }, err => {
          console.log('## error get data:', err.message);
        }
      );
  }

  /**
   * Récupère tous les utilisateurs.
   */
  loadAll() {
    this.cUserService
      .query()
      .subscribe((res: HttpResponse<ICUser[]>) => {
          this.getDataServ(res);
        }, err => {
          console.log('## error get data:', err.message);
        }
      );
  }

  closeModal() {
    console.log('## close cuser add');
    this.dialogRef.close();
  }


  //initialiser le formulaire pour l'ajout d'un trader
  initMessagesForm() {
    console.log('## init message form');
    this.messagesForm = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      numCNI: ['', Validators.required],
      login: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50)]],
      ConfirmPassword: ['', [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50)]],
      activated: [true],
      authority: ['', Validators.required],
      countryId: ['', Validators.required],
      attributsId: [this.AttributId]
      //countryName: ['', Validators.required]
    });
  }

  sendMessagesForm(valid: boolean) {
    if (valid) {
      this.loadingMessagesForm = true;
      setTimeout(() => {
        this.loadingMessagesForm = false;
        //this.messagesForm.reset();
      }, 1000);
    }
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  resetMessagesForm() {
    this.messagesForm.reset();
    this.loadingMessagesForm = false;
    this.alertCuserExit = false;
    this.alertAddCuser = true;
  }

  //Initialiser les Country pour le Select
  initCountry() {
/*    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );*/
    setTimeout(() => {
      //console.log("COUNTRIES",this.countries);
      this.countrylist = [];
      this.countries?.forEach(element => {
          this.countrylist.push({ 'label': element.label, 'value': element.id});
      });
    });
  }

  //Initialiser les Roles pour le Select
  initRole(): void {
/*    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );*/

    console.log('## authorities: ', this.authorities);

    setTimeout(() => {
      this.authority = [];
      this.authorities?.forEach(element => {
        //for role trader
        if (this.role == ROLE_NAME.partener) {
          if (element.name === this.roleTrader) {
            this.authority.push({ 'label': 'POINT_CASH', 'value': element.name });
          }
        } else {
          if (element.name === this.roleTrader || element.name === this.rolePartener) {
            if (element.name === this.roleTrader) {
              this.authority.push({ 'label': 'POINT_CASH', 'value': element.name });
            } else {
              this.authority.push({ 'label': element.name, 'value': element.name });
            }
          }
        }

      });
    });
  }

  //Initialiser la table des Cusers
  initTable(): void {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );

    setTimeout(() => {
      this.searchData = this.tableData;
      // this.searchData = JSON.parse(JSON.stringify(this.tableData));
      //console.log("C-USER",this.tableData);
    });
  }

  //recherche sur les traders
  search(query: string): void {
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.tableData].map(mapFn);
    this.searchData = this.tableData.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
  }

  getPrefixColorSelect(): string {
    return this.colorService.getPrefixColorSelect();
  }

  selectCountry(countrySelected) {
    console.log('## country selected: ', countrySelected);
    for(let c of this.countries) {
      if(c.id === countrySelected) {
        console.log('## c_id: ', c.id, ' match e_selected_id: ', countrySelected);
        this.indicatif = c.codePhone;
        console.log('## c_id indicatif: ', this.indicatif);
        break;
      }
    }
  }

  getPrefix(): string {
    return this.indicatif;
  }

  getPrefixModified(): string {
    if(this.indicatif) {
      return '+' +this.indicatif;
    }
    return '';
  }

  private createFromForm(): ICUser {
    const login = this.messagesForm.get(['phoneNumber'])?.value;
    console.log('## my indicatif: ', this.indicatif);

   // const phoneNumber = this.indicatif ? this.indicatif.toString().concat(login) : undefined;
    return {
      ...new CUser(),
      login: login,
      lastName: this.messagesForm.get(['lastName'])?.value,
      firstName: this.messagesForm.get(['firstName'])?.value,
      email: this.messagesForm.get(['email'])?.value,
      phoneNumber: login,
      activated: true,
      numCNI: this.messagesForm.get(['numCNI'])?.value,
      authority: this.messagesForm.get(['authority'])?.value,
      countryId: this.messagesForm.get(['countryId'])?.value,
      attributsId: this.AttributId,
      password: this.messagesForm.get(['password'])?.value
    };
  }
  // ADD E.

  //Ajouter un trader
  addTrader() {
    console.log('## adding trader');
    this.loadingMessagesForm = true;
    this.alertAddCuser = false;
    this.alertCuserExit = false;
    if (this.messagesForm.get(['password']).value === this.messagesForm.get(['ConfirmPassword']).value) {
      let user = this.createFromForm();
      console.log('### try to create:', user);
      this.cUserService.create(user).subscribe((resp) => {
        console.log("success create user");
        this.loadingMessagesForm = false;
        const u : ICUser = resp.body;
        console.log("val:",u);
        this.addMobileAccount(u);
        this.resetMessagesForm();
        if(this.role === ROLE_NAME.partener) {
          this.cUserService.getAllCuserList();
          /* On vérifie si le role est celui de l'admin, alors on renvoie
          tous les utilisateurs avec leur status. */
        } else if(this.role === ROLE_NAME.admin) {
          this.cUserService.getAllCuserWithStatus();
        }
      }, err => {
        console.log(err);
        this.loadingMessagesForm = false;
        this.alertCuserExit = true;
        this.alertAddCuser = false;
      });
    } else {
      this.alertAddCuser = true;
    }
  }

  //Ajouter un Mobile Account
  addMobileAccount(data: any) {
    const mobileAccount = {
      'cUserId': Number(data.id),
      'label': 'Main Account',
      'preferenceCurrency': data.currency,
      'phoneNumber': data.phoneNumber,
      'countryLabel': data.countryName
    };
    //console.log("## DATA: ",JSON.stringify(mobileAccount));
    this.accountUserService.create(mobileAccount).subscribe((rep) => {
      const body = rep?.body;
      console.log("MobileAccount", body);
      this.addAccountRessources(body);
    }, err => {
      console.log(err);
    });
  }


  //Ajouter un  Account Ressource
  addAccountRessources(dataMobileAccount: any) {
    //account-ressource
    console.log('## datamobileacc: ', dataMobileAccount);
    const accountRessource = {
      'amount': 0,
      'accountRessTypeId': 1,
      'accountRessType': 'CASH',
      'currency': dataMobileAccount.preferenceCurrency,
      'mobileAccountId': dataMobileAccount.id,
      'labelAccountUser': dataMobileAccount.label,
      'principal': PRINCIPAL.MAIN
    };
    this.http.post(`${BASE_URL}account-ressource`, accountRessource).subscribe((rep) => {
      console.log("### Account Ressource", rep);
    }, err => {
      console.log(err);
    });
  }

  //supprimer un trader
  removeTrader(id) {
    this.http.delete(`${BASE_URL}c-users/${id}`).subscribe(() => {
      console.log('SUCCESS');
      //this.loadTable();
    }, err => {
      console.log(err);
    });
  }

  isDirty(column: string): boolean {
    return this.formService.isDirty(this.messagesForm,
      column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.messagesForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.messagesForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.messagesForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.messagesForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.messagesForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.messagesForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.messagesForm, column);
  }

}
