import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from '../../base-page';
import { Account } from '../../../model/account.model';
import { Observable, Subject } from 'rxjs';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { ConstantHome } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { CONSTANT_ICON } from '../../../shared/constant/icon.constant';
import { ExchangeRate, IExchangeRate, IExchangeRateDto } from '../../../model/exchange-rate.model';
import { ExchangeRateService } from './exchange-rate.service';
import { ConstantExchangeRate } from './exchange-rate.url';
import { ExchangeRateAddComponent } from './add/exchange-rate-add.component';
import { ExchangeUpdateComponent } from './update/exchange-rate-update.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BTN_CANCEL_CLASS, CANCEL_BG_COLOR } from '../../../shared/constant/css-style/css-class.constant';
import { ColorService } from '../../../shared/services/color.service';
import { IVExchangeColumnName, VExchangeColumnName } from '../version-exch-rate/vexchange-rate-column-name';
import { IVersionExchangeRate, VersionExchangeRate } from '../../../model/version-exchange-rate.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../shared/constant/request.contant';
import { VersionExchangeRateService } from '../version-exch-rate/version-exchange-rate.service';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html'
})
export class ExchangeRateComponent extends BasePageComponent implements OnInit,
  OnDestroy, AfterViewChecked {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  exchangeRateList: IExchangeRateDto[] = [];
  exchangeRateSearch: IExchangeRateDto[] = [];
  findExchangeRateList = false;

  searchValue: string;
  subs$ = new Subject<void>();
  column: IVExchangeColumnName;

  versionSelected: IVersionExchangeRate;
  versionExchangeRateList: IVersionExchangeRate[] = [];
  filteredOptions: Observable<IVersionExchangeRate[]>;

  /* Contrôleur de l'input (auto complete) des version d'exchange rate */
  versionControl = new UntypedFormControl();

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private colorService: ColorService,
    protected versionExchangeRateService: VersionExchangeRateService,
    private formService: FormService,
    private dialog: MatDialog,
    private columnName: VExchangeColumnName,
    private accountService: AccountService,
    protected exchangeRateService: ExchangeRateService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct exchange rate list');
    this.pageData = {
      title: '',
      key: 'listExchangeRate',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: '',
          key: 'exchangeRate',
          route: Url.SLASH_ROUTE.concat(ConstantExchangeRate.urlFull
            .EXCHANGE_RATE.LIST)
        },
        {
          title: '',
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'listExchangeRate') {
      translate.get('listExchangeRate').subscribe(res => this.pageData.title = res);
    }
    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'exchangeRate') {
        translate.get('exchangeRate').subscribe(res => value.title = res);
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }
    });
    this.column = this.columnName.getColumnName();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init exchange rate');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          if (account) {
            super.setLoaded();
            this.getExchangeRateList();
          }
          this.account = account;
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    console.log('## on destroy exchange rate list');
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  private _filter(value: any): IVersionExchangeRate[] {
    let filteredList;
    try {
      console.log('## value version: ', value);
      const filterValue = (typeof value === 'string') ? value.toLowerCase() : value.version.toLowerCase();
      filteredList = this.versionExchangeRateList.filter(option => option.version.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter version of exchange rate: ' + e.message);
    }
    return filteredList;
  }

  displayFnVersion(subject: IVersionExchangeRate) {
    return subject ? subject.version : '';
  }

  getListExchageRate(v: MatAutocompleteSelectedEvent) {
    const val = v.option.value;
    this.versionSelected = val;
    console.log('## get list of exchange rate: ', val);
    console.log('## typeof: ', typeof val.id);
    this.exchangeRateService.getListByVersion(val.id).subscribe(res => {
      console.log('## Success get exchange rate by version');
      const e = (res.body) ? res.body : [];
      this.exchangeRateList = e;
      console.log('## size: ' + e.length);
      if(e.length > 0) {
        const first = e[0];
        this.versionSelected = new ExchangeRate();
        this.versionSelected.id = first['versionExchangeRateId'];
        this.versionSelected.version = first['versionExchangeRate'];
        this.versionSelected.etat = first['etatExchangeRate'];
       // this.versionSelected = first['versionExchangeRate']
        console.log('## versionselected: ', this.versionSelected);
        this.exchangeRateService.versionExchangeRateSelected = this.versionSelected;
      }
      this.findExchangeRateList = true;
      this.exchangeRateSearch = e;
      /*this.exchangeRateService.getExchangeRateObs().pipe(takeUntil(this.subs$))
        .subscribe((res: IExchangeRateDto[]) => {
          console.log('## get list exchange rate in list by subject');
          const rs = res ? res : [];
          console.log('## size: ' + rs.length);
          this.exchangeRateList = rs;
          this.exchangeRateSearch = rs;
        });*/
    });

  }

  /**
   * Récupère la liste des versions d'exchange rate et
   * les initialise dans un auto complete.
   */
  getVersionExchangeRateList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.versionExchangeRateService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IVersionExchangeRate[]>) => mayBeOk.ok),
          map((response: HttpResponse<IVersionExchangeRate[]>) => response.body)
        )
        .subscribe((res: IVersionExchangeRate[]) => {
            console.log('## success get list version of exchange rate while adding: ', res);
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.versionExchangeRateList = rs;
            this.filteredOptions = this.versionControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list version of exchange rate while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  goToLive() {
    this.router.navigate([Url.SLASH_ROUTE.concat(ConstantExchangeRate.urlFull
      .EXCHANGE_RATE.LIVE)]);
  }

  goToHistorical() {
    this.router.navigate([Url.SLASH_ROUTE.concat(ConstantExchangeRate.urlFull
      .EXCHANGE_RATE.HISTORICAL)]);
  }

  /**
   * Récupère la liste d'exchange rate dont la version est utilisée.
   */
  getExchangeRateList() {
    this.exchangeRateService.getListUsed().subscribe(res => {
      console.log('## Success get exchange rate list: ', res);
      const e = (res.body) ? res.body : [];
      this.exchangeRateList = e;
      console.log('## size: ' + e.length);
      if(e.length > 0) {
        const first = e[0];
        this.versionSelected = new VersionExchangeRate();
        this.versionSelected.id = first['versionExchangeRateId'];
        this.versionSelected.version = first['versionExchangeRate'];
        this.versionSelected.etat = first['etatExchangeRate'];
       // this.versionSelected = first['versionExchangeRate'];
        console.log('## versionselected: ', this.versionSelected);
        this.exchangeRateService.versionExchangeRateSelected = this.versionSelected;
      }
      this.findExchangeRateList = true;
      this.exchangeRateSearch = e;
      // Récupère la liste des versions d'exchange rate.
      this.getVersionExchangeRateList();
      this.exchangeRateService.getExchangeRateObs().pipe(takeUntil(this.subs$))
        .subscribe((res: IExchangeRateDto[]) => {
          console.log('## get list exchange rate in list by subject');
          const rs = res ? res : [];
          console.log('## size: ' + rs.length);
          this.exchangeRateList = rs;
          this.exchangeRateSearch = rs;
        });
    });
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.exchangeRateSearch].map(mapFn);
    const result = this.exchangeRateSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.exchangeRateList = [];
    result.forEach((c) => {
      this.exchangeRateList.push(c);
    });
  }

  hasFound(): boolean {
    return this.findExchangeRateList;
  }

  exchangeRateIsEmpty() {
    return this.exchangeRateList.length <= 0 || !this.exchangeRateList;
  }

  /* search(): void {
     const query = this.searchValue;
     console.log('## search: ' + query);
     const mapFn = (item) =>
       Object.values(item)
         .map(function(val) {
           return val?.toString().toLowerCase();
         })
         .join(' ');
     const stringArr = [...this.benefitSearch].map(mapFn);
     const result = this.benefitSearch.filter(
       (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
     );
     this.exchangeRateList = [];
     result.forEach((c) => {
       this.exchangeRateList.push(c);
     });
   }*/

  getIcon() {
    return CONSTANT_ICON;
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    console.log('## open exchange rate add');
    this.dialog.open(ExchangeRateAddComponent, {
      height: '700px',
      width: '800px'
    });
  }

  show(c: IExchangeRate) {
    /*console.log('## open service show');
    this.benefitService.setServiceItemSelectObs(c);
    this.dialog.open(MoniesShowComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  update(c: IExchangeRate) {
    this.exchangeRateService.setExchangeRateSelectObs(c);
    this.dialog.open(ExchangeUpdateComponent, {
      height: '700px',
      width: '800px'
    });
  }

  delete(c: IExchangeRate) {
    /*this.benefitService.setServiceItemSelectObs(c);
    this.dialog.open(ServiceItemDeleteComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  /**
   * Retourne la liste des services items
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IExchangeRateDto[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

}
