import { Injectable } from '@angular/core';
import { BASE_URL } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ICountry } from '../../../model/country.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { createRequestOption } from '../../../util/request-util';
import { IMoney } from '../../../model/money.model';

@Injectable({ providedIn: 'root' })
export class MoniesService {
  public resourceUrl = BASE_URL + 'monies';
  private money = new Subject<IMoney[]>();

  // le money sélectionné dans la liste.
  private moneySelect = new BehaviorSubject<IMoney>(undefined);

  constructor(protected http: HttpClient) {}

  getMoneySelectObs() : Observable<IMoney>{
    return this.moneySelect.asObservable();
  }

  setMoneySelectObs(c: IMoney) {
    this.moneySelect.next(c);
  }

  getMoneyObs() {
    return this.money.asObservable();
  }

  setMoneyObs(c: IMoney[]) {
    this.money.next(c);
  }

  /**
   * Permet de récupérer la liste des money
   * pour être partagée.
   */
  getAllMoney() {
    setTimeout(() => {
      this.query().subscribe(res => {
        console.log('## Success get money list');
        this.setMoneyObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }

  create(money: IMoney): Observable<HttpResponse<IMoney>> {
    return this.http.post<IMoney>(this.resourceUrl, money,
      { observe: 'response' });
  }

  update(money: IMoney): Observable<HttpResponse<IMoney>> {
    return this.http.put<IMoney>(this.resourceUrl, money,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des money.
   * @param req
   */
  query(req?: any): Observable<HttpResponse<IMoney[]>> {
    const options = createRequestOption(req);
    return this.http.get<IMoney[]>(this.resourceUrl,
      { params: options, observe: 'response' });
  }

}
