export const SETTINGS0 = {
  boxed: false,
  layout: 'vertical', // horizontal | vertical.
  /* background: informations de l'utilisateur */
  // sidebarBg: '#ff1a1a',
  /* Background: barre de navigation gauche */
  sidebarBg2: '#224b69',
  /* Couleur: label de barre de navigation gauche */
  sidebarColor: '#ffffff',
  /* background: label de barre de navigation gauche */
  sidebarAccentColor: '#5a93ff',
  /* Couleur: label de barre de navigation gauche */
  sidebarAccentContrastColor: '#ffffff',
  sidebarOpened: false,
  /* Background: barre de navigation haute */
  topbarBg: '#224b69',
  /* Couleur: label de barre de navigation haute */
  topbarColor: '#4a505c',
  headerFormBg: '#617a8e',
  titleHeaderFormColor: '#ececec'
};

export const SETTINGS = {
  boxed: false,
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#cd5447',
  sidebarBg2: '#9d5ce5',
  sidebarColor: '#ffffff',
  sidebarAccentColor: '#f8e71c',
  sidebarAccentContrastColor: '#8b572a',
  sidebarOpened: false,
  topbarBg: '#ffffff',
  topbarColor: '#4a505c'
};

// Demo 2
export const SETTINGS2 = {
  boxed: true,
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#cd5447',
  sidebarBg2: '#9d5ce5',
  sidebarColor: '#ffffff',
  sidebarAccentColor: '#f8e71c',
  sidebarAccentContrastColor: '#8b572a',
  sidebarOpened: false,
  topbarBg: '#ffffff',
  topbarColor: '#4a505c'
};

// Demo 3
export const SETTINGS3 = {
  boxed: false,
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#f4f5f8',
  sidebarBg2: '#f4f5f8',
  sidebarColor: '#4a505c',
  sidebarAccentColor: '#cd5447',
  sidebarAccentContrastColor: '#ffffff',
  sidebarOpened: false,
  topbarBg: '#ffffff',
  topbarColor: '#4a505c'
};

// Demo 4
export const SETTINGS4 = {
  boxed: false,
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#30323F',
  sidebarBg2: '#30323F',
  sidebarColor: '#ffffff',
  sidebarAccentColor: '#a1a8c3',
  sidebarAccentContrastColor: '#8b572a',
  sidebarOpened: false,
  topbarBg: '#ffffff',
  topbarColor: '#4a505c'
};

// Demo 5
export const SETTINGS5 = {
  boxed: false,
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#f4f5f8',
  sidebarBg2: '#f4f5f8',
  sidebarColor: '#4a505c',
  sidebarAccentColor: '#cd5447',
  sidebarAccentContrastColor: '#ffffff',
  sidebarOpened: false,
  topbarBg: '#30323f',
  topbarColor: '#ffffff'
};

// Demo 6
export const SETTINGS6 = {
  boxed: false,
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#484b5f',
  sidebarBg2: '#484b5f',
  sidebarColor: '#ffffff',
  sidebarAccentColor: '#cd5447',
  sidebarAccentContrastColor: '#ffffff',
  sidebarOpened: false,
  topbarBg: '#30323f',
  topbarColor: '#ffffff'
};
