import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomainColumnName, IDomainColumnName } from '../../domain/domain-column-name';
import { DomainService } from '../../domain/domain.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CUserApiKeyService } from '../../../../services/ServiceEntity/c-user-api-key.service';
import { ICashModel } from '../../cash/cash-data-model';
import { Domain } from '../../../../model/domain.model';
import { ApiKeyCDTO, IApiKeyCDTO } from '../../../../model/api-key.model';
import { IIpAdress, IIpAdressDTO, IIpAdressDTOS, IpAdress, IpAdressDTO, IpAdressDTOS } from '../../../../model/ipAdress.model';
import { HttpResponse } from '@angular/common/http';
import { ApiKeyService } from '../api-key.service';
import { TIME_MIN_X1_REQUEST, TIME_MIN_X2_REQUEST } from '../../../../shared/constant/request.contant';
import { IpAdressService } from '../ip-adress.service';

/**
 * Permet de gérer l'ajout des api key.
 */
@Component({
  selector: 'app-api-key-add',
  templateUrl: './api-key-add.component.html',
  animations: [fadeIn]
})
export class ApiKeyAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ApiKeyAddComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;
  idAccount: number;

  constructor(
    private ipAdressService: IpAdressService,
    private apiKeyService: ApiKeyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domainService: CUserApiKeyService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ApiKeyAddComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct api key add');
    this.theDialogRef = this.dialogRef;
    console.log('## idAccount: ', this.data.idAccount);
    this.idAccount = this.data.idAccount;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init api key add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy api key add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  initForm(fb: UntypedFormBuilder) {
    if (fb) {
      this.editForm = fb.group({
        ['label']: ['', []],
        ['ipAdress']: fb.array([])
      })
    }
  }

  get ipAdress() {
    return (<UntypedFormArray>this.editForm.get('ipAdress'));
  }



  /**
   * @param i the index.
   */
  removeIpAdress(i: number) {
    const ip = this.ipAdress;
    if(ip.at(i)){
      this.ipAdress.removeAt(i);
    }
  }

  addIpAdress() {
    const ipForm = this.fb.group({
      id: [],
      label: ['', Validators.required],
      description: []
    });
    this.ipAdress.push(ipForm);
  }

  getArrayControls(): AbstractControl[]{
    return this.ipAdress.controls;
  }

  private getFormIpAdressList(): IIpAdressDTO[] {
    let arrayForm = this.ipAdress;
    let ipAdressList = [];
    for(let c of arrayForm.controls) {
      if(c) {
        let i = new IpAdressDTO();
        i.label = c.get('label').value;
        i.description = c.get('description').value;
        ipAdressList.push(i);
      }
    }
    return ipAdressList;
  }

  private createFromForm(): IApiKeyCDTO {
    return {
      ...new ApiKeyCDTO(),
      label: this.editForm.get('label').value,
    };
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    console.log('## saving api key');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    let ipAdresslist = this.getFormIpAdressList();
    let data = new IpAdressDTOS();
    data.ipAddrList = ipAdresslist;
    console.log('## ip list: ', data.ipAddrList);
    this.apiKeyService
      .createApiKey(c)
      .subscribe(res => {
        console.log('## success create api key');
        // Association des adresses ip à l'api key.
        data.apiKeyId = res?.body?.id;
        console.log('## ip list data: ', data);
        this.ipAdressService.create(data).subscribe(res => {
          console.log('## success create ip adresses');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.ipAdress.clear();
          this.successSave = true;
          setTimeout(() => {
            this.isSaving = false;
            this.close();
          }, TIME_MIN_X2_REQUEST);
        });
        setTimeout(() => {
          this.apiKeyService.getAllObjetById();
        }, TIME_MIN_X1_REQUEST);
      }, err => {
        console.log('## error create api key: ', err);
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
        this.isSaving = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

}