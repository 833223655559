export const SIZE_MIN_POPUP = '300px';
export const SIZE_MEDIUM_POPUP = '400px';

export const SIZE_H2_SM_POPUP = '500px';
export const SIZE_H3_SM_POPUP = '600px';

export const SIZE_H_SM_POPUP = '400px';
export const SIZE_W_SM_POPUP = '700px';


export const SIZE_H_MD_POPUP = '700px';
export const SIZE_H2x_MD_POPUP = '800px';

export const SIZE_W_MD_POPUP = '800px';
