import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import {
  TIME_MIN_REQUEST,
  TIME_MIN_X1_REQUEST,
  TIME_MIN_X2_REQUEST,
  TIME_MIN_X_REQUEST
} from '../../../../shared/constant/request.contant';
import { DomainColumnName, IDomainColumnName } from '../domain-column-name';
import { StatusModel } from '../../../../model/status.model';
import { StatusService } from '../../status/status.service';
import { Domain } from '../../../../model/domain.model';
import { IStatusColumnName, StatusColumnName } from '../../status/status-column-name';
import { DomainService } from '../domain.service';
import { BaseDynamicComponent } from '../../../../shared/layouts/base-dynamic.component';
import { DynamicService } from '../../../../services/dynamic.service';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-domain-affect-status',
  templateUrl: './domain-affect-status.component.html',
  animations: [fadeIn]
})
export class DomainAffectStatusComponent extends BaseDynamicComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  init = false; // false, si le component n'est pas affiché.

  theDialogRef: MatDialogRef<DomainAffectStatusComponent>;
  private subs$ = new Subject<void>();
  editForm: UntypedFormGroup;
  column: IDomainColumnName;
  statusColumn: IStatusColumnName;

  private domain: Domain;

  statusList: StatusModel[] = [];
  // récupère la liste des status temporaires.
  statusListTemp: StatusModel[] = [];
  filteredOptions: Observable<StatusModel[]>;

  constructor(
    protected dynamicService: DynamicService,
    private statusService: StatusService,
    private domainService: DomainService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<DomainAffectStatusComponent>,
    private columnName: DomainColumnName,
    private statusColumnName: StatusColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(dynamicService, store, httpSv);
    console.log('## construct domain affect status');
    const column = this.columnName.getColumnNameRandom();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.statusColumn = statusColumnName.getColumnName();
    this.initForm(fb, this.statusColumn, column);
  }

  ngOnInit(): void {
    console.log('## init domain affect status');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## get domain selected to affect status');
        // récupération du domain sélectionné.
        this.domainService.getObjetSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: Domain) => {
          this.getStatusList(); // after 500ms.
          try {
            console.log('## data domain: ', res);
            this.domain = res;
            this.initDataForm(res);
          } catch (e) {
            console.log('## error get domain selected: ' + e.message);
          }
        });
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy domain add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  initDataForm(res: Domain) {
    this.editForm.patchValue({
      [this.column.label]: res.label
    });
  }

  displayFnStatus(subject: StatusModel) {
    return subject ? subject.label : '';
  }

  getControlStatusLabel() {
    return this.editForm.get(this.statusColumn.label) as UntypedFormControl;
  }

  /**
   * Récupère la liste des status appartenant à un domain.
   */
  getStatusByDomain() {
    setTimeout(() => {
      let p = new HttpParams();
      p = p.set(this.statusColumnName.getColumnNameWhenAffectStatus().domainId, this.domain.id + '');
      this.statusService
        .query(this.statusService.list_by_domain_Url, p)
        .pipe(
          filter((mayBeOk: HttpResponse<StatusModel[]>) => mayBeOk.ok),
          map((response: HttpResponse<StatusModel[]>) => response.body)
        )
        .subscribe((res: StatusModel[]) => {
            console.log('## success get list status by domain.');
            const rs = res ? res : [];
            console.log('## size status by domain: ' + rs.length);

            let statusList: StatusModel[] = []; // la liste des status non affectés.
            // on récupère la liste des status non encore affectés.
            for (let st of this.statusListTemp) {
              let hasFound = false; // vaut true si le status a déjà été affecté,
              // false dans le cas contraire.
              for (let s of rs) { // liste des status d'un domain.
                if (st.id === s.id) {
                  hasFound = true;
                  break;
                }
              }
              if (!hasFound) {
                statusList.push(st);
              }
            }
            this.statusList = statusList;
            this.init = true;
            /*this.filteredOptions = this.editForm.get(this.statusColumn.label).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );*/
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list status by domain: ', res);
          });
    }, TIME_MIN_X1_REQUEST);
  }

  /**
   * récupère la liste des status.
   */
  getStatusList() {
    setTimeout(() => {
      this.statusService
        .query(this.statusService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<StatusModel[]>) => mayBeOk.ok),
          map((response: HttpResponse<StatusModel[]>) => response.body)
        )
        .subscribe((res: StatusModel[]) => {
            console.log('## success get list status while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.statusListTemp = rs;
            console.log('## the status temp: ', this.statusListTemp);
            this.getStatusByDomain();
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list status while adding: ' + res.message);
          });
    }, TIME_MIN_X2_REQUEST);
  }

  private _filter(value: string): StatusModel[] {
    console.log('## filter value: ', value);
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.statusList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter status: ' + e.message);
    }
    return filteredList;
  }

  initForm(fb: UntypedFormBuilder, column: IStatusColumnName,
           domainColumn: IDomainColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.label]: ['', [Validators.required]],
        [column.description]: [''],
        [domainColumn.label]: ['']
      });
    }
  }

  getInit() {
    return this.init;
  }

  /**
   * Affecte un status à domain.
   */
  save() {
    console.log('## affect a status');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const column = this.statusColumnName.getColumnNameWhenAffectStatus();
    // on affecte plusieurs status au domain sélectionné.
    let statusList: StatusModel[] = this.editForm.get([this.statusColumn.label]).value;
    let statusIds = []; // liste des ids des status.
    // récupération des ids des différents status.
    for(let s of statusList) {
        statusIds.push(s.id);
    }
    const data = {
      [column.statusList]: statusIds,
      [column.domainId]: this.domain.id
    };
    console.log('## data: ', data);
    this.statusService.update(data, this.domainService.link_status)
      .subscribe(res => {
          console.log('## success link status');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.statusList = [];
          this.getStatusList();
          this.initDataForm(this.domain);
          this.successSave = true;
          this.statusService.getAllObjet();
        },
        err => {
          console.log('## error link status: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  closeModal() {
    console.log('## close');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}
