import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from '../../base-page';
import { ConstantCountries } from '../countries/countries.url';
import { ConstantHome, HOME_ROUTING } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { ConstantMonies } from './monies.url';
import { takeUntil } from 'rxjs/operators';
import { ICountry } from '../../../model/country.model';
import { MoniesService } from './monies.service';
import { Subject } from 'rxjs';
import { IMoney } from '../../../model/money.model';
import { CONSTANT_ICON } from '../../../shared/constant/icon.constant';
import { CountryAddComponent } from '../countries/add/country-add.component';
import { CountryShowComponent } from '../countries/show/country-show.component';
import { CountryUpdateComponent } from '../countries/update/country-update.component';
import { CountryDeleteComponent } from '../countries/delete/country-delete.component';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { MoniesShowComponent } from './show/monies-show.component';
import { MoniesAddComponent } from './add/monies-add.component';
import { MoniesUpdateComponent } from './update/monies-update.component';
import { MoneyDeleteComponent } from './delete/monies-delete.component';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { TranslateService } from '@ngx-translate/core';
import { BTN_CANCEL_CLASS, CANCEL_BG_COLOR } from '../../../shared/constant/css-style/css-class.constant';
import { ColorService } from '../../../shared/services/color.service';

@Component({
  selector: 'app-monies',
  templateUrl: './monies.component.html',
  styleUrls: ['./monies.component.css']
})
export class MoniesComponent extends BasePageComponent implements OnInit, OnDestroy {

  moniesList: IMoney[];
  moniesSearch: IMoney[];
  searchValue: string;
  subs$ = new Subject<void>();
  @ViewChild('basicTable') tabRef: any;

  constructor(
    private accountService: AccountService,
    private formService: FormService,
    private colorService: ColorService,
    private dialog: MatDialog,
    private moniesService: MoniesService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate:TranslateService) {
    super(store, httpSv);
    this.pageData = {
      title: ConstantMonies.data.sub.list.title,
      key: 'listMonies',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: HOME_ROUTING
        },
        {
          title: ConstantMonies.data.title,
          key: 'monies',
          route: Url.SLASH_ROUTE.concat(ConstantMonies.urlFull.MONIES.LIST)
        },
        {
          title: ConstantMonies.data.sub.lister,
          key: 'lister',
        }
      ]
    };
    if(this.pageData.key==="listMonies"){
      translate.get('listMonies').subscribe(res => this.pageData.title=res);
    }
   
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="monies" ){
        translate.get('monies').subscribe(res => value.title=res);
      }
      if( value.key==="lister" ){
        translate.get('lister').subscribe(res => value.title=res);
      }
     
    }); 
   }

  ngOnInit(): void {
    console.log('## init monies liste');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.moniesService.query().subscribe(res => {
          console.log('## Success get monies list');
          this.moniesList = (res.body) ? res.body : [];
          this.moniesSearch = this.moniesList;
          super.setLoaded();
          this.moniesService.getMoneyObs().pipe(takeUntil(this.subs$))
            .subscribe((res: IMoney[]) => {
              console.log('## get list money in list by subject');
              this.moniesList = (res) ? res : [];
              this.moniesSearch = this.moniesList;
            })
        });
      })
  }

  ngOnDestroy() {
    console.log('## on destroy monies list');
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  search() : void {
    const query = this.searchValue;
    console.log('## search: ' +query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val){
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.moniesSearch].map(mapFn);
    const result = this.moniesSearch.filter(
      (_, i) => stringArr[i]
        .indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.moniesList = [];
    result.forEach((c) => {
      this.moniesList.push(c);
    })
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    console.log('## open money add');
    this.dialog.open(MoniesAddComponent, {
      height: '700px',
      width: '800px',
    });
  }

  show(c: IMoney) {
    console.log('## open money show');
    this.moniesService.setMoneySelectObs(c);
    this.dialog.open(MoniesShowComponent, {
      height: '700px',
      width: '800px',
    });
  }
  update(c: IMoney) {
    this.moniesService.setMoneySelectObs(c);
    this.dialog.open(MoniesUpdateComponent, {
      height: '700px',
      width: '800px',
    });
  }

  delete(c: IMoney) {
    this.moniesService.setMoneySelectObs(c);
    this.dialog.open(MoneyDeleteComponent, {
      height: '700px',
      width: '800px',
    });
  }

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IMoney[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }
}
