import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { StatusDomainModel, StatusModel } from '../../../../model/status.model';
import { Domain } from '../../../../model/domain.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IStatusColumnName, StatusColumnName } from '../status-column-name';
import { StatusService } from '../status.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { TIME_MIN_X2_REQUEST } from '../../../../shared/constant/request.contant';
import { DomainService } from '../../domain/domain.service';
import { StatusLink } from '../status.url';
import { Router } from '@angular/router';

/**
 * Permet de gérer la modification d'un status.
 */
@Component({
  selector: 'app-status-liste-link',
  templateUrl: './status-update-link.component.html',
  animations: [fadeIn]
})
export class StatusUpdateLinkComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  // le status sélectionné dans la liste des status.
  statusDomainSelected: any;
  statusSelected = '';

  statusList: StatusDomainModel[] = [];
  init = false; // false, si le component n'est pas affiché.

  theDialogRef: MatDialogRef<StatusUpdateLinkComponent>;
  private subs$ = new Subject<void>();
  editForm: UntypedFormGroup;
  column: IStatusColumnName;

  formControlDomain = new UntypedFormControl();
  filteredOptions: Observable<Domain[]>;

  constructor(
    private router: Router,
    private domainService: DomainService,
    private statusService: StatusService,
    public baseService: BaseService,
    private accountService: AccountService,
    private statusColumnName: StatusColumnName,
    private formService: FormService,
    private dialogRef: MatDialogRef<StatusUpdateLinkComponent>,
    private columnName: StatusColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct status add');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init status add2');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.statusService.getObjetSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: any) => {
          console.log('## get domain selected to liste');
          try {
            console.log('## data: ', res);
            this.statusDomainSelected = res;
            /*this.editForm.patchValue({
              [this.column.id]: res.id,
            })*/
            this.getStatusList(res.domainId);
          }catch (e) {
            console.log('## error get country selected: ' + e.message);
          }
        });
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  /**
   * Récupère la liste des status.
   */
  getStatusList(domainId: number) {
    setTimeout(() => {
      let p = new HttpParams();
      p = p.set(this.statusColumnName.getColumnNameWhenAffectStatus().domainId, domainId + '');
      this.statusService
        .query(this.statusService.list_not_affected, p)
        .pipe(
          filter((mayBeOk: HttpResponse<StatusDomainModel[]>) => mayBeOk.ok),
          map((response: HttpResponse<StatusDomainModel[]>) => response.body)
        )
        .subscribe((res: StatusDomainModel[]) => {
            console.log('## success get list domains: ', res);
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.statusList = rs;
            this.filteredOptions = this.formControlDomain.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
            this.init = true; // on affiche le formulaire.
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list domains: ' + res.message);
          });
    }, TIME_MIN_X2_REQUEST);
  }

  initForm(fb: UntypedFormBuilder, column: IStatusColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.id]: ['', [Validators.required]],
      })
    }
  }

  private _filter(value: string): StatusDomainModel[] {
    console.log('## filter value: ', value);
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.statusList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter status: ' + e.message);
    }
    return filteredList;
  }

  displayFnStatus(subject: StatusDomainModel) {
    return subject ? subject.label : '';
  }

  getInit() {
    return this.init;
  }

  /**
   * Enregistre un status {@link StatusModel}
   */
  save() {
    console.log('## save status');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = {
      id: this.statusDomainSelected.domainStatusId,
      statusId: this.editForm.get([this.column.id]).value.id,
      domainId: this.statusDomainSelected.domainId
    };
    console.log('## c: ', c);
    this.domainService.update(c, this.domainService.affect_status)
      .subscribe(res => {
          console.log('## success create status');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.router.navigate(['/' + StatusLink.urlFull.FEES.LIST]);
          // this.statusService.getAllObjet();
        },
        err => {
          console.log('## error create status: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm() {
    return {
      statusId: this.editForm.get([this.column.id]).value
    };
  }

  closeModal() {
    console.log('## close formulaire add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }

}
