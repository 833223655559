import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { BASE_URL_ADMIN } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * Service permettant de gérer les détails de coûts
 * see {@link ICostsDetails}
 */
@Injectable({ providedIn: 'root' })
export class CostsDetailsService extends ApiService {

  public typesUrl = BASE_URL_ADMIN + '/typeDetails';

  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * {@link ApiService#getAllObjet}
   */
  getAllObjet(req?: any) {
    super.getAllObjet(this.typesUrl, req);
  }
}