import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { ValidatorListComponent } from '../validators/validator-list.component';
import { ValidatorAddComponent } from '../validators/add/validator-add.component';
import { ValidatorUpdateComponent } from '../validators/update/validator-update.component';
import { ValidatorDeleteComponent } from '../validators/delete/validator-delete.component';
import { ValidatorAffectedComponent } from './validator-affected.component';
import { ValidatorAffectedUpdateComponent } from './update/validator-affected-update.component';
import { ValidatorAffectedDeleteComponent } from './delete/validator-affected-delete.component';
import { SourceFormDeleteComponent } from '../source-form/delete/source-form-delete.component';

/**
 * Ce module permet de gérer le model costRule.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [SourceFormDeleteComponent,
        ValidatorAffectedComponent, ValidatorAffectedDeleteComponent,
        ValidatorAffectedUpdateComponent]
})
export class ValidatorAffectedModule {}
