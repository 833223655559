import { Injectable } from '@angular/core';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ICUser } from '../../../../model/c-user.model';
import { ICuserListService } from './i-cuser-list.service';

/**
 * Utilisé lorsque l'utilisateur fournit une donnée invalide.
 */
@Injectable({ providedIn: 'root' })
export class NoUserListService implements ICuserListService{


  constructor(private cUserService: CUserService) {
  }

  /**
   * @return Aucune donnée.
   */
  loadAll(): Observable<HttpResponse<ICUser[]>> {
    return undefined;
  }

  getUser(req?): Observable<HttpResponse<ICUser>> {
    return undefined;
  }

}