import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BASE_URL } from 'src/environments/environment';
import { BasePageComponent } from '../../base-page';
import { Content } from '../../../ui/interfaces/modal';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { error } from 'jquery';
import { IOption } from 'src/app/ui/interfaces/option';
import * as PageActions from '../../../store/actions/page.actions';
import { IAccountRessource } from '../../../model/account-ressource.model';
import { AccRessListComponent } from '../account-ressource/list/acc-ress-list.component';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMobileAccount, MobileAccount, MobileAccountCash } from 'src/app/model/mobile-account.model';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ICUser } from 'src/app/model/c-user.model';
import { TransactionsService } from 'src/app/services/ServiceEntity/transactions.service';
import { ITransaction } from 'src/app/model/transaction.model';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { Account, ROLE, STATUS } from 'src/app/model/account.model';
import { ICountry } from 'src/app/model/country.model';
import { CountryService } from '../countries/countries.service';
import { CONSTANT_ICON } from 'src/app/shared/constant/icon.constant';
import { CuserDetailManageModalComponent } from './add/cuser-detail-manage-modal.component';
import { AccountRessourceService } from '../account-ressource/acc-ress.service';
import { ServiceItemService } from '../service-items/service-item.service';
import { CUserService } from '../../../services/ServiceEntity/c-user.service';
import { TranslateService } from '@ngx-translate/core';
import { ColorService } from '../../../shared/services/color.service';
import { Code, ICode } from '../../../model/code.model';
import { ShowTransactionComponent } from '../view-account/show-transaction/show-transaction.component';
import { CodeService } from '../../../services/ServiceEntity/code.service';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { CONTAINER_DIALOG_POPUP } from '../../../shared/constant/css-style/css-class.constant';
import { ReclamationComponent } from '../view-account/reclamation/reclamation.component';
import { RollBackReclamationComponent } from '../view-account/roll-back-reclamation/roll-back-reclamation.component';
import { CuserUpdateComponent } from '../c-user/update-user/cuser-update.component';
import { STATUS_BD_CONSTANT } from '../../../model/status.model';
import { ICuserListService } from '../c-user/services/i-cuser-list.service';
import { UserListFactoryService } from '../c-user/services/user-list-factory.service';
import { UserDeleteComponent } from '../c-user/delete/user-delete.component';
import { AccRessListByUserComponent } from '../account-ressource/list-by-user/acc-ress-list-by-user.component';


@Component({
  selector: 'app-cuser-detail-manage',
  templateUrl: './cuser-detail-manage.component.html',
  styleUrls: ['./cuser-detail-manage.component.css']
})
export class CuserDetailManageComponent extends BasePageComponent implements OnInit, OnDestroy {


  readonly ROLE_PARTENER = ROLE.partener;
  readonly ROLE_ADMIN = ROLE.admin;
  readonly ROLE_NONE = 'NONE'; // pas de role.
  mAccounts: IMobileAccount[];

  accountSelected: IMobileAccount;
  roleTrader = ROLE.trader;

  cUser: ICUser;
  displayeFormAccount: boolean;
  displayTransactions: boolean;
  actRessStatus: boolean;
  transactions: ITransaction[];
  account: Account | null = null;

  //countries
  countries: ICountry[];

  isSaving: boolean;
  //Current User
  CurentUser: any;
  CurrentUserAuth: any;
  //
  currentCuser: any;
  currentCUserId: any;
  currentCuserTab = [];
  cuserAllowedItems: any;
  allServicesItems: any;
  serviceItemToUpdate: any;
  cUserMobileAccount: any;
  CuserCurrentAccountRessource: any;
  //select var
  AccountRessourceList: IOption[];
  AccountRessource: any;
  //Active Add Cash
  ActiveCashSide = false;
  //Cash value
  cashValue: number = 0;
  //wallet show
  walletShow = false;
  currentCode: Code;

  cuserSelected: ICUser;

  myControl = new UntypedFormControl();
  subs$ = new Subject<void>();


  constructor(
    store: Store<IAppState>, httpSv: HttpService,
              private colorService: ColorService,
    private userListFactoryService: UserListFactoryService,
    private formService: FormService,
              protected activatedRoute: ActivatedRoute,
              private modal: TCModalService,
              private http: HttpClient,
              private router: Router,
              private codeService: CodeService,
              public dialog: MatDialog,
              private fb: UntypedFormBuilder,
              protected mAccountService: AccountUserService,
              private transactionService: TransactionsService,
              private accountService: AccountService,
              protected countryService: CountryService,
              private accountResService:AccountRessourceService,
              private serviceItem:ServiceItemService,
              private cUserService:CUserService,
              private translate: TranslateService
  ) {
    super(store, httpSv);
    this.currentCUserId = this.activatedRoute.snapshot.params.id;

    this.pageData = {
      title: '',
      key: 'manageTrader',
      breadcrumbs: [
        {
          title: '',
          key: 'trader',
          route: 'c-user'
        },
        {
          title: '',
          key: 'manageTrader'
        }
      ]
    };
    this.initAccountRessourceList();
    //   this.getCurrentUser();

    if(this.pageData.key==="manageTrader"){
      translate.get('manageTrader').subscribe(res => this.pageData.title=res);
    }
    this.pageData.breadcrumbs.forEach(value=> {
      if( value.key==="trader" ){
        translate.get('trade').subscribe(res => value.title=res);
      }
      if( value.key==="manageTrader" ){
        translate.get('manageTrader').subscribe(res => value.title=res);
      }
     
    }); 

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isSaving = false;
    this.displayeFormAccount = false;
    this.actRessStatus = false;
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          this.account = account;
          console.log('roleeeeeeee', this.account.authorities);
          this.getCurrentUser(account);
          this.getCurrentCuser();
          console.log('currentCUser', this.getCurrentCuser());
          this.getCurrentMobileAccount();
          this.loadAllowedItems();
          this.getAccountRessourceList();
//this.loadAll();
          console.log('## current user id:', this.currentCUserId);
          this.mAccountService.findAllAccountsByCUserId(this.currentCUserId)
            .subscribe(res => {
              console.log('## Success get mobile account list:', res);
              this.mAccounts = (res.body) ? res.body : [];
              this.mAccountService.getMAccountObs().pipe(takeUntil(this.subs$))
                .subscribe((res: IMobileAccount[]) => {
                  console.log('## get list mobile account in list by subject:', res);
                  this.mAccounts = (res) ? res : [];
                  console.log('---------', this.mAccounts);
                });
            });
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
    this.getUserSelected();
  }

  shouldIshowDeactivate() {
    const d: any = this.cuserSelected;
    const id = d.statusId;
    const idDeactivate = STATUS_BD_CONSTANT.deactived;
    return id !== idDeactivate;
  }

  getStatusDeactivated() {
    return STATUS_BD_CONSTANT.deactived;
  }

  getStatusActivated() {
    return STATUS_BD_CONSTANT.actived;
  }

  delete() {
    this.dialog.open(UserDeleteComponent, {
      height: '500px',
      width: '800px',
    });
  }

  /**
   * Change le status d'un utilisateur.
   * @param d données de l'utilisateur.
   * @param statusId le nouveau status de l'utilisateur.
   */
  changeStatus(statusId: number) {
    const d: any = this.cuserSelected;
    console.log('## data: ', d);
    let data = {
      cUserId: d.id,
      statusId: statusId
    };
    console.log('## data2: ', data);
    console.log('## data2 new status: ', statusId);
    this.cUserService.updateStatus(data)
      .subscribe((response) => {
        console.log('## status modifié avec succès', response);
        console.log('## try to find cuserId: ', this.cuserSelected.id);
        this.getTheUser(this.cuserSelected.id)
      }, (err) => {
        console.log('## error when change status: ', err);
      });
  }

  getTheUser(userId: number) {
    let service: ICuserListService =
      this.userListFactoryService.getService(this.CurrentUserAuth);
    console.log('## service: ', service);
    let p = new HttpParams();
    p = p.set('cuserId', userId);
    service.getUser(p).subscribe((res: HttpResponse<ICUser>) => {
        console.log('## result user: ', res.body);
        this.cuserSelected = res.body;
      }, err => {
        console.log('## error get data:', err.message);
      }
    );
  }

  shouldIshowActivate() {
    const d: any = this.cuserSelected;
    const id = d.statusId;
    const idActivate = STATUS_BD_CONSTANT.actived;
    return id !== idActivate;
  }

  getUserSelected() {
    this.cUserService.getIcuserSelectObs().pipe(takeUntil(this.subs$))
      .subscribe((u: ICUser) => {
      console.log('## user selected: ', u);
      this.cuserSelected = u;
    })
  }

  /**
   * Ouvre le template html {@link CuserUpdateComponent}
   * dans un popup.
   */
  openUpdateUser() {
    console.log('## open update user');
    this.cUserService.roleSelected = this.roleTrader;
    this.dialog.open(CuserUpdateComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP
    });
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  getStatusBackground(status: number) {
    return this.colorService.getStatusBg(status);
  }

  viewTransaction(data) {
    console.log('dataaaaaaaa', data);
    this.codeService
      .findByReceiverId(data.receiverId)
      .pipe(
        filter((reslt: HttpResponse<ICode>) => reslt.ok),
        map((reslt: HttpResponse<ICode>) => reslt.body)
      )
      .subscribe((reslt: ICode) => {
        this.currentCode = reslt;

      });
    const dialogConfig = new MatDialogConfig();

    this.dialog.open(ShowTransactionComponent, {
      data: { transaction: data, code: this.currentCode }, height: '400px',
      width: '600px'
    });

  }

  viewReclamation(data){
    this.codeService
      .findByReceiverId(data.receiverId)
      .pipe(
        filter((reslt: HttpResponse<ICode>) => reslt.ok),
        map((reslt: HttpResponse<ICode>) => reslt.body)
      )
      .subscribe((reslt: ICode) => {
        this.currentCode = reslt;

      });
     
      this.transactionService
      .IfDemandeExiste(data.id)
      .pipe(
        filter((reslt: HttpResponse<ICode>) => reslt.ok),
        map((reslt: HttpResponse<ICode>) => reslt.body)
      )
      .subscribe(e => {
        if (e === true) {
          const result=e;
          this.dialog.open(ReclamationComponent, {
            data: { result: result }, height: '150px',
            width: '300px'
          });                  
          
        }
        if (e === false) {
          const result=e;
          this.dialog.open(ReclamationComponent, {
            data: { transaction: data, code: this.currentCode,result: result }, height: '600px',
            width: '700px'
          });
         
        }
      });
    }

    roolback(transaction: ITransaction) {
      
      this.transactionService
        .IfDemandeExisteA(transaction.id)
        .pipe(
          filter((reslt: HttpResponse<ICode>) => reslt.ok),
          map((reslt: HttpResponse<ICode>) => reslt.body)
        )
        .subscribe(e => {
          if (e === true) {
            const result=e;
            this.dialog.open(RollBackReclamationComponent, {
              data: { transaction: transaction, code: this.currentCode,result: result }, height: '600px',
              width: '700px'
            });
          }
          if (e === false) {
            //alert('Pas de Reclamation ici');
            const result=e;
           this.dialog.open(RollBackReclamationComponent, {
            data: { result: result }, height: '150px',
            width: '300px'
          });                  
          return null;
            
          }
        });
    }

  format(n: number) {
    return this.formService.format(n);
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  getAmountColBg() {
    return this.colorService.getAmountColBg();
  }


  //all mobile account user
  loadAll() {
    this.mAccountService
      .findAllAccountsByCUserId(this.currentCUserId)
      .pipe(
        filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
        map((res: HttpResponse<IMobileAccount[]>) => res.body)
      )
      .subscribe((res: IMobileAccount[]) => {
        this.mAccounts = res;

      });
    // this.accountRessTypeService
    //   .query()
    //   .pipe(
    //     filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
    //     map((res: HttpResponse<IMobileAccount[]>) => res.body)
    //   )
    //   .subscribe((res: IMobileAccount[]) => {
    //     this.accountRessTypes = res;
    //   });
    //.
  }

  //Pour avoir le User actuel
  getCurrentUser(response: Account) {
      this.CurentUser = response;
      if (response['authorities'][1]) {
        this.CurrentUserAuth = response['authorities'][1];
      } else {
        this.CurrentUserAuth = response['authorities'][0];
      }
      console.log('Current User', this.CurentUser);
    }

  initActiveCashSide() {
    this.ActiveCashSide = false;
  }

  initAccountRessourceList() {
    this.AccountRessourceList = [];
  }

  /*ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentCuser();
    this.getCurrentMobileAccount();
    this.loadAllowedItems();
    this.getAccountRessourceList();
    //this.getAllServicesItems();*/


  getAuthoritie(account: Account) {
   // console.log('## my acc: ', account);
    if (account) {
      if (account.authorities.indexOf(this.ROLE_ADMIN) !== -1) {
        //console.log('## admin trouvé');
        return this.ROLE_ADMIN;
      } else if (account.authorities.indexOf(this.ROLE_PARTENER) !== -1) {
        //console.log('## partener trouvé');
        return this.ROLE_PARTENER;
      }
    }
    //console.log('## none trouvé');
    return this.ROLE_NONE;
  }

  getAccountRessourceList() {
    this.getData(`${BASE_URL}account-ress-type`, 'AccountRessource', 'initAccountRessource');
  }

  initAccountRessource() {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );
    setTimeout(() => {
      //console.log("Account Ressource",this.AccountRessource);
      this.AccountRessource.forEach(element => {
        //console.log("element",element);
        this.AccountRessourceList.push({ 'label': element.type, 'value': element.id });
      });
    });
  }

  showApiKeys(m: IMobileAccount) {
    console.log('## show api keys: ', m);
    this.router.navigate(['/api-key'],
      { queryParams: {
          'account': m.id},
        state: { mobileAccount: m}});
  }

  getCurrentCuser() {
    this.getData(`${BASE_URL}c-users/${this.currentCUserId}`, 'currentCuser',
      'initcurrentCUserId');
  }

  initcurrentCUserId() {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );
    setTimeout(() => {
      //console.log("Account Ressource",this.AccountRessource);
      this.currentCuserTab.push(this?.currentCuser);
    });
  }

  getCurrentMobileAccount() {
    this.mAccountService.findAllAccountsByCUserId(this.currentCUserId).subscribe((val) => {
      this.cUserMobileAccount = val.body['0'];
      console.log('cusermobileaccount#######', this.cUserMobileAccount);
    }, err => {
      console.log(err);
    });
  }

  showM(m: MobileAccountCash) {
    console.log('## open account ressource list');

    if(this.account.authorities.indexOf(this.ROLE_ADMIN) !== -1) {
      try {
        console.log('## ' + m.id);
      } catch (e) {
        console.log('## error mobile account: ' + e.message);
      }
      this.dialog.open(AccRessListComponent, {
        data: m,
        height: '700px',
        width: '800px'
      });
    } else {
      try {
        console.log('## ' + m.id);
      } catch (e) {
        console.log('## error mobile account: ' + e.message);
      }
      this.dialog.open(AccRessListByUserComponent, {
        data: m,
        height: '700px',
        width: '800px'
      });
    }
  }

  show(m: IMobileAccount) {
    console.log('## open account ressource list');
    try {
      console.log('## mobile account: ', m);
    } catch (e) {
      console.log('## error mobile account: ' + e.message);
    }
    this.dialog.open(AccRessListComponent, {
      data: m,
      height: '700px',
      width: '800px'
    });
  }

  getCurrentAccountRessource(modalWalletBody, titleModal) {
    this.accountResService.find(this.cUserMobileAccount.id).subscribe((val) => {
      this.CuserCurrentAccountRessource = val;
      this.store.dispatch(new PageActions.Update({ loaded: true }));
      //this.router.navigate(['/vertical/c-user/cuserdetailmanage',this.currentCUserId]);
      this.openModal(modalWalletBody, titleModal);
    }, err => {
      console.log(err);
    });
  }

  //For reload the Account Ressouce Table (On modal)
  reloadCurrentAccountRessource() {
    this.getData(`${BASE_URL}account-ressource/${this.currentCUserId}`, 'CuserCurrentAccountRessource', 'setLoaded');
  }

  getAllowedItems() {
    this.getData(`${BASE_URL}c-users-service-item/${this.currentCUserId}`, 'cuserAllowedItems', 'setLoaded');
  }

  getAllServicesItems(id: any): any {
    this.serviceItem.find(id).subscribe((val) => {
      return val['name'];
    }, err => {
      console.log('ERROR while getting item', err);
    });
    //this.getData(`${baseUrl}service-item/${id}`, 'allServicesItems', 'setLoaded');
  }

  loadAllowedItems() {
    this.getAllowedItems();
  }

  ActiveServiceItem(data: any) {
    //this.getData(`${baseUrl}c-users-service-item/${data}`, 'serviceItemToUpdate', 'setLoaded');
    data.status = 'ACTIVE';
    //console.log(data);
    this.cUserService.statutServiceItem(data).subscribe((rep) => {
      //console.log("SUCCESS",rep);
      this.loadAllowedItems();
    }, err => {
      console.log('ERROR while UPDATING', err);
    });
  }

  DesactiveServiceItem(data: any) {
    data.status = 'ACTIVED';
    //console.log(data);
    this.cUserService.statutServiceItem(data).subscribe((rep) => {
      //console.log("SUCCESS",rep);
      this.loadAllowedItems();
    }, err => {
      console.log('ERROR while UPDATING', err);
    });
  }

  

  ngOnDestroy() {
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  previousState() {
    window.history.back();
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    /*  dialogConfig.disableClose = true;
     dialogConfig.autoFocus = true;   */
    this.dialog.open(CuserDetailManageModalComponent, {
      data: { cUserId: this.cUserMobileAccount.cUserId,
        mAccounts: this.mAccounts },
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP
    });
  }

  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options
    });
  }

  closeModal() {
    this.modal.close();
  }

  activeCashSide(/* data:any */) {
    this.ActiveCashSide = true;
  }

  addCash(data: any) {
    this.ActiveCashSide = false;
    data.amount = Number(data.amount) + Number(this.cashValue);
    //console.log("### Data cash", data);
    //console.log("### Current User", this.CurrentUserAuth);
    const dataForPartener = {
      'amount': data.amount,
      'id': data.id,
      'status': data.status
    };
    if (this.CurrentUserAuth == 'ROLE_ADMIN') {
      this.accountResService.update(data).subscribe((rep) => {
        this.cashValue = 0;
        console.log('### Adding cash by ADMIN');
      }, err => {
        console.log('ERROR while adding cash', err);
      });
    } else if (this.CurrentUserAuth == 'ROLE_PARTNER') {
      this.accountResService.updateByPartener(dataForPartener).subscribe((rep) => {
        this.cashValue = 0;
        console.log('### Adding cash by PARTENER');
      }, err => {
        console.log('ERROR while adding cash', err);
      });
    }
  }

  activedAR(data: any) {
    data.status = 'ACTIVED';
    //console.log(data);
    this.accountResService.update(data).subscribe((rep) => {
      //console.log("SUCCESS",rep);
      //this.loadAllowedItems();
    }, err => {
      console.log('ERROR while ACTIVED', err);
    });
  }

  desactivedAR(data: any) {
    data.status = 'DEACTIVED';
    //console.log(data);
    this.accountResService.update(data).subscribe((rep) => {
      //console.log("SUCCESS",rep);
      //this.loadAllowedItems();
    }, err => {
      console.log('ERROR while DESACTIVED', err);
    });
  }

  ViewTrader(mAccount: IMobileAccount) {
    console.log('iddddddddddddd', mAccount);
    let status = mAccount.status;
    status = status === 'ACTIVED' ? 'DEACTIVED' : 'ACTIVED';
    this.isSaving = true;

    const id = mAccount.id;
    this.mAccountService.updateStatus({ id, status }).subscribe(response => {
      console.log('## status modifié avec succès');
      mAccount.status = status;
    });

  }

  removeTrader(mAccount: IMobileAccount) {
    mAccount.status = 'REMOVED';
    this.isSaving = true;
    const id = mAccount.id;
    const label = mAccount.label;
    const numberAccount = mAccount.numberAccount;
    const status = mAccount.status;
    const cUserId = mAccount.cUserId;
    const mobileAccount = { id, label, numberAccount, status, cUserId };
    this.mAccountService.update(mobileAccount).subscribe(response => {
      this.router.navigateByUrl('c-user/cuserdetailmanage/' + this.currentCUserId);
    });
  }

  AdminAuthorisation() {
    for (let _i = 0; _i < this.account.authorities.length; _i++) {
      if (this.account.authorities[_i] === 'ROLE_ADMIN') {
        return true;
      }
    }
    return false;
  }

  changeAccount(m: IMobileAccount) {
    console.log('## account changed');
    this.accountSelected = m;
  }

  getAllTransactionsSelected(numberAccount: string, m: IMobileAccount) {
    this.accountSelected = m;
    this.displayTransactions = true;
    this.actRessStatus = false;
    this.transactionService
      .findAllTransactionsByaccountNumber(numberAccount)
      .pipe(
        filter((res: HttpResponse<ITransaction[]>) => res.ok),
        map((res: HttpResponse<ITransaction[]>) => res.body)
      )
      .subscribe((res: ITransaction[]) => {
        this.transactions = res;
        this.transactionService.getTransactionsObs().pipe(takeUntil(this.subs$))
          .subscribe((res: ITransaction[]) => {
            console.log('## get list transactions in list by subject:', res);
            this.transactions = (res) ? res : [];
            console.log('---------', this.transactions);
          });

        console.log('transactionssssssss', this.transactions);
      });
  }

  getAllTransactions(numberAccount: string) {
    this.displayTransactions = true;
    this.actRessStatus = false;
    this.transactionService
      .findAllTransactionsByaccountNumber(numberAccount)
      .pipe(
        filter((res: HttpResponse<ITransaction[]>) => res.ok),
        map((res: HttpResponse<ITransaction[]>) => res.body)
      )
      .subscribe((res: ITransaction[]) => {
        this.transactions = res;
        this.transactionService.getTransactionsObs().pipe(takeUntil(this.subs$))
                .subscribe((res: ITransaction[]) => {
                  console.log('## get list transactions in list by subject:', res);
                  this.transactions = (res) ? res : [];
                  console.log('---------', this.transactions);
                });

        console.log('transactionssssssss', this.transactions);
      });
  }

 

}
