import { Routes } from '@angular/router';
import { ConstantFeesBenefits } from '../../pages/entities/fees/fees.url';
import { VerticalLayoutComponent } from '../vertical';
import { FeesComponent } from '../../pages/entities/fees';
import { Url } from '../../shared/constant/url.constant';
import SLASH_ROUTE = Url.SLASH_ROUTE;

export const ROUTE_HOME_ = {
  signIn: 'sign-in',
  home: 'home',
  forgotPassword: 'forgot-password',
}

/**
 * Ensemble des routes pour la page d'accueil.
 */
export const ROUTE_HOME_FULL = {
  signIn: SLASH_ROUTE + ROUTE_HOME_.signIn,
  accueil: SLASH_ROUTE + ROUTE_HOME_.home,
  forgotPassword: SLASH_ROUTE + ROUTE_HOME_.forgotPassword
};
