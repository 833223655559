import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { UserRoleService } from 'src/app/services/roleMapOp/user-role.service';
import { TokenStorageService } from 'src/app/services/storage/token-storage.service';
import { BASE_URL } from 'src/environments/environment';
import { BasePageComponent } from '../../base-page';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Subject } from 'rxjs';
import { FormService } from '../../../shared/services/form.service';
import {
  SIZE_H2_SM_POPUP,
  SIZE_H_SM_POPUP,
  SIZE_MEDIUM_POPUP,
  SIZE_MIN_POPUP,
  SIZE_W_MD_POPUP,
  SIZE_W_SM_POPUP
} from '../../../shared/constant/css-style/css-size.constant';
import { CONTAINER_DIALOG_POPUP } from '../../../shared/constant/css-style/css-class.constant';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANT_MENU } from '../../../shared/constant/menu.constant';
import { IMenuItem } from '../../../interfaces/main-menu';
import { CONSTANT_KEY_TRANSLATE } from '../../../shared/constant/key-translate.constant';
import { DynamicFormComponent } from '../cash/dynamic-form/dynamic-form.component';
import {
  ID_SERVICE_ITEM_TRANSACTION_BASH,
  SERVICE_ITEM_ID_TRANSACTION_BASH,
  SERVICE_ITEM_TRANSACTION_BASH_NAME
} from '../../../shared/constant/service-item.constant';
import { CashDataModel, IRetraitCode } from '../cash/cash-data-model';
import { ServiceItemService } from '../service-items/service-item.service';
import { ServiceItemCompose, ServiceItemDisplay, ServiceItemFils } from '../../../model/service-item.model';
import { DisplayValue } from '../../../model/acc_ress_type.model';
import { ROLE, ROLE_NAME } from '../../../model/account.model';
import { AccountApiKeyComponent } from '../account-api-key/account-api-key.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BasePageComponent implements OnInit, OnDestroy {
  //for current CUsers
  CurrentUserID: any;
  token: any;
  response: any;
  authority: any;

  private subs$ = new Subject<void>();
  RoleAdmin: any;
  RolePartener: any;
  RoleTrader: any;

  serviceItemComposeList: ServiceItemCompose[] = [];

  constructor(
    private serviceItemService: ServiceItemService,
    private formService: FormService,
    private accountService: AccountService,
    private dialog: MatDialog,
    store: Store<IAppState>, httpSv: HttpService,
    private http: HttpClient,
    private localStorage: TokenStorageService,
    private userrole: UserRoleService,
    private translate: TranslateService) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      key: 'home',
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: 'home'
        },
        {
          title: '',
          key: 'default'
        }
      ]
    };
    if (this.pageData.key === 'home') {
      translate.get('home').subscribe(res => this.pageData.title = res);
    }
    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'default') {
        translate.get('default').subscribe(res => value.title = res);
      }

    });
    this.getCurrentUser();
  }

  loadUserAdmin() {
    const menu: IMenuItem[] = [];
    for (let m of CONSTANT_MENU) {
      if (m.key !== CONSTANT_KEY_TRANSLATE.home) {
        menu.push(m);
      }
    }
    this.RoleAdmin = menu;
    console.log("## admin: ", this.RoleAdmin);
    /*this.httpSv.getData('assets/data/role_admin.json').subscribe((data)=>{
       //console.log("MES DONNEES",data[1]);
       this.RoleAdmin = data;
    }, err =>{
     console.log(err);
    });*/
  }

  loadUserPartener() {
    this.httpSv.getData('assets/data/role_partener.json').subscribe((data) => {
      //console.log("MES DONNEES",data[1]);
      this.RolePartener = data;
      console.log("## partener: ", this.RolePartener);
      // récupèration des services item.
      this.serviceItemService.findAllActiveServicesAccounts().subscribe(res => {
        console.log('## success get all services composes : ', res);
        const data = res.body;
        for (let d of data) {
          if (d.parent.serviceId && d.parent.display === DisplayValue.ONE) {
            this.serviceItemComposeList.push(d);
          }
        }
      }, err => {
        console.log('## error get all services actifs: ', err);
      });
    }, err => {
      console.log(err);
    });
  }

  loadUserTrader() {
    this.httpSv.getData('assets/data/role_trader.json').subscribe((data) => {
      //console.log("MES DONNEES",data[1]);
      this.RoleTrader = data;
      console.log("## trader: ", this.RoleTrader);
      // récupèration des services item.
      this.serviceItemService.findAllActiveServicesAccounts().subscribe(res => {
        console.log('## success get all services composes: ', res);
        const data = res.body;
        for (let d of data) {
          if (d.parent.serviceId && d.parent.display === DisplayValue.ONE) {
            this.serviceItemComposeList.push(d);
          }
        }
      }, err => {
        console.log('## error get all services actifs: ', err);
      });
    }, err => {
      console.log(err);
    });
  }

  ngOnInit(): void {
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.ngOnInit();
        console.log("## account: ", account);
        this.getData('assets/data/table.json', 'tableData', 'setLoaded');
        if(this.authority === ROLE.admin) {
          this.loadUserAdmin();
        } else if(this.authority === ROLE.partener) {
          this.loadUserPartener();
        } else if(this.authority === ROLE.trader) {
          this.loadUserTrader();
        }
      });
  }

  ngOnDestroy() {
    console.log('## destroy home');
    this.formService.free(this.subs$);
  }

  open(s: ServiceItemCompose) {
    console.log("## try to open component account api key");
    console.log('## service: ', s);
    this.dialog.open(AccountApiKeyComponent, {
      minWidth: SIZE_W_MD_POPUP,
      minHeight: SIZE_H2_SM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: s,
    });
  }

  /**
   * Ouvre le component d'envoie code.
   * @param r informations du service parent.
   * @param fs liste des services fils du parent
   * @param serviceName
   */
  openTemplate(fs: ServiceItemFils[], r?: IRetraitCode, serviceName?: string) {
    console.log('## fils: ', fs);
    console.log('## parent: ', r.idServiceItem);
    let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    let idServiceItem;
    let serviceItemId;
    let f = fs.slice();
    // on vérifie si le parent n'a pas de fils.
    if (typeof f === 'undefined' || f.length <= 0) {
      idServiceItem = r.idServiceItem;
      serviceItemId = r.serviceItemId;
    } else {
      const f1 = f[0];
      idServiceItem = f1.serviceId;
      serviceItemId = f1.serviceItemId;
    }
    d.dataCode.idServiceItem = idServiceItem;
    d.dataCode.serviceItemId = serviceItemId;
    d.dataCode.serviceName = serviceName;
    d.dataCode.serviceItemFilsList = f;
    d.dataCode.serviceItemIdInter = serviceItemId;
    this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        others: r,
        cashDataModel: d
      }
    });
  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openMultiApiKey() {
    console.log('## open transaction bash');
    /*let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_TRANSACTION_BASH;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_TRANSACTION_BASH;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_TRANSACTION_BASH;*/

    let s = new ServiceItemCompose();
    s.parent = new ServiceItemDisplay();
    s.filsList = [];

    s.parent.serviceId = ID_SERVICE_ITEM_TRANSACTION_BASH;
    s.parent.serviceItemId = SERVICE_ITEM_ID_TRANSACTION_BASH;
    s.parent.name = SERVICE_ITEM_TRANSACTION_BASH_NAME;

    this.dialog.open(AccountApiKeyComponent, {
      minWidth: SIZE_W_MD_POPUP,
      minHeight: SIZE_H2_SM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: s,
    });

    /*this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        cashDataModel: d
      }
    });*/

  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openMulti() {
    console.log('## open envoi code');
    let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_TRANSACTION_BASH;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_TRANSACTION_BASH;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_TRANSACTION_BASH;
    this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        cashDataModel: d
      }
    });

  }

  /*  openMulti() {
      console.log('## open multi');
      this.dialog.open(TransactionMultiComponent, {
        minWidth: SIZE_MIN_POPUP,
        minHeight: SIZE_MEDIUM_POPUP,
        panelClass: CONTAINER_DIALOG_POPUP
      });
  /!*    this.dialog.open(TransactionMultiComponent, {
        height: '600px',
        width: '700px',
      });*!/
    }*/

  getCurrentUser() {
    this.http.get(`${BASE_URL}account`).subscribe((response) => {
      this.response = response;
      console.log('### Current  user ID', this.response.login);
      this.getCurrentUserID(this.response?.login);
      if (response['authorities'][1]) {
        this.authority = response['authorities'][1];
      } else {
        this.authority = response['authorities'][0];
      }
      console.log(response, this.authority);
    }, (err) => {
      console.log(err);
    });
  }

  getCurrentUserID(login) {
    this.http.get(`${BASE_URL}c-user/userLogin/${login}`).subscribe((val) => {
      this.CurrentUserID = val['id'];
    }, err => {
      console.log(err);
    });
  }
}