import { Injectable } from '@angular/core';
import { BASE_URL } from '../../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ICountry } from '../../../model/country.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { createRequestOption } from '../../../util/request-util';
import { User } from '../../../model/user.model';
import { VerificationCodeComponent } from '../cash-dynamic/verification-code/verification-code.component';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { CONTAINER_DIALOG_POPUP } from '../../../shared/constant/css-style/css-class.constant';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RetraitCodeValidDynamicComponent } from '../cash-dynamic/retrait-code-valid-dynamic/retrait-code-valid-dynamic.component';
import { DataFormDynamic } from './services/base-validation.service';
import { TransactionInfos } from '../../../model/transaction/transaction-infos.model';
import { TransactionInfosRetrait } from '../../../model/transaction/transaction-infos-retrait.model';
import { TransactionInfosTrCompte } from '../../../model/transaction/transaction-infos-tr-compte.model';
import { TransactionInfosDtom } from '../../../model/transaction/transaction-infos-dtom.model';
import { ServiceItemFils } from '../../../model/service-item.model';

export interface ICashModel {
  others: any,
  cashDataModel: CashDataModel,
  apiKey: string,
  numberAccount: string
  currency: string,
  currencyValueCode: string,
  theDialog?: any,
  numberAccountSelected?: string
}

/**
 * Contient les différentes informations
 * sur le service à utiliser.
 * Inter: pour international.
 * serviceItemId: pour national
 * - serviceItemIdInfos: le service item id
 * pour le service information sur le code.
 *
 * - dataServ: données à afficher dans le formulaire qui suit.
 */
export interface IRetraitCode {
  title?: string,
  code?: string | any,
  component?: any,
  serviceItemFilsList?: ServiceItemFils[]
  componentInfos?: any,
  idServiceItem?: number,
  serviceName?: string,
  serviceItemId?: number,
  serviceItemIdVerif?: number,
  idServiceItemVerif?: number,
  serviceItemIdInfos?: number,
  idServiceItemInfos?: number,
  serviceItemIdInter?: number,
  dataServ?: any,
  apiKey?: string
}

/**
 * va permettre de déterminer l'ouverture ou la fermeture
 * des différents popup cash.
 */
export interface CashPopupConstruct {
  key: string;
  value: boolean;
}


export class CashDataModel {

  public popupTab: CashPopupConstruct[] = [];
  resultRetraitCode: any;
  private _dialogRef: MatDialogRef<any>;
  private _transactionInfos: TransactionInfos;
  private _transactionInfosRetrait: TransactionInfosRetrait;
  private _transactionInfosTrCompte: TransactionInfosTrCompte;
  private _transactionInfosDtom: TransactionInfosDtom;

  constructor() {
    console.log('## cash service construct');
    this.dataCode = {};
  }

  private _dataCode: IRetraitCode;

  /* Données utilisées lors de la validation
  d'un formulaire dynamique */
  private _dataForm: DataFormDynamic;

  /**
   * Efface toutes les données.
   */
  clearAllData() {
    this.dataCode = {};
    this.transactionInfos = undefined;
    this.transactionInfosRetrait = undefined;
    this.transactionInfosTrCompte = undefined;
    this.transactionInfosDtom = undefined;
    this.dataForm.data = undefined;
    this.dataForm.reponseTransfert = undefined;
  }


  /**
   * Efface toutes les données sauf le code.
   */
  clearAllWithoutCode() {
    const code = this.dataCode ? this.dataCode.code : undefined;
    this.clearAllData();
    this.dataCode.code = code;
  }

  get transactionInfosDtom(): TransactionInfosDtom {
    return this._transactionInfosDtom;
  }

  set transactionInfosDtom(value: TransactionInfosDtom) {
    this._transactionInfosDtom = value;
  }

  get transactionInfosTrCompte(): TransactionInfosTrCompte {
    return this._transactionInfosTrCompte;
  }

  set transactionInfosTrCompte(value: TransactionInfosTrCompte) {
    this._transactionInfosTrCompte = value;
  }

  get transactionInfosRetrait(): TransactionInfosRetrait {
    return this._transactionInfosRetrait;
  }

  set transactionInfosRetrait(value: TransactionInfosRetrait) {
    this._transactionInfosRetrait = value;
  }

  get dataForm(): DataFormDynamic {
    return this._dataForm;
  }

  getDataForm(d: FormData, formkey: string, key: string) {
    const dv = this.dataForm.data.get(key);
    const v = dv ? dv : null;
    if(v) {
      d.set(formkey, v);
    }
  }

  set dataForm(value: DataFormDynamic) {
    this._dataForm = value;
  }

  get transactionInfos(): TransactionInfos {
    return this._transactionInfos;
  }

  set transactionInfos(value: TransactionInfos) {
    this._transactionInfos = value;
  }

  get dialogRef(): MatDialogRef<any> {
    return this._dialogRef;
  }

  set dialogRef(value: MatDialogRef<any>) {
    this._dialogRef = value;
  }

  get dataCode(): IRetraitCode {
    return this._dataCode;
  }

  set dataCode(value: IRetraitCode) {
    this._dataCode = value;
  }

  getDataCode(d: FormData, formkey: string, key: string) {
    const dv = this.dataCode[key];
    const v = dv ? dv : null;
    if(v) {
      d.set(formkey, v);
    }
  }


  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openRetraitCodeValidDynamic(dialog: MatDialog, component: any,
                              d: ICashModel) {
    console.log('## open retrait code all cash out');
    dialog.open(component, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: d
    });
  }

  getPopup() {
    return this.popupTab;
  }

  getCode() {
    return this.resultRetraitCode;
  }

  setCode(data) {
    this.resultRetraitCode = data;
  }

  clear() {
    this.popupTab = [];
  }

  setElement(key: string, value: boolean) {
    for(let p of this.popupTab) {
      if(p.key === key) {
        p.value = value;
        break;
      }
    }
    this.popupTab.push({
      key: key,
      value: value
    })
  }

  getElement(key: string): CashPopupConstruct {
    for(let p of this.popupTab) {
      if(p.key === key) {
        return p;
      }
    }
    return {
      key: '',
      value: undefined
    }
  }
}
