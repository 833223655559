import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { BasePageComponent } from '../../../pages/base-page';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IOption } from '../../../ui/interfaces/option';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../services/form.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { CountryService } from '../../../pages/entities/countries/countries.service';
import { CostsRuleService } from '../../../pages/entities/fees/costs-rule.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { takeUntil } from 'rxjs/operators';
import { BASE_URL, environment } from '../../../../environments/environment';
import {
  BEFORE_ICON_RESET,
  BEFORE_ICON_VALIDATION,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALID_CLASS,
  BTN_VALIDATION_CLASS,
  CANCEL_BG_COLOR,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS,
  CONTAINER_POPUP_CLASS,
  FORM_CLASS,
  ICON_COLOR_REQUEST_CSS, ICON_FONT_REQUEST_CLASS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET, REMOVABLE_ICON_REQUEST, RESULT_REQUEST_ERROR_CLASS, RESULT_REQUEST_SUCCESS_CLASS,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS,
  SUFFIX_ICON_FORM, TITLE_POPUP_CLASS, VALIDATION_BTN2_POPUP_CLASS, VALIDATION_BTN3_POPUP_CLASS,
  VIEW_ERROR,
  VIEW_VALIDATION,
  WARNING_COLOR_CSS,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../constant/css-style/css-class.constant';
import { Account, ROLE, ROLE_NAME } from '../../../model/account.model';
import { ColorService } from '../../services/color.service';
import { DynamicFormModel } from '../../../model/champs_formulaire/dynamic-form.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * Réprésente l'entête des différents formulaire.
 * Il utilise le MatDialogTitle de angular material.
 */
@Component({
  selector: 'app-header-form',
  templateUrl: './header-form.component.html',
  animations: [fadeIn]
})
export class HeaderFormComponent implements OnInit, OnDestroy {

  @Input('title') title: string;
  // indique si la transaction a échoué.
  @Input('transactionFailed') failed: boolean;
  // indique si la transaction a réussi.
  @Input('transactionSucceed') succeed: boolean;

  @Input('isDynamic') isDynamic = false;
  @Input('dynamicData') dynamicData: DynamicFormModel[] = [];

  // message lorsque la transaction a réussi.
  @Input('messageSucceed') msgSucceed;
  // json key pour un message de succès.
  @Input('keyTranslateMsgSucceed') keyTranslateMsgSucceed = 'actionSucceed';

  // message lorsque la transaction a échoué.
  @Input('messageFailed') msgFailed;
  // json key pour message d'échec.
  @Input('keyTranslateMsgFailed') keyTranslateMsgFailed = 'actionFailed';


  /* @Input('trMessageFailed') transactionFailedMessage = 'Sorry, transaction failed!';
   @Input('trMessageSucceed') transactionSucceedMessage = 'successful transaction';
  */
  authority: any;
  private subs$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private colorService: ColorService,
    private formService: FormService,
    private accountService: AccountService) {
    console.log('## construct header form')
    this.msgSucceed = this.translateService.instant(this.keyTranslateMsgSucceed)
    this.msgFailed = this.translateService.instant(this.keyTranslateMsgFailed)
  }

  ngOnInit(): void {
    console.log("## init header form");
    this.msgSucceed = this.translateService.instant(this.keyTranslateMsgSucceed)
    this.msgFailed = this.translateService.instant(this.keyTranslateMsgFailed)
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe((res: Account) => {
        try{
          console.log('## get account: ', res);
          try {
            //this.authority = response['authorities'][1];
            if (res['authorities'][1]) {
              this.authority = res['authorities'][1];
            } else {
              this.authority = res['authorities'][0];
            }
          }catch (e) {
            console.log('## error get authorities from account: ', e.message);
          }
        }catch (e) {
          console.log('## error identity:', e.message);
        }
      }, (err) => {
        console.log(err);
      });
  }

  ngOnDestroy(): void {
    this.formService.free(this.subs$);
  }

  getHeaderFormBg() {
    return this.colorService.getHeaderFormBg();
  }

  getTitleHeaderFormColor() {
    return this.colorService.getTitleHeaderFormColor();
  }

  getRole() {
    let role = 'none';
    if (this.canPartenerAccess()) {
      role = ROLE_NAME.partener;
    } else if (this.canAdminAccess()) {
      role = ROLE_NAME.admin;
    } else if (this.canTraderAccess()) {
      role = ROLE_NAME.trader;
    }
    return role;
  }

  canPartenerAccess() {
    return this.authority === ROLE.partener;
  }

  canAdminAccess() {
    return this.authority === ROLE.admin;
  }

  canTraderAccess() {
    return this.authority === ROLE.trader;
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  isCancelBtnOutline(): boolean {
    return OUTLINE_CANCEL;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getContainerBtnClass() {
    return CONTAINER_BTN_POPUP_CLASS;
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  getResultRequestTextSuccess(): string {
    return RESULT_REQUEST_TEXT_SUCCESS_CLASS;
  }

  getWarningColor(): string {
    return WARNING_COLOR_CSS;
  }

  getInside2xContainerClass(): string {
    return CONTAINER_INSIDE2X_POPUP_CLASS;
  }

  getInsideContainerClass(): string {
    return CONTAINER_INSIDE_POPUP_CLASS;
  }
  getContainerClass() : string {
    return CONTAINER_POPUP_CLASS;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getIconColorRequestClass(): string {
    return ICON_COLOR_REQUEST_CSS;
  }

  isRemovable(): boolean {
    return REMOVABLE_ICON_REQUEST;
  }

  getTitleClass(): string {
    return TITLE_POPUP_CLASS;
  }

  getIconRequestClass(): string {
    return ICON_FONT_REQUEST_CLASS;
  }

  getResultRequestErrorClass() {
    return RESULT_REQUEST_ERROR_CLASS;
  }

  getResultRequestSuccessClass() {
    return RESULT_REQUEST_SUCCESS_CLASS;
  }
}
