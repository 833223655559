import { Injectable } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { BASE_URL_MANAGEMENT } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TypeInputFormService extends ApiService {
  public resourceUrl = BASE_URL_MANAGEMENT + '/type-input-forms';
  public list_url = BASE_URL_MANAGEMENT + '/type-input-forms/all';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAllObjet(req?: any) {
    super.getAllObjet(this.list_url, req);
  }
}
