import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { ICountry } from '../../../../model/country.model';
import { Subject } from 'rxjs';
import { CountryService } from '../../countries/countries.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../../acc-ress-type/acc-ress-type-column-name';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { MoniesService } from '../monies.service';
import { IMoney } from '../../../../model/money.model';
import { AccountService } from '../../../../services/ServiceEntity/account.service';

/**
 * Permet de gérer la suppression d'une monnaie.
 */
@Component({
  selector: 'app-monies-delete',
  templateUrl: './monies-delete.component.html',
  animations: [fadeIn]
})
export class MoneyDeleteComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  moneySelected: IMoney;
  private subs$ = new Subject<void>();
  isDeleting = false;
  successDelete = false;
  serverHasError = false;
  hasDelete = false;

  constructor(
    private accountService: AccountService,
    private moneyService: MoniesService,
    private dialogRef: MatDialogRef<MoneyDeleteComponent>,
    private formService: FormService,
    private columnName: AccRessTypeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct money delete');
  }

  ngOnInit(): void {
    console.log('## init money delete.');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.moneyService.getMoneySelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: ICountry) => {
          console.log('## get money selected');
          try {
            console.log('## data: ' + JSON.stringify(res));
            this.moneySelected = res;
          } catch (e) {
            console.log('## error get money delete: ' + e.message);
          }
          super.setLoaded();
        });
      })
  }

  ngOnDestroy() {
    console.log('## destroy money delete');
    this.formService.free(this.subs$);
    this.moneyService.setMoneySelectObs(undefined);
  }

  closeModal() {
    console.log('## close delete money');
    this.dialogRef.close();
  }

  delete() {
    console.log('## deleting money');
    this.isDeleting = true;
    this.serverHasError = false;
    this.successDelete = false;
    this.moneyService.delete(this.moneySelected.id).subscribe(
      response => {
        console.log('## success delete money');
        this.serverHasError = false;
        this.successDelete = true;
        this.isDeleting = false;
        this.hasDelete = true;
        this.responseFromServer();
        this.moneyService.getAllMoney();
      }, error => {
        console.log('## error delete money');
        this.serverHasError = true;
        this.successDelete = false;
        this.isDeleting = false;
        this.responseFromServer();
      });
  }

  private responseFromServer() {
    setTimeout(() => {
      this.isDeleting = false;
    }, 300);
  }
}
