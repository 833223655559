// Service_item_id infos dépôt orange money.
export const SERVICE_ITEM_ID_INFOS_DEPOT_ORANGE_MONEY = 117;

// Service_item_id infos transaction transfert compte.
export const SERVICE_ITEM_ID_INFOS_TRANSACTION_TRANSFER_COMPTE = 116;

// Service_item_id infos transaction retrait code.
export const SERVICE_ITEM_ID_INFOS_TRANSACTION_RETRAIT_CODE = 115;

// Service_item_id infos transaction transfer code.
export const SERVICE_ITEM_ID_INFOS_TRANSACTION_TR_CODE = 114;

// RETRAIT PARTIEL ORANGE MONEY.
// VERIFICATION
export const ID_SERVICE_ITEM_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY = 25;
export const SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY = 110;
// INFORMATION
export const ID_SERVICE_ITEM_INFORMATION_CODE_PARTIEL_ORANGE_MONEY = 24;
export const SERVICE_ITEM_ID_INFORMATION_CODE_PARTIEL_ORANGE_MONEY = 109;
// RETRAIT
export const SERVICE_ITEM_ID_RETRAIT_CODE_ORANGE_MONEY = 113;
export const ID_SERVICE_ITEM_RETRAIT_CODE_ORANGE_MONEY = 28;

// RETRAIT COMPLET ORANGE MONEY.
// VERIFICATION
export const ID_SERVICE_ITEM_VERIFICATION_CODE_COMPLET_ORANGE_MONEY = 27;
export const SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET_ORANGE_MONEY = 112;
// INFORMATION
export const ID_SERVICE_ITEM_INFORMATION_CODE_COMPLET_ORANGE_MONEY = 26;
export const SERVICE_ITEM_ID_INFORMATION_CODE_COMPLET_ORANGE_MONEY = 111;
// RETRAIT
export const SERVICE_ITEM_ID_RETRAIT_CODE_COMPLET_ORANGE_MONEY = 118;
export const ID_SERVICE_ITEM_RETRAIT_CODE_COMPLET_ORANGE_MONEY = 33;

// DEPOT ORANGE MONEY.
export const ID_SERVICE_ITEM_DEPOT_ORANGE_MONEY = 4;
export const SERVICE_ITEM_ID_DEPOT_ORANGE_MONEY = 5;

// TRANSACTION BASH.
export const ID_SERVICE_ITEM_TRANSACTION_BASH = 19;
export const SERVICE_ITEM_ID_TRANSACTION_BASH = 104;
export const SERVICE_ITEM_TRANSACTION_BASH_NAME = "Transaction Bash";


// RETRAIT CODE PARTIEL CASH.
export const ID_SERVICE_ITEM_VERIFICATION_CODE_PARTIEL = 20;
export const SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL = 105;
export const SERVICE_ITEM_ID_VERIFICATION_CODE_INTER_PARTIEL = 105;

// RETRAIT INFORMATION CODE PARTIEL.
export const ID_SERVICE_ITEM_INFORMATION_CODE_PARTIEL = 21;
export const SERVICE_ITEM_ID_INFORMATION_CODE_PARTIEL = 106;
export const SERVICE_ITEM_ID_RETRAIT_CODE_CASH = 101;
export const ID_SERVICE_ITEM_RETRAIT_CODE_CASH = 11;

// RETRAIT CODE COMPLET (VERIFICATION, INFORMATION).
// INFORMATION
export const ID_SERVICE_ITEM_INFORMATION_CODE_COMPLET = 23;
export const SERVICE_ITEM_ID_INFORMATION_CODE_COMPLET = 108;
// VERIFICATION
export const ID_SERVICE_ITEM_VERIFICATION_CODE_COMPLET = 22;
export const SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET = 107;
export const ID_SERVICE_ITEM_RETRAIT_ALL_CODE_CASH = 18;

// RETRAIT TRANSFERT CODE CASH.
export const ID_SERVICE_ITEM_TRANSFER_CODE = 14;
export const SERVICE_ITEM_ID_TRANSFER_CODE = 102;
export const SERVICE_ITEM_ID_TRANSFER_CODE_INTER = 103;

// RETRAIT TRANSFER COMPTE CASH.
export const ID_SERVICE_ITEM_TRANSFER_COMPTE = 16;
export const SERVICE_ITEM_ID_TRANSFER_COMPTE = 202;
export const SERVICE_ITEM_ID_TRANSFER_COMPTE_INTER = 203;

// TRANSFER COMPTE CASH SELF.
export const ID_SERVICE_ITEM_TRANSFER_COMPTE_SELF = 34;
export const SERVICE_ITEM_TRANSFER_COMPTE_SELF = 204;
