import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GuardService } from './guard.service';
import { Observable, of } from 'rxjs';
import { AccountService } from '../../ServiceEntity/account.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Account } from '../../../model/account.model';
import { ROUTE_HOME_FULL } from '../../../layout/components/home.route';

/**
 * Permet de vérifier si un utilisateur est déconnecté
 * à fin de pouvoir décider si on lui donne accès à la route.
 */
@Injectable({
  providedIn: 'root'
})
export class GuardLoginService implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {

  }

  /**
   * Si l'utilisateur est n'est pas authentifié, alors on lui donne
   * accès aux routes publiques.
   * @param route
   * @param state
   * @Return true si l'utilisateur est n'est pas authentifié,
   * false dans le cas contraire et il sera redirigé
   * vers la page d'accueil.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean
    | UrlTree {
      return this.accountService.fetch().pipe(
        catchError((err) => {
          console.log('## error get account');
          return of(false);
        }),
        map(
        (res) => {
          console.log('## got value');
          if(res) {
            console.log('## user connected');
            this.router.navigate([ROUTE_HOME_FULL.accueil]);
            return false;
          }
          console.log('## user not connected');
          return true;
        }
      ));
  }
}
