import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../../animations/form-error';
import { BasePageComponent } from '../../../../../base-page';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { IOption } from '../../../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChampsFormulaireColumnName, IChampsFormulaireColumnName } from '../../champs-formulaire-column-name';
import { IServiceItem } from '../../../../../../model/service-item.model';
import { ServiceItemService } from '../../../../service-items/service-item.service';
import { ChampsFormulaireService } from '../../champs-formulaire.service';
import { BaseService } from '../../../../base/base.service';
import { AccountService } from '../../../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../../../shared/services/form.service';
import { TCModalService } from '../../../../../../ui/services/modal/modal.service';
import { CountryService } from '../../../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../../interfaces/app-state';
import { HttpService } from '../../../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ChampsFormulaire, TypeForm } from '../../../../../../model/champs_formulaire/champs-formulaire.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../../../../shared/constant/request.contant';
import { IValidatorAffectedColumnName, ValidatorAffectedColumnName } from '../../../validator_affected/validator-affected-column-name';
import { ValidatorAffectedService } from '../../../validator_affected/validator-affected.service';
import { FormValidator } from '../../../../../../model/champs_formulaire/form-validator.model';
import { ValidatorService } from '../../../validators/validator.service';
import { Validators as ValidatorsModel } from '../../../../../../model/champs_formulaire/validators.model';
import { Route, Router } from '@angular/router';
import { ConstantValidatorsAffected } from '../../../validator_affected/validator-affected.url';
import { ConstantChampsFormulaires } from '../../champs-formulaire.url';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-formulaire-add-validator',
  templateUrl: './formulaire-add-validator.component.html',
  animations: [fadeIn]
})
export class FormulaireAddValidatorComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  formSelected: ChampsFormulaire;
  theDialogRef: MatDialogRef<FormulaireAddValidatorComponent>;
  private subs$ = new Subject<void>();
  typeForms: IOption[] = [];
  editForm: UntypedFormGroup;
  column: IValidatorAffectedColumnName;

  validatorsList: ValidatorsModel[] = [];
  filteredOptions: Observable<ValidatorsModel[]>;

  constructor(
    private router: Router,
    private validatorService: ValidatorService,
    private serviceItemService: ServiceItemService,
    private formulaireService: ChampsFormulaireService,
    private validatorAffectedService: ValidatorAffectedService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<FormulaireAddValidatorComponent>,
    private columnName: ValidatorAffectedColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct formulaire add validator');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init formulaire add validator');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.formulaireService.getObjetSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: ChampsFormulaire) => {
          console.log('## get formulaire selected to liste');
          this.getValidatorsList(); // after 500ms.
          try {
            console.log('## data: ', res);
            this.formSelected = res;
            this.editForm.patchValue({
              [this.column.champsFormulaire]: res.label
            });
          } catch (e) {
            console.log('## error get country selected: ' + e.message);
          }
        });
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  getValidatorsList() {
    setTimeout(() => {
      this.validatorService.query(this.validatorService.list_url)
        .pipe(map((response: HttpResponse<ValidatorsModel[]>) => response.body))
        .subscribe((res) => {
          const rs = res ? res : [];
          console.log('## data v: ', rs);
          this.validatorsList = rs;
          this.filteredOptions = this.editForm.get(this.column.validator).valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        });
    }, TIME_MIN_REQUEST);
  }

  private _filter(value: string): ValidatorsModel[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.validatorsList.filter(option => option.type.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  displayFnF(subject: ChampsFormulaire) {
    return subject ? subject.label : '';
  }

  displayFnServiceItem(subject: ValidatorsModel) {
    return subject ? subject.type : '';
  }

  initForm(fb: UntypedFormBuilder, column: IValidatorAffectedColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.errorMessage]: ['', [Validators.required]],
        [column.validatorValue]: [''],
        [column.validator]: ['', [Validators.required]],
        [column.champsFormulaire]: ['', [Validators.required]]
      });
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save validator');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.validatorAffectedService.create(c,
      this.validatorAffectedService.resourceUrl)
      .subscribe(res => {
          console.log('## success create validator');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.router.navigate(['/' + ConstantValidatorsAffected.urlFull.FEES.LIST],
            { queryParams: { [ConstantChampsFormulaires.url.FEES.FEES_]: this.formSelected.id } });
        },
        err => {
          console.log('## error create validator: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): FormValidator {
    return {
      ...new FormValidator(),
      errorMessage: this.editForm.get([this.column.errorMessage]).value,
      validatorValue: this.editForm.get([this.column.validatorValue]).value,
      validatorId: this.editForm.get([this.column.validator]).value?.id,
      champsFormulaireId: this.formSelected.id
    };
  }

  closeModal() {
    console.log('## close formulaire add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}
