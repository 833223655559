import { IServiceItem } from '../service-item.model';
import { Validators } from './validators.model';
import { ChampsFormulaire } from './champs-formulaire.model';

export class FormValidator {
  constructor(
    public id?: number,
    public errorMessage?: string,
    public validatorValue?: string,
    public validator?: Validators,
    public validatorType?: string,
    public validatorId?: number,
    public champsFormulaire?: ChampsFormulaire,
    public champsFormulaireId?: number,
    public serviceItemName?: string,
    public labelFormulaire?: string,
    public formulaireId?: number,
  ) {}
}