import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from 'src/app/interfaces/app-state';
import { IApiKeyDTO } from 'src/app/model/api-key.model';
import { CUser, ICUser } from 'src/app/model/c-user.model';
import { HttpService } from 'src/app/services/http/http.service';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { CUserApiKeyService } from 'src/app/services/ServiceEntity/c-user-api-key.service';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { BASE_URL } from 'src/environments/environment';
import { BasePageComponent } from '../../base-page';
import { ColorService } from '../../../shared/services/color.service';
import {
  BEFORE_ICON_RESET,
  BEFORE_ICON_VALIDATION,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALIDATION_CLASS,
  CANCEL_BG_COLOR,
  CONTAINER_DIALOG_POPUP,
  OUTLINE_RESET
} from '../../../shared/constant/css-style/css-class.constant';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { ApikeyTestComponent } from './apikey-test/apikey-test.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CONSTANT_ICON } from 'src/app/shared/constant/icon.constant';
import { UntypedFormControl } from '@angular/forms';
import { OpenService } from '../cash/open.service';
import { Navigation, Router } from '@angular/router';
import { IMobileAccount, MobileAccount } from '../../../model/mobile-account.model';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { TIME_MIN_X1_REQUEST, TIME_MIN_X2_REQUEST, TIME_MIN_X_REQUEST } from '../../../shared/constant/request.contant';
import { AccountUserService } from '../../../services/ServiceEntity/account-user.service';
import { Observable, Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IVersionExchangeRate } from '../../../model/version-exchange-rate.model';
import { BaseService } from '../base/base.service';
import { DomainAddComponent } from '../domain/add/domain-add.component';
import { CUserAddKeyComponent } from '../c-user/add/c-user-add-key/c-user-add-key.component';
import { ApiKeyAddComponent } from './api-key-add/api-key-add-component';
import { ChampsFormulaire } from '../../../model/champs_formulaire/champs-formulaire.model';
import { ApiKeyService } from './api-key.service';
import { FormService } from '../../../shared/services/form.service';
import { Domain } from '../../../model/domain.model';
import { DomainAffectStatusComponent } from '../domain/affect_status/domain-affect-status.component';
import { STATUS_BD_CONSTANT } from '../../../model/status.model';
import { ApiKeyUpdateComponent } from './update/api-key-update.component';
import { ApiKeyListIpAdressComponent } from './list-ip-adress/api-key-list-ip-adress.component';
import { ApiKeyDeleteComponent } from './delete/api-key-delete.component';
import { ApiKeyServiceComponent } from './service-item/api-key-service.component';
import { TransactionApikeyComponent } from './transaction-api-key/transaction-apikey.component';


@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.css']
})
export class ApiKeyComponent extends BasePageComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  //The Current User var
  CurentUser: any;
  CurrentUserAuth: any;
  thisUserId: number;
  isActive: string;

  //liste apiKey
  listApiKey: IApiKeyDTO[] = [];
  listApiKeySearch: IApiKeyDTO[] = [];

  searchValue: string;
  apiValue: string;
  keyValue: UntypedFormControl;
  @ViewChild('inputKey') inputKey: ElementRef;
  @ViewChild('buttonKey') buttonKey: ElementRef;

  show: string;
  notShow: string;

  mobileAccountSelect: MobileAccount;

  mobileAccountList: MobileAccount[];

  filteredOptions: Observable<IMobileAccount[]>;

  /* Contrôleur de l'input (auto complete) des mobiles account */
  versionControl = new UntypedFormControl();

  subs$ = new Subject<void>();
  @ViewChild('basicTable') tabRef: any;

  constructor(
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private apiKeyService: ApiKeyService,
    public baseService: BaseService,
    protected mAccountService: AccountUserService,
    private router: Router,
    private openService: OpenService,
    store: Store<IAppState>,
    httpSv: HttpService,
    private dialog: MatDialog,
    private colorService: ColorService,
    private cUserApiKeyService: CUserApiKeyService,
    protected accountService: AccountService,
    protected cUserService: CUserService,
    private translate: TranslateService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      key: 'apiKey.title',
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: 'home'
        },
        {
          title: '',
          key: 'apiKey.title'
        }
      ]
    };
    if (this.pageData.key === 'apiKey.title') {
      translate.get('apiKey.title').subscribe(res => this.pageData.title = res);
    }
    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'apiKey.title') {
        translate.get('apiKey.title').subscribe(res => value.title = res);
      }

    });
    let nav: Navigation = this.router.getCurrentNavigation();
    if (nav && nav.extras && nav.extras.state) {
      let st = nav.extras.state;
      this.mobileAccountSelect = st.mobileAccount ? st.mobileAccount : this.mobileAccountSelect;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentUser();
    this.getListApiKeys();
    this.getListApiKeysBySubject();
    this.show = this.getIcon().show;
    this.getData('assets/data/table.json', 'tableData', 'setLoaded');
  }

  ngOnDestroy() {
    console.log('## on destroy domain list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

  getListApiKeys() {
    this.apiKeyService.findAllApiKeyAccounts()
      .subscribe(res => {
        console.log('## success get list api keys: ', res);
        this.listApiKey = (res && res.body) ? res.body : [];
        this.listApiKeySearch = this.listApiKey;
        this.mobileAccountSelect = null;
      }, error => {
        console.log('## error get list api keys: ', error);
      });
  }

  isActivated(data: IApiKeyDTO): boolean {
    return data.statusId === STATUS_BD_CONSTANT.actived;
  }

  delete(d: IApiKeyDTO) {
    console.log('## delete api key:', d);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(ApiKeyDeleteComponent, {
      data: {
        apiKey: d,
        idAccount: this.mobileAccountSelect ? this.mobileAccountSelect.id
          : d.idAccount, currentAccount: this.CurentUser,
        thisUserId: this.CurentUser.id
      }, height: '600px', width: '800px'
    });
  }

  affectStatus(d: IApiKeyDTO) {
    console.log('## open affect status: ', d);
    let a = {
      'apiKeyId': d.id,
      'statusId': (d.statusId === STATUS_BD_CONSTANT.actived) ? STATUS_BD_CONSTANT.deactived
        : STATUS_BD_CONSTANT.actived
    }
    this.apiKeyService.updateStatus(a).subscribe(res => {
      console.log('## success change status api key');
      setTimeout(() => {
        this.apiKeyService.getAllObjetById();
      }, TIME_MIN_X1_REQUEST);

    }, (res: HttpErrorResponse) => {
      console.log('## error get list status while adding: ' + res.message);
    })
  }

  /**
   * Retourne la liste des des api key
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IApiKeyDTO[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  getListApiKeysBySubject() {
    // récupération de la liste des api keys.
    this.apiKeyService.getObjetObs().pipe(takeUntil(this.subs$))
      .subscribe((res: IApiKeyDTO[]) => {
        console.log('## get list api key in list by subject: ', res);
        this.listApiKey = (res) ? res : [];
        this.listApiKeySearch = this.listApiKey;
      });
  }

  listTransaction(d: IApiKeyDTO) {
    console.log('## list transactions: ', d);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(TransactionApikeyComponent, {
      data: {
        apiKey: d,
        idAccount: this.mobileAccountSelect ? this.mobileAccountSelect.id
          : d.idAccount, currentAccount: this.CurentUser,
        numberAccount: this.mobileAccountSelect ? this.mobileAccountSelect.numberAccount
          : d.numberAccount,
        currency: this.mobileAccountSelect ? this.mobileAccountSelect.preferenceCurrency
          : d.codeMoney
      }, height: '700px', width: '1200px'
    });
  }

  manageService(d: IApiKeyDTO) {
    console.log('## manage service');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(ApiKeyServiceComponent, {
      data: {
        apiKey: d,
        idAccount: this.mobileAccountSelect ? this.mobileAccountSelect.id
          : d.idAccount, currentAccount: this.CurentUser,
        numberAccount: this.mobileAccountSelect ? this.mobileAccountSelect.numberAccount
          : d.numberAccount,
        currency: this.mobileAccountSelect ? this.mobileAccountSelect.preferenceCurrency
          : d.codeMoney
      }, height: '700px', width: '800px'
    });
  }

  listerIpAdress(d: IApiKeyDTO) {
    console.log('open popup list ipAdress');
    console.log('## api key:', d);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(ApiKeyListIpAdressComponent, {
      data: {
        apiKey: d,
        idAccount: this.mobileAccountSelect ? this.mobileAccountSelect.id
          : d.idAccount, currentAccount: this.CurentUser,
        thisUserId: this.CurentUser.id
      }, height: '700px', width: '800px'
    });
  }

  displayFnVersion(subject: IMobileAccount) {
    if(subject && subject.id) {
      return subject.label + ' - ' + subject.preferenceCurrency
    } else if(subject && subject.label) {
      return subject.label;
    }
    return '';
  }

  update(d: IApiKeyDTO) {
    console.log('## open api key liste');
    console.log('## api key:', d);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(ApiKeyUpdateComponent, {
      data: {
        apiKey: d,
        idAccount: this.mobileAccountSelect ? this.mobileAccountSelect.id
          : d.idAccount, currentAccount: this.CurentUser,
        thisUserId: this.CurentUser.id
      }, height: '600px', width: '800px'
    });
    // this.baseService.openSm(this.dialog, CUserAddKeyComponent);
  }

  add() {
    console.log('## open api key add');
    // console.log('dataaaaaaaa', data);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(ApiKeyAddComponent, {
      data: {
        idAccount: null, currentAccount: this.CurentUser,
        thisUserId: this.CurentUser.id
      }, height: '600px', width: '800px'
    });
   // this.baseService.openSm(this.dialog, CUserAddKeyComponent);
  }

  private _filter(value: any): IMobileAccount[] {
    let filteredList;
    try {
      console.log('## value version: ', value);
      const filterValue = value.toLowerCase();
      filteredList = this.mobileAccountList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter mobile account: ' + e.message);
    }
    return filteredList;
  }

  /**
   * Récupère la liste des mobiles account et
   * les initialise dans un auto complete.
   */
  loadAllMobileAccounts(login: string) {
    console.log('## login: ', login);
    setTimeout(() => {
      this.mAccountService
        .findAllAccountsByCUserLogin(login)
        .pipe(
          filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
          map((res: HttpResponse<IMobileAccount[]>) => res.body)
        )
        .subscribe((res: IMobileAccount[]) => {
          this.mobileAccountList = res;
          let m = new MobileAccount();
          m.label = 'TOUT';
          this.mobileAccountList.splice(0, 0, m);
          console.log('success get all mobile account:', this.mobileAccountList);
          this.filteredOptions = this.versionControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        });
    }, TIME_MIN_X2_REQUEST);
  }

  /**
   * Récupère la liste des mobiles account et
   * les initialise dans un auto complete.
   */
  loadAllMobileAccountsId(cUserId: number) {
    console.log('## cUserId: ', cUserId);
    setTimeout(() => {
      this.mAccountService
        .findAllAccountsByCUserId(cUserId)
        .pipe(
          filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
          map((res: HttpResponse<IMobileAccount[]>) => res.body)
        )
        .subscribe((res: IMobileAccount[]) => {
          this.mobileAccountList = res;
          console.log('success get all mobile account:', this.mobileAccountList);
          this.filteredOptions = this.versionControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        });
    }, TIME_MIN_X2_REQUEST);
  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openTemplateApikey(apikeyValue: string) {
    console.log('## open test apikey');
    // this.openService.openEnvoieCodeService({apikeyValue: apikeyValue});
    this.dialog.open(ApikeyTestComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        apikeyValue: apikeyValue
      }
    });
  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openTestApikey(apikeyValue: string) {
    console.log('## open test apikey');
    // this.openService.openEnvoieCodeService({apikeyValue: apikeyValue});
    this.dialog.open(ApikeyTestComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        apikeyValue: apikeyValue
      }
    });
  }

  openValueApikey(inputKey: any, buttonKey: any) {
    console.log('button ##', buttonKey);
    if (inputKey.type === 'password') {
      inputKey.type = 'text';
      buttonKey.beforeIcon = this.getIcon().notShow;
    } else {
      inputKey.type = 'password';
      buttonKey.beforeIcon = this.getIcon().show;
    }
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getIconValidation(): string {
    return BEFORE_ICON_VALIDATION;
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  getStatusBackground(statusId: number) {
    return this.colorService.getStatusBg(statusId);
  }

  getAmountColBg() {
    return this.colorService.getAmountColBg();
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.listApiKeySearch].map(mapFn);
    const result = this.listApiKeySearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.listApiKey = [];
    result.forEach((c) => {
      this.listApiKey.push(c);
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  loadTable(role: any) {
    if (role == 'ROLE_ADMIN') {
      this.getDataRest(`${BASE_URL}c-users`, 'tableData', 'initTable');
    } else if (role == 'ROLE_PARTNER') {
      this.getDataRest(`${BASE_URL}c-users/list`, 'tableData', 'initTable');
    }
  }


  /**
   * Récupère toutes les api keys associés à un account.
   * @param m
   */
  getAllApiKeysSelect(e: MatAutocompleteSelectedEvent) {
    const m = e.option.value as IMobileAccount;
    console.log('## select mobile account: ', m);
    this.getAllApiKeys(m);
  }

  /**
   * Récupère toutes les api keys associés à un account.
   * @param m
   */
  getAllApiKeys(m: IMobileAccount) {
    if(m.id) {
      setTimeout(() => {
        this.cUserApiKeyService.findAllByAccountId(m.id).subscribe(res => {
          this.mobileAccountSelect = m;
          this.listApiKey = (res.body) ? res.body : [];
          this.listApiKeySearch = this.listApiKey;
          console.log('## api key length: ', this.listApiKey.length);
          console.log('## liste api key found: ', this.listApiKey);
          console.log('responseeeeeeeeeee', this.listApiKey);
        });
      }, TIME_MIN_X_REQUEST);
    } else {
      this.getListApiKeys();
    }
  }

  getCurrentUser() {
    this.accountService.identity().subscribe(account => {
      this.CurentUser = account;
      if (account) {
        console.log('## my account: ', account);
        if (this.mobileAccountSelect) {
          this.getAllApiKeys(this.mobileAccountSelect);
          super.setLoaded();
        }
        console.log('## currentUser: ', this.CurentUser);
        this.loadAllMobileAccounts(this.CurentUser.login);
      }
      if (this.CurentUser['authorities'][1]) {
        this.CurrentUserAuth = this.CurentUser['authorities'][1];
        this.loadTable(this.CurrentUserAuth);
      } else {
        this.CurrentUserAuth = this.CurentUser['authorities'][0];
        this.loadTable(this.CurrentUserAuth);
      }
    }, (err) => {
      console.log(err);
    });
  }

}
