import { Routes } from '@angular/router';
import { ConstantAccRessType } from '../acc-ress-type/acc-ress-type.url';
import { VerticalLayoutComponent } from '../../../layout/vertical';
import { AccountsRessourceTypeComponent } from '../acc-ress-type';
import { ConstantCountries } from './countries.url';
import { CountriesComponent } from './countries.component';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';

/**
 * Ensemble des routes pour les countries.
 */
export const COUNTRIES_ROUTE: Routes = [
  {
    path: ConstantCountries.url.COUNTRIES.COUNTRIES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantCountries.url.COUNTRIES.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantCountries.url.COUNTRIES.LIST,
        component: CountriesComponent
      }
    ]
  }
];
