import { Injectable } from '@angular/core';
import { BodyFormat, RequestType } from '../../../model/service-item.model';

export interface ICostsRuleColumnName {
  id: string,
  amountA: string,
  amountB: string,
  benefit: string,
  fees: string,
  montant: string,
  costsId: string,
  moneyId: string,
  serviceItemId: string,
  countryAId: string,
  countryBId: string,
}

@Injectable({ providedIn: 'root' })
export class CostsRuleColumnName {

  getColumnName(): ICostsRuleColumnName {
    return {
      id: 'id',
      amountA: 'amountA',
      amountB: 'amountB',
      benefit: 'benefit',
      fees: 'fees',
      montant: 'montant',
      costsId: 'costsId',
      moneyId: 'moneyId',
      serviceItemId: 'serviceItemId',
      countryAId: 'countryAId',
      countryBId: 'countryBId',
    };
  }
}
