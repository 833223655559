import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les {@link StatusModel}.
 */
export namespace StatusLink {
  export const data = {
    title: 'Status',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un Status'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des Status'
      }
    }

  }
  export const url = {
    FEES: {
      FEES_: 'status',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    FEES: {
      ADD: StatusLink.url.FEES.FEES_
        .concat(Url.SLASH_ROUTE).concat(StatusLink.url.FEES.ADD),
      LIST: StatusLink.url.FEES.FEES_
        .concat(Url.SLASH_ROUTE).concat(StatusLink.url.FEES.LIST),
    }
  };
}
