export class TransferCompteConfirmDTO {
  constructor(
    public correlationId?: string,
    public numberAccount?: string,
    public response?: boolean,
  ) {}

}

export class ITransferCompteConfirmDTO {
  constructor(
    correlationId?: string,
    numberAccount?: string,
    response?: boolean,
  ) {}

}

export class AccountConfirmDTO {
  constructor(
    public numberAccount?: string,
    public codeMoney?: string,
    public preferenceCurrency?: number,
    public response?: boolean,
    public sameMoney?: boolean
  ) {}
}

export interface INotificationDTO {
  correlationId?: string,
  phoneNumberSender?: string,
  sentAmount?: number,
  receivedAmount?: number,
  transactionSenderId?: string,
  currency?: string,
  statusId?: number,
  statusLabel?: string,
  transactionTypeLabel?: string,
  lastModifiedDate?: string;
}

export class NotificationDTO implements INotificationDTO {
  constructor(
    public correlationId?: string,
    public phoneNumberSender?: string,
    public sentAmount?: number,
    public receivedAmount?: number,
    public transactionSenderId?: string,
    public currency?: string,
    public statusId?: number,
    public statusLabel?: string,
    public transactionTypeLabel?: string,
    public lastModifiedDate?: string
  ) {
  }
}