import { Injectable } from '@angular/core';

export interface ICUserAddKeyColumnName {
  label: string;
  idAccount: string;
  ipAuthorized: string;
  restrictionIp: string
  
}

@Injectable({ providedIn: 'root' })
export class CUserAddKeyColumnName {
  getColumnName(): ICUserAddKeyColumnName {
    return {
      label: 'label',
      idAccount: 'idAccount',
      ipAuthorized: 'ipAuthorized',
      restrictionIp: 'restrictionIp'

   
    };
  }
}
