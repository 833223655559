import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { ChampsFormulaireListComponent } from '../champs_formulaires/champs_formulaires/champs-formulaire-list.component';
import { SourceFormAddComponent } from '../champs_formulaires/champs_formulaires/add/source-form/source-form-add.component';
import { FormulaireAddValidatorComponent } from '../champs_formulaires/champs_formulaires/add/validator/formulaire-add-validator.component';
import { ChampsFormulaireAddComponent } from '../champs_formulaires/champs_formulaires/add/champs-formulaire-add.component';
import { ChampsFormulaireUpdateComponent } from '../champs_formulaires/champs_formulaires/update/champs-formulaire-update.component';
import { ChampsFormulaireDeleteComponent } from '../champs_formulaires/champs_formulaires/delete/champs-formulaire-delete.component';
import { NotificationComponent } from './notification.component';
import { NotificationConfirmComponent } from './confirm/notification-confirm.component';
import { RejectTransactionComponent } from './reject/reject-transaction.component';

/**
 * Ce module permet de gérer les notifications.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [NotificationComponent, RejectTransactionComponent,
        NotificationConfirmComponent]
})
export class NotificationModule {}