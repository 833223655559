import { Injectable } from '@angular/core';

export interface ITypeInputFormColumnName {
  id: string;
  type: string;
  description: string;
}

@Injectable({ providedIn: 'root' })
export class TypeInputFormColumnName {
  getColumnName(): ITypeInputFormColumnName {
    return {
      id: 'id',
      type: 'type',
      description: 'description'
    };
  }
}
