import { Injectable } from '@angular/core';

export interface ISourceFormColumnName {
  id: string;
  type: string;
  source: string;
  nameSourceFType: string;
  valeur: string;
  comment: string;
  champsFormulaireId: string;
  champsFormulaire: string;
}

@Injectable({ providedIn: 'root' })
export class SourceFormColumnName {
  getColumnName(): ISourceFormColumnName {
    return {
      id: 'id',
      type: 'type',
      source: 'source',
      nameSourceFType: 'nameSourceFType',
      valeur: 'valeur',
      comment: 'comment',
      champsFormulaireId: 'formulaireId',
      champsFormulaire: 'formulaire',
    };
  }
}
