import { Injectable } from '@angular/core';
import { Transaction } from '../../model/transaction.model';
import { FormService } from './form.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ICashModel } from '../../pages/entities/cash/cash-data-model';
import { Moment } from 'moment';
import { TransactionInfos } from '../../model/transaction/transaction-infos.model';

/**
 * Permet de faire des impressions de données.
 */
@Injectable({ providedIn: 'root' })
export class PrintService {

  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe,
    private formService: FormService) {
  }

  convertDate(m: Moment) {
    console.log('## moment: ', m);
    return m ? m.toString() : '';
  }

  getAmountReceived(d: ICashModel) {
    return d.cashDataModel.transactionInfos?.sentAmount - d.cashDataModel.transactionInfos?.fees
  }

  getAmountReceivedExRate(d: ICashModel, t?: Transaction) {
    const result = (d.cashDataModel.transactionInfos?.sentAmount - d.cashDataModel.transactionInfos?.fees) *
      d.cashDataModel.transactionInfos?.exchangeRate;
    return result;
    /*if(result > 0) {
      return result;
    }
    return t.receivedAmount *
      d.cashDataModel.transactionInfos?.exchangeRate;*/
  }

  format(n: any) {
    if(n) {
      console.log('## type amount: ', typeof (+n));
      return this.formService.format(+n);
    }
    return 0.0;
  }

  getDetailsTransaction(transaction: Transaction, docDefinition: any,
                        data: ICashModel, img?: any) {

    let labelsFeesTransactions: string[] = [];
    let montantFeesTransactions: any[] = [];

    //  récupération des labels des détails de transaction
    labelsFeesTransactions.push(this.translate.instant('transactionNumber') + ':');
    labelsFeesTransactions.push(('date') + ':');
    labelsFeesTransactions.push(this.translate.instant('amountReceive'));
    labelsFeesTransactions.push(this.translate.instant('devise'));
    labelsFeesTransactions.push(this.translate.instant('montantEnvoye'));
    labelsFeesTransactions.push(this.translate.instant('Fees'));

    let transactionInfos = data.cashDataModel.transactionInfos;
    // ajout des différents type de frais.
    if(transactionInfos && transactionInfos.fraisList) {
      for(let f of transactionInfos.fraisList) {
        labelsFeesTransactions.push(f.label);
      }
    }
    labelsFeesTransactions.push(this.translate.instant('exchangeRate'));

    // ajout des montants des détails de transaction.
    montantFeesTransactions.push(transaction.numberTrx);

    montantFeesTransactions.push(this.datePipe.transform(
      this.convertDate(transaction.createdDate),
      'EEEE, MMMM d, y') + ' ' + new Date().getUTCHours() + 'H'
      + new Date().getUTCMinutes() + 'min'
      + new Date().getUTCSeconds() + 's');

    montantFeesTransactions.push(this.format(transaction.receivedAmount) +
      ' ' + transaction.currency.slice(3));

    montantFeesTransactions.push(data.cashDataModel.transactionInfos?.currencySent + ' -> '
      + data.cashDataModel.transactionInfos?.currencyReceive);

    montantFeesTransactions.push(this.format(data.cashDataModel.transactionInfos?.sentAmount)
      + ' ' + data.cashDataModel.transactionInfos?.currencySent);

    montantFeesTransactions.push(this.format(data.cashDataModel.transactionInfos?.fees) + ' '
      + data.cashDataModel.transactionInfos?.currencySent);

    // ajout des différents montants de frais.
    if(transactionInfos && transactionInfos.fraisList) {
      for (let f of transactionInfos.fraisList) {
        montantFeesTransactions.push(this.format(f.montant)
          + ' ' + data.cashDataModel.transactionInfos?.currencySent);
      }
    }

    montantFeesTransactions.push(this.format(data.cashDataModel
      .transactionInfos?.exchangeRate));


      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        /*content: [
          {
            image: img,
            width: 150,
            height: 150,
          },
        ],*/
        header: [
          {
            image: img,
            width: 95,
            height: 75,
            margin: 20
          }
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [

          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear() + ' '
              + new Date().getUTCHours() + 'H'
              + new Date().getUTCMinutes() + 'min'
              + new Date().getUTCSeconds() + 's'
            ,
            alignment: 'center',
            fontSize: 18,
            bold: true,
            margin: 10
          },



          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: labelsFeesTransactions

                  /*this.translate.instant('transactionNumber') + ':',
                  this.translate.instant('date') + ':',
                  this.translate.instant('amountReceive'),
                  this.translate.instant('devise'),
                  this.translate.instant('montantEnvoye'),
                  this.translate.instant('Fees'),



                  this.translate.instant('exchangeRate'),*/
                 // this.translate.instant('mtExchangeRate')],
              ,
                fontSize: 12,
                bold: true,
              },
              {
                stack: montantFeesTransactions,

                /*[transaction.numberTrx,
                  this.datePipe.transform(this.convertDate(transaction.createdDate),
                    'EEEE, MMMM d, y'),

                  this.format(transaction.receivedAmount) + ' ' + transaction.currency.slice(3),

                  data.cashDataModel.transactionInfos?.currencySent + ' -> '
                  + data.cashDataModel.transactionInfos?.currencyReceive,

                  this.format(data.cashDataModel.transactionInfos?.sentAmount) + ' '
                  + data.cashDataModel.transactionInfos?.currencySent,

                  this.format(data.cashDataModel.transactionInfos?.fees) + ' '
                  + data.cashDataModel.transactionInfos?.currencySent,


                  this.format(data.cashDataModel.transactionInfos?.exchangeRate),

                 // this.format(transaction.receivedAmount) + ' ' + transaction.currency.slice(3),
                  //  this.format(this.getAmountReceivedExRate(data, transaction)) + ' ' + data.cashDataModel.transactionInfos?.currencyReceive
                ],*/
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 400,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':',
                  this.translate.instant('phoneNumber') + ':',
                  this.translate.instant('pays'), this.translate.instant('code') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                  data.cashDataModel.transactionInfos?.labelCountryReceiver,
                  transaction.valueCode
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 400,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('sent') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':',
                  this.translate.instant(('pays'))],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [


                  transaction.senderfName +
                  ' ' +
                  transaction.senderlName,
                  transaction.senderNumTel,
                  data.cashDataModel.transactionInfos?.labelCountrySender,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
  }

  /**
   * Récupère les détails de la transaction avec le code.
   * @param transaction
   * @param docDefinition
   * @param code
   * @param data
   */
  getDetailsWithCode(transaction: Transaction, docDefinition: any,
                     data: ICashModel) {
    if (transaction.transactionTypeName === 'RETRAIT CODE COMPLET ORANGE MONEY') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction'),
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is'),
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':',
                  this.translate.instant('date') + ':',
                  this.translate.instant('amountReceive') + ':',
                  this.translate.instant('Fees') + ':',
                  this.translate.instant('pays') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx,
                  this.datePipe.transform(this.convertDate(transaction.createdDate), 'EEEE, MMMM d, y'),
                  this.format(transaction.receivedAmount) + ' ' + transaction.currency.slice(3),
                  this.format(data.cashDataModel.transactionInfosRetrait.fees) + ' ' + transaction.currency.slice(3),
                  data.cashDataModel.transactionInfosRetrait.labelCountrySender],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':',
                  this.translate.instant('phoneNumber') + ':',
                  this.translate.instant('code') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                  transaction.valueCode
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
    }

    if (transaction.transactionTypeName === 'RETRAIT CODE PARTIEL ORANGE MONEY') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction'),
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is'),
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':',
                  this.translate.instant('date') + ':',
                  this.translate.instant('amountReceive') + ':',
                  this.translate.instant('Fees') + ':',
                  this.translate.instant('pays') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx,
                  this.datePipe.transform(this.convertDate(transaction.createdDate), 'EEEE, MMMM d, y'),
                  this.format(transaction.receivedAmount) + ' ' + transaction.currency.slice(3),
                  this.format(data.cashDataModel.transactionInfosRetrait.fees) + ' ' + transaction.currency.slice(3),
                  data.cashDataModel.transactionInfosRetrait.labelCountrySender],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':',
                  this.translate.instant('phoneNumber') + ':',
                  this.translate.instant('code') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                  transaction.valueCode
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
    }

    if (transaction.transactionTypeName === 'RETRAIT PAR CODE CASH') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction'),
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is'),
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':',
                  this.translate.instant('date') + ':',
                  this.translate.instant('amountReceive') + ':',
                  this.translate.instant('Fees') + ':',
                  this.translate.instant('pays') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx,
                  this.datePipe.transform(this.convertDate(transaction.createdDate), 'EEEE, MMMM d, y'),
                  this.format(transaction.receivedAmount) + ' ' + transaction.currency.slice(3),
                  this.format(data.cashDataModel.transactionInfosRetrait.fees) + ' ' + transaction.currency.slice(3),
                  data.cashDataModel.transactionInfosRetrait.labelCountrySender],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':',
                  this.translate.instant('phoneNumber') + ':',
                  this.translate.instant('code') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                  transaction.valueCode
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          /*  { text: this.translate.instant'sent') + ':', fontSize: 18, bold: true },
           {
             text: ' ',
           },

           {
             columns: [
               {
                 stack: [this.translate.instant('amount') + ':', this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                 fontSize: 12,
                 bold: true,
               },
               {
                 stack: [
                   transaction.receivedAmount +
                     ' ' +
                     transaction.currency,
                   transaction.receiverfName +
                     ' ' +
                     transaction.receiverlName,
                   transaction.receiverNumTel,
                 ],
               },
             ],
             columnGap: 5,
             fontSize: 12,
           }, */
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
    }
    if (transaction.transactionTypeName === 'DEPOT COMPTE CASH') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':',
                  this.translate.instant('date') + ':', this.translate.instant('amount') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx, transaction.createdDate,transaction.receivedAmount+ transaction.currency],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          /*  { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
           { text: ' ' },

           {
             columns: [
               {
                 stack: [this.translate.instant('amount') + ':', this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                 fontSize: 12,
                 bold: true,
               },
               {
                 stack: [
                   transaction.receivedAmount +
                     ' ' +
                     transaction.currency.slice(3),
                   transaction.receiverfName +
                     ' ' +
                     transaction.receiverlName,
                   transaction.receiverNumTel,
                 ],
               },
             ],
             columnGap: 5,
             fontSize: 12,
           }, */
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('sender') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':',
                  this.translate.instant('phoneNumber') + ':', this.translate.instant('code') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                  transaction.valueCode
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });

    }
    if (transaction.transactionTypeName === 'RETRAIT COMPTE CASH') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':',
                  this.translate.instant('date') + ':' ,this.translate.instant('amount') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx, transaction.createdDate, transaction.receivedAmount+ transaction.currency.slice(3)],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':',
                  this.translate.instant('code') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                  transaction.valueCode
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          /* { text: this.translate.instant'sent') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('amount') + ':', this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [
                  transaction.receivedAmount +
                    ' ' +
                    transaction.currency,
                  transaction.receiverfName +
                    ' ' +
                    transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          }, */
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
    }
    if (transaction.transactionTypeName === 'TRANSFER CODE CASH') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':', this.translate.instant('date') + ':',
                  this.translate.instant('amountReceive'),
                this.translate.instant('devise'),
                  this.translate.instant('montantEnvoye'),
                  this.translate.instant('Fees'), this.translate.instant('exchangeRate'),
                  this.translate.instant('mtExchangeRate')],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx,
                 this.datePipe.transform(this.convertDate(transaction.createdDate), 'EEEE, MMMM d, y'),
                  this.format(transaction.receivedAmount) + ' ' + transaction.currency.slice(3),
                data.cashDataModel.transactionInfos?.currencySent + ' -> ' + data.cashDataModel.transactionInfos?.currencyReceive,
                  this.format(data.cashDataModel.transactionInfos?.sentAmount) + ' ' + data.cashDataModel.transactionInfos?.currencySent,
                  this.format(data.cashDataModel.transactionInfos?.fees) + ' ' + data.cashDataModel.transactionInfos?.currencySent,
                  this.format(data.cashDataModel.transactionInfos?.exchangeRate),
                    this.format(this.getAmountReceivedExRate(data)) + ' ' + data.cashDataModel.transactionInfos?.currencyReceive
                ],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':',
                  this.translate.instant('pays'), this.translate.instant('code') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                  data.cashDataModel.transactionInfos?.labelCountryReceiver,
                  transaction.valueCode
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('sent') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':',
                  this.translate.instant(('pays'))],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [


                  transaction.senderfName +
                  ' ' +
                  transaction.senderlName,
                  transaction.senderNumTel,
                  data.cashDataModel.transactionInfos?.labelCountrySender,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
    }
    if (transaction.transactionTypeName === 'TRANSFER CODE CASH INTER') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':',
                  this.translate.instant('date') + ':',  this.translate.instant('amountReceive'),
                  this.translate.instant('devise'), this.translate.instant('montantEnvoye'),
                  this.translate.instant('Fees'), this.translate.instant('exchangeRate'),
                  this.translate.instant('mtExchangeRate')],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx,
                  this.datePipe.transform(this.convertDate(transaction.createdDate), 'EEEE, MMMM d, y'),
                  this.format(transaction.receivedAmount) + ' ' + transaction.currency.slice(3),
                  data.cashDataModel.transactionInfos?.currencySent + ' -> ' + data.cashDataModel.transactionInfos?.currencyReceive,
                  this.format(data.cashDataModel.transactionInfos?.sentAmount) + ' ' + data.cashDataModel.transactionInfos?.currencySent,
                  this.format(data.cashDataModel.transactionInfos?.fees) + ' ' + data.cashDataModel.transactionInfos?.currencySent,
                  this.format(data.cashDataModel.transactionInfos?.exchangeRate),
                  this.format(this.getAmountReceivedExRate(data)) + ' ' + data.cashDataModel.transactionInfos?.currencyReceive
                ],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [ this.translate.instant('nom') + ':',
                  this.translate.instant('phoneNumber') + ':',
                  this.translate.instant('pays'), this.translate.instant('code') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                  data.cashDataModel.transactionInfos?.labelCountryReceiver,
                  transaction.valueCode,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('sent') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':', this.translate.instant('pays')],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.senderlName +
                  ' ' +
                  transaction.senderfName,
                  transaction.senderNumTel,
                  data.cashDataModel.transactionInfos?.labelCountrySender
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });

    }
  }

  getDoc(transaction: Transaction, docDefinition: any) {
    if (transaction.transactionTypeName === 'RETRAIT PAR CODE CASH') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':', this.translate.instant('date') + ':',this.translate.instant('amount') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx, transaction.createdDate, transaction.receivedAmount+transaction.currency.slice(3)],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          /*  { text: this.translate.instant'sent') + ':', fontSize: 18, bold: true },
           {
             text: ' ',
           },

           {
             columns: [
               {
                 stack: [this.translate.instant('amount') + ':', this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                 fontSize: 12,
                 bold: true,
               },
               {
                 stack: [
                   transaction.receivedAmount +
                     ' ' +
                     transaction.currency,
                   transaction.receiverfName +
                     ' ' +
                     transaction.receiverlName,
                   transaction.receiverNumTel,
                 ],
               },
             ],
             columnGap: 5,
             fontSize: 12,
           }, */
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
    }
    if (transaction.transactionTypeName === 'DEPOT COMPTE CASH') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':', this.translate.instant('date') + ':', this.translate.instant('amount') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx, transaction.createdDate,transaction.receivedAmount+ transaction.currency],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          /*  { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
           { text: ' ' },

           {
             columns: [
               {
                 stack: [this.translate.instant('amount') + ':', this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                 fontSize: 12,
                 bold: true,
               },
               {
                 stack: [
                   transaction.receivedAmount +
                     ' ' +
                     transaction.currency.slice(3),
                   transaction.receiverfName +
                     ' ' +
                     transaction.receiverlName,
                   transaction.receiverNumTel,
                 ],
               },
             ],
             columnGap: 5,
             fontSize: 12,
           }, */
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('sent') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });

    }
    if (transaction.transactionTypeName === 'RETRAIT COMPTE CASH') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':', this.translate.instant('date') + ':',this.translate.instant('amount') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx, transaction.createdDate, transaction.receivedAmount+ transaction.currency.slice(3)],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          /* { text: this.translate.instant'sent') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('amount') + ':', this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [
                  transaction.receivedAmount +
                    ' ' +
                    transaction.currency,
                  transaction.receiverfName +
                    ' ' +
                    transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          }, */
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
    }
    if (transaction.transactionTypeName === 'TRANSFER CODE CASH') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':', this.translate.instant('date') + ':',this.translate.instant('amount') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx?transaction.numberTrx:null, transaction.createdDate,transaction.receivedAmount+transaction.currency.slice(3)],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':',
                  this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('sent') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [


                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });
    }
    if (transaction.transactionTypeName === 'TRANSFER CODE CASH INTER') {
      return (docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 40, 60],
        //background: this.translate.instant('transactionDetails')',
        header: [
          {
            text: this.translate.instant('transactionDetails'),
            style: 'header',
            decoration: 'underline',
          },
          {
            text:
              this.translate.instant('date') + ': ' +
              new Date().getUTCDate() +
              '-' +
              (new Date().getUTCMonth()+1)+
              '-' +
              new Date().getUTCFullYear(),
            alignment: 'right',
            fontSize: 18,
            bold: true,
          },
        ],
        //watermark: { text: 'Service Gate', color: 'blue', opacity: 0.3, bold: true, italics: false },
        /*
        footer: {
        columns: [
          'Left part',
          { text: new Date().getUTCDate()+'-'+new Date().getUTCMonth()+'-'+new Date().getUTCFullYear(), alignment: 'right', fontSize: 20, bold: true }
        ]
      }, */
        defaultStyle: {
          font: 'Roboto',
        },
        content: [
          {
            text: ' ' + this.translate.instant('allTransactionInfos'),
            style: 'textCenter',
          },
          {
            text: ' ',
          },

          {
            text: [
              this.translate.instant('yourTransaction') + ' ',
              { text: transaction.transactionTypeName, bold: true },
              ' ',
              this.translate.instant('is') + ' ',
              { text: transaction.status, color: 'green', bold: true },
              '!',
            ],
            fontSize: 13,
          },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('transactionNumber') + ':',
                  this.translate.instant('date') + ':',this.translate.instant('amount') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [transaction.numberTrx, transaction.createdDate,
                  transaction.receivedAmount+ transaction.currency.slice(3),],
              },
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('receive') + ':', fontSize: 18, bold: true },
          { text: ' ' },

          {
            columns: [
              {
                stack: [ this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 30,
                x2: 560,
                y2: 30,
                lineWidth: 1,
              },
            ],
          },
          { text: this.translate.instant('sent') + ':', fontSize: 18, bold: true },
          {
            text: ' ',
          },

          {
            columns: [
              {
                stack: [this.translate.instant('nom') + ':', this.translate.instant('phoneNumber') + ':'],
                fontSize: 12,
                bold: true,
              },
              {
                stack: [

                  transaction.receiverfName +
                  ' ' +
                  transaction.receiverlName,
                  transaction.receiverNumTel,
                ],
              },
            ],
            columnGap: 5,
            fontSize: 12,
          },
        ],

        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            alignment: 'center',
          },
          anotherStyle: {
            italics: true,
            alignment: 'center',
            fontSize: 20,
          },
          textCenter: {
            alignment: 'center',
            fontSize: 15,
            italics: true,
          },
        },
      });

    }
  }
}