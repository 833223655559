import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Transaction } from 'src/app/model/transaction.model';
import { TransactionsService } from 'src/app/services/ServiceEntity/transactions.service';

@Component({
  templateUrl: './valid-confirm-reclamation.component.html',
  styleUrls: ['./valid-confirm-reclamation.component.css']
})
export class ValidConfirmReclamationComponent implements OnInit {
   message: string;
   transaction: Transaction

  constructor(
    private transactionService: TransactionsService,
    public dialogRef: MatDialogRef<ValidConfirmReclamationComponent>,
    @Inject(MAT_DIALOG_DATA)data:{ message:string, transaction:Transaction} ) { 
    this.message = data.message;
    this.transaction= data.transaction
    console.log('numberAccount######', this.transaction);
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.transactionService.getAllTransactions(this.transaction.numberAccount);  

    // Close the dialog, return true
    this.dialogRef.close();
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
