import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les monies.
 */
export namespace ConstantCash {
  export const data = {
    title: 'Cash'
  }
  export const url = {
    CASH: {
      CASH_: 'cash'
    }
  };

  export const urlFull = {
    CASH: {
      LIST: ConstantCash.url.CASH.CASH_
    }
  };
}
