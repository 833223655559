import { Component, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { PasswordMatchingService } from '../../../password/update/password-matching.service';
import { Subject } from 'rxjs';
import { ROLE, ROLE_NAME } from '../../../../model/account.model';
import { CUser, ICUser } from '../../../../model/c-user.model';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IOption } from '../../../../ui/interfaces/option';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { RoleService } from '../../../../shared/services/role.service';
import { ColorService } from '../../../../shared/services/color.service';
import { FormService } from '../../../../shared/services/form.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { AccountUserService } from '../../../../services/ServiceEntity/account-user.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import {
  CONTENT_BTN_CLASS,
  LABEL_FORM_CLASS,
  SUFFIX_ICON_FORM,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../../../shared/constant/css-style/css-class.constant';
import { takeUntil } from 'rxjs/operators';
import { BASE_URL } from '../../../../../environments/environment';
import * as PageActions from '../../../../store/actions/page.actions';
import { IMoney } from '../../../../model/money.model';
import { ICountry } from '../../../../model/country.model';
import { TIME_LONG_REQUEST, TIME_MEDIUM_REQUEST } from '../../../../shared/constant/request.contant';
import { SelectPrefixService } from '../../../../shared/layouts/select-prefix.service';
import { FormValidationService } from '../../../../shared/services/form-validation.service';

@Component({
  selector: 'app-cuser-liste',
  templateUrl: './cuser-update.component.html'
})
export class CuserUpdateComponent extends BasePageComponent implements OnInit, OnDestroy,
  PasswordMatchingService, SelectPrefixService, FormValidationService {
  subs$ = new Subject<void>();
  indicatif: string;
  private cuserSelected: ICUser;

  success: boolean;
  failed: boolean;

  rolePartener = ROLE.partener;
  roleAdmin = ROLE.admin;
  roleTrader = ROLE.trader;

  //The Current User var
  CurentUser:any;
  CurrentUserAuth:any;
  thisUserId:number;
  thisUser:ICUser;
  iduser:string;
  //
  tableData: any[];
  searchData: any[];
  //
  updateCuserForm: UntypedFormGroup;
  loadingMessagesForm: boolean;
  theDialogRef: MatDialogRef<CuserUpdateComponent>;
  //
  countrylist: IOption[];
  authority: IOption[];
  //
  authorities: any;
  countries: any;
  //
  AttributId = 1;
  CuserToUpdate: any;
  //
  alertAddCuser:any;
  alertCuserExit:any;

  isSaving: boolean;
  act:string;
  title: string;
  role: string;

  roleSelected: string;

  constructor(store: Store<IAppState>, httpSv: HttpService,
              private roleService: RoleService,
              private colorService: ColorService,
              private formService: FormService,
              private formBuilder: UntypedFormBuilder,
              private http:HttpClient,
              private router:Router,
              private dialogRef: MatDialogRef<CuserUpdateComponent>,
              protected accountService: AccountService,
              protected cUserService: CUserService,
              private accountUserService:AccountUserService,
              private modal: TCModalService,
              private translate: TranslateService) {
    super(store, httpSv);
    this.theDialogRef = this.dialogRef;
    this.roleSelected = cUserService.roleSelected;
    this.tableData = [];
    this.countrylist = [];
    this.authority = [];
    this.loadingMessagesForm = false;
    //  this.initRole();
    this.getData(`${BASE_URL}Authority/getAuthority`,
      'authorities', 'initRole');
    this.getData(`${BASE_URL}countries`,
      'countries', 'initCountry');
    this.initUpdateForm();
  }

  ngOnInit(): void {
    console.log('## init cuser liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## account: ', account);
        this.role = this.roleService.getTheRole(account?.authorities);
        // this.getCurrentUser(account);
        console.log('accounttttt', this.CurentUser);
        this.cUserService.getIcuserSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: ICUser) => {
          console.log('## get cuser selected to liste');
          try {
            console.log('## data: ', res);
            this.cuserSelected = res;
            this.updateCuserForm.get('form').patchValue({
              id: res.id,
              userId: res.userId,
              phoneNumber: res.phoneNumber,
              numCNI: res.numCNI,
              login: res.login,
              firstName: res.firstName,
              lastName: res.lastName,
              email: res.email,
              activated: res.activated,
              authority: res.authority,
              countryId: res.countryId.toString(),
              attributsId: res.attributsId,
            })
            //  this.initCountry();
          }catch (e) {
            console.log('## error get cuser selected: ' + e.message);
          }
        });
      });
  }

  getRoleTrader() {
    return ROLE.trader;
  }

  getRolePartener() {
    return ROLE.partener;
  }

  /**
   * @see FormValidationService#isInvalid
   */
  isInvalid() {
    const formValidationControl = this.updateCuserForm.get('form') as UntypedFormControl;
    const formValidation = this.formService
      .isInvalidOrPristineControl(formValidationControl);
    const validationControl: UntypedFormControl = this.updateCuserForm.get('pass') as UntypedFormControl;
    const passValidation = this.formService
      .isInvalidOrPristineControl(validationControl);
    const lengthPassword = this.getNbChar(validationControl, 'password');
    const lengthConfirmPassword = this.getNbChar(validationControl, 'ConfirmPassword');
    if(lengthPassword > 0 || lengthConfirmPassword > 0){
      if(passValidation) {
        formValidationControl.markAsDirty();
        return this.formService
          .isInvalidOrPristineControl(formValidationControl) || passValidation;
      }
      return (formValidation || passValidation)
    }
    return formValidation;
  }

  isPassInvalid(column: string) {
    const validationControl: UntypedFormControl = this.updateCuserForm.get('pass') as UntypedFormControl;
    const passValidation = this.formService
      .isInvalidOrPristineControl(validationControl);
    const lengthPassword = this.getNbChar(validationControl, column);
    /* si un des champs contient au moins 1 caractères, on effectue
    la validation, dans le cas contraire, les champs du groupe 'pass'
     sont considérés comme valide */
    if(lengthPassword > 0){
      return passValidation || !this.isMatching();
    }
    return false;
  }

  isAllPassInvalid() {
    const validationControl: UntypedFormControl = this.updateCuserForm.get('pass') as UntypedFormControl;
    const passValidation = this.formService
      .isInvalidOrPristineControl(validationControl);
    const lengthPassword = this.getNbChar(validationControl, 'password');
    const lengthConfirmPassword = this.getNbChar(validationControl, 'ConfirmPassword');
    /* si un des champs contient au moins 1 caractères, on effectue
    la validation, dans le cas contraire, les champs du groupe 'pass'
     sont considérés comme valide */
    if(lengthPassword > 0 || lengthConfirmPassword > 0){
      return passValidation || !this.isMatching();
    }
    return false;
  }

  getNbChar(f: UntypedFormControl, column: string) {
    try {
      return (f.get(column).value as string).length;
    }catch (e) {
      console.log('## erreur getNbChar: ', e);
      return 0;
    }
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.updateCuserForm, column) &&
      this.updateCuserForm.get(column).value.length > 0;
  }

  getPrefixColorSelect(): string {
    return this.colorService.getPrefixColorSelect();
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  getContentClass() {
    return CONTENT_BTN_CLASS;
  }

  getPrefix(): string {
    return this.indicatif;
  }

  getPrefixModified(): string {
    if(this.indicatif) {
      return '+' +this.indicatif;
    }
    return '';
  }

  //status C-User
  StatusTrader(data: CUser){
    let act=data.activated;
    /*  let status = data.status;
     status = status === 'ACTIVED' ? 'DEACTIVED' : 'ACTIVED'; */
    this.isSaving = true;
    const id = (data.id.toString());
    const option={id,act}
    this.cUserService.updateStatusCUser( option).subscribe((response) => {
      console.log('## status modifié avec succès', response);
      const cuser = response.body;
      console.log('responseeeeee', cuser);
      data.activated=cuser.activated
      //data.activated ===(cuser && cuser.activated) ? cuser.activated : data.activated;

    });
  }

  ngOnDestroy() {
    console.log('## destroy cuser liste');
    this.formService.free(this.subs$);
  }

  getRole(authority: string) {
    return this.roleService.getRole(authority);
  }

  initUpdateForm() {
    console.log('## init liste form');
    this.updateCuserForm = this.formBuilder.group({
      form: this.formBuilder.group({
        id: ['', Validators.required],
        userId: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        numCNI: ['', Validators.required],
        login: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50)]],
        activated: [true],
        authority: ['', Validators.required],
        countryId: ['', Validators.required],
        attributsId: [this.AttributId]
      }),
      pass: this.formBuilder.group({
        password: ['', [Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50)]],
        ConfirmPassword: ['', [Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50)]],
      })
    });
  }

  interValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const c: string = control.value;
    console.log('## c: ' + c);
    let val = (c !== undefined && c !== null) ? c : '';
    console.log('##val: ' + val);
    console.log('##val: ' + val.length);
    /* on vérifie si l'utilisateur a saisi une valeur,
     on renvoie 'true' si le control est invalid, sinon on renvoie 'false */
    const minLength = 8;
    const maxLength = 50;
    const l = val.length;
    if(l > 0) {
      control.setValidators([Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50)]);
      return { ['passUpdate']: true }
    } else {
      control.setValidators([]);
      // l'utilisateur n'a pas saisi de valeur
      return null
    }
  }


  selectCountry(countrySelected) {
    console.log('## country selected: ', countrySelected);
    for(let c of this.countries) {
      if(c.id === (+countrySelected)) {
        console.log('## c_id: ', c.id, ' match e_selected_id: ', countrySelected);
        this.indicatif = c.codePhone;
        console.log('## c_id indicatif: ', this.indicatif);
        break;
      }
    }
  }

  isMatching() {
    const newPass = this.updateCuserForm.get('pass.password').value;
    const confirmPass = this.updateCuserForm.get('pass.ConfirmPassword').value;
    return ((newPass && confirmPass) && (newPass === confirmPass));
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }
  // initCountryAuthority() {

  getThisUser(account: ICUser) {
    this.cUserService
      .query({})
      .subscribe((res: HttpResponse<ICUser[]>) => {
        this.thisUser = res.body.find(resul => {
          return resul.userId === account.id;
        });
      });
  }

  private getDataServ(res: HttpResponse<ICUser[]>) {
    try {
      const data = res.body;
      console.log('## data without filter: ', data);
      console.log('## size: ', data.length);
      const dataFilter = data.filter(resul => resul.authority === this.roleTrader
        || resul.authority === this.rolePartener);
      console.log('## data filter: ', dataFilter);
      console.log('## size: ', dataFilter.length);
      this.tableData = dataFilter;
      this.searchData = dataFilter;
      /*      this.cUserService.getIcuserObs().pipe(takeUntil(this.subs$))
              .subscribe((res: IMoney[]) => {
                console.log('## get list money in list by subject');
                this.tableData = (res) ? res : [];
                this.searchData = this.searchData;
              })*/
    } catch (e) {
      console.log('## error get data:', e.message);
    }
  }

  /**
   * Récupère tous les utilisateurs créés par l'utilisateur
   * connecté.
   */
  loadAllList() {
    this.cUserService
      .queryList()
      .subscribe((res: HttpResponse<ICUser[]>) => {
          this.getDataServ(res);
        }, err => {
          console.log('## error get data:', err.message);
        }
      );
  }

  /**
   * Récupère tous les utilisateurs.
   */
  loadAll() {
    this.cUserService
      .query()
      .subscribe((res: HttpResponse<ICUser[]>) => {
          this.getDataServ(res);
        }, err => {
          console.log('## error get data:', err.message);
        }
      );
  }

  closeModal() {
    console.log('## close cuser add');
    this.dialogRef.close();
  }


  //initialiser le formulaire pour l'ajout d'un trader
  initMessagesForm() {
    console.log('## init message form');
    this.updateCuserForm = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      numCNI: ['', Validators.required],
      login: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50)]],
      ConfirmPassword: ['', [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50)]],
      activated: [true],
      authority: ['', Validators.required],
      countryId: ['', Validators.required],
      attributsId: [this.AttributId]
      //countryName: ['', Validators.required]
    });
  }

  sendMessagesForm(valid: boolean) {
    if (valid) {
      this.loadingMessagesForm = true;
      setTimeout(() => {
        this.loadingMessagesForm = false;
        //this.updateCuserForm.reset();
      }, 1000);
    }
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  resetMessagesForm() {
    this.updateCuserForm.reset();
    this.loadingMessagesForm = false;
    this.alertCuserExit = false;
    this.alertAddCuser = true;
  }

  //Initialiser les Country pour le Select
  initCountry() {
      this.countrylist = [];
      let selected = false;
      this.countries?.forEach(element => {
       // console.log('## val: ', this.cuserSelected.countryId === element.id);
        this.countrylist.push({
          ...new Option(),
          label: element.label,
          value: element.id?.toString()});
      });
      console.log('## val: ', this.countrylist);
  }

  //Initialiser les Roles pour le Select
  initRole(): void {
      this.authority = [];
      this.authorities?.forEach(element => {
        //for role trader
        if (this.role == ROLE_NAME.partener) {
          if (element.name === this.roleTrader) {
            this.authority.push({ 'label': 'POINT_CASH', 'value': element.name });
          }
        } else {
          if (element.name === this.roleTrader || element.name === this.rolePartener) {
            if (element.name === this.roleTrader) {
              this.authority.push({ 'label': 'POINT_CASH', 'value': element.name });
            } else {
              this.authority.push({ 'label': element.name, 'value': element.name });
            }
          }
        }
      });
  }

  //recherche sur les traders
  search(query: string): void {
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.tableData].map(mapFn);
    this.searchData = this.tableData.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
  }

  private createFromForm(): ICUser {
    let password = this.updateCuserForm.get('pass.password')?.value;
    password = (password && password !== '') ? password : undefined;
    return {
      ...new CUser(),
      id: this.updateCuserForm.get('form.id')?.value,
      userId: this.updateCuserForm.get('form.userId')?.value,
      login: this.updateCuserForm.get('form.login')?.value,
      lastName: this.updateCuserForm.get('form.lastName')?.value,
      firstName: this.updateCuserForm.get('form.firstName')?.value,
      email: this.updateCuserForm.get('form.email')?.value,
      phoneNumber: this.updateCuserForm.get('form.phoneNumber')?.value,
      activated: this.updateCuserForm.get('form.activated')?.value,
      numCNI: this.updateCuserForm.get('form.numCNI')?.value,
     // authority: this.updateCuserForm.get('form.authority')?.value,
      countryId: +this.updateCuserForm.get('form.countryId')?.value,
      attributsId: this.AttributId,
      password: password
    };
  }

  ExecuteUpDateTrader() {
    this.loadingMessagesForm = true;
    this.success = false;
    this.failed = false;
    const user = this.createFromForm();
    user.authority = this.cuserSelected.authority;
    console.log('## updateForm:', user);
    this.cUserService.updateCUser(user).subscribe(() => {
      console.log('SUCCESS FOR UPDATING');
      this.updateCuserForm.get('pass').patchValue({
        'password': '',
        'ConfirmPassword': ''
      });
      setTimeout(() => {
        this.loadingMessagesForm = false;
      }, TIME_MEDIUM_REQUEST)
      this.success = true;
      this.failed = false;
      if(this.role === ROLE_NAME.partener) {
        this.cUserService.getAllCuserList();
      } else if(this.role === ROLE_NAME.admin) {
        this.cUserService.getAllCuserWithStatus();
      }
    }, err => {
      console.log('Error When updating', err);
      this.loadingMessagesForm = false;
      this.success = false;
      this.failed = true;
    });
  }

  //Ajouter un Mobile Account
  addMobileAccount(data: any) {
    const mobileAccount = {
      'cUserId': Number(data.id),
      'label': 'Main Account',
      'preferenceCurrency': data.currency,
      'phoneNumber': data.phoneNumber,
      'countryLabel': data.countryName
    };
    //console.log("## DATA: ",JSON.stringify(mobileAccount));
    this.accountUserService.create(mobileAccount).subscribe((rep) => {
      const body = rep?.body;
      console.log("MobileAccount", body);
      this.addAccountRessources(body);
    }, err => {
      console.log(err);
    });
  }


  //Ajouter un  Account Ressource
  addAccountRessources(dataMobileAccount: any) {
    //account-ressource
    console.log('## datamobileacc: ', dataMobileAccount);
    const accountRessource = {
      'amount': 0,
      'accountRessTypeId': 1,
      'accountRessType': 'CASH',
      'currency': dataMobileAccount.preferenceCurrency,
      'mobileAccountId': dataMobileAccount.id,
      'labelAccountUser': dataMobileAccount.label
    };
    this.http.post(`${BASE_URL}account-ressource`, accountRessource).subscribe((rep) => {
      console.log("### Account Ressource", rep);
    }, err => {
      console.log(err);
    });
  }

  //supprimer un trader
  removeTrader(id) {
    this.http.delete(`${BASE_URL}c-users/${id}`).subscribe(() => {
      console.log('SUCCESS');
      //this.loadTable();
    }, err => {
      console.log(err);
    });
  }

  isDirty(column: string): boolean {
    return this.formService.isDirty(this.updateCuserForm,
      column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.updateCuserForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.updateCuserForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.updateCuserForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.updateCuserForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.updateCuserForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.updateCuserForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.updateCuserForm, column);
  }

}
