import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { DomainListComponent } from '../domain/domain-list.component';
import { DomainAffectStatusComponent } from '../domain/affect_status/domain-affect-status.component';
import { DomainUpdateComponent } from '../domain/update/domain-update.component';
import { DomainAddComponent } from '../domain/add/domain-add.component';
import { CostsComponent } from './costs.component';
import { CostsAddComponent } from './add/costs-add.component';
import { CostsUpdateComponent } from './update/costs-update.component';

/**
 * Ce module permet de gérer les coûts.
 */
@NgModule({
  imports: [SharedModule],
  declarations: [CostsComponent, CostsAddComponent,
    CostsUpdateComponent]
})
export class CostsModule {}