import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from '../../base-page';
import { IServiceItem } from '../../../model/service-item.model';
import { Observable, Subject } from 'rxjs';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { ServiceItemService } from '../service-items/service-item.service';
import { ConstantServiceItems } from '../service-items/service-items.url';
import { ConstantHome } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { CONSTANT_ICON } from '../../../shared/constant/icon.constant';
import { ServiceItemAddComponent } from '../service-items/add/service-item-add.component';
import { IBenefitRule } from '../../../model/benefit-rule.model';
import { BenefitRuleService } from './benefits.service';
import { ConstantFeesBenefits } from './fees.url';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Account } from '../../../model/account.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ICostsRule } from '../../../model/costs-rule.model';
import { ICountry } from '../../../model/country.model';
import { IMoney, Money } from '../../../model/money.model';
import { CountryService } from '../countries/countries.service';
import { CostsRuleService } from './costs-rule.service';
import { MoniesService } from '../monies/monies.service';
import { UntypedFormControl } from '@angular/forms';
import { FeesAddComponent } from './add/fees-add.component';
import { FeesUpdateComponent } from './update/fees-update.component';
import { CreateComponent } from './create/create.component';
import { TranslateService } from '@ngx-translate/core';
import { Costs } from '../../../model/costs.model';
import { TIME_MIN_REQUEST } from '../../../shared/constant/request.contant';
import { CostsDetailsService } from '../costs-details/costs-details.service';
import { CostsDetails, ICostsDetails } from '../../../model/costs-details.model';
import { BTN_CANCEL_CLASS, CANCEL_BG_COLOR } from '../../../shared/constant/css-style/css-class.constant';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.css']
})
export class FeesComponent extends BasePageComponent implements OnInit,
  OnDestroy, AfterViewChecked {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  serviceItemsList: IServiceItem[] = [];
  serviceItemSelected: IServiceItem;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<IServiceItem[]>;

  costsRulesList: ICostsRule[] = [];
  costsRulesListSearch: ICostsRule[] = [];
  moneyList: IMoney[] = [];

  countriesList: ICountry[] = [];
  filteredCountryOptions: Observable<ICountry[]>;
  myControl2 = new UntypedFormControl();
  countrySelected: ICountry;

  findCostsRuleList = false;
  findMoneyList = false;
  searchValue: string;
  subs$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private benefitService: BenefitRuleService,
    private accountService: AccountService,
    protected costsRuleService: CostsRuleService,
    protected countryService: CountryService,
    protected moniesService: MoniesService,
    private serviceItemService: ServiceItemService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct costs rule list');
    this.pageData = {
      title: ConstantFeesBenefits.data.sub.list.title,
      key: 'listFeesBenefits',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantFeesBenefits.data.title,
          key: 'Fees&Benefit',
          route: Url.SLASH_ROUTE.concat(ConstantFeesBenefits.urlFull
            .FEES.LIST)
        },
        {
          title: ConstantFeesBenefits.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if(this.pageData.key==="listFeesBenefits"){
      translate.get('listFeesBenefits').subscribe(res => this.pageData.title=res);
    }
   
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="Fees&Benefit" ){
        translate.get('Fees&Benefit').subscribe(res => value.title=res);
      }
      if( value.key==="lister" ){
        translate.get('lister').subscribe(res => value.title=res);
      }
     
    }); 
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init costs liste');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.getCostsRuleList();
          this.getMoneyList();
          this.getServiceItemList(); // after 500ms.
          this.getCountryList(); // after 500ms.
          this.account = account;
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    console.log('## on destroy costs rule list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  private _filterCountry(value: string): ICountry[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.countriesList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
      console.log('##############countryyyyy#########', filteredList);
    } catch (e) {
      console.log('## error filter country: ' + e.message);
    }
    return filteredList;
  }



  private _filter(value: string): IServiceItem[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option.name.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  displayFnCountry(subject: ICountry) {
    return subject ? subject.label : '';
  }

  changeCountry(event) {
    try {
      console.log('## select country');
      const c: ICountry = event.option.value;
      console.log('## value country: ' + c.label);
      this.countrySelected = c;
      this.searchCountry();
    } catch (e) {
      console.log('## error selected value country');
    }
  }
  searchCountry(): void {
    const query = this.countrySelected.label;//country selected
    const result = this.costsRulesListSearch.filter(obj => { return obj.countryLabelA === query });
    //console.log('result', result);
    this.costsRulesList = [];
    result.forEach((c) => {
      this.costsRulesList.push(c);
    });

    if (this.serviceItemSelected !== null) {
      this.searchServiceItem();
      this.costsRulesListSearch = this.costsRulesList;
      const result = this.costsRulesListSearch.filter(obj => { return obj.countryLabelA === query });
      console.log('serviceItemSelected######################');
      this.costsRulesList = [];
      result.forEach((c) => {
        this.costsRulesList.push(c);
      });
    }

  }
  displayFn(subject: IServiceItem) {
    return subject ? subject.name : '';
  }

  changeItem(event) {
    try {
      console.log('## select item');
      const si = event.option.value;
      console.log('## value item: ' + si.name);
      this.serviceItemSelected = si;
      this.searchServiceItem();
    } catch (e) {
      console.log('## error selected value s item');
    }
  }

  searchServiceItem(): void {
    const query = this.serviceItemSelected.name;
    const result = this.costsRulesListSearch.filter(obj => { return obj.serviceItemsName === query });
    this.costsRulesList = [];
    result.forEach((c) => {
      this.costsRulesList.push(c);
    })
  }

  getServiceItemList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.serviceItemService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IServiceItem[]>) => mayBeOk.ok),
          map((response: HttpResponse<IServiceItem[]>) => response.body)
        )
        .subscribe((res: IServiceItem[]) => {
          console.log('## success get list service item');
          const rs = res ? res : [];
          console.log('## size: ' + rs.length);
          this.serviceItemsList = rs;
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        },
          (res: HttpErrorResponse) => {
            console.log('## error get list service item: ' + res.message);
          });
    }, 500);
  }

  getCountryList() {
    setTimeout(() => {
      // COUNTRY
      this.countryService.query().subscribe(res => {
        console.log('## Success get country list');
        this.countriesList = (res.body) ? res.body : [];
        console.log('## size: ' + this.countriesList.length);
        this.filteredCountryOptions = this.myControl2.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterCountry(value))
          );
      });
    }, 500);
  }

  getMoneyList() {
    this.moniesService.query().subscribe(res => {
      console.log('## Success get monies list');
      this.moneyList = (res.body) ? res.body : [];
      console.log('## size: ' + this.moneyList.length);
      this.findMoneyList = true;
    });
  }

  getCostsRuleList() {
    this.costsRuleService.queryList().subscribe(res => {
      console.log('## Success get costs rule list');
      this.costsRulesList = (res.body) ? res.body : [];
      this.costsRulesListSearch = this.costsRulesList;
      console.log('## size: ' + this.costsRulesList.length);
      this.findCostsRuleList = true;
      this.costsRuleService.getCostRuleObs().pipe(takeUntil(this.subs$))
        .subscribe((res: ICostsRule[]) => {
          console.log('## get list costs rule in list by subject');
          const rs = res ? res : [];
          console.log('## size: ' + rs.length);
          this.costsRulesList = rs;
          this.costsRulesListSearch = this.costsRulesList;
        });
    });
  }

  hasFound() {
    return this.findCostsRuleList && this.findMoneyList;
  }

  costRuleListIsEmpty() {
    return this.costsRulesList.length <= 0 || !this.costsRulesList;
  }

  moneyListIsEmpty() {
    return this.moneyList.length <= 0 || !this.moneyList;
  }

  getMoney(idMoney) {
    if (idMoney == null) {
      const mon: Money = new Money();
      mon.codeMoney === 'NOT FOUND';
      return mon;
    } else {
      return this.moneyList.find(e => e.id === idMoney);
    }
  }
  format(n: number) {
    return this.formService.format(n);
  }

  /* search(): void {
     const query = this.searchValue;
     console.log('## search: ' + query);
     const mapFn = (item) =>
       Object.values(item)
         .map(function(val) {
           return val?.toString().toLowerCase();
         })
         .join(' ');
     const stringArr = [...this.benefitSearch].map(mapFn);
     const result = this.benefitSearch.filter(
       (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
     );
     this.costsRulesList = [];
     result.forEach((c) => {
       this.costsRulesList.push(c);
     });
   }*/

  getIcon() {
    return CONSTANT_ICON;
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    console.log('## open fees add', this.costsRulesList);

    this.dialog.open(FeesAddComponent, {
      data: { costsRulesList: this.costsRulesList }, height: '700px', width: '800px'
    });
  }

  show(c: ICostsRule) {
    /*console.log('## open service show');
    this.benefitService.setServiceItemSelectObs(c);
    this.dialog.open(MoniesShowComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  update(c: ICostsRule) {
    this.costsRuleService.setCostRuleSelectObs(c);
    this.dialog.open(FeesUpdateComponent, {
      height: '700px',
      width: '800px',
    });
  }

  create(c: ICostsRule) {
    this.costsRuleService.setCostRuleSelectObs(c);
    this.dialog.open(CreateComponent, { data: { costsRule: c },
      height: "600px", width: "800px" }
    );
  }


  delete(c: ICostsRule) {
    /*this.benefitService.setServiceItemSelectObs(c);
    this.dialog.open(ServiceItemDeleteComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  /**
   * Retourne la liste des services items
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): ICostsRule[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

}
