import { Routes } from '@angular/router';
import { ConstantValidators } from '../validators/validator.url';
import { GuardAdminService } from '../../../../services/guard/authorities/guard-admin.service';
import { ValidatorListComponent } from '../validators/validator-list.component';
import { ConstantValidatorsAffected } from './validator-affected.url';
import { ValidatorAffectedComponent } from './validator-affected.component';

/**
 * Ensemble des routes pour les costs rule.
 */
export const VALIDATOR_AFFECTED_ROUTE: Routes = [
  {
    path: ConstantValidatorsAffected.url.FEES.FEES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantValidatorsAffected.url.FEES.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantValidatorsAffected.url.FEES.LIST,
        component: ValidatorAffectedComponent
      }
    ]
  }
];
