import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from 'src/environments/environment';

import { throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TokenStorageService } from './storage/token-storage.service';
import { TOKEN } from '../shared/constant/request.contant';


@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(private http:HttpClient,private localstorage: TokenStorageService) { }
  
  loginProcess(data:any):Observable<any>{
    console.log('Here the server');
    return this.http.post(`${BASE_URL}authenticate`,data);
  }
  loginIn(){
    return !!this.localstorage.getItem(TOKEN);
  }
 
}
