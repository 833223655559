/**
 * Décrit les éléments du menu pour un trader.
 */
export const CONSTANT_KEY_TRANSLATE =
  {
    notification: 'Notification',
    home: 'Home',
    costs: 'costs',
    mobileAccount: 'Mobile Account',
    trader: 'Trader',
    validator: 'validator',
    formulaire: 'formulaire',
    champsFormulaire: 'champs_formulaire',
    sourceForm: 'sourceForm',
    typeInputForm: 'type-input-form',
    validatorAffected: 'validatorAffected',
    monies: 'Monies',
    fees: 'Fees & Benefits Rule',
    transactions: 'Transactions',
    service: 'Service Items',
    exchange: 'Exchange Rate',
    versionExchange: 'Version Exchange Rate',
    countries: 'Countries',
    domain: 'Domain',
    status: 'Status',
    account: 'Account ressource type',
    cash: 'Cash',
    apikey: 'Api Key'
  };
  
