import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { BasePageComponent } from '../../../pages/base-page';
import { AbstractControl, ControlValueAccessor, FormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { IOption } from '../../../ui/interfaces/option';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../services/form.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { CountryService } from '../../../pages/entities/countries/countries.service';
import { CostsRuleService } from '../../../pages/entities/fees/costs-rule.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { takeUntil } from 'rxjs/operators';
import { BASE_URL } from '../../../../environments/environment';
import {
  BEFORE_ICON_RESET,
  BEFORE_ICON_VALIDATION,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALID_CLASS,
  BTN_VALIDATION_CLASS,
  CANCEL_BG_COLOR,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS,
  CONTAINER_POPUP_CLASS,
  FORM_CLASS,
  ICON_COLOR_REQUEST_CSS, ICON_FONT_REQUEST_CLASS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET, REMOVABLE_ICON_REQUEST, RESULT_REQUEST_ERROR_CLASS, RESULT_REQUEST_SUCCESS_CLASS,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS,
  SUFFIX_ICON_FORM, TITLE_POPUP_CLASS, VALIDATION_BTN2_POPUP_CLASS, VALIDATION_BTN3_POPUP_CLASS,
  VIEW_ERROR,
  VIEW_VALIDATION,
  WARNING_COLOR_CSS,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../constant/css-style/css-class.constant';
import { FormValidationService } from '../../services/form-validation.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  animations: [fadeIn]
})
export class GroupFormComponent implements OnInit {

  // le titre du champs.
  @Input('label') label: string;

  @Input('formValidationService') formValidationService: FormValidationService;

  // les informations sur le champs.
  @Input('control') control: AbstractControl;
  @Input('column') column: string;
  @Input('theFormGroup') theFormGroup: UntypedFormGroup;

  // indique si l'input est requis.
  @Input('required') required = false;
  @Input('errorMessage') errorMessage: string;
  @Input('errorMinLengthMessage') errorMinLengthMessage: string;
  @Input('errorMaxLengthMessage') errorMaxLengthMessage: string;
  @Input('errorPatternMessage') errorPatternMessage: string;
  @Input('errorEmailMessage') errorEmailMessage: string;

  constructor(private formService: FormService) {
    console.log('## construct header form')
  }

  ngOnInit(): void {
    console.log("## init header form");
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.theFormGroup, column);
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  isCancelBtnOutline(): boolean {
    return OUTLINE_CANCEL;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getContainerBtnClass() {
    return CONTAINER_BTN_POPUP_CLASS;
  }

  getFormClass() {
    return FORM_CLASS;
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  getResultRequestTextSuccess(): string {
    return RESULT_REQUEST_TEXT_SUCCESS_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getWarningColor(): string {
    return WARNING_COLOR_CSS;
  }

  getInside2xContainerClass(): string {
    return CONTAINER_INSIDE2X_POPUP_CLASS;
  }

  getInsideContainerClass(): string {
    return CONTAINER_INSIDE_POPUP_CLASS;
  }
  getContainerClass() : string {
    return CONTAINER_POPUP_CLASS;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getIconColorRequestClass(): string {
    return ICON_COLOR_REQUEST_CSS;
  }

  isRemovable(): boolean {
    return REMOVABLE_ICON_REQUEST;
  }

  getTitleClass(): string {
    return TITLE_POPUP_CLASS;
  }

  getIconRequestClass(): string {
    return ICON_FONT_REQUEST_CLASS;
  }

  getResultRequestErrorClass() {
    return RESULT_REQUEST_ERROR_CLASS;
  }

  getResultRequestSuccessClass() {
    return RESULT_REQUEST_SUCCESS_CLASS;
  }

  isRequired(column: string): boolean {
    if(this.formValidationService) {
      return this.formValidationService.isRequired(column);
    }
    return this.formService.isRequired(this.theFormGroup, column);
  }

  hasInteract(column: string): boolean {
    return this.theFormGroup.get(column).invalid &&
      (this.theFormGroup.get(column).dirty ||
      this.theFormGroup.get(column).touched);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.theFormGroup,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.theFormGroup, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.theFormGroup, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.theFormGroup, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.theFormGroup, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.theFormGroup, column);
  }

}
