import { Routes } from '@angular/router';
import { ConstantFeesBenefits } from '../../fees/fees.url';
import { GuardAdminService } from '../../../../services/guard/authorities/guard-admin.service';
import { FeesComponent } from '../../fees';
import { ConstantValidators } from './validator.url';
import { ValidatorListComponent } from './validator-list.component';

/**
 * Ensemble des routes pour les costs rule.
 */
export const VALIDATOR_ROUTE: Routes = [
  {
    path: ConstantValidators.url.FEES.FEES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantValidators.url.FEES.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantValidators.url.FEES.LIST,
        component: ValidatorListComponent
      }
    ]
  }
];
