import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService } from '../../ServiceEntity/account.service';
import { Observable } from 'rxjs';
import { ROLE } from '../../../model/account.model';
import { Url } from '../../../shared/constant/url.constant';
import ERR_404 = Url.ERR_404;
import { GuardAuthoritiesService } from './guard-authorities.service';

/**
 * Permet de vérifier si un utilisateur possède le role
 * {@link ROLE.partener} afin de pouvoir décider
 * si on lui donne accès à la route.
 */
@Injectable({
  providedIn: 'root'
})
export class GuardPartenerService implements CanActivate {
  constructor(
    private router: Router,
    private guardAuthoritiesService: GuardAuthoritiesService,
  ) {

  }

  /**
   * Si l'utilisateur possède le role {@link ROLE.partener}
   * alors on lui donne accès à la route.
   * @param route
   * @param state
   * @Return true si l'utilisateur possède le role {@link ROLE.partener} ,
   * false dans le cas contraire et il sera redirigé
   * vers la page d'erreur.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('## verify partener role');
    return this.guardAuthoritiesService.hasRole(route, state, ROLE.partener);
  }

}
