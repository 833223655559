import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { BASE_URL, BASE_URL_ADMIN } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IApikey, IApiKeyCDTO, IApiKeyDTO } from '../../../model/api-key.model';
import { Observable } from 'rxjs';
import { MobileAccountCash } from '../../../model/mobile-account.model';

type EntityResponseType = HttpResponse<IApikey>;
type EntityArrayResponseType = HttpResponse<IApikey[]>;

@Injectable({ providedIn: 'root' })
export class ApiKeyService extends ApiService {

  public apiKeyAccountsUrl = BASE_URL + 'api-keys/accounts';
  public resourceUrlApiKeysAccounts = BASE_URL + 'api-keys/cuser';
  public resourceUrlApiKeysStatus = BASE_URL + 'api-key/change-status';
  public resourceUrlApiKey = BASE_URL + 'api-key';

  // liste les domains en fonction de la dernière date de modification.
  public list_url = BASE_URL_ADMIN + '/domain/sort_by_date';

  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * Récupère les api key de tous les accounts de l'utilisateur connecté.
   * @param option
   */
  findAllApiKeyAccounts(option?: any): Observable<HttpResponse<IApiKeyDTO[]>> {
    return this.http.get<IApiKeyDTO[]>(this.apiKeyAccountsUrl,
      { params: option, observe: 'response' });
  }

  updateStatus(a: any): Observable<EntityResponseType> {
    return this.http.post<IApiKeyCDTO>(this.resourceUrlApiKeysStatus, a,
      { observe: 'response' });
  }

  updateApiKey(a: IApiKeyCDTO): Observable<EntityResponseType> {
    return this.http.put<IApiKeyCDTO>(this.resourceUrlApiKey, a, { observe: 'response' });
  }

  createApiKey(a: IApiKeyCDTO): Observable<EntityResponseType> {
    return this.http.post<IApiKeyCDTO>(this.resourceUrlApiKey, a, { observe: 'response' });
  }

  /**
   * {@link ApiService#getAllObjet}
   */
  getAllObjetById() {
    setTimeout(() => {
      this.http.get<IApiKeyDTO[]>(`${this.resourceUrlApiKeysAccounts}`,
        { observe: 'response' }).subscribe(res => {
        console.log('## Success get objet list');
        this.setObjetObs((res.body) ? res.body : []);
      });
    }, 300)
  }
}
