import { Url } from '../../../../shared/constant/url.constant';

/**
 * Route utilisées pour les fees & benefits.
 */
export namespace ConstantValidatorsAffected {
  export const data = {
    title: 'Validateurs affectés',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un Validateur'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des Validateurs affectés'
      }
    }

  }
  export const url = {
    FEES: {
      FEES_: 'validator_affected',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    FEES: {
      ADD: ConstantValidatorsAffected.url.FEES.FEES_
        .concat(Url.SLASH_ROUTE).concat(ConstantValidatorsAffected.url.FEES.ADD),
      LIST: ConstantValidatorsAffected.url.FEES.FEES_
        .concat(Url.SLASH_ROUTE).concat(ConstantValidatorsAffected.url.FEES.LIST),
    }
  };
}
