import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { ICode } from 'src/app/model/code.model';
import { CodeService } from 'src/app/services/ServiceEntity/code.service';
import { MobileMoneyKey } from '../mobile-mobey-key';
import { RetraitCodeService } from '../retrait-code.service';

@Component({
  selector: 'app-retrait-code',
  templateUrl: './retrait-code.component.html',
  styleUrls: ['./retrait-code.component.css']
})
export class RetraitCodeComponent implements OnInit {
  verifyCodeForm: UntypedFormGroup;
  loadingCodeForm: boolean;
  tabRetrait:boolean;

  code: ICode;


  constructor(private fb: UntypedFormBuilder,
    private codeService: CodeService,
    private retraitCodeService:RetraitCodeService,
    private mobileMoneyKey:MobileMoneyKey
  )
  { 
    this.retraitCodeService.setElement(this.mobileMoneyKey.appTabsRetraitCode, true);
    this.retraitCodeService.setElement(this.mobileMoneyKey.appRetraitCodeValide, false);

  }

  ngOnInit(): void {
    this.verifyCodeForm = this.fb.group({
      valueCode: ['', [Validators.required]]
    });
    this.getTabRetraitCode();
  }

  ngOnDestroy() {
    console.log('## destroy envoi code');
    this.retraitCodeService.clear();
    
  }

  onSubmitRetraitCode(valid: boolean) {   
    if (valid) {
      this.loadingCodeForm = true;
      setTimeout(() => {
        this.loadingCodeForm = false;
        this.verifyCodeForm.reset();
      }, 1000);
    }
  }
  
  retraitCash() { 
    const valueCode = this.verifyCodeForm.get(['valueCode']).value;
      this.codeService
        .findByCode(valueCode)
        .pipe(
          filter((res: HttpResponse<ICode>) => res.ok),
          map((res: HttpResponse<ICode>) => res.body)
        )
        .subscribe(res => {
          console.log('code###############', res);
          this.code = res;
          this.retraitCodeService.setCode(this.code);
          this.verifyCodeForm.reset();
          this.retraitCodeSuccess();
        });
      this.loadingCodeForm = true;
      setTimeout(() => {
        this.loadingCodeForm = false;
        this.verifyCodeForm.reset();
      }, 1000);
    }  

    retraitCodeSuccess() {
      this.retraitCodeService.setElement(this.mobileMoneyKey.appTabsRetraitCode, false);      
      this.retraitCodeService.setElement(this.mobileMoneyKey.appRetraitCodeValide, true);     
      this.retraitCodeService.setElement(this.mobileMoneyKey.retraitCodeValide, true);     
;    }

  getRetraitCodeValide(): boolean {
    return this.retraitCodeService
      .getElement(this.mobileMoneyKey.retraitCodeValide).value;
  }

  getTabRetraitCode(): boolean {
    return this.retraitCodeService
       .getElement(this.mobileMoneyKey.appTabsRetraitCode).value;
   }

  resetRetraitCodeForm() {
    this.verifyCodeForm.reset();
    this.loadingCodeForm = false;
  }
  isInvalid() { }

}
