import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Code } from 'src/app/model/code.model';
import { Reclamation } from 'src/app/model/reclamation.model';
import { Transaction } from 'src/app/model/transaction.model';
import { TransactionsService } from 'src/app/services/ServiceEntity/transactions.service';
import { BEFORE_ICON_VALIDATION, BTN_POPUP_CLASS, BTN_VALIDATION_CLASS, BTN_VALID_CLASS } from 'src/app/shared/constant/css-style/css-class.constant';
import { CONSTANT_ICON } from 'src/app/shared/constant/icon.constant';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  templateUrl: './reclamation.component.html',
  styleUrls: ['./reclamation.component.css']
})
export class ReclamationComponent implements OnInit {

  transaction:Transaction;
  code:Code;
  reclamaForm:UntypedFormGroup;
  successSave:boolean;
  erreurSave = false;
  result:boolean;

  constructor( 
    private formService: FormService,
    private fb:UntypedFormBuilder,
    private transactionService: TransactionsService, 
    private dialogRef: MatDialogRef<ReclamationComponent>,
    @Inject(MAT_DIALOG_DATA) data:{transaction:Transaction, code:Code,result:boolean}) { 
     
      this.transaction=data.transaction;
      this.code=data.code;
      this.result= data.result
  }

  ngOnInit(): void {
    this.successSave= false;
    this.reclamaForm=this.fb.group({
      valueMessage: ['', Validators.required]

    })
  }
  getIcon() {
    return CONSTANT_ICON;
  }
  close(){
    this.dialogRef.close();
  }
  sendReclamation(transaction){
    const reclamationN: Reclamation = new Reclamation(
      null,
      this.transaction.id,
      this.reclamaForm.get(['valueMessage']).value,
      null,
      null,
      null,
      null,
      null
    );   
    this.successSave= false;
    this.transactionService.DemandeRoolback(reclamationN).subscribe(
      e => {
        
          this.successSave= true; 
          this.reclamaForm.reset();        
         
       
      },
      error => {
        this.successSave= false;
        this.erreurSave=true;       
        console.error(error);
      }
    );
  
 

  }
  getIconValidation(): string {
    return BEFORE_ICON_VALIDATION;
  }
  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }
  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.reclamaForm);
  }
 

}
