import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { Domain } from '../../../../model/domain.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ILinkDomainColumnName, IStatusColumnName, StatusColumnName } from '../status-column-name';
import { ServiceItemService } from '../../service-items/service-item.service';
import { DomainService } from '../../domain/domain.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_X1_REQUEST } from '../../../../shared/constant/request.contant';
import { StatusModel } from '../../../../model/status.model';
import { StatusService } from '../status.service';

/**
 * Permet de gérer la modification d'un status.
 */
@Component({
  selector: 'app-status-liste',
  templateUrl: './status-update.component.html',
  animations: [fadeIn]
})
export class StatusUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  // le status sélectionné dans la liste des status.
  statusSelected: StatusModel;

  domainList: Domain[] = [];
  init = false; // false, si le component n'est pas affiché.

  theDialogRef: MatDialogRef<StatusUpdateComponent>;
  private subs$ = new Subject<void>();
  editForm: UntypedFormGroup;
  column: IStatusColumnName;

  constructor(
    private statusService: StatusService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<StatusUpdateComponent>,
    private columnName: StatusColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct status add');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init status add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.statusService.getObjetSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: StatusModel) => {
          console.log('## get domain selected to liste');
          try {
            console.log('## data: ', res);
            this.statusSelected = res;
            this.editForm.patchValue({
              [this.column.id]: res.id,
              [this.column.label]: res.label,
              [this.column.description]: res.description
            })
            this.init = true;
          }catch (e) {
            console.log('## error get country selected: ' + e.message);
          }
        });
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  initForm(fb: UntypedFormBuilder, column: IStatusColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.label]: ['', [Validators.required]],
        [column.description]: [''],
      })
    }
  }

  getInit() {
    return this.init;
  }

  /**
   * Enregistre un status {@link StatusModel}
   */
  save() {
    console.log('## save status');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    c.id = this.statusSelected.id;
    console.log('## c: ', c);
    this.statusService.update(c, this.statusService.list_url)
      .subscribe(res => {
          console.log('## success create status');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.statusService.getAllObjet();
        },
        err => {
          console.log('## error create status: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): StatusModel {
    return {
      ...new StatusModel(),
      label: this.editForm.get([this.column.label]).value,
      description: this.editForm.get([this.column.description]).value
    };
  }

  closeModal() {
    console.log('## close formulaire add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }

}
