import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

//import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'src/app/util/request-util';
import { CUser, ICUser } from 'src/app/model/c-user.model';
import { ICuserServiceItem, CuserServiceItem } from 'src/app/model/cuser-service-item.model';
import { BASE_URL, BASE_URL_ADMIN, BASE_URL_PORT } from 'src/environments/environment';
import { IMoney } from '../../model/money.model';
import { ICuserListService } from '../../pages/entities/c-user/services/i-cuser-list.service';
import { UserListFactoryService } from '../../pages/entities/c-user/services/user-list-factory.service';
import { RoleType } from '../../model/account.model';

type EntityResponseType = HttpResponse<ICUser>;
type EntityArrayResponseType = HttpResponse<ICUser[]>;

type EntityResponseType2 = HttpResponse<ICuserServiceItem>;
type EntityArrayResponseType2 = HttpResponse<ICuserServiceItem[]>;

@Injectable({ providedIn: 'root' })
export class CUserService {

  // le role sélectionné dans un select. (ex: trader, partener)
  private _roleSelected: string;

  public user_with_status = BASE_URL_ADMIN + '/c-users/status/get';

  /* Récupère tous les utilisateurs avec leur status.
  * NB: réquière le privilège admin */
  public list_all_with_status = BASE_URL_ADMIN + '/c-users/status';

  public resourceUrl = BASE_URL + 'c-users';

  // change le status d'un utilisateur.
  public url_status_affect = BASE_URL_ADMIN + '/cusers/status/affect';

  public resourceUrlStatus= BASE_URL + 'cusers/status';

  /* liste tous les utilisateurs avec leur status créés
  par l'utilisateur connecté */
  public  get_user_by_user_connected = BASE_URL + 'c-users/status/get';

  public  list_all_by_user_connected = BASE_URL + 'c-users/list/status';

  public resourceListUrl = BASE_URL + 'c-users/list';

  public resourceUrl2 = BASE_URL + 'c-user/userLogin';
  public resourceUrlApiKey = BASE_URL + 'c-user/apikey';
  public resourceUrl3 = BASE_URL + 'c-user/phoneNumber';

  public resourceUrlLinkServiceItem = BASE_URL + 'c-users-service-item';
  public resourceUrlAuthority = BASE_URL + 'Authority/getAuthority';
  public apiChangePassword = BASE_URL + 'account/change-password';
  public apiResetPassword = BASE_URL + 'account/reset-password/init';
  private cuser = new Subject<ICUser[]>();

  constructor(
    protected http: HttpClient) {}


  get roleSelected(): string {
    return this._roleSelected;
  }

  set roleSelected(value: string) {
    this._roleSelected = value;
  }

// l'utilisateur sélectionné dans la liste.
  private icuserSelect = new BehaviorSubject<ICUser>(undefined);

  getAll(r: RoleType, u: UserListFactoryService) {
    setTimeout(() => {
      u.getService(r)
        .loadAll().subscribe(res => {
        console.log('## Success get cuser list');
        this.setIcuserObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  /**
   * Permet de récupérer la liste des money
   * pour être partagée.
   */
  getAllCuserList() {
    setTimeout(() => {
      this.queryListWithStatus().subscribe(res => {
        console.log('## Success get cuser list');
        this.setIcuserObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  /**
   * Permet de récupérer la liste des utilisateurs
   * avec leur status.
   */
  getAllCuserWithStatus() {
    setTimeout(() => {
      this.queryWithStatus().subscribe(res => {
        console.log('## Success get cuser list with status');
        this.setIcuserObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  /**
   * Permet de récupérer la liste des money
   * pour être partagée.
   */
  getAllCuser() {
    setTimeout(() => {
      this.query().subscribe(res => {
        console.log('## Success get cuser list');
        this.setIcuserObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  getIcuserSelectObs() : Observable<ICUser>{
    return this.icuserSelect.asObservable();
  }

  setIcuserSelectObs(c: ICUser) {
    this.icuserSelect.next(c);
  }

  getIcuserObs() {
    return this.cuser.asObservable();
  }

  setIcuserObs(c: ICUser[]) {
    this.cuser.next(c);
  }

  resetPassword(mail: string): Observable<any> {
    return this.http.post(this.apiResetPassword, mail);
  }

  findAllAuthority(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrlAuthority}`, { observe: 'response' });
  }

  create(cUser: ICUser): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrl, cUser, { observe: 'response' });
  }
  updateCUser(cUser: ICUser): Observable<EntityResponseType> {
    return this.http.put<ICUser>(this.resourceUrl, cUser, { observe: 'response' });
  }

  LinkUserServiceItem(cuserServiceItem: ICuserServiceItem): Observable<EntityResponseType2> {
    return this.http.post<ICuserServiceItem>(this.resourceUrlLinkServiceItem, cuserServiceItem, { observe: 'response' });
  }
  getLinkUserServiceItem(id: number): Observable<EntityArrayResponseType2> {
    return this.http.get<ICuserServiceItem[]>(`${this.resourceUrlLinkServiceItem}/${id}`, { observe: 'response' });
  }

  /**
   * Change le status d'un utilisateur.
   * @param option les données
   * option: {
   *   cUserId: id de l'utilisateur de la table c_user
   *   statusId: id du nouveau status
   * }
   */
  updateStatus(option): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.url_status_affect, option,{observe: 'response' });
  }

  updateStatusCUser(option): Observable<EntityResponseType> {
    return this.http.put<ICUser>(this.resourceUrlStatus, {option},{params:option, observe: 'response' });
  }
  

  update(cUser: ICUser): Observable<EntityResponseType> {
    return this.http.put<ICUser>(this.resourceUrl, cUser, { observe: 'response' });
  }
  statutServiceItem(cuserServiceItem: ICuserServiceItem): Observable<EntityResponseType2> {
    return this.http.put<ICuserServiceItem>(this.resourceUrlLinkServiceItem, cuserServiceItem, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ICUser>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findOneByUserLogin(login: string): Observable<EntityResponseType> {
    return this.http.get<ICUser>(`${this.resourceUrl2}/${login}`, { observe: 'response' });
  }

  findOneByApiKey(apikey: string): Observable<EntityResponseType> {
    return this.http.get<ICUser>(`${this.resourceUrlApiKey}/${apikey}`, { observe: 'response' });
  }

  findOneByPhoneNumber(phone: string): Observable<EntityResponseType> {
    return this.http.get<ICUser>(`${this.resourceUrl3}/${phone}`, { observe: 'response' });
  }

  updatePassword(newPassword: string, currentPassword: string): Observable<any> {
    return this.http.post(this.apiChangePassword, { currentPassword, newPassword });
  }

  /**
   * List all CUser with their status created by the connected user.
   * @param req
   */
  getUserWithStatusByUserConnected(req?: any): Observable<EntityResponseType> {
    return this.http.get<ICUser>(this.get_user_by_user_connected,
      { params: req, observe: 'response' });
  }

  /**
   * List all CUser with their status created by the connected user.
   * @param req
   */
  queryListWithStatus(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<ICUser[]>(this.list_all_by_user_connected, { observe: 'response' });
  }

  /**
   * List all CUser create by the same.
   * @param req
   */
  queryList(req?: any): Observable<EntityArrayResponseType> {
    // const options = createRequestOption(req);
   // return this.http.get<ICUser[]>(this.resourceListUrl, { params: options, observe: 'response' });
    return this.http.get<ICUser[]>(this.resourceListUrl, { observe: 'response' });
  }

  /**
   * Récupère tous les utilisateurs avec leur status.
   * NB: réquière le privilège admin
   * @param req
   */
  getUserWithStatus(req?: any): Observable<EntityResponseType> {
    return this.http.get<ICUser>(this.user_with_status,
      { params: req, observe: 'response' });
  }

  /**
   * Récupère tous les utilisateurs avec leur status.
   * NB: réquière le privilège admin
   * @param req
   */
  queryWithStatus(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<ICUser[]>(this.list_all_with_status, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<ICUser[]>(this.resourceUrl, { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
}
