import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { FeesUpdateComponent } from '../../fees/update/fees-update.component';
import { FeesAddComponent } from '../../fees/add/fees-add.component';
import { FeesComponent } from '../../fees';
import { CreateComponent } from '../../fees/create/create.component';
import { ValidatorListComponent } from './validator-list.component';
import { ValidatorAddComponent } from './add/validator-add.component';
import { ValidatorUpdateComponent } from './update/validator-update.component';
import { ValidatorDeleteComponent } from './delete/validator-delete.component';

/**
 * Ce module permet de gérer le model costRule.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [ValidatorListComponent, ValidatorAddComponent,
        ValidatorUpdateComponent, ValidatorDeleteComponent]
})
export class ValidatorModule {}
