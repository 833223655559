import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { Subject } from 'rxjs';
import { CountryColumnName, ICountryColumnName } from '../../countries/country-column-name';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IOption } from '../../../../ui/interfaces/option';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { AccountUserService } from '../../../../services/ServiceEntity/account-user.service';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CostsRuleService } from '../../fees/costs-rule.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BASE_URL } from '../../../../../environments/environment';
import { IMobileAccount } from '../../../../model/mobile-account.model';
import { STATUS } from '../../../../model/account.model';
import {
  CONTAINER_DIALOG_POPUP,
  CONTENT_BTN_CLASS,
  LABEL_FORM_CLASS,
  POPUP_CONTAINER_CLASS,
  SUFFIX_ICON_FORM
} from '../../../../shared/constant/css-style/css-class.constant';
import { TIME_MEDIUM_REQUEST } from '../../../../shared/constant/request.contant';
import { Content } from '../../../../ui/interfaces/modal';
import { ColorService } from '../../../../shared/services/color.service';
import { SelectPrefixService } from '../../../../shared/layouts/select-prefix.service';
import { PRINCIPAL } from '../../../../model/account-ressource.model';

/**
 * Permet à une personne quelconque d'envoyer de l'argent
 * à une autre personne.
 */
@Component({
  selector: 'app-apikey-test',
  templateUrl: './apikey-test.component.html',
  animations: [fadeIn]
})
export class ApikeyTestComponent extends BasePageComponent
  implements OnInit, OnDestroy, SelectPrefixService {

  private subs$ = new Subject<void>();
  column: ICountryColumnName;
  valueApiKey: string;


  transfertCode: UntypedFormGroup;
  loadingMessagesForm: boolean;
  theDialogRef: MatDialogRef<ApikeyTestComponent>;

  CurentUser: any;
  CurentUserCuser: any;
  CurentUserApiKey: any;
  currentMobileAccount: any;
  cUserMobileAccount: any;

  indicatif: string;

  accountNumber: any;
  countrylist: IOption[];
  country: any;
  countries: any;
  SelectedContry: any;

  sentAmount: number;
  countryTrader: string;
  error: string;

  ReponseTransfert: any;
  successTransfert = false;
  errorTransfert = false;
  CountryDestID: any;

  transfertCompteSuccess = false;
  transfertCompteFail = false;
  transfertCompteERROR: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private colorService: ColorService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private formService: FormService,
    private cUserService:CUserService,
    private maccountService:AccountUserService,
    private transactionsService:TransactionsService,
    private dialogRef: MatDialogRef<ApikeyTestComponent>,
    private columnName: CountryColumnName,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private modal: TCModalService,
    private costsRuleService: CostsRuleService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct envoi code');
    this.theDialogRef = this.dialogRef;
    this.loadingMessagesForm = false;
    this.countrylist = [];
    this.initTransfertCode();
  }

  ngOnInit(): void {
    console.log('## init envoi code');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## get account');
        const apikey = this.data.apikeyValue;
        console.log('## apikey: ', apikey);
        this.valueApiKey = apikey;
        this.CurentUser = account;
        console.log('POINT CASH', this.CurentUser);
        this.getCurrentUserApiKey(apikey);
        this.getCurrentCuser();
        this.getData('assets/data/table.json', 'tableData',
          'setLoaded');
        this.getData(`${BASE_URL}countries`, 'countries',
          'initCountry');
      });

  }

  ngOnDestroy() {
    console.log('## destroy envoi code');
    this.formService.free(this.subs$);
  }

  getPrefix(): string {
    return this.indicatif;
  }

  getPrefixModified(): string {
    if(this.indicatif) {
      return '+' +this.indicatif;
    }
    return '';
  }

  initTransfertCode() {
    console.log('## init transfert code form');
    this.transfertCode = this.fb.group({
      numberAccount: [''],
      senderfName: ['', [Validators.required]],
      senderlName: ['', [Validators.required]],
      senderNumTel: ['', [Validators.required]],
      password: ['', [Validators.required]],
      sentAmount: ['', [Validators.required]],
      labelCountry: ['', Validators.required],
      receiverfName: ['', Validators.required],
      receiverlName: ['', Validators.required],
      receiverNumTel: ['', [Validators.required]],
      ReceivedAmount: [],
      principal: [],
      serviceItemId: [102],
      preferenceCurrency: ['XOF']
    });
  }


  /**
   * Récupère l'utilisateur possédant l'api key
   * pour pouvoir effectuer le transfert d'argent.
   * @param apiKey
   */
  getCurrentUserApiKey(apiKey: string) {
    this.cUserService.findOneByApiKey(apiKey).subscribe((response) => {
      this.CurentUserApiKey = response.body;
    }, (err) => {
      console.log(err);
    });
  }

  getCurrentCuser() {
    this.cUserService.findOneByUserLogin(this.CurentUser.login).subscribe((response) => {
      this.CurentUserCuser = response.body;
      console.log('CUSER POINT CASH', this.CurentUserCuser);
      //console.log("Current User",this.CurentUser);
      this.getCurrentMobileAccount();
    }, (err) => {
      console.log(err);
    });
  }

  getCurrentMobileAccount() {
    this.maccountService.findAllAccountsByCUserId(this.CurentUserCuser.id)
      .pipe(
        filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
        map((res: HttpResponse<IMobileAccount[]>) => res.body)
      )
      .subscribe((val: IMobileAccount[]) => {
        console.log('## mobiles accounts: ', val);
        const res = val.filter(m => (m.status === STATUS.actived));
        console.log('## res:', res);
        this.cUserMobileAccount = res['0'];
        //  this.cUserMobileAccount = val['0'];
        this.accountNumber = this.cUserMobileAccount.numberAccount;
        console.log('NUMBER Account', this.accountNumber);
      }, err => {
        console.log(err);
      });
  }

  getPrefixColorSelect(): string {
    return this.colorService.getPrefixColorSelect();
  }

  selectCountry(countrySelected) {
    console.log('## country selected: ', countrySelected);
    for(let c of this.countries) {
      const label = c.label;
      if(label && countrySelected) {
        if(label.toLocaleLowerCase() === countrySelected.toLocaleLowerCase()) {
          console.log('## c_id: ', c.id, ' match e_selected_id: ', countrySelected);
          this.indicatif = c.codePhone;
          console.log('## c_id indicatif: ', this.indicatif);
          break;
        }
      }
    }
  }

  getCountryByLabel(label) {
    this.getData(`${BASE_URL}countries/label/${label}`, 'country', 'initCountryDestID');
  }

  initCountryDestID() {
    setTimeout(() => {
      this.CountryDestID = this.country.mId;
      console.log('### DESt Country ID', this.CountryDestID);
    }, 0);
  }

  copyInputMessage(inputElement){
    console.log('## copy: ', inputElement);
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    console.log('## copy: ', inputElement.value);
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  getContentClass() {
    return CONTENT_BTN_CLASS;
  }

  /**
   * Permet d'effectuer un envoie code.
   * @param forModal
   * @param forfooter
   * @constructor
   */
  envoiCode(forModal: any, forfooter?: any) {
    console.log('## envoie code');
    this.loadingMessagesForm = true;
    this.successTransfert = false;
    this.errorTransfert = false;
    // ADD.
    this.CountryDestID = this.transfertCode.value.labelCountry;
    this.transfertCode.value.principal = PRINCIPAL.ZERO;

    this.transfertCode.value.numberAccount = this.accountNumber;
    console.log('## user details: ',this.CurentUserCuser);
    console.log('## user details api key: ',this.CurentUserApiKey);

    console.log('## user countrid api key: ',this.CurentUserApiKey.countryId);
    console.log('## countrid dest: ',this.CountryDestID);
        setTimeout(
      () => {
        if (this.CurentUserApiKey.countryId === this.CountryDestID) {//transfert National
          console.log('transfert NATIONAL');
          this.transfertCode.value.valueApiKey = this.valueApiKey;
          this.transfertCode.value.serviceItemId = 102;
          console.log('## data', this.transfertCode.value);
          this.transactionsService.transferCodeApikey(this.transfertCode.value)
            .subscribe((resp: any) => {
              let rep = resp.body;
              if(rep.code === 200 ) {
                console.log('### SUCCESS Transfert Code', rep);
                this.manageMessageWhenSucceed(rep.transactionDTO);
                this.showDetailsEnvoieCode(forModal,
                  'Transaction details', null,
                  {
                    closeButton: true,
                    overlayClose: false
                  }); // after 700ms
              } else {
                console.log('ERROR While TRANS');
                this.manageMessageWhenError();
              }
            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        } else {//transfert International api/transactions/transferCodeInter
          console.log('transfert INTERNATIONAL');
          this.transfertCode.value.valueApiKey = this.valueApiKey;
          console.log('CUSER CURRENT', this.CurentUserApiKey.countryId);
          this.transfertCode.value.serviceItemId = 103;
          console.log('## trans inter api key: ', this.transfertCode.value);
          this.transactionsService.transferCodeApikey(this.transfertCode.value)
            .subscribe((resp: any) => {
              let rep = resp.body;
              if(rep.code === 200 ) {
                console.log('### SUCCESS Transfert Code', rep);
                this.manageMessageWhenSucceed(rep.transactionDTO);
                this.showDetailsEnvoieCode(forModal,
                  'Transaction details', null,
                  {
                    closeButton: true,
                    overlayClose: false
                  }); // after 700ms
              } else {
                console.log('ERROR While TRANS');
                this.manageMessageWhenError();
              }
            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        }
      },
      500
    );
  }

  /**
   * Affiche les détails de la transaction
   * lorsqu'elle a réussi.
   * @param modal
   * @param title
   * @param footer
   * @param options
   */
  showDetailsEnvoieCode(modal, title: string, footer, options) {
    console.log('## showDetailsEnvoieCode');
    console.log('## modal: ', modal);
    console.log('## title: ', title);
    setTimeout(() => {
      this.openModal(modal, title, footer, options, POPUP_CONTAINER_CLASS);
    }, TIME_MEDIUM_REQUEST);
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenError() {
    this.successTransfert = false;
    this.loadingMessagesForm = false;
    this.errorTransfert = true;
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenSucceed(response) {
    this.errorTransfert = false;
    this.loadingMessagesForm = false;
    this.successTransfert = true;
    this.ReponseTransfert = response;
    this.transfertCode.reset();
  }

  //Initialiser les Country pour le Select
  initCountry() {
    console.log('## init country');
    setTimeout(() => {
      console.log('COUNTRIES size: ', this.countries?.length);
      this.countrylist = [];
      this.countries?.forEach(element => {
        console.log('## country element: ', element);
        this.countrylist.push({ 'label': element.label, 'value': element.id });
      });
    });
  }

  resetMessagesForm() {
    this.transfertCode.reset();
    this.loadingMessagesForm = false;
  }

  openModal<T>(body: Content<T>, header: Content<T> = null,
               footer: Content<T> = null, options: any = null,
               theClass?: string) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options,
      theClass: theClass
    });
  }

  closeModal() {
    console.log('## close envoi code');
    this.dialogRef.close();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.transfertCode);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.transfertCode, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.transfertCode,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.transfertCode, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.transfertCode, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.transfertCode, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.transfertCode, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.transfertCode, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.transfertCode, column);
  }

}
