import { Injectable } from '@angular/core';

export interface IValidatorAffectedColumnName {
  id: string;
  errorMessage: string;
  validatorValue: string;
  validator: string;
  champsFormulaire: string;
}

@Injectable({ providedIn: 'root' })
export class ValidatorAffectedColumnName {
  getColumnName(): IValidatorAffectedColumnName {
    return {
      id: 'id',
      errorMessage: 'errorMessage',
      validatorValue: 'validatorValue',
      validator: 'validator',
      champsFormulaire: 'champsFormulaire',
    };
  }
}
