import { Component } from '@angular/core';
import { fadeIn } from '../../animations/form-error';
import { DynamicFormModel } from '../../model/champs_formulaire/dynamic-form.model';
import { FormValidator } from '../../model/champs_formulaire/form-validator.model';
import { ValidatorDefaultType } from '../../model/champs_formulaire/validators.model';
import { TypeInputFormModel, TypeInputFormType } from '../../model/champs_formulaire/type-input-form.model';
import {
  CONTENT_BTN_CLASS,
  LABEL_FORM_CLASS,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  SUFFIX_ICON_FORM,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../constant/css-style/css-class.constant';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IOption } from '../../ui/interfaces/option';
import { NameSourceFType } from '../../model/champs_formulaire/source-form.model';
import { DynamicService } from '../../services/dynamic.service';
import { BasePageComponent } from '../../pages/base-page';
import { Store } from '@ngrx/store';
import { IAppState } from '../../interfaces/app-state';
import { HttpService } from '../../services/http/http.service';
import { TIME_MIN_X_REQUEST } from '../constant/request.contant';
import { IBuildingFormByStep, INextForm } from '../../pages/entities/cash-dynamic/dynamic-form.init';
import { LocationType } from '../../model/champs_formulaire/champs-formulaire.model';

/**
 * Contient plusieurs fonctionnalités permettant de faire
 * les champs_formulaires dynamiques.
 */
@Component({
  selector: 'app-base-dynamic',
  templateUrl: './base-dynamic.component.html',
  animations: [fadeIn]
})
export class BaseDynamicComponent extends BasePageComponent {

  constructor(protected dynamicService: DynamicService,
              store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
  }

  /**
   * Copie newData dans data
   * @param newData
   * @param data
   */
  copyData(newData: any, data: any) {
    const d = newData;
    Object.keys(d).forEach(k => {
      console.log('## data key: ' + k + ', val: ' + d[k]);
      data[k] = d[k];
    })
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  /**
   * Permet de charger les éléments du formulaire
   * de l'étape suivante.
   * @param res tous les champs du formulaire.
   * @param b
   */
  buildFormByStep(res: DynamicFormModel[], b?: IBuildingFormByStep) {
    let formList = [];
    if (res.length > 0 && b.lastIndexForm < res.length) {
      let i = b.lastIndexForm;
      b.previousIndexForm = i-1;
      let prev = res[i].formulaire.steps;
      let next;
      for (; i < res.length; i++) {
        next = res[i].formulaire.steps;
        if (prev != next) {
          break;
        } else {
          prev = next;
        }
        formList.push(res[i]);
      }
      if(res.length === b.lastIndexForm) {
        b.lastIndexForm = i-1;
      } else {
        b.lastIndexForm = i;
      }
      b.dynamicFormModels = formList;
    }
  }

  /**
   * Permet de récupérer les éléments du formulaire
   * de l'étape qui suit.
   * Construit les validateurs de tous les éléments du formulaire
   * une fois.
   * @param res tous les champs du formulaire.
   * @param f
   */
  nextForm(res: DynamicFormModel[], f?: INextForm) {
    f.init = false;
    f.dynamicFormModelsDefault = res;
    this.buildFormByStep(res, f.buildingFormByStep);
    this.buildList(f.buildingFormByStep.dynamicFormModels, f.tabOption);
    //  console.log('## build d: ', f.buildingFormByStep.dynamicFormModels)
    if(!f.hasBuiltList) {
      // Construit les validateurs de tous les éléments du formulaire.
      f.formulaire = this.initTransfertCodeDynamic(f.dynamicFormModelsDefault,
        f.formBuilder);

      // ajout du validateur number account
      f.formulaire.addControl('numberAccount', new FormControl('', []))
      f.formulaire.addControl('numberAccountFrom', new FormControl('', []))
      f.formulaire.addControl('numberAccountTo', new FormControl('', []))

      f.hasBuiltList = true;
    }

    console.log('## data models: ', f.buildingFormByStep.dynamicFormModels);

    this.buildAllForm(f.buildingFormByStep);
    setTimeout( r => {
      f.init = true;
    }, TIME_MIN_X_REQUEST)
   // this.buildFromEnum(null);
  }

  /**
   * Permet de construire les éléments du formulaire
   * en fonction de la position (body et header)
   * @param f
   */
  buildAllForm(f: IBuildingFormByStep) {
    let body = [];
    let header = [];
    const formData = f.dynamicFormModels;
    for(let i = 0; i < formData.length; i++) {
      const d = formData[i];
     // console.log('## data models33: ', d.formulaire.locationType.toLowerCase() === LocationType.BODY.toLowerCase());
      const locationTypeLabel = (d.formulaire.locationType as string)
        .toLowerCase();
    const locationType = locationTypeLabel;
    //  const locationType = d.formulaire.locationType.toLowerCase();
      if(locationType === LocationType.BODY.toLowerCase()) {
        body.push(d);
      } else if (locationType === LocationType.HEADER.toLowerCase()) {
        header.push(d);
      }
    }
    f.bodyModels = body;
    f.headerModels = header;

    console.log('## data body: ', body);
    console.log('## data header: ', header);

  }

  getFormDataOld(f: UntypedFormGroup, data: FormData): FormData {
    Object.keys(f.controls).forEach(key => {
      data.set(key, f.value[key]);
    });
    return data;
  }

  getFormData(f: UntypedFormGroup): FormData {
    const data = new FormData();
    Object.keys(f.controls).forEach(key => {
      data.append(key, f.value[key]);
    });
    return data;
  }

  showKeys(d: any) {
    console.log('## keys');
    d.forEach((k, v) => {
      console.log('## keys| ' + k + ' : ' + v);
    });
  }

  /**
   * Permet de construire les options à partir
   * d'une liste d'ennumération.
   * NB: la liste est séparé par des points virgules (;)
   * @param d
   * @Return IOption[] la liste d'option
   */
  buildFromEnum(d: DynamicFormModel) {
    let list: IOption[];
    list = [];
    try {
      let tab = d.formulaire.valeurType.split(';');
      console.log('## tab: ', tab);
      tab?.forEach(element => {
        list.push({ 'label': element, 'value': element });
      });
    } catch (e) {
      console.log('## error: ', e);
    }
    return list;
  }

  /**
   * Permet de construire les options à partir
   * d'une liste des données d'une table.
   * @param d
   */
  initOption(d: DynamicFormModel) {
    const data = d.data;
    let list: IOption[];
    list = [];
    let columnName = '';
    if (d.sourceForms) {
      d.sourceForms.forEach(e => {
        if (e.nameSourceFType.toLowerCase() === NameSourceFType.COLUMN.toLowerCase()) {
          columnName = e.valeur;
          console.log('## columnName: ', columnName);
        }
      });
      data?.forEach(element => {
        // 'id' va permettre de récupérer l'id comme valeur.
        list.push({ 'label': element[columnName], 'value': element['id'] });
      });
    }
    return list;
  }

  /**
   * Permet de construire les options à partir
   * d'une liste de données.
   * @param res le formulaire.
   * @param tab tableau contenant les options.
   */
  buildList(res: DynamicFormModel[], tab: any[]) {
    let i = 0;
    res.forEach(r => {
      if(r.formulaire.valeurType) {
        // construit le formulaire à partir des valeurs d'énumération.
        tab[i] = this.buildFromEnum(r);
      } else {
        // construit le formulaire à partir d'une liste de valeur.
        tab[i] = this.initOption(r);
      }
      i++;
    });
  }

  getMsgErrorLength(forms: FormValidator[], v: ValidatorDefaultType) {
    let msg = '';
    try {
      for (let f of forms) {
        if (f.validator.type === v) {
          msg = f.errorMessage + ' ' + f.validatorValue;
        }
      }
    } catch (e) {
      console.log('## error: ', e);
    }
    return msg;
  }

  getValidatorDefaulType() {
    return ValidatorDefaultType;
  }

  isInputSelect(t: TypeInputFormModel) {
    return t && (t.type === TypeInputFormType.SELECT);
  }

  isInputFile(t: TypeInputFormModel) {
    return t && (t.type === TypeInputFormType.FILE);
  }

  getMsgError(forms: FormValidator[], v: ValidatorDefaultType) {
    let msg = '';
    try {
      for (let f of forms) {
        if (f.validator.type === v) {
          msg = f.errorMessage;
        }
      }
    } catch (e) {
      console.log('## error: ', e);
    }
    return msg;
  }

  isFieldRequired(forms: FormValidator[]) {
    let r = false;
    try {
      for (let f of forms) {
        if (f.validator.type === ValidatorDefaultType.required) {
          r = true;
        }
      }
    } catch (e) {
      console.log('## error: ', e);
    }
    return r;
  }

  formValidator(forms: FormValidator[]) {
    let validators = [];
    for (let f of forms) {
      for (let v in ValidatorDefaultType) {
        if (f.validator && f.validator.type === v) {
          let t;
          if (v === ValidatorDefaultType.required) {
            t = Validators[v];
          } else if (v === ValidatorDefaultType.minLength) {
            t = Validators.minLength(+f.validatorValue);
          } else if (v === ValidatorDefaultType.email) {
            t = Validators[ValidatorDefaultType.email];
          } else if (v === ValidatorDefaultType.maxLength) {
            t = Validators.maxLength(+f.validatorValue);
          } else if (v === ValidatorDefaultType.pattern) {
            t = Validators.pattern(f.validatorValue);
          }
          validators.push(t);
          break;
        }
      }
    }
    return validators;
  }

  initTransfertCodeDynamic(d: DynamicFormModel[],
                           fb: UntypedFormBuilder) {
    console.log('## init transfert code form');
    const dfm = d;
    let validators = {};
    for (let f of dfm) {
      validators[f.formulaire.keyJson] = ['', this.formValidator(f.formValidators)];
    }
    return fb.group(validators);
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  getContentClass() {
    return CONTENT_BTN_CLASS;
  }

  isInvalid(t: UntypedFormGroup): boolean {
    return this.dynamicService.formService.isInvalidForm(t);
  }

  isRequired(column: string, t: UntypedFormGroup): boolean {
    return this.dynamicService.formService.isRequired(t, column);
  }

  isInvalidDirtyOrtouched(column: string, t: UntypedFormGroup): boolean {
    return this.dynamicService.formService.isInvalidDirtyOrTouched(t,
      column);
  }

  getMinLength(column: string, t: UntypedFormGroup): number {
    return this.dynamicService.formService.getMinLength(t, column);
  }

  getMaxLength(column: string, t: UntypedFormGroup): number {
    return this.dynamicService.formService.getMaxLength(t, column);
  }

  hasErrorEmail(column: string, t: UntypedFormGroup): boolean {
    return this.dynamicService.formService.hasErrorEmail(t, column);
  }

  hasErrorPattern(column: string, t: UntypedFormGroup): boolean {
    return this.dynamicService.formService.hasErrorPattern(t, column);
  }

  hasErrorMinLength(column: string, t: UntypedFormGroup): boolean {
    return this.dynamicService.formService.hasErrorMinLength(t, column);
  }

  hasErrorMaxLength(column: string, t: UntypedFormGroup): boolean {
    return this.dynamicService.formService.hasErrorMaxLength(t, column);
  }
}
