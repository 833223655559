import { Injectable } from '@angular/core';
import { IAccountRessType } from '../../../model/acc_ress_type.model';
import { BASE_URL, BASE_URL_ADMIN } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { createRequestOption } from '../../../util/request-util';
import { SItemAccountRessTypeDTO } from '../../../model/service-item.model';

@Injectable({ providedIn: 'root' })
export class AccountRessTypeService {
  public resourceUrl = BASE_URL + 'account-ress-type';
  public resourceUrlAdmin = BASE_URL_ADMIN + '/service-item/account-ressource-type';
  public resourceUrlServiceNonPermisAdmin = BASE_URL_ADMIN + '/service_permis/disable';
  public resourceUrlServicePermisUser = BASE_URL + 'user/service_permis';
  public resourceUrlServicePermisByAdmin = BASE_URL_ADMIN + '/service_permis';

  private accountRessType = new Subject<any[]>();

  constructor(protected http: HttpClient) {}

  getAccountRessTypeObs() {
    return this.accountRessType.asObservable();
  }

  setAccountRessTypeObs(d: SItemAccountRessTypeDTO[]) {
    this.accountRessType.next(d);
  }

  /**
   * Permet de modifier un type de account ressource.
   * @param req
   */
  update(accountRessType: IAccountRessType): Observable<HttpResponse<IAccountRessType>> {
    return this.http.put<IAccountRessType>(this.resourceUrl, accountRessType,
      { observe: 'response' });
  }

  /**
   * Permet de créer un type de account ressource.
   * @param req
   */
  create(accountRessType: IAccountRessType): Observable<HttpResponse<IAccountRessType>> {
    return this.http.post<IAccountRessType>(this.resourceUrl, accountRessType,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des types de account ressource.
   * @param req
   */
  query(req?: any): Observable<HttpResponse<IAccountRessType[]>> {
    const options = createRequestOption(req);
    return this.http.get<IAccountRessType[]>(this.resourceUrl,
      { params: options, observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des types de account ressource.
   * @param req
   */
  listServicePermisByAdmin(req?: any): Observable<HttpResponse<SItemAccountRessTypeDTO[]>> {
    return this.http.get<SItemAccountRessTypeDTO[]>(this.resourceUrlServicePermisByAdmin,
      {params: req, observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des types de account ressource.
   * @param id
   */
  listServicePermisByUser(id?: number): Observable<HttpResponse<SItemAccountRessTypeDTO[]>> {
    if(id) {
      return this.http.get<SItemAccountRessTypeDTO[]>(this.resourceUrlServicePermisUser + '/' + id,
        { observe: 'response' });
    }
    return this.http.get<SItemAccountRessTypeDTO[]>(this.resourceUrlServicePermisUser,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des types de account ressource.
   * @param req
   */
  listServiceNonPermisByCUserId(req?: any): Observable<HttpResponse<SItemAccountRessTypeDTO[]>> {
    return this.http.get<SItemAccountRessTypeDTO[]>(this.resourceUrlServiceNonPermisAdmin,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des types de account ressource.
   * @param req
   */
  queryByAccountUser(req?: any): Observable<HttpResponse<SItemAccountRessTypeDTO[]>> {
    return this.http.get<SItemAccountRessTypeDTO[]>(this.resourceUrlAdmin,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de recuperer un type de account ressource.
   * @param req
   */
   findOne(id: number): Observable<HttpResponse<IAccountRessType>> {
    return this.http.get<IAccountRessType>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }

}
