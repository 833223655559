
export interface IICon {
  add: string;
  show: string;
  delete: string;
  update: string;
  doc: string;
  ok: string;
  notShow: string;
  credit?: string;
  login?: string;
}

export const CONSTANT_ICON: IICon = {
  add: "sli-plus",
  show: "sli-eye",
  delete: "sli-close",
  update: "sli-pencil",
  doc: "sli-doc",
  ok: "sli-ok",
  credit: "sli-credit-card",
  login: "sli-login",
  notShow: "sli-key"
}
