import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService } from '../../ServiceEntity/account.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account } from '../../../model/account.model';
import { GuardService } from './guard.service';

/**
 * Permet de vérifier si un utilisateur est authentifié
 * à fin de pouvoir décider si on lui donne accès aux routes enfants.
 */
@Injectable({
  providedIn: 'root'
})
export class GuardAuthChildService implements CanActivateChild {
  constructor(
    private guardService: GuardService
  ) {

  }

  /**
   * Si l'utilisateur est authentifié, alors on lui donne
   * accès aux routes enfants.
   * @param route
   * @param state
   * @Return true si l'utilisateur est authentifié,
   * false dans le cas contraire et il sera redirigé
   * vers la page d'authentification.
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardService.isAuthenticate(route, state);
  }

}
