import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IOption } from '../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IMoneyColumnName, MoneyColumnName } from '../../monies/money-column-name';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MoniesService } from '../../monies/monies.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { ICountry } from '../../../../model/country.model';
import { IMoney, Money } from '../../../../model/money.model';
import { IBodyFormat, IRequestType, IServiceItemColumnName, ServiceItemColumnName } from '../service-item-column-name';
import { ServiceItemService } from '../service-item.service';
import { BodyType, IServiceItem, ServiceItem } from '../../../../model/service-item.model';
import { AccountRessTypeService } from '../../acc-ress-type/account-ress-type.service';
import { AccountRessType, IAccountRessType } from '../../../../model/acc_ress_type.model';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { Observable, Subject } from 'rxjs';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { BaseService } from '../../base/base.service';
import { ServiceItemImageComponent } from '../image/service-item-image.component';
import { SIZE_H_MD_POPUP, SIZE_W_MD_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { Formulaire } from '../../../../model/champs_formulaire/formulaire.model';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';
import { FormulaireService } from '../../formulaires/formulaire.service';

/**
 * Permet de gérer l'ajout des service items.
 */
@Component({
  selector: 'app-service-item-add',
  templateUrl: './service-item-add.component.html',
  animations: [fadeIn]
})
export class ServiceItemAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  accountRessTypes: IOption[] = [];
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ServiceItemAddComponent>;

  private subs$ = new Subject<void>();
  serviceItem: IOption[] = [];
  editForm: UntypedFormGroup;
  column: IServiceItemColumnName;
  requestType: IOption[] = [];
  bodyFormat: IOption[] = [];

  // service item.
  serviceItemSelected: Formulaire;
  serviceItemsList: Formulaire[] = [];
  filteredOptions: Observable<Formulaire[]>;

  constructor(
    private formulaireService: FormulaireService,
    private dialog: MatDialog,
    private baseService: BaseService,
    private accountService: AccountService,
    protected accountRessTypeService: AccountRessTypeService,
    private dialogRef: MatDialogRef<ServiceItemAddComponent>,
    private serviceItemService: ServiceItemService,
    private formService: FormService,
    private columnName: ServiceItemColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct service item add');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    const bodyFormat = this.columnName.getBodyFormat();
    Object.keys(bodyFormat).forEach((m: string) => {
      this.bodyFormat.push({
        ...new Option(),
        label: bodyFormat[m] ? bodyFormat[m].toLocaleUpperCase() : '',
        value: bodyFormat[m]
      })
    })
    const requestType = this.columnName.getRequestType();
    Object.keys(requestType).forEach((m: string) => {
      this.requestType.push({
        ...new Option(),
        label: requestType[m] ? requestType[m].toLocaleUpperCase() : '',
        value: requestType[m]
      })
    })
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init service item add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.getAllAccRessType();
        this.getServiceItemList();
        super.setLoaded();
      })
  }

  ngOnDestroy() {
    console.log('## destroy service item add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  getServiceItemList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.formulaireService
        .query(this.formulaireService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<Formulaire[]>) => mayBeOk.ok),
          map((response: HttpResponse<Formulaire[]>) => response.body)
        )
        .subscribe((res: Formulaire[]) => {
            console.log('## success get list formulaire while adding.');
            const rs = res ? res : [];
            console.log('## data: ', rs);
            console.log('## size: ' + rs.length);
            this.serviceItemsList = rs;
            this.filteredOptions = this.editForm.get(this.column.formulaire).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list formulaire while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  private _filter(value: string): Formulaire[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option?.titre?.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter champs formulaire: ' + e.message);
    }
    return filteredList;
  }

  upload() {
    console.log('## open service');
   // this.baseService.open(this.dialog, ServiceItemImageComponent);
    this.dialog.open(ServiceItemImageComponent, {
      height: SIZE_H_MD_POPUP,
      width: SIZE_W_MD_POPUP,
      data: {
        "editForm": this.editForm
      }
    });
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getAllAccRessType() {
    setTimeout(() => {
      this.accountRessTypeService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IAccountRessType[]>) => mayBeOk.ok),
          map((response: HttpResponse<IAccountRessType[]>) => response.body)
        )
        .subscribe((res: IAccountRessType[]) => {         
          let acc = (res) ? res : [];
          if(res){
            acc=res;
            this.accountRessTypes=[];
            acc.forEach(a => {
              this.accountRessTypes.push({
                ...new Option(),
                label: (a.type) ? a.type : '',
                value: (a.id) ? a.id.toString() : undefined
              })
            })
          }else{
            acc=[];
            this.accountRessTypes=[];
            this.accountRessTypes.push({
              ...new Option(),
              label: 'No account ressource type',
              value: ''
            })

          }

         
         /*  this.accountRessTypes.push({
            ...new Option(),
            label: 'No account ressource type',
            value: ''
          })
          console.log('## success get acc ress type list by add afterrrrr',  this.accountRessTypes);
          acc.forEach(a => {
            this.accountRessTypes.push({
              ...new Option(),
              label: (a.type) ? a.type : '',
              value: (a.id) ? a.id.toString() : undefined
            })
          }) */
           
         
        }),
        (res: HttpErrorResponse) => {
          console.log('## error get acc ress type list: ' +res.message);
        };
    }, 500)
  }

  initForm(fb: UntypedFormBuilder, column: IServiceItemColumnName) {
    try {
      this.editForm = this.fb.group({
        [column.id]: [],
        [column.name]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        [column.description]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(100)]
        ],
        [column.serviceItemId]: ['', [Validators.required, Validators.minLength(1),
          Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
        [column.accountRessTypeId]: [''],
        ['formulaire']: [],
        ['type']: [],
        [column.accountRessTypeName]: [''],
        [this.column.bodyFormat]: [null],
        [this.column.requestType]: [null, [Validators.required]],
        [this.column.url]: [null, [Validators.required]],
        [this.column.bodyType]: [null],
        [this.column.bodyContent]: [null],
        'image': [],
        'formsOrder': [1],
        'file': []

      });

    }catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  showKeys(d: any) {
    console.log('## keys');
    d.forEach((k, v) => {
      console.log('## keys| ' + k + ' : ' + v);
    });
  }

  displayFnFormulaire(subject: Formulaire) {
    return subject ? subject.titre : '';
  }

  save() {
    console.log('## save service item');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const f = this.createFormData();
    this.showKeys(f);

    this.serviceItemService.createWithImage(f).subscribe(res => {
        console.log('## success create service item: ',res);

        const dataForm = {
          serviceItemId: res.body.id,
          formsOrder: this.editForm.get([this.column.formsOrder]).value,
          formulaireId: this.editForm.get([this.column.formulaire]).value?.id
        }

        console.log('## dataform: ', dataForm);
      if(dataForm.formulaireId) {
        this.formulaireService.create(dataForm,
          this.formulaireService.resourceServiceUrl)
          .subscribe(res => {
            console.log('success link formulaire: ', res);
            this.closeModal();
          }, error => {
            console.log('error link formulaire: ', error);
          })
      }


        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.serviceItemService.getAllServiceItem();
      },
      err => {
        console.log('## error create service item');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  /**
   * Enregistre un type de account ressource.
   */
/*  save() {
    console.log('## save service item');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const accId = this.editForm.get(['accountRessTypeId']);
    let c: IServiceItem;
    if(accId && accId.value && accId.value.toString() !== '') {
      console.log('## create form with acc ress type');
      c = this.createFromFormWithActRessType();
    } else {
      console.log('## create form without acc ress type');
      c = this.createFromForm();
    }
    console.log('## c: ', c);
    this.serviceItemService.create(c).subscribe(res => {
        console.log('## success create service item');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.serviceItemService.getAllServiceItem();
      },
      err => {
        console.log('## error create service item');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }*/

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): IServiceItem {
    const bodyType: string =  this.editForm.get(['body_type']).value;
    const bodyContent: string = this.editForm.get(['body_content']).value;
    return {
      ...new ServiceItem(),
      id: this.editForm.get(['id']).value,
      name: this.editForm.get(['name']).value,
      description: this.editForm.get(['description']).value,
      serviceItemId: this.editForm.get(['serviceItemId']).value,
      url: (this.editForm.get(['url']).value as string).toString().trim(),
      bodyFormat: this.editForm.get(['body_format']).value,
      image: this.editForm.get(['image']).value,
      bodyContent: bodyContent ? bodyContent.toString().trim() : null,
      bodyType: bodyType
        ? {
          ...new BodyType(),
          field: bodyType.toString().trim()
        }
        : null,
      requestType: this.editForm.get(['request_type']).value
    };
  }

  private createFormData(): any{
    let bodyType: string =  this.editForm.get(['body_type']).value;
    bodyType = bodyType ? bodyType.toString().trim() : bodyType;
    let bodyContent: string = this.editForm.get(['body_content']).value;
    bodyContent = bodyContent ? bodyContent.toString().trim() : bodyContent;
    let url = (this.editForm.get(['url']).value as string);
    url = url ? url.toString().trim() : url;

    const f = new FormData();
    f.set('file', this.editForm.get(['file']).value);
    f.set('id', this.editForm.get(['id']).value);
    f.set('name', this.editForm.get(['name']).value);
    f.set('description', this.editForm.get(['description']).value);
    f.set('serviceItemId', this.editForm.get(['serviceItemId']).value);
    f.set('accountRessTypeId', this.editForm.get(['accountRessTypeId']).value);
    f.set('url', url);
    f.set('type', this.editForm.get(['type']).value);
    f.set('bodyFormat', this.editForm.get(['body_format']).value);
    f.set('formulaire', this.editForm.get(['formulaire']).value);
    f.set('image', this.editForm.get(['image']).value);
    f.set('bodyContent', bodyContent);
    f.set('bodyType', bodyType);
    f.set('requestType', this.editForm.get(['request_type']).value);
    return f;
  }

  private createFromFormWithActRessType(): IServiceItem {
    const bodyType: string =  this.editForm.get(['body_type']).value;
    const bodyContent: string = this.editForm.get(['body_content']).value;
    return {
      ...new ServiceItem(),
      id: this.editForm.get(['id']).value,
      name: this.editForm.get(['name']).value,
      description: this.editForm.get(['description']).value,
      serviceItemId: this.editForm.get(['serviceItemId']).value,
      accountRessTypeId: this.editForm.get(['accountRessTypeId']).value,
//      accountRessTypeName: this.editForm.get(['accountRessTypeName']).value,
      url: (this.editForm.get(['url']).value as string).toString().trim(),
      bodyFormat: this.editForm.get(['body_format']).value,
      image: this.editForm.get(['image']).value,
      bodyContent: bodyContent ? bodyContent.toString().trim() : null,
      bodyType: bodyType
        ? {
          ...new BodyType(),
          field: bodyType.toString().trim()
        }
        : null,
      requestType: this.editForm.get(['request_type']).value
    };
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }
  reset(){
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
