export const TIME_MIN_X1_REQUEST = 100;
export const TIME_MIN_X_REQUEST = 200;
export const TIME_MIN_X2_REQUEST = 300;
export const TIME_MIN_REQUEST = 500;

export const TIME_MEDIUM_REQUEST = 700;
export const TIME_LONG_REQUEST = 1500;
export const TIME_VERY_LONG_REQUEST = 2000;
export const TIME_FITH_REQUEST = 5000;

export const TOKEN = 'tokenUser';
export const TOKEN_USERNAME = 'username';

export const STATUS_OK = 200;
