import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { AccountRessType, IAccountRessType } from '../../../../model/acc_ress_type.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../acc-ress-type-column-name';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { AccountRessTypeService } from '../account-ress-type.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { ConstantAccRessType } from '../acc-ress-type.url';
import { ConstantHome } from '../../home/home.url';
import { Url } from '../../../../shared/constant/url.constant';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Permet de gérer l'ajout des types de account ressource.
 */
@Component({
  selector: 'app-accounts-ressource-type-liste',
  templateUrl: './acc-ress-type-update.component.html',
  animations: [fadeIn]
})
export class AccountsRessourceTypeUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  isSaving = false;
  idColumn: string;
  typeColumn: string;
  successSave = false;
  serverHasError = false;
  @Output('addList') accRessTypeList = new EventEmitter<IAccountRessType[]>();
  @Output('editFormUpdate') editFormUpdate = new EventEmitter<UntypedFormGroup>();
  @Output('updateRef') updateRefOut = new EventEmitter<any>();
  @ViewChild('updateRef') updateRef: any;
  @Input('data') data: IAccountRessType;

  private subs$ = new Subject<void>();
  editForm = this.fb.group({
    id: [],
    type: [null, [Validators.required]]
  });


  constructor(
    private accountService: AccountService,
    private formService: FormService,
    private columnName: AccRessTypeColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private accountRessTypeService: AccountRessTypeService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct account ressource type add');
    const column = this.columnName.getColumnName();
    this.typeColumn = column.type;
    this.idColumn = column.id;
  }

  ngOnInit(): void {
    console.log('## init account ressource type liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {})
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy acc ress type ressource liste');
    super.ngOnDestroy();
    this.formService.free(this.subs$);
    this.successSave = false;
  }


  /**
   * Enregistre un type de account ressource.
   */
  update() {
    console.log('## liste');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const acc: IAccountRessType = this.createFromForm();
    console.log('## acc: ' + JSON.stringify(acc));
    this.accountRessTypeService.update(acc).subscribe(res => {
        console.log('## success liste acc ress type');
        this.serverHasError = false;
        this.isSaving = false;
        this.successSave = true;
        this.editForm.get(this.typeColumn).markAsPristine();
        this.accountRessTypeService.query().subscribe(res => {
          console.log('## Success get account_ress_type list by liste ');
          this.accRessTypeList.emit((res.body) ? res.body : []);
        });
      },
      err => {
        console.log('## error liste acc ress type');
        this.isSaving = false;
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  private createFromForm(): IAccountRessType {
    return {
      ...new AccountRessType(),
      id: this.editForm.get([this.idColumn]).value,
      type: this.editForm.get([this.typeColumn]).value
    };
  }

  closeModal() {
    console.log('## close modal liste');
    this.successSave = false;
    this.serverHasError = false;
    this.modal.close();
    this.editForm.reset();
  }

  /**
   * Permet de renvoyer le template add type account
   * ressource.
   */
  ngAfterViewInit(): void {
    console.log('## afterViewInit acc ress type liste');
    this.updateRefOut.emit(this.updateRef);
    this.editFormUpdate.emit(this.editForm);
  }

  isPristine() {
    return this.editForm.get(this.typeColumn).pristine;
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  ngAfterContentInit(): void {
    console.log('## init');
  }
}
