import { Routes } from '@angular/router';
import { ConstantMonies } from '../monies/monies.url';
import { VerticalLayoutComponent } from '../../../layout/vertical';
import { MoniesComponent } from '../monies';
import { ConstantServiceItems } from './service-items.url';
import { ServiceItemsComponent } from './service-items.component';

/**
 * Ensemble des routes pour les service items.
 */
export const SERVICE_ITEMS_ROUTE: Routes = [
  {
    path: ConstantServiceItems.url.SERVICE_ITEMS.SERVICE_ITEMS_,
    children: [
      {
        path: '',
        redirectTo: ConstantServiceItems.url.SERVICE_ITEMS.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantServiceItems.url.SERVICE_ITEMS.LIST,
        component: ServiceItemsComponent
      }
    ]
  }
];
