import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { ValidatorListComponent } from '../validators/validator-list.component';
import { ValidatorAddComponent } from '../validators/add/validator-add.component';
import { ValidatorUpdateComponent } from '../validators/update/validator-update.component';
import { ValidatorDeleteComponent } from '../validators/delete/validator-delete.component';
import { ChampsFormulaireListComponent } from './champs-formulaire-list.component';
import { ChampsFormulaireAddComponent } from './add/champs-formulaire-add.component';
import { ChampsFormulaireUpdateComponent } from './update/champs-formulaire-update.component';
import { ChampsFormulaireDeleteComponent } from './delete/champs-formulaire-delete.component';
import { FormulaireAddValidatorComponent } from './add/validator/formulaire-add-validator.component';
import { SourceFormAddComponent } from './add/source-form/source-form-add.component';

/**
 * Ce module permet de gérer le model costRule.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [ChampsFormulaireListComponent, SourceFormAddComponent,
        FormulaireAddValidatorComponent,
        ChampsFormulaireAddComponent, ChampsFormulaireUpdateComponent,
        ChampsFormulaireDeleteComponent]
})
export class ChampsFormulaireModule {}
