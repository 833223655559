import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CostsService } from '../../costs/costs.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { Costs, ICosts } from '../../../../model/costs.model';
import { CostsDetailsService } from '../costs-details.service';
import { CostsDetails, ICostsDetails } from '../../../../model/costs-details.model';
import { Formulaire } from '../../../../model/champs_formulaire/formulaire.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';

/**
 * Permet de gérer l'ajout des détails de coûts.
 */
@Component({
  selector: 'app-costs-details-add',
  templateUrl: './costs-details-add.component.html',
  animations: [fadeIn]
})
export class CostsDetailsAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<CostsDetailsAddComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;

  // informations sur les coûts.
  costsSelected: Costs;
  costsList: Costs[] = [];
  filteredOptions: Observable<Costs[]>;

  constructor(
    private costsService: CostsService,
    private costsDetailsService: CostsDetailsService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<CostsDetailsAddComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct costs details add');
    this.theDialogRef = this.dialogRef;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init costs details add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
        this.getCostsList();
      });
  }

  ngOnDestroy() {
    console.log('## destroy costs add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  initForm(fb: UntypedFormBuilder) {
    if (fb) {
      this.editForm = fb.group({
        'label': ['', [Validators.required]],
        'costs': ['', [Validators.required]]
      })
    }
  }

  displayFnCosts(subject: ICosts) {
    return subject ? subject.label : '';
  }

  /**
   * Permet de récupérer la liste des coûts
   */
  getCostsList() {
    setTimeout(() => {
      this.costsService
        .query(this.costsService.typesUrl)
        .pipe(
          filter((mayBeOk: HttpResponse<Costs[]>) => mayBeOk.ok),
          map((response: HttpResponse<Costs[]>) => response.body)
        )
        .subscribe((res: Costs[]) => {
            console.log('## success get list costs while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.costsList = rs;
            this.filteredOptions = this.editForm.get('costs').valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list costs while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  /**
   * Permet de faire des recherches sur les coûts.
   * @param value la valeur à rechercher
   * @private
   */
  private _filter(value: string): Costs[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.costsList.filter(option => option?.label?.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter costs: ' + e.message);
    }
    return filteredList;
  }

  /**
   * Enregistre un détails de coût.
   */
  save() {
    console.log('## save costs details');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.costsDetailsService.create(c, this.costsDetailsService.typesUrl)
      .subscribe(res => {
          console.log('## success create costs details');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.closeModal();
          this.costsDetailsService.getAllObjet();
        },
        err => {
          console.log('## error create costs details: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): ICostsDetails {
    return {
      ...new CostsDetails(),
      label: this.editForm.get('label').value,
      typeId: (this.editForm.get('costs').value as ICosts).id
    };
  }

  closeModal() {
    console.log('## close costs add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}
