import { IServiceItem } from '../service-item.model';
import { LocationTypeModel, TypeInputFormModel } from './type-input-form.model';
import { Formulaire } from './formulaire.model';

export enum TypeForm {
  STRING, NUMBER, ENUM, ANY
}

export enum SourceType {
  DATABASE, API, NONE
}

export enum LocationType {
  HEADER = 'header', BODY = 'body'
}

export class ChampsFormulaire {
  constructor(
    public id?: number,
    public label?: string,
    public keyJson?: string,
    public code?: string,
    public steps?: number,
    public formsOrder?: number,
    public locationType?: LocationTypeModel,
    public locationTypeId?: number,
    public type?: TypeForm,
    public sourceType?: SourceType,
    public typeInputForm?: TypeInputFormModel,
    public typeInputFormId?: string,
    public valeurType?: string,
    public comment?: string,
    public placeholder?: string,
    public serviceItem?: IServiceItem,
    public formulaire?: Formulaire,
    public formulaireId?: number,
    public serviceItemName?: string,
    public serviceItemId?: string,
  ) {}
}