
import { SharedModule } from '../../../shared.module';
import { NgModule } from '@angular/core';
import { StatusListComponent } from './status-list.component';
import { StatusAddComponent } from './add/status-add.component';
import { StatusUpdateComponent } from './update/status-update.component';
import { StatusUpdateLinkComponent } from './update-link/status-update-link.component';

/**
 * Ce module permet de gérer le model {@link StatusModel}.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [StatusListComponent, StatusUpdateComponent,
        StatusUpdateLinkComponent, StatusAddComponent]
})
export class StatusModule {}
