import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Code } from 'src/app/model/code.model';
import { Reclamation } from 'src/app/model/reclamation.model';
import { Transaction } from 'src/app/model/transaction.model';
import { TransactionsService } from 'src/app/services/ServiceEntity/transactions.service';
import { BEFORE_ICON_VALIDATION, BTN_POPUP_CLASS, BTN_VALIDATION_CLASS } from 'src/app/shared/constant/css-style/css-class.constant';
import { CONSTANT_ICON } from 'src/app/shared/constant/icon.constant';
import { ConfirmReclamationComponent } from '../confirm-reclamation/confirm-reclamation.component';

@Component({
  templateUrl: './roll-back-reclamation.component.html',
  styleUrls: ['./roll-back-reclamation.component.css']
})
export class RollBackReclamationComponent implements OnInit {
  transaction: Transaction;
  code: Code;
  result: boolean;
  justificatifForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private transactionService: TransactionsService,
    private dialogRef: MatDialogRef<RollBackReclamationComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: { transaction: Transaction, code: Code, result: boolean }) {

    this.transaction = data.transaction;
    this.code = data.code;
    this.result = data.result
    
  }
  getIcon() {
    return CONSTANT_ICON;
  }
  close() {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.justificatifForm = this.fb.group({
      valueJustificatif: ['', Validators.required]
    })
  }
  PendingToSuccess() {
    const reclamationN: Reclamation = new Reclamation(
      null,
      this.transaction.id,
      null,
      null,
      null,
      this.justificatifForm.get(['valueJustificatif']).value,
      null,
      null
    );
   
      let title = "Vous allez effectuer un Roolback, Cette action annulera la transaction !";
      let message="Transaction Marqué Success"; 
      this.transactionService.FinishReclamation(reclamationN).subscribe(e => { });
      this.transactionService.roolbackPenddingToSuccess(this.transaction).subscribe(
        reset => {
          this.justificatifForm.reset();
          //this.transactionService.getAllTransactions(this.numberAccount);
          this.dialog.open(ConfirmReclamationComponent, {
            data: { transaction: this.transaction, title: title, message: message }, height: '200px',
            width: '700px'
          });
         /*  setTimeout(() => {
            alert('Transaction Marqué Success');
            this.justificatifForm.reset();
            this.transactionService.getAllTransactions(this.numberAccount);           
          }, 3000); */
        },
        error => {

          console.error(error);
        }
      );
    
  }

  PendingToFailled() {
    const reclamationN: Reclamation = new Reclamation(
      null,
      this.transaction.id,
      null,
      null,
      null,
      this.justificatifForm.get(['valueJustificatif']).value,
      null,
      null
    );
    
      let title = "Vous allez effectuer un Roolback, Cette action annulera la transaction !";
      let message="Transaction Marqué Failled"; 
      this.transactionService.FinishReclamation(reclamationN).subscribe(e => { });
      this.transactionService.roolbackPenddingToFailled(this.transaction).subscribe(
        reset => {
          this.justificatifForm.reset();
         // this.transactionService.getAllTransactions(this.numberAccount);
          this.dialog.open(ConfirmReclamationComponent, {
            data: { transaction: this.transaction, title: title, message: message }, height: '200px',
            width: '700px'
          });
      /*     setTimeout(() => {

            alert('Transaction Marqué Failled');
            this.justificatifForm.reset();
            this.transactionService.getAllTransactions(this.numberAccount);         
          }, 3000); */
        },
        error => {

          console.error(error);
        }
      );
    
  }

  FailledToPending() {
    const reclamationN: Reclamation = new Reclamation(
      null,
      this.transaction.id,
      null,
      null,
      null,
      this.justificatifForm.get(['valueJustificatif']).value,
      null,
      null
    );
    let title = "Vous allez effectuer un Roolback, Cette action annulera la transaction !";
    let message="Transaction Marqué PENDING";   

    this.transactionService.takeToInProgress(reclamationN).subscribe(e => { });
    this.transactionService.roolbackFailledToPendding(this.transaction).subscribe(
      reset => {
        this.justificatifForm.reset();
        //this.transactionService.getAllTransactions(this.numberAccount);
        this.dialog.open(ConfirmReclamationComponent, {
          data: { transaction: this.transaction,title: title, message: message }, height: '200px',
          width: '700px'
        });
        /*  setTimeout(() => {
           
           alert('Transaction Marqué PENDING');
           this.justificatifForm.reset();
           this.transactionService.getAllTransactions(this.numberAccount);
           
           
         }, 3000); */
      },
      error => {

        console.error(error);
      }
    );

  }

  SuccessToPending() {
    const reclamationN: Reclamation = new Reclamation(
    null, this.transaction.id, 'null', null, null, null, null, null);
    let title = "Vous allez effectuer un Roolback, Cette action annulera la transaction !";
    let message="Transaction Marqué PENDING"; 
    this.transactionService.takeToInProgress(reclamationN).subscribe(e => { });
    this.transactionService.roolbackSuccessToPendding(this.transaction).subscribe(
      reset => {
        this.justificatifForm.reset();
        //this.transactionService.getAllTransactions(this.numberAccount);
        console.log('success to pending ', this.transaction);
        this.dialog.open(ConfirmReclamationComponent, {
          data: { transaction: this.transaction,title: title,message: message }, height: '200px',
          width: '700px'
        });
        /*  setTimeout(() => {            
           alert('Transaction Marqué PENDING');
           this.justificatifForm.reset();
           this.transactionService.getAllTransactions(this.numberAccount);            
         }, 3000); */
      },
      error => {

        console.error(error);
      }
    );

  }

  getIconValidation(): string {
    return BEFORE_ICON_VALIDATION;
  }
  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }
  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

}
