import { Injectable } from '@angular/core';
import { ROLE, ROLE_NAME, RoleType } from '../../model/account.model';

/**
 * Permet d'effectuer des contrôles sur les rôles utilisateurs.
 */
@Injectable({ providedIn: 'root' })
export class RoleService {
  constructor() {
  }

  /**
   * Vérifier si l'utilisateur possède un role.
   * @param role le role à vérifier
   * @return le role de l'utilisateur.
   */
  getFromRoleType(role: string[]): RoleType {
    if(role.indexOf(RoleType.ROLE_ADMIN) !== -1) {
      return RoleType.ROLE_ADMIN;
    } else if(role.indexOf(RoleType.ROLE_PARTENER) !== -1) {
      return RoleType.ROLE_PARTENER;
    } else if(role.indexOf(RoleType.ROLE_TRADER) !== -1) {
      return RoleType.ROLE_TRADER;
    }
    return RoleType.NONE;
  }

  /**
   * Permet de renvoyer le role d'un utilisateur.
   * @param role
   */
  getRoleType(role: string) {
    if(role === RoleType.ROLE_ADMIN) {
      return RoleType.ROLE_ADMIN;
    } else if(role === RoleType.ROLE_PARTENER) {
      return RoleType.ROLE_PARTENER;
    } else if(role === RoleType.ROLE_TRADER) {
      return RoleType.ROLE_TRADER;
    }
    return RoleType.NONE;
  }

  getTheRole(authorities: string[]): string {
    let role = 'none';
    if(authorities.indexOf(ROLE.partener) !== -1) {
      role = ROLE_NAME.partener;
    } else if(authorities.indexOf(ROLE.trader) !== -1) {
      role = ROLE_NAME.trader;
    } else if(authorities.indexOf(ROLE.admin) !== -1) {
      role = ROLE_NAME.admin;
    }
    return role;
  }

  getRole(authority: string): string {
    let role = 'none';
    if (this.canPartenerAccess(authority)) {
      role = ROLE_NAME.partener;
    } else if (this.canAdminAccess(authority)) {
      role = ROLE_NAME.admin;
    } else if (this.canTraderAccess(authority)) {
      role = ROLE_NAME.trader;
    }
    return role;
  }

  canTraderAccess(authority: string) {
    return authority === ROLE.trader;
  }

  canPartenerAccess(authority: string) {
    return authority === ROLE.partener;
  }

  canAdminAccess(authority: string) {
    return authority === ROLE.admin;
  }

}
