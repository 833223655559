import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { ChampsFormulaire } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { Subject } from 'rxjs';
import { BaseService } from '../../base/base.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../../../shared/services/form.service';
import { ChampsFormulaireColumnName } from '../../champs_formulaires/champs_formulaires/champs-formulaire-column-name';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { Formulaire } from '../../../../model/champs_formulaire/formulaire.model';
import { FormulaireService } from '../formulaire.service';
import { FormulaireColumnName } from '../formulaire-column-name';

/**
 * Permet de gérer la suppression d'un country
 */
@Component({
  selector: 'app-formulaire-delete',
  templateUrl: './formulaire-delete.component.html',
  animations: [fadeIn]
})
export class FormulaireDeleteComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  countrySelected: Formulaire;
  private subs$ = new Subject<void>();
  successDelete = false;
  serverHasError = false;
  hasDelete = false;
  isDeleting = false;

  constructor(
    private baseService: BaseService,
    private formulaireService: FormulaireService,
    private dialogRef: MatDialogRef<FormulaireDeleteComponent>,
    private formService: FormService,
    private columnName: FormulaireColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct formulaire delete');
  }

  ngOnInit(): void {
    console.log('## init formulaire delete.');
    this.formulaireService.getObjetSelectObs()
      .pipe(takeUntil(this.subs$)).subscribe((res) => {
      console.log('## get formulaire selected');
      try {
        console.log('## data: ', res);
        this.countrySelected = res;
      } catch (e) {
        console.log('## error get formulaire delete: ' + e.message);
      }
      super.setLoaded();
    });
  }

  ngOnDestroy() {
    console.log('## destroy formulaire delete');
    this.formService.free(this.subs$);
    this.formulaireService.setObjetSelectObs(undefined);
  }

  closeModal() {
    console.log('## close delete formulaire');
    this.dialogRef.close();
  }

  delete() {
    console.log('## deleting formulaire');
    this.isDeleting = true;
    this.serverHasError = false;
    this.successDelete = false;
    const id = this.countrySelected.serviceFormulaireId ?
      this.countrySelected.serviceFormulaireId: this.countrySelected.id;
    console.log('## id: ', id);

    let api;
    if(this.countrySelected.serviceFormulaireId) {
      api = this.formulaireService.resourceServiceUrl;
    } else {
      api = this.formulaireService.delete_url;
    }

    this.formulaireService.deleteByServiceFormulaireId(api + `/${id}`)
      .subscribe(
      response => {
        console.log('## success delete country');
        this.serverHasError = false;
        this.successDelete = true;
        this.isDeleting = false;
        this.hasDelete = true;
        this.responseFromServer();
        this.formulaireService.getAllObjet();
      }, error => {
        console.log('## error delete country');
        this.serverHasError = true;
        this.successDelete = false;
        this.isDeleting = false;
        this.responseFromServer();
      });
  }

  private responseFromServer() {
    setTimeout(() => {
      this.isDeleting = false;
    }, 300);
  }
}