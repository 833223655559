import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les Account ressource.
 */
export namespace ConstantAccountRessource {
  export const data = {
    title: 'Account Ressource',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un Account Ressource'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des Account Ressource'
      }
    }

  }
  export const url = {
    ACCOUNT_RESSOURCE: {
      ACCOUNT_RESSOURCE_: 'account_ressource',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    ACCOUNT_RESSOURCE: {
      ADD: ConstantAccountRessource.url.ACCOUNT_RESSOURCE.ACCOUNT_RESSOURCE_
        .concat(Url.SLASH_ROUTE).concat(ConstantAccountRessource.url.ACCOUNT_RESSOURCE.ADD),
      LIST: ConstantAccountRessource.url.ACCOUNT_RESSOURCE.ACCOUNT_RESSOURCE_
        .concat(Url.SLASH_ROUTE).concat(ConstantAccountRessource.url.ACCOUNT_RESSOURCE.LIST),
    }
  };
}
