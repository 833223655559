import { IMenuItem } from '../../interfaces/main-menu';
import { ConstantAccRessType } from '../../pages/entities/acc-ress-type/acc-ress-type.url';
import { ConstantHome } from '../../pages/entities/home/home.url';
import { ConstantUtilisateur } from '../../pages/entities/user-managment/user-managment.url';
import { ConstantCountries } from '../../pages/entities/countries/countries.url';
import { ConstantMonies } from '../../pages/entities/monies/monies.url';
import { ConstantServiceItems } from '../../pages/entities/service-items/service-items.url';
import { ConstantFeesBenefits } from '../../pages/entities/fees/fees.url';
import { ConstantExchangeRate } from '../../pages/entities/exchange-rate/exchange-rate.url';
import { CONSTANT_KEY_TRANSLATE  } from '../../shared/constant/key-translate.constant';
import { ConstantValidators } from '../../pages/entities/champs_formulaires/validators/validator.url';
import { ConstantChampsFormulaires } from '../../pages/entities/champs_formulaires/champs_formulaires/champs-formulaire.url';
import { ConstantValidatorsAffected } from '../../pages/entities/champs_formulaires/validator_affected/validator-affected.url';
import { ConstantTypeInputForm } from '../../pages/entities/champs_formulaires/type-input-form/type-input-form.url';
import { ConstantSourceForm } from '../../pages/entities/champs_formulaires/source-form/source-form.url';
import { DomainLink } from '../../pages/entities/domain/domain.url';
import { StatusLink } from '../../pages/entities/status/status.url';
import { ConstantVersionExchangeRate } from '../../pages/entities/version-exch-rate/version-exchange-rate.url';
import { ConstantFormulaires } from '../../pages/entities/formulaires/formulaire.url';
import { ConstantCosts } from '../../pages/entities/costs/costs.url';
import { ConstantCostsDetails } from '../../pages/entities/costs-details/costs-details.url';


/**
 * Décrit les différents données utilisées
 * pour le menu.
 */
export const CONSTANT_MENU: IMenuItem[] = [
  
    {
      "title": "Home",
      "key" : CONSTANT_KEY_TRANSLATE.home,
      "icon": {
        "class": "icofont-home"
      },
      "routing": ConstantHome.data.routing,
      "image":""
    }
  ,
  {
    "title": "Point Cash",
    "key" : CONSTANT_KEY_TRANSLATE.trader,
    "icon": {
      "class": "icofont-home"
    },
    "image": "assets/content/trader.png",
    "routing": 'c-user',
    sub: [{
      title: "Lister",
      routing: 'c-user'
    }]
  },
  {
    "title": ConstantMonies.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.monies,
    "icon": {
      "class": "icofont-home"
    },
    routing: ConstantMonies.urlFull.MONIES.LIST,
    "image": "assets/content/monies.png",
    sub: [{
      title: "Lister",
      routing: ConstantMonies.urlFull.MONIES.LIST,
    }, {
      title: "Taux de change",
      routing: ConstantExchangeRate.urlFull.EXCHANGE_RATE.LIST
    },
      {
        title: "Version taux de change",
        routing: ConstantVersionExchangeRate.urlFull.EXCHANGE_RATE.LIST
      }
    ]
  },
  {
    "title": ConstantFeesBenefits.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.fees,
    "icon": {
      "class": "icofont-home"
    },
    "routing": ConstantFeesBenefits.urlFull.FEES.LIST,
    "image": "assets/content/fees.png"
  },
  {
    "title": "Transactions",
    "key" : CONSTANT_KEY_TRANSLATE.transactions,
    "icon": {
      "class": "icofont-home"
    },
    "routing": "transactions",
    "image": "assets/content/transactions.png"
  },
  {
    "title": ConstantServiceItems.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.service,
    "icon": {
      "class": "icofont-home"
    },
    routing: ConstantServiceItems.urlFull.SERVICE_ITEMS.LIST,
    "image": "assets/content/serviceItems.png",
    sub: [{
      title: ConstantServiceItems.data.title,
      routing: ConstantServiceItems.urlFull.SERVICE_ITEMS.LIST
    },
      {
        title: "Champs formulaire",
        sub: [{
          title: "Lister",
          routing: ConstantChampsFormulaires.urlFull.FEES.LIST
        },
          {
            title: "Validateurs",
            routing: ConstantValidators.urlFull.FEES.LIST
          },
          {
            title: "Validateurs affectés",
            routing: ConstantValidatorsAffected.urlFull.FEES.LIST
          },
          {
            title: "Type input formulaire",
            routing: ConstantTypeInputForm.urlFull.FEES.LIST
          },
          {
            title: "Source champs",
            routing: ConstantSourceForm.urlFull.FEES.LIST
          }
        ]
      }
    ]
  },
  {
    "title": 'Service Items Composés',
    "key" : 'Service Items Composés',
    "icon": {
      "class": "icofont-home"
    },
    "routing": 'service_items_compose/liste',
    "image": "assets/content/serviceItems.png"
  },
  {
    "title": ConstantCountries.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.countries,
    "icon": {
      "class": "icofont-home"
    },
    "routing": ConstantCountries.urlFull.COUNTRIES.LIST,
    "image": "assets/content/accountsRessource.png"
  },
  {
    "title": StatusLink.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.status,
    "icon": {
      "class": "icofont-home"
    },
    routing: StatusLink.urlFull.FEES.LIST,
    "image": "assets/content/accountsRessource.png",
    sub: [{
      title: "Lister",
      routing: StatusLink.urlFull.FEES.LIST
    }, {
      title: "Domain",
      routing: DomainLink.urlFull.FEES.LIST
    }
    ]
  },
  {
    "title": ConstantFormulaires.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.formulaire,
    "icon": {
      "class": "icofont-home"
    },
    routing: ConstantFormulaires.urlFull.FEES.LIST,
    "image": "assets/content/accountsRessource.png",
    sub: [{
      title: "Lister",
      routing: ConstantFormulaires.urlFull.FEES.LIST
    }, {
      title: "Champs formulaire",
      sub: [{
        title: "Lister",
        routing: ConstantChampsFormulaires.urlFull.FEES.LIST
      },
        {
          title: "Validateurs",
          routing: ConstantValidators.urlFull.FEES.LIST
        },
        {
          title: "Validateurs affectés",
          routing: ConstantValidatorsAffected.urlFull.FEES.LIST
        },
        {
          title: "Type input formulaire",
          routing: ConstantTypeInputForm.urlFull.FEES.LIST
        },
        {
          title: "Source champs",
          routing: ConstantSourceForm.urlFull.FEES.LIST
        }
      ]
    }
    ]
  },

  {
    "title": ConstantCosts.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.costs,
    "icon": {
      "class": "icofont-home"
    },
    routing: ConstantCosts.urlFull.COSTS.LIST,
    "image": "assets/content/monies.png",
    sub: [{
      title: "Lister",
      routing: ConstantCosts.urlFull.COSTS.LIST,
    }, {
      title: "Détails",
      routing: ConstantCostsDetails.urlFull.COSTS.LIST
    }
    /*
      {
        title: "Version taux de change",
        routing: ConstantVersionExchangeRate.urlFull.EXCHANGE_RATE.LIST
      }*/
    ]
  },

/*  {
    "title": ConstantChampsFormulaires.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.champsFormulaire,
    "icon": {
      "class": "icofont-home"
    },
    "routing": ConstantChampsFormulaires.urlFull.FEES.LIST,
    "image": "assets/content/accountsRessource.png"
  },*/
  {
    "title": ConstantAccRessType.data.title,
    "key" : CONSTANT_KEY_TRANSLATE.account,
    "icon": {
      "class": "icofont-home"
    },
    "routing": ConstantAccRessType.urlFull.ACCOUNT_RESSOURCE_TYPE.LIST,
    "image": "assets/content/accountsRessource.png"
  }
]
