import { Injectable } from '@angular/core';

export interface IAccRessColumnName {
  actived: string;
  deactivated: string;
  accountResstype: string;
  mobileAccountId: string;
}

@Injectable({ providedIn: 'root' })
export class AccRessColumnName {
  getColumnName(): IAccRessColumnName{
    return {
      actived:  'ACTIVED',
      deactivated: 'DEACTIVED',
      accountResstype: 'accountRessType',
      mobileAccountId: 'mobileAccountId'
    }
  }
}
