import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IOption } from '../../../../ui/interfaces/option';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IServiceItemColumnName, ServiceItemColumnName } from '../../service-items/service-item-column-name';
import { BodyType, IServiceItem, ServiceItem, ServiceItemStatus } from '../../../../model/service-item.model';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { AccountRessTypeService } from '../../acc-ress-type/account-ress-type.service';
import { ServiceItemService } from '../../service-items/service-item.service';
import { ServiceItemComposeService } from '../service-item-compose.service';
import { FormService } from '../../../../shared/services/form.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { IAccountRessType } from '../../../../model/acc_ress_type.model';
import {
  CompositeCreateServiceItemDTO,
  IServiceItemCompose,
  ServiceItemFilsDTO,
  ServiceItemFilsListDTO
} from '../../../../model/service-item-compose.model';
import { ColorService } from '../../../../shared/services/color.service';
import { ServiceItemUpdateComponent } from '../../service-items/update/service-item-update.component';
import { ServiceItemComposeUpdateComponent } from '../update/service-item-compose-update.component';
import { ServiceItemComposeDeleteComponent } from '../delete/service-item-compose-delete.component';

/**
 * Permet de gérer les services fils.
 */
@Component({
  selector: 'app-service-item-fils-liste',
  templateUrl: './service-item-fils-liste.component.html',
  animations: [fadeIn]
})
export class ServiceItemFilsListeComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  accountRessTypes: IOption[] = [];
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ServiceItemFilsListeComponent>;

  @ViewChild('basicTable') tabRef: any;

  serviceItemFilsList: ServiceItemFilsListDTO[];
  serviceItemFilsSearch: ServiceItemFilsListDTO[];

  private subs$ = new Subject<void>();
  serviceItem: IOption[] = [];

  searchValue: string;
  serviceItemCompose: IServiceItemCompose;

  editForm: UntypedFormGroup;
  column: IServiceItemColumnName;


  // filteredOptions: Observable<IServiceItem[]>;
  serviceItemList: ServiceItemStatus;

  constructor(
    private colorService: ColorService,
    public baseService: BaseService,
    private accountService: AccountService,
    private dialog: MatDialog,
    protected accountRessTypeService: AccountRessTypeService,
    private dialogRef: MatDialogRef<ServiceItemFilsListeComponent>,
    private serviceItemService: ServiceItemService,
    private serviceItemComposeService: ServiceItemComposeService,
    private formService: FormService,
    private columnName: ServiceItemColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct service item fils liste');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm();
  }

  ngOnInit(): void {
    console.log('## init service item fils liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {

        this.serviceItemComposeService.getServiceItemSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: IServiceItemCompose) => {
          console.log('## get service item parent selected to list fils: ', res);
          this.serviceItemCompose = res;

          // on récupère les services fils
          let p = new HttpParams();
          p = p.set('parentId', this.serviceItemCompose.serviceId);
          this.serviceItemComposeService.getServiceFils(p).subscribe(res => {
            console.log('## Success get service item fils list: ', res);
            this.serviceItemFilsList = (res.body) ? res.body : [];
            this.serviceItemFilsSearch = this.serviceItemFilsList;
            console.log('## size: ' + this.serviceItemFilsList.length);

            this.serviceItemComposeService.getServiceItemFilsObs()
              .pipe(takeUntil(this.subs$))
              .subscribe((res: ServiceItemStatus[]) => {
                console.log('## get list service compose fils list by subject', res);
                this.serviceItemFilsList = (res) ? res : [];
                this.serviceItemFilsSearch = this.serviceItemFilsList;
              });
          });
        });
        super.setLoaded();
      })
  }

  ngOnDestroy() {
    console.log('## destroy service item fils liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  getStatusBg(id: number): string {
    return this.colorService.getStatusBg(id);
  }

  update(c: ServiceItemFilsListDTO) {
    this.serviceItemComposeService.setServiceItemFilsSelectObs(c);
    this.dialog.open(ServiceItemComposeUpdateComponent, {
      height: '700px',
      width: '800px',
    });
  }

  delete(c: ServiceItemFilsListDTO) {
    this.serviceItemComposeService.setServiceItemFilsSelectObs(c);
    this.dialog.open(ServiceItemComposeDeleteComponent, {
      height: '700px',
      width: '800px',
    });
  }


  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.serviceItemFilsSearch].map(mapFn);
    const result = this.serviceItemFilsSearch.filter(
      (_, i) => stringArr[i].indexOf(query
      && query.length ? query.toLowerCase() : '') > -1
    );
    this.serviceItemFilsList = [];
    result.forEach((c) => {
      this.serviceItemFilsList.push(c);
    });
  }

  /**
   * Retourne la liste des services items
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): ServiceItemFilsListDTO[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  /*private _filter(value: string): IServiceItem[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemList.filter(option => option.name.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }*/

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getAllAccRessType() {
    setTimeout(() => {
      this.accountRessTypeService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IAccountRessType[]>) => mayBeOk.ok),
          map((response: HttpResponse<IAccountRessType[]>) => response.body)
        )
        .subscribe((res: IAccountRessType[]) => {
          let acc = (res) ? res : [];
          if(res){
            acc=res;
            this.accountRessTypes=[];
            acc.forEach(a => {
              this.accountRessTypes.push({
                ...new Option(),
                label: (a.type) ? a.type : '',
                value: (a.id) ? a.id.toString() : undefined
              })
            })
          }else{
            acc=[];
            this.accountRessTypes=[];
            this.accountRessTypes.push({
              ...new Option(),
              label: 'No account ressource type',
              value: ''
            })

          }


          /*  this.accountRessTypes.push({
             ...new Option(),
             label: 'No account ressource type',
             value: ''
           })
           console.log('## success get acc ress type list by add afterrrrr',  this.accountRessTypes);
           acc.forEach(a => {
             this.accountRessTypes.push({
               ...new Option(),
               label: (a.type) ? a.type : '',
               value: (a.id) ? a.id.toString() : undefined
             })
           }) */


        }),
        (res: HttpErrorResponse) => {
          console.log('## error get acc ress type list: ' +res.message);
        };
    }, 500)
  }

  displayFnServiceItem(subject: IServiceItem) {
    return subject ? subject.name : '';
  }

  getArrayControls(): AbstractControl[]{
    return this.serviceFilsList.controls;
  }

  /**
   * initialise les champs_formulaires.
   */
  initForm() {
    try {
      this.editForm = this.fb.group({
        ['serviceItemParentId']: ['', [Validators.required]],
        ['serviceItemFilsList']: this.fb.array([]),
      });
    }catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  get serviceFilsList() {
    return (<UntypedFormArray>this.editForm.get('serviceItemFilsList'));
  }

  addServiceFils() {
    const ipForm = this.fb.group({
      serviceItemFilsId: ['', Validators.required],
      step: ['', Validators.required]
    });
    this.serviceFilsList.push(ipForm);
  }

  /**
   * @param i the index.
   */
  removeServiceFils(i: number) {
    const s = this.serviceFilsList;
    if(s.at(i)){
      this.serviceFilsList.removeAt(i);
    }
  }

  private getFormServiceList(): ServiceItemFilsDTO[] {
    let arrayForm = this.serviceFilsList;
    let serviceFilsList = [];
    for(let c of arrayForm.controls) {
      if(c) {
        let i = new ServiceItemFilsDTO();
        i.serviceItemFilsId = (c.get('serviceItemFilsId').value as ServiceItemStatus).id;
        i.step = c.get('step').value;
        serviceFilsList.push(i);
      }
    }
    return serviceFilsList;
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save service item');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const serviceItemParentId = this.editForm.get(['serviceItemParentId']);
    let serviceFilsList = this.getFormServiceList();
    let data = new CompositeCreateServiceItemDTO();
    data.serviceItemFilsList = serviceFilsList;
    data.serviceItemParentId = (serviceItemParentId.value as ServiceItemStatus)
      .id;
    console.log('## data: ', data);
    this.serviceItemComposeService.createServiceCompose(data)
      .subscribe( res => {
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.serviceItemComposeService.getAllServiceItem();
      }, error => {
        console.log('## error create service item: ', error);
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      })
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): IServiceItem {
    const bodyType: string =  this.editForm.get(['body_type']).value;
    const bodyContent: string = this.editForm.get(['body_content']).value;
    return {
      ...new ServiceItem(),
      id: this.editForm.get(['id']).value,
      name: this.editForm.get(['name']).value,
      description: this.editForm.get(['description']).value,
      serviceItemId: this.editForm.get(['serviceItemId']).value,
      url: (this.editForm.get(['url']).value as string).toString().trim(),
      bodyFormat: this.editForm.get(['body_format']).value,
      bodyContent: bodyContent ? bodyContent.toString().trim() : null,
      bodyType: bodyType
        ? {
          ...new BodyType(),
          field: bodyType.toString().trim()
        }
        : null,
      requestType: this.editForm.get(['request_type']).value
    };
  }

  private createFromFormWithActRessType(): IServiceItem {
    const bodyType: string =  this.editForm.get(['body_type']).value;
    const bodyContent: string = this.editForm.get(['body_content']).value;
    return {
      ...new ServiceItem(),
      id: this.editForm.get(['id']).value,
      name: this.editForm.get(['name']).value,
      description: this.editForm.get(['description']).value,
      serviceItemId: this.editForm.get(['serviceItemId']).value,
      accountRessTypeId: this.editForm.get(['accountRessTypeId']).value,
//      accountRessTypeName: this.editForm.get(['accountRessTypeName']).value,
      url: (this.editForm.get(['url']).value as string).toString().trim(),
      bodyFormat: this.editForm.get(['body_format']).value,
      bodyContent: bodyContent ? bodyContent.toString().trim() : null,
      bodyType: bodyType
        ? {
          ...new BodyType(),
          field: bodyType.toString().trim()
        }
        : null,
      requestType: this.editForm.get(['request_type']).value
    };
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }
  reset(){
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
