
export enum TypeInputFormType {
  TEXT = 'text', PASSWORD = 'password', SELECT = 'select', FILE = 'file'
}

export class TypeInputFormModel {
  constructor(
    public id?: number,
    public type?: string,
    public description?: string,
  ) {}
}

export class LocationTypeModel {
  constructor(
    public id?: number,
    public label?: string,
    public code?: string,
    public description?: string,
  ) {}
}