import { IOption } from '../../../../ui/interfaces/option';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BaseDynamicComponent } from '../../../../shared/layouts/base-dynamic.component';
import { SelectPrefixService } from '../../../../shared/layouts/select-prefix.service';
import { Subject } from 'rxjs';
import { CountryColumnName, ICountryColumnName } from '../../countries/country-column-name';
import { DynamicFormModel } from '../../../../model/champs_formulaire/dynamic-form.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicService } from '../../../../services/dynamic.service';
import { ColorService } from '../../../../shared/services/color.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { AccountUserService } from '../../../../services/ServiceEntity/account-user.service';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CostsRuleService } from '../../fees/costs-rule.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { IMobileAccount } from '../../../../model/mobile-account.model';
import { STATUS } from '../../../../model/account.model';
import { BASE_URL } from '../../../../../environments/environment';
import { POPUP_CONTAINER_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { TIME_MEDIUM_REQUEST } from '../../../../shared/constant/request.contant';
import { Content } from '../../../../ui/interfaces/modal';


/**
 * Permet à une personne quelconque d'envoyer de l'argent
 * à une autre personne.
 */
@Component({
  selector: 'app-transfert-compte-dynamic',
  templateUrl: './transfert-compte-dynamic.component.html',
  animations: [fadeIn]
})
export class TransfertCompteDynamicComponent extends BaseDynamicComponent
  implements OnInit, OnDestroy, SelectPrefixService {

  private subs$ = new Subject<void>();
  column: ICountryColumnName;

  dynamicFormModels: DynamicFormModel[];
  indicatif: string;
  init = false;
  tabOption: any[] = [];

  transfertCode: UntypedFormGroup;
  loadingMessagesForm: boolean;
  theDialogRef: MatDialogRef<TransfertCompteDynamicComponent>;

  CurentUser: any;
  CurentUserCuser: any;
  currentMobileAccount: any;
  cUserMobileAccount: any;

  accountNumber: any;
  countrylist: IOption[];
  country: any;
  countries: any;
  SelectedContry: any;

  sentAmount: number;
  countryTrader: string;
  error: string;

  ReponseTransfert: any;
  successTransfert = false;
  errorTransfert = false;
  CountryDestID: any;

  loadingTransfertCompte: boolean;
  transfertCompteSuccess = false;
  transfertCompteFail = false;
  transfertCompteERROR: any;


  constructor(
    protected dynamicService: DynamicService,
    private colorService: ColorService,
    private accountService: AccountService,
    private formService: FormService,
    private cUserService: CUserService,
    private formulaireService: ChampsFormulaireService,
    private maccountService: AccountUserService,
    private transactionsService: TransactionsService,
    private dialogRef: MatDialogRef<TransfertCompteDynamicComponent>,
    private columnName: CountryColumnName,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private modal: TCModalService,
    private costsRuleService: CostsRuleService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(dynamicService, store, httpSv);
    console.log('## construct envoi code');
    this.theDialogRef = this.dialogRef;
   // this.cashService.dialogRef = dialogRef;
    this.loadingMessagesForm = false;
    this.countrylist = [];
    this.transfertCode = this.fb.group({});
  }

  ngOnInit(): void {
    console.log('## init transfert compte');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## get account');
        this.CurentUser = account;
        console.log('POINT CASH', this.CurentUser);
        this.getCurrentCuser();
        this.getData('assets/data/table.json', 'tableData',
          'setLoaded');
        let params = new HttpParams();
        params = params.set('idServiceItem', String(16));
        this.formulaireService.query(this.formulaireService.dynamicFormApi, params)
          .pipe(map(res => res.body))
          .subscribe((res: DynamicFormModel[]) => {
            console.log('## success get list forms');
            console.log('## res: ', res);
            this.dynamicFormModels = res;
            this.buildList(res, this.tabOption);
            this.transfertCode = this.initTransfertCodeDynamic(res, this.fb);
            this.init = true;
            this.buildFromEnum(null);
          }, error => {
            console.log('## error get list forms: ', error);
          });
      });
  }

  ngOnDestroy() {
    console.log('## destroy transfert compte');
    this.formService.free(this.subs$);
  }

  getPrefix(): string {
    return this.indicatif;
  }

  getPrefixModified(): string {
    if (this.indicatif) {
      return '+' + this.indicatif;
    }
    return '';
  }

  getCurrentCuser() {
    this.cUserService.findOneByUserLogin(this.CurentUser.login).subscribe((response) => {
      this.CurentUserCuser = response.body;
      console.log('CUSER POINT CASH', this.CurentUserCuser);
      //console.log("Current User",this.CurentUser);
      this.getCurrentMobileAccount();
    }, (err) => {
      console.log(err);
    });
  }

  getCurrentMobileAccount() {
    this.maccountService.findAllAccountsByCUserId(this.CurentUserCuser.id)
      .pipe(
        filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
        map((res: HttpResponse<IMobileAccount[]>) => res.body)
      )
      .subscribe((val: IMobileAccount[]) => {
        console.log('## mobiles accounts: ', val);
        const res = val.filter(m => (m.status === STATUS.actived));
        console.log('## res:', res);
        this.cUserMobileAccount = res['0'];
        //  this.cUserMobileAccount = val['0'];
        this.accountNumber = this.cUserMobileAccount.numberAccount;
        console.log('NUMBER Account', this.accountNumber);
      }, err => {
        console.log(err);
      });
  }

  getCountryByLabel(label) {
    this.getData(`${BASE_URL}countries/label/${label}`, 'country', 'initCountryDestID');
  }

  initCountryDestID() {
    setTimeout(() => {
      this.CountryDestID = this.country.mId;
      console.log('### DESt Country ID', this.CountryDestID);
    }, 0);
  }

  copyInputMessage(inputElement) {
    console.log('## copy: ', inputElement);
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    console.log('## copy: ', inputElement.value);
  }

  getPrefixColorSelect(): string {
    return this.colorService.getPrefixColorSelect();
  }

  selectCountry(countrySelected) {
    console.log('## country selected: ', countrySelected);
    for (let c of this.countries) {
      const label = c.label;
      if (label && countrySelected) {
        if (label.toLocaleLowerCase() === countrySelected.toLocaleLowerCase()) {
          console.log('## c_id: ', c.id, ' match e_selected_id: ', countrySelected);
          this.indicatif = c.codePhone;
          console.log('## c_id indicatif: ', this.indicatif);
          break;
        }
      }
    }
  }

  //Transfert Compte Valide
  TransfertCompteValide() {
    // init message..
    this.loadingTransfertCompte = true;
    this.transfertCompteSuccess = false;
    this.transfertCompteFail = false;
    const data = this.getFormData(this.transfertCode);
    data.set('serviceItemId', '' + 202);
  //  this.showKeys(data);

    this.transactionsService.withdrawalv2(data)
      .subscribe((rep) => {
        console.log('### SUCCESS Transfert compte ', rep);
        this.manageMessageWhenSucceed();
      }, err => {
        this.transfertCompteFail = true;
        console.log('### ERROR Transfert compte', err);
        this.manageMessageWhenError();
      });
  }

  /**
   * Permet d'effectuer un envoie code.
   * @param forModal
   * @param forfooter
   * @constructor
   */
  envoiCode(forModal: any, forfooter?: any) {
    console.log('## envoie code');
    this.CountryDestID = this.transfertCode.value[this.columnName.getColumnName().labelCountry];
    console.log('## cu: ', this.CurentUserCuser.countryId);
    console.log('## cu dest: ', this.CountryDestID);

    this.loadingMessagesForm = true;
    this.successTransfert = false;
    this.errorTransfert = false;

    const data = this.getFormData(this.transfertCode);
    data.append('numberAccount', this.accountNumber);
    data.append('preferenceCurrency', 'XOF');


/*    setTimeout(
      () => {
        if (this.CurentUserCuser.countryId === this.CountryDestID) {//transfert National
          console.log('transfert NATIONAL');
          //  this.transfertCode.value.serviceItemId = 102;
          data.set('serviceItemId', '' + 102);
          data.forEach((v, k) => {
            console.log(k + ':', v);
          });

          this.transactionsService.transferCode(data)
            .subscribe((rep) => {
              console.log('### SUCCESS Transfert Code', rep);
              this.manageMessageWhenSucceed(rep);
              this.showDetailsEnvoieCode(forModal,
                'Transaction details', null,
                {
                  closeButton: true,
                  overlayClose: false
                }); // after 700ms
            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        } else {//transfert International api/transactions/transferCodeInter
          console.log('transfert INTERNATIONAL');
          console.log('CUSER CURRENT', this.CurentUserCuser.countryId);
          // this.transfertCode.value.serviceItemId = 103;
          data.set('serviceItemId', '' + 103);
          console.log('### POST data:', data);

          this.transactionsService.transferCodeInter(data)
            .subscribe((rep) => {
              console.log('### SUCCESS Transfert Code', rep);
              this.manageMessageWhenSucceed(rep);
              this.showDetailsEnvoieCode(forModal,
                'Transaction details', null,
                {
                  closeButton: true,
                  overlayClose: false
                }); // after 700ms
            }, err => {
              console.log('ERROR While TRANS', err);
              this.manageMessageWhenError();
            });
        }
      },
      1000
    );*/
  }



  /**
   * Affiche les détails de la transaction
   * lorsqu'elle a réussi.
   * @param modal
   * @param title
   * @param footer
   * @param options
   */
  showDetailsEnvoieCode(modal, title: string, footer, options) {
    console.log('## showDetailsEnvoieCode');
    setTimeout(() => {
      this.openModal(modal, title, footer, options, POPUP_CONTAINER_CLASS);
    }, TIME_MEDIUM_REQUEST);
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenError() {
    this.successTransfert = false;
    this.loadingMessagesForm = false;
    this.errorTransfert = true;
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenSucceed() {
    this.errorTransfert = false;
    this.loadingMessagesForm = false;
    this.successTransfert = true;
 //   this.ReponseTransfert = response.body;
    this.transfertCode.reset();
  }

  //Initialiser les Country pour le Select
  initCountry() {
    console.log('## init country');
    setTimeout(() => {
      this.countrylist = [];
      this.countries?.forEach(element => {
        console.log('## country element: ', element);
        this.countrylist.push({ 'label': element.label, 'value': element.label });
      });
    });
  }

  resetMessagesForm() {
    this.transfertCode.reset();
    this.loadingMessagesForm = false;
  }

  openModal<T>(body: Content<T>, header: Content<T> = null,
               footer: Content<T> = null, options: any = null,
               theClass?: string) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options,
      theClass: theClass
    });
  }

  closeModal() {
    console.log('## close transfert compte');
    this.dialogRef.close();
  }



}
