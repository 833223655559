/**
 * Décrit les frais, bénéfices..
 */
export interface ICosts {
  id?: number;
  label?: string;
}

/**
 * Décrit les frais, bénéfices..
 */
export class Costs implements ICosts {
  constructor(
    public id?: number,
    public label?: string
  ) {}
}
