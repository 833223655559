import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { COUNTRIES_ROUTE } from './countries.route';
import { CountriesComponent } from './countries.component';
import { CountryAddComponent } from './add/country-add.component';
import { CountryShowComponent } from './show/country-show.component';
import { CountryUpdateComponent } from './update/country-update.component';
import { CountryDeleteComponent } from './delete/country-delete.component';

/**
 * Ce module permet de gérer le model Countries.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [CountryDeleteComponent, CountryUpdateComponent,
        CountryShowComponent, CountryAddComponent,
        CountriesComponent]
})
export class CountriesModule {}
