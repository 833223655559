import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Account } from '../../../../model/account.model';
import { ChampsFormulaire } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { Observable, Subject } from 'rxjs';
import { IServiceItem } from '../../../../model/service-item.model';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from '../../base/base.service';
import { ServiceItemService } from '../../service-items/service-item.service';
import { FormService } from '../../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { ChampsFormulaireService } from '../champs_formulaires/champs-formulaire.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantChampsFormulaires } from '../champs_formulaires/champs-formulaire.url';
import { ConstantHome } from '../../home/home.url';
import { Url } from '../../../../shared/constant/url.constant';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConstantValidatorsAffected } from '../validator_affected/validator-affected.url';
import { FormulaireAddValidatorComponent } from '../champs_formulaires/add/validator/formulaire-add-validator.component';
import { ChampsFormulaireAddComponent } from '../champs_formulaires/add/champs-formulaire-add.component';
import { ChampsFormulaireUpdateComponent } from '../champs_formulaires/update/champs-formulaire-update.component';
import { ChampsFormulaireDeleteComponent } from '../champs_formulaires/delete/champs-formulaire-delete.component';
import { TypeInputFormModel } from '../../../../model/champs_formulaire/type-input-form.model';
import { ConstantTypeInputForm } from './type-input-form.url';
import { TypeInputFormService } from './type-input-form.service';
import { TypeInputFormAddComponent } from './add/type-input-form-add.component';
import { TypeInputFormUpdateComponent } from './update/type-input-form-update.component';
import { TypeInputFormDeleteComponent } from './delete/type-input-form-delete.component';

@Component({
  selector: 'app-type-input-form-list',
  templateUrl: './type-input-form-list.component.html'
})
export class TypeInputFormListComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  countriesList: TypeInputFormModel[];
  countriesSearch: TypeInputFormModel[];
  searchValue: string;
  initByQueryParam = false;

  serviceItemTyped = '';
  subs$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public baseService: BaseService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private formulaireService: TypeInputFormService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct formulaire list');
    this.pageData = {
      title: ConstantTypeInputForm.data.sub.list.title,
      key: 'typeInputForm.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantTypeInputForm.data.title,
          key: 'validator.name',
          route: Url.SLASH_ROUTE.concat(ConstantTypeInputForm.urlFull
            .FEES.LIST)
        },
        {
          title: ConstantTypeInputForm.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'typeInputForm.title') {
      translate.get('typeInputForm.title').subscribe(res => this.pageData.title = res);
    }

    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'typeInputForm.name') {
        translate.get('typeInputForm.name').subscribe(res => {
          console.log('## res: ', res);
          value.title = res;
        });
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init type-input-form list');
    super.ngOnInit();
    this.activatedRoute.queryParams.subscribe(d => {
      console.log('## param changed');
      super.setLoaded();
      const res = d['service'];
      if (this.initByQueryParam) {
        console.log('## param: ', res);
        if (!res) {
          this.formulaireService.query(this.formulaireService.list_url).subscribe(r => {
            console.log('## Success get type-input-form list: ', r);
            this.countriesList = (r.body) ? r.body : [];
         //   this.updateData(this.countriesList);
            this.countriesSearch = this.countriesList;
          });
        }
      }
      this.initByQueryParam = true;
    });
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.account = account;
          this.formulaireService.query(this.formulaireService.list_url)
            .subscribe(res => {
              console.log('## Success get type-input-form list: ', res);
              this.countriesList = (res.body) ? res.body : [];
            //  this.updateData(this.countriesList);
              this.countriesSearch = this.countriesList;
              super.setLoaded();
              this.formulaireService.getObjetObs().pipe(takeUntil(this.subs$))
                .subscribe((res: TypeInputFormModel[]) => {
                  console.log('## get list type-input-form in list by subject: ', res);
                  this.countriesList = (res) ? res : [];
             //     this.updateData(this.countriesList);
                  this.countriesSearch = this.countriesList;
                });
            });
        //  this.getServiceItemList(); // after 500ms.
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    console.log('## on destroy type-input-form list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.countriesSearch].map(mapFn);
    const result = this.countriesSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  add() {
    console.log('## open type-input-form add');
    this.baseService.openSmH2(this.dialog, TypeInputFormAddComponent);
  }

  update(c: TypeInputFormModel) {
    this.formulaireService.setObjetSelectObs(c);
    this.baseService.openSmH2(this.dialog, TypeInputFormUpdateComponent);
  }

  delete(c: TypeInputFormModel) {
    this.formulaireService.setObjetSelectObs(c);
    this.baseService.openSm(this.dialog, TypeInputFormDeleteComponent);
  }

 /* show(d: TypeInputFormModel) {
    console.log('## show');
    console.log('## id form: ', d.id);
    this.router.navigate(['/' + ConstantValidatorsAffected.urlFull.FEES.LIST],
      { queryParams: { [ConstantFormulaires.url.FEES.FEES_]: d.id } });
  }

  createValidator(d: TypeInputFormModel) {
    console.log('## open formulaire add validator');
    this.formulaireService.setObjetSelectObs(d);
    this.baseService.open(this.dialog, FormulaireAddValidatorComponent);
  }

  add() {
    console.log('## open formulaire add');
    this.baseService.open(this.dialog, ChampsFormulaireAddComponent);
  }

  liste(c: Formulaire) {
    this.formulaireService.setObjetSelectObs(c);
    this.baseService.open(this.dialog, ChampsFormulaireUpdateComponent);
  }

  delete(c: Formulaire) {
    this.formulaireService.setObjetSelectObs(c);
    this.baseService.open(this.dialog, ChampsFormulaireDeleteComponent);
  }*/

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): TypeInputFormModel[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }
}