import { HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { map } from "jquery";
import { Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";
import { fadeIn } from "src/app/animations/form-error";
import { IAppState } from "src/app/interfaces/app-state";
import { IAccountRessType } from "src/app/model/acc_ress_type.model";
import { IServiceItemCompose, ServiceItemFilsListDTO } from "src/app/model/service-item-compose.model";
import { ServiceItemStatus, IServiceItem, ServiceItem, BodyType, ServiceItemCompose } from "src/app/model/service-item.model";
import { BasePageComponent } from "src/app/pages/base-page";
import { HttpService } from "src/app/services/http/http.service";
import { AccountService } from "src/app/services/ServiceEntity/account.service";
import { WIDTH_MEDIUM_POPUP_CLASS, SUFFIX_ICON_FORM } from "src/app/shared/constant/css-style/css-class.constant";
import { FormService } from "src/app/shared/services/form.service";
import { IOption } from "src/app/ui/interfaces/option";
import { AccountRessTypeService } from "../../acc-ress-type/account-ress-type.service";
import { IServiceItemColumnName, ServiceItemColumnName } from "../../service-items/service-item-column-name";
import { ServiceItemService } from "../../service-items/service-item.service";
import { ServiceItemComposeService } from "../service-item-compose.service";

/**
 * Permet de gérer la suppression des service items composés
 */
@Component({
  selector: 'app-service-item-compose-delete-parent',
  templateUrl: './service-item-compose-delete-parent.component.html',
  animations: [fadeIn]
})
export class ServiceItemComposeDeleteParentComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  accountRessTypes: IOption[] = [];
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ServiceItemComposeDeleteParentComponent>;
  serviceItem: IOption[] = [];
  editForm: UntypedFormGroup;
  column: IServiceItemColumnName;
  requestType: IOption[] = [];
  bodyFormat: IOption[] = [];

  serviceItemSelected: ServiceItemStatus;
  serviceItemFils: IServiceItemCompose;


  successDelete = false;
  hasDelete = false;
  isDeleting = false;

  private subs$ = new Subject<void>();

  constructor(
    private serviceItemComposeService: ServiceItemComposeService,
    private accountService: AccountService,
    protected accountRessTypeService: AccountRessTypeService,
    private dialogRef: MatDialogRef<ServiceItemComposeDeleteParentComponent>,
    private serviceItemService: ServiceItemService,
    private formService: FormService,
    private columnName: ServiceItemColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct service item delete');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
  }

  ngOnInit(): void {
    console.log('## init service item delete');
    /*this.accountService.identity().pipe(takeUntil(this.subs$)).subscribe(res => {
      this.getAllAccRessType();
    });*/
    this.getData();
    super.setLoaded();
  }

  getData() {
    this.serviceItemComposeService.getServiceItemSelectObs()
      .pipe(takeUntil(this.subs$)).subscribe((d: IServiceItemCompose) => {
      console.log('## service item compose selected: ', d);
      this.serviceItemFils = d;
    });
  }

  initValueForm(d: ServiceItemFilsListDTO, l: ServiceItemStatus[]) {
    let serviceCompose: IServiceItem;
    for (const s of l) {
      if (s.id === d.serviceId) {
        serviceCompose = s;
        break;
      }
    }
    return serviceCompose;
  }

  ngOnDestroy() {
    console.log('## destroy service item delete');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }


  initForm(fb: UntypedFormBuilder, column: IServiceItemColumnName) {
    try {
      this.editForm = this.fb.group({
        [column.id]: [],
        [column.name]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        [column.description]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(100)]
        ],
        [column.serviceItemId]: ['', [Validators.required, Validators.minLength(1),
          Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
        [column.accountRessTypeId]: [''],
        [column.accountRessTypeName]: [''],
        [this.column.bodyFormat]: [null],
        [this.column.requestType]: [null, [Validators.required]],
        [this.column.url]: [null, [Validators.required]],
        [this.column.bodyType]: [null],
        [this.column.bodyContent]: [null]
      });

    } catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save service item');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const accId = this.editForm.get(['accountRessTypeId']);
    let c: IServiceItem;
    if (accId && accId.value && accId.value.toString() !== '') {
      console.log('## create form with acc ress type');
      c = this.createFromFormWithActRessType();
    } else {
      console.log('## create form without acc ress type');
      c = this.createFromForm();
    }
    console.log('## c: ' + JSON.stringify(c));
    this.serviceItemService.update(c).subscribe(res => {
        console.log('## success create service item');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.dialogRef.close();
    //    this.serviceItemService.getAllServiceItem();
      },
      err => {
        console.log('## error create service item');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
  }

  delete() {
    console.log('## deleting');
    this.isDeleting = true;
    this.serverHasError = false;
    this.successDelete = false;
    console.log('## service to delete: ', this.serviceItemFils);
    let p = new HttpParams();
    p = p.set('parentId', this.serviceItemFils.serviceId.toString());
    this.serviceItemService.deleteParent(p)
      .subscribe(
        response => {
          console.log('## success delete service');
          this.serverHasError = false;
          this.successDelete = true;
          this.isDeleting = false;
          this.hasDelete = true;
          this.responseFromServer();
          this.serviceItemComposeService.getAllServiceItem();
          this.closeModal();
        }, error => {
          console.log('## error delete country');
          this.serverHasError = true;
          this.successDelete = false;
          this.isDeleting = false;
          this.responseFromServer();
        });
  }

  reset() {
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

  private createFromForm(): IServiceItem {
    const bodyType: string = this.editForm.get(['body_type']).value;
    const bodyContent: string = this.editForm.get(['body_content']).value;
    return {
      ...new ServiceItem(),
      id: this.editForm.get(['id']).value,
      name: this.editForm.get(['name']).value,
      description: this.editForm.get(['description']).value,
      serviceItemId: this.editForm.get(['serviceItemId']).value,
      url: (this.editForm.get(['url']).value as string).toString().trim(),
      bodyFormat: this.editForm.get(['body_format']).value,
      bodyContent: bodyContent ? bodyContent.toString().trim() : null,
      bodyType: bodyType
        ? {
          ...new BodyType(),
          field: bodyType.toString().trim()
        }
        : null,
      requestType: this.editForm.get(['request_type']).value
    };
  }

  private createFromFormWithActRessType(): IServiceItem {
    const bodyType: string = this.editForm.get(['body_type']).value;
    const bodyContent: string = this.editForm.get(['body_content']).value;
    return {
      ...new ServiceItem(),
      id: this.editForm.get(['id']).value,
      name: this.editForm.get(['name']).value,
      description: this.editForm.get(['description']).value,
      serviceItemId: this.editForm.get(['serviceItemId']).value,
      accountRessTypeId: this.editForm.get(['accountRessTypeId']).value,
//      accountRessTypeName: this.editForm.get(['accountRessTypeName']).value,
      url: (this.editForm.get(['url']).value as string).toString().trim(),
      bodyFormat: this.editForm.get(['body_format']).value,
      bodyContent: bodyContent ? bodyContent.toString().trim() : null,
      bodyType: bodyType
        ? {
          ...new BodyType(),
          field: bodyType.toString().trim()
        }
        : null,
      requestType: this.editForm.get(['request_type']).value
    };
  }

}
