import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { fadeIn } from 'src/app/animations/form-error';
import { ICUser } from 'src/app/model/c-user.model';
import { ICountry } from 'src/app/model/country.model';
import { IMobileAccount, MobileAccount } from 'src/app/model/mobile-account.model';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { BASE_URL } from 'src/environments/environment';
import { FormService } from '../../../../shared/services/form.service';
import { CountryService } from '../../countries/countries.service';
import { CUserDetailManageColumnName, ICUserDetailManageColumnName } from '../cuser-detail-manage-column-name';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { PRINCIPAL } from '../../../../model/account-ressource.model';


@Component({
  selector: 'app-cuser-detail-manage-modal',
  templateUrl: './cuser-detail-manage-modal.component.html',
  styleUrls: ['./cuser-detail-manage-modal.component.css'],
  animations: [fadeIn]
})
export class CuserDetailManageModalComponent {

  private subs$ = new Subject<void>();
  form: UntypedFormGroup;
  description: string;
  editForm: UntypedFormGroup;

  theDialogRef: MatDialogRef<CuserDetailManageModalComponent>;
  column: ICUserDetailManageColumnName;
  cUser: ICUser;
  mAccounts: IMobileAccount[];
  currentCuser: any;
  //countries
  countriesList: ICountry[] = [];
  filteredCountryOptions: Observable<ICountry[]>;
  myControl2 = new UntypedFormControl();
  isSaving: boolean;
  displayeFormAccount: boolean;
  currentCUserId: any;
  CurrentUserAuth: any;
  cUserMobileAccount: any;
  CurentUser: any;
  cUserId: number;
  successSave = false;
  erreurSave = false;

  

  constructor(
    private fb: UntypedFormBuilder,
    protected countryService: CountryService,
    protected mAccountService: AccountUserService,
    protected activatedRoute: ActivatedRoute,
    protected accountService: AccountService,
    private columnName: CUserDetailManageColumnName,
    protected http: HttpClient,
    private formService: FormService,
    private dialogRef: MatDialogRef<CuserDetailManageModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { cUserId: number, mAccounts }) {
    this.theDialogRef = this.dialogRef;
    this.cUserId = data.cUserId;
    this.mAccounts = data.mAccounts;
    console.log('mAccountsssssss', this.mAccounts);
    console.log('cUserId', this.cUserId);
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb, column);


  }

  // ########


  // ########

  ngOnInit() {
    this.isSaving = false;
    this.displayeFormAccount = false;
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('on init, this.currentCUserId', this.currentCUserId);
        /*  this.editForm= this.fb.group({
             label: [''],
             preferenceCurrency: ['']
           }); */
        this.getCountryList();
      });
  }

  ngOnDestroy() {
    console.log('## destroy money add');
    this.formService.free(this.subs$);
    this.successSave = false;
    this.erreurSave= false;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  initForm(fb: UntypedFormBuilder, column: ICUserDetailManageColumnName) {
    if (fb && column) {
      this.editForm = this.fb.group({
        [column.label]: ['', Validators.required],
        [column.preferenceCurrency]: ['', Validators.required]

      });
    }
  }

  getCountryList() {
    setTimeout(() => {
      // COUNTRY
      this.countryService.query().subscribe(res => {
        console.log('## Success get country list');
        this.countriesList = (res.body) ? res.body : [];
        console.log('## size: ' + this.countriesList.length);
        this.filteredCountryOptions = this.myControl2.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterCountry(value))
          );
      });
    }, 500);
  }

  private _filterCountry(value: string): ICountry[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.countriesList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter country: ' + e.message);
    }
    return filteredList;
  }

  private createFromForm(): IMobileAccount {
    return {
      ...new MobileAccount(),
      cUserId: this.cUserId,
      label: this.editForm.get(['label']).value,
      preferenceCurrency: this.editForm.get(['preferenceCurrency']).value.mCode
    };
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  //Ajouter un  Account Ressource
  addAccountRessources(dataMobileAccount: any) {
    //account-ressource
    console.log('## datamobileacc: ', dataMobileAccount);
    const accountRessource = {
      'amount': 0,
      'accountRessTypeId': 1,
      'accountRessType': 'CASH',
      'currency': dataMobileAccount.preferenceCurrency,
      'mobileAccountId': dataMobileAccount.id,
      'labelAccountUser': dataMobileAccount.label,
      'principal': PRINCIPAL.MAIN
    };
    this.http.post(`${BASE_URL}account-ressource`, accountRessource).subscribe((rep) => {
      console.log("### Account Ressource", rep);
    }, err => {
      console.log(err);
    });
  }

  AddNewAccount() {
    this.isSaving = true;
    this.successSave = false;
    const mobileAccount = this.createFromForm();
    const preferenceCurrency = mobileAccount.preferenceCurrency;
    console.log('preferenceCurrency', preferenceCurrency);
    const resultat = this.mAccounts.find(account => {
      //console.log('preferenceCurrent findddddddd', preferenceCurrency);
      return account.preferenceCurrency === preferenceCurrency;

    });
    if (resultat === undefined) {
      this.mAccountService.create(mobileAccount).subscribe(response => {
        const m = response.body;
        this.addAccountRessources(m);
        this.isSaving = false;
        this.displayeFormAccount = false;
        this.editForm.reset();
        this.successSave = true;        
        this.mAccountService.getAllMAccount(this.cUserId);
        this.mAccountService.findAllCashForSubject();
      },err => {       
        this.erreurSave = true;
        console.log('## error create country');
        this.responseFromServer();
        this.successSave = false;
      }); 
    } else if (resultat.status === 'REMOVED') {
      this.mAccountService.create(mobileAccount).subscribe(response => {
        const m = response.body;
        this.addAccountRessources(m);
        this.isSaving = false;
        this.displayeFormAccount = false;
        this.editForm.reset();
        this.successSave = true;
        this.mAccountService.getAllMAccount(this.cUserId);
        this.mAccountService.findAllCashForSubject();
      },err => {        
        this.erreurSave = true;
        console.log('## error create country');
        this.responseFromServer();
        this.successSave = false;
      });
    } else {
      this.responseFromServer();      
      this.erreurSave = true;
    }
  }
  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300)
  }
  reset(){
    this.editForm.reset();

  }


  close() {
    this.dialogRef.close();
  }

  /*  save() {
       this.dialogRef.close(this.form.value);
>>>>>>> origin/coumba-dev
   }
   return filteredList;
 }

 private createFromForm(): IMobileAccount {
   return {
     ...new MobileAccount(),
     cUserId: this.cUserId,
     label: this.editForm.get(['label']).value,
     preferenceCurrency: this.editForm.get(['preferenceCurrency']).value.mCode
   };
 }


 AddNewAccount() {
   this.isSaving = true;
   this.successSave = false;
   const mobileAccount = this.createFromForm();
   const preferenceCurrency = mobileAccount.preferenceCurrency;
   const resultat = this.mAccounts.find(account => account.preferenceCurrency === preferenceCurrency);

   if (resultat === undefined) {
     this.mAccountService.create(mobileAccount).subscribe(response => {
       this.isSaving = false;
       this.displayeFormAccount = false;
       this.editForm.reset();
       this.successSave = true;
       this.mAccountService.getAllMAccount(this.cUserId);
     });
     setTimeout(() => {
       alert('DONE.');
       console.log('done');

     }, 3000);

   } else {
     alert('Existe Deja');
   }


 }

 /*  save() {
      this.dialogRef.close(this.form.value);
  }
*/

}
