import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IAccountRessType } from '../../../../model/acc_ress_type.model';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../acc-ress-type-column-name';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { AccountRessTypeService } from '../account-ress-type.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { ConstantAccRessType } from '../acc-ress-type.url';
import { ConstantHome } from '../../home/home.url';
import { Url } from '../../../../shared/constant/url.constant';

/**
 * Permet de gérer l'ajout des types de account ressource.
 */
@Component({
  selector: 'app-accounts-ressource-type-show',
  templateUrl: './acc-ress-type-show.component.html',
  animations: [fadeIn]
})
export class AccountsRessourceTypeShowComponent extends BasePageComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Output('showRef') showRefOut = new EventEmitter<any>();
  @ViewChild('showRef') showRef: any;
  @Input('data') data: IAccountRessType;

  constructor(
    private formService: FormService,
    private columnName: AccRessTypeColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private accountRessTypeService: AccountRessTypeService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct account ressource type show');
  }

  ngOnInit(): void {
    console.log('## init account ressource type show');
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy acc ress type show ressource');
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    console.log('## afterViewInit destroy acc ress type show ressource');
    this.showRefOut.emit(this.showRef);
  }

  closeModal() {
    console.log('## close show acc ress type');
    this.modal.close();
   // this.data = undefined;
  }
}
