import { BASE_URL } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createRequestOption } from '../../../util/request-util';
import { ICostsRule } from '../../../model/costs-rule.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CostsRuleService {
  public resourceUrl = BASE_URL + 'costs-rules';
  public resourceUrlList = BASE_URL + 'costs-rules/list';
  private costsRule = new Subject<ICostsRule[]>();
  // le costsRule sélectionné dans la liste.
  private costsRuleSelect = new BehaviorSubject<ICostsRule>(undefined);

  constructor(protected http: HttpClient) {}

  /**
   * Permet de récupérer la liste des costs rule
   * pour être partagée.
   */
  getAllCostsRule() {
    setTimeout(() => {
      this.query().subscribe(res => {
        console.log('## Success get costs rule list');
        this.setCostRuleObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  getCostRuleSelectObs() : Observable<ICostsRule>{
    return this.costsRuleSelect.asObservable();
  }

  setCostRuleSelectObs(c: ICostsRule) {
    this.costsRuleSelect.next(c);
  }

  getCostRuleObs() {
    return this.costsRule.asObservable();
  }

  setCostRuleObs(c: ICostsRule[]) {
    this.costsRule.next(c);
  }

  create(costsRule: ICostsRule): Observable<HttpResponse<ICostsRule>> {
    return this.http.post<ICostsRule>(this.resourceUrl, costsRule, { observe: 'response' });
  }

  update(costsRule: ICostsRule): Observable<HttpResponse<ICostsRule>> {
    return this.http.put<ICostsRule>(this.resourceUrl, costsRule, { observe: 'response' });
  }

  find(id: number): Observable<HttpResponse<ICostsRule>> {
    return this.http.get<ICostsRule>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findByAmountAndCostRule(amount: number, costsRule: number): Observable<HttpResponse<ICostsRule>> {
    return this.http.get<ICostsRule>(`${this.resourceUrl}/${amount}/${costsRule}`, { observe: 'response' });
  }
  findByAmountAndCostRule2(amount: number, costsRule: number, moneyId: number): Observable<HttpResponse<ICostsRule>> {
    return this.http.get<ICostsRule>(`${this.resourceUrl}/${amount}/${costsRule}/${moneyId}`, { observe: 'response' });
  }

  queryList(): Observable<HttpResponse<ICostsRule[]>> {
    return this.http.get<ICostsRule[]>(this.resourceUrlList, { observe: 'response' });
  }

  query(req?: any): Observable<HttpResponse<ICostsRule[]>> {
    const options = createRequestOption(req);
    return this.http.get<ICostsRule[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
}
