import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService } from '../../../services/http/http.service';
import { Account } from '../../../model/account.model';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileUpdateComponent } from '../../../pages/profile/update/profile-update.component';
import { PasswordUpdateComponent } from '../../../pages/password/update/password-update.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormService } from '../../../shared/services/form.service';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { CONTAINER_DIALOG_POPUP } from '../../../shared/constant/css-style/css-class.constant';
import { INotificationDTO } from '../../../model/notification.model';
import { NotificationService } from '../../../pages/entities/notification/notification.service';
import { STATUS_BD_CONSTANT } from '../../../model/status.model';
import { HttpParams } from '@angular/common/http';
import { TCDropdownComponent, TCDropdownContentComponent } from '../../../ui/components/dropdown';

@Component({
  selector: 'actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, OnDestroy {
  notifications: INotificationDTO[];
  closeDropdown: EventEmitter<boolean>;

  @Input() layout: string;
  account: Account;
  init = 0;
  timerId;

  @ViewChild(TCDropdownContentComponent) popup: TCDropdownContentComponent;

  opened = false;
  private subs$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private formService: FormService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private httpSv: HttpService,
    private router: Router
  ) {
    console.log('## construct actions');
    console.log('## val show: ', this.popup);
    this.notifications = [];
    this.closeDropdown = new EventEmitter<boolean>();
    this.layout = 'vertical';
  }

  ngOnInit() {
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe((a: Account) => {
        try {
          console.log('## get account: ', a);
          this.account = a;
          // on récupère les notifications de transaction
          this.getListNotifications();
          this.notificationService.getObjetObs().pipe(takeUntil(this.subs$))
            .subscribe((res: INotificationDTO[]) => {
              console.log('## Success get notification list by subject: ', res);
              this.notifications = (res) ? res : [];
            });
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });

    /*this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe((a: Account) => {
      try {
        console.log('## get account: ', a);
        this.account = a;
        // on récupère les notifications de transaction
        this.timerId = setInterval(res => {
          this.notificationService.listView()
            .subscribe(res => {
              console.log('## Success get notification list: ', res);
              this.notifications = (res.body) ? res.body : [];
            });
        }, TIME_FITH_REQUEST);
      }catch (e) {
        console.log('## error identity:', e.message);
      }
    });*/
    // this.getData('assets/data/navbar-notifications.json', 'notifications');
  }

  ngOnDestroy(): void {
    this.formService.free(this.subs$);
    clearInterval(this.timerId);
  }

  getListNotifications() {
    this.notificationService.listView()
      .subscribe(res => {
        console.log('## Success get notification list: ', res);
        this.notifications = (res.body) ? res.body : [];
        this.popup.hide();
      });
  }

  seeNotification(n: INotificationDTO) {
    console.log('## tcd', this.popup);
    const m = (n.statusId !== STATUS_BD_CONSTANT.pending);
    console.log('## val: ', m);
    if (m) {
      console.log('## seen notification', n);
      let p = new HttpParams();
      p = p.set('corrId', n.correlationId);
      this.notificationService.markNotificationAsSeen(p).subscribe(res => {
        console.log('## success mark notification as seen: ', res);
        // récupération des notifications
       // this.getListNotifications();
        this.notificationService.listView()
          .subscribe(res => {
            console.log('## Success get notification list: ', res);
            this.notifications = (res.body) ? res.body : [];
            this.notificationService.setObjetObs(this.notifications);
            this.popup.hide();
          });
      }, error => {
        console.log('## error mark notification as seen: ', error);
      });
    } else {
      this.popup.hide();
    }
  }

  format(n: number) {
    return this.formService.format(n);
  }

  getData(url: string, dataName: string) {
    this.httpSv.getData(url).subscribe(
      data => {
        this[dataName] = data;
      },
      err => {
        console.log(err);
      }
    );
  }

  logout() {
    console.log('## logout: ', this.account);
    this.accountService.logout();
  }

  onCloseDropdown() {
    this.closeDropdown.emit(true);
  }

  updatePassword() {
    console.log('## udpate password');
    this.dialog.open(PasswordUpdateComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP
    });
  }

  update() {
    console.log('## liste profil');
    this.dialog.open(ProfileUpdateComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP
    });
    /*this.dialog.open(ProfileUpdateComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  goTo(event: Event, link: string, layout: string = '') {
    event.preventDefault();

    this.onCloseDropdown();

    setTimeout(() => {
      this.router.navigate([layout ? layout : this.layout, link]);
    });
  }
}
