import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CONTAINER_DIALOG_POPUP } from '../../../shared/constant/css-style/css-class.constant';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { HttpClient } from '@angular/common/http';
import {
  ID_SERVICE_ITEM_TRANSFER_CODE,
  SERVICE_ITEM_ID_TRANSFER_CODE,
  SERVICE_ITEM_ID_TRANSFER_CODE_INTER
} from '../../../shared/constant/service-item.constant';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { CashDataModel } from './cash-data-model';

/**
 * Permet d'ouvrir différents services dans des popups.
 */
@Injectable({ providedIn: 'root' })
export class OpenService {

  constructor(
    private dialog: MatDialog,
    protected http: HttpClient) {

  }

  /**
   * Ouvre le component d'envoie code.
   * @param d1
   */
  openEnvoieCodeService(d1?: {}) {
    let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_TRANSFER_CODE;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_TRANSFER_CODE;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_TRANSFER_CODE_INTER;
    this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        others: d1,
        cashDataModel: d
      }
    });
  }
}