import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IAccountRessType } from '../../../../model/acc_ress_type.model';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../../acc-ress-type/acc-ress-type-column-name';
import { UntypedFormBuilder } from '@angular/forms';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { AccountRessTypeService } from '../../acc-ress-type/account-ress-type.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CountryService } from '../countries.service';
import { ICountry } from '../../../../model/country.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Permet de gérer l'ajout d'un country
 */
@Component({
  selector: 'app-country-show',
  templateUrl: './country-show.component.html',
  animations: [fadeIn]
})
export class CountryShowComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  countrySelected: ICountry;
  private subs$ = new Subject<void>();

  constructor(
    private countryService: CountryService,
    private dialogRef: MatDialogRef<CountryShowComponent>,
    private formService: FormService,
    private columnName: AccRessTypeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct country show');
  }

  ngOnInit(): void {
    console.log('## init country shows.');
    this.countryService.getCountrySelectObs()
      .pipe(takeUntil(this.subs$)).subscribe((res: ICountry) => {
        console.log('## get country selected');
      try {
         console.log('## data: ' + JSON.stringify(res));
         this.countrySelected = res;
       }catch (e) {
         console.log('## error get country show: ' + e.message);
       }
      super.setLoaded();
    })
  }

  ngOnDestroy() {
    console.log('## destroy country show');
    this.formService.free(this.subs$);
    this.countryService.setCountrySelectObs(undefined);
  }

  closeModal() {
    console.log('## close show country');
    this.dialogRef.close();
  }
}
