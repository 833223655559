import { Injectable } from '@angular/core';

export interface IDomainColumnName {
  id: string;
  label: string;
  domainLabel: string;
}

/**
 * Permet de renvoyer les champs du model Domain
 * sous forme de string.
 */
@Injectable({ providedIn: 'root' })
export class DomainColumnName {
  getColumnName(): IDomainColumnName {
    return {
      id: 'id',
      label: 'label',
      domainLabel: "domainLabel"
    };
  }

  /**
   * permet générer les noms de colonne unique.
   */
  getColumnNameRandom(): IDomainColumnName {
    return {
      id: 'id',
      label: 'label2',
      domainLabel: "domainLabel"
    };
  }
}
