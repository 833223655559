/**
 * Ce module permet de gérer le model service item.
 */
import { SharedModule } from '../../../shared.module';
import { NgModule } from '@angular/core';
import { ServiceItemsComposeComponent } from './service-items-compose.component';
import { ServiceItemComposeAddComponent } from './add/service-item-compose-add.component';
import { ServiceItemFilsListeComponent } from './liste/service-item-fils-liste.component';
import { ServiceItemComposeUpdateComponent } from './update/service-item-compose-update.component';
import { ServiceItemComposeDeleteComponent } from './delete/service-item-compose-delete.component';
import { ServiceItemComposeDeleteParentComponent } from './delete-parent/service-item-compose-delete-parent.component';


@NgModule({
  imports: [SharedModule],
  declarations: [ServiceItemComposeAddComponent, ServiceItemsComposeComponent,
    ServiceItemFilsListeComponent, ServiceItemComposeDeleteParentComponent, 
    ServiceItemComposeDeleteComponent,
    ServiceItemComposeUpdateComponent]
})
export class ServiceItemComposeModule {}
