import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Pipe({ name: 'defaultValue' })
export class DefaultValue implements PipeTransform {
  constructor(private t: TranslateService) {
  }

  transform(value: any): any {
    if (value === '' || value === null || value === undefined) {
      return this.t.instant('novalue.none');
    }
    return value;
  }
}