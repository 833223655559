import { Injectable } from '@angular/core';
import { IServiceItem, ServiceItemCompose, ServiceItemDisplay, ServiceItemStatus } from '../../../model/service-item.model';
import { createRequestOption } from '../../../util/request-util';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BASE_URL, BASE_URL_ADMIN } from '../../../../environments/environment';
import { IMoney } from '../../../model/money.model';
import { IServiceItemCompose, ServiceItemFilsListDTO } from '../../../model/service-item-compose.model';


type EntityResponseType = HttpResponse<IServiceItem>;
@Injectable({ providedIn: 'root' })
export class ServiceItemService {

  public resourceManageImageListUrl = BASE_URL_ADMIN
    + '/service-item/manage/images/list';

  public resourceManageImageUrl = BASE_URL_ADMIN
   + '/service-item/manage/images';
  public resourceImageUrl = BASE_URL_ADMIN + '/service-item/images';

  public resourceDeleteUrl = BASE_URL + 'service-item/delete';
  public resourceStatusUrl = BASE_URL_ADMIN + '/service-item/status';
  public resourceUrl = BASE_URL + 'service-item';
  public resourceUrlActifAll = BASE_URL + 'service-item/compose/all';
  public serviceComposeAccountUrl = BASE_URL + 'service-item/compose/accounts';
  public resourceUrlActif = BASE_URL_ADMIN + '/service-item/compose';

  constructor(protected http: HttpClient) {}

  private _imageNameSelected: string;

  private serviceItem = new Subject<IServiceItem[]>();

  // le serviceItem sélectionné dans la liste.
  private serviceItemSelect = new BehaviorSubject<IServiceItem>(undefined);

  private serviceItemFilsSelect = new BehaviorSubject<ServiceItemFilsListDTO>(undefined);


  get imageNameSelected(): string {
    return this._imageNameSelected;
  }

  set imageNameSelected(value: string) {
    this._imageNameSelected = value;
  }

  getServiceItemSelectObs() : Observable<IServiceItem>{
    return this.serviceItemSelect.asObservable();
  }

  setServiceItemSelectObs(c: IServiceItem) {
    this.serviceItemSelect.next(c);
  }

  getServiceItemObs() {
    return this.serviceItem.asObservable();
  }

  setServiceItemObs(c: IServiceItem[]) {
    this.serviceItem.next(c);
  }

  /**
   * permet de supprimer un service composé
   */
  getImage(req?): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.resourceImageUrl,
      { params: req, observe: 'response' });
  }

  /**
   * permet de supprimer un service composé
   */
   deleteParent(req?): Observable<HttpResponse<any>> {
    return this.http.delete<any>(this.resourceUrlActif,
      { params: req, observe: 'response' });
  }

  findAllActiveApiKeyServices(apiKeyId: number): Observable<HttpResponse<ServiceItemCompose[]>> {
    let p = new HttpParams();
    p = p.set('apiKeyId', apiKeyId.toString());
    return this.http.get<ServiceItemCompose[]>(this.resourceUrlActifAll,
      { params: p, observe: 'response' });
  }

  /**
   * permet de récupérer tous les services de tous les accounts
   */
  findAllActiveServicesAccounts(): Observable<HttpResponse<ServiceItemCompose[]>> {
    return this.http.get<ServiceItemCompose[]>(this.serviceComposeAccountUrl,
      { observe: 'response' });
  }

  findAllActiveServices(): Observable<HttpResponse<ServiceItemCompose[]>> {
    return this.http.get<ServiceItemCompose[]>(this.resourceUrlActif,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des country
   * pour être partagée.
   */
  getAllServiceItem() {
    setTimeout(() => {
      this.queryStatus().subscribe(res => {
        console.log('## Success get serviceItem list');
        this.setServiceItemObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  saveImageList(image: FormData): Observable<HttpResponse<boolean>> {
    return this.http.post<boolean>(this.resourceManageImageListUrl, image,
      { observe: 'response' });
  }

  saveImage(image: FormData): Observable<HttpResponse<boolean>> {
    return this.http.post<boolean>(this.resourceManageImageUrl, image,
      { observe: 'response' });
  }

  updateWithImage(serviceItem: any): Observable<HttpResponse<IServiceItem>> {
    return this.http.put<IServiceItem>(this.resourceImageUrl, serviceItem,
      { observe: 'response' });
  }

  createWithImage(serviceItem: any): Observable<HttpResponse<IServiceItem>> {
    return this.http.post<IServiceItem>(this.resourceImageUrl, serviceItem,
      { observe: 'response' });
  }

  create(serviceItem: IServiceItem): Observable<HttpResponse<IServiceItem>> {
    return this.http.post<IServiceItem>(this.resourceUrl, serviceItem,
      { observe: 'response' });
  }
  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IServiceItem>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  update(serviceItem: IServiceItem): Observable<HttpResponse<IServiceItem>> {
    return this.http.put<IServiceItem>(this.resourceUrl, serviceItem,
      { observe: 'response' });
  }

  queryStatus(req?: any): Observable<HttpResponse<ServiceItemStatus[]>> {
    return this.http.get<ServiceItemStatus[]>(this.resourceStatusUrl,
      { observe: 'response' });
  }

  query(req?: any): Observable<HttpResponse<IServiceItem[]>> {
    const options = createRequestOption(req);
    return this.http.get<IServiceItem[]>(this.resourceUrl,
      { params: options, observe: 'response' });
  }

  deleteService(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceDeleteUrl}/${id}`,
      { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }
}
