import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IApiKeyDTO } from '../../../../model/api-key.model';
import { IpAdressApiKeyDTOS } from '../../../../model/ipAdress.model';
import { IpAdressService } from '../ip-adress.service';
import { ApiKeyService } from '../api-key.service';
import { ColorService } from '../../../../shared/services/color.service';
import { CUserApiKeyService } from '../../../../services/ServiceEntity/c-user-api-key.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ITransaction } from '../../../../model/transaction.model';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { HttpResponse } from '@angular/common/http';
import { Code, ICode } from '../../../../model/code.model';
import { ShowTransactionComponent } from '../../view-account/show-transaction/show-transaction.component';
import { CodeService } from '../../../../services/ServiceEntity/code.service';
import { CONSTANT_ICON } from '../../../../shared/constant/icon.constant';

/**
 * Permet de lister les transactions des accounts
 */
@Component({
  selector: 'app-transaction-apikey',
  templateUrl: './transaction-apikey.component.html',
  animations: [fadeIn]
})
export class TransactionApikeyComponent extends BasePageComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<TransactionApikeyComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;
  idAccount: number;
  numberAccount: string;
  apiKey: IApiKeyDTO;

  transactionsList: ITransaction[] = [];
  transactionsSearch: ITransaction[] = [];

  @ViewChild('basicTable') tabRef: any;
  searchValue: string;
  currentCode: Code;

  constructor(
    private codeService: CodeService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private transactionService: TransactionsService,
    private apiKeyService: ApiKeyService,
    private colorService: ColorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domainService: CUserApiKeyService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<TransactionApikeyComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct transaction list');
    this.theDialogRef = this.dialogRef;
    console.log('## data: ', this.data);
    this.apiKey = this.data.apiKey;
    this.idAccount = this.data.idAccount;
    this.numberAccount = this.data.numberAccount;
  }

  ngOnInit(): void {
    console.log('## init transaction list');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.transactionService
          .findAllTransactionsByaccountNumber(this.numberAccount)
          .pipe(
            filter((res: HttpResponse<ITransaction[]>) => res.ok),
            map((res: HttpResponse<ITransaction[]>) => res.body)
          )
          .subscribe((res: ITransaction[]) => {
            console.log('transactionssssssss', this.transactionsList);
            this.transactionsList = res ? res : [];
            this.transactionsSearch = this.transactionsList;
          });
      });
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy transaction list');
    this.formService.free(this.subs$);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.transactionsSearch].map(mapFn);
    const result = this.transactionsSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length
        ? query.toLowerCase() : '') > -1
    );
    this.transactionsList = [];
    result.forEach((c) => {
      this.transactionsList.push(c);
    });
  }

  getAmountColBg() {
    return this.colorService.getAmountColBg();
  }

  format(n: number) {
    return this.formService.format(n);
  }

  getStatusBackground(status: number) {
    return this.colorService.getStatusBg(status);
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  viewTransaction(data) {
    console.log('dataaaaaaaa', data);
    this.codeService
      .findByReceiverId(data.receiverId)
      .pipe(
        filter((reslt: HttpResponse<ICode>) => reslt.ok),
        map((reslt: HttpResponse<ICode>) => reslt.body)
      )
      .subscribe((reslt: ICode) => {
        this.currentCode = reslt;

      });
    const dialogConfig = new MatDialogConfig();

    this.dialog.open(ShowTransactionComponent, {
      data: { transaction: data, code: this.currentCode }, height: '600px',
      width: '700px'
    });

  }
}