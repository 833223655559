import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { BASE_URL, BASE_URL_ADMIN, BASE_URL_MANAGEMENT } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DomainService extends ApiService {
  // lie un status à plusieurs domains.
  public link_domain = BASE_URL_ADMIN + '/domain/link/status';

  public link_status = BASE_URL_ADMIN + '/domain/status/link';
  public affect_status = BASE_URL_ADMIN + '/domain/status/affect';
  // permet la gestion des domains.
  public resourceUrl = BASE_URL_ADMIN + '/domain';
  public dynamicFormApi = BASE_URL + 'dynamic-form';
  // liste les domains en fonction de la dernière date de modification.
  public list_url = BASE_URL_ADMIN + '/domain/sort_by_date';

  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * {@link ApiService#getAllObjet}
   */
  getAllObjet(req?: any) {
    super.getAllObjet(this.list_url, req);
  }
}
