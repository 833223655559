import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Account } from '../../../../model/account.model';
import { Validators } from '../../../../model/champs_formulaire/validators.model';
import { Observable, Subject } from 'rxjs';
import { BaseService } from '../../base/base.service';
import { FormService } from '../../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { ValidatorService } from '../validators/validator.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantValidators } from '../validators/validator.url';
import { ConstantHome } from '../../home/home.url';
import { Url } from '../../../../shared/constant/url.constant';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ValidatorAddComponent } from '../validators/add/validator-add.component';
import { ValidatorUpdateComponent } from '../validators/update/validator-update.component';
import { ValidatorDeleteComponent } from '../validators/delete/validator-delete.component';
import { ChampsFormulaire, SourceType } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { ConstantChampsFormulaires } from './champs-formulaire.url';
import { ChampsFormulaireService } from './champs-formulaire.service';
import { ChampsFormulaireAddComponent } from './add/champs-formulaire-add.component';
import { ChampsFormulaireUpdateComponent } from './update/champs-formulaire-update.component';
import { ChampsFormulaireDeleteComponent } from './delete/champs-formulaire-delete.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormulaireAddValidatorComponent } from './add/validator/formulaire-add-validator.component';
import { ConstantValidatorsAffected } from '../validator_affected/validator-affected.url';
import { IServiceItem } from '../../../../model/service-item.model';
import { UntypedFormControl } from '@angular/forms';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ServiceItemService } from '../../service-items/service-item.service';
import { SourceFormAddComponent } from './add/source-form/source-form-add.component';
import { ConstantSourceForm } from '../source-form/source-form.url';
import { SIZE_H_MD_POPUP, SIZE_W_MD_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { Formulaire } from '../../../../model/champs_formulaire/formulaire.model';
import { FormulaireService } from '../../formulaires/formulaire.service';

@Component({
  selector: 'app-champs-formulaire-list',
  templateUrl: './champs-formulaire-list.component.html'
})
export class ChampsFormulaireListComponent extends BasePageComponent
  implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  countriesList: ChampsFormulaire[];
  countriesSearch: ChampsFormulaire[];
  searchValue: string;
  initByQueryParam = false;

  serviceItemTyped = '';
  subs$ = new Subject<void>();

  serviceItemsList: Formulaire[] = [];
  serviceItemSelected: Formulaire;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<Formulaire[]>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public baseService: BaseService,
    private serviceItemService: ServiceItemService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private champsFormulaireService: ChampsFormulaireService,
    private formulaireService: FormulaireService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct formulaire list');
    this.pageData = {
      title: ConstantChampsFormulaires.data.sub.list.title,
      key: 'formulaire.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantChampsFormulaires.data.title,
          key: 'validator.name',
          route: Url.SLASH_ROUTE.concat(ConstantChampsFormulaires.urlFull
            .FEES.LIST)
        },
        {
          title: ConstantChampsFormulaires.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'formulaire.title') {
      translate.get('formulaire.title').subscribe(res => this.pageData.title = res);
    }

    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'formulaire.name') {
        translate.get('formulaire.name').subscribe(res => {
          console.log('## res: ', res);
          value.title = res;
        });
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }

    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init formulaire list');
    super.ngOnInit();
    this.activatedRoute.queryParams.subscribe(d => {
      console.log('## param changed');
      super.setLoaded();
      const res = d['service'];
      if (this.initByQueryParam) {
        console.log('## param: ', res);
        if (!res) {
          this.champsFormulaireService.query(this.champsFormulaireService.list_url)
            .subscribe(r => {
            console.log('## Success get champs formulaire list: ', r);
            this.countriesList = (r.body) ? r.body : [];
            // this.updateData(this.countriesList);
            this.countriesSearch = this.countriesList;
          });
        }
      }
      this.initByQueryParam = true;
    });
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.account = account;
          this.champsFormulaireService.query(this.champsFormulaireService.list_url)
            .subscribe(res => {
            console.log('## Success get champs formulaire list: ', res);
            this.countriesList = (res.body) ? res.body : [];
           // this.updateData(this.countriesList);
            this.countriesSearch = this.countriesList;
            super.setLoaded();
            this.champsFormulaireService.getObjetObs().pipe(takeUntil(this.subs$))
              .subscribe((res: ChampsFormulaire[]) => {
                console.log('## get list champs formulaire in list by subject: ', res);
                this.countriesList = (res) ? res : [];
               // this.updateData(this.countriesList);
                this.countriesSearch = this.countriesList;
              });
          }, error => {
              console.log('## error get list champs formulaire: ', error);
            });
          this.getFormulaireList(); // after 500ms.
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    console.log('## on destroy champs formulaire list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

  displayFn(subject: Formulaire) {
    return subject ? subject.titre : '';
  }

  getFormulaireList() {
    setTimeout(() => {
      this.formulaireService
        .query(this.formulaireService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<Formulaire[]>) => mayBeOk.ok),
          map((response: HttpResponse<Formulaire[]>) => response.body)
        )
        .subscribe((res: Formulaire[]) => {
            console.log('## success get list formulaire: ', res);
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.serviceItemsList = rs;
            this.filteredOptions = this.myControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list formulaire: ' + res.message);
          });
    }, 500);
  }

  private _filter(value: string): Formulaire[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option?.titre?.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter formulaire: ' + e.message);
    }
    return filteredList;
  }

  changeItem(event) {
    try {
      console.log('## select item');
      const si = event.option.value;
      console.log('## value item si: ', si);
      console.log('## value item: ' + si.titre);
      this.serviceItemSelected = si;
      this.searchServiceItem();
      this.router.navigate(['/' + ConstantChampsFormulaires.urlFull.FEES.LIST],
        { queryParams: { service: si.titre } });
    } catch (e) {
      console.log('## error selected value s item');
    }
  }

  searchServiceItem(): void {
    const query = this.serviceItemSelected.id;
    const result = this.countriesSearch.filter((obj : ChampsFormulaire) => {
      return obj?.formulaire?.id === query;
    });
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  updateData(data: ChampsFormulaire[]) {
    data.forEach(d => {
      try {
        d.serviceItemName = d.serviceItem.name;
      } catch (e) {
        console.log('## erreur: ', e);
      }
    });
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.countriesSearch].map(mapFn);
    const result = this.countriesSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length
        ? query.toLowerCase() : '') > -1
    );
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  canAddSource(d: ChampsFormulaire) {
    const r = d.sourceType.toString().toLowerCase()
      !== SourceType[SourceType.NONE].toString().toLowerCase();
    return r;
  }

  show(d: ChampsFormulaire) {
    console.log('## show');
    console.log('## id form: ', d.id);
    this.router.navigate(['/' + ConstantValidatorsAffected.urlFull.FEES.LIST],
      { queryParams: { [ConstantChampsFormulaires.url.FEES.FEES_]: d.id } });
  }

  addSource(d: ChampsFormulaire) {
    console.log('## open formulaire add source');
    this.champsFormulaireService.setObjetSelectObs(d);
    this.baseService.open(this.dialog, SourceFormAddComponent);
  }

  showSource(d: ChampsFormulaire) {
    console.log('## show');
    console.log('## id form: ', d.id);
    this.router.navigate(['/' + ConstantSourceForm.urlFull.FEES.LIST],
      { queryParams: { [ConstantChampsFormulaires.url.FEES.FEES_]: d.id } });
/*    this.router.navigate(['/' + ConstantValidatorsAffected.urlFull.FEES.LIST],
      { queryParams: { [ConstantFormulaires.url.FEES.FEES_]: d.id } });*/
  }

  createValidator(d: ChampsFormulaire) {
    console.log('## open formulaire add validator: ', d);
    this.champsFormulaireService.setObjetSelectObs(d);
    this.baseService.open(this.dialog, FormulaireAddValidatorComponent);
  }

  add() {
    console.log('## open formulaire add');
   // this.baseService.open(this.dialog, ChampsFormulaireAddComponent);
    this.dialog.open(ChampsFormulaireAddComponent, {
      height: SIZE_H_MD_POPUP,
      width: SIZE_W_MD_POPUP,
      data: {
        serviceItemSelected: this.serviceItemSelected
      }
    });
  }

  update(c: ChampsFormulaire) {
    this.champsFormulaireService.setObjetSelectObs(c);
    this.baseService.open(this.dialog, ChampsFormulaireUpdateComponent);
  }

  delete(c: ChampsFormulaire) {
    this.champsFormulaireService.setObjetSelectObs(c);
    this.baseService.open(this.dialog, ChampsFormulaireDeleteComponent);
  }

  /*  add() {
      console.log('## open formulaire add');
      this.baseService.openSm(this.dialog, ValidatorAddComponent);
    }

    liste(c: Validators) {
      this.champsFormulaireService.setCountrySelectObs(c);
      this.baseService.openSm(this.dialog, ValidatorUpdateComponent);
    }

    delete(c: Validators) {
      this.champsFormulaireService.setCountrySelectObs(c);
      this.baseService.openSm(this.dialog, ValidatorDeleteComponent);
    }*/

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): ChampsFormulaire[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }
}