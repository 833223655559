import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

//import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'src/app/util/request-util';
import { IMobileAccount, MobileAccountCash } from 'src/app/model/mobile-account.model';
import { BASE_URL } from 'src/environments/environment';
import { AccountDTO } from '../../model/iaccount.model';


type EntityResponseType = HttpResponse<IMobileAccount>;
type EntityArrayResponseType = HttpResponse<IMobileAccount[]>;

@Injectable({ providedIn: 'root' })
export class AccountUserService {
  public accountCashUrl = BASE_URL + 'mobile-accounts/cash';
  public resourcePropertyUrl = BASE_URL + 'mobile-accounts/isMyProperty';
  public resourceSatusUrl = BASE_URL + 'mobile-accounts/status';
  public accountServiceUrl = BASE_URL + 'mobile-accounts/service';
  public resourceUrl = BASE_URL + 'mobile-accounts';
  public resourceUrl2 = BASE_URL + 'mobile-accounts/CUserLogin';
  public resourceUrl3 = BASE_URL + 'mobile-account';
  public resourceUrl4 = BASE_URL + 'mobile-account/phoneNumber';

  private mAccounts = new Subject<IMobileAccount[]>();
  private mobileAccountsCash = new Subject<MobileAccountCash[]>();

  // le mAccount sélectionné dans la liste.
  private mAccountSelect = new BehaviorSubject<IMobileAccount>(undefined);

  constructor(protected http: HttpClient) {
  }

  /**
   * Récupère les accounts avec cash de l'utilisateur.
   * @param option
   */
  findAllCash(option?: any): Observable<HttpResponse<MobileAccountCash[]>> {
    return this.http.get<MobileAccountCash[]>(this.accountCashUrl,
      { params: option, observe: 'response' });
  }

  findAllCashForSubject() {
    setTimeout(() => {
      this.findAllCash()
        .subscribe((res: HttpResponse<MobileAccountCash[]>) => {
          console.log('## success find all account with cash');
          const d = (res && res.body) ? res.body : [];
          console.log('## data: ', d);
          console.log('## length: ', d.length);
          this.setMAccountCashObs(d);
        }, error => {
          console.log('## Error find all account with cash: ', error);
        });
    }, 300);
  }

  /**
   * Permet de récupérer tous les accounts utilisés par un service
   * @param option
   */
  findAccounts(option: any): Observable<HttpResponse<AccountDTO[]>> {
    return this.http.get<AccountDTO[]>(this.accountServiceUrl,
      { params: option, observe: 'response' });
  }

  getMAccountSelectObs(): Observable<IMobileAccount> {
    return this.mAccountSelect.asObservable();
  }

  setMAccountSelectObs(c: IMobileAccount) {
    this.mAccountSelect.next(c);
  }

  getMAccountObs() {
    return this.mAccounts.asObservable();
  }

  setMAccountObs(c: IMobileAccount[]) {
    this.mAccounts.next(c);
  }

  getMAccountCashObs() {
    return this.mobileAccountsCash.asObservable();
  }

  setMAccountCashObs(c: MobileAccountCash[]) {
    return this.mobileAccountsCash.next(c);
  }

  /**
   * Permet de récupérer la liste des mAccounts
   * pour être partagée.
   */
  getAllMAccount(id: number) {
    setTimeout(() => {
      this.findAllAccountsByCUserId(id).subscribe(res => {
        this.setMAccountObs((res.body) ? res.body : []);
      });
    }, 300);
  }

  findAccountUser(option: any): Observable<EntityResponseType> {
    return this.http.get<IMobileAccount>(`${this.resourcePropertyUrl}`, { params: option, observe: 'response' });
  }

  create(mobileAccount: IMobileAccount): Observable<EntityResponseType> {
    return this.http.post<IMobileAccount>(this.resourceUrl, mobileAccount, { observe: 'response' });
  }


  /**
   * Modifie le status d'un account_user.
   * @param mobileAccount
   */
  updateStatus(mobileAccount: IMobileAccount): Observable<EntityResponseType> {
    return this.http.put<IMobileAccount>(this.resourceSatusUrl, mobileAccount, { observe: 'response' });
  }

  update(mobileAccount: IMobileAccount): Observable<EntityResponseType> {
    return this.http.put<IMobileAccount>(this.resourceUrl, mobileAccount, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IMobileAccount>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findByNumberAccount(numberAccount: string): Observable<EntityResponseType> {
    return this.http.get<IMobileAccount>(`${this.resourceUrl3}/${numberAccount}`, { observe: 'response' });
  }

  findByPhoneNumber(phoneNumber: string): Observable<EntityResponseType> {
    return this.http.get<IMobileAccount>(`${this.resourceUrl4}/${phoneNumber}`, { observe: 'response' });
  }

  findAllAccountsByCUserId(id: number, req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IMobileAccount[]>(`${this.resourceUrl}/${id}`, { params: options, observe: 'response' });
  }

  findAllAccountsByCUserLogin(login: string, req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IMobileAccount[]>(`${this.resourceUrl2}/${login}`, { params: options, observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IMobileAccount[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
}
