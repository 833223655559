import { Injectable } from '@angular/core';

export interface ICUserDetailManageColumnName {
  id: string;
  label: string;
  preferenceCurrency: string;
  
}

@Injectable({ providedIn: 'root' })
export class CUserDetailManageColumnName {
  getColumnName(): ICUserDetailManageColumnName {
    return {
      id: 'id',
      label: 'label',
      preferenceCurrency: 'preferenceCurrency'
   
    };
  }
}
