import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { CuserDetailManageModalComponent } from './add/cuser-detail-manage-modal.component';

import { CuserDetailManageComponent } from './cuser-detail-manage.component';
import { PagesModule } from '../../pages.module';
import { NzButtonModule } from 'ng-zorro-antd/button';

/**
 * Ce module permet de gérer le model costRule.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [CuserDetailManageComponent, CuserDetailManageModalComponent]
})
export class CuserDetailManageModule {}
