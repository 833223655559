import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class CostsRuleValidator {

  readonly AMOUNT_A_VALIDATOR = [null,
    [Validators.required, Validators.pattern('^[0-9]*$')]];

  getCountryBValidator(c: any) {
    return [null, [c.interValidator.bind(c)]];
  }
}
