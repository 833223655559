import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Permet d'effectuer des contrôles sur les champs_formulaires.
 */
@Injectable({ providedIn: 'root' })
export class FormService {
  constructor() {
  }

  format(n: number) {
    try {
      return (n).toLocaleString('en-us', { minimumFractionDigits: 2 });
    } catch (e) {
      console.log('## error formatting: ' + e.message);
    }
  }

  /**
   * Libère les ressources.
   * @param subs$
   */
  free(subs$: Subject<void>) {
    try {
      subs$.next();
      subs$.complete();
    } catch (e) {
      console.log('## error closing Subject: ' + e.message);
    }
  }

  /**
   * Vérifie si un champ du formulaire a dépassé
   * le nombre maximum de caractère requis.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  getMinLength(editForm: UntypedFormGroup, name: string): number {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    let min = 0;
    try {
      min = control.errors.minlength.requiredLength;
    } catch (e) {
      min = 0;
    }
    return min;
  }

  /**
   * Vérifie si un champ du formulaire a dépassé
   * le nombre maximum de caractère requis.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  getMaxLength(editForm: UntypedFormGroup, name: string): number {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    let max = 0;
    try {
      max = control.errors.maxlength.requiredLength;
    } catch (e) {
      max = 0;
    }
    return max;
  }

  /**
   * Vérifie si un champ du formulaire ne respecte pas le pattern.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  hasError(editForm: UntypedFormGroup, name: string, validator: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.hasError(validator) : null;
  }


  /**
   * Vérifie si un champ du formulaire ne respecte pas le pattern.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  hasErrorEmailControl(control: UntypedFormControl): boolean {
    return (control) ? control.hasError('email') : null;
  }

  /**
   * Vérifie si un champ du formulaire ne respecte pas le pattern.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  hasErrorEmail(editForm: UntypedFormGroup, name: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.hasError('email') : null;
  }

  /**
   * Vérifie si un champ du formulaire ne respecte pas le pattern.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  hasErrorPattern(editForm: UntypedFormGroup, name: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.hasError('pattern') : null;
  }

  /**
   * Vérifie si un champ du formulaire est inférieur au nombre minimum
   * de caractère requis.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  hasErrorMinLength(editForm: UntypedFormGroup, name: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.hasError('minlength') : null;
  }

  /**
   * Vérifie si un champ du formulaire a dépassé
   * le nombre maximum de caractère requis.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  hasErrorMaxLength(editForm: UntypedFormGroup, name: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.hasError('maxlength') : null;
  }

  /**
   * Vérifie si un champ du formulaire est requis.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  isRequiredControl(control: UntypedFormControl): boolean {
    return (control) ? control.hasError('required') : null;
  }

  /**
   * Vérifie si un champ du formulaire est requis.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  isRequired(editForm: UntypedFormGroup, name: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.hasError('required') : null;
  }

  /**
   * Vérifie si un formulaire est invalide.
   * @param editForm le formulaire
   * @Return true si le formulaire est invalide,
   * false dans le cas contraire.
   */
  isInvalidOrPristineForm(editForm: UntypedFormGroup): boolean {
    return (editForm) ? (editForm.invalid || editForm.pristine) : null;
  }

  /**
   * Vérifie si un formulaire est invalide.
   * @param editForm le formulaire
   * @Return true si le formulaire est invalide,
   * false dans le cas contraire.
   */
  isInvalidForm(editForm: UntypedFormGroup): boolean {
    return (editForm) ? editForm.invalid : null;
  }

  isInvalidControl(control: UntypedFormControl): boolean {
    return (control) ? control.invalid : null;
  }

  isInvalidOrPristineControl(control: UntypedFormControl): boolean {
    return (control) ? (control.invalid || control.pristine) : null;
  }

  /**
   * Vérifie si un champ du formulaire est invalide.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  isInvalid(editForm: UntypedFormGroup, name: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.invalid : null;
  }

  isTouchedControl(control: UntypedFormControl): boolean {
    return (control) ? control.touched : null;
  }

  /**
   * Vérifie si un champ du formulaire a perdu le focus.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire a perdu le focus,
   * false dans le cas contraire.
   */
  isTouched(editForm: UntypedFormGroup, name: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.touched : null;
  }

  /**
   * Vérifie si un champ du formulaire a déjà été intéragi
   * par l'utilisateur.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire a déjà été intéragi
   * par l'utilisateur, false dans le cas contraire.
   */
  isDirtyControl(control: UntypedFormControl): boolean {
    return (control) ? control.dirty : null;
  }

  /**
   * Vérifie si un champ du formulaire a déjà été intéragi
   * par l'utilisateur.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire a déjà été intéragi
   * par l'utilisateur, false dans le cas contraire.
   */
  isDirty(editForm: UntypedFormGroup, name: string): boolean {
    const control: AbstractControl = (editForm) ? editForm.get(name) : null;
    return (control) ? control.dirty : null;
  }

  /**
   * Vérifie si un champ du formulaire est invalide.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  isInvalidDirtyOrTouchedControl(control: UntypedFormControl): boolean {
    let result = false;
    if (control) {
      if (this.isInvalidControl(control) && (this.isDirtyControl(control)
        || this.isTouchedControl(control))) {
        result = true;
      }
    }
    return result;
  }

  /**
   * Vérifie si un champ du formulaire est invalide.
   * @param editForm le formulaire
   * @param name le nom de l'input dans le formulaire.
   * @Return true si le champs du formulaire est invalide,
   * false dans le cas contraire.
   */
  isInvalidDirtyOrTouched(editForm: UntypedFormGroup, name: string): boolean {
    let result = false;
    if (editForm) {
      if (this.isInvalid(editForm, name) && (this.isDirty(editForm, name)
        || this.isTouched(editForm, name))) {
        result = true;
      }
    }
    return result;
  }
}
