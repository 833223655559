import { Injectable } from '@angular/core';

export interface ICountryColumnName {
  id: string;
  label: string;
  codeCountry: string;
  codePhone: string;
  mId: string;
  idCountry: string;
  labelCountry: string;
  mLabel: string;
  mLabelCourt: string;
  mValue: string;
  mUnit: string;
  mCode: string;
}

@Injectable({ providedIn: 'root' })
export class CountryColumnName {
  getColumnName(): ICountryColumnName {
    return {
      id: 'id',
      label: 'label',
      idCountry: 'idCountry',
      labelCountry: 'labelCountry',
      codeCountry: 'codeCountry',
      codePhone: 'codePhone',
      mId: 'mId',
      mLabel: 'mLabel',
      mLabelCourt: 'mLabelCourt',
      mValue: 'mValue',
      mUnit: 'mUnit',
      mCode: 'mCode'
    };
  }
}
