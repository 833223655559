import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour la page d'accueil.
 */
export namespace ConstantHome {
  export const data = {
    title: 'Home',
    routing: 'home'
  };
}

export const HOME_ROUTING = Url.SLASH_ROUTE.concat(ConstantHome.data.routing);
