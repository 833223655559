import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { TokenStorageService } from 'src/app/services/storage/token-storage.service';

import { fadeIn } from 'src/app/animations/form-error';
import { FormService } from '../../../../shared/services/form.service';
import { TIME_MIN_REQUEST, TOKEN } from '../../../../shared/constant/request.contant';
import { ROUTE_HOME_FULL } from '../../../../layout/components/home.route';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { Account } from '../../../../model/account.model';
import { takeUntil } from 'rxjs/operators';
import { Settings } from '../../../../model/settings.model';

@Component({
  selector: 'page-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: [fadeIn]
})
export class PageSignInComponent implements OnInit {
  loginForm: UntypedFormGroup;
  readonly passRoute = ROUTE_HOME_FULL.forgotPassword;

  serverError = false;
  successAuth = false;
  isAuthenticating = false;

  settings: Settings;

  constructor(
    private accountService: AccountService,
    private formService: FormService,
    private router: Router,
              private authservice: LoginServiceService,
              private tokenStorageService: TokenStorageService) {
  }

  ngOnInit() {
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(null,
        [Validators.required]),
      password: new UntypedFormControl(null,
        [Validators.required])
    });

    this.accountService.getVersion().subscribe(res => {
      console.log('res:', res?.body);
      this.settings = res?.body;
    })
  }

  login() {
      this.isAuthenticating = true;
      this.serverError = false;
      console.log(this.loginForm.value);
      this.accountService.login(this.loginForm.value).subscribe((result) => {
        this.responseFromServer();
        if (result) {
          console.log('RESULT', result);
          this.successAuth = true;
          this.serverError = false;
          this.waitForNotifications();
          this.router.navigate([ROUTE_HOME_FULL.accueil]);
        } else {
          console.log('## error authenticate', this.loginForm.value);
          this.serverError = true;
        }
      }, (err) => {
        console.log('## error authenticate: ', this.loginForm.value);
        this.responseFromServer();
        this.serverError = true;
      });
  }

  /**
   * Permet de souscrire un topic à firebase
   */
  waitForNotifications() {
    this.accountService.identity(true)
      .subscribe(ac => {
        console.log('## account: ', ac);
        console.log('## appel de requestToken: ');
        this.accountService.requestToken();
        console.log('## appel de requestToken2: ');


        // this.accountService.requestPermission(ac);
       // this.accountService.listen();
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isAuthenticating = false;
    }, TIME_MIN_REQUEST)
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.loginForm);
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }
}
