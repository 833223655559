import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from '../../base-page';
import { DepotComponent } from './depot/depot.component';
import { RetraitCodeComponent } from './retrait-code/retrait-code.component';
import { RetraitComponent } from './retrait/retrait.component';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFormComponent } from '../cash/dynamic-form/dynamic-form.component';
import { CONTAINER_DIALOG_POPUP } from '../../../shared/constant/css-style/css-class.constant';
import {
  ID_SERVICE_ITEM_DEPOT_ORANGE_MONEY, ID_SERVICE_ITEM_INFORMATION_CODE_COMPLET_ORANGE_MONEY,
  ID_SERVICE_ITEM_INFORMATION_CODE_PARTIEL, ID_SERVICE_ITEM_INFORMATION_CODE_PARTIEL_ORANGE_MONEY,
  ID_SERVICE_ITEM_RETRAIT_CODE_CASH, ID_SERVICE_ITEM_RETRAIT_CODE_COMPLET_ORANGE_MONEY, ID_SERVICE_ITEM_RETRAIT_CODE_ORANGE_MONEY,
  ID_SERVICE_ITEM_TRANSFER_CODE, ID_SERVICE_ITEM_VERIFICATION_CODE_COMPLET_ORANGE_MONEY,
  ID_SERVICE_ITEM_VERIFICATION_CODE_PARTIEL, ID_SERVICE_ITEM_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY,
  SERVICE_ITEM_ID_DEPOT_ORANGE_MONEY, SERVICE_ITEM_ID_INFORMATION_CODE_COMPLET_ORANGE_MONEY,
  SERVICE_ITEM_ID_INFORMATION_CODE_PARTIEL, SERVICE_ITEM_ID_INFORMATION_CODE_PARTIEL_ORANGE_MONEY,
  SERVICE_ITEM_ID_RETRAIT_CODE_CASH, SERVICE_ITEM_ID_RETRAIT_CODE_COMPLET_ORANGE_MONEY, SERVICE_ITEM_ID_RETRAIT_CODE_ORANGE_MONEY,
  SERVICE_ITEM_ID_TRANSFER_CODE_INTER, SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET_ORANGE_MONEY,
  SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY
} from '../../../shared/constant/service-item.constant';
import { VerificationCodeComponent } from '../cash-dynamic/verification-code/verification-code.component';
import { RetraitCodePartielComponent } from '../cash-dynamic/retrait-code-partiel/retrait-code-partiel.component';
import { RetraitOrangeMoneyInfosComponent } from './retrait-orange-money-infos/retrait-orange-money-infos.component';
import { CashDataModel, ICashModel } from '../cash/cash-data-model';

@Component({
  selector: 'app-mobile-money',
  templateUrl: './mobile-money.component.html',
  styleUrls: ['./mobile-money.component.css']
})
export class MobileMoneyComponent extends BasePageComponent implements OnInit, OnDestroy {
  
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private dialog: MatDialog,
    private translate: TranslateService
    ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      key:'mobileMoney',
      breadcrumbs: [
        {
          title: '',
          key:'home',
          route: 'home',
        },
        {
          title: '',
          key:'mobileMoney'
        },
      ],
    };
    if(this.pageData.key==="mobileMoney"){
      translate.get('mobileMoney').subscribe(res => this.pageData.title=res);
    }
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="mobileMoney" ){
        translate.get('mobileMoney').subscribe(res => value.title=res);
      }    
     
    });
   }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData('assets/data/table.json', 'tableData', 'setLoaded');
  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openDepotMm() {
    console.log('## open dépôt');
    let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_DEPOT_ORANGE_MONEY;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_DEPOT_ORANGE_MONEY;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_DEPOT_ORANGE_MONEY;
    this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        cashDataModel: d
      }
    });
  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openRetraitCodeAllCashOut() {
    console.log('## open retrait code complet par orange money');
    let d = new CashDataModel();
    d.dataCode.title = this.translate.instant('RslRetraitCodeCompletOrangeMoney');
    d.dataCode.component = RetraitCodePartielComponent;
    d.dataCode.componentInfos = RetraitOrangeMoneyInfosComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_RETRAIT_CODE_COMPLET_ORANGE_MONEY;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_RETRAIT_CODE_COMPLET_ORANGE_MONEY;
    d.dataCode.idServiceItemVerif = ID_SERVICE_ITEM_VERIFICATION_CODE_COMPLET_ORANGE_MONEY;
    d.dataCode.serviceItemIdVerif = SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET_ORANGE_MONEY;
    d.dataCode.serviceItemIdInfos = SERVICE_ITEM_ID_INFORMATION_CODE_COMPLET_ORANGE_MONEY;
    d.dataCode.idServiceItemInfos = ID_SERVICE_ITEM_INFORMATION_CODE_COMPLET_ORANGE_MONEY;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_VERIFICATION_CODE_COMPLET_ORANGE_MONEY;
    this.dialog.open(VerificationCodeComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        cashDataModel: d
      }
    });

  }

  /**
   * Ouvre le template html {@link EnvoiCodeComponent}
   * dans un popup.
   */
  openRetraitCodePartialCashOut() {
    console.log('## open retrait code partiel par orange money');
    let d = new CashDataModel();
    // this.cashService.dataCode.title = this.translate.instant('RslRetraitCodePartielOrangeMoney');
    d.dataCode.title = 'Résultat Retrait Code Partiel par Orange Money';
    d.dataCode.component = RetraitCodePartielComponent;
    d.dataCode.componentInfos = RetraitOrangeMoneyInfosComponent;
    d.dataCode.idServiceItem = ID_SERVICE_ITEM_RETRAIT_CODE_ORANGE_MONEY;
    d.dataCode.serviceItemId = SERVICE_ITEM_ID_RETRAIT_CODE_ORANGE_MONEY;
    d.dataCode.idServiceItemVerif = ID_SERVICE_ITEM_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY;
    d.dataCode.serviceItemIdVerif = SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY;
    d.dataCode.serviceItemIdInfos = SERVICE_ITEM_ID_INFORMATION_CODE_PARTIEL_ORANGE_MONEY;
    d.dataCode.idServiceItemInfos = ID_SERVICE_ITEM_INFORMATION_CODE_PARTIEL_ORANGE_MONEY;
    d.dataCode.serviceItemIdInter = SERVICE_ITEM_ID_VERIFICATION_CODE_PARTIEL_ORANGE_MONEY;
    this.dialog.open(VerificationCodeComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        cashDataModel: d
      }
    });
  }

  /*openDepotMm(){
    console.log('## open depot');
    this.dialog.open(DepotComponent, {
      width:"600px",
      height: "700px"
    });
  }*/

  openRetraitMm(){
    console.log('## open retrai');
    this.dialog.open(RetraitComponent, {
      width:"600px",
      height: "700px"
    });

  }
  openRetraitCodeMm(){
    console.log('## open retrait par code');
    this.dialog.open(RetraitCodeComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP
    });

  }
  

}
