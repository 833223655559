import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService } from '../../ServiceEntity/account.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Account } from '../../../model/account.model';

/**
 * Permet de vérifier si un utilisateur est authentifié
 * à fin de pouvoir décider si on lui donne accès aux routes enfants.
 */
@Injectable({
  providedIn: 'root'
})
export class GuardService {
  constructor(private router: Router,
              private accountService: AccountService
  ) {}

  /**
   * Si l'utilisateur est authentifié, alors on lui donne
   * accès aux routes enfants.
   * @param route
   * @param state
   * @Return true si l'utilisateur est authentifié,
   * false dans le cas contraire et il sera redirigé
   * vers la page d'authentification.
   */
  isAuthenticate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.accountService.getAccount().pipe(catchError((err) => {
      console.log('## error get account');
      this.accountService.logout(); // avec redirection.
      return of(false);
    }),
      map((res: Account) => {
      if (res) {
        console.log('## success get account:', res);
        return true;
      }
      this.accountService.logout(); // avec redirection.
      return false;
    }));
  }

}
