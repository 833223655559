import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService } from '../../ServiceEntity/account.service';
import { Observable } from 'rxjs';
import { ROLE } from '../../../model/account.model';
import { Url } from '../../../shared/constant/url.constant';
import ERR_404 = Url.ERR_404;

/**
 * Permet de vérifier si un utilisateur possède le role
 * {@link ROLE.partener} afin de pouvoir décider
 * si on lui donne accès à la route.
 */
@Injectable({
  providedIn: 'root'
})
export class GuardAuthoritiesService {
  constructor(
    private router: Router,
    private accountService: AccountService
  ) {

  }

  /**
   * Si l'utilisateur possède l'un des 2 roles en paramètre
   * alors on lui donne accès à la route.
   * @param route
   * @param state
   * @Return true si l'utilisateur possède l'un des 2 roles en paramètre
   * false dans le cas contraire et il sera redirigé
   * vers la page d'erreur.
   */
  hasRoleAtLeastOneIn2(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
           roles: string[])
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role1 = this.accountService
      .hasAutority((roles && roles[0]) ? roles[0] : undefined);
    const role2 = this.accountService
      .hasAutority((roles && roles[1]) ? roles[1] : undefined);
    if(role1 || role2) {
      return true;
    }
    this.router.navigate([ERR_404]);
    return false;
  }


  /**
   * Si l'utilisateur possède le role {@link ROLE.partener}
   * alors on lui donne accès à la route.
   * @param route
   * @param state
   * @Return true si l'utilisateur possède le role {@link ROLE.partener} ,
   * false dans le cas contraire et il sera redirigé
   * vers la page d'erreur.
   */
  hasRole(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
              role: string)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let access = this.accountService.hasAutority(role);
    console.log('## verify role');
    if(access) {
      return access;
    }
    this.router.navigate([ERR_404]);
    return false;
  }

}
