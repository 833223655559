import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les fees & benefits.
 */
export namespace ConstantFeesBenefits {
  export const data = {
    title: 'Fees & Benefits Rule',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un Fees & Benefits Rule'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des Fees & Benefits Rule'
      }
    }

  }
  export const url = {
    FEES: {
      FEES_: 'fees_benefits_rule',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    FEES: {
      ADD: ConstantFeesBenefits.url.FEES.FEES_
        .concat(Url.SLASH_ROUTE).concat(ConstantFeesBenefits.url.FEES.ADD),
      LIST: ConstantFeesBenefits.url.FEES.FEES_
        .concat(Url.SLASH_ROUTE).concat(ConstantFeesBenefits.url.FEES.LIST),
    }
  };
}
