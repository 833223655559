export interface IApiKeyCDTO {
  id?: number;
  label?: string;
  idAccount?: number;
}

export class ApiKeyCDTO implements IApiKeyCDTO {
  constructor(
    public id?: number,
    public label?: string,
    public idAccount?: number
  ) {
  }
}

export interface IApiKeyDTO {
  id?: number;
  label?: string;
  labelAccount?: string;
  codeMoney?: string;
  keyValue?: string;
  idAccount?: number;
  numberAccount?: number;
  statusLabel?: string;
  statusId?: number;
  cUserId?: number;
  createdDate?: string;
  lastModifiedDate?: string;
}


export interface IApikey {
  id?: number;
  label?: string;
  keyValue?: string;
  idPartener?: number;
  idAccount?: number;
  status?: string;
  ipRestrictiction?: boolean;
  ipAuthorized?: string;
  numberAccount?: string;
  createdBy?: string;
  createdDate?: string;
}

export class Apikey implements IApikey {
  constructor(
    public id?: number,
    public label?: string,
    public keyValue?: string,
    public idPartener?: number,
    public idAccount?: number,
    public status?: string,
    public ipRestrictiction?: boolean,
    public ipAuthorized?: string,
    public numberAccount?: string,
    public createdBy?: string,
    public createdDate?: string
  ) {}
}
