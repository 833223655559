import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { Account } from 'src/app/model/account.model';
import { CUser, ICUser } from 'src/app/model/c-user.model';
import { IMobileAccount } from 'src/app/model/mobile-account.model';
import { ITransaction, Transaction } from 'src/app/model/transaction.model';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { TIME_MEDIUM_REQUEST } from 'src/app/shared/constant/request.contant';
import { FormService } from 'src/app/shared/services/form.service';
import { Content } from 'src/app/ui/interfaces/modal';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { MobileMoneyService } from '../mobile-money.service';
import { RetraitCodeService } from '../retrait-code.service';

@Component({
  selector: 'app-retrait',
  templateUrl: './retrait.component.html',
  styleUrls: ['./retrait.component.css']
})
export class RetraitComponent implements OnInit {

  isSaving: boolean;
  successTransfert = false;
  errorTransfert = false;
  loadingMessagesForm: boolean;

  MobileMoneyRetrait: UntypedFormGroup;

  accountNumber: string;
  userLogin: string;
  ReponseTransfert: any;
  itemsDepotMobileMoney = [];

  currentCUser: CUser;
  account: Account | null = null;
  itemsRetraitMobileMoney = [];
  mAccounts: IMobileAccount[];

  constructor(
    private retraitCodeService: RetraitCodeService,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private mAccountService: AccountUserService,
    private cUserService: CUserService,
    private formService: FormService,
    private loginService: LoginServiceService,
    private mobileMoneyService: MobileMoneyService,
    private modal: TCModalService,
  ) {
    this.loadingMessagesForm = false;
  }

  ngOnInit(): void {
    this.accountService.identity().subscribe(account => {
      this.account = account;
      this.userLogin = account.login;
      this.mAccountService
        .findAllAccountsByCUserLogin(account.login)
        .pipe(
          filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
          map((res: HttpResponse<IMobileAccount[]>) => res.body)
        )
        .subscribe((res: IMobileAccount[]) => {
          this.mAccounts = res.filter(value => value.label === 'Main Account');
          this.accountNumber = this.mAccounts[0].numberAccount;
        });
      this.cUserService
        .findOneByUserLogin(account.login)
        .pipe(
          filter((res: HttpResponse<ICUser>) => res.ok),
          map((res: HttpResponse<ICUser>) => res.body)
        )
        .subscribe((res: ICUser) => {
          this.currentCUser = res;
        });
    });
    this.MobileMoneyRetrait = this.fb.group({
      numberAccount: [''],
      serviceItemsId: ['', [Validators.required]],
      valueCode: [''],
      receiverNumTel: ['', [Validators.required, Validators.pattern('^([0-9]{9})$')]],
      sentAmount: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.itemsDepotMobileMoney = [
      {
        fs: 'Orange',
        itemName: 'ORANGE MONEY',
        serviceItemsId: 5
      },
      {
        fs: 'Free',
        itemName: 'FREE MONEY',
        serviceItemsId: 17
      },
      {
        fs: 'Expresso',
        itemName: 'E-MONEY',
        serviceItemsId: 14
      }
    ];
}

    /*this.itemsRetraitMobileMoney = [
      {
        fs: 'Orange',
        itemName: 'ORANGE MONEY',
        serviceItemsId: 15
      },
      {
        fs: 'Free',
        itemName: 'FREE MONEY',
        serviceItemsId: 18
      },
      {
        fs: 'Expresso',
        itemName: 'E-MONEY',
        serviceItemsId: 19
      }
    ];*/


  private createFromFormMobileMoneyRetrait(): ITransaction {
    return {
      ...new Transaction(),
      serviceItemId: this.MobileMoneyRetrait.get(['serviceItemsId']).value,
      receiverNumTel: this.MobileMoneyRetrait.get(['receiverNumTel']).value,
      sentAmount: this.MobileMoneyRetrait.get(['sentAmount']).value,
      // numberAccount: this.MobileMoneyRetrait.get(['numberAccount']).value
      numberAccount: this.accountNumber
    };
  }
  reset(){}
  onSubmitRetrait(forModal: any, forfooter?: any){
    console.log('## onSubmitRetrait');
    const code = this.retraitCodeService.getCode();
    this.isSaving = true;
    this.loadingMessagesForm = true;
    const mobileMoney = this.createFromFormMobileMoneyRetrait();
    this.loginService
      .loginProcess({
        username: this.userLogin,
        password: this.MobileMoneyRetrait.get('password').value
      })
      .subscribe(
        () => {
          this.mobileMoneyService
            .retraitCode(mobileMoney)
            .pipe(
              filter((res: HttpResponse<ITransaction>) => res.ok),
              map((res: HttpResponse<ITransaction>) => res.body)
            )
            .subscribe(
              res => {
                this.successTransfert = true;
                this.isSaving = false;
                this.manageMessageWhenSucceed(res);                
                this.showDetailsRetraitMM(forModal,
                  'Details Transaction', null,
                  {
                    closeButton: true,
                    overlayClose: false
                  }); //
                
              },
              error => {
               
                console.error(error);
              }
            );
        },
        error => {
          console.error(error);
        }
      );
  }

  manageMessageWhenError() {
    this.successTransfert = false;
    this.loadingMessagesForm = false;
    this.errorTransfert = true;
  }

   /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
    private manageMessageWhenSucceed(response) {
      this.errorTransfert = false;
      this.successTransfert = true;
      this.loadingMessagesForm = false;
      this.ReponseTransfert = response;   
      this.MobileMoneyRetrait.reset();
    }

    showDetailsRetraitMM(modal, title: string, footer, options){
      console.log('## showDetailsEnvoieCode');
      console.log('## modal: ', modal);
      console.log('## title: ', title);
      setTimeout(() => {
        this.openModal(modal, title, footer, options, 'zindex-result');
      }, TIME_MEDIUM_REQUEST);
    }


  openModal<T>(body: Content<T>, header: Content<T> = null,
    footer: Content<T> = null, options: any = null,
    theClass?: string) {
    this.modal.open({
    body: body,
    header: header,
    footer: footer,
    options: options,
    theClass: theClass
    });
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.MobileMoneyRetrait);
  }

}
