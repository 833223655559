import { Injectable } from '@angular/core';
import { ValidationFormService } from './validation-form.service';
import { BaseValidationService, DataFormDynamic } from './base-validation.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { CONTAINER_DIALOG_POPUP } from '../../../../shared/constant/css-style/css-class.constant';
import { MatDialog } from '@angular/material/dialog';
import { EnvoiCodeInfosTransacComponent } from '../envoi-code-infos-transac/envoi-code-infos-transac.component';
import { ICashModel } from '../cash-data-model';


/**
 * Permet de valider le formulaire d'envoi code
 */
@Injectable({ providedIn: 'root' })
export class EnvoiCodeValidationService extends BaseValidationService
  implements ValidationFormService {


  constructor(
    private dialog: MatDialog,
    protected modal: TCModalService,
    private transactionsService: TransactionsService) {
    super(modal);
  }

  /**
   * @see ValidationFormService#valider
   */
  valider(d: DataFormDynamic, c: ICashModel): any {
    console.log('## validation envoi code: ', c);
    c.cashDataModel.dataForm = d;
    this.dialog.open(EnvoiCodeInfosTransacComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: c
    });
  }
}