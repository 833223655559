import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { Account } from 'src/app/model/account.model';
import { CUser, ICUser } from 'src/app/model/c-user.model';
import { IMobileAccount } from 'src/app/model/mobile-account.model';
import { ITransaction, Transaction } from 'src/app/model/transaction.model';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { TIME_MEDIUM_REQUEST } from 'src/app/shared/constant/request.contant';
import { Content } from 'src/app/ui/interfaces/modal';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { MobileMoneyKey } from '../mobile-mobey-key';
import { MobileMoneyService } from '../mobile-money.service';
import { RetraitCodeService } from '../retrait-code.service';

@Component({
  selector: 'app-partial-cash-out',
  templateUrl: './partial-cash-out.component.html',
  styleUrls: ['./partial-cash-out.component.css']
})
export class PartialCashOutComponent implements OnInit {
  PartialCachOutForm:UntypedFormGroup;

  account: Account | null = null;
  mAccounts: IMobileAccount[];
  currentCUser: CUser;

  loadingAllCashOut: boolean;
  successTransfert = false;
  errorTransfert = false;
  loadingMessagesForm: boolean;
  isSaving: boolean;

  accountNumber: string;
  userLogin: string;

 

  code:any;
  ReponseTransfert: any;
  itemsRetraitMobileMoney: { fs: string; itemName: string; serviceItemsId: number; }[];

  constructor
  (
    private fb:UntypedFormBuilder,
    private retraitCodeService:RetraitCodeService,
    private mobileMoneyKey:MobileMoneyKey,
    private accountService: AccountService,
    private mAccountService: AccountUserService,
    private cUserService: CUserService,
    private mobileMoneyService: MobileMoneyService,
    private modal: TCModalService,
    private loginService: LoginServiceService,
  ) { 
    this.initPartialCashOutForm();
  }

  ngOnInit(): void {
    this.itemsRetraitMobileMoney =  [
      {
        fs: 'Orange',
        itemName: 'ORANGE MONEY',
        serviceItemsId: 5
      },
      {
        fs: 'Free',
        itemName: 'FREE MONEY',
        serviceItemsId: 18
      },
      {
        fs: 'Expresso',
        itemName: 'E-MONEY',
        serviceItemsId: 19
      }
    ];
    this.code=this.retraitCodeService.getCode();
    this.accountService.identity().subscribe(account => {
      this.account = account;
      this.userLogin = account.login;
      this.mAccountService
        .findAllAccountsByCUserLogin(account.login)
        .pipe(
          filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
          map((res: HttpResponse<IMobileAccount[]>) => res.body)
        )
        .subscribe((res: IMobileAccount[]) => {
          this.mAccounts = res.filter(value => value.label === 'Main Account');
          this.accountNumber = this.mAccounts[0].numberAccount;
        });
      this.cUserService
        .findOneByUserLogin(account.login)
        .pipe(
          filter((res: HttpResponse<ICUser>) => res.ok),
          map((res: HttpResponse<ICUser>) => res.body)
        )
        .subscribe((res: ICUser) => {
          this.currentCUser = res;
        });
    });
    
   
  }

  //for partial Cash Out
  initPartialCashOutForm() {
    this.PartialCachOutForm = this.fb.group({
      receivedAmount: ['', [Validators.required]],
      sentAmount: [''],
      valueCode: [''],
      password: ['', [Validators.required]],
      serviceItemId: ['', [Validators.required]],
      numberAccount: [''],
      receiverNumTel: ['']
      
    });
  }

  DisableRetraitCodeSuccess() {
    this.retraitCodeService.setElement(this.mobileMoneyKey.appPartialCashOut, false);
    this.retraitCodeService.setElement(this.mobileMoneyKey.appRetraitCodeValide, true);
  }
  //All Cash Out Reset
  ResetAllCashOut() {
    this.PartialCachOutForm.reset();
    this.loadingMessagesForm = false;
  }

  createFromFormMobileMoneyDepot(): ITransaction{
    return {
      ...new Transaction(),
      serviceItemId: this.PartialCachOutForm.get(['serviceItemId']).value,
      numberAccount: this.accountNumber,
      receiverNumTel: this.code.receiverNumTel,
    //  receivedAmount:   this.PartialCachOutForm.get(['receivedAmount']).value,
      sentAmount:   this.PartialCachOutForm.get(['receivedAmount']).value,
      password:   this.PartialCachOutForm.get(['password']).value,
    };
  }

  DoneAllCachOutForm(forModal: any, forfooter?: any){
    const code = this.retraitCodeService.getCode();
    this.loadingMessagesForm = true;
    this.successTransfert = false;
    this.errorTransfert = false;
    const mobileMoney = this.createFromFormMobileMoneyDepot();

    mobileMoney.valueCode = code.valueCode;
    mobileMoney.receiverNumTel = code.receiverNumTel;
    mobileMoney.senderId = code.senderId;
    mobileMoney.receiverId = code.receiverId;

    console.log("###mobile money before sent api:", mobileMoney);
    this.loginService
      .loginProcess({
        username: this.userLogin,
        password: this.PartialCachOutForm.get('password').value
      })
      .subscribe(
        () => {
          this.mobileMoneyService
            .retraitCode(mobileMoney)
            .pipe(
              filter((res: HttpResponse<ITransaction>) => res.ok),
              map((res: HttpResponse<ITransaction>) => res.body)
            )
            .subscribe(
              res => {
                this.successTransfert = true;
                this.isSaving = false;
                this.manageMessageWhenSucceed(res);                
                 this.showDetailsDepotOM(forModal,
                  'Details Transaction', null,
                  {
                    closeButton: true,
                    overlayClose: false
                  }); 

                console.log('succefullll all cash out', res);
                
              },
              error => {
                this.manageMessageWhenError();
                this.responseFromServer();
                console.log('failedddd all cash out');
                console.error(error);
                
              }
            );
        },
        error => {
          this.responseFromServer()
          console.log('passworddd incorrecttttttttttttt');
          console.error(error);
          
        }
      );
  }
  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300)
  }
  showDetailsDepotOM(modal, title: string, footer, options){
    console.log('## showDetailsEnvoieCode');
    console.log('## modal: ', modal);
    console.log('## title: ', title);
    setTimeout(() => {
      this.openModal(modal, title, footer, options, 'zindex-result');
    }, TIME_MEDIUM_REQUEST);
  }

  manageMessageWhenError() {
    this.successTransfert = false;
    this.loadingMessagesForm = false;
    this.errorTransfert = true;
  }
  /**
 * show or delete message infos.
 * @param response the response of the transaction.
 * @private
 */
 private manageMessageWhenSucceed(response) {
  this.errorTransfert = false;
  this.successTransfert = true;
  this.loadingMessagesForm = true;
  this.ReponseTransfert = response;   
  this.PartialCachOutForm.reset();
}  

openModal<T>(body: Content<T>, header: Content<T> = null,
  footer: Content<T> = null, options: any = null,
  theClass?: string) {
  this.modal.open({
  body: body,
  header: header,
  footer: footer,
  options: options,
  theClass: theClass
  });
}

}
