import { Injectable } from '@angular/core';

export interface IPasswordColumnName {
  password: {
    current: string,
    new: string,
    confirm: string
  }
}

@Injectable({ providedIn: 'root' })
export class PasswordColumnName {
  getColumnName(): IPasswordColumnName{
    return {
      password: {
        current: 'currentPassword',
        new: 'newPassword',
        confirm: 'confirmPassword'
      }
    }
  }
}
