import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { IServiceItem, ServiceItemStatus } from '../../../model/service-item.model';
import { Subject } from 'rxjs';
import { BaseService } from '../base/base.service';
import { ColorService } from '../../../shared/services/color.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { ServiceItemService } from '../service-items/service-item.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantServiceItems } from '../service-items/service-items.url';
import { Url } from '../../../shared/constant/url.constant';
import { ConstantHome } from '../home/home.url';
import { takeUntil } from 'rxjs/operators';
import { BTN_CANCEL_CLASS, CANCEL_BG_COLOR, WIDTH_MEDIUM_POPUP_CLASS } from '../../../shared/constant/css-style/css-class.constant';
import { CONSTANT_ICON } from '../../../shared/constant/icon.constant';
import { ServiceItemAddComponent } from '../service-items/add/service-item-add.component';
import { ServiceItemUpdateComponent } from '../service-items/update/service-item-update.component';
import { ServiceItemDeleteComponent } from '../service-items/delete/service-item-delete.component';
import { IServiceItemCompose } from '../../../model/service-item-compose.model';
import { ServiceItemComposeService } from './service-item-compose.service';
import { ServiceItemComposeAddComponent } from './add/service-item-compose-add.component';
import { ServiceItemFilsListeComponent } from './liste/service-item-fils-liste.component';
import { ServiceItemComposeDeleteParentComponent } from './delete-parent/service-item-compose-delete-parent.component';

@Component({
  selector: 'app-service-items-compose',
  templateUrl: './service-items-compose.component.html'
})
export class ServiceItemsComposeComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  serviceItemList: IServiceItemCompose[];
  serviceItemSearch: IServiceItemCompose[];
  searchValue: string;
  subs$ = new Subject<void>();

  constructor(
    public baseService: BaseService,
    private colorService: ColorService,
    private accountService: AccountService,
    private formService: FormService,
    private dialog: MatDialog,
    private serviceItemService: ServiceItemComposeService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct service item compose list');
    this.pageData = {
      title: ConstantServiceItems.data.sub.list.title,
      key: 'serviceItemComposeList',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title:'',
          key: 'service.compose',
          route: 'service_items_compose/liste'
        },
        {
          title: '',
          key: 'lister',
        }
      ]
    };

    if(this.pageData.key==="serviceItemComposeList"){
      translate.get('serviceItemComposeList').subscribe(res => this.pageData.title=res);
    }
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="service.compose" ){
        translate.get('service.compose').subscribe(res => value.title=res);
      }
      if( value.key==="lister" ){
        translate.get('lister').subscribe(res => value.title=res);
      }


    });
  }

  ngOnInit(): void {
    console.log('## init service items compose liste');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.serviceItemService.getServiceCompose().subscribe(res => {
          console.log('## Success get service item composes list');
          this.serviceItemList = (res.body) ? res.body : [];
          console.log('## size: ' + this.serviceItemList.length);
          this.serviceItemSearch = this.serviceItemList;
          super.setLoaded();
          console.log('## BEFORE get list service in list by subject', this.serviceItemList);

          this.serviceItemService.getServiceItemObs().pipe(takeUntil(this.subs$))
            .subscribe((res: ServiceItemStatus[]) => {
              console.log('## get list service compose in list by subject', res);
              this.serviceItemList = (res) ? res : [];
              this.serviceItemSearch = this.serviceItemList;
            });

        });
      });
  }

  ngOnDestroy() {
    console.log('## on destroy service item compose list');
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  getStatusBg(id: number): string {
    return this.colorService.getStatusBg(id);
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.serviceItemSearch].map(mapFn);
    const result = this.serviceItemSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.serviceItemList = [];
    result.forEach((c) => {
      this.serviceItemList.push(c);
    });
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    console.log('## open service item compose add');
    this.dialog.open(ServiceItemComposeAddComponent, {
      height: '700px',
      width: '800px'
    });
  }

  show(c: IServiceItem) {
    /*console.log('## open service show');
    this.serviceItemService.setServiceItemSelectObs(c);
    this.dialog.open(MoniesShowComponent, {
      height: '700px',
      width: '800px',
    });*/
  }

  manage(c: IServiceItem) {
    this.serviceItemService.setServiceItemSelectObs(c);
    this.dialog.open(ServiceItemFilsListeComponent, {
      height: '700px',
      width: '800px',
    });
  }

  update(c: IServiceItem) {
    this.serviceItemService.setServiceItemSelectObs(c);
    this.dialog.open(ServiceItemUpdateComponent, {
      height: '700px',
      width: '800px',
    });
  }

  delete(c: IServiceItem) {
    console.log('## service selected: ', c);
    this.serviceItemService.setServiceItemSelectObs(c);
    this.dialog.open(ServiceItemComposeDeleteParentComponent, {
      height: '700px',
      width: '800px',
    });
  }

  /**
   * Retourne la liste des services items
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IServiceItemCompose[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

}
