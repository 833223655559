import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IOption } from '../../../../ui/interfaces/option';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CountryColumnName, ICountryColumnName } from '../../countries/country-column-name';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { MoniesService } from '../../monies/monies.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { CONTENT_BTN_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { BaseValidationService } from '../services/base-validation.service';
import { TransactionInfos } from '../../../../model/transaction/transaction-infos.model';
import { HttpParams } from '@angular/common/http';
import {
  SERVICE_ITEM_ID_INFOS_TRANSACTION_TR_CODE,
  SERVICE_ITEM_ID_TRANSFER_CODE, SERVICE_ITEM_ID_TRANSFER_CODE_INTER
} from '../../../../shared/constant/service-item.constant';
import { EnvoiCodeValidationService } from '../services/envoi-code-validation.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { takeUntil } from 'rxjs/operators';
import { ICashModel } from '../cash-data-model';
import { FEES_AMOUNT, INSUFFICIENT_AMOUNT_ERROR_CODE } from '../../../../shared/constant/error-code.constant';

/**
 * Permet de gérer l'ajout des countries
 */
@Component({
  selector: 'app-envoi-code-infos-transac',
  templateUrl: './envoi-code-infos-transac.component.html',
  animations: [fadeIn]
})
export class EnvoiCodeInfosTransacComponent extends BaseValidationService
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  loadingMessagesForm = false;
  theDialogRef: MatDialogRef<EnvoiCodeInfosTransacComponent>;
  transactionInfos: TransactionInfos;

  btnConfirmSuccess = -1;
  errorMessage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICashModel,
    private transactionsService: TransactionsService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<EnvoiCodeInfosTransacComponent>,
    protected modal: TCModalService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(modal);
    console.log('## construct country add');
    console.log('## cash j model: ', data);
    this.theDialogRef = this.dialogRef;
  }

  ngOnDestroy(): void {
    console.log('## on destroy');
  }

  ngOnInit(): void {
    console.log('## serviceName: ', this.data.cashDataModel.dataCode.serviceName);
    let p = new FormData();
    // p.set('serviceItemId', this.tostring(SERVICE_ITEM_ID_INFOS_TRANSACTION_TR_CODE));

    this.data.cashDataModel.getDataForm(p, 'valueCode', 'valueCode');
    this.data.cashDataModel.getDataForm(p, 'countryId', 'labelCountry');
    this.data.cashDataModel.getDataForm(p, 'sentAmount', 'sentAmount');
    this.data.cashDataModel.getDataCode(p, 'serviceId', 'serviceItemId');
    this.data.cashDataModel.getDataCode(p, 'valueApiKey', 'apiKey');

    this.data.cashDataModel.getDataForm(p, 'numberAccountTo', 'numberAccountTo');
    this.data.cashDataModel.getDataForm(p, 'numberAccountFrom', 'numberAccountFrom');

    this.data.cashDataModel.getDataForm(p, 'numberAccount', 'numberAccount');

    // p.set('serviceId', this.tostring(this.getServiceId()));
    // on ferme après avoir ouvert le popup du résultat de la transaction.
    this.dialogRef.afterClosed().subscribe(res => {
      console.log('## popup closed');
      if(this.btnConfirmSuccess === 1) {
        // on ferme le popup précédent
        this.data.cashDataModel.dialogRef.close();
      }
    });
    this.transactionsService.getTransactionInfosConfirm(p).subscribe(res => {
      console.log('## result: ', res);
      let data = res.body;
      console.log('## success récupération infos transaction: ', data);
      this.transactionsService.affectDefaultValue(data);
      this.transactionInfos = data;
      const a = this.transactionInfos.sentAmount;
      const i = this.data.cashDataModel.dataCode.dataServ['amountInfos'];
      console.log('## amount: ', a);
      console.log('## amountInfos: ', i);
      this.data.cashDataModel.transactionInfos = this.transactionInfos;
    }, err => {
      console.log('## Erreur récupération infos transaction: ', err);
    });
  }

  getServiceId() {
    // vérifie si c'est un transfert national.
    if (this.data.cashDataModel.dataForm.curentUserCuser.countryId ===
      this.data.cashDataModel.dataForm.countryDestID) {
      return SERVICE_ITEM_ID_TRANSFER_CODE;
    }
    // c'est un transfert international.
    return SERVICE_ITEM_ID_TRANSFER_CODE_INTER;
  }

  getInit() {
    if (!this.transactionInfos) {
      return false;
    }
    return true;
  }

  tostring(s: any) {
    return s + '';
  }

  format(n: any) {
    console.log('## type amount: ', typeof (+n));
    return this.formService.format(+n);
  }

  confirm() {
    console.log('## confirm envoi code');
    this.data.cashDataModel.dataForm.loadingMessagesForm = true;
    this.loadingMessagesForm = true;
    const d = this.data.cashDataModel.dataForm;
    d.data.set('serviceItemId', '' + this.data.cashDataModel.dataCode.serviceItemId);
    this.transactionsService.transferCode(d.data)
      .subscribe((rep) => {
        console.log('### SUCCESS', rep);
        this.loadingMessagesForm = false;
        this.manageMessageWhenSucceed(rep, d);
        const body = rep.body;
        if (body && body.valueCode) {
          d.reponseTransfert = body;
          this.showDetailsEnvoieCode(d.forModal,
            'Transaction details', null,
            {
              closeButton: true,
              overlayClose: false
            });
        }
        this.btnConfirmSuccess = 1;
        this.dialogRef.close();
      }, err => {
        console.log('ERROR While TRANS', err);
        let errorCode = err?.error?.errorCode;
        console.log('Error code', errorCode);
        this.errorMessage = (errorCode === INSUFFICIENT_AMOUNT_ERROR_CODE) ?
          'Montant account ressource insuffisant' : this.errorMessage;

        this.errorMessage = (errorCode === FEES_AMOUNT) ?
          'Frais supérieurs au montant à envoyer' : this.errorMessage;
        this.loadingMessagesForm = false;
        this.manageMessageWhenError(d);
      });
  }

  getAmountReceive() {
    const amount: number = +this.data.cashDataModel.dataForm.data.get('sentAmount');
    return this.format((amount - this.transactionInfos?.fees));
  }

  getAmountReceiveExchangeRate() {
    const amount: number = +this.data.cashDataModel.dataForm.data.get('sentAmount');
    const result = (amount - this.transactionInfos?.fees)
      * this.transactionInfos?.exchangeRate;
    if(result > 0) {
      return this.format(result);
    }
    return null;
  }

  getContentClass() {
    return CONTENT_BTN_CLASS;
  }

}