import { Injectable } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { BASE_URL, BASE_URL_MANAGEMENT } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ChampsFormulaireService extends ApiService {
  public resourceUrl = BASE_URL_MANAGEMENT + '/champs-formulaires';
  public resourceLocationUrl = BASE_URL_MANAGEMENT + '/location-type';
  public dynamicFormApi = BASE_URL + 'dynamic-form';
  public list_url = BASE_URL_MANAGEMENT + '/champs-formulaires/sort_by_date';

  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * {@link ApiService#getAllObjet}
   */
  getAllObjet(req?: any) {
    super.getAllObjet(this.list_url, req);
  }
}
