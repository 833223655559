import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IpAdressService } from '../../api-key/ip-adress.service';
import { ApiKeyService } from '../../api-key/api-key.service';
import { CUserApiKeyService } from '../../../../services/ServiceEntity/c-user-api-key.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { IIpAdressDTO, IpAdressDTO, IpAdressDTOS } from '../../../../model/ipAdress.model';
import { Apikey, ApiKeyCDTO, IApiKeyCDTO } from '../../../../model/api-key.model';
import { TIME_MIN_X1_REQUEST, TIME_MIN_X2_REQUEST } from '../../../../shared/constant/request.contant';

/**
 * Permet de gérer l'ajout des api key.
 */
@Component({
  selector: 'app-ip-adress-add',
  templateUrl: './ip-adress-add.component.html',
  animations: [fadeIn]
})
export class IpAdressAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<IpAdressAddComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;
  idAccount: number;

  apiKey: Apikey;

  constructor(
    private ipAdressService: IpAdressService,
    private apiKeyService: ApiKeyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domainService: CUserApiKeyService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<IpAdressAddComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct ip adress add');
    this.theDialogRef = this.dialogRef;
    console.log('## idAccount: ', this.data.idAccount);
    this.idAccount = this.data.idAccount;
    this.apiKey = this.data.apiKey;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init ip adress add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy ip adress add');
    this.formService.free(this.subs$);
  }

  initForm(fb: UntypedFormBuilder) {
    if (fb) {
      this.editForm = fb.group({
        ['label']: [null, [Validators.required]],
        ['description']: []
      })
    }
  }

  private createFromForm(): IIpAdressDTO {
    return {
      ...new IpAdressDTO(),
      label: this.editForm.get('label').value,
      description: this.editForm.get('description').value,
    };
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    console.log('## saving ip adress');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    c.apiKeyId = this.apiKey.id;
    this.ipAdressService.createOne(c)
      .subscribe(res => {
        console.log('## success create one ip adress');
        this.successSave = true;
        setTimeout(() => {
          this.ipAdressService.eventEmitter.emit('list');
        }, TIME_MIN_X1_REQUEST);
        setTimeout(() => {
          this.isSaving = false;
          this.close();
        }, TIME_MIN_X2_REQUEST);
      }, err => {
        console.log('## error create one ip adress: ', err);
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
        this.isSaving = false;
      });

  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

}