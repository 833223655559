
import { Injectable } from '@angular/core';
import { DepotOrangeMoneyInfosComponent } from '../depot-orange-money-infos/depot-orange-money-infos.component';
import { CONTAINER_DIALOG_POPUP } from '../../../../shared/constant/css-style/css-class.constant';
import { MatDialog } from '@angular/material/dialog';
import { ValidationFormService } from '../../cash/services/validation-form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { BaseValidationService, DataFormDynamic } from '../../cash/services/base-validation.service';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { ICashModel } from '../../cash/cash-data-model';

/**
 * Permet de valider le formulaire de dépôt d'orange money.
 */
@Injectable({ providedIn: 'root' })
export class DepotOrangeMoneyValidationService extends BaseValidationService
  implements ValidationFormService {


  constructor(
    private dialog: MatDialog,
    protected modal: TCModalService,
    private transactionsService: TransactionsService) {
    super(modal);
  }

  /**
   * @see ValidationFormService#valider
   */
  valider(d: DataFormDynamic, dm: ICashModel): any {
    console.log('## validation dépôt orange money');
    dm.cashDataModel.dataForm = d;
    this.dialog.open(DepotOrangeMoneyInfosComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: dm
    });
  }
}