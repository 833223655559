import { Moment } from 'moment';

export interface ITransaction {
  id?: number;
  numberTrx?: string;
  valueApiKey?: string;
  createdDate?: Moment;
  sentAmount?: number;
  amountTrx?: number;
  receivedAmount?: number;
  password?: string;
  fees?: number;
  benefit?: number;
  transactionTypeId?: number;
  transactionTypeName?: string;
  statusTrxId?: number;
  status?: string;
  mAccountId?: number;
  serviceItemId?: number;
  numberAccount?: string;
  phoneNumberDest?: string;
  others?: string;
  currency?: string;
  preferenceCurrency?: string;
  valueCode?: string;
  senderId?: number;
  senderlName?: string;
  validationTrx?: string;
  senderfName?: string;
  senderNumTel?: string;
  receiverId?: number;
  receiverlName?: string;
  receiverfName?: string;
  receiverNumTel?: string;
  dateTrx?: string;
  labelCountry?: string;
}

export class Transaction implements ITransaction {
  constructor(
    public id?: number,
    public numberTrx?: string,
    public valueApiKey?: string,
    public createdDate?: Moment,
    public sentAmount?: number,
    public amountTrx?: number,
    public receivedAmount?: number,
    public password?: string,
    public fees?: number,
    public senderId?: number,
    public benefit?: number,
    public transactionTypeId?: number,
    public transactionTypeName?: string,
    public validationTrx?: string,
    public statusTrxId?: number,
    public status?: string,
    public mAccountId?: number,
    public serviceItemId?: number,
    public numberAccount?: string,
    public phoneNumberDest?: string,
    public others?: string,
    public currency?: string,
    public preferenceCurrency?: string,
    public senderlName?: string,
    public senderfName?: string,
    public senderNumTel?: string,
    public receiverlName?: string,
    public receiverId?: number,
    public balanceBefor?: number,
    public balanceAfter?: number,
    public receiverfName?: string,
    public receiverNumTel?: string,
    public labelCountry?: string,
    public dateTrx?: string,
    public valueCode?: string
  ) {}
}
