import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apikey, IApikey, IApiKeyCDTO, IApiKeyDTO } from 'src/app/model/api-key.model';
import { IIpAdress, IpAdress } from 'src/app/model/ipAdress.model';
import { IMobileAccount } from 'src/app/model/mobile-account.model';
import { BASE_URL } from 'src/environments/environment';

type EntityResponseType = HttpResponse<IApikey>;
type EntityArrayResponseType = HttpResponse<IApikey[]>;

@Injectable({
  providedIn: 'root'
})
export class CUserApiKeyService {
  public resourceUrlApiKeysAccounts = BASE_URL + 'api-keys/account';
  public resourceUrl = BASE_URL + 'create-apiKey';
  public resourceUrlStatus = BASE_URL + 'changeStatus-Key';
  public resourceUrlAllIp = BASE_URL + 'addMultiIP';
  public resourceUrlOneIp = BASE_URL + 'addOneIP';
  public resourceUrlChangeIpStatus = BASE_URL + 'changeIpStatus';
  public resourceUrlFindAllIp = BASE_URL + 'findAllIpRestricion';
  public resourceUrlAllMaccounts = BASE_URL + 'mobileAccountByCUser';

  constructor(protected http: HttpClient) {
  }

  saveOneIpAdressForRestriction(ipAdress: IpAdress): Observable<HttpResponse<IIpAdress>> {
    return this.http.post<IpAdress>(this.resourceUrlOneIp, ipAdress, { observe: 'response' });
  }

  changeIpStatus(ipAdress: IpAdress): Observable<HttpResponse<IIpAdress>> {
    return this.http.post<IpAdress>(this.resourceUrlChangeIpStatus, ipAdress, { observe: 'response' });
  }

  findAllIpByApiKey(idApiKey: number): Observable<HttpResponse<IIpAdress[]>> {
    return this.http.get<IIpAdress[]>(`${this.resourceUrlFindAllIp}/${idApiKey}`, { observe: 'response' });
  }

  saveAllIpAdressForRestriction(ipAdress: IpAdress[]) {
    return this.http.post<IIpAdress[]>(this.resourceUrlAllIp, ipAdress, { observe: 'response' });
  }


  /**
   * Récupère les api keys par mobile account.
   * @param accountId
   */
  findAllByAccountId(accountId: number): Observable<HttpResponse<IApiKeyDTO[]>> {
    return this.http.get<IApiKeyDTO[]>(`${this.resourceUrlApiKeysAccounts}/${accountId}`,
      { observe: 'response' });
  }

  findAllByUserId(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IApikey[]>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  create(apikey: Apikey): Observable<EntityResponseType> {
    return this.http.post<Apikey>(this.resourceUrl, apikey, { observe: 'response' });
  }

  changeStatusKey(apikey: Apikey): Observable<EntityResponseType> {
    return this.http.post<Apikey>(this.resourceUrlStatus, apikey, { observe: 'response' });
  }

  getAll(): Observable<EntityArrayResponseType> {
    return this.http.get<IMobileAccount[]>(`${this.resourceUrlAllMaccounts}`, { observe: 'response' });
  }
}
