import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { fadeIn } from 'src/app/animations/form-error';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { ROUTE_HOME_FULL } from '../../../../layout/components/home.route';
import { FormService } from '../../../../shared/services/form.service';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';

@Component({
  selector: 'page-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [fadeIn]
})
export class PageResetPasswordComponent implements OnInit {
  email: UntypedFormControl;
  readonly home = ROUTE_HOME_FULL.signIn;

  isSaving = false;
  successSave = false;
  serverHasError = false;

  constructor(
    private formService: FormService,
    private cUserService: CUserService,
    private router: Router) {
    this.email = new UntypedFormControl('', [Validators.required, Validators.email]);
  }

  ngOnInit() {
    console.log('## oninit reset password');
  }

  reset() {
    console.log('## try to reset password:', this.email);
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    this.cUserService.resetPassword(this.email.value).subscribe(res => {
      console.log('## success reset password');
      this.serverHasError = false;
      this.responseFromServer();
      this.email.reset();
      this.successSave = true;
    }, err => {
      console.log('## error reset password:', err.message);
      this.responseFromServer();
      this.serverHasError = true;
      this.successSave = false;
    })
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, TIME_MIN_REQUEST);
  }

  isInvalid(): boolean {
    return this.formService.isInvalidControl(this.email);
  }

  isRequired(): boolean {
    return this.formService.isRequiredControl(this.email);
  }

  isInvalidDirtyOrtouched(): boolean {
    return this.formService.isInvalidDirtyOrTouchedControl(this.email);
  }

  hasErrorEmail(): boolean {
    return this.formService.hasErrorEmailControl(this.email);
  }
}
