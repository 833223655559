export class ServiceItemCompose {
  constructor(
    public parent?: ServiceItemDisplay,
    public image?: string,
    public filsList?: ServiceItemFils[]
  ) {}
}

export class ServiceItemFils {
  constructor(
    public serviceId?: number,
    public serviceItemId?: number,
    public name?: string,
  ) {}
}

export class ServiceItemDisplay {
  constructor(
    public serviceId?: number,
    public serviceItemId?: number,
    public name?: string,
    public accountRessourceStatusId?: number,
    public accountRessourceStatusLabel?: string,
    public accountRessTypeId?: number,
    public type?: string,
    public display?: number,
    public numberAccount?: string,
  ) {}
}

export const enum BodyFormat {
  JSON = 'JSON',
  TEXT = 'TEXT',
  HTML = 'HTML',
  XML = 'XML',
  JAVASCRIPT = 'JAVASCRIPT'
}

export const enum RequestType {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export class SItemAccountRessTypeDTO {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public type?: string,
    public serviceItemId?: number,
    public statusId?: number,
    public statusLabel?: string,
    public accountRessTypeId?: number,
    public accountUserId?: number,
    public accountRessTypeLabel?: string,
    public accountRessTypeDisplay?: number,
  ) {}
}

export interface IServiceItem {
  id?: number;
  name?: string;
  description?: string;
  serviceItemId?: number;
  bodyContent?: string;
  bodyType?: BodyType;
  bodyTypeField?: string;
  bodyFormat?: BodyFormat;
  requestType?: RequestType;
  url?: string;
  type?: string;
  image?: string;
  accountRessTypeId?: string;
  accountRessTypeName?: string;
  status?: string;
}

export class ServiceItem implements IServiceItem {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public serviceItemId?: number,
    public bodyContent?: string,
    public bodyType?: BodyType,
    public bodyFormat?: BodyFormat,
    public requestType?: RequestType,
    public url?: string,
    public image?: string,
    public accountRessTypeId?: string,
    public accountRessTypeName?: string
  ) {}
}

export class ServiceItemStatus implements IServiceItem {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public serviceItemId?: number,
    public bodyContent?: string,
    public image?: string,
    public bodyType?: BodyType,
    public bodyFormat?: BodyFormat,
    public requestType?: RequestType,
    public url?: string,
    public type?: string,
    public accountRessTypeId?: string,
    public accountRessTypeName?: string,
    public statusLabel?: string,
    public statusId?: number
  ) {}
}

export class BodyType {
  constructor(public field?: string) {}
}

export class BodyContentDTO {
  constructor(public key?: string, public description?: string) {}
}
