import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { CostsRule, ICostsRule } from '../../../../model/costs-rule.model';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CostsRuleColumnName, ICostsRuleColumnName } from '../../fees/fees-column-name';
import { MatDialogRef } from '@angular/material/dialog';
import { CostsRuleService } from '../../fees/costs-rule.service';
import { FormService } from '../../../../shared/services/form.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { TIME_MIN_REQUEST, TIME_MIN_X1_REQUEST } from '../../../../shared/constant/request.contant';
import { ExchangeRate, IExchangeRate } from '../../../../model/exchange-rate.model';
import { ExchangeRateService } from '../exchange-rate.service';
import { ExchangeColumnName, IExchangeColumnName } from '../exchange-rate-column-name';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { IVersionExchangeRate, VersionExchangeRate } from '../../../../model/version-exchange-rate.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { VersionExchangeRateService } from '../../version-exch-rate/version-exchange-rate.service';

/**
 * Permet de gérer la modification des Exchange Rate.
 */
@Component({
  selector: 'app-exchange-rate-liste',
  templateUrl: './exchange-rate-update.component.html',
  animations: [fadeIn]
})
export class ExchangeUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;
  private exchangeRateSelected: IVersionExchangeRate;
  private subs$ = new Subject<void>();
  editForm: UntypedFormGroup;
  column: IExchangeColumnName;

  versionExchangeRateList: IVersionExchangeRate[] = [];
  findVersionList = false;
  filteredOptions: Observable<IVersionExchangeRate[]>;
  theDialogRef: MatDialogRef<ExchangeUpdateComponent>;

  constructor(
    protected versionExchangeRateService: VersionExchangeRateService,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<ExchangeUpdateComponent>,
    private exchangeRateService: ExchangeRateService,
    private formService: FormService,
    private columnName: ExchangeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct Exchange Rate liste');
    this.theDialogRef = this.dialogRef;
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init Exchange Rate liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.getVersionExchangeRateList();
        super.setLoaded();
      })

  }

  hasFound(): boolean {
    return this.findVersionList;
  }

  /**
   * Récupère la liste des versions d'exchange rate et
   * les initialise dans un auto complete
   */
  getVersionExchangeRateList() {
    this.findVersionList = false;
    setTimeout(() => {
      this.versionExchangeRateService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IVersionExchangeRate[]>) => mayBeOk.ok),
          map((response: HttpResponse<IVersionExchangeRate[]>) => response.body)
        )
        .subscribe((res: IVersionExchangeRate[]) => {
            console.log('## success get list version of exchange rate while adding: ', res);
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.versionExchangeRateList = rs;
            this.filteredOptions = this.editForm.get(this.column.versionExchangeRateId).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
            this.getSelectExchangeRate();
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list version of exchange rate while adding: ' + res.message);
          });
    }, TIME_MIN_X1_REQUEST);
  }

  private _filter(value: any): IVersionExchangeRate[] {
    let filteredList;
    try {
      console.log('## value version ', value);
      const filterValue = (typeof value === 'string') ? value.toLowerCase() : value.version.toLowerCase();
      filteredList = this.versionExchangeRateList.filter(option => option.version.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter version of exchange rate: ' + e.message);
    }
    return filteredList;
  }

  getSelectExchangeRate() {
    this.exchangeRateService.getExchangeRateSelectObs()
      .pipe(takeUntil(this.subs$)).subscribe((res: IExchangeRate) => {
      console.log('## get Exchange Rate selected to liste');
      try {
        console.log('## data exchange rate liste: ', res);
        const v = new VersionExchangeRate();
        v.id = res['versionExchangeRateId']
        v.version = res['versionExchangeRate']
        v.etat = res['etatExchangeRate']
        this.exchangeRateSelected = v;
        console.log('## exchangeRateSelected: ', this.exchangeRateSelected);
        this.editForm.patchValue({
          [this.column.id]: res.id,
          [this.column.sourceMoney]: res.sourceMoney,
          [this.column.destMoney]: res.destMoney,
          [this.column.resultExchange]: res.resultExchange,
          [this.column.valeurModifie]: res.valeurModifie,
          [this.column.versionExchangeRateId]: v
        })
        this.findVersionList = true;
      }catch (e) {
        console.log('## error get Exchange Rate selected: ' + e.message);
        this.findVersionList = true;
      }
    });
  }

  ngOnDestroy() {
    console.log('## destroy Exchange Rate liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  displayFnVersion(subject: IVersionExchangeRate) {
    return subject ? subject.version : '';
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  initForm(fb: UntypedFormBuilder) {
    try {
      this.editForm = this.fb.group({
        id: [],
        sourceMoney: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(5),
            Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        destMoney: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(5),
            Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        resultExchange: ['', [Validators.required, Validators.minLength(1),
          Validators.maxLength(50), Validators.pattern('^[0-9]+(\\.[0-9]*){0,1}$')]],
        valeurModifie: ['',
          [Validators.maxLength(50), Validators.pattern('^[0-9]+(\\.[0-9]*){0,1}$')]],
        versionExchangeRateId: ['', [Validators.required]]
      });

    } catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## liste Exchange Rate');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: IExchangeRate = this.createFromForm();
    console.log('## c: ', c);
    this.exchangeRateService.update(c).subscribe(res => {
        console.log('## success liste Exchange Rate');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.markAsPristine();
        this.successSave = true;
        this.exchangeRateService.getAllExchangeRateByVersion(this.exchangeRateSelected
          .id);
      },
      err => {
        console.log('## error liste Exchange Rate');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, TIME_MIN_REQUEST);
  }

  private createFromForm(): IExchangeRate {
    return {
      ...new ExchangeRate(),
      id: this.editForm.get([this.column.id]).value,
      destMoney: this.editForm.get([this.column.destMoney]).value,
      sourceMoney: this.editForm.get([this.column.sourceMoney]).value,
      resultExchange: this.editForm.get([this.column.resultExchange]).value,
      valeurModifie: this.editForm.get([this.column.valeurModifie]).value,
      versionExchangeRateId: this.editForm.get([this.column.versionExchangeRateId]).value.id,

    };
  }

  closeModal() {
    console.log('## close Exchange Rate add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidOrPristineForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
