import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { ICountry } from '../../../../model/country.model';
import { Subject } from 'rxjs';
import { CountryService } from '../countries.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../../acc-ress-type/acc-ress-type-column-name';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Permet de gérer la suppression d'un country
 */
@Component({
  selector: 'app-country-delete',
  templateUrl: './country-delete.component.html',
  animations: [fadeIn]
})
export class CountryDeleteComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  countrySelected: ICountry;
  private subs$ = new Subject<void>();
  isDeleting = false;
  successDelete = false;
  serverHasError = false;
  hasDelete = false;

  constructor(
    private countryService: CountryService,
    private dialogRef: MatDialogRef<CountryDeleteComponent>,
    private formService: FormService,
    private columnName: AccRessTypeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct country delete');
  }

  ngOnInit(): void {
    console.log('## init country delete.');
    this.countryService.getCountrySelectObs()
      .pipe(takeUntil(this.subs$)).subscribe((res: ICountry) => {
      console.log('## get country selected');
      try {
        console.log('## data: ' + JSON.stringify(res));
        this.countrySelected = res;
      } catch (e) {
        console.log('## error get country delete: ' + e.message);
      }
      super.setLoaded();
    });
  }

  ngOnDestroy() {
    console.log('## destroy country delete');
    this.formService.free(this.subs$);
    this.countryService.setCountrySelectObs(undefined);
  }

  closeModal() {
    console.log('## close delete country');
    this.dialogRef.close();
  }

  delete() {
    console.log('## deleting country');
    this.isDeleting = true;
    this.serverHasError = false;
    this.successDelete = false;
    this.countryService.delete(this.countrySelected.id).subscribe(
      response => {
        console.log('## success delete country');
        this.serverHasError = false;
        this.successDelete = true;
        this.isDeleting = false;
        this.hasDelete = true;
        this.responseFromServer();
        this.countryService.getAllCountry();
      }, error => {
        console.log('## error delete country');
        this.serverHasError = true;
        this.successDelete = false;
        this.isDeleting = false;
        this.responseFromServer();
      });
  }

  private responseFromServer() {
    setTimeout(() => {
      this.isDeleting = false;
    }, 300);
  }
}
