import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les types de account ressources.
 */
export namespace ConstantAccRessType {
  export const data = {
    title: 'Account ressource type',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un type de account ressource'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des types de account ressource'
      }
    }

  }
  export const url = {
    ACCOUNT_RESSOURCE_TYPE: {
      ACCOUNT_RESSOURCE_TYPE_: 'accounts-ressource-type',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    ACCOUNT_RESSOURCE_TYPE: {
      ADD: ConstantAccRessType.url.ACCOUNT_RESSOURCE_TYPE.ACCOUNT_RESSOURCE_TYPE_
        .concat(Url.SLASH_ROUTE).concat(ConstantAccRessType.url.ACCOUNT_RESSOURCE_TYPE.ADD),
      LIST: ConstantAccRessType.url.ACCOUNT_RESSOURCE_TYPE.ACCOUNT_RESSOURCE_TYPE_
        .concat(Url.SLASH_ROUTE).concat(ConstantAccRessType.url.ACCOUNT_RESSOURCE_TYPE.LIST),
    }
  };
}

