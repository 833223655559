import { HttpResponse } from '@angular/common/http';

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { Account } from 'src/app/model/account.model';
import { CUser, ICUser } from 'src/app/model/c-user.model';
import { IMobileAccount } from 'src/app/model/mobile-account.model';
import { ITransaction, Transaction } from 'src/app/model/transaction.model';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { TIME_MEDIUM_REQUEST } from 'src/app/shared/constant/request.contant';
import { Content } from 'src/app/ui/interfaces/modal';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { MobileMoneyKey } from '../mobile-mobey-key';
import { MobileMoneyService } from '../mobile-money.service';
import { RetraitCodeService } from '../retrait-code.service';

@Component({
  selector: 'app-all-cash-out',
  templateUrl: './all-cash-out.component.html',
  styleUrls: ['./all-cash-out.component.css']
})
export class AllCashOutComponent implements OnInit {
  allCachOutForm: UntypedFormGroup;
  account: Account | null = null;
  mAccounts: IMobileAccount[];
  currentCUser: CUser;

  loadingAllCashOut: boolean;
  successTransfert = false;
  errorTransfert = false;
  loadingMessagesForm: boolean;
  isSaving: boolean;


  code: any;
  ReponseTransfert: any;

  userLogin: string;
  accountNumber: string;

  itemsRetraitMobileMoney = [];


  constructor
  (
    private fb: UntypedFormBuilder,
    private retraitCodeService: RetraitCodeService,
    private mobileMoneyKey: MobileMoneyKey,
    private loginService: LoginServiceService,
    private accountService: AccountService,
    private mAccountService: AccountUserService,
    private cUserService: CUserService,
    private mobileMoneyService: MobileMoneyService,
    private modal: TCModalService
  ) {
    console.log('Construct All cash out');
    this.initAllCashOutForm();
  }

  ngOnInit(): void {
    this.itemsRetraitMobileMoney = [
      {
        fs: 'Orange',
        itemName: 'ORANGE MONEY',
        serviceItemsId: 5
      },
      {
        fs: 'Free',
        itemName: 'FREE MONEY',
        serviceItemsId: 18
      },
      {
        fs: 'Expresso',
        itemName: 'E-MONEY',
        serviceItemsId: 19
      }
    ];
    this.code = this.retraitCodeService.getCode();
    console.log('ALL CASH OUT#######################', this.code);
    this.accountService.identity().subscribe(account => {
      this.account = account;
      this.userLogin = account.login;
      this.mAccountService
        .findAllAccountsByCUserLogin(account.login)
        .pipe(
          filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
          map((res: HttpResponse<IMobileAccount[]>) => res.body)
        )
        .subscribe((res: IMobileAccount[]) => {
          this.mAccounts = res.filter(value => value.label === 'Main Account');
          this.accountNumber = this.mAccounts[0].numberAccount;
        });
      this.cUserService
        .findOneByUserLogin(account.login)
        .pipe(
          filter((res: HttpResponse<ICUser>) => res.ok),
          map((res: HttpResponse<ICUser>) => res.body)
        )
        .subscribe((res: ICUser) => {
          this.currentCUser = res;
        });
    });

  }

  initAllCashOutForm() {
    this.allCachOutForm = this.fb.group({
      password: ['', [Validators.required]],
      serviceItemId: ['', [Validators.required]],
      numberAccount: [''],
      sentAmount: [''],
      receiverNumTel: ['']


    });
  }

  //For All cash out
  sendAllCashOut(valid: boolean) {
    if (valid) {
      this.loadingAllCashOut = true;
      setTimeout(() => {
        this.loadingAllCashOut = false;
        this.allCachOutForm.reset();
      }, 1000);
    }
  }

  DisableRetraitCodeSuccess() {
    this.retraitCodeService.setElement(this.mobileMoneyKey.appAllCashOut, false);
    this.retraitCodeService.setElement(this.mobileMoneyKey.appRetraitCodeValide, true);
  }

  //All Cash Out Reset
  ResetAllCashOut() {
    this.allCachOutForm.reset();
  }

  createFromFormMobileMoneyDepot(): ITransaction {
    return {
      ...new Transaction(),
      serviceItemId: this.allCachOutForm.get(['serviceItemId']).value,
      numberAccount: this.accountNumber,
      receiverNumTel: this.code.receiverNumTel,
      sentAmount: this.code.amount,
      receivedAmount: this.code.amount,
      password: this.allCachOutForm.get(['password']).value
    };

  }

  DoneAllCachOutForm(forModal: any, forfooter?: any) {
    const code = this.retraitCodeService.getCode();
    this.loadingAllCashOut = true;
    this.successTransfert = false;
    this.errorTransfert = false;
    const mobileMoney = this.createFromFormMobileMoneyDepot();

    mobileMoney.valueCode = code.valueCode;
    mobileMoney.receiverNumTel = code.receiverNumTel;
    mobileMoney.senderId = code.senderId;
    mobileMoney.receiverId = code.receiverId;

    console.log('###mobile money before sent api:', mobileMoney);
    this.loginService
      .loginProcess({
        username: this.userLogin,
        password: this.allCachOutForm.get('password').value
      })
      .subscribe(
        () => {
          this.mobileMoneyService
            .retraitCode(mobileMoney)
            .pipe(
              filter((res: HttpResponse<ITransaction>) => res.ok),
              map((res: HttpResponse<ITransaction>) => res.body)
            )
            .subscribe(
              res => {
                console.log('## success transfert mobile money');
                this.loadingAllCashOut = false;
                this.successTransfert = true;
                this.isSaving = false;
                this.manageMessageWhenSucceed(res);
                this.showDetailsDepotOM(forModal,
                  'Details Transaction', null,
                  {
                    closeButton: true,
                    overlayClose: false
                  });
                console.log('succefullll all cash out', res);
              },
              error => {
                console.log('## error transfert mobile money');
                this.manageMessageWhenError();
                this.responseFromServer();
                console.log('failedddd all cash out');
                console.error(error);
              }
            );
        },
        error => {
          this.responseFromServer();
          console.log('passworddd incorrecttttttttttttt');
          console.error(error);

        }
      );
  }

  responseFromServer() {
    this.loadingAllCashOut = false;
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  showDetailsDepotOM(modal, title: string, footer, options) {
    console.log('## showDetailsEnvoieCode');
    console.log('## modal: ', modal);
    console.log('## title: ', title);
    setTimeout(() => {
      this.openModal(modal, title, footer, options, 'zindex-result');
    }, TIME_MEDIUM_REQUEST);
  }

  manageMessageWhenError() {
    this.successTransfert = false;
    this.loadingMessagesForm = false;
    this.errorTransfert = true;
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  private manageMessageWhenSucceed(response) {
    this.errorTransfert = false;
    this.successTransfert = true;
    this.loadingMessagesForm = false;
    this.ReponseTransfert = response;
    this.allCachOutForm.reset();
  }

  openModal<T>(body: Content<T>, header: Content<T> = null,
               footer: Content<T> = null, options: any = null,
               theClass?: string) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options,
      theClass: theClass
    });
  }

}
