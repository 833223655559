import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { BasePageComponent } from '../../base-page';
import { LANGUAGES } from '../../../shared/constant/language.constant';
import { Observable, Subject } from 'rxjs';
import { Account } from '../../../model/account.model';
import { IOption } from '../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IUsersColumnName, UsersColumnName } from '../../entities/user-managment/users-column-name';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { FormService } from '../../../shared/services/form.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TIME_MIN_REQUEST } from '../../../shared/constant/request.contant';
import { IPasswordColumnName, PasswordColumnName } from './password-column-name';
import { CUserService } from '../../../services/ServiceEntity/c-user.service';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../shared/constant/css-style/css-class.constant';
import { PasswordMatchingService } from './password-matching.service';

/**
 * Permet de gérer la mise à jour du mot de passe d'un user.
 */
@Component({
  selector: 'app-password-liste',
  templateUrl: './password-update.component.html',
  animations: [fadeIn]
})
export class PasswordUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy, PasswordMatchingService {
  account: Account;
  private subs$ = new Subject<void>();

  isSaving = false;
  successSave = false;
  serverHasError = false;

  editForm: UntypedFormGroup;
  column: IPasswordColumnName;
  theDialogRef: MatDialogRef<PasswordUpdateComponent>;

  constructor(
    private dialogRef: MatDialogRef<PasswordUpdateComponent>,
    private accountService: AccountService,
    private cUserService: CUserService,
    private formService: FormService,
    private columnName: PasswordColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct password liste');
    this.theDialogRef = this.dialogRef;
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init password liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe((res: Account) => {
      console.log('## get account: ', res);
      console.log('## login: ', res?.login);
      this.account = res;
      super.setLoaded();
    });
  }

  ngOnDestroy() {
    console.log('## destroy password liste');
    this.successSave = false;
    this.formService.free(this.subs$);
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  initForm(fb: UntypedFormBuilder) {
    this.editForm = this.fb.group({
      [this.column.password.current]: ['', [Validators.required]],
      [this.column.password.new]: ['', [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50)]],
      [this.column.password.confirm]: ['', [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50)]]
    });
  }

  isMatching() {
    const newPass = this.editForm.get([this.column.password.new]).value;
    const confirmPass = this.editForm.get([this.column.password.confirm]).value;
   // console.log('## newpass: ', newPass);
   // console.log('## confirmPass: ', confirmPass);
    return ((newPass && confirmPass) && (newPass === confirmPass));
  }

  save() {
    console.log('## liste password');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const passCol = this.column.password;
    const newPass = this.editForm.get([passCol.new]).value;
    const currentPass = this.editForm.get([passCol.current]).value;
    this.cUserService.updatePassword(newPass, currentPass).subscribe(
      () => {
        console.log('## success liste password');
        this.serverHasError = false;
        this.responseFromServer();
        this.successSave = true;
        this.editForm.reset();
        /*this.accountService.identity(true).subscribe((ac: Account) => {
          console.log('## get account:', ac);
          this.account = ac;
        });*/
      },
      () => {
        console.log('## error liste password');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      }
    );
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, TIME_MIN_REQUEST);
  }

  closeModal() {
    console.log('## close password liste');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  thereIsNoChange(): boolean {
    return this.formService.isInvalidOrPristineForm(this.editForm);
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isDirty(column: string): boolean {
    return this.formService.isDirty(this.editForm,
      column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
