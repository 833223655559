import { Injectable } from '@angular/core';

export interface IStatusColumnName {
  id: string;
  label: string;
  description: string;
}

export interface ILinkDomainColumnName {
  labelStatus: string;
  descriptionStatus: string;
  domainList: string;
}

/**
 * Permet de renvoyer les champs du model Status {@link StatusModel}
 * sous forme de string.
 */
@Injectable({ providedIn: 'root' })
export class StatusColumnName {
  getColumnName(): IStatusColumnName {
    return {
      id: 'id',
      label: 'label',
      description: 'description'
    };
  }

  /**
   * Récupère le format json utilisé lors
   * de l'affectation d'un ou plusieurs status à un domain.
   */
  getColumnNameWhenLinkDomain(): ILinkDomainColumnName {
    return {
      labelStatus: 'labelStatus',
      descriptionStatus: 'descriptionStatus',
      domainList: 'domainList'
    }
  }

  /**
   * Récupère le format json utilisé lors
   * de l'affectation d'un ou plusieurs status à un domain.
   */
  getColumnNameWhenAffectStatus() {
    return {
      statusId: 'statusId',
      domainId: 'domainId',
      statusList: 'statusList'
    }
  }
}
