import { IMenuItem } from '../../interfaces/main-menu';
import { ConstantHome } from '../../pages/entities/home/home.url';
import { ConstantCash } from '../../pages/entities/cash/cash.url';
import { CONSTANT_KEY_TRANSLATE } from './key-translate.constant';
import { ConstantNotification } from '../../pages/entities/notification/notification.url';
import { ConstantAccountRessource } from '../../pages/entities/account-ressource/acc-ress.url';

/**
 * Décrit les éléments du menu pour un trader.
 */
export const CONSTANT_MENU_TRADER: IMenuItem[] = [
  {
    "title": ConstantHome.data.title,
    "key" :  CONSTANT_KEY_TRANSLATE.home,
    "icon": {
      "class": "icofont-home"
    },
    "routing": ConstantHome.data.routing,
    "image":""
  },
  {
    "title": 'account',
    "key" :  CONSTANT_KEY_TRANSLATE.mobileAccount,
    "icon": {
      "class": "icofont-home"
    },
    "routing": 'account',
    "image":""
  },
  {
    "title": ConstantNotification.data.title,
    "key" :  CONSTANT_KEY_TRANSLATE.notification,
    "icon": {
      "class": "icofont-home"
    },
    "routing": ConstantNotification.data.routing,
    "image":""
  }
]
