import { Injectable } from '@angular/core';
import { BASE_URL_MANAGEMENT } from '../../../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Validators } from '../../model/champs_formulaire/validators.model';

export class ApiService {

  constructor(protected http: HttpClient) {}

  private objet = new Subject<any[]>();
  private objetBehave = new BehaviorSubject<any[]>(undefined);

  // la sélection dans la liste.
  private select = new BehaviorSubject<any>(undefined);

  getObjetSelectObs() : Observable<any>{
    return this.select.asObservable();
  }

  setObjetSelectObs(c: any) {
    this.select.next(c);
  }

  getObjetObs() {
    return this.objet.asObservable();
  }

  setObjetObs(c: any[]) {
    this.objet.next(c);
  }

  getObjetBehaveObs() {
    return this.objetBehave.asObservable();
  }

  setObjetBehaveObs(c: any[]) {
    this.objetBehave.next(c);
  }

  /**
   * Permet de récupérer la liste des objet au niveau du serveur
   * pour être partagée. La liste est mise dans un objet de type
   * {@link Subject<any[]>}
   */
  getAllObjet(api: string, req?: any) {
    setTimeout(() => {
      this.query(api, req).subscribe(res => {
        console.log('## Success get objet list');
        this.setObjetObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  /**
   * Permet de récupérer la liste des objet au niveau du serveur
   * pour être partagée. La liste est mise dans un objet de type
   * {@link Subject<any[]>}
   */
  getAllObjetBehave(api: string, req?: any) {
    setTimeout(() => {
      this.query(api, req).subscribe(res => {
        console.log('## Success get objet behave list');
        this.setObjetBehaveObs((res.body) ? res.body : []);
      });
    }, 300)
  }


  create(validator: any, api: string): Observable<HttpResponse<any>> {
    return this.http.post<any>(api, validator,
      { observe: 'response' });
  }

  update(validator: any, api: string): Observable<HttpResponse<any>> {
    return this.http.put<any>(api, validator,
      { observe: 'response' });
  }

  query(api: string, req?: any): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(api,
      { params: req, observe: 'response' });
  }

  deleteByParam(api: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(api,
      { observe: 'response' });
  }

/*  delete(id: number, api: string): Observable<HttpResponse<any>> {

    return this.http.delete<any>(api,
      { observe: 'response' });
  }*/

  delete(id: number, api: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.set('id', '' +id);
    return this.http.delete<any>(api,
      { params: params, observe: 'response' });
  }
}
