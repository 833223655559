import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApiService } from '../../../shared/services/api.service';
import { BASE_URL, BASE_URL_MANAGEMENT } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FormulaireService extends ApiService {
  public resourceServiceUrl = BASE_URL_MANAGEMENT + '/formulaires/service';
  public resourceUrl = BASE_URL_MANAGEMENT + '/formulaires';
  public dynamicFormApi = BASE_URL + 'dynamic-form';
  public list_url = BASE_URL_MANAGEMENT + '/formulaires';
  public delete_url = BASE_URL_MANAGEMENT + '/formulaires';

  constructor(protected http: HttpClient) {
    super(http);
  }

  deleteByServiceFormulaireId(api: string): Observable<HttpResponse<any>> {
    return super.deleteByParam(api);
  }

  listByServiceId(api: string, id: number): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(api + `/${id}`,
      { observe: 'response' });
  }

  deleteByParamId(id: number): Observable<HttpResponse<any>> {
    return super.deleteByParam(`${this.delete_url}/${id}`);
  }

  /**
   * {@link ApiService#getAllObjet}
   */
  getAllObjet(req?: any) {
    super.getAllObjet(this.list_url, req);
  }
}
