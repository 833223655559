import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CONTENT_BTN_CLASS, POPUP_CONTAINER_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { TransactionInfos } from '../../../../model/transaction/transaction-infos.model';
import {
  SERVICE_ITEM_ID_INFOS_TRANSACTION_RETRAIT_CODE,
  SERVICE_ITEM_ID_INFOS_TRANSACTION_TR_CODE, SERVICE_ITEM_ID_RETRAIT_CODE_CASH, SERVICE_ITEM_ID_TRANSFER_CODE
} from '../../../../shared/constant/service-item.constant';
import { TransactionInfosRetrait } from '../../../../model/transaction/transaction-infos-retrait.model';
import { BaseValidationService } from '../../cash/services/base-validation.service';
import { ICashModel } from '../../cash/cash-data-model';

/**
 * Permet d'afficher les informations de la transaction
 * avant d'effectuer un retrait code partiel.
 */
@Component({
  selector: 'app-retrait-orange-money-infos',
  templateUrl: './retrait-orange-money-infos.component.html',
  animations: [fadeIn]
})
export class RetraitOrangeMoneyInfosComponent extends BaseValidationService
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  loadingMessagesForm = false;
  theDialogRef: MatDialogRef<RetraitOrangeMoneyInfosComponent>;
  private subs$ = new Subject<void>();

  transactionInfos: TransactionInfosRetrait;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICashModel,
    private transactionsService: TransactionsService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<RetraitOrangeMoneyInfosComponent>,
    protected modal: TCModalService) {
    super(modal);
    console.log('## construct retrait orange money infos');
    this.theDialogRef = this.dialogRef;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    let p = new FormData();
    p.set('serviceItemId', this.tostring(SERVICE_ITEM_ID_INFOS_TRANSACTION_RETRAIT_CODE));
    p.set('valueCode', this.tostring(this.data.cashDataModel.dataForm.data.get('valueCode')));
    p.set('sentAmount', this.tostring(this.data.cashDataModel.dataForm.data.get('sentAmount')));
    p.set('serviceId', this.tostring(SERVICE_ITEM_ID_RETRAIT_CODE_CASH));
    this.transactionsService.execute(p).subscribe(res => {
      let data = res.body;
      console.log('## success récupération infos transaction: ', data);
      this.transactionInfos = data;
      this.data.cashDataModel.transactionInfosRetrait = data;
      console.log('## title1: ', this.data.cashDataModel.dataCode.title);
    }, err => {
      console.log('## Erreur récupération infos transaction: ', err);
    })
  }

  getInit() {
    if(!this.transactionInfos) {
      return false;
    }
    return true;
  }

  tostring(s: any) {
    return s + '';
  }

  format(n: any) {
    console.log('## type amount: ', typeof (+n));
    return this.formService.format(+n);
  }

  getAmountReceive() {
    const amount: number = +this.data.cashDataModel.dataForm.data.get('sentAmount');
    return this.format((amount - this.transactionInfos?.fees));
  }

  confirm() {
    console.log('## confirm retrait orange money');
    this.data.cashDataModel.dataForm.loadingMessagesForm = true;
    this.loadingMessagesForm = true;
    const d = this.data.cashDataModel.dataForm;
    this.transactionsService.withdrawalv2(d.data).subscribe((rep) => {
      console.log('### SUCCESS Retrait PARTIAL CASH OUT', rep);
      this.dialogRef.close();
      this.data.cashDataModel.dialogRef.close();
      this.loadingMessagesForm = false;
      this.manageMessageWhenSucceed(rep, d);
      d.reponseTransfert = rep.body;
      console.log('## title: ', this.data.cashDataModel.dataCode.title);
      this.openModal(d.forModal, this.data.cashDataModel.dataCode.title,
        null, { closeButton: true, overlayClose: false }, POPUP_CONTAINER_CLASS);
      d.nextFormData.formulaire.reset();

    }, err => {
      console.log('### ERROR Retrait PARTIAL CASH OUT', err);
      this.loadingMessagesForm = false;
      this.manageMessageWhenError(d);
      // prendre la valeur dans 'myException'.
    });
  }

  getContentClass() {
    return CONTENT_BTN_CLASS;
  }

}