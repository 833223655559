import { NgModule } from '@angular/core';

import { FormulaireListComponent } from './formulaire-list.component';
import { SharedModule } from '../../../shared.module';
import { FormulaireAddComponent } from './add/formulaire-add.component';
import { FormulaireUpdateComponent } from './update/formulaire-update.component';
import { FormulaireDeleteComponent } from './delete/formulaire-delete.component';
import { FormulaireLinkComponent } from './formulaire-link/formulaire-link.component';

/**
 * Ce module permet de gérer les formulaires.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [FormulaireListComponent,
        FormulaireUpdateComponent,
        FormulaireLinkComponent,
        FormulaireDeleteComponent,
        FormulaireAddComponent]
})
export class FormulaireModule {}
