import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { ICountry } from '../../../model/country.model';
import {
  BTN_CANCEL_CLASS,
  CANCEL_BG_COLOR,
  SUFFIX_ICON_FORM,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../../shared/constant/css-style/css-class.constant';
import { ColorService } from '../../../shared/services/color.service';
import { CONSTANT_ICON } from '../../../shared/constant/icon.constant';
import { FormService } from '../../../shared/services/form.service';
import { UntypedFormGroup } from '@angular/forms';
import { ValidatorAddComponent } from '../champs_formulaires/validators/add/validator-add.component';
import {
  SIZE_H2_SM_POPUP,
  SIZE_H_MD_POPUP, SIZE_H_SM_POPUP,
  SIZE_MEDIUM_POPUP,
  SIZE_MIN_POPUP,
  SIZE_W_MD_POPUP,
  SIZE_W_SM_POPUP
} from '../../../shared/constant/css-style/css-size.constant';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class BaseService implements OnInit {


  constructor(
    private formService: FormService,
    private colorService: ColorService) {
  }

  ngOnInit(): void {
    console.log('## init base');
  }

  freeMemory(subs$: Subject<void>) {
    try {
      this.formService.free(subs$);
    } catch(e) {
      console.log('## e: ', e);
    }
  }

  closeModal(dialogRef: MatDialogRef<any>) {
    console.log('## close delete validator');
    try {
      dialogRef.close();
    }catch (e) {
      console.log('## e: ', e);
    }
  }

  open(dialog: MatDialog, component: any) {
    console.log('## open component');
    dialog.open(component, {
      height: SIZE_H_MD_POPUP,
      width: SIZE_W_MD_POPUP
    });
  }

  openSm(dialog: MatDialog, component: any) {
    console.log('## open component sm');
    dialog.open(component, {
      height: SIZE_H_SM_POPUP,
      width: SIZE_W_SM_POPUP
    });
  }

  openSmH2(dialog: MatDialog, component: any) {
    console.log('## open component sm');
    dialog.open(component, {
      height: SIZE_H2_SM_POPUP,
      width: SIZE_W_SM_POPUP
    });
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  isInvalid(f: UntypedFormGroup): boolean {
    return this.formService.isInvalidForm(f);
  }

  isRequired(column: string, f: UntypedFormGroup): boolean {
    return this.formService.isRequired(f, column);
  }

  isInvalidDirtyOrtouched(column: string, f: UntypedFormGroup): boolean {
    return this.formService.isInvalidDirtyOrTouched(f,
      column);
  }

  getMinLength(column: string, f: UntypedFormGroup): number {
    return this.formService.getMinLength(f, column);
  }

  getMaxLength(column: string, f: UntypedFormGroup): number {
    return this.formService.getMaxLength(f, column);
  }

  hasErrorEmail(column: string, f: UntypedFormGroup): boolean {
    return this.formService.hasErrorEmail(f, column);
  }

  hasErrorPattern(column: string, f: UntypedFormGroup): boolean {
    return this.formService.hasErrorPattern(f, column);
  }

  hasErrorMinLength(column: string, f: UntypedFormGroup): boolean {
    return this.formService.hasErrorMinLength(f, column);
  }

  hasErrorMaxLength(column: string, f: UntypedFormGroup): boolean {
    return this.formService.hasErrorMaxLength(f, column);
  }


  getIcon() {
    return CONSTANT_ICON;
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }
}