import { Routes } from '@angular/router';
import { ConstantChampsFormulaires } from '../champs_formulaires/champs_formulaires/champs-formulaire.url';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';
import { ChampsFormulaireListComponent } from '../champs_formulaires/champs_formulaires/champs-formulaire-list.component';
import { DomainLink } from './domain.url';
import { DomainListComponent } from './domain-list.component';

/**
 * Ensemble des routes pour les domains.
 */
export const DOMAIN_ROUTE: Routes = [
  {
    path: DomainLink.url.FEES.FEES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: DomainLink.url.FEES.LIST,
        pathMatch: 'full'
      },
      {
        path: DomainLink.url.FEES.LIST,
        component: DomainListComponent
      }
    ]
  }
];