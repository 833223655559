import { BASE_URL } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { IBenefitRule } from '../../../model/benefit-rule.model';
import { createRequestOption } from '../../../util/request-util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IServiceItem } from '../../../model/service-item.model';

@Injectable({ providedIn: 'root' })
export class BenefitRuleService {
  public resourceUrl = BASE_URL + 'benefit-rule';

  constructor(protected http: HttpClient) {}

  private benefitRule = new Subject<IBenefitRule[]>();

  // le benefitRule sélectionné dans la liste.
  private benefitRuleSelect = new BehaviorSubject<IBenefitRule>(undefined);

  getBenefitRuleSelectObs() : Observable<IBenefitRule>{
    return this.benefitRuleSelect.asObservable();
  }

  setBenefitRuleSelectObs(c: IBenefitRule) {
    this.benefitRuleSelect.next(c);
  }

  getBenefitRuleObs() {
    return this.benefitRule.asObservable();
  }

  setBenefitRuleObs(c: IBenefitRule[]) {
    this.benefitRule.next(c);
  }

  /**
   * Permet de récupérer la liste des country
   * pour être partagée.
   */
  getAllBenefitRule() {
    setTimeout(() => {
      this.query().subscribe(res => {
        console.log('## Success get benefitRule list');
        this.setBenefitRuleObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  create(benefitRule: IBenefitRule): Observable<HttpResponse<IBenefitRule>> {
    return this.http.post<IBenefitRule>(this.resourceUrl, benefitRule,
      { observe: 'response' });
  }

  update(benefitRule: IBenefitRule): Observable<HttpResponse<IBenefitRule>> {
    return this.http.put<IBenefitRule>(this.resourceUrl, benefitRule,
      { observe: 'response' });
  }

  find(id: number): Observable<HttpResponse<IBenefitRule>> {
    return this.http.get<IBenefitRule>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }

  query(req?: any): Observable<HttpResponse<IBenefitRule[]>> {
    const options = createRequestOption(req);
    return this.http.get<IBenefitRule[]>(this.resourceUrl,
      { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }
}
