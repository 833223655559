import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IOption } from '../../../../ui/interfaces/option';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IServiceItemColumnName, ServiceItemColumnName } from '../service-item-column-name';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { AccountRessTypeService } from '../../acc-ress-type/account-ress-type.service';
import { ServiceItemService } from '../service-item.service';
import { FormService } from '../../../../shared/services/form.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { ImagesDTO } from '../../../../model/images.model';
import { BaseService } from '../../base/base.service';
import { BASE_URL_IMAGE } from '../../../../../environments/environment';
import { SUFFIX_ICON_FORM } from '../../../../shared/constant/css-style/css-class.constant';

/**
 * Permet de gérer l'ajout des service items.
 */
@Component({
  selector: 'app-service-item-image',
  templateUrl: './service-item-image.component.html',
  animations: [fadeIn]
})
export class ServiceItemImageComponent extends BasePageComponent
  implements OnInit, OnDestroy {

  image: ImagesDTO[];
  editForm: FormGroup;
  file: any;
  files: any = [];
  urlImage = BASE_URL_IMAGE;

  theDialogRef: MatDialogRef<ServiceItemImageComponent>;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLElement>;

  private subs$ = new Subject<void>();


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogReference: MatDialogRef<ServiceItemImageComponent>,
    public baseService: BaseService,
    private serviceItemService: ServiceItemService,
    private formService: FormService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct service item image');
    this.theDialogRef = this.dialogReference;
    this.editForm = this.data.editForm;
    console.log('## form: ', this.editForm);
  }

  ngOnInit(): void {
    console.log('## init service item image');
    this.getAllImages();
  }

  ngOnDestroy() {
    console.log('## destroy service item image');
    this.formService.free(this.subs$);
  }

  getAllImages() {
    this.serviceItemService.getImage().subscribe(res => {
      console.log('## res');
      this.image = res.body
      // this.image = 'data:image/jpeg;base64,' + res.body.image;
    });
  }

  triggerClickFileInput() {
    let el: HTMLElement = this.fileInput.nativeElement;
    el.click();
  }

  cancel() {
    this.theDialogRef.close();
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  onFileChange(event) {
    console.log('## onFileChange:', event);
    try {
      const taille = event.target.files.length;
      if (taille > 0) {
        console.log('## files length: ', taille);
        const f = event.target.files;
        console.log('## file: ', f);
        this.file = f;
        // on enregistre l'image sur le serveur.
        for(const theFile of f) {
          console.log('## append file: ', theFile);
          const image = new FormData();
          image.set('file', theFile);
          this.serviceItemService.saveImage(image).subscribe(res => {
            console.log('## success save image');
            setTimeout(() => {
              this.getAllImages();
            }, 100)
          }, error => {
            console.log('## error save image: ', error);
          })
        }
      }
    } catch (e) {
      console.log('## error load file:', e);
    }
  }

  selectFile(f) {
    const fullImageName = this.urlImage + f;
    console.log('## fileName: ', fullImageName);
    this.serviceItemService.imageNameSelected = fullImageName;
    this.editForm.get('image').setValue(fullImageName);
    this.cancel();
  }
}