//import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BASE_URL } from 'src/environments/environment';
import { BasePageComponent } from '../../base-page';
import * as PageActions from '../../../store/actions/page.actions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IOption } from 'src/app/ui/interfaces/option';
import { Router } from '@angular/router';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { Content } from '../../../ui/interfaces/modal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormService } from '../../../shared/services/form.service';
import { Account, ROLE, THE_ROLE_NAME } from '../../../model/account.model';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CUserAddKeyComponent } from './add/c-user-add-key/c-user-add-key.component';
import { CUser, ICUser } from 'src/app/model/c-user.model';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { STATUS_NAME } from '../../../model/user.model';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { TranslateService } from '@ngx-translate/core';
import { ColorService } from '../../../shared/services/color.service';
import { RoleService } from '../../../shared/services/role.service';
import {
  BTN_CANCEL_CLASS,
  CANCEL_BG_COLOR,
  CONTAINER_DIALOG_POPUP,
  SUFFIX_ICON_FORM,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../../shared/constant/css-style/css-class.constant';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { CuserAddComponent } from './add-user/cuser-add.component';
import { CuserUpdateComponent } from './update-user/cuser-update.component';
import { UserListFactoryService } from './services/user-list-factory.service';
import { ICuserListService } from './services/i-cuser-list.service';
import { STATUS_BD_CONSTANT } from '../../../model/status.model';
import { IMoney } from '../../../model/money.model';
import { MoneyDeleteComponent } from '../monies/delete/monies-delete.component';
import { UserDeleteComponent } from './delete/user-delete.component';
import { AccRessListByUserComponent } from '../account-ressource/list-by-user/acc-ress-list-by-user.component';
import { ActivateServiceComponent } from './activate-service/activate-service.component';

@Component({
  selector: 'app-c-user',
  templateUrl: './c-user.component.html',
  styleUrls: ['./c-user.component.css']
})
export class CUserComponent extends BasePageComponent implements OnInit, OnDestroy {
  private subs$ = new Subject<void>();
  @ViewChild('basicTable') tabRef: any;
  @ViewChild('basicTablePart') tabParRef: any;

  indicatif = '221';
  searchValue: string;
  opened = false;

  rolePartener = ROLE.partener;
  roleAdmin = ROLE.admin;
  roleTrader = ROLE.trader;

  //The Current User var
  CurentUser: any;
  CurrentUserAuth: any;
  thisUserId: number;
  thisUser: ICUser;
  iduser: string;
  //
  tableData: any[];
  searchData: any[];
  //
  messagesForm: UntypedFormGroup;
  loadingMessagesForm: boolean;
  //
  updateCuserForm: UntypedFormGroup;
  loadingUpdateCuserForm: boolean;
  //
  countrylist: IOption[];
  authority: IOption[];
  //
  authorities: any;
  countries: any;
  //
  AttributId = 1;
  CuserToUpdate: any;
  //
  alertAddCuser: any;
  alertCuserExit: any;

  private _init = true; // si false, alors le loading est affiché
  isSaving: boolean;
  act: string;
  title: string;
  selected = this.roleTrader;
  roles = [
    {
      id: 1,
      name: this.rolePartener,
      desc: THE_ROLE_NAME.partener
    },
    {
      id: 2,
      name: this.roleTrader,
      desc: THE_ROLE_NAME.trader
    }
  ];


  constructor(store: Store<IAppState>, httpSv: HttpService,
              private userListFactoryService: UserListFactoryService,
              private roleService: RoleService,
              private colorService: ColorService,
              private formService: FormService,
              private formBuilder: UntypedFormBuilder,
              private http: HttpClient,
              private router: Router,
              public dialog: MatDialog,
              protected accountService: AccountService,
              protected cUserService: CUserService,
              private accountUserService: AccountUserService,
              private modal: TCModalService,
              private translate: TranslateService) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      key: 'trader',
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: 'home'
        },
        {
          key: 'trader',
          title: ''
        }
      ]
    };
    if (this.pageData.key === 'trader') {
      translate.get('trade').subscribe(res => this.pageData.title = res);
    }
    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'trader') {
        translate.get('trade').subscribe(res => value.title = res);
      }

    });

    console.log('title###', this.title);
    this.tableData = [];
    this.loadingMessagesForm = false;
    this.loadingUpdateCuserForm = false;
    this.initCountryAuthority();
    this.initMessagesForm();
    this.initUpdateForm();
  }

  ngOnInit(): void {
    super.ngOnInit();
    console.log('## init cuser');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.getCurrentUser(account);
        console.log('accounttttt', this.CurentUser);
        console.log('thisUserId', this.CurentUser.id);
        this.getData(`${BASE_URL}Authority/getAuthority`, 'authorities', 'initRole');
        this.getData(`${BASE_URL}countries`, 'countries', 'initCountry');
        /*        this.initMessagesForm();
                this.initUpdateForm();*/
      });
    this.roles[0].desc = this.translate.instant('partner');
    this.roles[1].desc = this.translate.instant('pointCash');
  }

  getRoles() {

  }

  delete(c: ICUser) {
    this.opened = false;

    this.cUserService.setIcuserSelectObs(c);
    this.dialog.open(UserDeleteComponent, {
      height: '500px',
      width: '800px',
    });
  }

  getStatusActivated() {
    return STATUS_BD_CONSTANT.actived;
  }

  getStatusDeactivated() {
    return STATUS_BD_CONSTANT.deactived;
  }

  shouldIshowActivate(d: any) {
    const id = d.statusId;
    const idActivate = STATUS_BD_CONSTANT.actived;
    return id !== idActivate;
  }

  shouldIshowDeactivate(d: any) {
    const id = d.statusId;
    const idDeactivate = STATUS_BD_CONSTANT.deactived;
    return id !== idDeactivate;
  }

  /**
   * Change le status d'un utilisateur.
   * @param d données de l'utilisateur.
   * @param statusId le nouveau status de l'utilisateur.
   */
  changeStatus(d: any, statusId: number) {
    this.init = false;
    console.log('## data: ', d);
    let data = {
      cUserId: d.id,
      statusId: statusId
    };
    console.log('## data2: ', data);
    console.log('## data2 new status: ', statusId);
    this.cUserService.updateStatus(data)
      .subscribe((response) => {
        console.log('## status modifié avec succès', response);
        this.init = true;
        this.loadTable(this.CurrentUserAuth);
      }, (err) => {
        this.init = true;
        console.log('## error when change status: ', err);
      });
  }

  //status C-User
  StatusTrader(data: CUser) {
    let act = data.activated;

    /*  let status = data.status;
     status = status === 'ACTIVED' ? 'DEACTIVED' : 'ACTIVED'; */
    this.isSaving = true;
    const id = (data.id.toString());
    const option = { id, act };
    this.cUserService.updateStatusCUser(option).subscribe((response) => {
      console.log('## status modifié avec succès', response);
      const cuser = response.body;
      console.log('responseeeeee', cuser);
      data.activated = cuser.activated;
      //data.activated ===(cuser && cuser.activated) ? cuser.activated : data.activated;

    });
  }

  getStatusUserBg(status: boolean) {
    return this.colorService.getStatusUserBg(status);
  }

  getStatusBg(id: number): string {
    return this.colorService.getStatusBg(id);
  }

  getStatusBackground(status: string) {
    return this.colorService.getStatusBackground(status);
  }

  getStatus(status: boolean) {
    if (status) {
      return STATUS_NAME.actived;
    }
    return STATUS_NAME.blocked;
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  getRole(authority: string) {
    return this.roleService.getRole(authority);
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getAmountColBg() {
    return this.colorService.getAmountColBg();
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  //Pour avoir le User actuel
  getCurrentUser(a: Account) {
    this.CurentUser = a;
    if (a) {
      this.getThisUser(this.CurentUser);
    }
    if (this.CurentUser['authorities'][1]) {
      this.CurrentUserAuth = this.CurentUser['authorities'][1];
      this.loadTable(this.CurrentUserAuth);
    } else {
      this.CurrentUserAuth = this.CurentUser['authorities'][0];
      this.loadTable(this.CurrentUserAuth);
    }
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  // initCountryAuthority() {

  getThisUser(account: ICUser) {
    this.cUserService
      .query({})
      .subscribe((res: HttpResponse<ICUser[]>) => {
        this.thisUser = res.body.find(resul => {
          return resul.userId === account.id;
        });
      });
  }

  initCountryAuthority() {
    this.initCountry();
    this.initRole();
  }

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): any {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  getPartList(): any {
    return (this.tabParRef) ? this.tabParRef.data : [];
  }

  /* loadAllList() {
     this.cUserService
       .queryList()
       .subscribe((res: HttpResponse<ICUser[]>) =>
         this.paginateCUsers(res.body.filter(resul => resul.authority === this.roleTrader || resul.authority === this.rolePartener), res.headers)
       );
   }*/

  private getDataServ(res: HttpResponse<ICUser[]>) {
    try {
      const data = res.body;
      console.log('## data without filter: ', data);
      console.log('## size: ', data.length);
      data.forEach(d => {
        d.phoneNumberTab = '+' + d.codePhone + d.phoneNumber;
      });
      const dataFilter = data.filter(resul => resul.authority === this.roleTrader
        || resul.authority === this.rolePartener);
      console.log('## data filter: ', dataFilter);
      console.log('## size: ', dataFilter.length);
      this.tableData = dataFilter ? dataFilter : [];
      this.searchData = this.tableData;
      this.cUserService.getIcuserObs().pipe(takeUntil(this.subs$))
        .subscribe((res: ICUser[]) => {
          console.log('## get list icuser in list by subject: ', res);
          const dataFilter = res.filter(resul => resul.authority === this.roleTrader
            || resul.authority === this.rolePartener);
          this.tableData = (dataFilter) ? dataFilter : [];
          dataFilter.forEach(d => {
            d.phoneNumberTab = '+' + d.codePhone + d.phoneNumber;
          });
          this.searchData = this.tableData;
        });
    } catch (e) {
      console.log('## error get data:', e.message);
    }
  }


  get init(): boolean {
    return this._init;
  }

  set init(value: boolean) {
    this._init = value;
  }

  /**
   * Récupére les utilisateurs en fonction
   * du role.
   * @param role
   */
  loadTable(role: any) {
    console.log('## ROLE: ', role);
    let service: ICuserListService =
      this.userListFactoryService.getService(role);
      console.log('## service: ', service);
    service.loadAll().subscribe((res: HttpResponse<ICUser[]>) => {
      console.log('## res: ', res);
        this.getDataServ(res);
      }, err => {
        console.log('## error get data:', err.message);
      }
    );
  }

  //initialiser le formulaire pour l'ajout d'un trader
  initMessagesForm() {
    console.log('## init message form');
    this.messagesForm = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      numCNI: ['', Validators.required],
      login: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      ConfirmPassword: ['', Validators.required],
      activated: [true],
      authority: ['', Validators.required],
      countryId: ['', Validators.required],
      attributsId: ['', Validators.required]
      //countryName: ['', Validators.required]
    });
  }

  //initialiser le formulaire de mis a jour
  initUpdateForm() {
    console.log('## init liste form');
    this.updateCuserForm = this.formBuilder.group({
      id: ['', Validators.required],
      userId: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      numCNI: ['', Validators.required],
      login: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      activated: [true],
      authority: ['', Validators.required],
      countryId: ['', Validators.required],
      attributsId: ['', Validators.required]
    });
  }

  sendMessagesForm(valid: boolean) {
    if (valid) {
      this.loadingMessagesForm = true;

      setTimeout(() => {
        this.loadingMessagesForm = false;
        //this.messagesForm.reset();
      }, 1000);
    }
  }

  sendUpdateCuserForm(valid: boolean) {
    if (valid) {
      this.loadingUpdateCuserForm = true;

      setTimeout(() => {
        this.loadingUpdateCuserForm = false;
        //this.messagesForm.reset();
      }, 1000);
    }
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  resetMessagesForm() {
    this.messagesForm.reset();
    this.loadingMessagesForm = false;
    this.alertCuserExit = false;
    this.alertAddCuser = true;
  }

  resetUpdateCuserForm() {
    this.updateCuserForm.reset();
    this.loadingUpdateCuserForm = false;
  }

  //Initialiser les Country pour le Select
  initCountry() {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );
    setTimeout(() => {
      //console.log("COUNTRIES",this.countries);
      this.countrylist = [];
      this.countries?.forEach(element => {
        this.countrylist.push({ 'label': element.label, 'value': element.id });
      });
    });
  }

  //Initialiser les Roles pour le Select
  initRole(): void {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );

    setTimeout(() => {
      this.authority = [];
      this.authorities?.forEach(element => {
        //for role trader
        if (this.CurrentUserAuth == this.rolePartener) {
          if (element.name === this.roleTrader) {
            this.authority.push({ 'label': 'POINT_CASH', 'value': element.name });
          }
        } else {
          if (element.name === this.roleTrader || element.name === this.rolePartener) {
            if (element.name === this.roleTrader) {
              this.authority.push({ 'label': 'POINT_CASH', 'value': element.name });
            } else {
              this.authority.push({ 'label': element.name, 'value': element.name });
            }
          }
        }

      });
    });
  }

  //Initialiser la table des Cusers
  initTable(): void {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );

    setTimeout(() => {
      this.searchData = this.tableData;
      // this.searchData = JSON.parse(JSON.stringify(this.tableData));
      //console.log("C-USER",this.tableData);
    });
  }

  //recherche sur les traders
  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.searchData].map(mapFn);
    const result = this.searchData.filter(
      (_, i) => stringArr[i]
        .indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.tableData = [];
    result.forEach((c) => {
      this.tableData.push(c);
    });
  }

  private createFromForm(): ICUser {
    const login = this.messagesForm.get(['phoneNumber'])?.value;
    const phoneNumber = this.indicatif.concat(login);
    return {
      ...new CUser(),
      login: login,
      lastName: this.messagesForm.get(['lastName'])?.value,
      firstName: this.messagesForm.get(['firstName'])?.value,
      email: this.messagesForm.get(['email'])?.value,
      phoneNumber: phoneNumber,
      activated: true,
      numCNI: this.messagesForm.get(['numCNI'])?.value,
      authority: this.messagesForm.get(['authority'])?.value,
      countryId: this.messagesForm.get(['countryId'])?.value,
      attributsId: this.AttributId,
      password: this.messagesForm.get(['password'])?.value
    };
  }

  // ADD E.

  //Ajouter un trader
  addTrader() {
    console.log('## adding trander');
    this.alertAddCuser = false;
    this.alertCuserExit = false;
    if (this.messagesForm.get(['password']).value === this.messagesForm.get(['ConfirmPassword']).value) {
      let user = this.createFromForm();
      console.log('### try to create:', user);
      this.cUserService.create(user).subscribe((resp) => {
        console.log('success create user');
        const u: ICUser = resp.body;
        console.log('val:', u);
        this.addMobileAccount(u);
        this.initCountryAuthority();
        this.resetMessagesForm();
        this.loadTable(this.CurrentUserAuth);

        // this.initMessagesForm();
        //this.ngOnInit();
      }, err => {
        console.log(err);
        this.alertCuserExit = true;
        this.alertAddCuser = false;
      });
    } else {
      this.alertAddCuser = true;
    }
  }

  //Ajouter un Mobile Account
  addMobileAccount(data: any) {
    const mobileAccount = {
      'cUserId': Number(data.id),
      'label': 'Main Account',
      'preferenceCurrency': data.currency,
      'phoneNumber': data.phoneNumber,
      'countryLabel': data.countryName
    };
    //console.log("## DATA: ",JSON.stringify(mobileAccount));
    this.accountUserService.create(mobileAccount).subscribe((rep) => {
      //console.log("MobileAccount",rep);
      this.addAccountRessources(rep);
    }, err => {
      console.log(err);
    });
  }

  //Ajouter un apikey
  addApiKey(data) {
    console.log('dataaaaaaaa', data);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(CUserAddKeyComponent, {
      data: {
        trader: data, currentAccount: this.CurentUser,
        thisUserId: this.CurentUser.id
      }, height: '600px', width: '800px'
    });
  }

  //Ajouter un  Account Ressource
  addAccountRessources(dataMobileAccount: any) {
    //account-ressource
    const accountRessource = {
      'amount': 0,
      'accountRessTypeId': 1,
      'accountRessType': 'CASH',
      'currency': dataMobileAccount.preferenceCurrency,
      'mobileAccountId': dataMobileAccount.id,
      'labelAccountUser': dataMobileAccount.label
    };
    this.http.post(`${BASE_URL}account-ressource`, accountRessource).subscribe((rep) => {
      //console.log("### Account Ressource",rep);
    }, err => {
      console.log(err);
    });
  }

  //Ajouter un Cuser service Item
  addCuserItemSevice() {

  }

  //supprimer un trader
  removeTrader(id) {
    this.http.delete(`${BASE_URL}c-users/${id}`).subscribe(() => {
      console.log('SUCCESS');
      //this.loadTable();
    }, err => {
      console.log(err);
    });
  }

  UpdateTrader(id, modalUpdateBody) {
    //this.getData(`${baseUrl}c-users/${id}`, 'CuserToUpdate', 'ExecuteUpDateTrader');
    this.http.get(`${BASE_URL}c-users/${id}`).subscribe((dataCuser) => {
      this.CuserToUpdate = dataCuser;
      console.log('C-USER', this.CuserToUpdate);
      this.loadTable(this.CurrentUserAuth);
      this.openModal(modalUpdateBody);
    }, err => {
      console.log(err);
    });
  }

  ExecuteUpDateTrader() {
    //this.CuserToUpdate
    this.updateCuserForm.value.phoneNumber = '221' + this.updateCuserForm.get(['login']).value;
    //this.updateCuserForm.value.id = this.CuserToUpdate.id;
    this.cUserService.updateCUser(this.updateCuserForm.value).subscribe(() => {
      console.log('SUCCESS FOR UPDATING');
      this.initCountryAuthority();
      this.closeModal();
      this.loadTable(this.CurrentUserAuth);
    }, err => {
      console.log('Error When updating', err);
    });
  }

  ViewTrader(id, u: ICUser) {
    console.log('ID to View', id);
    this.cUserService.setIcuserSelectObs(u);
    this.router.navigate(['/c-user/cuserdetailmanage', id]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  /**
   * Ouvre le template html {@link CuserAddComponent}
   * dans un popup.
   */
  openAddUser() {
    console.log('## open add user');
    this.dialog.open(CuserAddComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP
    });
  }

  activateService(user) {
    console.log('## activate service for user: ', user);
    this.dialog.open(ActivateServiceComponent, {
      data: {'cUserId': user?.id},
      height: '700px',
      width: '800px'
    });
  }

  /**
   * Ouvre le template html {@link CuserUpdateComponent}
   * dans un popup.
   */
  openUpdateUser(data: ICUser) {
    console.log('## open liste user');
    this.cUserService.setIcuserSelectObs(data);
    this.cUserService.roleSelected = this.selected;
    this.dialog.open(CuserUpdateComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP
    });
  }

  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options
    });
  }

  closeModal() {
    this.resetMessagesForm();
    this.modal.close();
    //this.ngOnInit();
    this.loadTable(this.CurrentUserAuth);
  }
}
