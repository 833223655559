import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../notification.service';
import { AccountConfirmDTO, INotificationDTO, TransferCompteConfirmDTO } from '../../../../model/notification.model';
import { HttpParams } from '@angular/common/http';
import { MatRadioChange } from '@angular/material/radio';
import { TIME_MEDIUM_REQUEST } from '../../../../shared/constant/request.contant';
import { IExchangeRateDto } from '../../../../model/exchange-rate.model';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-notification-confirm',
  templateUrl: './notification-confirm.component.html',
  animations: [fadeIn]
})
export class NotificationConfirmComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<NotificationConfirmComponent>;
  accountConfirmDTOS: AccountConfirmDTO[];

  editForm: UntypedFormGroup;
  notificationDTO: INotificationDTO;
  accountChecked: AccountConfirmDTO;
  numberAccountChecked: string;

  receivedAmount: number;
  exchangeRateDto: IExchangeRateDto;

  private subs$ = new Subject<void>();


  constructor(
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NotificationConfirmComponent>,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct notification confirm');
    this.editForm = this.fb.group({});
    this.theDialogRef = this.dialogRef;
    this.notificationDTO = this.data.notification;
  }

  ngOnInit(): void {
    console.log('## init notification confirm');
    const transactionId = this.notificationDTO
      ? this.notificationDTO.transactionSenderId : null;
    let p = new HttpParams();
    if (transactionId) {
      p = p.set('transactionId', transactionId);
    }
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.notificationService.listAccounts(p)
          .subscribe(res => {
            console.log('## Success get list accounts: ', res);
            this.accountConfirmDTOS = (res.body) ? res.body : [];
            this.checkDefaultAccount();
          });
      });
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy notification confirm');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  close() {
    this.dialogRef.close();
  }

  getReceivedAmount() {
    let p = new HttpParams();
    p = p.set('startMoney', this.notificationDTO.currency.slice(0, 3));
    p = p.set('destMoney', this.accountChecked.codeMoney);
    p = p.set('amount', this.notificationDTO.receivedAmount.toString());
    this.notificationService.convertMoney(p)
      .subscribe(res => {
        console.log('## Success convert money: ', res);
        this.exchangeRateDto = (res && res.body) ? res.body : null;
        this.receivedAmount = (this.exchangeRateDto) ? this.exchangeRateDto.resultExchange : null;
      });
  }

  format(n: number) {
    return this.formService.format(n);
  }

  change(e: MatRadioChange) {
    console.log('## change: ', e);
    this.checkAccount(e.value);
  }

  /**
   * @param nAccount: le number account
   */
  checkAccount(nAccount: string) {
    this.accountChecked = null;
    const numberAccount = nAccount;
    console.log('## change: ', numberAccount);
    this.accountChecked = this.accountConfirmDTOS.find((current,
                                                        index) => {
      if (current.numberAccount === numberAccount) {
        return current;
      }
    });
    console.log('## account founded: ', this.accountChecked);
    this.getReceivedAmount();
  }

  checkDefaultAccount() {
    console.log('## check default');
    this.numberAccountChecked = null;
    this.accountChecked = null;
    this.accountChecked = this.accountConfirmDTOS.find((current,
                                                        index) => {
      if (current.sameMoney) {
        return current;
      }
    });
    if (this.accountChecked) {
      this.numberAccountChecked = this.accountChecked.numberAccount;
      this.getReceivedAmount();
    }
  }

  getCurrencyReceiver(): string {
    return this.accountChecked ? this.accountChecked.codeMoney :
      '';
  }

  /*verifyAccount(a: AccountConfirmDTO) {
    console.log('## verify account: ', a);
    //this.checkAccount(a.numberAccount);
    this.numberAccountChecked = a.numberAccount;
    return a.sameMoney;
  }*/

  save() {
    if (this.accountChecked || this.numberAccountChecked) {
      console.log('## saving ');
      // this.isSaving = true;
      this.successSave = false;
      const t = new TransferCompteConfirmDTO();
      t.correlationId = this.notificationDTO.correlationId;
      t.numberAccount = this.numberAccountChecked ? this.numberAccountChecked
        : this.accountChecked.numberAccount;
      t.response = true;
      console.log('## tr: ', t);
      this.notificationService.confirmTransaction(t)
        .subscribe(res => {
          console.log('## success confirm transaction ');
          this.successSave = true;
          setTimeout(res => {
            this.close();
            this.isSaving = false;
          }, TIME_MEDIUM_REQUEST);
          this.notificationService.getAllObjetBySubject();
      }, error => {
          console.log('## error confirm transaction ');
          this.isSaving = false;
          this.successSave = false;
        });
    }
  }
}