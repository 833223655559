import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../../base-page';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';

@Component({
  selector: 'page-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss']
})
export class PageInputsComponent extends BasePageComponent implements OnInit, OnDestroy {
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService
  ) {
    super(store, httpSv);

    this.demoCode =
`<tc-form-group>
   <tc-input [value]="'Value'"></tc-input>
</tc-form-group>`;

    this.pageData = {
      title: 'Inputs',
      key: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'UI Kit',
          key: '',
          route: 'dashboard'
        },
        {
          title: 'Components',
          key: '',
          route: 'dashboard'
        },
        {
          title: 'Inputs',
          key: ''
        }
      ]
    };
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
