import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../animations/form-error';
import { BasePageComponent } from '../../../../base-page';
import { ChampsFormulaire, SourceType } from '../../../../../model/champs_formulaire/champs-formulaire.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { IOption } from '../../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ISourceFormColumnName, SourceFormColumnName } from '../source-form-column-name';
import { Validators as ValidatorsModel } from '../../../../../model/champs_formulaire/validators.model';
import { Router } from '@angular/router';
import { ValidatorService } from '../../validators/validator.service';
import { ServiceItemService } from '../../../service-items/service-item.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs-formulaire.service';
import { BaseService } from '../../../base/base.service';
import { AccountService } from '../../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../../shared/services/form.service';
import { TCModalService } from '../../../../../ui/services/modal/modal.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { HttpService } from '../../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { NameSourceFType, SourceFormModel } from '../../../../../model/champs_formulaire/source-form.model';
import { ConstantSourceForm } from '../source-form.url';
import { ConstantChampsFormulaires } from '../../champs_formulaires/champs-formulaire.url';
import { SourceFormService } from '../source-form.service';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-source-form-update',
  templateUrl: './source-form-update.component.html',
  animations: [fadeIn]
})
export class SourceFormUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  formSelected: SourceFormModel;
  theDialogRef: MatDialogRef<SourceFormUpdateComponent>;
  private subs$ = new Subject<void>();
  typeForms: IOption[] = [];
  nameSourceFType: IOption[] = [];

  editForm: UntypedFormGroup;
  column: ISourceFormColumnName;

  validatorsList: ValidatorsModel[] = [];
  filteredOptions: Observable<ValidatorsModel[]>;

  constructor(
    private router: Router,
    private validatorService: ValidatorService,
    private serviceItemService: ServiceItemService,
    private sourceFormService: SourceFormService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<SourceFormUpdateComponent>,
    private columnName: SourceFormColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct source form add validator');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init source form update');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.sourceFormService.getObjetSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: SourceFormModel) => {
          console.log('## get formulaire selected to liste');
          for(let item in NameSourceFType) {
            if(isNaN(Number(item))) {
              this.nameSourceFType.push({
                ...new Option(),
                label: item?.toLocaleLowerCase(),
                value: item?.toString()
              })
            }
          }
/*          [this.column.nameSourceFType]: res.nameSourceFType ?
            res.nameSourceFType.toString().toLocaleLowerCase() : '',. */
          try {
            console.log('## data: ', res);
            this.formSelected = res;
            const f = this.formSelected;
            this.editForm.patchValue({
              [this.column.id]: res.id,
           //   [this.column.formulaire]: 'my data',
              [this.column.champsFormulaire]: f.champsFormulaire?.label,
              [this.column.nameSourceFType]: res.nameSourceFType,
              [this.column.valeur]: res.valeur,
              [this.column.comment]: res.comment,
              [this.column.champsFormulaireId]: res.champsFormulaire.id
            });
          } catch (e) {
            console.log('## error get country selected: ' + e.message);
          }
        });
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }


  displayFnF(subject: ChampsFormulaire) {
    return subject ? subject.label : '';
  }

  initForm(fb: UntypedFormBuilder, column: ISourceFormColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.id]: ['', [Validators.required]],
        [column.nameSourceFType]: ['', [Validators.required]],
        [column.valeur]: ['', [Validators.required]],
        [column.comment]: [''],
        [column.champsFormulaire]: [''],
        [column.champsFormulaireId]: ['', [Validators.required]],
      });
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save validator');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.sourceFormService.update(c,
      this.sourceFormService.resourceUrl)
      .subscribe(res => {
          console.log('## success create validator');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.sourceFormService.getAllObjet();
          /*this.router.navigate(['/' + ConstantSourceForm.urlFull.FEES.LIST],
            { queryParams: { [ConstantFormulaires.url.FEES.FEES_]: this.formSelected.id } });*/
        },
        err => {
          console.log('## error create validator: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): SourceFormModel {
    return {
      ...new SourceFormModel(),
      id: this.editForm.get([this.column.id]).value,
      nameSourceFType: this.editForm.get([this.column.nameSourceFType]).value,
      valeur: this.editForm.get([this.column.valeur]).value,
      comment: this.editForm.get([this.column.comment]).value,
      champsFormulaire: this.editForm.get([this.column.champsFormulaire]).value,
      champsFormulaireId: this.formSelected.champsFormulaire.id
    };
  }

  closeModal() {
    console.log('## close formulaire add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}