import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les countries.
 */
export namespace ConstantCountries {
  export const data = {
    title: 'Countries',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un country'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des countries'
      }
    }

  }
  export const url = {
    COUNTRIES: {
      COUNTRIES_: 'countries',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    COUNTRIES: {
      ADD: ConstantCountries.url.COUNTRIES.COUNTRIES_
        .concat(Url.SLASH_ROUTE).concat(ConstantCountries.url.COUNTRIES.ADD),
      LIST: ConstantCountries.url.COUNTRIES.COUNTRIES_
        .concat(Url.SLASH_ROUTE).concat(ConstantCountries.url.COUNTRIES.LIST),
    }
  };
}
