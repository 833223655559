import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { BasePageComponent } from '../../base-page';
import { IOption } from '../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryColumnName, ICountryColumnName } from '../../entities/countries/country-column-name';
import { MatDialogRef } from '@angular/material/dialog';
import { MoniesService } from '../../entities/monies/monies.service';
import { FormService } from '../../../shared/services/form.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { CountryService } from '../../entities/countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { Country, ICountry } from '../../../model/country.model';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Account } from '../../../model/account.model';
import { IUsersColumnName, UsersColumnName } from '../../entities/user-managment/users-column-name';
import { Observable, Subject } from 'rxjs';
import { IServiceItem } from '../../../model/service-item.model';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { LANGUAGES } from '../../../shared/constant/language.constant';
import { TIME_MIN_REQUEST } from '../../../shared/constant/request.contant';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../shared/constant/css-style/css-class.constant';

/**
 * Permet de gérer la mise à jour du profil user.
 */
@Component({
  selector: 'app-profile-liste',
  templateUrl: './profile-update.component.html',
  animations: [fadeIn]
})
export class ProfileUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  languagesList: string[] = LANGUAGES;
  filteredOptions: Observable<string[]>;

  private subs$ = new Subject<void>();
  account: Account;
  isSaving = false;
  successSave = false;
  serverHasError = false;
  theDialogRef: MatDialogRef<ProfileUpdateComponent>;

  editForm: UntypedFormGroup;
  column: IUsersColumnName;

  constructor(
    private dialogRef: MatDialogRef<ProfileUpdateComponent>,
    private accountService: AccountService,
    private formService: FormService,
    private columnName: UsersColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct profile liste');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb);
    this.filteredOptions = this.editForm.get(this.column.langKey).valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterlanguage(value))
      );
  }

  ngOnInit(): void {
    console.log('## init profile liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe((res: Account) => {
      console.log('## get account: ', res);
      console.log('## login: ', res?.login);
      this.account = res;
      if (res) {
        this.editForm.patchValue({
          [this.column.firstName]: res.firstName,
          [this.column.lastName]: res.lastName,
          [this.column.email]: res.email,
          [this.column.langKey]: res.langKey,
          activated: res.activated,
          authorities: res.authorities,
          login: res.login,
          imageUrl: res.imageUrl
        });
      }
      super.setLoaded();
    });
  }

  ngOnDestroy() {
    console.log('## destroy profile liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  displayFnLang(subject: string) {
    return subject ? subject : '';
  }

  private _filterlanguage(value: string): string[] {
    console.log('## filter..');
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.languagesList.filter(option => option.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter language: ' + e.message);
    }
    return filteredList;
  }

  initForm(fb: UntypedFormBuilder) {
    this.editForm = this.fb.group({
      firstName: [undefined, [Validators.required, Validators.minLength(1),
        Validators.maxLength(50)]],
      lastName: [undefined, [Validators.required, Validators.minLength(1),
        Validators.maxLength(50)]],
      email: [undefined, [Validators.required, Validators.minLength(5),
        Validators.maxLength(254), Validators.email]],
      langKey: ['en'],
      activated: [false],
      authorities: [[]],
      login: [],
      imageUrl: []
    });
  }

  save() {
    console.log('## save country');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const settingsAccount = this.accountFromForm();
    this.accountService.save(settingsAccount).subscribe(
      () => {
        console.log('## success liste profil');
        this.serverHasError = false;
        this.responseFromServer();
        this.successSave = true;
        this.editForm.markAsPristine();
        this.accountService.identity().subscribe((ac: Account) => {
          console.log('## get account:', ac);
          this.account = ac;
        });
      },
      () => {
        console.log('## error liste account');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      }
    );
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, TIME_MIN_REQUEST);
  }

  private accountFromForm(): any {
    const account = {};
    return {
      ...account,
      firstName: this.editForm.get('firstName').value,
      lastName: this.editForm.get('lastName').value,
      email: this.editForm.get('email').value,
      activated: this.editForm.get('activated').value,
      authorities: this.editForm.get('authorities').value,
      langKey: this.editForm.get('langKey').value,
      login: this.editForm.get('login').value,
      imageUrl: this.editForm.get('imageUrl').value
    };
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  closeModal() {
    console.log('## close profile liste');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  thereIsNoChange(): boolean {
    return this.formService.isInvalidOrPristineForm(this.editForm);
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
