import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomainColumnName, IDomainColumnName } from '../../domain/domain-column-name';
import { DomainService } from '../../domain/domain.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { Domain } from '../../../../model/domain.model';
import { CostsService } from '../costs.service';
import { Costs } from '../../../../model/costs.model';

/**
 * Permet de gérer l'ajout des coûts.
 */
@Component({
  selector: 'app-costs-add',
  templateUrl: './costs-add.component.html',
  animations: [fadeIn]
})
export class CostsAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<CostsAddComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;

  constructor(
    private costsService: CostsService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<CostsAddComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct costs add');
    this.theDialogRef = this.dialogRef;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init domain add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy domain add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  initForm(fb: UntypedFormBuilder) {
    if (fb) {
      this.editForm = fb.group({
        'label': ['', [Validators.required]]
      })
    }
  }

  /**
   * Enregistre un coût.
   */
  save() {
    console.log('## save coût');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.costsService.create(c, this.costsService.typesUrl)
      .subscribe(res => {
          console.log('## success create types');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.closeModal();
          this.costsService.getAllObjet();
        },
        err => {
          console.log('## error create types: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): Costs {
    return {
      ...new Costs(),
      label: this.editForm.get('label').value
    };
  }

  closeModal() {
    console.log('## close costs add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}
