import { User } from './user.model';

export interface ICUser {
    id?: number;
    firstName?: string;
    lastName?: string;
    login?: string;
    email?: string;
    password?: string;
    phoneNumber?: string;
    phoneNumberTab?: string;
    numCNI?: number;
    activated?: boolean;
    status?: string;
    authority?: string;
    countryId?: number;
    codeCountry?: string,
    codePhone?: number
    currency?: string;
    countryName?: string;
    userId?: number;
    attributsId?: number;
    sendMail?: boolean;
  }
  
  export class CUser implements ICUser {
    constructor(
      public id?: number,
      public firstName?: string,
      public lastName?: string,
      public login?: string,
      public email?: string,
      public password?: string,
      public phoneNumber?: string,
      public phoneNumberTab?: string,
      public numCNI?: number,
      public activated?: boolean,
      public status?: string,
      public authority?: string,
      public countryId?: number,
      public codeCountry?: string,
      public codePhone?: number,
      public countryName?: string,
      public currency?: string,
      public userId?: number,
      public attributsId?: number,
      public sendMail?: boolean
    ) {}
  }
  