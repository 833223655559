import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
// import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { BASE_URL_PORT, environment } from '../environments/environment';
// import { getMessaging, getToken, onMessage } from '@firebase/messaging';
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import firebase from 'firebase/compat';
import MessagePayload = firebase.messaging.MessagePayload;
import { takeUntil } from 'rxjs/operators';
import { AccountService } from './services/ServiceEntity/account.service';
import { Subject } from 'rxjs';
import { NotificationService } from './pages/entities/notification/notification.service';
import { Account } from './model/account.model';
import Messaging = firebase.messaging.Messaging;

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private msg: AngularFireMessaging,
    private titleService: Title,
    private translate: TranslateService,
    private readonly nzConfigService: NzConfigService
  )
  {

    translate.setDefaultLang(CONSTANT_LANG_DEFAULT);
    this.titleService.setTitle(CONSTANT_TITLE_APP);

  }

  account: Account;

  title = 'af-notification';
  message:any = null;
  private subs$ = new Subject<void>();

  // message: string = 'bonjour';

 // messages: Array<Message> = [];

  ngOnInit(): void {
   // this.requestToken();
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(ac => {
        this.account = ac;
        console.log('## account: ', ac);
       // this.accountService.requestPermission(ac);
       // this.accountService.listen();

        //  this.requestPermission(ac);
      //  this.listen();
        console.log('## appel de requestToken: ');
        this.accountService.requestToken();
        console.log('## appel de requestToken2: ');

      });
  }

  requestToken() {
    this.msg.requestToken.subscribe(token => {
      console.log("### token: ", token);
      this.http.post(BASE_URL_PORT + 'api/topic/subscription', {
        topic: 'weather',
        subscriber: token
      }).subscribe(() => {
        console.log("### réponse de la subscription");
      }, error => {
        console.log("### erreur réponse de la subscription");
      });

      this.msg.onMessage((payload) => {
        // Get the data about the notification
        let notification = payload.notification;
        console.log('## notification: ', notification);
        // Create a Message object and add it to the array
        // this.messages.push({title: notification.title, body: notification.body, iconUrl: notification.icon});
      });

    }, error => {

      console.log("## erreur requestToken");
      console.log(error);

    });
  }
}

export const CONSTANT_LANG_DEFAULT = 'fr';
export const CONSTANT_TITLE_APP = 'Service Gate'
