import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CUser } from 'src/app/model/c-user.model';
import { CostsRule, ICostsRule } from 'src/app/model/costs-rule.model';
import { CostsRuleService } from '../costs-rule.service';
import { CostsRuleColumnName, ICostsRuleColumnName } from '../fees-column-name';
import { MoniesService } from '../../monies/monies.service';
import { FormService } from 'src/app/shared/services/form.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { CostsDetails, ICostsDetails } from '../../../../model/costs-details.model';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';
import { CostsDetailsService } from '../../costs-details/costs-details.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit, OnDestroy {

  isSaving: boolean;
  costsRule: ICostsRule;

  fees: number;
  benefit: number;

  // ancien montant avant création frais et bénéfice
  previousAmount: number;

  editForm = this.fb.group({
    id: [],
    amountA: [],
    amountB: [],
    costsDetails: ['', [Validators.required]],
    montant: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
    benefit: [],
    fees: [],
    costsId: [],
    moneyId: [],
    serviceItemId: [],
    countryAId: [],
    countryBId: []
  });
  column: ICostsRuleColumnName;

  successSave = false;
  serverHasError = false;

  // informations sur les coûts.
  costsSelected: CostsDetails;
  costsList: CostsDetails[] = [];
  filteredOptionsCostsDetails: Observable<CostsDetails[]>;
  
  private subs$ = new Subject<void>();


  constructor(
    private costsDetailsService: CostsDetailsService,
    private fb: UntypedFormBuilder,
    protected costsRuleService: CostsRuleService,
    private columnName: CostsRuleColumnName,
    protected moneyService: MoniesService,
    private formService: FormService,
    private dialogRef: MatDialogRef<CreateComponent>,
    @Inject(MAT_DIALOG_DATA) data: { costsRule: ICostsRule }
  ) {
    this.costsRule = data.costsRule;
    const column = this.columnName.getColumnName();
    this.column = column;

  }

  ngOnInit(): void {
    console.log('costsRules....', this.costsRule);
    this.isSaving = false;
    this.editForm.patchValue({
      [this.column.id]: this.costsRule.id,
      [this.column.amountA]: this.costsRule.amountA,
      ['costsDetails']: {
        ...new CostsDetails(),
        id: this.costsRule.typeDetailsId
      },
      //amountB: [null,[Validators.required, Validators.pattern('^[0-9]*$')]],
      //benefit: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      //fees: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      [this.column.moneyId]: this.costsRule.moneyId,
      [this.column.serviceItemId]: this.costsRule.serviceItemsId,
      [this.column.countryAId]: this.costsRule.countryAId,
      [this.column.countryBId]: this.costsRule.countryBId
    });
    this.getCostsDetailsList(); // after 500ms.
    this.fees = this.costsRule.feeCosts;
    this.benefit = this.costsRule.benefit;
    this.previousAmount = this.costsRule.montant

  }
  initForm(fb: UntypedFormBuilder) {
    this.editForm = this.fb.group({
      id: this.costsRule.id,
      costsDetails: ['', [Validators.required]],
      montant: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      amountA: this.costsRule.amountA,
      amountB: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      moneyId: this.costsRule.moneyId,
      serviceItemId: this.costsRule.serviceItemsId,
      countryAId: this.costsRule.countryAId,
      countryBId: this.costsRule.countryBId
    });
  }

  displayFnCosts(subject: ICostsDetails) {
    return subject ? subject.label : '';
  }

  /**
   * Permet de récupérer la liste des détails de coût
   */
  getCostsDetailsList() {
    setTimeout(() => {
      this.costsDetailsService
        .query(this.costsDetailsService.typesUrl)
        .pipe(
          filter((mayBeOk: HttpResponse<CostsDetails[]>) => mayBeOk.ok),
          map((response: HttpResponse<CostsDetails[]>) => response.body)
        )
        .subscribe((res: CostsDetails[]) => {
            console.log('## success get list costs details while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.costsList = rs;
            this.filteredOptionsCostsDetails = this.editForm.get('costsDetails').valueChanges
              .pipe(
                startWith(''),
                map(value => this._filterCostsDetails(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list costs details while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  /**
   * Effectue une recherche sur les détails de coût
   * @param value la valeur à rechercher
   * @private
   */
  private _filterCostsDetails(value: string): ICostsDetails[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.costsList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  private createFromForm(): ICostsRule {
    return {
      ...new CostsRule(),
      id: this.editForm.get(['id']).value,
      amountA: this.editForm.get(['amountA']).value,
      amountB: this.editForm.get(['amountB']).value,
      moneyId: this.editForm.get(['moneyId']).value,
      montant: this.editForm.get(['montant']).value,
      typeDetailsId: (this.editForm.get(['costsDetails']).value as
        ICostsDetails).id,
      serviceItemsId: this.editForm.get(['serviceItemId']).value,
      countryAId: this.editForm.get(['countryAId']).value,
      countryBId: this.editForm.get(['countryBId']).value
    };
  }

  save() {
    this.isSaving = true;
    this.successSave = false;
    if (this.editForm.get(['moneyId']).value == null) {
      this.onSaveError();
    } else {
      const costsRule = this.createFromForm();
      console.log('costsrule liste fees....', costsRule);
      if (costsRule.id !== undefined) {
        this.costsRuleService.update(costsRule).subscribe(res =>{
          console.log('responseeee', res);
          
        });
        costsRule.id = null;
        costsRule.amountA = this.createFromForm().amountB;
        costsRule.amountB=this.costsRule.amountB;
        
        costsRule.feeCosts = this.fees;
        costsRule.benefit = this.benefit;
        console.log('costsrule create new fees....', costsRule);
        costsRule.montant = this.previousAmount;

        this.costsRuleService.create(costsRule).subscribe(res =>{
          console.log('responseeee', res);          
          this.editForm.reset();
          this.successSave = true;
          this.costsRuleService.queryList().subscribe(data => {
            console.log('## Success get costs rule list after saving');
            const c: ICostsRule[] = (data.body) ? data.body : [];
            console.log('## size: ' + c.length);
            this.costsRuleService.setCostRuleObs(c);
            this.closeModal();
          });
        });
      }
    }

    
  }

  protected onSaveError() {
    this.isSaving = false;
  }

  closeModal() {
    console.log('## close costs rule add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }
  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }
  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }
  ngOnDestroy() {
    console.log('## destroy costs rule liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }





}
