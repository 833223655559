import { Routes } from '@angular/router';
import { ConstantMonies } from '../monies/monies.url';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';
import { MoniesComponent } from '../monies';
import { ConstantCosts } from './costs.url';
import { CostsComponent } from './costs.component';

/**
 * Ensemble des routes pour gérer les coûts.
 */
export const COSTS_ROUTE: Routes = [
  {
    path: ConstantCosts.url.COSTS.COSTS_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantCosts.url.COSTS.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantCosts.url.COSTS.LIST,
        component: CostsComponent
      }
    ]
  }
];