import { Injectable } from '@angular/core';
import { BASE_URL, BASE_URL_MANAGEMENT } from '../../../../../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IBenefitRule } from '../../../../model/benefit-rule.model';
import { createRequestOption } from '../../../../util/request-util';
import { Validators } from '../../../../model/champs_formulaire/validators.model';
import { ICountry } from '../../../../model/country.model';
import { ApiService } from '../../../../shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class ValidatorService extends ApiService {
  public resourceUrl = BASE_URL_MANAGEMENT + '/validators';
  public list_url = BASE_URL_MANAGEMENT + '/validators/all';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAllObjet(req?: any) {
    super.getAllObjet(this.list_url, req);
  }
}
