import { Component, OnDestroy, OnInit } from '@angular/core';
//import cloneDeep from 'lodash/clonedeep';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../../base-page';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import * as PageActions from '../../../../store/actions/page.actions';

@Component({
  selector: 'page-sorting-table',
  templateUrl: './sorting-table.component.html',
  styleUrls: ['./sorting-table.component.scss']
})
export class PageSortingTableComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  tableData: any[];
  displayedData: any[];

  constructor(store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);

    this.pageData = {
      title: 'Sorting tables',
      key: '',
      breadcrumbs: [
        {
          title: 'UI Kit',
          key: '',
          route: 'dashboard'
        },
        {
          title: 'Tables',
          key: '',
          route: 'dashboard'
        },
        {
          title: 'Sorting',
          key: '',
        }
      ]
    };
    this.tableData = [];
  }

  ngOnInit() {
    super.ngOnInit();

    this.getData('assets/data/table-sorting.json', 'tableData', 'initTable');
  }

  initTable(): void {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );

    setTimeout(() => {
      this.displayedData = JSON.parse(JSON.stringify(this.tableData));
    });
  }

  sort({ key, value }): void {
    const sortingEnabled = key && value;

    if (sortingEnabled) {
      const direction = value === 'ascend' ? 1 : -1;
      const sortFn = (prev, cur) => (prev[key] > cur[key] ? 1 : -1) * direction;

      this.displayedData = this.displayedData.sort(sortFn);
    } else {
      this.displayedData = this.tableData;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
