import { Component, Input, OnInit } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { AbstractControl } from '@angular/forms';
import {
  BEFORE_ICON_RESET,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALIDATION_CLASS,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS, CONTAINER_POPUP_CLASS,
  FORM_CLASS, ICON_COLOR_REQUEST_CSS, ICON_FONT_REQUEST_CLASS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET, REMOVABLE_ICON_REQUEST, RESULT_REQUEST_ERROR_CLASS, RESULT_REQUEST_SUCCESS_CLASS,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS,
  SUFFIX_ICON_FORM, TITLE_POPUP_CLASS,
  WARNING_COLOR_CSS, WIDTH_MEDIUM_POPUP_CLASS
} from '../../constant/css-style/css-class.constant';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { PrintService } from '../../services/print.service';
import { Transaction } from '../../../model/transaction.model';
import { ICashModel } from '../../../pages/entities/cash/cash-data-model';
import { HttpClient } from '@angular/common/http';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-code-transaction',
  templateUrl: './code-transaction.component.html',
  animations: [fadeIn]
})
export class codeTransactionComponent implements OnInit {

  @Input('title') title: string;
  @Input('cashModel') data: ICashModel;
  @Input('transactionResult') ReponseTransfert: Transaction;

  docDefinition: any;

  constructor(
    private http: HttpClient,
    private printService: PrintService) {
    console.log('## construct code transaction')
  }

  ngOnInit(): void {
    console.log("## init code transaction");
    console.log('## ReponseTransfert: ', this.ReponseTransfert);
  }

  copyInputMessage(inputElement) {
    console.log('## copy: ', inputElement);
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    console.log('## copy: ', inputElement.value);
  }

  printPdf() {

    this.getLogoAndPrint();
  }

  getLogoAndPrint() {
    let img = './assets/img/logo-insoft.png'
    this.http.get(img,
      {responseType: 'blob'}).subscribe(res => {
      console.log('res img size:', res.size);
      console.log('res img tyoe:', res.type);
      console.log('res img data:', res);

      let reader = new FileReader();
      reader.addEventListener("load", () => {
        let r = reader.result;
        console.log('## res r:', r);

        const documentDefinition = {
          content: 'This is an sample PDF printed with pdfMake',
        };
        console.log('## the transaction: ', this.ReponseTransfert);
        console.log('## the transaction: ', this.data);
        pdfMake.createPdf(this.printService.getDetailsTransaction(this.ReponseTransfert,
          this.docDefinition, this.data, r)).print();

      }, false);

      if (res) {
        reader.readAsDataURL(res);
      }

    });
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  isCancelBtnOutline(): boolean {
    return OUTLINE_CANCEL;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getContainerBtnClass() {
    return CONTAINER_BTN_POPUP_CLASS;
  }

  getFormClass() {
    return FORM_CLASS;
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  getResultRequestTextSuccess(): string {
    return RESULT_REQUEST_TEXT_SUCCESS_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getWarningColor(): string {
    return WARNING_COLOR_CSS;
  }

  getInside2xContainerClass(): string {
    return CONTAINER_INSIDE2X_POPUP_CLASS;
  }

  getInsideContainerClass(): string {
    return CONTAINER_INSIDE_POPUP_CLASS;
  }
  getContainerClass() : string {
    return CONTAINER_POPUP_CLASS;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getIconColorRequestClass(): string {
    return ICON_COLOR_REQUEST_CSS;
  }

  isRemovable(): boolean {
    return REMOVABLE_ICON_REQUEST;
  }

  getTitleClass(): string {
    return TITLE_POPUP_CLASS;
  }

  getIconRequestClass(): string {
    return ICON_FONT_REQUEST_CLASS;
  }

  getResultRequestErrorClass() {
    return RESULT_REQUEST_ERROR_CLASS;
  }

  getResultRequestSuccessClass() {
    return RESULT_REQUEST_SUCCESS_CLASS;
  }
}
