import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { MONIES_ROUTE } from '../monies/monies.route';
import { MoneyDeleteComponent } from '../monies/delete/monies-delete.component';
import { MoniesUpdateComponent } from '../monies/update/monies-update.component';
import { MoniesAddComponent } from '../monies/add/monies-add.component';
import { MoniesShowComponent } from '../monies/show/monies-show.component';
import { MoniesComponent } from '../monies';
import { ServiceItemsComponent } from './service-items.component';
import { SERVICE_ITEMS_ROUTE } from './service-item.route';
import { ServiceItemAddComponent } from './add/service-item-add.component';
import { ServiceItemUpdateComponent } from './update/service-item-update.component';
import { ServiceItemDeleteComponent } from './delete/service-item-delete.component';
import { ServiceItemImageComponent } from './image/service-item-image.component';

/**
 * Ce module permet de gérer le model service item.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [ServiceItemAddComponent, ServiceItemDeleteComponent,
        ServiceItemUpdateComponent, ServiceItemImageComponent,
        ServiceItemsComponent]
})
export class ServiceItemModule {}
