import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IOption } from '../../../../ui/interfaces/option';
import { IAccountRessType } from '../../../../model/acc_ress_type.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryColumnName, ICountryColumnName } from '../country-column-name';
import { MatDialogRef } from '@angular/material/dialog';
import { MoniesService } from '../../monies/monies.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { Country, ICountry } from '../../../../model/country.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';

/**
 * Permet de gérer la modification des countries.
 */
@Component({
  selector: 'app-country-liste',
  templateUrl: './country-update.component.html',
  animations: [fadeIn]
})
export class CountryUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;
  monies: IOption[] = [];
  theDialogRef: MatDialogRef<CountryUpdateComponent>;
  editForm: UntypedFormGroup;
  column: ICountryColumnName;
  anotherMoney: boolean;
  private countrySelected: ICountry;
  private subs$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private dialogRef: MatDialogRef<CountryUpdateComponent>,
    private moniesService: MoniesService,
    private formService: FormService,
    private columnName: CountryColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct country liste');
    this.theDialogRef = this.dialogRef;
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init country liste');
    this.accountService.identity().pipe(takeUntil(this.subs$)).subscribe(res => {
      this.countryService.getCountrySelectObs()
        .pipe(takeUntil(this.subs$)).subscribe((res: ICountry) => {
        console.log('## get country selected to liste');
        try {
          console.log('## data: ' + JSON.stringify(res));
          this.countrySelected = res;
          this.editForm.patchValue({
            [this.column.id]: res.id,
            [this.column.label]: res.label,
            [this.column.codeCountry]: res.codeCountry,
            [this.column.codePhone]: res.codePhone,
            [this.column.mId]: res.mId
          })
          this.getAllMonies(res);
        }catch (e) {
          console.log('## error get country selected: ' + e.message);
        }
      });
    })
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy country liste');
    this.successSave = false;
    this.formService.free(this.subs$);
    this.countryService.setCountrySelectObs(undefined);
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getAllMonies(countrySelected: ICountry) {
    setTimeout(()=>{
      this.moniesService
        .query().subscribe((res) => {
        console.log('## get all monies');
        const monies = (res) ? res.body : [];
        console.log('## size: ' + monies.length);
        this.monies = [];
        let selected = false;
        monies.forEach(m => {
          selected = (countrySelected.mId === m.id) ? true: false;
          this.monies.push({
            ...new Option(),
            label: m.label,
            value: m.id?.toString(),
            selected: selected
          })
        })
        console.log('## val: ' + JSON.stringify(this.monies));
      });
    }, 500)
  }

  initForm(fb: UntypedFormBuilder, column: ICountryColumnName) {
    if(fb && column) {
      this.editForm = fb.group({
        [column.id]: [],
        [column.label]: [
          '',
          [Validators.required, Validators.minLength(3),
            Validators.maxLength(50), Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        [column.codeCountry]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(5), Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        [column.codePhone]: ['', [Validators.required, Validators.minLength(2),
          Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
        [column.mId]: ['', [Validators.required]],
        [column.mLabel]: ['', [Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]],
        [column.mLabelCourt]: ['', [Validators.pattern("^[A-Za-z]+((\\s)?((\\'|\\-|\\.)?([A-Za-z])+))*$")]],
        [column.mValue]: [''],
        [column.mUnit]: [''],
        [column.mCode]: ['', Validators.pattern('^([A-Za-z])+$')]
      });
    }
  }

  /**
   * Enregistre un type de account ressource
   */
  save() {
    console.log('## liste country');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: ICountry = this.createFromForm();
    console.log('## c: ' + JSON.stringify(c));
    this.countryService.update(c).subscribe(res => {
        console.log('## success liste country');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.markAsPristine();
       // this.editForm.reset();
        this.successSave = true;
        this.getAllMonies(this.countrySelected);
        this.getAllCountry();
      },
      err => {
        console.log('## error liste country');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  getAllCountry() {
    setTimeout(() => {
      this.countryService.query().subscribe(res => {
        console.log('## Success get country list');
        this.countryService.setCountryObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300)
  }

  private createFromForm(): ICountry {
    return {
      ...new Country(),
      id: this.editForm.get(['id']).value,
      label: this.editForm.get(['label']).value,
      codeCountry: this.editForm.get(['codeCountry']).value,
      codePhone: this.editForm.get(['codePhone']).value,
      mId: +this.editForm.get(['mId']).value
    };
  }

  private createFromFormMoney(): ICountry {
    return {
      ...new Country(),
      id: this.editForm.get(['id']).value,
      label: this.editForm.get(['label']).value,
      codeCountry: this.editForm.get(['codeCountry']).value,
      codePhone: this.editForm.get(['codePhone']).value,
      mLabel: this.editForm.get(['mLabel']).value,
      mLabelCourt: this.editForm.get(['mLabelCourt']).value,
      mValue: this.editForm.get(['mValue']).value,
      mUnit: this.editForm.get(['mUnit']).value,
      mCode: this.editForm.get(['mCode']).value
    };
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidOrPristineForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
