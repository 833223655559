import { Injectable } from '@angular/core';
import { Account } from '../../model/account.model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BASE_URL, BASE_URL_PORT, environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ROUTE_HOME_FULL } from '../../layout/components/home.route';
import { TOKEN, TOKEN_USERNAME } from '../../shared/constant/request.contant';
import { MatDialog } from '@angular/material/dialog';
import { getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import firebase from 'firebase/compat';
import MessagePayload = firebase.messaging.MessagePayload;
import { NotificationService } from '../../pages/entities/notification/notification.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Settings } from '../../model/settings.model';


type EntityResponseType = HttpResponse<Account>;
/**
 * Gére tout ce qui est authentification d'un user.
 */
@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: Account;
  private authenticated = false;
  private authenticationState = new BehaviorSubject<Account>(undefined);
  private resourceUrl = BASE_URL + 'Allusers';
    private accountApi = 'account';

  constructor(
    private msg: AngularFireMessaging,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: Router,
    private http: HttpClient) {
  }

  /**
   * Récupère la version du projet
   */
  getVersion(): Observable<HttpResponse<Settings>>{
    return this.http.get<Settings>(`${BASE_URL}version`, {observe: 'response'});
  }

  requestToken() {
    console.log('## requestingToken');
    this.msg.requestToken.subscribe(token => {
      console.log("### token: ", token);
      this.http.post(BASE_URL_PORT + 'api/topic/subscription', {
        subscriber: token
      }).subscribe(() => {
        console.log("### réponse de la subscription");
      }, error => {
        console.log("### erreur réponse de la subscription");
      });

      this.msg.onMessage((payload) => {
        // Get the data about the notification
        let notification = payload.notification;
        console.log('## notification: ', notification);
        // récupération des notifications
        this.notificationService.getAllObjetBySubject();

        // Create a Message object and add it to the array
        // this.messages.push({title: notification.title, body: notification.body, iconUrl: notification.icon});
      });

    }, error => {

      console.log("## erreur requestToken");
      console.log(error);

    });
  }
  
  /*subscribeToTopic(token, topic) {
    getMessaging().subscribeToTopic(registrationTokens, topic)
      .then((response) => {
        // See the MessagingTopicManagementResponse reference documentation
        // for the contents of response.
        console.log('Successfully subscribed to topic:', response);
      })
      .catch((error) => {
        console.log('Error subscribing to topic:', error);
      });
  }*/

  /**
   * Récupère le token et souscrit à un topic.
   * @param ac le compte de l'utilisateur
   */
  requestPermission(ac: Account) {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebaseConfig.vapidKey}).then(
      (token) => {
        if (token) {
          console.log("### We got the token.....");
          console.log(token);
          const topic = ac.id.toString().concat('-').concat(ac.login);
          console.log('generated topic: ', topic);
          this.subscribeTokenToTopic(token, topic);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }

  subscribeTokenToTopic(token, topic) {
    fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'key='+ environment.firebaseConfig.serverKey
      })
    }).then(response => {
      if (response.status < 200 || response.status >= 400) {
        throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
      }
      console.log('Subscribed to topic: ', topic);
    }).catch(error => {
      console.error(error);
    })
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: MessagePayload) => {
      console.log('## Message received: ', payload);
      // récupération des notifications
      this.notificationService.getAllObjetBySubject();
    });
  }

  /**
   * Doit permettre de déconnecter un utilisateur.
   */
  logout() {
    console.log('## logout the user..');
    this.logoutUser();
    this.dialog.closeAll();
    this.router.navigate([ROUTE_HOME_FULL.signIn]);
  }


  hasAutority(role: string) {
    let hasRole = false;
    if(this.userIdentity) {
     const ol = this.userIdentity.authorities;
     hasRole = (ol.indexOf(role) !== -1) ? true : hasRole;
    }
    return hasRole;
  }
 

  /**
   * @Return le compte de l'utilisateur connecté.
   */
  fetch(): Observable<Account> {
    return this.http.get<Account>(BASE_URL + this.accountApi);
  }

  getAll(id: number): Observable<any> {
    return this.http.get<Account>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }

  save(account: Account): Observable<Account> {
    return this.http.post<Account>(BASE_URL + this.accountApi, account);
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${BASE_URL}account`, { observe: 'response' });
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }

    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }

    return authorities.some((authority: string) => this.userIdentity.authorities.includes(authority));
  }

  getAccount(): Observable<Account>{
    return this.fetchAccount();
  }

  /**
   * @Return le compte de l'utilisateur connecté.
   * Si le compte est inexistant, on le déconnecte.
   */
  private fetchAccount(): Observable<Account> {
    return this.fetch().pipe(
      tap(
        account => {
          if (account) {
            console.log('## success get account:', account);
            this.authenticated = true;
          } else {
            this.authenticated = false;
            this.logout();
          }
          this.userIdentity = account;
          this.authenticationState.next(this.userIdentity);
        },
        (err) => {
          console.log('## error get account:', err.message);
          this.authenticated = false;
          this.logout();
        }
      ),
    );
  }

  /**
   * @param force si cela vaut true, alors on envoie
   * une requête GET pour récupérer le compte de l'utilisateur
   * connecté, sinon on récupère la valeur contenu dans la variable.
   * @Return le compte de l'utilisateur connecté.
   */
  identity(force = true): Observable<Account> {
    console.log('## identity');
    if (force || !this.userIdentity) {
      console.log('## try to get account');
      return this.fetch().pipe(
        tap(
          account => {
            if (account) {
              console.log('## success get account:', account);
              console.log('## login:', account.login);
              this.authenticated = true;
              localStorage.setItem(TOKEN_USERNAME, account.login);
              // After retrieve the account info, the language will be changed to
              // the user's preferred language configured in the account setting
              /*if (this.userIdentity.langKey) {
                const langKey = this.sessionStorage.retrieve('locale') || this.userIdentity.langKey;
                this.languageService.changeLanguage(langKey);
              }*/
            } else {
              this.logout();
            }
            this.userIdentity = account;
            this.authenticationState.next(this.userIdentity);
          },
          () => {
            this.logout();
          }
        ),
      )
    }
    return of(this.userIdentity);
  }

  /**
   * Permet d'authentifier un utilisateur.
   * @param data les données d'authentification.
   */
  login(data:any):Observable<any>{
    console.log('## login process');
    return this.http.post(`${BASE_URL}authenticate`,data).pipe(
      tap(
        (tokenInfos: any) => {
          if (tokenInfos) {
            console.log('## success get tokenInfos:', tokenInfos);
            this.authenticated = true;
            localStorage.setItem(TOKEN, tokenInfos.id_token)
          }
        },
        () => {
          this.authenticated = false;
          localStorage.removeItem(TOKEN);
        }
      ),
      shareReplay()
    )
  }

  /**
   * Déconnecte un utilisateur.
   */
  private logoutUser() {
    this.userIdentity = null;
    this.authenticated = false;
    this.authenticationState.next(undefined);
    localStorage.removeItem(TOKEN);
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
  }
}
