import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CashDataModel, ICashModel, IRetraitCode } from '../cash/cash-data-model';
import { ServiceItemCompose, ServiceItemFils } from '../../../model/service-item.model';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IMobileAccount, MobileAccount } from '../../../model/mobile-account.model';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { TIME_MIN_X1_REQUEST, TIME_MIN_X2_REQUEST, TIME_MIN_X_REQUEST } from '../../../shared/constant/request.contant';
import { AccountUserService } from '../../../services/ServiceEntity/account-user.service';
import { AccountDTO } from '../../../model/iaccount.model';
import { IApiKeyDTO } from '../../../model/api-key.model';
import { CUserApiKeyService } from '../../../services/ServiceEntity/c-user-api-key.service';
import { BaseService } from '../base/base.service';
import {
  BEFORE_ICON_RESET,
  BEFORE_ICON_VALIDATION, BTN_CANCEL_CLASS, BTN_POPUP_CLASS,
  BTN_VALIDATION_CLASS, CANCEL_BG_COLOR, CONTAINER_DIALOG_POPUP,
  OUTLINE_RESET
} from '../../../shared/constant/css-style/css-class.constant';
import { CONSTANT_ICON } from '../../../shared/constant/icon.constant';
import { ColorService } from '../../../shared/services/color.service';
import { ApiKeyUpdateComponent } from '../api-key/update/api-key-update.component';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { STATUS_BD_CONSTANT } from '../../../model/status.model';
import { ApiKeyService } from '../api-key/api-key.service';
import { ApiKeyListIpAdressComponent } from '../api-key/list-ip-adress/api-key-list-ip-adress.component';
import { ApiKeyDeleteComponent } from '../api-key/delete/api-key-delete.component';
import { FormService } from '../../../shared/services/form.service';
import { DynamicFormComponent } from '../cash/dynamic-form/dynamic-form.component';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';

@Component({
  selector: 'app-account-api-key',
  templateUrl: './account-api-key.component.html',
  styleUrls: ['./account-api-key.component.css']
})
export class AccountApiKeyComponent
  implements OnInit, OnDestroy {

  CurentUser: any;

  versionControl = new UntypedFormControl();

  filteredOptions: Observable<AccountDTO[]>;

  accountDTOS: AccountDTO[];

  mobileAccountSelect: AccountDTO;

  //liste apiKey
  listApiKey: IApiKeyDTO[] = [];
  listApiKeySearch: IApiKeyDTO[] = [];

  show: string;
  notShow: string;

  @ViewChild('basicTable') tabRef: any;

  serviceCompose: ServiceItemCompose;

  subs$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private apiKeyService: ApiKeyService,
    private colorService: ColorService,
    protected accountService: AccountService,
    public baseService: BaseService,
    private dialog: MatDialog,
    private dialogDynamicRef: MatDialogRef<AccountApiKeyComponent>,
    private cUserApiKeyService: CUserApiKeyService,
    protected mAccountService: AccountUserService,
    @Inject(MAT_DIALOG_DATA) public data: ServiceItemCompose) {
    console.log("## construct accountApiKey");
    console.log('## data: ', data);
    this.serviceCompose = data;
  }

  ngOnInit(): void {
    console.log('## on init accountApiKey');
    console.log('## service id id: ', this.data.parent.serviceId);
    this.accountService.identity().subscribe(account => {
      this.CurentUser = account;
      if(account) {
        this.loadAllMobileAccounts(this.data.parent.serviceId);
      }
    });
    this.getListApiKeysBySubject();
    this.show = this.getIcon().show;
  }

  ngOnDestroy() {
    console.log('## on destroy');
    this.formService.free(this.subs$);
  }

  /**
   * Ouvre le component d'envoie code.
   * @param r informations du service parent.
   * @param fs liste des services fils du parent
   * @param serviceName
   */
  openTemplate(fs: ServiceItemFils[], apiKey: IApiKeyDTO,
               r?: IRetraitCode, serviceName?: string) {
    console.log('## fils: ', fs);
    console.log('## parent: ', r.idServiceItem);
    let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    let idServiceItem;
    let serviceItemId;
    let f = fs.slice();
    // on vérifie si le parent n'a pas de fils.
    if (typeof f === 'undefined' || f.length <= 0) {
      idServiceItem = r.idServiceItem;
      serviceItemId = r.serviceItemId;
    } else {
      const f1 = f[0];
      idServiceItem = f1.serviceId;
      serviceItemId = f1.serviceItemId;
    }
    d.dataCode.idServiceItem = idServiceItem;
    d.dataCode.serviceItemId = serviceItemId;
    d.dataCode.serviceName = serviceName;
    d.dataCode.serviceItemFilsList = f;
    d.dataCode.serviceItemIdInter = serviceItemId;
    d.dataCode.apiKey = apiKey?.keyValue;
    console.log('## api key: ', d.dataCode.apiKey);
    this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        numberAccount: this.mobileAccountSelect.numberAccount,
        currency: this.mobileAccountSelect.codeMoney,
        others: r,
        theDialog: this.dialogDynamicRef,
        cashDataModel: d
      }
    });
  }

  affectStatus(d: IApiKeyDTO) {
    console.log('## open affect status: ', d);
    let a = {
      'apiKeyId': d.id,
      'statusId': (d.statusId === STATUS_BD_CONSTANT.actived) ? STATUS_BD_CONSTANT.deactived
        : STATUS_BD_CONSTANT.actived
    }
    this.apiKeyService.updateStatus(a).subscribe(res => {
      console.log('## success change status api key');
      setTimeout(() => {
        this.apiKeyService.getAllObjetById();
      }, TIME_MIN_X1_REQUEST);

    }, (res: HttpErrorResponse) => {
      console.log('## error get list status while adding: ' + res.message);
    })
  }

  isActivated(data: IApiKeyDTO): boolean {
    return data.statusId === STATUS_BD_CONSTANT.actived;
  }

  listerIpAdress(d: IApiKeyDTO) {
    console.log('open popup list ipAdress');
    console.log('## api key:', d);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(ApiKeyListIpAdressComponent, {
      data: {
        apiKey: d,
        idAccount: this.mobileAccountSelect.accountUserId, currentAccount: this.CurentUser,
        thisUserId: this.CurentUser.id
      }, height: '700px', width: '800px'
    });
  }

  delete(d: IApiKeyDTO) {
    console.log('## delete api key:', d);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(ApiKeyDeleteComponent, {
      data: {
        apiKey: d,
        idAccount: this.mobileAccountSelect.accountUserId, currentAccount: this.CurentUser,
        thisUserId: this.CurentUser.id
      }, height: '600px', width: '800px'
    });
  }

  getListApiKeysBySubject() {
    // récupération de la liste des api keys.
    this.apiKeyService.getObjetObs().pipe(takeUntil(this.subs$))
      .subscribe((res: IApiKeyDTO[]) => {
        console.log('## get list api key in list by subject: ', res);
        this.listApiKey = (res) ? res : [];
        this.listApiKeySearch = this.listApiKey;
      });
  }

  update(d: IApiKeyDTO) {
    console.log('## open api key liste');
    console.log('## api key:', d);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '700px';
    dialogConfig.width = '800px';
    this.dialog.open(ApiKeyUpdateComponent, {
      data: {
        apiKey: d,
        idAccount: this.mobileAccountSelect.accountUserId, currentAccount: this.CurentUser,
        thisUserId: this.CurentUser.id
      }, height: '600px', width: '800px'
    });
    // this.baseService.openSm(this.dialog, CUserAddKeyComponent);
  }

  /**
   * Récupère la liste des mobiles account et
   * les initialise dans un auto complete.
   */
  loadAllMobileAccounts(serviceId: number) {
    console.log('## try to find accounts for service: ', serviceId);
    setTimeout(() => {
      let p = new HttpParams();
      p = p.set('service', serviceId.toString());
      this.mAccountService
        .findAccounts(p)
        .subscribe((res: HttpResponse<AccountDTO[]>) => {
          this.accountDTOS = (res && res.body) ? res.body : [];
          console.log('success get mobile accounts:', this.accountDTOS);
          this.filteredOptions = this.versionControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        });
    }, TIME_MIN_X2_REQUEST);
  }

  private _filter(value: any): AccountDTO[] {
    let filteredList;
    try {
      console.log('## value version: ', value);
      const filterValue = value.toLowerCase();
      filteredList = this.accountDTOS
        .filter(option => option.codeMoney.toLowerCase()
                  .includes(filterValue) ||
                (option.accountLabel && option.accountLabel.toLocaleLowerCase()
                  .includes(filterValue)
                )
              );
    } catch (e) {
      console.log('## error filter mobile account: ' + e.message);
    }
    return filteredList;
  }

  displayFnVersion(subject: AccountDTO) {
    return subject ? subject.codeMoney + ' - ' + subject.accountLabel : '';
  }

  openValueApikey(inputKey: any, buttonKey: any) {
    console.log('button ##', buttonKey);
    if (inputKey.type === 'password') {
      inputKey.type = 'text';
      buttonKey.beforeIcon = this.getIcon().notShow;
    } else {
      inputKey.type = 'password';
      buttonKey.beforeIcon = this.getIcon().show;
    }
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getIconValidation(): string {
    return BEFORE_ICON_VALIDATION;
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  getStatusBackground(statusId: number) {
    return this.colorService.getStatusBg(statusId);
  }

  getAmountColBg() {
    return this.colorService.getAmountColBg();
  }

  /**
   * Récupère toutes les api keys associés à un account.
   * @param m
   */
  getAllApiKeys(m: AccountDTO) {
    setTimeout(() => {
      this.cUserApiKeyService.findAllByAccountId(+m.accountUserId)
        .subscribe(res => {
        this.mobileAccountSelect = m;
        this.listApiKey = (res.body) ? res.body : [];
        this.listApiKeySearch = this.listApiKey;
        console.log('## api key length: ', this.listApiKey.length);
        console.log('## liste api key found: ', this.listApiKey);
        console.log('responseeeeeeeeeee', this.listApiKey);
      });
    }, TIME_MIN_X_REQUEST);
  }

  /**
   * Retourne la liste des des api key
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): IApiKeyDTO[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  /**
   * Récupère toutes les api keys associés à un account.
   * @param m
   */
  getAllApiKeysSelect(e: MatAutocompleteSelectedEvent) {
    const m = e.option.value as AccountDTO;
    console.log('## select mobile account: ', m);
    this.getAllApiKeys(m);
  }
}