import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { STATUS } from '../../model/account.model';
import { STATUS_NAME } from '../../model/user.model';
import { STATUS_BD_CONSTANT } from '../../model/status.model';

/**
 * Permet d'effectuer des contrôles sur les champs_formulaires.
 */
@Injectable({ providedIn: 'root' })
export class ColorService {
  constructor() {
  }

  private envSettings = environment.appSettings;
  private headerFormBg: string;
  private sidebarBg2: string;
  private titleHeaderFormColor: string;

  resetDefault() {
    this.headerFormBg = this.envSettings.headerFormBg;
    this.titleHeaderFormColor = this.envSettings.titleHeaderFormColor;
  }

  getStatusBg(id: number): string {
    let result = 'error';
    if(id === STATUS_BD_CONSTANT.actived || id === STATUS_BD_CONSTANT.successful
        || id === STATUS_BD_CONSTANT.approved) {
      result = 'success';
    } else if(id === STATUS_BD_CONSTANT.new_user || id === STATUS_BD_CONSTANT.in_progess
    || id === STATUS_BD_CONSTANT.pending) {
      result = 'info'
    }
    return result;
  }

  getStatusUserBg(status: boolean) {
    let background = 'success';
    if(status) {
      background = 'success';
    } else {
        background = 'error';
    }
    return background;
  }

  getStatusBackground(status: string) {
    let background = 'warning';
    if (status) {
      if (status === STATUS.actived || status === STATUS.successful) {
        background = 'success';
      } else if (status === STATUS.deactived || status === STATUS.failed
      || status === STATUS.failled) {
        background = 'error';
      }
    }
    return background;
  }

  getBrdColorSearchBar() {
    return '#c5c6ff';
  }

  getColorSearchBar() {
    return '#505050';
  }

  getInfoUserBg() {
    return '#375772';
  }

  getAmountColBg() {
    return '#5d6679';
  }

  getTheadBg() {
    return '#717c94';
  }

  getSideBarBg2() {
    return this.sidebarBg2 ? this.sidebarBg2
      : this.envSettings.sidebarBg2;
  }

  setSidebarBg2(bg: string) {
    this.sidebarBg2 = bg;
  }

  getHeaderFormBg() {
    return this.headerFormBg ? this.headerFormBg
      : this.envSettings.headerFormBg;
  }

  getTitleHeaderFormColor() {
    return this.titleHeaderFormColor ? this.titleHeaderFormColor
      : this.envSettings.titleHeaderFormColor;
  }

  setHeaderFormBg(bg: string) {
    this.headerFormBg = bg;
  }

  setTitleHeaderFormColor(col: string) {
    this.titleHeaderFormColor = col;
  }

  getPrefixColorSelect(): string {
    return '#717171';
  }
}
