export interface IMoney {
  id?: number;
  label?: string;
  value?: string;
  unit?: string;
  labelCourt?: string;
  codeMoney?: string;
}

export class Money implements IMoney {
  constructor(
    public id?: number,
    public label?: string,
    public value?: string,
    public unit?: string,
    public labelCourt?: string,
    public codeMoney?: string
  ) {}
}
