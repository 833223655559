
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Account } from '../../../../model/account.model';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { FormService } from '../../../../shared/services/form.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AccountService } from '../../../../services/ServiceEntity/account.service';

import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { TransactionService } from '../transaction.service';
import { TIME_LONG_REQUEST, TIME_MIN_REQUEST, TIME_VERY_LONG_REQUEST } from '../../../../shared/constant/request.contant';
import { fadeIn } from '../../../../animations/form-error';
import { takeUntil } from 'rxjs/operators';
import { WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { FileExcelService } from 'src/app/services/fileDownload/file-excel.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-transaction-multi',
  templateUrl: './transaction-multi.component.html',
  animations: [fadeIn]
})
export class TransactionMultiComponent extends BasePageComponent implements OnInit,
  OnDestroy, AfterViewChecked {

   

  extensionsAutorise = ['xlsx', 'xls', 'xlt'];
  isSaving = false;
  column = {
    file: 'file',
    password: 'password',
    fileSource: 'fileSource'
  };
  file: any;
  password: string;
  successSave = false;
  serverHasError = false;
  editForm: UntypedFormGroup;
  account: Account; // the account connected.
  subs$ = new Subject<void>();
  theDialogRef: MatDialogRef<TransactionMultiComponent>;

  constructor(
    private transactionService: TransactionService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialogRef: MatDialogRef<TransactionMultiComponent>,
    private accountService: AccountService,
    private fileExcelService:FileExcelService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct trans multi');
    this.theDialogRef = this.dialogRef;
    this.editForm = new UntypedFormGroup({
      name: new UntypedFormControl('sdf', []),
      file: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl('', [Validators.required])
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init trans multi');
    super.ngOnInit();
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe((ac: Account) => {
      console.log('## success get account:', ac);
      this.account = ac;
    });
  }

  ngOnDestroy() {
    console.log('## on destroy trans multi');
    this.formService.free(this.subs$);
  }

  downloadexcel(): void 
    {
      this.fileExcelService.download().subscribe(blob =>
        {
          console.log('file excel#####', blob); 
          saveAs(blob, 'templateExcel.xlsx');
        })
    }
   /*  openExcel(){
      this.fileExcelService.download().subscribe(blob =>
        {
          console.log('file excel#####', blob); 
          const url= window.URL.createObjectURL(blob);
          window.open(url);
        

        }) 
			
    

    } */

  sendFile() {
    console.log('## try to send file');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const formData = new FormData();
    formData.append(this.column.file, this.file);
    formData.append('serviceItemId', '' +104);
    formData.append(this.column.password, this.editForm.get('password').value);

    setTimeout(() => {
      this.transactionService.transactionBash(formData).subscribe(res => {
          console.log('## transaction succeed:', res);
          this.file = undefined;
          this.serverHasError = false;
          this.responseFromServer(TIME_LONG_REQUEST);
          this.editForm.reset();
          this.successSave = true;
        },
        err => {
          console.log('## transaction failed:', err);
          this.responseFromServer(TIME_VERY_LONG_REQUEST);
          this.serverHasError = true;
          this.successSave = false;
        });
    }, 1500);
  }

  responseFromServer(time: number) {
    setTimeout(() => {
      this.isSaving = false;
    }, time);
  }

  onFileChange(event) {
    console.log('## onFileChange:', event);
    try {
      if (event.target.files.length > 0) {
        const f = event.target.files['0'];
        this.file = f;
      }
    }catch (e) {
      console.log('## error load file:', e);
    }

  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.editForm.reset();
  }

  canDeactivated() {
    return this.isInvalid() || !this.file || this.isSaving;
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }


}
