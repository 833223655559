import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Account } from '../../../../model/account.model';
import { Validators } from '../../../../model/champs_formulaire/validators.model';
import { Observable, Subject } from 'rxjs';
import { BaseService } from '../../base/base.service';
import { FormService } from '../../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { ValidatorService } from '../validators/validator.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantValidators } from '../validators/validator.url';
import { ConstantHome } from '../../home/home.url';
import { Url } from '../../../../shared/constant/url.constant';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ValidatorAddComponent } from '../validators/add/validator-add.component';
import { ValidatorUpdateComponent } from '../validators/update/validator-update.component';
import { ValidatorDeleteComponent } from '../validators/delete/validator-delete.component';
import { FormValidator } from '../../../../model/champs_formulaire/form-validator.model';
import { ValidatorAffectedService } from './validator-affected.service';
import { ConstantValidatorsAffected } from './validator-affected.url';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { ChampsFormulaire } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { ChampsFormulaireUpdateComponent } from '../champs_formulaires/update/champs-formulaire-update.component';
import { ValidatorAffectedUpdateComponent } from './update/validator-affected-update.component';
import { ValidatorAffectedDeleteComponent } from './delete/validator-affected-delete.component';
import { IServiceItem } from '../../../../model/service-item.model';
import { UntypedFormControl } from '@angular/forms';
import { ServiceItemService } from '../../service-items/service-item.service';
import { ConstantChampsFormulaires } from '../champs_formulaires/champs-formulaire.url';

@Component({
  selector: 'app-validator-affected',
  templateUrl: './validator-affected.component.html'
})
export class ValidatorAffectedComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  countriesList: FormValidator[];
  countriesSearch: FormValidator[];
  searchValue: string;

  serviceItemTyped = '';

  subs$ = new Subject<void>();

  serviceItemsList: IServiceItem[] = [];
  serviceItemSelected: IServiceItem;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<IServiceItem[]>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public baseService: BaseService,
    private serviceItemService: ServiceItemService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private validatorAffectedService: ValidatorAffectedService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct validator affected list');
    this.pageData = {
      title: ConstantValidatorsAffected.data.sub.list.title,
      key: 'validatorAffected.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantValidatorsAffected.data.title,
          key: 'validatorAffected.name',
          route: Url.SLASH_ROUTE.concat(ConstantValidatorsAffected.urlFull
            .FEES.LIST)
        },
        {
          title: ConstantValidatorsAffected.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'validatorAffected.title') {
      translate.get('validatorAffected.title').subscribe(res => this.pageData.title = res);
    }

    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'validatorAffected.name') {
        translate.get('validatorAffected.name').subscribe(res => {
          console.log('## res: ', res);
          value.title = res;
        });
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init validatorAffected list');
    super.ngOnInit();
    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
              const n = Object.keys(params).length;
              console.log('## params lg: ', params);
              console.log('## params n: ', n);
              this.account = account;
              if(n > 0) {
                const formId = params['champs_formulaire'];
                console.log('## val form: ', formId);
                if (formId) {
                  console.log('## get all formValidator by champs formulaire id');
                  let params = new HttpParams();
                  params = params.set('champsFormulaireId', formId);
                  this.validatorAffectedService.query(this.validatorAffectedService.resourceUrl,
                    params)
                    .subscribe(res => {
                      console.log('## Success get validator affected list by id ', res);
                      this.getTheData(res);
                    }, error => {
                      console.log('## error get validator by id ', error);
                      super.setLoaded();
                    });
                  this.getServiceItemList(); // after 500ms.
                } else {
                  super.setLoaded();
                }
              } else {
                console.log('## get all formValidator');
                this.validatorAffectedService.query(this.validatorAffectedService.list_url)
                  .subscribe(res => {
                    console.log('## Success get validator affected list');
                    this.getTheData(res);
                  }, error => {
                  console.log('## error get validator list ', error);
                  super.setLoaded();
                });
                this.getServiceItemList(); // after 500ms.
              }
            });
          this.getDataAsync();
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    console.log('## on destroy validator list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

  displayFn(subject: IServiceItem) {
    return subject ? subject.name : '';
  }

  getServiceItemList() {
    setTimeout(() => {
      this.serviceItemService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IServiceItem[]>) => mayBeOk.ok),
          map((response: HttpResponse<IServiceItem[]>) => response.body)
        )
        .subscribe((res: IServiceItem[]) => {
            console.log('## success get list service item');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.serviceItemsList = rs;
            this.filteredOptions = this.myControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list service item: ' + res.message);
          });
    }, 500);
  }

  private _filter(value: string): IServiceItem[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option.name.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  changeItem(event) {
    try {
      console.log('## select item');
      const si = event.option.value;
      console.log('## value item: ' + si.name);
      this.serviceItemSelected = si;
      this.searchServiceItem();
      this.router.navigate(['/' + ConstantValidatorsAffected.urlFull.FEES.LIST],
        { queryParams: { service: si.name } });
    } catch (e) {
      console.log('## error selected value s item');
    }
  }

  searchServiceItem(): void {
    const query = this.serviceItemSelected.name;
    const result = this.countriesSearch.filter(obj => {
      return obj.serviceItemName === query;
    });
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

/*  updateData(data: FormValidator[]) {
    data.forEach(d => {
      try {
        d.labelFormulaire = d.champsFormulaire.label;
        d.validatorType = d.validator.type;
      } catch (e) {
        console.log('## erreur: ', e);
      }
    });
  }*/

  getDataAsync() {
    this.validatorAffectedService.getObjetObs().pipe(takeUntil(this.subs$))
      .subscribe((res: FormValidator[]) => {
        console.log('## get list country in list by subject: ', res);
        this.countriesList = (res) ? res : [];
        this.countriesSearch = this.countriesList;
      });
  }

  getTheData(res: HttpResponse<FormValidator[]>) {
    this.countriesList = (res.body) ? res.body : [];
    // this.updateData(this.countriesList);
    this.countriesSearch = this.countriesList;
    super.setLoaded();
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.countriesSearch].map(mapFn);
    const result = this.countriesSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  update(c: FormValidator) {
    this.validatorAffectedService.setObjetSelectObs(c);
    this.baseService.open(this.dialog, ValidatorAffectedUpdateComponent);
  }

  delete(c: FormValidator) {
    this.validatorAffectedService.setObjetSelectObs(c);
    this.baseService.open(this.dialog, ValidatorAffectedDeleteComponent);
  }

  /*  add() {
      console.log('## open validator add');
      this.baseService.openSm(this.dialog, ValidatorAddComponent);
    }

    liste(c: Validators) {
      this.validatorAffectedService.setObjetSelectObs(c);
      this.baseService.openSm(this.dialog, ValidatorUpdateComponent);
    }

    delete(c: Validators) {
      this.validatorAffectedService.setObjetSelectObs(c);
      this.baseService.openSm(this.dialog, ValidatorDeleteComponent);
    }*/

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): FormValidator[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }
}