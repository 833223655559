import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, map } from 'rxjs/operators';
import { Account } from 'src/app/model/account.model';
import { Apikey } from 'src/app/model/api-key.model';
import { CUser, ICUser } from 'src/app/model/c-user.model';
import { IIpAdress, IpAdress } from 'src/app/model/ipAdress.model';
import { IMobileAccount } from 'src/app/model/mobile-account.model';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { CUserApiKeyService } from 'src/app/services/ServiceEntity/c-user-api-key.service';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { FormService } from 'src/app/shared/services/form.service';
import { CUserAddKeyColumnName, ICUserAddKeyColumnName } from '../../c-user-add-key-column';
import { CONTENT_BTN_CLASS, SUFFIX_ICON_FORM } from '../../../../../shared/constant/css-style/css-class.constant';
import { ApiKeyService } from '../../../api-key/api-key.service';

@Component({
  templateUrl: './c-user-add-key.component.html',
  styleUrls: ['./c-user-add-key.component.css']
})
export class CUserAddKeyComponent implements OnInit {


  mAccount: IMobileAccount;
  currentAccount: Account;

  theDialogRef: MatDialogRef<CUserAddKeyComponent>;

  thisUserId: number;
  thisUser: ICUser;
  marked: false;
  displayIpRestrictionInput: false;
  ipAdressS: IpAdress[];
  nbrIP: number;
  acountSelected: any;
  apikeyN: Apikey;
  column: ICUserAddKeyColumnName;
  apiKeyform: UntypedFormGroup;
  isSaving: boolean;
  successSave = false;
  erreurSave = false;
  trader: CUser;

  constructor(
    private apiKeyService: ApiKeyService,
    private dialogRef: MatDialogRef<CUserAddKeyComponent>,
    protected mAccountService: AccountUserService,
    protected cUserService: CUserService,
    protected accountService: AccountService,
    protected cUserApikeyService: CUserApiKeyService,
    private columnName: CUserAddKeyColumnName,
    private formService: FormService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: { trader: CUser, currentAccount: Account, thisUserId: number }
  ) {
    this.trader = data?.trader;
    this.theDialogRef = this.dialogRef;
    this.currentAccount = data?.currentAccount;
    this.thisUserId = data?.thisUserId;
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb, column);
    this.marked = false;
    this.displayIpRestrictionInput = false;
    this.nbrIP = 0;

  }

  ngOnInit(): void {
    this.isSaving = false;
    /*  this.cUserApikeyService.getAll().subscribe(res => {
       this.mAccounts = res.body;      
     }); */
    this.mAccountService.find(this.trader.id).subscribe((response) => {
      this.mAccount = response.body[0];
      console.log('this.mAccount+++++++++', this.mAccount);
    }

    )
  }

  getContentClass() {
    return CONTENT_BTN_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  initForm(fb: UntypedFormBuilder, column: ICUserAddKeyColumnName) {
    if (fb && column) {
      this.apiKeyform = this.fb.group({
        idAccount: ['', [Validators.required]],
        ipAuthorized: [''],
        label: ['', [Validators.required]],
        restrictionIp: ['']
      });
    }
  }

  add() {
    const row = document.createElement('div');
    row.className = 'row';
    row.innerHTML = `
        <br>
        <input type="text" class="form-control" name="Ip${this.nbrIP}" 
        formControlName="Ip${this.nbrIP}" placeholder="${this.nbrIP}" style="width=95%;">`;
    document.querySelector('#AuthorizedIp').appendChild(row);
    this.nbrIP++;
  }


  getNumberAccount(accountId) {
    console.log('accountId', accountId.value);
    //const id = Number(accountId);
    const id = accountId.value;
    //this.acountSelected = this.mAccount.find(acc => acc.id === id);
    this.mAccountService.find(this.trader.id).subscribe(response => {
      this.acountSelected = response.body[0];
      console.log('this.acountSelected.numberAccount', this.acountSelected.numberAccount);
    });
    //console.log('acountSelected',this.acountSelected);

  }

  isChecked(evt) {
    this.marked = evt.target.checked;
    this.displayIpRestrictionInput = this.marked;

  }
  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.apiKeyform);
  }
  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.apiKeyform,
      column);
  }
  isRequired(column: string): boolean {
    return this.formService.isRequired(this.apiKeyform, column);
  }

  /**
   * Création d'une api key.
   */
  createApikey() {
    this.isSaving = true;
    this.successSave = false;
    this.apikeyN = new Apikey(
      null,
      this.apiKeyform.get(['label']).value,
      null,
      this.thisUserId,
      this.apiKeyform.get(['idAccount']).value,
      null,
      this.displayIpRestrictionInput,
      this.apiKeyform.get(['ipAuthorized']).value,
      this.acountSelected.numberAccount,
      this.currentAccount.login,
      null
    );
    console.log('apikeyNNNNNNNNN', this.apikeyN);
    this.cUserApikeyService
      .create(this.apikeyN)
      .subscribe(res => {
        if(this.apiKeyform.get(['ipAuthorized']).value){
          const ipAdd = new IpAdress(null, res.body.id,
            this.apiKeyform.get(['ipAuthorized']).value, 'ACTIVED', this.currentAccount.login);
          this.cUserApikeyService
            .saveOneIpAdressForRestriction(ipAdd)
            .pipe(
              filter((re: HttpResponse<IIpAdress>) => res.ok),
              map((re: HttpResponse<IIpAdress>) => res.body)
            ).subscribe(res => {
              console.log(res);
              this.successSave = true;
              this.isSaving = false;
              this.apiKeyform.reset();
            }, err => {
              this.apiKeyform.reset();
              this.successSave = true;
              
              this.isSaving = false;
            });
          }
          this.successSave = true;
          this.isSaving = false;
          this.apiKeyform.reset()
       

      }, err => {
        console.log('## error create country');
        this.responseFromServer();        
        this.successSave = false;
        this.erreurSave = true;
      });

  }
  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300)
  }

  close() {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    console.log('## destroy money add');
    this.successSave = false;
    this.erreurSave= false;
  }

}
