import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import { ROUTES } from './routing/routing.module';
import { AppComponent } from './app.component';

import { pageDataReducer } from './store/reducers/page-data.reducer';
import { appSettingsReducer } from './store/reducers/app-settings.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DatePipe, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { JwtModule } from '@auth0/angular-jwt';
import { AccRessTypeModule } from './pages/entities/acc-ress-type/acc-ress-type.module';
import { SharedModule } from './shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CountriesModule } from './pages/entities/countries/countries.module';
import { MoniesModule } from './pages/entities/monies/monies.module';
import { ServiceItemModule } from './pages/entities/service-items/service-item.module';
import { FeesModule } from './pages/entities/fees/fees.module';
import { ExchangeRateModule } from './pages/entities/exchange-rate/exchange-rate.module';
import { AccRessListComponent } from './pages/entities/account-ressource/list/acc-ress-list.component';
import { ProfileUpdateComponent } from './pages/profile/update/profile-update.component';
import { PasswordUpdateComponent } from './pages/password/update/password-update.component';
import { PasswordStrengthBarComponent } from './pages/password/update/strength/password-strength-bar.component';
import { TransactionMultiComponent } from './pages/entities/transaction/multi/transaction-multi.component';
import { CuserDetailManageModule } from './pages/entities/cuser-detail-manage/cuser-detail-manage.module';
import { TOKEN } from './shared/constant/request.contant';
import { CuserModule } from './pages/entities/c-user/c-user.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GroupFormComponent } from './shared/layouts/group-form/group-form.component';
import { ResultTransactionMsgComponent } from './shared/layouts/result-transaction-msg/result-transaction-msg.component';
import { codeTransactionComponent } from './shared/layouts/code-transaction/code-transaction.component';
import { GroupFormNdialogComponent } from './shared/group-form-ndialog/group-form-ndialog.component';
import { PagesModule } from './pages/pages.module';
import { ApikeyTestComponent } from './pages/entities/api-key/apikey-test/apikey-test.component';
import { ValidatorModule } from './pages/entities/champs_formulaires/validators/validator.module';
import { ChampsFormulaireModule } from './pages/entities/champs_formulaires/champs_formulaires/champs-formulaire.module';
import { ValidatorAffectedModule } from './pages/entities/champs_formulaires/validator_affected/validator-affected.module';
import { TypeInputFormModule } from './pages/entities/champs_formulaires/type-input-form/type-input-form.module';
import { SourceFormModule } from './pages/entities/champs_formulaires/source-form/source-form.module';
import { TransfertCompteDynamicComponent } from './pages/entities/cash-dynamic/transfert-compte-dynamic/transfert-compte-dynamic.component';
import { VerificationCodeComponent } from './pages/entities/cash-dynamic/verification-code/verification-code.component';
import { RetraitCodeValidDynamicComponent } from './pages/entities/cash-dynamic/retrait-code-valid-dynamic/retrait-code-valid-dynamic.component';
import { RetraitCodePartielComponent } from './pages/entities/cash-dynamic/retrait-code-partiel/retrait-code-partiel.component';
import { RetraitCodeCompletComponent } from './pages/entities/cash-dynamic/retrait-code-complet/retrait-code-complet.component';
import { RetraitCodeInfosComponent } from './pages/entities/mobile-money/retrait-code/retrait-code-infos/retrait-code-infos.component';
import { DynamicFormComponent } from './pages/entities/cash/dynamic-form/dynamic-form.component';
import { DomainModule } from './pages/entities/domain/domain.module';
import { StatusModule } from './pages/entities/status/status.module';
import { EnvoiCodeInfosTransacComponent } from './pages/entities/cash/envoi-code-infos-transac/envoi-code-infos-transac.component';
import { TransfertCompteInfosComponent } from './pages/entities/cash/transfert-compte-infos/transfert-compte-infos.component';
import { RetraitCodePartielInfosComponent } from './pages/entities/cash/retrait-code-partiel-infos/retrait-code-partiel-infos.component';
import { RetraitCodeCompletInfosComponent } from './pages/entities/cash/retrait-code-complet-infos/retrait-code-complet-infos.component';
import { DepotOrangeMoneyInfosComponent } from './pages/entities/mobile-money/depot-orange-money-infos/depot-orange-money-infos.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { VersionExchangeRateModule } from './pages/entities/version-exch-rate/version-exchange-rate.module';
import { MatRadioModule } from '@angular/material/radio';
import { RetraitOrangeMoneyInfosComponent } from './pages/entities/mobile-money/retrait-orange-money-infos/retrait-orange-money-infos.component';
import { NotificationModule } from './pages/entities/notification/notification.module';
import { environment } from "../environments/environment";
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';
import { AngularFireModule } from '@angular/fire/compat';

import firebase from 'firebase/compat/app';
import initializeApp = firebase.initializeApp;
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ServiceItemComposeModule } from './pages/entities/service-items-composes/service-item.module';
import { FormulaireModule } from './pages/entities/formulaires/formulaire.module';
import { AccRessListByUserComponent } from './pages/entities/account-ressource/list-by-user/acc-ress-list-by-user.component';
import { CostsModule } from './pages/entities/costs/costs.module';
import { CostsDetailsModule } from './pages/entities/costs-details/costs-details.module';

// import initializeApp = firebase.initializeApp;
// import { AngularFireModule } from 'angularfire2';

initializeApp(environment.firebaseConfig);
registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent,
        RetraitOrangeMoneyInfosComponent,
        DepotOrangeMoneyInfosComponent,
        RetraitCodeCompletInfosComponent,
        RetraitCodePartielInfosComponent,
        TransfertCompteInfosComponent,
        EnvoiCodeInfosTransacComponent,
        ApikeyTestComponent,
        ProfileUpdateComponent,
        PasswordUpdateComponent,
        PasswordStrengthBarComponent,
        TransfertCompteDynamicComponent,
        AccRessListComponent,
        AccRessListByUserComponent,
        RetraitCodeInfosComponent,
        DynamicFormComponent,
        TransactionMultiComponent,
        VerificationCodeComponent,
        RetraitCodeValidDynamicComponent,
        RetraitCodeCompletComponent,
        RetraitCodePartielComponent,
        ResultTransactionMsgComponent,
        codeTransactionComponent,
        GroupFormNdialogComponent,
        //DemoNgZorroAntdModule
    ],
    imports: [
        //MatTabsModule,
        CostsDetailsModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES, { useHash: true, relativeLinkResolution: 'legacy' }),
        StoreModule.forRoot({
            pageData: pageDataReducer,
            appSettings: appSettingsReducer
        }),
        ServiceItemComposeModule,
        VersionExchangeRateModule,
        StatusModule,
        CostsModule,
        DomainModule,
        SourceFormModule,
        TypeInputFormModule,
        ValidatorAffectedModule,
        NotificationModule,
        FormulaireModule,
        ChampsFormulaireModule,
        ValidatorModule,
        AccRessTypeModule,
        CountriesModule,
        MoniesModule,
        ServiceItemModule,
        FeesModule,
        CuserModule,
        ExchangeRateModule,
        CuserDetailManageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        PagesModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        JwtModule.forRoot({
            config: {
                tokenGetter: function tokenGetter() {
                    return localStorage.getItem(TOKEN);
                },
                allowedDomains: ['localhost:8085', '192.168.1.23:8085',
                'api-sgate.almfinance.com'],
                throwNoTokenError: false
                //whitelistedDomains: ['localhost:3000'],
                //blacklistedRoutes: ['http://localhost:3000/auth/login']
            }
        }),
    ],
    exports: [SharedModule,
        TranslateModule, GroupFormComponent, ResultTransactionMsgComponent],
    providers: [
        DatePipe,
        MatRadioModule,
        MatDatepickerModule,
        { provide: NZ_I18N, useValue: en_US },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
