import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../services/form.service';
import {
  BEFORE_ICON_RESET,
  BEFORE_ICON_VALIDATION,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALID_CLASS,
  BTN_VALIDATION_CLASS,
  CANCEL_BG_COLOR,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS,
  CONTAINER_POPUP_CLASS,
  ICON_COLOR_REQUEST_CSS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET, REMOVABLE_ICON_REQUEST,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS, VALIDATION_BTN2_POPUP_CLASS,
  VALIDATION_BTN3_POPUP_CLASS,
  WARNING_COLOR_CSS,
  WIDTH_MEDIUM_POPUP_CLASS
} from '../../constant/css-style/css-class.constant';

/**
 * Réprésente le bouton d'annulation pour différents formulaire.
 */
@Component({
  selector: 'app-cancel-btn',
  templateUrl: './cancel-btn.component.html',
  animations: [fadeIn]
})
export class CancelBtnComponent implements OnInit {

  /* si l'évènement est généré, cela permettra soit de
  fermer un popup ou d'effectuer une autre action. */
  @Output('cancel') event = new EventEmitter<any>();

  @Input('dialogRef') dialogRef: MatDialogRef<any>
  @Input('textCancel') textCancel = 'Cancel';

  constructor(private formService: FormService) {
    console.log('## construct cancel btn');
  }

  ngOnInit(): void {
    console.log('## init cancel btn');
  }

  cancel() {
    if(this.dialogRef) {
      this.dialogRef.close();
    } else {
      return this.event.emit();
    }
  }

   isCancelBtnOutline(): boolean {
    return OUTLINE_CANCEL;
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

}
