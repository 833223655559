export const enum DisplayValue {
  ONE = 1
}

export interface IAccountRessType {
  id?: number;
  type?: string;
}

export class AccountRessType implements IAccountRessType {
  constructor(
    public id?: number,
    public type?: string
  ) {}
}
