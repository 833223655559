import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {
  transform(value: string, limit: number, ...args: any[]): any {
    let val = 'unknown';
    try {
      val = value.substr(0, limit);
      if(val.length >= limit) {
        val = val.concat('...');
      }
    }catch (e) {
      console.log('## error transform: ', e.message);
    }
    return val;
  }
}
