import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../../base-page';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import * as PageActions from '../../../../store/actions/page.actions';

@Component({
  selector: 'page-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss'],
})
export class PageFilterTableComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  tableData: any[];
  listOfName: any[];
  displayedData: any[];

  constructor(store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    this.listOfName = [
      {
        text: 'Airi',
        value: 'Airi Satou',
      },
      {
        text: 'Angelica',
        value: 'Angelica Ramos',
      },
      {
        text: 'Bradley',
        value: 'Bradley Greer',
      },
      {
        text: 'Caesar',
        value: 'Caesar Vance',
      },
    ];
    this.pageData = {
      title: 'Filtering tables',
      key: '',
      breadcrumbs: [
        {
          title: 'UI Kit',
          key: '',
          route: 'dashboard',
        },
        {
          title: 'Tables',
          key: '',
          route: 'dashboard',
        },
        {
          title: 'Filtering',
          key: '',
        },
      ],
    };
    this.tableData = [];
  }

  ngOnInit() {
    super.ngOnInit();

    this.getData('assets/data/table-sorting.json', 'tableData', 'initTable');
  }

  initTable(): void {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );

    setTimeout(() => {
      this.displayedData = [...this.tableData];
    });
  }

  filter(filterList: string[]): void {
    const filterFn = (item: { name: string }) =>
      filterList.length ? filterList.includes(item.name) : true;

    this.displayedData = this.tableData.filter(filterFn);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
