import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Code } from 'src/app/model/code.model';
import { Transaction } from 'src/app/model/transaction.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { PrintService } from '../../../../shared/services/print.service';
import { FormService } from '../../../../shared/services/form.service';
import { ColorService } from '../../../../shared/services/color.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  templateUrl: './show-transaction.component.html',
  styleUrls: ['./show-transaction.component.css'],
})
export class ShowTransactionComponent implements OnInit {
  transaction: Transaction;
  code: Code;
  docDefinition: any;

  constructor(
    private formService: FormService,
    private colorService: ColorService,
    private printService: PrintService,
    private dialogRef: MatDialogRef<ShowTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) data: { transaction: Transaction; code: Code }
  ) {
    this.transaction = data.transaction;
    this.code = data.code;
  }

  getTitleHeaderFormColor() {
    return this.colorService.getTitleHeaderFormColor();
  }

  getHeaderFormBg() {
    return this.colorService.getHeaderFormBg();
  }

  format(n: number) {
    return this.formService.format(n);
  }

  generatePdf() {
    const documentDefinition = {
      content: 'This is an sample PDF printed with pdfMake',
    };
    pdfMake.createPdf(this.printService.getDoc(this.transaction,
      this.docDefinition)).download();
  }

  openPdf() {
    const documentDefinition = {
      content: 'This is an sample PDF printed with pdfMake',
    };
    pdfMake.createPdf(this.printService.getDoc(this.transaction,
      this.docDefinition)).open();
  }

  printPdf() {
    const documentDefinition = {
      content: 'This is an sample PDF printed with pdfMake',
    };
    pdfMake.createPdf(this.printService.getDoc(this.transaction,
      this.docDefinition)).print();
  }

  

  ngOnInit(): void {
    console.log('transactionnnnn', this.transaction);
  }
}
