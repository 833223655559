import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { IOption } from '../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ChampsFormulaireColumnName,
  IChampsFormulaireColumnName
} from '../../champs_formulaires/champs_formulaires/champs-formulaire-column-name';
import { IServiceItem } from '../../../../model/service-item.model';
import { TypeInputFormModel } from '../../../../model/champs_formulaire/type-input-form.model';
import { ServiceItemService } from '../../service-items/service-item.service';
import { TypeInputFormService } from '../../champs_formulaires/type-input-form/type-input-form.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ChampsFormulaire, LocationType, SourceType, TypeForm } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { FormulaireColumnName, IFormulaireColumnName, IServiceFormulaireColumnName } from '../formulaire-column-name';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../../shared/constant/request.contant';
import { Formulaire } from '../../../../model/champs_formulaire/formulaire.model';
import { FormulaireService } from '../formulaire.service';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-formulaire-link',
  templateUrl: './formulaire-link.component.html',
  animations: [fadeIn]
})
export class FormulaireLinkComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<FormulaireLinkComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;
  column: IServiceFormulaireColumnName;

  // service item.
  serviceItemsList: IServiceItem[] = [];
  filteredOptions: Observable<IServiceItem[]>;

  formulairesList: Formulaire[] = [];
  filteredOptionsFormulaire: Observable<Formulaire[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceItemService: ServiceItemService,
    private typeInputFormService: TypeInputFormService,
    private formulaireService: FormulaireService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<FormulaireLinkComponent>,
    private columnName: FormulaireColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct formulaire link');
    const column = this.columnName.getColumnNameServiceFormulaire();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init formulaire link');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
      this.getServiceItemList(); // after 500ms.
      this.getFormulaireList(); // after 500ms.
   // this.getTypeInputFormList(); // after 500ms.

/*    if (this.serviceItemSelected) {
      setTimeout(() => {
        this.editForm.get(this.column.serviceItem)
          .setValue(this.serviceItemSelected);
      }, 200);
    }*/


  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  initForm(fb: UntypedFormBuilder, column: IServiceFormulaireColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.formsOrder]: ['', [Validators.required]],
        [column.serviceItemId]: ['', []],
        [column.formulaireId]: ['', []],
        [column.serviceItem]: ['', [Validators.required]],
        [column.formulaire]: ['', [Validators.required]]
      });
    }
  }

  displayFnFormulaire(subject: Formulaire) {
    return subject ? subject.titre : '';
  }

  displayFnServiceItem(subject: IServiceItem) {
    return subject ? subject.name : '';
  }

  getFormulaireList() {
    setTimeout(() => {
      // FORMULAIRE
      this.formulaireService
        .query(this.formulaireService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<IServiceItem[]>) => mayBeOk.ok),
          map((response: HttpResponse<IServiceItem[]>) => response.body)
        )
        .subscribe((res: Formulaire[]) => {
            console.log('## success get list formulaire: ', res);
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.formulairesList = rs;
            this.filteredOptionsFormulaire = this.editForm.get(this.column.formulaire)
              .valueChanges
              .pipe(
                startWith(''),
                map(value => this._filterFormulaire(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list formulaire while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  getServiceItemList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.serviceItemService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IServiceItem[]>) => mayBeOk.ok),
          map((response: HttpResponse<IServiceItem[]>) => response.body)
        )
        .subscribe((res: IServiceItem[]) => {
            console.log('## success get list service item while adding: ', res);
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.serviceItemsList = rs;
            this.filteredOptions = this.editForm.get(this.column.serviceItem).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list service item while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  private _filterFormulaire(value: string): Formulaire[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.formulairesList.filter(option => option?.titre?.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter formulaire: ' + e.message);
    }
    return filteredList;
  }

  private _filter(value: string): IServiceItem[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option?.name?.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;

    const c = this.createFromForm();
    console.log('## c: ', c);
    this.formulaireService.create(c, this.formulaireService.resourceServiceUrl)
      .subscribe(res => {
          console.log('## success create');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.formulaireService.getAllObjet();
          this.closeModal();
        },
        err => {
          console.log('## error create validator: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  closeModal() {
    console.log('## close formulaire');
    this.dialogRef.close();
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): any {
    return {
      formsOrder: this.editForm.get([this.column.formsOrder]).value,
      formulaireId: this.editForm.get([this.column.formulaire]).value?.id,
      serviceItemId: this.editForm.get([this.column.serviceItem])?.value?.id
    };
  }

}