import { Component, Input, OnInit } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormService } from '../../services/form.service';
import {
  BEFORE_ICON_RESET,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALIDATION_CLASS,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS,
  CONTAINER_POPUP_CLASS,
  FORM_CLASS, ICON_COLOR_REQUEST_CSS, ICON_FONT_REQUEST_CLASS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET, REMOVABLE_ICON_REQUEST, RESULT_REQUEST_ERROR_CLASS, RESULT_REQUEST_SUCCESS_CLASS,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS,
  SUFFIX_ICON_FORM, TITLE_POPUP_CLASS,
  WARNING_COLOR_CSS, WIDTH_MEDIUM_POPUP_CLASS
} from '../css-style/css-class.constant';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-error-input',
  templateUrl: './error-input.component.html',
  animations: [fadeIn]
})
export class ErrorInputComponent implements OnInit {

  // les informations sur le champs.
  @Input('control') control: AbstractControl;
  @Input('column') column: string;
  @Input('theFormGroup') theFormGroup: UntypedFormGroup;

  // indique si l'input est requis.
  @Input('required') required = false;
  @Input('errorMinLength') minlength = false;
  @Input('errorMaxLength') maxlength = false;

  @Input('errorMessage') errorMessage: string;
  @Input('errorMinLengthMessage') errorMinLengthMessage: string;
  @Input('errorMaxLengthMessage') errorMaxLengthMessage: string;
  @Input('errorEmailMessage') errorEmailMessage: string;

  constructor(private formService: FormService) {
    console.log('## construct header form')
  }

  ngOnInit(): void {
    console.log("## init header form");
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.theFormGroup, column);
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  isCancelBtnOutline(): boolean {
    return OUTLINE_CANCEL;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getContainerBtnClass() {
    return CONTAINER_BTN_POPUP_CLASS;
  }

  getFormClass() {
    return FORM_CLASS;
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  getResultRequestTextSuccess(): string {
    return RESULT_REQUEST_TEXT_SUCCESS_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getWarningColor(): string {
    return WARNING_COLOR_CSS;
  }

  getInside2xContainerClass(): string {
    return CONTAINER_INSIDE2X_POPUP_CLASS;
  }

  getInsideContainerClass(): string {
    return CONTAINER_INSIDE_POPUP_CLASS;
  }
  getContainerClass() : string {
    return CONTAINER_POPUP_CLASS;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getIconColorRequestClass(): string {
    return ICON_COLOR_REQUEST_CSS;
  }

  isRemovable(): boolean {
    return REMOVABLE_ICON_REQUEST;
  }

  getTitleClass(): string {
    return TITLE_POPUP_CLASS;
  }

  getIconRequestClass(): string {
    return ICON_FONT_REQUEST_CLASS;
  }

  getResultRequestErrorClass() {
    return RESULT_REQUEST_ERROR_CLASS;
  }

  getResultRequestSuccessClass() {
    return RESULT_REQUEST_SUCCESS_CLASS;
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.theFormGroup, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.theFormGroup,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.theFormGroup, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.theFormGroup, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.theFormGroup, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.theFormGroup, column);
  }

}
