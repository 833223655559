import { NgModule } from '@angular/core';
import { PagesModule } from './pages/pages.module';
import { UIModule } from './ui/ui.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { MaterialModule } from './material.module';
import { ShortenPipe } from './pipe/shorten.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSelectModule} from '@angular/material/select';

/**
 * Ensemble des modules qui seront partagés avec
 * d'autres modules.
 */
@NgModule({
  imports: [MaterialModule, PagesModule],
  exports: [MaterialModule, PagesModule],
  declarations: []
})
export class SharedModule {
}
