import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import {
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS
} from '../../constant/css-style/css-class.constant';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-result-transaction-msg',
  templateUrl: './result-transaction-msg.component.html',
  animations: [fadeIn]
})
export class ResultTransactionMsgComponent implements OnInit {

  // indique si la transaction a réussi
  @Input('transactionSucceed') success = false;
  // indique si la transaction a échoué.
  @Input('transactionFailed') failed = false;

  // message lorsque la transaction a réussi.
  @Input('messageSucceed') msgSucceed;
  // json key pour un message de succès.
  @Input('keyTranslateMsgSucceed') keyTranslateMsgSucceed = 'actionSucceed';

  // message lorsque la transaction a échoué.
  @Input('messageFailed') msgFailed;
  // json key pour message d'échec.
  @Input('keyTranslateMsgFailed') keyTranslateMsgFailed = 'actionFailed';

  constructor(private translateService: TranslateService) {
    console.log('## construct result transaction')
    this.msgSucceed = this.translateService.instant(this.keyTranslateMsgSucceed)
    this.msgFailed = this.translateService.instant(this.keyTranslateMsgFailed)
    console.log('## message succeed: ', this.msgSucceed);
  }

  ngOnInit(): void {
    console.log('## init result transaction');
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  getResultRequestTextSuccess(): string {
    return RESULT_REQUEST_TEXT_SUCCESS_CLASS;
  }

}
