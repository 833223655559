import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountConfirmDTO, INotificationDTO, TransferCompteConfirmDTO } from '../../../../model/notification.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { NotificationService } from '../notification.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { ColorService } from '../../../../shared/services/color.service';
import { TIME_LONG_REQUEST, TIME_MEDIUM_REQUEST } from '../../../../shared/constant/request.contant';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-reject-transaction',
  templateUrl: './reject-transaction.component.html',
  animations: [fadeIn]
})
export class RejectTransactionComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<RejectTransactionComponent>;

  editForm: UntypedFormGroup;
  notificationDTO: INotificationDTO;

  private subs$ = new Subject<void>();


  constructor(
    public baseService: BaseService,
    private colorService: ColorService,
    private accountService: AccountService,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RejectTransactionComponent>,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct reject transaction');
    this.editForm = this.fb.group({});
    this.theDialogRef = this.dialogRef;
    this.notificationDTO = this.data.notification;
  }

  ngOnInit(): void {
    console.log('## init reject transaction');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## account: ', account);
      });
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy reject transaction');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  close() {
    this.dialogRef.close();
  }

  format(n: number) {
    return this.formService.format(n);
  }

  getStatusBg(id: number): string {
    return this.colorService.getStatusBg(id);
  }

  reject() {
    console.log('## reject');
      console.log('## saving ');
      this.isSaving = true;
      this.successSave = false;
      const t = new TransferCompteConfirmDTO();
      t.correlationId = this.notificationDTO.correlationId;
      t.response = false;
      console.log("## tr: ", t);

      this.notificationService.confirmTransaction(t)
        .subscribe(res => {
          console.log('## success reject transaction ');
          this.successSave = true;
          setTimeout(res => {
            this.close();
            this.isSaving = false;
          }, TIME_MEDIUM_REQUEST);
          this.notificationService.getAllObjet();
        }, error => {
          console.log('## error reject transaction ');
          this.isSaving = false;
          this.successSave = false;
        });
  }
}