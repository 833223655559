import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared.module';
import { CUserAddKeyComponent } from './add/c-user-add-key/c-user-add-key.component';
import { CUserComponent } from './c-user.component';
import { CuserAddComponent } from './add-user/cuser-add.component';
import { CuserUpdateComponent } from './update-user/cuser-update.component';
import { UserDeleteComponent } from './delete/user-delete.component';
import { ApiKeyAddComponent } from '../api-key/api-key-add/api-key-add-component';
import { ApiKeyUpdateComponent } from '../api-key/update/api-key-update.component';
import { ApiKeyListIpAdressComponent } from '../api-key/list-ip-adress/api-key-list-ip-adress.component';
import { ApiKeyDeleteComponent } from '../api-key/delete/api-key-delete.component';
import { IpAdressAddComponent } from '../ip-adress/add/ip-adress-add.component';
import { IpAdressUpdateComponent } from '../ip-adress/update/ip-adress-update.component';
import { IpAdressDeleteComponent } from '../ip-adress/delete/ip-adress-delete.component';
import { ApiKeyServiceComponent } from '../api-key/service-item/api-key-service.component';
import { AccountApiKeyComponent } from '../account-api-key/account-api-key.component';
import { TransactionApikeyComponent } from '../api-key/transaction-api-key/transaction-apikey.component';
import { ActivateServiceComponent } from './activate-service/activate-service.component';

@NgModule({
    imports: [SharedModule],
    declarations: [ApiKeyDeleteComponent, ActivateServiceComponent,
        IpAdressAddComponent,
        IpAdressDeleteComponent, ApiKeyServiceComponent,
        UserDeleteComponent, CUserComponent, IpAdressUpdateComponent,
        CUserAddKeyComponent, ApiKeyListIpAdressComponent,
        CuserAddComponent, ApiKeyUpdateComponent,
        AccountApiKeyComponent, TransactionApikeyComponent,
        CuserUpdateComponent, ApiKeyAddComponent]
})
export class CuserModule {
}
