import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../animations/form-error';
import { BasePageComponent } from '../../../../base-page';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IOption } from '../../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryColumnName, ICountryColumnName } from '../../../countries/country-column-name';
import { AccountService } from '../../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../../shared/services/form.service';
import { MoniesService } from '../../../monies/monies.service';
import { TCModalService } from '../../../../../ui/services/modal/modal.service';
import { CountryService } from '../../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { HttpService } from '../../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../../shared/constant/css-style/css-class.constant';
import { Country, ICountry } from '../../../../../model/country.model';
import { IValidatorColumnName, ValidatorColumnName } from '../validator-column-name';
import { BaseService } from '../../../base/base.service';
import { Validators as validatorsModel } from '../../../../../model/champs_formulaire/validators.model';
import { ValidatorService } from '../validator.service';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-validator-add',
  templateUrl: './validator-add.component.html',
  animations: [fadeIn]
})
export class ValidatorAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ValidatorAddComponent>;
  private subs$ = new Subject<void>();
  monies: IOption[] = [];
  editForm: UntypedFormGroup;
  column: IValidatorColumnName;
  anotherMoney: boolean;

  constructor(
    private validatorService: ValidatorService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ValidatorAddComponent>,
    private columnName: ValidatorColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct validator add');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init validator add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }


  initForm(fb: UntypedFormBuilder, column: IValidatorColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.id]: [],
        [column.type]: [
          '',
          [Validators.required]
        ]
      })
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save validator');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.validatorService.create(c, this.validatorService.resourceUrl).subscribe(res => {
        console.log('## success create validator');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.validatorService.getAllObjet();
      },
      err => {
        console.log('## error create validator');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): validatorsModel {
    return {
      ...new validatorsModel(),
      id: this.editForm.get([this.column.id]).value,
      type: this.editForm.get([this.column.type]).value
    };
  }

  closeModal() {
    console.log('## close validator add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}
