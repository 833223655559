import { RouterModule, Routes } from '@angular/router';
import { VerticalLayoutComponent } from '../../../layout/vertical';
import { GuardAuthService } from '../../../services/guard/auth/guard-auth.service';
import { HorizontalLayoutComponent } from '../../../layout/horizontal';
import { PublicLayoutComponent } from '../../../layout/public';
import { ErrorLayoutComponent } from '../../../layout/error';
import { AccountRessTypeService } from './account-ress-type.service';
import { AccountsRessourceTypeComponent } from './list/acc-ress-type-list.component';
import { NgModule } from '@angular/core';
import { ConstantAccRessType } from './acc-ress-type.url';
import { AccountsRessourceTypeAddComponent } from './add/acc-ress-type-add.component';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';

/**
 * Ensemble des routes pour les types de account ressource.
 */
export const ACC_RESS_TYPE_ROUTE: Routes = [
  {
    path: ConstantAccRessType.url.ACCOUNT_RESSOURCE_TYPE.ACCOUNT_RESSOURCE_TYPE_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantAccRessType.url.ACCOUNT_RESSOURCE_TYPE.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantAccRessType.url.ACCOUNT_RESSOURCE_TYPE.LIST,
        component: AccountsRessourceTypeComponent
      }
    ]
  }
];
