import { Component, Input } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { BasePageComponent } from '../../../pages/base-page';

/**
 * Permet à une personne quelconque d'envoyer de l'argent
 * à une autre personne.
 */
@Component({
  selector: 'app-mat-spinner-medium',
  templateUrl: './mat-spinner-medium.component.html',
  animations: [fadeIn]
})
export class MatSpinnerMediumComponent {

  @Input('init') init = true; // si false, alors le spinner est affiché.
}