import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ICUser } from 'src/app/model/c-user.model';
import { ITransaction } from 'src/app/model/transaction.model';
import { DATE_FORMAT } from 'src/app/shared/constant/input.constants';
import { createRequestOption } from 'src/app/util/request-util';
import { BASE_URL, BASE_URL_DYNAMIQ, BASE_URL_PORT } from 'src/environments/environment';
import { IReclamation } from 'src/app/model/reclamation.model';
import { TransactionInfos } from '../../model/transaction/transaction-infos.model';


type EntityResponseType = HttpResponse<ITransaction>;
type EntityArrayResponseType = HttpResponse<ITransaction[]>;

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  public resourceUrl = BASE_URL + 'transactions';
  public resourceTransApikeyUrl = BASE_URL_PORT + 'V2/apk/access/transaction/';

  public resourceUrl2 = BASE_URL + 'transactions/account';
  public resourceUrl4 = BASE_URL + 'transaction/Roolback';
  public reclamation = BASE_URL + 'reclamation';
  public reclamationDemande = BASE_URL + 'reclamation/existe';
  public reclamationDemandeA = BASE_URL + 'reclamation/existeA';
  public currencyUrl = BASE_URL + 'transactions/withdrawalCode/currency'


  private transactions = new Subject<ITransaction[]>();

  // le mAccount sélectionné dans la liste.
  private transactionsSelect = new BehaviorSubject<ITransaction>(undefined);
 
  constructor(protected http: HttpClient) {
  }

  affectDefaultValue(t: TransactionInfos) {
    if(t) {
      t.fees = t.fees ? t.fees : 0.00;
      t.currencyReceive = t.currencyReceive ? t.currencyReceive : '';
      t.labelCountryReceiver = t.labelCountryReceiver ? t.labelCountryReceiver : ' ';
    }
  }

  getTransactionsSelectObs() : Observable<ITransaction>{
    return this.transactionsSelect.asObservable();
  }

  setTransactionsSelectObs(c: ITransaction) {
    this.transactionsSelect.next(c);
  }

  getTransactionsObs() {
    return this.transactions.asObservable();
  }

  setTransactionsObs(c: ITransaction[]) {
    this.transactions.next(c);
  }


   /**
   * Permet de récupérer la liste des transactions
   * pour être partagée.
   */
    getAllTransactions(accountNumber: string) {    
      setTimeout(() => {
        this.findAllTransactionsByaccountNumber(accountNumber).subscribe(res => {       
        this.setTransactionsObs((res.body) ? res.body : []);      
        });
      }, 300)
    }

  transactionBash(transactionExp: any): Observable<EntityResponseType> {
    return this.http.post<any>(this.resourceUrl + '/transactionBash', transactionExp, { observe: 'response' });
  }

  getCurrency(param: any): Observable<HttpResponse<string>> {
    return this.http.get(this.currencyUrl, { params: param, observe: 'response',
      responseType: 'text'});
  }

  withdrawalv2(transactionExp: any): Observable<EntityResponseType> {
    return this.http.post<ICUser>(BASE_URL_DYNAMIQ, transactionExp, { observe: 'response' });
  }

  withdrawal(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrl + '/withdrawal', transactionExp, { observe: 'response' });
  }

  deposit(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrl + '/deposit', transactionExp, { observe: 'response' });
  }

  transferCodeApikey(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceTransApikeyUrl,
      transactionExp, { observe: 'response' });
  }

  transferCode(transactionExp: any): Observable<EntityResponseType> {
    return this.http.post<ICUser>(BASE_URL_DYNAMIQ,
      transactionExp, { observe: 'response' });
  } 

  /*  test callApi START */

  transferCodeBis(transactionExp: ITransaction){
    return this.http.post<any>(BASE_URL+'execute',transactionExp,{ observe: 'response' }).subscribe(resp =>console.log('response execute##', resp));
    

  }
   /*  test callApi END */
  execute(data: any): Observable<HttpResponse<any>> {
    return this.http.post(BASE_URL_DYNAMIQ, data,
      {observe: 'response' });
  }

  getTransactionInfosConfirm(data: any): Observable<HttpResponse<TransactionInfos>> {
    return this.http.post(BASE_URL + 'transactions/confirm', data,
      {observe: 'response' });
  }

  getTransactionInfos(data: any): Observable<HttpResponse<TransactionInfos>> {
    return this.http.post(BASE_URL_DYNAMIQ, data,
      {observe: 'response' });
  }

  transferCodeInter(transactionExp: any): Observable<EntityResponseType> {
    return this.http.post<ICUser>(BASE_URL_DYNAMIQ, transactionExp, { observe: 'response' });
  }

  withdrawalCode(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrl + '/withdrawalCode', transactionExp, { observe: 'response' });
  }

    DemandeRoolback(reclamation: IReclamation): Observable<EntityResponseType> {
     return this.http.post<ICUser>(this.reclamation + '/newReclamation', reclamation, { observe: 'response' });
   }
   takeToInProgress(reclamation: IReclamation): Observable<EntityResponseType> {
     return this.http.post<ICUser>(this.reclamation + '/InProgressReclamation', reclamation, { observe: 'response' });
   }
   FinishReclamation(reclamation: IReclamation): Observable<EntityResponseType> {
     return this.http.post<ICUser>(this.reclamation + '/Done', reclamation, { observe: 'response' });
   } 

  IfDemandeExiste(idTransacton: number): Observable<EntityResponseType> {
    return this.http.get(`${this.reclamationDemande}/${idTransacton}`, { observe: 'response' });
    //return this.http.post<IReclamation>(this.reclamation + '/existe', idTransacton, { observe: 'response' });
  }

  IfDemandeExisteA(idTransacton: number): Observable<EntityResponseType> {
    return this.http.get(`${this.reclamationDemandeA}/${idTransacton}`, { observe: 'response' });
    //return this.http.post<IReclamation>(this.reclamation + '/existe', idTransacton, { observe: 'response' });
  }

  roolbackSuccessToPendding(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrl4 + '/SuccessToPendding', transactionExp, { observe: 'response' });
  }

  roolbackFailledToPendding(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrl4 + '/FailledToPendding', transactionExp, { observe: 'response' });
  }

  roolbackPenddingToFailled(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrl4 + '/PenddingToFailled', transactionExp, { observe: 'response' });
  }

  roolbackPenddingToSuccess(transactionExp: ITransaction): Observable<EntityResponseType> {
    return this.http.post<ICUser>(this.resourceUrl4 + '/PenddingToSuccess', transactionExp, { observe: 'response' });
  }

  create(transaction: ITransaction): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(transaction);
    return this.http
      .post<ITransaction>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(transaction: ITransaction): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(transaction);
    return this.http
      .put<ITransaction>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ITransaction>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findAllTransactionsByaccountNumber(accountNumber: string, req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ITransaction[]>(`${this.resourceUrl2}/${accountNumber}`, {
      params: options,
      observe: 'response'
    });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ITransaction[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(transaction: ITransaction): ITransaction {
    const copy: ITransaction = Object.assign({}, transaction, {
      createdDate: transaction.createdDate != null && transaction.createdDate.isValid() ? transaction.createdDate.format(DATE_FORMAT) : null
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate != null ? moment(res.body.createdDate) : null;


    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((transaction: ITransaction) => {
        transaction.createdDate = transaction.createdDate != null ? moment(transaction.createdDate) : null;
      });
    }
    return res;
  }

}
