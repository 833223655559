export interface ICostsRule {
  id?: number;
  amountA?: number;
  amountB?: number;
  amountBbis?: number;
  feeCosts?: number;
  montant?: number;
  typeDetailsLabel?: string;
  typeDetailsId?: number;
  taxe?: number;
  benefit?: number;
  feeCostsBis?: number;
  totalCost?: number;
  costsId?: number;
  moneyId?: number;
  countryAId?: number;
  countryLabelA?: string;
  countryBId?: number;
  countryLabelB?: string;
  serviceItemsId?: number;
  serviceItemsName?: string;
  cUserId?: number;
  accountUserId?: number;
}

export class CostsRule implements ICostsRule {
  constructor(
    public id?: number,
    public amountA?: number,
    public amountB?: number,
    public amountBbis?: number,
    public feeCosts?: number,
    public montant?: number,
    public typeDetailsLabel?: string,
    public typeDetailsId?: number,
    public taxe?: number,
    public benefit?: number,
    public feeCostsBis?: number,
    public totalCost?: number,
    public costsId?: number,
    public moneyId?: number,
    public countryAId?: number,
    public countryLabelA?: string,
    public countryBId?: number,
    public countryLabelB?: string,
    public serviceItemsId?: number,
    public serviceItemsName?: string,
    public cUserId?: number,
    public accountUserId?: number
  ) {
  }
}
  