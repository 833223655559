import { Injectable } from '@angular/core';
import { BodyFormat, IServiceItem, RequestType } from '../../../model/service-item.model';

export interface IServiceItemColumnName {
  id: string;
  name: string;
  image: string;
  description: string;
  accountRessTypeId: string;
  accountRessTypeName: string;
  serviceItemId: string;
  bodyFormat: string;
  requestType: string;
  url: string;
  type: string;
  bodyType: string;
  bodyContent: string;
  formulaire: string;
  formsOrder: string;
}

export interface IRequestType {
  post: string;
  get: string;
  put: string;
  delete: string;
}

export interface IBodyFormat {
  json: string;
  text: string;
  html: string;
  javascript: string;
  xml: string;
}

@Injectable({ providedIn: 'root' })
export class ServiceItemColumnName {
  getRequestType(): IRequestType {
    return {
      post: RequestType.POST,
      get: RequestType.GET,
      put: RequestType.PUT,
      delete: RequestType.DELETE
    };
  }

  getBodyFormat(): IBodyFormat {
    return {
      json: BodyFormat.JSON,
      text: BodyFormat.TEXT,
      html: BodyFormat.HTML,
      javascript: BodyFormat.JAVASCRIPT,
      xml: BodyFormat.XML
    };
  }

  getColumnName(): IServiceItemColumnName {
    return {
      id: 'id',
      name: 'name',
      image: 'image',
      description: 'description',
      accountRessTypeId: 'accountRessTypeId',
      accountRessTypeName: 'accountRessTypeName',
      serviceItemId: 'serviceItemId',
      bodyFormat: 'body_format',
      requestType: 'request_type',
      url: 'url',
      type: 'type',
      bodyType: 'body_type',
      bodyContent: 'body_content',
      formulaire: 'formulaire',
      formsOrder: 'formsOrder'
    };
  }
}
