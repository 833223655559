import { Routes } from '@angular/router';
import { ConstantExchangeRate } from '../exchange-rate/exchange-rate.url';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';
import { ExchangeRateComponent } from '../exchange-rate/exchange-rate.component';
import { ExchangeRateHistoricalComponent } from '../exchange-rate/historical/exchange-rate-historical.component';
import { ExchangeRateLiveComponent } from '../exchange-rate/live/exchange-rate-live.component';
import { ConstantVersionExchangeRate } from './version-exchange-rate.url';
import { VersionExchangeRateComponent } from './version-exchange-rate.component';

/**
 * Ensemble des routes pour les version exchange rate.
 */
export const VERSION_EXCHANGE_RATE_ROUTE: Routes = [
  {
    path: ConstantVersionExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantVersionExchangeRate.url.EXCHANGE_RATE.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantVersionExchangeRate.url.EXCHANGE_RATE.LIST,
        component: VersionExchangeRateComponent
      },
      {
        path: ConstantVersionExchangeRate.url.EXCHANGE_RATE.HISTORICAL,
        component: ExchangeRateHistoricalComponent
      },
      {
        path: ConstantVersionExchangeRate.url.EXCHANGE_RATE.LIVE,
        component: ExchangeRateLiveComponent
      }
    ]
  }
];
