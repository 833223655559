import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../animations/form-error';
import { BasePageComponent } from '../../../../base-page';
import { IOption } from '../../../../../ui/interfaces/option';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChampsFormulaireColumnName, IChampsFormulaireColumnName } from '../../champs_formulaires/champs-formulaire-column-name';
import { ChampsFormulaire, TypeForm } from '../../../../../model/champs_formulaire/champs-formulaire.model';
import { Observable, Subject } from 'rxjs';
import { IServiceItem } from '../../../../../model/service-item.model';
import { BaseService } from '../../../base/base.service';
import { ServiceItemService } from '../../../service-items/service-item.service';
import { AccountService } from '../../../../../services/ServiceEntity/account.service';
import { MoniesService } from '../../../monies/monies.service';
import { FormService } from '../../../../../shared/services/form.service';
import { TCModalService } from '../../../../../ui/services/modal/modal.service';
import { ChampsFormulaireService } from '../../champs_formulaires/champs-formulaire.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { HttpService } from '../../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../../../shared/constant/request.contant';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../../shared/constant/css-style/css-class.constant';
import { ITypeInputFormColumnName, TypeInputFormColumnName } from '../type-input-form-column-name';
import { TypeInputFormModel } from '../../../../../model/champs_formulaire/type-input-form.model';
import { IValidatorColumnName, ValidatorColumnName } from '../../validators/validator-column-name';
import { Validators as ValidatorsModel } from '../../../../../model/champs_formulaire/validators.model';
import { ValidatorService } from '../../validators/validator.service';
import { TypeInputFormService } from '../type-input-form.service';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-type-input-form-liste',
  templateUrl: './type-input-form-update.component.html',
  animations: [fadeIn]
})
export class TypeInputFormUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<TypeInputFormUpdateComponent>;
  private subs$ = new Subject<void>();
  monies: IOption[] = [];
  editForm: UntypedFormGroup;
  column: ITypeInputFormColumnName;
  anotherMoney: boolean;

  private countrySelected: TypeInputFormModel;

  constructor(
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<TypeInputFormUpdateComponent>,
    private columnName: TypeInputFormColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private validatorService: TypeInputFormService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct type_input_form liste');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init type_input_form liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
        this.validatorService.getObjetSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: TypeInputFormModel) => {
          console.log('## get type_input_form selected to liste');
          try {
            console.log('## data: ', res);
            this.countrySelected = res;
            this.editForm.patchValue({
              [this.column.id]: res.id,
              [this.column.type]: res.type,
              [this.column.description]: res.description,
            })
          }catch (e) {
            console.log('## error get type_input_form selected: ' + e.message);
          }
        });
      });
  }

  ngOnDestroy() {
    console.log('## destroy type_input_form liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }


  initForm(fb: UntypedFormBuilder, column: ITypeInputFormColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.id]: [],
        [column.type]: [
          '',
          [Validators.required]
        ],
        [column.description]: ['']
      })
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## liste type_input_form');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.validatorService.update(c, this.validatorService.resourceUrl).subscribe(res => {
        console.log('## success create type_input_form');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.successSave = true;
        this.validatorService.getAllObjet();
      },
      err => {
        console.log('## error create type_input_form');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): TypeInputFormModel {
    return {
      ...new TypeInputFormModel(),
      id: this.editForm.get([this.column.id]).value,
      type: this.editForm.get([this.column.type]).value,
      description: this.editForm.get([this.column.description]).value
    };
  }

  closeModal() {
    console.log('## close validator liste');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }
}