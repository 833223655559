import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BaseValidationService } from '../../cash/services/base-validation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TransactionsService } from '../../../../services/ServiceEntity/transactions.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { CONTENT_BTN_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import {
  SERVICE_ITEM_ID_DEPOT_ORANGE_MONEY,
  SERVICE_ITEM_ID_INFOS_DEPOT_ORANGE_MONEY,
  SERVICE_ITEM_ID_INFOS_TRANSACTION_TRANSFER_COMPTE,
  SERVICE_ITEM_ID_TRANSFER_COMPTE
} from '../../../../shared/constant/service-item.constant';
import { TransactionInfosTrCompte } from '../../../../model/transaction/transaction-infos-tr-compte.model';
import { TransactionInfosDtom } from '../../../../model/transaction/transaction-infos-dtom.model';
import { ICashModel } from '../../cash/cash-data-model';

/**
 * Permet d'afficher les informations de la transaction
 * avant d'effectuer un dépôt orange money.
 */
@Component({
  selector: 'app-depot-mobile-money-infos',
  templateUrl: './depot-orange-money-infos.component.html',
  animations: [fadeIn]
})
export class DepotOrangeMoneyInfosComponent extends BaseValidationService
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  loadingMessagesForm = false;
  theDialogRef: MatDialogRef<DepotOrangeMoneyInfosComponent>;
  private subs$ = new Subject<void>();

  transactionInfos: TransactionInfosDtom;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICashModel,
    private transactionsService: TransactionsService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<DepotOrangeMoneyInfosComponent>,
    protected modal: TCModalService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(modal);
    console.log('## construct dépôt orange money infos');
    this.theDialogRef = this.dialogRef;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    let p = new FormData();
    p.set('serviceItemId', this.tostring(SERVICE_ITEM_ID_INFOS_DEPOT_ORANGE_MONEY));
    p.set('sentAmount', this.tostring(this.data.cashDataModel.dataForm.data.get('sentAmount')));
    p.set('serviceId', this.tostring(SERVICE_ITEM_ID_DEPOT_ORANGE_MONEY));
    this.transactionsService.execute(p).subscribe(res => {
      let data = res.body;
      console.log('## success récupération infos transaction: ', data);
      this.transactionInfos = data;
      this.data.cashDataModel.transactionInfosDtom = data;
    }, err => {
      console.log('## Erreur récupération infos transaction: ', err);
    })
  }

  getAmountReceive() {
    const amount: number = +this.data.cashDataModel.dataForm.data.get('sentAmount');
    return this.format((amount - this.transactionInfos?.fees));
  }

  getInit() {
    if(!this.transactionInfos) {
      return false;
    }
    return true;
  }

  tostring(s: any) {
    return s + '';
  }

  format(n: any) {
    console.log('## type amount: ', typeof (+n));
    return this.formService.format(+n);
  }

  confirm() {
    console.log('## confirm dépôt orange money');
    this.data.cashDataModel.dataForm.loadingMessagesForm = true;
    this.loadingMessagesForm = true;
    const d = this.data.cashDataModel.dataForm;
    // Vérifie si le transfert est national.
    if (d.curentUserCuser.countryId === d.countryDestID) {
      console.log('transfert NATIONAL');
      d.data.set('serviceItemId', '' + this.data.cashDataModel.dataCode.serviceItemId);
      this.transactionsService.transferCode(d.data)
        .subscribe((rep) => {
          console.log('### SUCCESS Transfert Compte', rep);
          this.dialogRef.close();
          this.loadingMessagesForm = false;
          this.manageMessageWhenSucceed(rep, d);
          const body = rep.body;
          if (body && body.valueCode) {
            d.reponseTransfert = body;
            this.showDetailsEnvoieCode(d.forModal,
              'Transaction details', null,
              {
                closeButton: true,
                overlayClose: false
              }); // after 700ms
          }
        }, err => {
          console.log('ERROR While TRANS', err);
          this.loadingMessagesForm = false;
          this.manageMessageWhenError(d);
        });
    } else {//transfert International api/transactions/transferCodeInter
      console.log('transfert INTERNATIONAL');
      console.log('CUSER CURRENT', d.curentUserCuser.countryId);
      this.loadingMessagesForm = true;
      // this.transfertCode.value.serviceItemId = 103;
      d.data.set('serviceItemId', '' + this.data.cashDataModel.dataCode.serviceItemIdInter);
      console.log('### POST data:', d.data);
      this.transactionsService.transferCodeInter(d.data)
        .subscribe((rep) => {
          console.log('### SUCCESS Transfert Compte', rep);
          this.dialogRef.close();
          this.loadingMessagesForm = false;
          this.manageMessageWhenSucceed(rep, d);
          const body = rep.body;
          if (body && body.valueCode) {
            d.reponseTransfert = body;
            this.showDetailsEnvoieCode(d.forModal,
              'Transaction details', null,
              {
                closeButton: true,
                overlayClose: false
              }); // after 700ms
          }
        }, err => {
          console.log('ERROR While TRANS', err);
          this.loadingMessagesForm = false;
          this.manageMessageWhenError(d);
        });
    }
  }

  getContentClass() {
    return CONTENT_BTN_CLASS;
  }

}