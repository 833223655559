import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'upperOneLetter' })
export class UpperOneLetterPipe implements PipeTransform {
  constructor() {
  }

  /**
   * Met la première lettre d'un mot en majuscule.
   * @param value le mot.
   */
  transform(value:string): string {
    console.log('## the value: ', value);
    value = value.toLowerCase();
    let first = value.substr(0,1).toUpperCase();
    return first + value.substr(1);
  }
}
