import { Injectable } from '@angular/core';

export interface IServiceFormulaireColumnName {
  id: string;
  formsOrder: string;
  serviceItemId: string;
  formulaireId: string;
  serviceItem: string;
  formulaire: string;
}

export interface IFormulaireColumnName {
  id: string;
  titre: string;
  description: string;
  serviceItem: string;
}

@Injectable({ providedIn: 'root' })
export class FormulaireColumnName {
  getColumnName(): IFormulaireColumnName {
    return {
      id: 'id',
      titre: 'titre',
      description: 'description',
      serviceItem: 'serviceItem'
    };
  }

  getColumnNameServiceFormulaire(): IServiceFormulaireColumnName {
    return {
      id: 'id',
      formsOrder: 'formsOrder',
      serviceItemId: 'serviceItemId',
      formulaireId: 'formulaireId',
      serviceItem: 'serviceItem',
      formulaire: 'formulaire'
    };
  }
}
