import { Routes } from '@angular/router';
import { ConstantMonies } from '../monies/monies.url';
import { VerticalLayoutComponent } from '../../../layout/vertical';
import { MoniesComponent } from '../monies';
import { ConstantFeesBenefits } from './fees.url';
import { FeesComponent } from './fees.component';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';

/**
 * Ensemble des routes pour les costs rule.
 */
export const FEES_ROUTE: Routes = [
  {
    path: ConstantFeesBenefits.url.FEES.FEES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantFeesBenefits.url.FEES.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantFeesBenefits.url.FEES.LIST,
        component: FeesComponent
      }
    ]
  }
];
