import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IpAdressService } from '../ip-adress.service';
import { ApiKeyService } from '../api-key.service';
import { CUserApiKeyService } from '../../../../services/ServiceEntity/c-user-api-key.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { IIpAdressDTO, IpAdressDTO, IpAdressDTOS } from '../../../../model/ipAdress.model';
import { ApiKeyCDTO, IApiKeyCDTO, IApiKeyDTO } from '../../../../model/api-key.model';
import { TIME_MIN_X1_REQUEST, TIME_MIN_X2_REQUEST } from '../../../../shared/constant/request.contant';
import { STATUS_BD_CONSTANT } from '../../../../model/status.model';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Permet de gérer l'ajout des api key.
 */
@Component({
  selector: 'app-api-key-delete',
  templateUrl: './api-key-delete.component.html',
  animations: [fadeIn]
})
export class ApiKeyDeleteComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ApiKeyDeleteComponent>;
  private subs$ = new Subject<void>();

  editForm: UntypedFormGroup;
  idAccount: number;

  apiKey: IApiKeyDTO;

  isDeleting = false;
  successDelete = false;
  hasDelete = false;

  constructor(
    private ipAdressService: IpAdressService,
    private apiKeyService: ApiKeyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domainService: CUserApiKeyService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ApiKeyDeleteComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct api key add');
    this.theDialogRef = this.dialogRef;
    console.log('## idAccount: ', this.data.idAccount);
    this.idAccount = this.data.idAccount;
    this.apiKey = this.data.apiKey;
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
    console.log('## init api key add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
  }

  ngOnDestroy() {
    console.log('## destroy api key add');
    this.formService.free(this.subs$);
  }

  close() {
    this.dialogRef.close();
  }

  delete() {
    console.log('## deleting api key: ', this.apiKey);
    this.isDeleting = true;
    this.serverHasError = false;
    this.successDelete = false;
    let a = {
      'apiKeyId': this.apiKey.id,
      'statusId': STATUS_BD_CONSTANT.removed
    }
    this.apiKeyService.updateStatus(a).subscribe(res => {
      console.log('## success delete api key');
      this.serverHasError = false;
      this.successDelete = true;
      this.hasDelete = true;
      setTimeout(() => {
        this.apiKeyService.getAllObjetById();
      }, TIME_MIN_X1_REQUEST);
      this.responseFromServer();
    }, (res: HttpErrorResponse) => {
      console.log('## error get list status while adding: ' + res.message);
      this.serverHasError = true;
      this.successDelete = false;
      this.isDeleting = false;
    })
  }

  private responseFromServer() {
    setTimeout(() => {
      this.isDeleting = false;
      this.close();
    }, 300);
  }

}