import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileExcelService {

  public resourceUrl = BASE_URL + 'file';
  constructor(private http: HttpClient) {
  }

  download(): Observable<Blob> {
    return this.http.get(this.resourceUrl, {
      responseType: 'blob'
    });
  }
}
