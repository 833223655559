import { Injectable } from '@angular/core';
import { AdminUserListService } from './admin-user-list.service';
import { PartenerUserListService } from './partener-user-list.service';
import { RoleType } from '../../../../model/account.model';
import { NoUserListService } from './no-user-list.service';
import { ICuserListService } from './i-cuser-list.service';

/**
 * Permet de lister les utilisateurs de la base de données
 * en fonction de plusieurs roles.
 */
@Injectable({ providedIn: 'root' })
export class UserListFactoryService {

  constructor(
    private adminUserListService: AdminUserListService,
    private partenerUserListService: PartenerUserListService,
    private noUserListService: NoUserListService) {
  }

  /**
   * @param r role de l'utilisateur.
   * @return ICuserListService service qui va permettre
   * de gérer les utilisateurs de la base de données en
   * fonction de plusieurs roles.
   */
  getService(r: RoleType): ICuserListService{
    console.log('## role r: ', r);
    if (r === RoleType.ROLE_ADMIN) {
      return this.adminUserListService;
    } else if (r === RoleType.ROLE_PARTENER) {
      return this.partenerUserListService;
    }
    return this.noUserListService;
  }

}