import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { AccountRessType, IAccountRessType } from '../../../../model/acc_ress_type.model';
import { AccountRessTypeService } from '../account-ress-type.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { ConstantHome } from '../../home/home.url';
import { ConstantAccRessType } from '../acc-ress-type.url';
import { Url } from '../../../../shared/constant/url.constant';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccRessTypeColumnName } from '../acc-ress-type-column-name';
import { FormService } from '../../../../shared/services/form.service';
import { fadeIn } from '../../../../animations/form-error';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Permet de gérer l'ajout des types de account ressource.
 */
@Component({
  selector: 'app-accounts-ressource-type-add',
  templateUrl: './acc-ress-type-add.component.html',
  animations: [fadeIn]
})
export class AccountsRessourceTypeAddComponent extends BasePageComponent
  implements OnInit, OnDestroy, AfterViewInit {
  isSaving = false;
  idColumn: string;
  typeColumn: string;
  successSave = false;
  serverHasError = false;

  private subs$ = new Subject<void>();
  @Output('addList') accRessTypeList = new EventEmitter<IAccountRessType[]>();
  @Output('addRef') addRefOut = new EventEmitter<any>();
  @ViewChild('addRef') addRef: any;
  editForm: UntypedFormGroup;


  constructor(
    private accountService: AccountService,
    private formService: FormService,
    private columnName: AccRessTypeColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private accountRessTypeService: AccountRessTypeService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct account ressource type add');
    const column = this.columnName.getColumnName();
    this.typeColumn = column.type;
    this.idColumn = column.id;
    this.editForm = this.fb.group({
      [this.idColumn]: [],
      [this.typeColumn]: [null, [Validators.required]]
    });
    this.pageData = {
      title: ConstantAccRessType.data.sub.ajout.title,
      key: '',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: '',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantAccRessType.data.title,
          key: '',
          route: Url.SLASH_ROUTE.concat(ConstantAccRessType.urlFull.ACCOUNT_RESSOURCE_TYPE.LIST)
        },
        {
          title: ConstantAccRessType.data.sub.ajouter,
          key: '',
        }
      ]
    };
  }

  ngOnInit(): void {
    console.log('## init account ressource type add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {})
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy acc ress type ressource');
    this.formService.free(this.subs$);
    this.successSave = false;
  }


  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const acc: IAccountRessType = this.createFromForm();
    console.log('## acc: ' + JSON.stringify(acc));
    this.accountRessTypeService.create(acc).subscribe(res => {
        console.log('## success create acc ress type');
        this.serverHasError = false;
        this.isSaving = false;
        this.editForm.reset();
        this.successSave = true;
        this.accountRessTypeService.query().subscribe(res => {
          console.log('## Success get account_ress_type list by ');
          this.accRessTypeList.emit((res.body) ? res.body : []);
        });
      },
      err => {
        console.log('## error create acc ress type');
        this.isSaving = false;
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  private createFromForm(): IAccountRessType {
    return {
      ...new AccountRessType(),
      [this.idColumn]: this.editForm.get(this.idColumn).value,
      [this.typeColumn]: this.editForm.get(this.typeColumn).value
    };
  }

  closeModal() {
    this.successSave = false;
    this.serverHasError = false;
    this.modal.close();
    this.editForm.reset();
  }
  reset(){
    this.editForm.reset();
  }

  /**
   * Permet de renvoyer le template add type account
   * ressource.
   */
  ngAfterViewInit(): void {
    this.addRefOut.emit(this.addRef);
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }
}
