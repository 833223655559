import { Routes } from '@angular/router';
import { ConstantFormulaires } from './formulaire.url';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';
import { FormulaireListComponent } from './formulaire-list.component';

/**
 * Ensemble des routes pour les costs rule.
 */
export const FORMULAIRE_ROUTE: Routes = [
  {
    path: ConstantFormulaires.url.FEES.FEES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantFormulaires.url.FEES.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantFormulaires.url.FEES.LIST,
        component: FormulaireListComponent
      }
    ]
  }
];
