import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IOption } from '../../../../ui/interfaces/option';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryColumnName, ICountryColumnName } from '../../countries/country-column-name';
import { Country, ICountry } from '../../../../model/country.model';
import { Subject } from 'rxjs';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { MoniesService } from '../../monies/monies.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { IVExchangeColumnName, VExchangeColumnName } from '../vexchange-rate-column-name';
import { IVersionExchangeRate } from '../../../../model/version-exchange-rate.model';
import { VersionExchangeRateService } from '../version-exchange-rate.service';

/**
 * Permet de gérer la modification des countries.
 */
@Component({
  selector: 'app-version-exch-rate-liste',
  templateUrl: './version-exch-rate-update.component.html',
  animations: [fadeIn]
})
export class VersionExchRateUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;
  monies: IOption[] = [];
  theDialogRef: MatDialogRef<VersionExchRateUpdateComponent>;
  editForm: UntypedFormGroup;
  column: IVExchangeColumnName;
  anotherMoney: boolean;
  private countrySelected: IVersionExchangeRate;
  private subs$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private dialogRef: MatDialogRef<VersionExchRateUpdateComponent>,
    private moniesService: MoniesService,
    private formService: FormService,
    private columnName: VExchangeColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: VersionExchangeRateService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct country liste');
    this.theDialogRef = this.dialogRef;
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init country liste');
    this.accountService.identity().pipe(takeUntil(this.subs$)).subscribe(res => {
      this.countryService.getVersionExchangeRateSelectObs()
        .pipe(takeUntil(this.subs$)).subscribe((res: IVersionExchangeRate) => {
        console.log('## get country selected to liste');
        try {
          console.log('## data: ', res);
          this.countrySelected = res;
          this.editForm.patchValue({
            [this.column.id]: res.id,
            [this.column.version]: res.version,
            [this.column.description]: res.description,
            [this.column.etat]: res.etat,
          })
        }catch (e) {
          console.log('## error get country selected: ' + e.message);
        }
      });
    })
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy country liste');
    this.successSave = false;
    this.formService.free(this.subs$);
    this.countryService.setVersionExchangeRateSelectObs(undefined);
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  initForm(fb: UntypedFormBuilder) {
    try {
      this.editForm = this.fb.group({
        id: [],
        version: [
          '',
          [Validators.required, Validators.minLength(1),
            Validators.maxLength(200)]
        ],
        description: [
          '',
          [Validators.maxLength(200)]
        ],
        etat: [
          '',
          [Validators.required,
            Validators.maxLength(200)]
        ],
      });

    } catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  /**
   * Enregistre un type de account ressource
   */
  save() {
    console.log('## liste country');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: IVersionExchangeRate = this.createFromForm();
    console.log('## c: ', c);
    this.countryService.update(c).subscribe(res => {
        console.log('## success liste country: ', res);
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.markAsPristine();
        // this.editForm.reset();
        this.successSave = true;
        this.getAllVersion();
      },
      err => {
        console.log('## error liste exchange rate: ', err);
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  getAllVersion() {
    setTimeout(() => {
      this.countryService.query().subscribe(res => {
        console.log('## Success get country list');
        this.countryService.setVersionExchangeRateObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300)
  }

  private createFromForm(): IVersionExchangeRate {
    return {
      ...new Country(),
      id: this.editForm.get(['id']).value,
      version: this.editForm.get(['version']).value,
      description: this.editForm.get(['description']).value,
      etat: this.editForm.get(['etat']).value
    };
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidOrPristineForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}