import { Injectable } from '@angular/core';
import { BaseValidationService, DataFormDynamic } from './base-validation.service';
import { ValidationFormService } from './validation-form.service';
import { MatDialog } from '@angular/material/dialog';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../../shared/constant/css-style/css-size.constant';
import { CONTAINER_DIALOG_POPUP } from '../../../../shared/constant/css-style/css-class.constant';
import { TransfertCompteInfosComponent } from '../transfert-compte-infos/transfert-compte-infos.component';
import { ICashModel } from '../cash-data-model';

/**
 * Permet de valider le formulaire d'un transfert compte.
 */
@Injectable({ providedIn: 'root' })
export class TransfertCompteValidationService extends BaseValidationService
  implements ValidationFormService {


  constructor(
    private dialog: MatDialog,
    protected modal: TCModalService) {
    super(modal);
  }

  /**
   * @see ValidationFormService#valider
   */
  valider(d: DataFormDynamic, dm: ICashModel): any {
    console.log('## validation transfert compte');
    dm.cashDataModel.dataForm = d;
    this.dialog.open(TransfertCompteInfosComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: dm
    });
  }
}