import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../animations/form-error';
import { BasePageComponent } from '../../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { IOption } from '../../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryColumnName, ICountryColumnName } from '../../../countries/country-column-name';
import { AccountService } from '../../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../../shared/services/form.service';
import { MoniesService } from '../../../monies/monies.service';
import { TCModalService } from '../../../../../ui/services/modal/modal.service';
import { CountryService } from '../../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { HttpService } from '../../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../../shared/constant/css-style/css-class.constant';
import { Country, ICountry } from '../../../../../model/country.model';
import { BaseService } from '../../../base/base.service';
import { Validators as validatorsModel } from '../../../../../model/champs_formulaire/validators.model';
import { ChampsFormulaireColumnName, IChampsFormulaireColumnName } from '../champs-formulaire-column-name';
import { ChampsFormulaireService } from '../champs-formulaire.service';
import { ChampsFormulaire, LocationType, SourceType, TypeForm } from '../../../../../model/champs_formulaire/champs-formulaire.model';
import { IServiceItem } from '../../../../../model/service-item.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../../../shared/constant/request.contant';
import { ServiceItemService } from '../../../service-items/service-item.service';
import { LocationTypeModel, TypeInputFormModel } from '../../../../../model/champs_formulaire/type-input-form.model';
import { TypeInputFormService } from '../../type-input-form/type-input-form.service';
import { FormulaireService } from '../../../formulaires/formulaire.service';
import { Formulaire } from '../../../../../model/champs_formulaire/formulaire.model';

/**
 * Permet de gérer l'ajout des champs de formulaire.
 */
@Component({
  selector: 'app-champs-formulaire-add',
  templateUrl: './champs-formulaire-add.component.html',
  animations: [fadeIn]
})
export class ChampsFormulaireAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ChampsFormulaireAddComponent>;
  private subs$ = new Subject<void>();
  typeForms: IOption[] = [];
  sourceTypes: IOption[] = [];
  locationTypes: IOption[] = [];
  editForm: UntypedFormGroup;
  column: IChampsFormulaireColumnName;

  // service item.
  serviceItemSelected: Formulaire;
  serviceItemsList: Formulaire[] = [];
  filteredOptions: Observable<Formulaire[]>;

  // TypeInputFormModel.
  typeInputFormList: TypeInputFormModel[] = [];
  filteredOptionsInput: Observable<TypeInputFormModel[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceItemService: ServiceItemService,
    private typeInputFormService: TypeInputFormService,
    private champsFormulaireService: ChampsFormulaireService,
    private formulaireService: FormulaireService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ChampsFormulaireAddComponent>,
    private columnName: ChampsFormulaireColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct formulaire add');
    this.serviceItemSelected = this.data?.serviceItemSelected;
    console.log('## si: ', this.serviceItemSelected);
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init formulaire add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.setLoaded();
      });
    this.getServiceItemList(); // after 500ms.
    this.getTypeInputFormList(); // after 500ms.
    for (let item in TypeForm) {
      if (isNaN(Number(item))) {
        this.typeForms.push({
          ...new Option(),
          label: item?.toLocaleLowerCase(),
          value: item?.toString()
        });
      }
    }
    for (let item in SourceType) {
      if (isNaN(Number(item))) {
        this.sourceTypes.push({
          ...new Option(),
          label: item?.toLocaleLowerCase(),
          value: item?.toString()
        });
      }
    }

    // récupérer location type.
    this.champsFormulaireService.query(this.champsFormulaireService
      .resourceLocationUrl)
    .subscribe(res => {
      console.log('## success get list location type: ', res);
      const locationTypes: LocationTypeModel[] = res && res.body ? res.body : [];
      for(const l of locationTypes) {
        this.locationTypes.push({
          ...new Option(),
          label: l.label,
          value: l.id.toString()
        })
      }
    }, error => {
      console.log('## error get list location type');
    });


    if (this.serviceItemSelected) {
      setTimeout(() => {
        this.editForm.get(this.column.formulaire).setValue(this.serviceItemSelected);
      }, 200);
    }


  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  displayFnFormulaire(subject: Formulaire) {
    return subject ? subject.titre : '';
  }

  displayFnTypeInput(subject: TypeInputFormModel) {
    return subject ? subject.type : '';
  }

  getTypeInputFormList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.typeInputFormService
        .query(this.typeInputFormService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<TypeInputFormModel[]>) => mayBeOk.ok),
          map((response: HttpResponse<TypeInputFormModel[]>) => response.body)
        )
        .subscribe((res: TypeInputFormModel[]) => {
            console.log('## success get list TypeInputFormModel while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.typeInputFormList = rs;
            this.filteredOptionsInput = this.editForm.get(this.column.typeInputForm).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filterInput(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list TypeInputFormModel while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  private _filterInput(value: string): TypeInputFormModel[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.typeInputFormList.filter(option => option.type.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter TypeInputFormModel: ' + e.message);
    }
    return filteredList;
  }

  getServiceItemList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.formulaireService
        .query(this.formulaireService.list_url)
        .pipe(
          filter((mayBeOk: HttpResponse<Formulaire[]>) => mayBeOk.ok),
          map((response: HttpResponse<Formulaire[]>) => response.body)
        )
        .subscribe((res: Formulaire[]) => {
            console.log('## success get list formulaire while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.serviceItemsList = rs;
            this.filteredOptions = this.editForm.get(this.column.formulaire).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list formulaire while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  private _filter(value: string): Formulaire[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option?.titre?.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter champs formulaire: ' + e.message);
    }
    return filteredList;
  }

  initForm(fb: UntypedFormBuilder, column: IChampsFormulaireColumnName) {
    if (fb && column) {
      this.editForm = fb.group({
        [column.label]: ['', [Validators.required]],
        [column.type]: ['', [Validators.required]],
        [column.typeInputForm]: ['', [Validators.required]],
        [column.keyJson]: ['', [Validators.required]],
        [column.locationType]: ['', [Validators.required]],
        [column.formsOrder]: ['', [Validators.required]],
        [column.sourceType]: ['', [Validators.required]],
        [column.valeurType]: [''],
        [column.comment]: [''],
        [column.placeholder]: [''],
        [column.formulaire]: ['', [Validators.required]]
      });
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save validator');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    console.log('## c: ', c);
    this.champsFormulaireService.create(c, this.champsFormulaireService.resourceUrl)
      .subscribe(res => {
          console.log('## success create champs formulaire');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.champsFormulaireService.getAllObjet();
        },
        err => {
          console.log('## error create champs formulaire: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): ChampsFormulaire {
    return {
      ...new ChampsFormulaire(),
      label: this.editForm.get([this.column.label]).value,
      type: this.editForm.get([this.column.type]).value,
      typeInputForm: this.editForm.get([this.column.typeInputForm]).value,
      typeInputFormId: this.editForm.get([this.column.typeInputForm]).value?.id,
      keyJson: this.editForm.get([this.column.keyJson]).value,
      locationType: this.editForm.get([this.column.locationType]).value,
      locationTypeId: +this.editForm.get([this.column.locationType]).value,
      formsOrder: this.editForm.get([this.column.formsOrder]).value,
      sourceType: this.editForm.get([this.column.sourceType]).value,
      valeurType: this.editForm.get([this.column.valeurType]).value,
      comment: this.editForm.get([this.column.comment]).value,
      placeholder: this.editForm.get([this.column.placeholder]).value,
      formulaire: this.editForm.get([this.column.formulaire]).value,
      formulaireId: this.editForm.get([this.column.formulaire])?.value?.id
    };
  }

  closeModal() {
    console.log('## close formulaire add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }


}
