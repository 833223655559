// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of ell replacements can be found in `angular.json`.
import { SETTINGS, SETTINGS0 } from './settings';
import firebase from 'firebase/compat/app';
import initializeApp = firebase.initializeApp;
// import * as npm from '../../package.json';
// import firebase from 'firebase/compat';

// import initializeApp = firebase.initializeApp;
// let v = npm.version;

export const environment = {
  production: false,
  appSettings: SETTINGS0,
  version: "1.5.0",
  firebaseConfig : {
    apiKey: "AIzaSyCo21iwrPgntAWT4-BQPGBAjgBk0Ng_nMA",
    authDomain: "fcm-web-servicegate.firebaseapp.com",
    projectId: "fcm-web-ser vicegate",
    storageBucket: "fcm-web-servicegate.appspot.com",
    messagingSenderId: "626472471753",
    appId: "1:626472471753:web:0bebccfe017d764f879118",
    vapidKey: "BLUswRra2f3tT74Lt0lVjTh8cH5L99fqx5rcV0TuzBlEJF63mijOxg69HD7Ep3hvXKBXyYh85RWGkCO3R5NOje0",
    serverKey: "AAAAkdyrTMk:APA91bGKYoY8qJTIkEsnczyw_mEHlrUM1cfRsN0dw5GCEwJB81MX13uegjmfXng-TVcugfpldSPU9vZrkpTijchg_RmUVoE0N22-UIQ_xT8QGrj8JS4EEkwALpPIHQIqZ-IPx9ZP69hh"
  }
};

// Initialize Firebase
const app = initializeApp(environment.firebaseConfig);

export const BASE_URL_PORT = 'https://api-sgate.almfinance.com/';
export const BASE_URL = BASE_URL_PORT + 'api/';
export const BASE_URL_DYNAMIQ = BASE_URL_PORT + 'api/execute/';
export const BASE_URL_MANAGEMENT = BASE_URL_PORT + 'api/management';
// l'api est restreint au role admin.
export const BASE_URL_ADMIN = BASE_URL_PORT + 'api/admin';
export const BASE_URL_IMAGE = BASE_URL_PORT + 'assets/images/'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
