import { Injectable } from '@angular/core';
import { INextForm } from '../../cash-dynamic/dynamic-form.init';
import {
  BEFORE_ICON_RESET,
  BTN_CANCEL_CLASS,
  BTN_POPUP_CLASS,
  BTN_VALIDATION_CLASS,
  CONTAINER_BTN_POPUP_CLASS,
  CONTAINER_INSIDE2X_POPUP_CLASS,
  CONTAINER_INSIDE_POPUP_CLASS, CONTAINER_POPUP_CLASS, ICON_COLOR_REQUEST_CSS, ICON_FONT_REQUEST_CLASS,
  LABEL_FORM_CLASS,
  OUTLINE_CANCEL,
  OUTLINE_RESET,
  POPUP_CONTAINER_CLASS, REMOVABLE_ICON_REQUEST, RESULT_REQUEST_ERROR_CLASS, RESULT_REQUEST_SUCCESS_CLASS,
  RESULT_REQUEST_TEXT_ERROR_CLASS,
  RESULT_REQUEST_TEXT_SUCCESS_CLASS, TITLE_POPUP_CLASS,
  WARNING_COLOR_CSS, WIDTH_MEDIUM_POPUP_CLASS
} from '../../../../shared/constant/css-style/css-class.constant';
import { TIME_MEDIUM_REQUEST, TIME_MIN_X2_REQUEST } from '../../../../shared/constant/request.contant';
import { Content } from '../../../../ui/interfaces/modal';
import { TCModalService } from '../../../../ui/services/modal/modal.service';


/**
 * Données utilisées lors de la validation
 * d'un formulaire dynamique.
 * data: les données du formulaire.
 * loadingMessagesForm: permet de savoir si
 * la transaction est en cours d'exécution.
 * reponseTransfert: les données de la réponse de la transaction.
 * forModal: un template html permettant d'afficher
 * le résultat de la transaction.
 */
export interface DataFormDynamic {
  curentUserCuser?: any;
  countryDestID?: any;
  data?: FormData;
  errorTransfert?: boolean;
  loadingMessagesForm?: boolean;
  successTransfert?: boolean;
  nextFormData?: INextForm;
  reponseTransfert?: any;
  forModal?: any;
}


/**
 * Contient les fonctionnalités communes à la validation
 * d'un formulaire dynamique.
 */
@Injectable({ providedIn: 'root' })
export class BaseValidationService {


  constructor(
    protected modal: TCModalService) {
  }

  getLabelFormClass() {
    return LABEL_FORM_CLASS;
  }

  hasOutline() {
    return OUTLINE_RESET;
  }

  getIconReset(): string {
    return BEFORE_ICON_RESET;
  }

  isCancelBtnOutline(): boolean {
    return OUTLINE_CANCEL;
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getContainerBtnClass() {
    return CONTAINER_BTN_POPUP_CLASS;
  }

  getResultRequestTextError(): string {
    return RESULT_REQUEST_TEXT_ERROR_CLASS;
  }

  getResultRequestTextSuccess(): string {
    return RESULT_REQUEST_TEXT_SUCCESS_CLASS;
  }

  getWarningColor(): string {
    return WARNING_COLOR_CSS;
  }

  getInside2xContainerClass(): string {
    return CONTAINER_INSIDE2X_POPUP_CLASS;
  }

  getInsideContainerClass(): string {
    return CONTAINER_INSIDE_POPUP_CLASS;
  }
  getContainerClass() : string {
    return CONTAINER_POPUP_CLASS;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getIconColorRequestClass(): string {
    return ICON_COLOR_REQUEST_CSS;
  }

  isRemovable(): boolean {
    return REMOVABLE_ICON_REQUEST;
  }

  getTitleClass(): string {
    return TITLE_POPUP_CLASS;
  }

  getIconRequestClass(): string {
    return ICON_FONT_REQUEST_CLASS;
  }

  getResultRequestErrorClass() {
    return RESULT_REQUEST_ERROR_CLASS;
  }

  getResultRequestSuccessClass() {
    return RESULT_REQUEST_SUCCESS_CLASS;
  }

  /**
   * Affiche les détails de la transaction
   * lorsqu'elle a réussi.
   * @param modal
   * @param title
   * @param footer
   * @param options
   */
  showDetailsEnvoieCode(modal, title: string, footer, options) {
    console.log('## showDetails transaction');
      this.openModal(modal, title, footer, options, POPUP_CONTAINER_CLASS);
      this.modal.componentRef.instance.close.subscribe(data => {
      if(data) {
        console.log('## closed');
     //   this._codeTransactionService.clearAllData();
      } else {
        return null;
      }
    });
  }

  openModal<T>(body: Content<T>, header: Content<T> = null,
               footer: Content<T> = null, options: any = null,
               theClass?: string) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options,
      theClass: theClass
    });
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @private
   */
  public manageMessageWhenError(d: DataFormDynamic) {
    d.successTransfert = false;
    d.loadingMessagesForm = false;
    d.errorTransfert = true;
  }

  /**
   * show or delete message infos.
   * @param response the response of the transaction.
   * @param d {@see DataFormDynamic}
   */
  public manageMessageWhenSucceed(response, d: DataFormDynamic) {
    d.errorTransfert = false;
    d.loadingMessagesForm = false;
    d.successTransfert = true;
    d.nextFormData.formulaire.reset();
  }

}