import { Component, ComponentFactoryResolver, ComponentRef, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from '../../base-page';
import { ConstantAccRessType } from '../acc-ress-type/acc-ress-type.url';
import { ConstantHome } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { ConstantCountries } from './countries.url';
import { ICountry } from '../../../model/country.model';
import { IAccountRessType } from '../../../model/acc_ress_type.model';
import { CONSTANT_ICON } from '../../../shared/constant/icon.constant';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { CountryAddComponent } from './add/country-add.component';
import { TCModalComponent } from '../../../ui/components/modal';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CountryService } from './countries.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormService } from '../../../shared/services/form.service';
import { CountryShowComponent } from './show/country-show.component';
import { CountryUpdateComponent } from './update/country-update.component';
import { CountryDeleteComponent } from './delete/country-delete.component';
import { IServiceItem } from '../../../model/service-item.model';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { TranslateService } from '@ngx-translate/core';
import { BTN_CANCEL_CLASS, CANCEL_BG_COLOR } from '../../../shared/constant/css-style/css-class.constant';
import { ColorService } from '../../../shared/services/color.service';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})
export class CountriesComponent extends BasePageComponent implements OnInit, OnDestroy {
  countriesList: ICountry[];
  countriesSearch: ICountry[];
  searchValue: string;
  @ViewChild('basicTable') tabRef: any;
  countriesTemp: ICountry[];
  countryAddRef: any;
  subs$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private colorService: ColorService,
    private formService: FormService,
    private dialog: MatDialog,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct countries list');
    console.log('this.pageData before##', this.pageData);
    this.pageData = {
      title: '',
      key: 'listCountries',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: '',
          key: 'countries',
          route: Url.SLASH_ROUTE.concat(ConstantCountries.urlFull.COUNTRIES.LIST)
        },
        {
          title: '',
          key: 'lister'
        }
      ]
    };
    if(this.pageData.key==="listCountries"){
      translate.get('listCountries').subscribe(res => this.pageData.title=res);
    }
   
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="countries" ){
        translate.get('countries').subscribe(res => value.title=res);
      }
      if( value.key==="lister" ){
        translate.get('lister').subscribe(res => value.title=res);
      }
     
    }); 
    console.log('this.pageData after##', this.pageData);
  }

  ngOnInit(): void {
    console.log('## init countries liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        super.ngOnInit();
        super.setLoaded();
        this.countryService.query().subscribe(res => {
          console.log('## Success get country list');
          this.countriesList = (res.body) ? res.body : [];
          this.countriesSearch = this.countriesList;
          super.setLoaded();
          this.countryService.getCountryObs().pipe(takeUntil(this.subs$))
            .subscribe((res: ICountry[]) => {
              console.log('## get list country in list by subject');
              this.countriesList = (res) ? res : [];
              this.countriesSearch = this.countriesList;
            });
        });
      });
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  getCancelBgColor(): string {
    return CANCEL_BG_COLOR;
  }

  getBtnCancelClass(): string {
    return BTN_CANCEL_CLASS;
  }

  getBgSearchBar() {
    return this.colorService.getBrdColorSearchBar();
  }

  getColorSearchBar() {
    return this.colorService.getColorSearchBar();
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.countriesSearch].map(mapFn);
    const result = this.countriesSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  /**
   * Ouvre le template html AccountsRessourceTypeAddComponent
   * dans un popup.
   */
  add() {
    console.log('## open country add');
    this.dialog.open(CountryAddComponent, {
      height: '700px',
      width: '800px'
    });
  }

  show(c: ICountry) {
    console.log('## open country show');
    this.countryService.setCountrySelectObs(c);
    this.dialog.open(CountryShowComponent, {
      height: '700px',
      width: '800px'
    });
  }

  update(c: ICountry) {
    this.countryService.setCountrySelectObs(c);
    this.dialog.open(CountryUpdateComponent, {
      height: '700px',
      width: '800px'
    });
  }

  delete(c: ICountry) {
    this.countryService.setCountrySelectObs(c);
    this.dialog.open(CountryDeleteComponent, {
      height: '700px',
      width: '800px'
    });
  }

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): ICountry[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }
}
