import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { AccountRessType, IAccountRessType } from '../../../../model/acc_ress_type.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../../acc-ress-type/acc-ress-type-column-name';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { AccountRessTypeService } from '../../acc-ress-type/account-ress-type.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { ConstantAccRessType } from '../../acc-ress-type/acc-ress-type.url';
import { ConstantHome } from '../../home/home.url';
import { Url } from '../../../../shared/constant/url.constant';
import { CountryColumnName, ICountryColumnName } from '../country-column-name';
import { Country, ICountry } from '../../../../model/country.model';
import { MoniesService } from '../../monies/monies.service';
import { IMoney } from '../../../../model/money.model';
import { MatDialogRef } from '@angular/material/dialog';
import { IOption } from '../../../../ui/interfaces/option';
import { CountryService } from '../countries.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';

/**
 * Permet de gérer l'ajout des countries.
 */
@Component({
  selector: 'app-country-add',
  templateUrl: './country-add.component.html',
  animations: [fadeIn]
})
export class CountryAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<CountryAddComponent>;
  private subs$ = new Subject<void>();
  monies: IOption[] = [];
  editForm: UntypedFormGroup;
  column: ICountryColumnName;
  anotherMoney: boolean;

  constructor(
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<CountryAddComponent>,
    private moniesService: MoniesService,
    private columnName: CountryColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct country add');
    const column = this.columnName.getColumnName();
    this.theDialogRef = this.dialogRef;
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init country add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
      this.getAllMonies();
      super.setLoaded();
    })
  }

  ngOnDestroy() {
    console.log('## destroy country add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  getAllMonies() {
    setTimeout(()=>{
      this.moniesService
        .query().subscribe((res) => {
        console.log('## get all monies');
        const monies = (res) ? res.body : [];
        console.log('## size: ' + monies.length);
        this.monies = [];
        monies.forEach(m => {
          //  this.monies.push({"label":m.label,"value":m.id.toString()});
          this.monies.push({
            ...new Option(),
            label: m.label,
            value: m.id?.toString()
          })
        })
        console.log('## val: ' + JSON.stringify(this.monies));
      });
    }, 500)
  }

  initForm(fb: UntypedFormBuilder, column: ICountryColumnName) {
    if(fb && column) {
      this.editForm = fb.group({
        [column.id]: [],
        [column.label]: [
          '',
          [Validators.required, Validators.minLength(3),
            Validators.maxLength(50), Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        [column.codeCountry]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(5), Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        [column.codePhone]: ['', [Validators.required, Validators.minLength(2),
          Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
        [column.mId]: ['', [Validators.required]],
        [column.mLabel]: ['', [Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]],
        [column.mLabelCourt]: ['', [Validators.pattern("^[A-Za-z]+((\\s)?((\\'|\\-|\\.)?([A-Za-z])+))*$")]],
        [column.mValue]: [''],
        [column.mUnit]: [''],
        [column.mCode]: ['', Validators.pattern('^([A-Za-z])+$')]
      });
    }
  }

  addAnotherMoney() {
    console.log('## another money');
    this.anotherMoney = true;
      this.editForm.controls['mId'].disable();
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save country');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: ICountry = (!this.anotherMoney) ? this.createFromForm() :
    this.createFromFormMoney();
    console.log('## c: ' + JSON.stringify(c));
    this.countryService.create(c).subscribe(res => {
        console.log('## success create country');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.reset();
        this.anotherMoney = false;
        this.successSave = true;
        this.getAllMonies();
        this.countryService.getAllCountry();
      },
      err => {
        console.log('## error create country');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300)
  }

  private createFromForm(): ICountry {
    return {
      ...new Country(),
      id: this.editForm.get(['id']).value,
      label: this.editForm.get(['label']).value,
      codeCountry: this.editForm.get(['codeCountry']).value,
      codePhone: this.editForm.get(['codePhone']).value,
      mId: +this.editForm.get(['mId']).value
    };
  }

  private createFromFormMoney(): ICountry {
    return {
      ...new Country(),
      id: this.editForm.get(['id']).value,
      label: this.editForm.get(['label']).value,
      codeCountry: this.editForm.get(['codeCountry']).value,
      codePhone: this.editForm.get(['codePhone']).value,
    //  mId: +this.editForm.get(['mId']).value,
      mLabel: this.editForm.get(['mLabel']).value,
      mLabelCourt: this.editForm.get(['mLabelCourt']).value,
      mValue: this.editForm.get(['mValue']).value,
      mUnit: this.editForm.get(['mUnit']).value,
      mCode: this.editForm.get(['mCode']).value
    };
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }
  reset(){
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
