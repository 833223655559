import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { Subject } from 'rxjs';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../../acc-ress-type/acc-ress-type-column-name';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { ICUser } from '../../../../model/c-user.model';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { STATUS_BD_CONSTANT } from '../../../../model/status.model';
import { ColorService } from '../../../../shared/services/color.service';
import { RoleService } from '../../../../shared/services/role.service';
import { Account, RoleType } from '../../../../model/account.model';
import { UserListFactoryService } from '../services/user-list-factory.service';

/**
 * Permet de gérer la suppression d'un utilisateur.
 */
@Component({
  selector: 'app-user-delete',
  templateUrl: './user-delete.component.html',
  animations: [fadeIn]
})
export class UserDeleteComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  userSelected: ICUser;
  private subs$ = new Subject<void>();
  isDeleting = false;
  successDelete = false;
  serverHasError = false;
  hasDelete = false;

  role: RoleType;
  account: Account;

  constructor(
    private userListFactoryService: UserListFactoryService,
    private roleService: RoleService,
    private colorService: ColorService,
    private accountService: AccountService,
    private cUserService: CUserService,
    private dialogRef: MatDialogRef<UserDeleteComponent>,
    private formService: FormService,
    private columnName: AccRessTypeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct user delete');
  }

  ngOnInit(): void {
    console.log('## init user delete.');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('## account', account);
        this.account = account;
        this.role = this.roleService.getFromRoleType(account?.authorities);
        this.cUserService.getIcuserSelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: ICUser) => {
          console.log('## get user selected');
          try {
            console.log('## data: ' + JSON.stringify(res));
            this.userSelected = res;
          } catch (e) {
            console.log('## error get user delete: ' + e.message);
          }
          super.setLoaded();
        });
      });
  }

  ngOnDestroy() {
    console.log('## destroy user delete');
    this.formService.free(this.subs$);
    this.cUserService.setIcuserSelectObs(undefined);
  }

  getStatusBg(id: number): string {
    return this.colorService.getStatusBg(id);
  }

  closeModal() {
    console.log('## close delete money');
    this.dialogRef.close();
  }

  delete() {
    console.log('## deleting user');
    this.isDeleting = true;
    this.serverHasError = false;
    this.successDelete = false;
    let data = {
      cUserId: this.userSelected.id,
      statusId: STATUS_BD_CONSTANT.removed
    };
    console.log('## the role type: ', this.role);
    this.cUserService.updateStatus(data).subscribe(
      response => {
        console.log('## success delete user');
        this.serverHasError = false;
        this.successDelete = true;
        this.isDeleting = false;
        this.hasDelete = true;
        this.responseFromServer();
        this.cUserService.getAll(this.role, this.userListFactoryService);
      }, error => {
        console.log('## error delete user');
        this.serverHasError = true;
        this.successDelete = false;
        this.isDeleting = false;
        this.responseFromServer();
      });
  }

  private responseFromServer() {
    setTimeout(() => {
      this.isDeleting = false;
    }, 300);
  }
}
