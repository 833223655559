import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../../base-page';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import * as PageActions from '../../../../store/actions/page.actions';

@Component({
  selector: 'page-search-table',
  templateUrl: './search-table.component.html',
  styleUrls: ['./search-table.component.scss']
})
export class PageSearchTableComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  tableData: any[];
  searchData: any[];

  constructor(store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);

    this.pageData = {
      title: 'Search tables',
      key: '',
      breadcrumbs: [
        {
          title: 'UI Kit',
          key: '',
          route: 'dashboard'
        },
        {
          title: 'Tables',
          key: '',
          route: 'dashboard'
        },
        {
          title: 'Search',
          key: ''
        }
      ]
    };
    this.tableData = [];
  }

  ngOnInit() {
    super.ngOnInit();

    this.getData('assets/data/table-sorting.json', 'tableData', 'initTable');
  }

  initTable(): void {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );

    setTimeout(() => {
      this.searchData = JSON.parse(JSON.stringify(this.tableData));
      console.log("Search Data",this.searchData);
    });
  }

  search(query: string): void {
    const mapFn = item =>
      Object.values(item)
        .map(val => val.toString().toLowerCase())
        .join(' ');

    const stringArr = [...this.tableData].map(mapFn);

    this.searchData = this.tableData.filter(
      (_, i) => stringArr[i].indexOf(query && query.length ? query.toLowerCase() : '') > -1
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
