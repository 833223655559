import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { BASE_URL, BASE_URL_MANAGEMENT } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountConfirmDTO, INotificationDTO } from '../../../model/notification.model';
import { IExchangeRateDto } from '../../../model/exchange-rate.model';

@Injectable({ providedIn: 'root' })
export class NotificationService extends ApiService {

  public resourceUrl = BASE_URL + 'transactions/withdrawalv3/';
  public notificationSearchUrl;
  public confirmTransactionUrl;
  public notificationUrl;
  public notificationSeenUrl;
  public notificationViewUrl;
  public notificationShowUrl;
  public accountsUrl;
  public conversionUrl;

  constructor(protected http: HttpClient) {
    super(http);
    this.notificationSearchUrl = this.resourceUrl + 'notifications/search';
    this.confirmTransactionUrl = this.resourceUrl + 'confirm';
    this.notificationUrl = this.resourceUrl + 'notifications';
    this.notificationSeenUrl = this.resourceUrl + 'notifications/seen';
    this.notificationViewUrl = this.resourceUrl + 'notifications/view';
    this.notificationShowUrl = this.resourceUrl + 'notifications/show';
    this.accountsUrl = this.resourceUrl + 'accounts';
    this.conversionUrl = BASE_URL + 'transactions/conversion'
  }

  /**
   * Permet de lister les accounts du receiver.
   * {@link INotificationDTO[]}
   */
  getNotificationByStatus(req?: any): Observable<HttpResponse<INotificationDTO[]>> {
    return this.http.get<INotificationDTO[]>(this.notificationSearchUrl,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de convertir un montant à partir d'une monnaie source
   * et une monnaie destinataire.
   * @return Observable<HttpResponse<number>>
   */
  convertMoney(req?: any): Observable<HttpResponse<IExchangeRateDto>> {
    return this.http.get<IExchangeRateDto>(this.conversionUrl,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de confirmer une transaction.
   */
  confirmTransaction(req?: any): Observable<any> {
    return this.http.post<any>(this.confirmTransactionUrl, req,
      { observe: 'response' });
  }

  /**
   * Permet de lister les accounts du receiver.
   * {@link INotificationDTO[]}
   */
  listAccounts(req?: any): Observable<HttpResponse<AccountConfirmDTO[]>> {
    return this.http.get<AccountConfirmDTO[]>(this.accountsUrl,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de recupérer une notification de transaction
   * {@link INotificationDTO[]}
   */
  markNotificationAsSeen(req?: any): Observable<HttpResponse<any>> {
    return this.http.put<INotificationDTO>(this.notificationSeenUrl, null,
      {params: req, observe: 'response' });
  }

  /**
   * Permet de recupérer une notification de transaction
   * {@link INotificationDTO[]}
   */
  getNotification(req?: any): Observable<HttpResponse<INotificationDTO>> {
    return this.http.get<INotificationDTO>(this.notificationShowUrl,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de lister les notifications de transaction
   * {@link INotificationDTO[]}
   */
  listView(req?: any): Observable<HttpResponse<INotificationDTO[]>> {
    return this.http.get<INotificationDTO[]>(this.notificationViewUrl,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de lister les notifications de transaction
   * {@link INotificationDTO[]}
   */
  list(req?: any): Observable<HttpResponse<INotificationDTO[]>> {
    return this.http.get<INotificationDTO[]>(this.notificationUrl,
      { params: req, observe: 'response' });
  }

  /**
   * Permet de récupérer les notifications de transaction
   * {@link ApiService#getAllObjet}
   */
  getAllObjetBehave(req?: any) {
    super.getAllObjetBehave(this.notificationViewUrl, req);
  }

  /**
   * Permet de récupérer les notifications de transaction
   * {@link ApiService#getAllObjet}
   */
  getAllObjetBySubject(req?: any) {
    super.getAllObjet(this.notificationViewUrl, req);
  }


  /**
   * {@link ApiService#getAllObjet}
   */
  getAllObjet(req?: any) {
    super.getAllObjet(this.notificationUrl, req);
  }
}
