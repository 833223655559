import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ICUser } from '../../../../model/c-user.model';
import { CUserService } from '../../../../services/ServiceEntity/c-user.service';
import { Observable } from 'rxjs';
import { ICuserListService } from './i-cuser-list.service';

/**
 * Permet de Lister tous les utilisateurs de la base de données.
 */
@Injectable({ providedIn: 'root' })
export class AdminUserListService implements ICuserListService{


  constructor(private cUserService: CUserService) {
  }

  /**
   * @return Liste tous les utilisateurs de la base de données.
   */
  loadAll(): Observable<HttpResponse<ICUser[]>> {
    return this.cUserService.queryWithStatus();
  }

  getUser(req?): Observable<HttpResponse<ICUser>> {
    return this.cUserService.getUserWithStatus(req);
  }

}