import { Injectable } from '@angular/core';

/**
 * Contient les clés permettant de déterminer
 * si on affiche ou pas les components.
 */
@Injectable({ providedIn: 'root' })
export class CashKey {

  constructor() {}

  /**
   * pour le component {@link AllCashOutComponent}
   */
  readonly appAllCashOut = 'appAllCashOut';

  /**
   * pour le component {@link PartialCashOutComponent}
   */
  readonly appPartialCashOut = 'appPartialCashOut';

  /**
   * pour le component {@link RetraitCodeComponent}
   */
  readonly appTabsRetraitCode = 'appTabsRetraitCode';

  /**
   * pour le component {@link RetraitCodeValidComponent}
   */
  readonly appRetraitCodeValide = 'appRetraitCodeValide';

  /**
   * pour le component {@link RetraitCodeValidComponent}
   */
  readonly retraitCodeValide = 'retraitCodeValide';

}
