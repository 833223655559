import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../../base-page';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';

@Component({
  selector: 'page-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class PageAlertsComponent extends BasePageComponent implements OnInit, OnDestroy {
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService
  ) {
    super(store, httpSv);

    this.demoCode =
`<tc-alert>
  This is <strong>Default alert.</strong> Lorem ipsum dolor sit amet.
</tc-alert>`;

    this.pageData = {
      title: 'Alerts',
      key: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'UI Kit',
          key: '',
          route: 'dashboard'
        },
        {
          title: 'Components',
          key: '',
          route: 'dashboard'
        },
        {
          title: 'Alerts',
          key: '',
        }
      ]
    };
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
