import { Routes } from '@angular/router';
import { ConstantServiceItems } from '../service-items/service-items.url';
import { ServiceItemsComponent } from '../service-items';
import { ServiceItemsComposeComponent } from './service-items-compose.component';

/**
 * Ensemble des routes pour les service items composés.
 */
export const SERVICE_ITEMS_COMPOSE_ROUTE: Routes = [
  {
    path: 'service_items_compose',
    children: [
      {
        path: '',
        redirectTo: 'liste',
        pathMatch: 'full'
      },
      {
        path: 'liste',
        component: ServiceItemsComposeComponent
      }
    ]
  }
];
