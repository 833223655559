export interface IExchangeRateDiff {
  id?: number;
  sourceMoney?: string;
  destMoney?: string;
  resultExchange?: number;
  resultExchangeUsed?: number;
  rate?: number;
  difference?: number;
  versionExchangeRateId?: number;
}

export class ExchangeRateDiff implements IExchangeRateDiff {
  constructor(public id?: number, public sourceMoney?: string,
              public destMoney?: string, public resultExchange?: number,
              public resultExchangeUsed?: number,
              public rate?: number,
              public difference?: number,
              public versionExchangeRateId?: number) {}
}

export interface IExchangeRate {
    id?: number;
    sourceMoney?: string;
    destMoney?: string;
    resultExchange?: number;
    rate?: number;
    valeurModifie?: number;
    versionExchangeRateId?: number;
  }

  export class ExchangeRate implements IExchangeRate {
    constructor(public id?: number, public sourceMoney?: string,
                public destMoney?: string, public resultExchange?: number,
                public valeurModifie?: number,
                public rate?: number,
                public versionExchangeRateId?: number) {}
  }

export interface IExchangeRateDto {
  id?: number;
  sourceMoney?: string;
  destMoney?: string;
  resultExchange?: number;
  rate?: number;
  versionExchangeRateId?: number;
  idManualCheck?: number;
  valeurModifie?: number;
}

export class ExchangeRateDto implements IExchangeRateDto {
  constructor(public id?: number, public sourceMoney?: string,
              public destMoney?: string, public resultExchange?: number,
              public versionExchangeRateId?: number,
              public rate?: number,
              public idManualCheck?: number,
              public valeurModifie?: number) {}
}