import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Account } from '../../../model/account.model';
import { ChampsFormulaire } from '../../../model/champs_formulaire/champs-formulaire.model';
import { Observable, Subject } from 'rxjs';
import { IServiceItem } from '../../../model/service-item.model';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from '../base/base.service';
import { ServiceItemService } from '../service-items/service-item.service';
import { FormService } from '../../../shared/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { ChampsFormulaireService } from '../champs_formulaires/champs_formulaires/champs-formulaire.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantChampsFormulaires } from '../champs_formulaires/champs_formulaires/champs-formulaire.url';
import { ConstantHome } from '../home/home.url';
import { Url } from '../../../shared/constant/url.constant';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { Formulaire } from '../../../model/champs_formulaire/formulaire.model';
import { FormulaireService } from './formulaire.service';
import { ConstantFormulaires } from './formulaire.url';
import { ChampsFormulaireUpdateComponent } from '../champs_formulaires/champs_formulaires/update/champs-formulaire-update.component';
import { ChampsFormulaireDeleteComponent } from '../champs_formulaires/champs_formulaires/delete/champs-formulaire-delete.component';
import { ConstantValidatorsAffected } from '../champs_formulaires/validator_affected/validator-affected.url';
import { ChampsFormulaireAddComponent } from '../champs_formulaires/champs_formulaires/add/champs-formulaire-add.component';
import { SIZE_H_MD_POPUP, SIZE_W_MD_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { FormulaireAddComponent } from './add/formulaire-add.component';
import { FormulaireUpdateComponent } from './update/formulaire-update.component';
import { FormulaireDeleteComponent } from './delete/formulaire-delete.component';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FormulaireLinkComponent } from './formulaire-link/formulaire-link.component';

@Component({
  selector: 'app-formulaire-list',
  templateUrl: './formulaire-list.component.html'
})
export class FormulaireListComponent extends BasePageComponent implements OnInit,
  OnDestroy {

  @ViewChild('basicTable') tabRef: any;
  account: Account; // the account connected.
  countriesList: Formulaire[];
  countriesSearch: Formulaire[];
  searchValue: string;
  initByQueryParam = false;

  serviceItemTyped = '';
  subs$ = new Subject<void>();

  serviceItemsList: IServiceItem[] = [];
  serviceItemSelected: IServiceItem;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<IServiceItem[]>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public baseService: BaseService,
    private serviceItemService: ServiceItemService,
    private cdRef: ChangeDetectorRef,
    private formService: FormService,
    private dialog: MatDialog,
    private formulaireService: FormulaireService,
    private accountService: AccountService,
    store: Store<IAppState>, httpSv: HttpService,
    private translate: TranslateService) {
    super(store, httpSv);
    console.log('## construct formulaire list');
    this.pageData = {
      title: ConstantFormulaires.data.sub.list.title,
      key: 'formulaire.title',
      // fil d'ariane.
      breadcrumbs: [
        {
          title: ConstantHome.data.title,
          key: 'home',
          route: Url.SLASH_ROUTE.concat(ConstantHome.data.routing)
        },
        {
          title: ConstantFormulaires.data.title,
          key: 'validator.name',
          route: Url.SLASH_ROUTE.concat(ConstantFormulaires.urlFull
            .FEES.LIST)
        },
        {
          title: ConstantFormulaires.data.sub.lister,
          key: 'lister'
        }
      ]
    };
    if (this.pageData.key === 'formulaire.title') {
      translate.get('newform.title').subscribe(res => this.pageData.title = res);
    }

    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'formulaire.name') {
        translate.get('formulaire.name').subscribe(res => {
          console.log('## res: ', res);
          value.title = res;
        });
      }
      if (value.key === 'lister') {
        translate.get('lister').subscribe(res => value.title = res);
      }

    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    console.log('## init formulaire list');
    super.ngOnInit();
    this.activatedRoute.queryParams.subscribe(d => {
      console.log('## param changed');
      super.setLoaded();
      const res = d['service'];
      if (this.initByQueryParam) {
        console.log('## param: ', res);
        if (!res) {
          this.formulaireService.query(this.formulaireService.list_url)
            .subscribe(r => {
              console.log('## Success get formulaire list: ', r);
              this.countriesList = (r.body) ? r.body : [];
             // this.updateData(this.countriesList);
              this.countriesSearch = this.countriesList;
            });
          this.serviceItemSelected = null;
        }
      }
      this.initByQueryParam = true;
    });


    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.account = account;

          this.formulaireService.query(this.formulaireService.list_url)
            .subscribe(res => {
              console.log('## Success get formulaire list: ', res);
              this.countriesList = (res.body) ? res.body : [];

              // this.updateData(this.countriesList);
              this.countriesSearch = this.countriesList;
              super.setLoaded();

              this.formulaireService.getObjetObs().pipe(takeUntil(this.subs$))
                .subscribe((res: Formulaire[]) => {
                  console.log('## get list formulaire in list by subject: ', res);
                  this.countriesList = (res) ? res : [];
                 // this.updateData(this.countriesList);
                  this.countriesSearch = this.countriesList;
                });

            }, error => {
              console.log('## error get list formulaire: ', error);
            });
            this.getServiceItemList(); // after 500ms.

        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    console.log('## on destroy formulaire list');
    this.formService.free(this.subs$);
    super.ngOnDestroy();
  }

  getServiceItemList() {
    setTimeout(() => {
      this.serviceItemService
        .query()
        .pipe(
          filter((mayBeOk: HttpResponse<IServiceItem[]>) => mayBeOk.ok),
          map((response: HttpResponse<IServiceItem[]>) => response.body)
        )
        .subscribe((res: IServiceItem[]) => {
            console.log('## success get list service item');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.serviceItemsList = rs;
            this.filteredOptions = this.myControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list service item: ' + res.message);
          });
    }, 500);
  }

  private _filter(value: string): IServiceItem[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.serviceItemsList.filter(option => option.name.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

/*  updateData(data: Formulaire[]) {
    data.forEach(d => {
      try {
        d.name = d.serviceItem.name;
      } catch (e) {
        console.log('## erreur: ', e);
      }
    });
  }*/

  changeItem(event) {
    try {
      console.log('## select item');
      const si = event.option.value;
      console.log('## value item si: ' + si);
      console.log('## value item: ' + si.name);
      this.serviceItemSelected = si;
      this.searchServiceItem(si.id);
      this.router.navigate(['/' + ConstantFormulaires.urlFull.FEES.LIST],
        { queryParams: { service: si.name } });
    } catch (e) {
      console.log('## error selected value s item');
    }
  }

  /**
   * Permet de récupérer la liste des formulaires par service.
   * @param id id du service
   */
  searchServiceItem(id: number): void {
    this.formulaireService.listByServiceId(
      this.formulaireService.resourceServiceUrl, id).subscribe(res => {
        console.log('## success get list form: ', res);
        this.countriesList = (res && res.body) ? res.body : [];
        console.log('## service item selected: ', this.serviceItemSelected);
    }, error => {
        console.log('## error get list form: ', error);
    });

    /*const query = this.serviceItemSelected.name;
    const result = this.countriesSearch.filter(obj => {
      return obj.titre === query;
    });
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });*/
  }

  displayFn(subject: IServiceItem) {
    return subject ? subject.name : '';
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.countriesSearch].map(mapFn);
    const result = this.countriesSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length
        ? query.toLowerCase() : '') > -1
    );
    this.countriesList = [];
    result.forEach((c) => {
      this.countriesList.push(c);
    });
  }

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): Formulaire[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

  link() {
    console.log('## open formulaire link');
    this.dialog.open(FormulaireLinkComponent, {
      height: SIZE_H_MD_POPUP,
      width: SIZE_W_MD_POPUP
    });
  }

  add() {
    console.log('## open formulaire add');
    this.dialog.open(FormulaireAddComponent, {
      height: SIZE_H_MD_POPUP,
      width: SIZE_W_MD_POPUP
    });
  }

  update(c) {
    console.log('## open formulaire update');
    this.formulaireService.setObjetSelectObs(c);
    this.baseService.open(this.dialog, FormulaireUpdateComponent);
  }

  delete(c: Formulaire) {
    console.log('## ready to delete: ', c);
    this.formulaireService.setObjetSelectObs(c);
    this.baseService.open(this.dialog, FormulaireDeleteComponent);
  }
}