import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

//import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'src/app/util/request-util';
import { ExchangeRate, IExchangeRate } from 'src/app/model/exchange-rate.model';
import { BASE_URL, BASE_URL_ADMIN } from 'src/environments/environment';
import { ICostsRule } from '../../../model/costs-rule.model';
import { TIME_MIN_REQUEST } from '../../../shared/constant/request.contant';
import { IVersionExchangeRate } from '../../../model/version-exchange-rate.model';

type EntityResponseType = HttpResponse<IExchangeRate>;
type EntityArrayResponseType = HttpResponse<IExchangeRate[]>;

@Injectable({ providedIn: 'root' })
export class ExchangeRateService {

  public resourceListByVersionUrl = BASE_URL_ADMIN + '/exchange-rates/version/';
  public resourceListVersionUrl = BASE_URL_ADMIN + '/exchange-rates-list/version';
  public resourceListUrl = BASE_URL_ADMIN + '/exchange-rates-list';
  public resourceExUsedUrl = BASE_URL_ADMIN + '/exchange-rates/used';

  public resourceManageUrl = BASE_URL_ADMIN + '/exchange-rates';
  public resourceUrl = BASE_URL + 'exchange-rates';

  public resourceUrl2 = BASE_URL + 'exchange-rates/destMoney';
  public resourceUrl3 = BASE_URL + 'exchange-rates/conversion';
  // essential URL structure is built using constants
  public ACCESS_KEY = 'd254a00c5ec3ecef54c408c45650e1ba';
  public BASE_URL = 'https://api.currencylayer.com/';
  public ENDPOINT_LIVE = 'live';
  public ENDPOINT_HISTORICAL = 'historical';
  public resourceExchangeRateLive = this.BASE_URL + this.ENDPOINT_LIVE
    + '?access_key=' + this.ACCESS_KEY;
  public resourceExchangeRateHistorical = this.BASE_URL + this.ENDPOINT_HISTORICAL
    + '?access_key=' + this.ACCESS_KEY + '&date=';

  private _versionExchangeRateSelected: IVersionExchangeRate;

  private exchangeRate = new Subject<IExchangeRate[]>();
  // l'exchange Rate sélectionné dans la liste.
  private exchangeRateSelect = new BehaviorSubject<IExchangeRate>(undefined);

  constructor(protected http: HttpClient) {}


  get versionExchangeRateSelected(): IVersionExchangeRate {
    return this._versionExchangeRateSelected;
  }

  set versionExchangeRateSelected(value: IVersionExchangeRate) {
    this._versionExchangeRateSelected = value;
  }

  /**
   *
   * @param res La liste d'exchange rate à partir d'une api.
   * @return la liste d'exchange rate au format {@link IExchangeRate}
   */
  getExchangeRateList(res: any): IExchangeRate[] {
    const e: any = (res.quotes) ? res.quotes : [];
    console.log('## size: ', e.length);
    let tabExchangeRate = [];
    Object.keys(e).forEach((k: string) => {
      let ex = new ExchangeRate();
      ex.sourceMoney = res.source;
      ex.destMoney = k.slice(3);
      ex.resultExchange = e[k];
      tabExchangeRate.push(ex);
    })
    return tabExchangeRate;
  }

  /**
   * @param exchangeRate données contenant une liste d'exchange rate.
   */
  createListVersion(exchangeRate: any): Observable<EntityResponseType> {
    return this.http.post<IExchangeRate>(this.resourceListVersionUrl, exchangeRate,
      { observe: 'response' });
  }

  /**
   * @param exchangeRate données contenant une liste d'exchange rate.
   */
  createList(exchangeRate: any): Observable<EntityResponseType> {
    return this.http.post<IExchangeRate>(this.resourceListUrl, exchangeRate,
      { observe: 'response' });
  }

  /**
   * Permet de récupérer la liste des exchange rate
   * à partir de la version.
   */
  getAllExchangeRateByVersion(versionId: number) {
    setTimeout(() => {
      this.getListByVersion(versionId).subscribe(res => {
        console.log('## Success get exchange rate list by version');
        this.setExchangeRateObs((res.body) ? res.body : []);
      });
    }, TIME_MIN_REQUEST)
  }

  /**
   * Permet de récupérer la liste des exchange rate
   * dont la version est utilisée.
   */
  getAllExchangeRateUsed() {
    setTimeout(() => {
      this.getListUsed().subscribe(res => {
        console.log('## Success get exchange rate list used');
        this.setExchangeRateObs((res.body) ? res.body : []);
      });
    }, TIME_MIN_REQUEST)
  }

  /**
   * Permet de récupérer la liste des exchange rate
   * pour être partagée.
   */
  getAllExchangeRate() {
    setTimeout(() => {
      this.query().subscribe(res => {
        console.log('## Success get exchange rate list');
        this.setExchangeRateObs((res.body) ? res.body : []);
      });
    }, TIME_MIN_REQUEST)
  }

  getExchangeRateSelectObs() : Observable<IExchangeRate>{
    return this.exchangeRateSelect.asObservable();
  }

  setExchangeRateSelectObs(c: IExchangeRate) {
    this.exchangeRateSelect.next(c);
  }

  getExchangeRateObs() {
    return this.exchangeRate.asObservable();
  }

  setExchangeRateObs(c: IExchangeRate[]) {
    this.exchangeRate.next(c);
  }

  getExchangeRate() {
    return this.http.get<any>(this.resourceExchangeRateLive);
  }

  getExchangeRateHistorical(date) {
    return this.http.get<any>(this.resourceExchangeRateHistorical + date);
  }

  getMatriceToHaveAll() {
    return this.http.get<any>(this.resourceUrl + '/Matrice');
  }
  create(exchangeRate: IExchangeRate): Observable<EntityResponseType> {
    return this.http.post<IExchangeRate>(this.resourceManageUrl, exchangeRate, { observe: 'response' });
  }

  update(exchangeRate: IExchangeRate): Observable<EntityResponseType> {
    return this.http.put<IExchangeRate>(this.resourceManageUrl, exchangeRate, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IExchangeRate>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findByDestMoney(destMoney: string): Observable<EntityResponseType> {
    return this.http.get<IExchangeRate>(`${this.resourceUrl2}/${destMoney}`, { observe: 'response' });
  }

  conversion(destMoney: string, sourceMoney: string, amount: number): Observable<EntityResponseType> {
    return this.http.get<IExchangeRate>(`${this.resourceUrl3}/${destMoney}/${sourceMoney}/${amount}`, { observe: 'response' });
  }

  /**
   * Récupère la liste d'exchange rate par version.
   */
  getListByVersion(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IExchangeRate[]>(this.resourceListByVersionUrl + id, { observe: 'response' });
  }

  /**
   * Récupère la liste d'exchange rate dont la version est utilisée.
   */
  getListUsed(): Observable<EntityArrayResponseType> {
    return this.http.get<IExchangeRate[]>(this.resourceExUsedUrl, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IExchangeRate[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
}
