import { Routes } from '@angular/router';
import { ConstantValidators } from '../validators/validator.url';
import { GuardAdminService } from '../../../../services/guard/authorities/guard-admin.service';
import { ValidatorListComponent } from '../validators/validator-list.component';
import { ConstantChampsFormulaires } from './champs-formulaire.url';
import { ChampsFormulaireListComponent } from './champs-formulaire-list.component';

/**
 * Ensemble des routes pour les costs rule.
 */
export const CHAMPS_FORMULAIRE_ROUTE: Routes = [
  {
    path: ConstantChampsFormulaires.url.FEES.FEES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantChampsFormulaires.url.FEES.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantChampsFormulaires.url.FEES.LIST,
        component: ChampsFormulaireListComponent
      }
    ]
  }
];
