import { Routes } from '@angular/router';
import { ConstantChampsFormulaires } from '../champs_formulaires/champs_formulaires/champs-formulaire.url';
import { GuardAdminService } from '../../../services/guard/authorities/guard-admin.service';
import { ChampsFormulaireListComponent } from '../champs_formulaires/champs_formulaires/champs-formulaire-list.component';
import { StatusLink } from './status.url';
import { StatusListComponent } from './status-list.component';

/**
 * Ensemble des routes pour les status {@link StatusModel}.
 */
export const STATUS_ROUTE: Routes = [
  {
    path: StatusLink.url.FEES.FEES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: StatusLink.url.FEES.LIST,
        pathMatch: 'full'
      },
      {
        path: StatusLink.url.FEES.LIST,
        component: StatusListComponent
      }
    ]
  }
];