import * as PageActions from '../actions/page.actions';
import { IPageData } from '../../interfaces/page-data';

export type Action = PageActions.All;

// Default app state
const defaultData: IPageData = {
  title: '',
  key: '',
  loaded: false,
  breadcrumbs: null,
  fullFilled: false,
};

// Reducer function
export function pageDataReducer(state: any = defaultData, action: Action) {
  switch (action.type) {
    case PageActions.SET: {
      return action.data;
    }
    case PageActions.UPDATE: {
      return { ...state, ...action.data };
    }
    case PageActions.RESET: {
      return defaultData;
    }
    default: {
      return state;
    }
  }
}
