import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { ChampsFormulaireListComponent } from '../champs_formulaires/champs_formulaires/champs-formulaire-list.component';
import { SourceFormAddComponent } from '../champs_formulaires/champs_formulaires/add/source-form/source-form-add.component';
import { FormulaireAddValidatorComponent } from '../champs_formulaires/champs_formulaires/add/validator/formulaire-add-validator.component';
import { ChampsFormulaireAddComponent } from '../champs_formulaires/champs_formulaires/add/champs-formulaire-add.component';
import { ChampsFormulaireUpdateComponent } from '../champs_formulaires/champs_formulaires/update/champs-formulaire-update.component';
import { ChampsFormulaireDeleteComponent } from '../champs_formulaires/champs_formulaires/delete/champs-formulaire-delete.component';
import { DomainListComponent } from './domain-list.component';
import { DomainAddComponent } from './add/domain-add.component';
import { DomainUpdateComponent } from './update/domain-update.component';
import { DomainAffectStatusComponent } from './affect_status/domain-affect-status.component';

/**
 * Ce module permet de gérer le model costRule.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [DomainListComponent, DomainAffectStatusComponent,
        DomainUpdateComponent, DomainAddComponent]
})
export class DomainModule {}
