import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ROUTE_HOME_FULL } from '../../../layout/components/home.route';

@Component({
  selector: 'page-404',
  templateUrl: './page-404.component.html',
  styleUrls: ['../error-base-page/error-base-page.component.scss'],
})
export class Page404Component implements OnInit {
  readonly route_home = ROUTE_HOME_FULL.accueil;
  title: string;
  subTitle: string;
  errorMessage: string;

  constructor(private translation: TranslateService) { }

  ngOnInit() {
    this.translation.get('error404').subscribe(res => this.title=res);
    this.translation.get('subTitle').subscribe(res =>  this.subTitle=res);
    this.translation.get('errorMessagePage').subscribe(res =>  this.errorMessage=res);
    
  }
}
