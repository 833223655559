import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { ICountry } from '../../../../model/country.model';
import { Subject } from 'rxjs';
import { CountryService } from '../../countries/countries.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../../../shared/services/form.service';
import { AccRessTypeColumnName } from '../../acc-ress-type/acc-ress-type-column-name';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { VersionExchangeRateService } from '../version-exchange-rate.service';
import { IVersionExchangeRate } from '../../../../model/version-exchange-rate.model';
import { VExchangeColumnName } from '../vexchange-rate-column-name';

/**
 * Permet de gérer l'ajout d'un country
 */
@Component({
  selector: 'app-version-exch-rate-show',
  templateUrl: './version-exch-rate-show.component.html',
  animations: [fadeIn]
})
export class VersionExchRateShowComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  countrySelected: IVersionExchangeRate;
  private subs$ = new Subject<void>();

  constructor(
    private versionExchangeRateService: VersionExchangeRateService,
    private dialogRef: MatDialogRef<VersionExchRateShowComponent>,
    private formService: FormService,
    private columnName: VExchangeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct country show');
  }

  ngOnInit(): void {
    console.log('## init country shows.');
    this.versionExchangeRateService.getVersionExchangeRateSelectObs()
      .pipe(takeUntil(this.subs$)).subscribe((res: IVersionExchangeRate) => {
      console.log('## get country selected');
      try {
        console.log('## data: ' + JSON.stringify(res));
        this.countrySelected = res;
      }catch (e) {
        console.log('## error get country show: ' + e.message);
      }
      super.setLoaded();
    })
  }

  ngOnDestroy() {
    console.log('## destroy country show');
    this.formService.free(this.subs$);
    this.versionExchangeRateService.setVersionExchangeRateSelectObs(undefined);
  }

  closeModal() {
    console.log('## close show country');
    this.dialogRef.close();
  }
}
