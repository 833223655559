import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANT_LANG_DEFAULT } from 'src/app/app.component';

@Component({
  selector: 'app-choose-language',
  templateUrl: './choose-language.component.html',
  styleUrls: ['./choose-language.component.css']
})
export class ChooseLanguageComponent implements OnInit {
  langues =  [
    {
      idLangue: 1,
      name: 'Fr'
    },
    {
      idLangue: 2,
      name: 'En'
    }      
  ];

  selected='Fr'

  constructor( private translate: TranslateService) { }

  ngOnInit(): void {
  }

  changeValue(value:any){
    console.log(value)
    if(value==='Fr'){
    console.log('## value is: ', value);
    this.translate.setDefaultLang(CONSTANT_LANG_DEFAULT);
    }else{
      console.log('## value is: ', value);
      this.translate.setDefaultLang('en');
    }
  }

}
