import { TypeInputFormModel } from './champs_formulaire/type-input-form.model';
import { IServiceItem } from './service-item.model';
import { LocationType, SourceType, TypeForm } from './champs_formulaire/champs-formulaire.model';

/**
 * Il s'agit des noms de tables de la bd.
 */
export class Domain {
  constructor(
    public id?: number,
    public label?: string
  ) {}
}

export const DOMAIN_BD_CONSTANT = {
  transaction_confirm: 9
}