import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les Exchange Rate.
 */
export namespace ConstantExchangeRate {
  export const data = {
    title: 'Exchange Rate',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un Exchange Rate'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des Exchange Rate'
      }
    }

  }
  export const url = {
    EXCHANGE_RATE: {
      EXCHANGE_RATE_: 'exchange_rate',
      LIST: 'liste',
      ADD: 'add',
      LIVE: 'live',
      HISTORICAL: 'historical'
    }
  };

  export const urlFull = {
    EXCHANGE_RATE: {
      ADD: ConstantExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_
        .concat(Url.SLASH_ROUTE).concat(ConstantExchangeRate.url.EXCHANGE_RATE.ADD),
      LIVE: ConstantExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_
        .concat(Url.SLASH_ROUTE).concat(ConstantExchangeRate.url.EXCHANGE_RATE.LIVE),
      LIST: ConstantExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_
        .concat(Url.SLASH_ROUTE).concat(ConstantExchangeRate.url.EXCHANGE_RATE.LIST),
      HISTORICAL: ConstantExchangeRate.url.EXCHANGE_RATE.EXCHANGE_RATE_
        .concat(Url.SLASH_ROUTE).concat(ConstantExchangeRate.url.EXCHANGE_RATE.HISTORICAL),
    }
  };
}
