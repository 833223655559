import { Injectable } from '@angular/core';

export interface IAccRessTypeColumnName {
  id: string;
  type: string;
}

@Injectable({ providedIn: 'root' })
export class AccRessTypeColumnName {
  getColumnName(): IAccRessTypeColumnName{
    return {
      id:  'id',
      type: 'type'
    }
  }
}
