import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IpAdressService } from '../ip-adress.service';
import { ApiKeyService } from '../api-key.service';
import { CUserApiKeyService } from '../../../../services/ServiceEntity/c-user-api-key.service';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { CountryService } from '../../countries/countries.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { ApiKeyCDTO, IApiKeyCDTO, IApiKeyDTO } from '../../../../model/api-key.model';
import { TIME_MIN_X1_REQUEST, TIME_MIN_X2_REQUEST } from '../../../../shared/constant/request.contant';

/**
 * Permet de gérer l'ajout des api key.
 */
@Component({
  selector: 'app-api-key-update',
  templateUrl: './api-key-update.component.html',
  animations: [fadeIn]
})
export class ApiKeyUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;

  theDialogRef: MatDialogRef<ApiKeyUpdateComponent>;
  editForm: UntypedFormGroup;
  idAccount: number;
  apiKey: IApiKeyDTO;
  private subs$ = new Subject<void>();

  constructor(
    private ipAdressService: IpAdressService,
    private apiKeyService: ApiKeyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domainService: CUserApiKeyService,
    public baseService: BaseService,
    private accountService: AccountService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ApiKeyUpdateComponent>,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private countryService: CountryService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct api key liste');
    this.theDialogRef = this.dialogRef;
    this.apiKey = this.data.apiKey;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init api key add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          const d = this.apiKey;
          console.log('## data: ', d);
          this.editForm.patchValue({
            ['id']: d.id,
            ['label']: d.label
          });
        } catch (e) {
          console.log('## error get api key selected: ' + e.message);
        }
      });
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy api key liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  close() {
    this.dialogRef.close();
  }

  initForm(fb: UntypedFormBuilder) {
    if (fb) {
      this.editForm = fb.group({
        ['label']: ['', []]
      });
    }
  }

  update() {
    console.log('## saving api key');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c = this.createFromForm();
    c.id = this.apiKey.id;
    console.log('## c: ', c);
    this.apiKeyService
      .updateApiKey(c)
      .subscribe(res => {
        console.log('## success liste api key');
        this.successSave = true;
        setTimeout(() => {
          this.apiKeyService.getAllObjetById();
        }, TIME_MIN_X1_REQUEST);
        setTimeout(() => {
          this.isSaving = false;
          this.close();
        }, TIME_MIN_X2_REQUEST);
      }, err => {
        console.log('## error liste api key: ', err);
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
        this.isSaving = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): IApiKeyCDTO {
    return {
      ...new ApiKeyCDTO(),
      label: this.editForm.get('label').value
    };
  }

}