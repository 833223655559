import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { ChampsFormulaireListComponent } from '../champs_formulaires/champs-formulaire-list.component';
import { SourceFormAddComponent } from '../champs_formulaires/add/source-form/source-form-add.component';
import { FormulaireAddValidatorComponent } from '../champs_formulaires/add/validator/formulaire-add-validator.component';
import { ChampsFormulaireAddComponent } from '../champs_formulaires/add/champs-formulaire-add.component';
import { ChampsFormulaireUpdateComponent } from '../champs_formulaires/update/champs-formulaire-update.component';
import { ChampsFormulaireDeleteComponent } from '../champs_formulaires/delete/champs-formulaire-delete.component';
import { SourceFormComponent } from './source-form.component';
import { SourceFormUpdateComponent } from './update/source-form-update.component';

/**
 * Ce module permet de gérer le model costRule.
 */
@NgModule({
    imports: [SharedModule],
    declarations: [SourceFormComponent, SourceFormUpdateComponent]
})
export class SourceFormModule {}
