import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { BASE_URL } from 'src/environments/environment';
import { BasePageComponent } from '../../base-page';
import { Content } from '../../../ui/interfaces/modal';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IOption } from 'src/app/ui/interfaces/option';
import * as PageActions from '../../../store/actions/page.actions';
import { FormService } from '../../../shared/services/form.service';
import { ITransaction } from 'src/app/model/transaction.model';
import { filter, map, takeUntil } from 'rxjs/operators';
import { TransactionsService } from 'src/app/services/ServiceEntity/transactions.service';
import { IMobileAccount, MobileAccountCash } from 'src/app/model/mobile-account.model';
import { AccountUserService } from 'src/app/services/ServiceEntity/account-user.service';
import { Account, STATUS } from 'src/app/model/account.model';
import { AccountService } from 'src/app/services/ServiceEntity/account.service';
import { Subject } from 'rxjs';
import { CONSTANT_ICON } from 'src/app/shared/constant/icon.constant';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ShowTransactionComponent } from './show-transaction/show-transaction.component';
import { CodeService } from 'src/app/services/ServiceEntity/code.service';
import { Code, ICode } from 'src/app/model/code.model';
import { AccountRessourceService } from '../account-ressource/acc-ress.service';
import { ServiceItemService } from '../service-items/service-item.service';
import { CUserService } from 'src/app/services/ServiceEntity/c-user.service';
import { HOME_ROUTING } from '../home/home.url';
import { ColorService } from '../../../shared/services/color.service';
import { TranslateService } from '@ngx-translate/core';
import { AccRessListComponent } from '../account-ressource/list/acc-ress-list.component';
import { BTN_POPUP_CLASS, BTN_VALIDATION_CLASS, CONTAINER_DIALOG_POPUP } from '../../../shared/constant/css-style/css-class.constant';
import { AccRessListByUserComponent } from '../account-ressource/list-by-user/acc-ress-list-by-user.component';
import { CuserDetailManageModalComponent } from '../cuser-detail-manage/add/cuser-detail-manage-modal.component';
import { SIZE_MEDIUM_POPUP, SIZE_MIN_POPUP } from '../../../shared/constant/css-style/css-size.constant';
import { DisplayValue } from '../../../model/acc_ress_type.model';
import { ServiceItemCompose, ServiceItemFils } from '../../../model/service-item.model';
import { ID_SERVICE_ITEM_TRANSFER_COMPTE_SELF } from '../../../shared/constant/service-item.constant';
import { CashDataModel, IRetraitCode } from '../cash/cash-data-model';
import { DynamicFormComponent } from '../cash/dynamic-form/dynamic-form.component';


@Component({
  selector: 'app-view-account',
  templateUrl: './view-account.component.html',
  styleUrls: ['./view-account.component.css']
})
export class ViewAccountComponent extends BasePageComponent implements OnInit, OnDestroy {

  readonly ROLE_PARTENER = 'ROLE_PARTNER';
  readonly ROLE_ADMIN = 'ROLE_ADMIN';
  readonly ROLE_NONE = 'NONE'; // pas de role.
  //Current User
  CurentUser: any;
  CurrentUserAuth: any;
  //account
  account: Account | null = null;
  //
  currentCuser: any;
  currentCUserId: any;
  currentCuserTab = [];
  cuserAllowedItems: any;
  allServicesItems: any;
  serviceItemToUpdate: any;
  cUserMobileAccount: any;
  CuserCurrentAccountRessource: any;
  //select var
  AccountRessourceList: IOption[];
  AccountRessource: any;
  //Active Add Cash
  ActiveCashSide = false;
  //Cash value
  cashValue: number = 0;
  //wallet show
  walletShow = false;
  //transaction
  displayTransactions: boolean;
  actRessStatus: boolean;
  transactions: ITransaction[];
  //mobile account
  mAccounts: IMobileAccount[];
  accountsCash: MobileAccountCash[];

  show: string;
  notShow: string;

  numberAccountSelected: string;

  private subs$ = new Subject<void>();

  currentCode: Code;

  serviceItemComposeList: ServiceItemCompose[] = [];

  serviceItemCompose: ServiceItemCompose;

  constructor(store: Store<IAppState>, httpSv: HttpService,
              private serviceItemService: ServiceItemService,
              private colorService: ColorService,
              private formService: FormService,
              protected activatedRoute: ActivatedRoute,
              private modal: TCModalService,
              private http: HttpClient,
              private transactionService: TransactionsService,
              protected mAccountService: AccountUserService,
              private accountService: AccountService,
              private accountRessourceService: AccountRessourceService,
              public dialog: MatDialog,
              private codeService: CodeService,
              private serviceItem: ServiceItemService,
              private cUserService: CUserService,
              private accountRessService: AccountRessourceService,
              private router: Router,
              private translate : TranslateService) {
    super(store, httpSv);
    // this.currentCUserId = this.activatedRoute.snapshot.params.id;
    this.pageData = {
      title: '',
      key: 'account',
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: HOME_ROUTING
        },
        {
          title: '',
          key: 'accountUser'
        }
      ]
    };

    if(this.pageData.key==="account"){
      translate.get('account').subscribe(res => this.pageData.title=res);
    }
    this.pageData.breadcrumbs.forEach(value=> {
      if(value.key==="home"){
        translate.get('home').subscribe(res => value.title=res);
      }
      if( value.key==="accountUser" ){
        translate.get('accountUser').subscribe(res => value.title=res);
      }    
     
    });
    // this.initAccountRessourceList();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          this.account = account;
          console.log("## account: ", this.account);
          this.getCurrentUserID(account);
          console.log('## try to execute subject');
          this.getAllMobileAccountBySubject();

          console.log('## récupération services et formulaires');
          // récupération des services et formulaires
         this.getAllServicesAndForms();

          this.show = this.getIcon().show;
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  executeService(numberAccountSelected: string) {
    console.log('show form');
    const s = this.serviceItemCompose;
    this.numberAccountSelected = numberAccountSelected;

    console.log('## numberAccountSelected:', numberAccountSelected);

    this.openTemplate(s.filsList, {idServiceItem: s.parent.serviceId,
      serviceItemId: s.parent.serviceItemId}, s.parent.name);
  }

  /**
   * Permet de récupérer tous les services et formulaires
   */
  getAllServicesAndForms() {
    // récupération des services
    this.serviceItemService.findAllActiveServicesAccounts().subscribe(res => {
      console.log('## success get all services composes : ', res);
      const data = res.body;
      for (let d of data) {
        if (d.parent.serviceId && (d.parent.display === DisplayValue.ONE) &&
            (d.parent.serviceId === ID_SERVICE_ITEM_TRANSFER_COMPTE_SELF)
        ) {
          this.serviceItemCompose = d;
          console.log('## service trouve:', d);
          break;
        }
      }
    }, err => {
      console.log('## error get all services actifs: ', err);
    });
  }

  /**
   * Ouvre le component d'envoie code.
   * @param r informations du service parent.
   * @param fs liste des services fils du parent
   * @param serviceName
   */
  openTemplate(fs: ServiceItemFils[], r?: IRetraitCode, serviceName?: string) {
    console.log('## fils: ', fs);
    console.log('## parent: ', r.idServiceItem);
    let d = new CashDataModel();
    d.dataCode.component = DynamicFormComponent;
    let idServiceItem;
    let serviceItemId;
    let f = fs.slice();
    // on vérifie si le parent n'a pas de fils.
    if (typeof f === 'undefined' || f.length <= 0) {
      idServiceItem = r.idServiceItem;
      serviceItemId = r.serviceItemId;
    } else {
      const f1 = f[0];
      idServiceItem = f1.serviceId;
      serviceItemId = f1.serviceItemId;
    }
    d.dataCode.idServiceItem = idServiceItem;
    d.dataCode.serviceItemId = serviceItemId;
    d.dataCode.serviceName = serviceName;
    d.dataCode.serviceItemFilsList = f;
    d.dataCode.serviceItemIdInter = serviceItemId;
    this.dialog.open(DynamicFormComponent, {
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP,
      data: {
        others: r,
        cashDataModel: d,
        numberAccountSelected: this.numberAccountSelected
      }
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    /*  dialogConfig.disableClose = true;
     dialogConfig.autoFocus = true;   */
    console.log('## mAccounts: ', this.accountsCash);
    this.dialog.open(CuserDetailManageModalComponent, {
      data: { cUserId: this.currentCUserId,
        mAccounts: this.accountsCash },
      minWidth: SIZE_MIN_POPUP,
      minHeight: SIZE_MEDIUM_POPUP,
      panelClass: CONTAINER_DIALOG_POPUP
    });
  }

  getCurrentUserID(account: Account) {
    this.http.get(`${BASE_URL}c-user/userLogin/${(account.login)}`).subscribe((val) => {
      this.currentCUserId = val['id'];
      console.log('roleeeeeeee', this.account.authorities);
      this.actRessStatus = false;
      this.getCurrentUser(account);
      this.getCurrentCuser();
     // this.getCurrentMobileAccount();
      this.loadAllowedItems();
      this.loadAll();
    }, err => {
      console.log(err);
    });
  }

  getSingleBtnClass(): string {
    return BTN_POPUP_CLASS;
  }

  getBtnValidationClass(): string {
    return BTN_VALIDATION_CLASS;
  }

  toggleAmount(inputKey: any, buttonKey: any) {
    console.log('button ##', buttonKey);
    if (inputKey.type === 'password') {
      inputKey.type = 'text';
      buttonKey.beforeIcon = this.getIcon().notShow;
    } else {
      inputKey.type = 'password';
      buttonKey.beforeIcon = this.getIcon().show;
    }
  }

  getAmount(m: number, money: string) {
    return money ? this.format(m).concat(' ').concat(money) :
    '';
  }

  getIcon() {
    return CONSTANT_ICON;
  }

  getAmountColBg() {
    return this.colorService.getAmountColBg();
  }

  getTheadBg() {
    return this.colorService.getTheadBg();
  }

  viewTransaction(data) {
    console.log('dataaaaaaaa', data);
    this.codeService
      .findByReceiverId(data.receiverId)
      .pipe(
        filter((reslt: HttpResponse<ICode>) => reslt.ok),
        map((reslt: HttpResponse<ICode>) => reslt.body)
      )
      .subscribe((reslt: ICode) => {
        this.currentCode = reslt;

      });
    const dialogConfig = new MatDialogConfig();

    this.dialog.open(ShowTransactionComponent, {
      data: { transaction: data, code: this.currentCode }, height: '600px',
      width: '700px'
    });

  }

  getCurrentUser(response: Account) {
    this.CurentUser = response;
    if (response['authorities'][1]) {
      this.CurrentUserAuth = response['authorities'][1];
    } else {
      this.CurrentUserAuth = response['authorities'][0];
    }
    console.log('Current User', this.CurentUser);
   // this.currentCUserId = this.CurentUser.id;
  }

  format(n: number) {
    return this.formService.format(n);
  }

  initActiveCashSide() {
    this.ActiveCashSide = false;
  }

  initAccountRessourceList() {
    this.AccountRessourceList = [];
  }


  /*getAccountRessourceList() {
    this.getData(`${BASE_URL}account-ress-type`, 'AccountRessource', 'initAccountRessource');
  }*/

  initAccountRessource() {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );
    setTimeout(() => {
      //console.log("Account Ressource",this.AccountRessource);
      this.AccountRessource.forEach(element => {
        //console.log("element",element);
        this.AccountRessourceList.push({ 'label': element.type, 'value': element.id });
      });
    });
  }

  getCurrentCuser() {
    this.getData(`${BASE_URL}c-users/${this.currentCUserId}`, 'currentCuser',
      'initcurrentCUserId');

    // ADD.
    console.log('## current user: ', this.currentCuser);
  }

  initcurrentCUserId() {
    setTimeout(
      () => this.store.dispatch(new PageActions.Update({ loaded: true })),
      0
    );
    setTimeout(() => {
      //console.log("Account Ressource",this.AccountRessource);
      this.currentCuserTab.push(this?.currentCuser);
    });
  }

  getCurrentMobileAccount() {
    this.mAccountService.findAllAccountsByCUserId(this.currentCUserId).subscribe((val) => {
      this.cUserMobileAccount = val['0'];
      console.log("## cusermobileaccount: ", this.cUserMobileAccount);
      //console.log(this.cUserMobileAccount);
    }, err => {
      console.log(err);
    });
  }

  getCurrentAccountRessource(modalWalletBody, titleModal, m: IMobileAccount) {
    this.accountRessourceService.find(m.id).subscribe((val) => {
      console.log('valllllllllllllllllllllllllll', val);
      this.CuserCurrentAccountRessource = val.body;

      this.store.dispatch(new PageActions.Update({ loaded: true }));
      //this.router.navigate(['/vertical/c-user/cuserdetailmanage',this.currentCUserId]);
      this.openModal(modalWalletBody, titleModal);
    }, err => {
      console.log(err);
    });
  }

  //For reload the Account Ressouce Table (On modal)
  reloadCurrentAccountRessource() {
    this.getData(`${BASE_URL}account-ressource/${this.currentCUserId}`,
      'CuserCurrentAccountRessource', 'setLoaded');
  }

  getAllowedItems() {
    this.getData(`${BASE_URL}c-users-service-item/${this.currentCUserId}`,
      'cuserAllowedItems', 'setLoaded');
  }

  getAllServicesItems(id: any): any {
    this.serviceItem.find(id).subscribe((val) => {
      return val['name'];
    }, err => {
      console.log('ERROR while getting item', err);
    });
    //this.getData(`${baseUrl}service-item/${id}`, 'allServicesItems', 'setLoaded');
  }

  loadAllowedItems() {
    this.getAllowedItems();
  }

  ActiveServiceItem(data: any) {
    //this.getData(`${baseUrl}c-users-service-item/${data}`, 'serviceItemToUpdate', 'setLoaded');
    data.status = 'ACTIVE';
    //console.log(data);
    this.cUserService.statutServiceItem(data).subscribe((rep) => {
      //console.log("SUCCESS",rep);
      this.loadAllowedItems();
    }, err => {
      console.log('ERROR while UPDATING', err);
    });
  }

  DesactiveServiceItem(data: any) {
    data.status = 'ACTIVED';
    //console.log(data);
    this.cUserService.statutServiceItem(data).subscribe((rep) => {
      //console.log("SUCCESS",rep);
      this.loadAllowedItems();
    }, err => {
      console.log('ERROR while UPDATING', err);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.formService.free(this.subs$);
  }

  /**
   * Find all account ressource
   * @param id the id of the account user.
   */
  /*getAccountRessourceList(id: number) {
    this.accountRessourceService.findAllAccountRessourceByMAccountId(id)
      .subscribe((res) => {
        this.getResult(res);
      }, err => {
        console.log('## error get account ressource list: ' + err.message);
      });
  }
*/
  showApiKeys(m: MobileAccountCash) {
    console.log('## show api keys: ', m);
    this.router.navigate(['/api-key'],
      { queryParams: {
        'account': m.id},
          state: { mobileAccount: m}});
  }

  showM(m: MobileAccountCash) {
    console.log('## open account ressource list');

    if(this.account.authorities.indexOf(this.ROLE_ADMIN) !== -1) {
      try {
        console.log('## ' + m.id);
      } catch (e) {
        console.log('## error mobile account: ' + e.message);
      }
      this.dialog.open(AccRessListComponent, {
        data: m,
        height: '700px',
        width: '800px'
      });
    } else {
      try {
        console.log('## ' + m.id);
      } catch (e) {
        console.log('## error mobile account: ' + e.message);
      }
      this.dialog.open(AccRessListByUserComponent, {
        data: m,
        height: '700px',
        width: '800px'
      });
    }
  }

  openModal<T>(body: Content<T>, header: Content<T> = null,
               footer: Content<T> = null, options: any = null) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options,
      theClass: 'account'
    });
  }

  getStatusBackground(status: number) {
    return this.colorService.getStatusBg(status);
  }

  getActivedLabel() {
    return STATUS.actived;
  }

  getDeactivedLabel() {
    return STATUS.deactived;
  }

  getFailedLabel() {
    return STATUS.failed;
  }

  closeModal() {
    this.modal.close();
  }

  activeCashSide(/* data:any */) {
    this.ActiveCashSide = true;
  }

  addCash(data: any) {
    this.ActiveCashSide = false;
    data.amount = Number(data.amount) + Number(this.cashValue);
    //console.log("### Data cash", data);
    //console.log("### Current User", this.CurrentUserAuth);
    const dataForPartener = {
      'amount': data.amount,
      'id': data.id,
      'status': data.status
    };
    if (this.CurrentUserAuth == 'ROLE_ADMIN') {
      this.accountRessService.update(data).subscribe((rep) => {
        this.cashValue = 0;
        console.log('### Adding cash by ADMIN');
      }, err => {
        console.log('ERROR while adding cash', err);
      });
    } else if (this.CurrentUserAuth == 'ROLE_PARTNER') {
      this.accountRessService.updateByPartener(dataForPartener).subscribe((rep) => {
        this.cashValue = 0;
        console.log('### Adding cash by PARTENER');
      }, err => {
        console.log('ERROR while adding cash', err);
      });
    }
  }

  activedAR(data: any) {
    data.status = 'ACTIVED';
    //console.log(data);
    this.accountRessService.update(data).subscribe((rep) => {
      //console.log("SUCCESS",rep);
      //this.loadAllowedItems();
    }, err => {
      console.log('ERROR while ACTIVED', err);
    });
  }

  desactivedAR(data: any) {
    data.status = 'DEACTIVED';
    //console.log(data);
    this.accountRessService.update(data).subscribe((rep) => {
      //console.log("SUCCESS",rep);
      //this.loadAllowedItems();
    }, err => {
      console.log('ERROR while DESACTIVED', err);
    });
  }


  getAllTransactions(numberAccount: string) {
    this.displayTransactions = true;
    this.actRessStatus = false;
    this.transactionService
      .findAllTransactionsByaccountNumber(numberAccount)
      .pipe(
        filter((res: HttpResponse<ITransaction[]>) => res.ok),
        map((res: HttpResponse<ITransaction[]>) => res.body)
      )
      .subscribe((res: ITransaction[]) => {
        this.transactions = res;
        console.log('transactionssssssss', this.transactions);
      });
  }

  AdminAuthorisation() {
    for (let _i = 0; _i < this.account.authorities.length; _i++) {
      if (this.account.authorities[_i] === 'ROLE_ADMIN') {
        return true;
      }
    }
    return false;
  }

  //all mobile account user
  loadAll() {
    // ADD
    this.mAccountService.findAllCash()
      .subscribe((res: HttpResponse<MobileAccountCash[]>) => {
        console.log('## success find all account with cash');
        const d = (res && res.body) ? res.body : [];
        console.log("## data: ", d);
        console.log("## length: ", d.length);
        this.accountsCash = d;
    }, error => {
        console.log('## Error find all account with cash: ', error);
      });

/*    this.mAccountService
      .findAllAccountsByCUserId(this.currentCUserId)
      .pipe(
        filter((res: HttpResponse<IMobileAccount[]>) => res.ok),
        map((res: HttpResponse<IMobileAccount[]>) => res.body)
      )
      .subscribe((res: IMobileAccount[]) => {
        this.mAccounts = res;
        this.accountsCash = res;
        console.log('all mobile account: ', this.mAccounts);
      });*/
  }

  getAllMobileAccountBySubject() {
        this.mAccountService.getMAccountCashObs().pipe(takeUntil(this.subs$))
          .subscribe((res: IMobileAccount[]) => {
            console.log('## get list mobile account in list by subject:', res);
            this.mAccounts = (res) ? res : [];
            this.accountsCash = this.mAccounts;
            console.log('---------', this.mAccounts);
          });
  }

  previousState() {
    window.history.back();
  }

  getAuthoritie(account: Account) {
    if (account) {
      if (account.authorities.indexOf(this.ROLE_ADMIN) !== -1) {
        return this.ROLE_ADMIN;
      } else if (account.authorities.indexOf(this.ROLE_PARTENER) !== -1) {
        return this.ROLE_PARTENER;
      }
    }
    return this.ROLE_NONE;
  }
}
