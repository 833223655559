import { Url } from '../../../shared/constant/url.constant';

/**
 * Route utilisées pour les monies.
 */
export namespace ConstantMonies {
  export const data = {
    title: 'Monies',
    sub: {
      lister: 'Lister',
      ajouter: 'Ajouter',
      ajout: {
        title: 'Ajout d\'un money'
      },
      liste: 'Liste',
      list: {
        title: 'Liste des monies'
      }
    }

  }
  export const url = {
    MONIES: {
      MONIES_: 'monies',
      LIST: 'liste',
      ADD: 'add'
    }
  };

  export const urlFull = {
    MONIES: {
      ADD: ConstantMonies.url.MONIES.MONIES_
        .concat(Url.SLASH_ROUTE).concat(ConstantMonies.url.MONIES.ADD),
      LIST: ConstantMonies.url.MONIES.MONIES_
        .concat(Url.SLASH_ROUTE).concat(ConstantMonies.url.MONIES.LIST),
    }
  };
}
