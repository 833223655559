import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeIn } from '../../../animations/form-error';
import { BasePageComponent } from '../../base-page';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FormService } from '../../../shared/services/form.service';
import { AccountService } from '../../../services/ServiceEntity/account.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { HOME_ROUTING } from '../home/home.url';
import { NOTIFICATION_ROUTING } from './notification.url';
import { NotificationService } from './notification.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { Account } from '../../../model/account.model';
import { INotificationDTO } from '../../../model/notification.model';
import { BaseService } from '../base/base.service';
import { ColorService } from '../../../shared/services/color.service';
import { NotificationConfirmComponent } from './confirm/notification-confirm.component';
import { RejectTransactionComponent } from './reject/reject-transaction.component';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { IMobileAccount } from '../../../model/mobile-account.model';
import { TIME_MIN_X2_REQUEST } from '../../../shared/constant/request.contant';
import { StatusService } from '../status/status.service';
import { STATUS_BD_CONSTANT, StatusDomainModel } from '../../../model/status.model';
import { DOMAIN_BD_CONSTANT } from '../../../model/domain.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

/**
 * Permet l'affichage des notification des transactions.
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  animations: [fadeIn]
})
export class NotificationComponent extends BasePageComponent implements OnInit, OnDestroy {

  notificationsList: INotificationDTO[];
  notificationsSearch: INotificationDTO[];
  account: Account; // the account connected.

  statusDomainList: StatusDomainModel[]
  searchValue: string;

  @ViewChild('basicTable') tabRef: any;
  correlationIdSelect: string;

  /* Contrôleur de l'input (auto complete) des notifications */
  versionControl = new UntypedFormControl();

  filteredOptions: Observable<StatusDomainModel[]>;

  private subs$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private colorService: ColorService,
    public baseService: BaseService,
    private formService: FormService,
    private accountService: AccountService,
    private statusService: StatusService,
    private notificationService: NotificationService,
    store: Store<IAppState>,
    httpSv: HttpService,
    private modal: TCModalService,
    private translate: TranslateService) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      key: 'notification',
      breadcrumbs: [
        {
          title: '',
          key: 'home',
          route: HOME_ROUTING
        },
        {
          title: '',
          key: 'notification',
          route: NOTIFICATION_ROUTING
        }
      ]
    };
    if (this.pageData.key === 'notification') {
      translate.get('notification').subscribe(res => this.pageData.title = res);
    }
    this.pageData.breadcrumbs.forEach(value => {
      if (value.key === 'home') {
        translate.get('home').subscribe(res => value.title = res);
      }
      if (value.key === 'notification') {
        translate.get('notification').subscribe(res => value.title = res);
      }
    });
    // récupération de la correlation id
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.correlationIdSelect = params['id'];
        console.log('## correlationId: ', this.correlationIdSelect);
        let p = new HttpParams();
        p = p.set('corrId', this.correlationIdSelect);

        if(p.get('corrId') !== null) {
          this.notificationService.getNotification(p)
            .subscribe(res => {
              console.log('## Success get notification: ', res);
              const notif = (res.body) ? res.body : null;
              if(notif) {
                this.notificationsList = [];
                this.notificationsList.push(notif);
                this.notificationsSearch = this.notificationsList;
                this.loadAllStatus();
              } else {
                this.notificationsList = [];
                this.notificationsSearch = this.notificationsList;
                this.loadAllStatus();
              }
            });
        } else {
          this.notificationsList = [];
          this.notificationsSearch = this.notificationsList;
        }

      }
    );
  }

  ngOnInit(): void {
    console.log('## init notification..');
    super.ngOnInit();

    this.accountService.identity(false).pipe(takeUntil(this.subs$))
      .subscribe(account => {
        try {
          super.setLoaded();
          this.account = account;
          // on vérifie si l'utilisateur n'a pas sélectionné de correlationId.
          if(!this.correlationIdSelect) {
            // on affiche toutes les notifications.
            this.listAllNotification();
          }

          // on récupère la liste des notifications et des status
          this.notificationService.getObjetObs().pipe(takeUntil(this.subs$))
            .subscribe((res: INotificationDTO[]) => {
              console.log('## get list notification by subject: ', res);
              this.notificationsList = (res) ? res : [];
              this.notificationsSearch = this.notificationsList;
              this.loadAllStatus();
            });
        } catch (e) {
          console.log('## error identity:', e.message);
        }
      });
  }

  ngOnDestroy() {
    this.formService.free(this.subs$);
  }

  listAllNotification() {
    this.notificationService.list()
      .subscribe(res => {
        console.log('## Success get notification list: ', res);
        this.notificationsList = (res && res.body) ? res.body : [];
        this.notificationsSearch = this.notificationsList;
        // on récupère tous les status
        this.loadAllStatus();
        super.setLoaded();
      });
  }

  isPending(d: INotificationDTO) {
    return (d && d.statusId === STATUS_BD_CONSTANT.pending)
  }

  /**
   * Permet de récupérer la liste des notifications
   * à partir du status.
   * @param n id du status
   */
  getNotificationByStatus(n: number) {
    console.log('## n: ', n);
    if(n) {
      let p = new HttpParams();
      p = p.set('status', n.toString());
      this.notificationService.getNotificationByStatus(p)
        .subscribe(res => {
          console.log('## success get notification by status: ', res);
          this.notificationsList = (res && res.body) ? res.body : [];
          this.notificationsSearch = this.notificationsList;
          console.log('## nombre: ', this.notificationsList.length);
        });
    } else {
      this.listAllNotification();
    }

  }

  displayFnVersion(subject: StatusDomainModel) {
    return (subject && subject.id) ? subject.label + ' - ' + subject?.id
      : subject.label;
  }

  /**
   * Récupère toutes les api keys associés à un account
   * @param m
   */
  getAllNotificationByStatusSelected(e: MatAutocompleteSelectedEvent) {
    const m = e.option.value as StatusDomainModel;
    console.log('## selected status: ', m);
    this.getNotificationByStatus(m.id);
    // this.getAllNotifications(m);
  }

  /**
   * Récupère la liste des mobiles account et
   * les initialise dans un auto complete.
   */
  loadAllStatus() {
    setTimeout(() => {
      let p = new HttpParams();
      p = p.set('domainId', DOMAIN_BD_CONSTANT.transaction_confirm.toString());

      this.statusService.listAllByDomain(p)
        .subscribe((res: HttpResponse<StatusDomainModel[]>) => {
          this.statusDomainList = (res && res.body) ? res.body : [];
          let sd = new StatusDomainModel();
          sd.label = 'TOUT';
          this.statusDomainList.splice(0, 0, sd);
          console.log('success get all status:', this.statusDomainList);
          console.log('length:', this.statusDomainList.length);
          this.filteredOptions = this.versionControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        });
    }, TIME_MIN_X2_REQUEST);
  }

  private _filter(value: any): StatusDomainModel[] {
    let filteredList;
    try {
      console.log('## value version: ', value);
      const filterValue = value.toLowerCase();
      filteredList = this.statusDomainList.filter(option => option.label.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter status: ' + e.message);
    }
    return filteredList;
  }

 /* displayFnVersion(subject: INotificationDTO) {
    return subject ? subject.statusLabel + ' - ' + subject.preferenceCurrency : '';
  }*/

  confirm(n: INotificationDTO) {
    console.log('## confirm: ', n);
    this.dialog.open(NotificationConfirmComponent, {
      data: {
        notification: n
      }, height: '600px', width: '800px'
    });
    // this.baseService.open(this.dialog, NotificationConfirmComponent);
  }

  reject(n: INotificationDTO) {
    console.log('## reject: ', n);
    this.dialog.open(RejectTransactionComponent, {
      data: {
        notification: n
      }, height: '500px', width: '800px'
    });
  }

  test(): void {
    console.log('test');
  }

  search(): void {
    const query = this.searchValue;
    console.log('## search: ' + query);
    const mapFn = (item) =>
      Object.values(item)
        .map(function(val) {
          return val?.toString().toLowerCase();
        })
        .join(' ');
    const stringArr = [...this.notificationsSearch].map(mapFn);
    const result = this.notificationsSearch.filter(
      (_, i) => stringArr[i].indexOf(query && query.length
        ? query.toLowerCase() : '') > -1
    );
    this.notificationsList = [];
    result.forEach((c) => {
      this.notificationsList.push(c);
    });
  }

  format(n: number) {
    return this.formService.format(n);
  }

  getStatusBg(id: number): string {
    return this.colorService.getStatusBg(id);
  }

  /**
   * Retourne la liste des des country
   * qui ont été stockés dans le selector 'nz-table'.
   */
  getList(): INotificationDTO[] {
    return (this.tabRef) ? this.tabRef.data : [];
  }

}