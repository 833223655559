import { Injectable } from '@angular/core';
import { BASE_URL } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IAccountRessType } from '../../../model/acc_ress_type.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { createRequestOption } from '../../../util/request-util';
import { ICountry } from '../../../model/country.model';


type EntityResponseType = HttpResponse<ICountry>;
@Injectable({ providedIn: 'root' })
export class CountryService {
  public resourceUrl = BASE_URL + 'countries';
  private country = new Subject<ICountry[]>();

  // le country sélectionné dans la liste.
  private countrySelect = new BehaviorSubject<ICountry>(undefined);

  constructor(protected http: HttpClient) {}

  getCountrySelectObs() : Observable<ICountry>{
    return this.countrySelect.asObservable();
  }

  setCountrySelectObs(c: ICountry) {
    this.countrySelect.next(c);
  }

  getCountryObs() {
    return this.country.asObservable();
  }

  setCountryObs(c: ICountry[]) {
    this.country.next(c);
  }

  /**
   * Permet de récupérer la liste des country
   * pour être partagée.
   */
  getAllCountry() {
    setTimeout(() => {
      this.query().subscribe(res => {
        console.log('## Success get country list');
        this.setCountryObs((res.body) ? res.body : []);
      });
    }, 300)
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`,
      { observe: 'response' });
  }

  update(country: ICountry): Observable<HttpResponse<ICountry>> {
    return this.http.put<ICountry>(this.resourceUrl, country,
      { observe: 'response' });
  }

  create(country: ICountry): Observable<HttpResponse<ICountry>> {
    return this.http.post<ICountry>(this.resourceUrl, country,
      { observe: 'response' });
  }
  
  findByLabel(label: string): Observable<EntityResponseType> {
    return this.http.get<ICountry>(`${this.resourceUrl}/${label}`, { observe: 'response' });
  }
  /**
   * Permet de récupérer la liste des countries.
   * @param req
   */
  query(req?: any): Observable<HttpResponse<ICountry[]>> {
    const options = createRequestOption(req);
    return this.http.get<ICountry[]>(this.resourceUrl,
      { params: options, observe: 'response' });
  }

}
