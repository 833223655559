import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../../animations/form-error';
import { BasePageComponent } from '../../../../base-page';
import { Account } from '../../../../../model/account.model';
import { IExchangeRate } from '../../../../../model/exchange-rate.model';
import { IVExchangeColumnName, VExchangeColumnName } from '../../../version-exch-rate/vexchange-rate-column-name';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IVersionExchangeRate, VersionExchangeRate } from '../../../../../model/version-exchange-rate.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../../../../services/ServiceEntity/account.service';
import { ExchangeRateService } from '../../exchange-rate.service';
import { VersionExchangeRateService } from '../../../version-exch-rate/version-exchange-rate.service';
import { FormService } from '../../../../../shared/services/form.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { HttpService } from '../../../../../services/http/http.service';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TIME_MIN_REQUEST } from '../../../../../shared/constant/request.contant';
import { MatRadioChange } from '@angular/material/radio';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../../shared/constant/css-style/css-class.constant';

/**
 * Permet de gérer l'ajout de version des exchange rate.
 */
@Component({
  selector: 'app-exchange-rate-historical-add',
  templateUrl: './exchange-rate-historical-add.component.html',
  animations: [fadeIn]
})
export class ExchangeRateHistoricalAddComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  account: Account; // The account connected.
  existBtnSelected = false;

  dateTyped: any;

  radioValueNouVersion = 'nouv';
  radioValueOldVersion = 'old';

  exchangeRateList: IExchangeRate[] = [];
  column: IVExchangeColumnName;
  private subs$ = new Subject<void>();

  isSaving = false;
  successSave = false;
  serverHasError = false;
  editForm: UntypedFormGroup;

  // service item.
  versionExchangeRateList: IVersionExchangeRate[] = [];
  filteredOptions: Observable<IVersionExchangeRate[]>;

  theDialogRef: MatDialogRef<ExchangeRateHistoricalAddComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {name: string},
    private accountService: AccountService,
    protected exchangeRateService: ExchangeRateService,
    protected versionExchangeRateService: VersionExchangeRateService,
    private dialogRef: MatDialogRef<ExchangeRateHistoricalAddComponent>,
    private formService: FormService,
    private columnName: VExchangeColumnName,
    private fb: UntypedFormBuilder,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct exchange rate historical add');
    this.theDialogRef = this.dialogRef;
    this.column = this.columnName.getColumnName();
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init exchange rate historical add');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.account = account;
        console.log('## data: ', this.data)
        this.dateTyped = this.data;
        /* récupère la liste des version d'exchange rate
        n'ayant pas encore été affectée */
        this.getVersionExchangeRateList();
        // récupère la liste des exchange rate à jour. (api historical)
        this.exchangeRateService.getExchangeRateHistorical(this.dateTyped).subscribe(res => {
          console.log('## success get exchange rate live: ', res);
          this.exchangeRateList = this.exchangeRateService.getExchangeRateList(res);
        });
      });
  }

  ngOnDestroy() {
    console.log('## destroy exchange rate add');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  isVersionFound() {
    return this.versionExchangeRateList.length > 0
  }

  getVersionExchangeRateList() {
    setTimeout(() => {
      // SERVICE ITEM
      this.versionExchangeRateService
        .getList()
        .pipe(
          filter((mayBeOk: HttpResponse<IVersionExchangeRate[]>) => mayBeOk.ok),
          map((response: HttpResponse<IVersionExchangeRate[]>) => response.body)
        )
        .subscribe((res: IVersionExchangeRate[]) => {
            console.log('## success get list service item while adding.');
            const rs = res ? res : [];
            console.log('## size: ' + rs.length);
            this.versionExchangeRateList = rs;
            this.filteredOptions = this.editForm.get(this.column.version).valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          },
          (res: HttpErrorResponse) => {
            console.log('## error get list service item while adding: ' + res.message);
          });
    }, TIME_MIN_REQUEST);
  }

  private _filter(value: string): IVersionExchangeRate[] {
    let filteredList;
    try {
      const filterValue = value.toLowerCase();
      filteredList = this.versionExchangeRateList.filter(option => option.version.toLowerCase()
        .includes(filterValue));
    } catch (e) {
      console.log('## error filter service item: ' + e.message);
    }
    return filteredList;
  }

  displayFnServiceItem(subject: IVersionExchangeRate) {
    return subject ? subject.version : '';
  }

  showForm(e: MatRadioChange) {
    console.log('## show form');
    console.log('## e: ', e);
    const v = e.value;
    if (v === this.radioValueOldVersion) {
      this.existBtnSelected = true;
    } else {
      this.existBtnSelected = false;
    }
  }

  initForm(fb: UntypedFormBuilder) {
    try {
      this.editForm = this.fb.group({
        id: [],
        version: [this.radioValueNouVersion, [Validators.required]],
        nomVersion: [null]
      });

    } catch (e) {
      console.log('## error form: ' + e.message);
    }
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  resetSomeField() {
    this.editForm;
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## save version exchange rate');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const version: IVersionExchangeRate = this.editForm.get([this.column.version]).value;
    const nomVersion: IVersionExchangeRate = this.editForm.get([this.column.nomVersion]).value;
    console.log('## version:', version);

    /* si l'user veut sauvegarder la liste d'exchange rate
    pour une nouvelle version. */
    if (version === this.radioValueNouVersion) {
      console.log('## for new version: ');
      let data = {
        list: this.exchangeRateList,
        nomVersion: nomVersion
      };
      console.log('## data: ', data);
      this.exchangeRateService.createList(data).subscribe(res => {
          console.log('## success create version exchange rate');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          this.exchangeRateService.getExchangeRateHistorical(this.dateTyped).subscribe(res => {
            console.log('## Success get version exchange rate list after saving');
            const c: IExchangeRate[] = (res.body) ? res.body : [];
            console.log('## size: ' + c.length);
            this.exchangeRateService.setExchangeRateObs(c);
          });
        },
        err => {
          console.log('## error create version exchange rate: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
    } else {
      /* si l'user veut sauvegarder la liste d'exchange rate
      pour une version existante. */
      console.log('## for old version: ');
      let data = {
        versionExchangeRateId: version.id,
        list: this.exchangeRateList
      };
      console.log('## data: ', data);
      this.exchangeRateService.createListVersion(data).subscribe(res => {
          console.log('## success create version exchange rate');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.reset();
          this.successSave = true;
          /* récupère la liste des version d'exchange rate
        n'ayant pas encore été affectée */
          this.getVersionExchangeRateList();
          this.exchangeRateService.getExchangeRateHistorical(this.dateTyped).subscribe(res => {
            console.log('## Success get version exchange rate list after saving');
            const c: IExchangeRate[] = (res.body) ? res.body : [];
            console.log('## size: ' + c.length);
            this.exchangeRateService.setExchangeRateObs(c);
          });
        },
        err => {
          console.log('## error create version exchange rate: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
    }


  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, TIME_MIN_REQUEST);
  }

  private createFromForm(): IVersionExchangeRate {
    return {
      ...new VersionExchangeRate(),
      id: this.editForm.get([this.column.id]).value,
      version: this.editForm.get([this.column.version]).value,
      description: this.editForm.get([this.column.description]).value
    };
  }

  closeModal() {
    console.log('## close exchange rate add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  reset() {
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}