import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { createRequestOption } from '../../../util/request-util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ITransaction } from '../../../model/transaction.model';
import { map } from 'rxjs/operators';
import { ICUser } from '../../../model/c-user.model';
import { IReclamation } from '../../../model/reclamation.model';
import { BASE_URL, BASE_URL_DYNAMIQ } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TransactionService {
  public resourceUrl = BASE_URL + 'transactions';
  public resourceUrl2 = BASE_URL + 'transactions/account';
  public resourceUrl4 = BASE_URL + 'transaction/Roolback';
  public reclamation = BASE_URL + 'reclamation';
  public reclamationDemande = BASE_URL + 'reclamation/existe';
  public reclamationDemandeA = BASE_URL + 'reclamation/existeA';

  constructor(protected http: HttpClient) {
  }

  transactionBash(transactionExp: any): Observable<HttpResponse<ITransaction>> {
    return this.http.post<any>(BASE_URL_DYNAMIQ, transactionExp, { observe: 'response' });
  }

  withdrawalv2(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl + '/withdrawalv2', transactionExp, { observe: 'response' });
  }

  withdrawal(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl + '/withdrawal', transactionExp, { observe: 'response' });
  }

  deposit(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl + '/deposit', transactionExp, { observe: 'response' });
  }

  transferCode(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl + '/transferCode', transactionExp, { observe: 'response' });
  }

  transferCodeInter(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl + '/transferCodeInter', transactionExp, { observe: 'response' });
  }

  withdrawalCode(transactionExp: any): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(BASE_URL_DYNAMIQ, transactionExp, { observe: 'response' });
  }

  DemandeRoolback(reclamation: IReclamation): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.reclamation + '/newReclamation', reclamation, { observe: 'response' });
  }

  takeToInProgress(reclamation: IReclamation): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.reclamation + '/InProgressReclamation', reclamation, { observe: 'response' });
  }

  FinishReclamation(reclamation: IReclamation): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.reclamation + '/Done', reclamation, { observe: 'response' });
  }

  IfDemandeExiste(idTransacton: number): Observable<HttpResponse<ITransaction>> {
    return this.http.get(`${this.reclamationDemande}/${idTransacton}`, { observe: 'response' });
    //return this.http.post<IReclamation>(this.reclamation + '/existe', idTransacton, { observe: 'response' });
  }

  IfDemandeExisteA(idTransacton: number): Observable<HttpResponse<ITransaction>> {
    return this.http.get(`${this.reclamationDemandeA}/${idTransacton}`, { observe: 'response' });
    //return this.http.post<IReclamation>(this.reclamation + '/existe', idTransacton, { observe: 'response' });
  }

  roolbackSuccessToPendding(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl4 + '/SuccessToPendding', transactionExp, { observe: 'response' });
  }

  roolbackFailledToPendding(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl4 + '/FailledToPendding', transactionExp, { observe: 'response' });
  }

  roolbackPenddingToFailled(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl4 + '/PenddingToFailled', transactionExp, { observe: 'response' });
  }

  roolbackPenddingToSuccess(transactionExp: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http.post<ICUser>(this.resourceUrl4 + '/PenddingToSuccess', transactionExp, { observe: 'response' });
  }

  create(transaction: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http
      .post<ITransaction>(this.resourceUrl, transaction,{ observe: 'response' });
  }

  update(transaction: ITransaction): Observable<HttpResponse<ITransaction>> {
    return this.http
      .put<ITransaction>(this.resourceUrl, transaction, { observe: 'response' });
  }

  find(id: number): Observable<HttpResponse<ITransaction>> {
    return this.http
      .get<ITransaction>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findAllTransactionsByaccountNumber(accountNumber: string, req?: any)
    : Observable<HttpResponse<ITransaction[]>> {
    const options = createRequestOption(req);
    return this.http.get<ITransaction[]>(`${this.resourceUrl2}/${accountNumber}`, {
      params: options,
      observe: 'response'
    });
  }

  query(req?: any): Observable<HttpResponse<ITransaction[]>> {
    const options = createRequestOption(req);
    return this.http
      .get<ITransaction[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
}

