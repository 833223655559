import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { IOption } from '../../../../ui/interfaces/option';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IMoneyColumnName, MoneyColumnName } from '../money-column-name';
import { MatDialogRef } from '@angular/material/dialog';
import { MoniesService } from '../monies.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { ICountry } from '../../../../model/country.model';
import { IMoney, Money } from '../../../../model/money.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { MoniesComponent } from '../monies.component';

/**
 * Permet de gérer l'ajout des monies.
 */
@Component({
  selector: 'app-monies-liste',
  templateUrl: './monies-update.component.html',
  animations: [fadeIn]
})
export class MoniesUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;
  monies: IOption[] = [];
  private moneySelected: ICountry;
  private subs$ = new Subject<void>();
  editForm: UntypedFormGroup;
  column: IMoneyColumnName;
  theDialogRef: MatDialogRef<MoniesComponent>;

  constructor(
    private accountService: AccountService,
    private dialogRef: MatDialogRef<MoniesUpdateComponent>,
    private moniesService: MoniesService,
    private formService: FormService,
    private columnName: MoneyColumnName,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct money liste');
    const column = this.columnName.getColumnName();
    this.column = column;
    this.initForm(fb, column);
  }

  ngOnInit(): void {
    console.log('## init money liste');
    this.accountService.identity().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        this.moniesService.getMoneySelectObs()
          .pipe(takeUntil(this.subs$)).subscribe((res: IMoney) => {
          console.log('## get money selected to liste');
          try {
            console.log('## data: ' + JSON.stringify(res));
            this.moneySelected = res;
            this.editForm.patchValue({
              [this.column.id]: res.id,
              [this.column.label]: res.label,
              [this.column.value]: res.value,
              [this.column.unit]: res.unit,
              [this.column.labelCourt]: res.labelCourt,
              [this.column.codeMoney]: res.codeMoney
            })
          }catch (e) {
            console.log('## error get money selected: ' + e.message);
          }
        });
        super.setLoaded();
      })
  }

  ngOnDestroy() {
    console.log('## destroy money liste');
    this.formService.free(this.subs$);
    this.successSave = false;
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  initForm(fb: UntypedFormBuilder, column: IMoneyColumnName) {
    if (fb && column) {
      this.editForm = this.fb.group({
        [column.id]: [],
        [column.label]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(50), Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        [column.value]: [],
        [column.unit]: [],
        [column.labelCourt]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(10), Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ],
        [column.codeMoney]: [
          '',
          [Validators.required, Validators.minLength(2),
            Validators.maxLength(5), Validators.pattern('^[A-Za-z]+((\\s)?([A-Za-z])+)*$')]
        ]
      });
    }
  }

  /**
   * Enregistre un type de account ressource.
   */
  save() {
    console.log('## liste money');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: IMoney = this.createFromForm();
    console.log('## c: ' + JSON.stringify(c));
    this.moniesService.update(c).subscribe(res => {
        console.log('## success liste money');
        this.serverHasError = false;
        this.responseFromServer();
        this.editForm.markAsPristine();
        this.successSave = true;
        this.moniesService.getAllMoney();
      },
      err => {
        console.log('## error liste money');
        this.responseFromServer();
        this.serverHasError = true;
        this.successSave = false;
      });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300);
  }

  private createFromForm(): IMoney {
    return {
      ...new Money(),
      id: this.editForm.get(['id']).value,
      label: this.editForm.get(['label']).value,
      value: this.editForm.get(['value']).value,
      unit: this.editForm.get(['unit']).value,
      labelCourt: this.editForm.get(['labelCourt']).value,
      codeMoney: this.editForm.get(['codeMoney']).value
    };
  }

  closeModal() {
    console.log('## close country add');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

  isInvalid(): boolean {
    return this.formService.isInvalidOrPristineForm(this.editForm);
  }

  isRequired(column: string): boolean {
    return this.formService.isRequired(this.editForm, column);
  }

  isInvalidDirtyOrtouched(column: string): boolean {
    return this.formService.isInvalidDirtyOrTouched(this.editForm,
      column);
  }

  getMinLength(column: string): number {
    return this.formService.getMinLength(this.editForm, column);
  }

  getMaxLength(column: string): number {
    return this.formService.getMaxLength(this.editForm, column);
  }

  hasErrorEmail(column: string): boolean {
    return this.formService.hasErrorEmail(this.editForm, column);
  }

  hasErrorPattern(column: string): boolean {
    return this.formService.hasErrorPattern(this.editForm, column);
  }

  hasErrorMinLength(column: string): boolean {
    return this.formService.hasErrorMinLength(this.editForm, column);
  }

  hasErrorMaxLength(column: string): boolean {
    return this.formService.hasErrorMaxLength(this.editForm, column);
  }

}
