/**
 * Décrit les détails de frais, bénéfices..
 */
import { ICosts } from './costs.model';

export interface ICostsDetails {
  id?: number;
  label?: string;
  typeId?: number;
  type?: ICosts;
}

/**
 * Décrit les détails frais, bénéfices..
 */
export class CostsDetails implements ICostsDetails {
  constructor(
    public id?: number,
    public label?: string,
    public typeId?: number,
    public type?: ICosts
  ) {}
}
