import { Routes } from '@angular/router';
import { ConstantChampsFormulaires } from '../champs_formulaires/champs-formulaire.url';
import { GuardAdminService } from '../../../../services/guard/authorities/guard-admin.service';
import { ChampsFormulaireListComponent } from '../champs_formulaires/champs-formulaire-list.component';
import { ConstantSourceForm } from './source-form.url';
import { SourceFormComponent } from './source-form.component';

/**
 * Ensemble des routes pour les costs rule.
 */
export const SOURCE_FORM_ROUTE: Routes = [
  {
    path: ConstantSourceForm.url.FEES.FEES_,
    canActivate: [GuardAdminService],
    children: [
      {
        path: '',
        redirectTo: ConstantSourceForm.url.FEES.LIST,
        pathMatch: 'full'
      },
      {
        path: ConstantSourceForm.url.FEES.LIST,
        component: SourceFormComponent
      }
    ]
  }
];