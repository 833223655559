import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  ROLE_ADMIN = [
    {
      "name":"Trader(cuser)",
      "route":"c-user"
    },
    {
      "name":"countries",
      "route":"countries"
    },
    {
      "name":"fees & Benefit",
      "route":"fees"
    },
    {
      "name":"monies",
      "route":"monies"
    }
  ];
  ROLE_PARTENER = [
    {
      "name":"Trader(c-user)",
      "route":"c-user"
    },
    {
      "name":"countries",
      "route":"countries"
    },
    {
      "name":"fees & Benefit",
      "route":"fees"
    }
  ];
  ROLE_ANONYMOUS = [
    {
      "name":"Trader(cuser)",
      "route":"c-user"
    },
    {
      "name":"countries",
      "route":"countries"
    },
    {
      "name":"fees & Benefit",
      "route":"fees"
    }
  ];
  ROLE_POINT_CASH = [
    {
      "name":"Trader(cuser)",
      "route":"cuser"
    },
    {
      "name":"countries",
      "route":"countries"
    },
    {
      "name":"fees & Benefit",
      "route":"fees"
    }
  ];

  getRoleAdmin(){
    return this.ROLE_ADMIN;
  }
  getRolePartener(){
    return this.ROLE_PARTENER
  }
  getRoleAnonymous(){
    return this.ROLE_ANONYMOUS;
  }
  getPointCash(){
    return this.ROLE_POINT_CASH;
  }
  constructor() { }
}
