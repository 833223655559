import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeIn } from '../../../../animations/form-error';
import { BasePageComponent } from '../../../base-page';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomainColumnName, IDomainColumnName } from '../../domain/domain-column-name';
import { Domain } from '../../../../model/domain.model';
import { Subject } from 'rxjs';
import { BaseService } from '../../base/base.service';
import { AccountService } from '../../../../services/ServiceEntity/account.service';
import { FormService } from '../../../../shared/services/form.service';
import { TCModalService } from '../../../../ui/services/modal/modal.service';
import { DomainService } from '../../domain/domain.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { takeUntil } from 'rxjs/operators';
import { IServiceItem } from '../../../../model/service-item.model';
import { TypeInputFormModel } from '../../../../model/champs_formulaire/type-input-form.model';
import { SUFFIX_ICON_FORM, WIDTH_MEDIUM_POPUP_CLASS } from '../../../../shared/constant/css-style/css-class.constant';
import { ChampsFormulaire } from '../../../../model/champs_formulaire/champs-formulaire.model';
import { Costs, ICosts } from '../../../../model/costs.model';
import { CostsService } from '../costs.service';

/**
 * Permet de gérer la modification des coûts.
 */
@Component({
  selector: 'app-costs-update',
  templateUrl: './costs-update.component.html',
  animations: [fadeIn]
})
export class CostsUpdateComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  isSaving = false;
  successSave = false;
  serverHasError = false;
  theDialogRef: MatDialogRef<CostsUpdateComponent>;
  editForm: UntypedFormGroup;
  private countrySelected: ICosts;
  private subs$ = new Subject<void>();

  constructor(
    public baseService: BaseService,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<CostsUpdateComponent>,
    private formService: FormService,
    private fb: UntypedFormBuilder,
    private modal: TCModalService,
    private costsService: CostsService,
    store: Store<IAppState>, httpSv: HttpService) {
    super(store, httpSv);
    console.log('## construct costs update');
    this.theDialogRef = this.dialogRef;
    this.initForm(fb);
  }

  ngOnInit(): void {
    console.log('## init costs update');
    this.accountService.identity().pipe(takeUntil(this.subs$)).subscribe(res => {
      this.costsService.getObjetSelectObs()
        .pipe(takeUntil(this.subs$)).subscribe((res: Costs) => {
        console.log('## get costs selected to liste');
        try {
          console.log('## data: ', res);
          this.countrySelected = res;
          this.editForm.patchValue({
            'id': res.id,
            'label': res.label
          })
        }catch (e) {
          console.log('## error get country selected: ' + e.message);
        }
      });
    })
    super.setLoaded();
  }

  ngOnDestroy() {
    console.log('## destroy costs update');
    this.successSave = false;
    this.formService.free(this.subs$);
    this.costsService.setObjetSelectObs(undefined);
  }

  getWidthPopupClass() {
    return WIDTH_MEDIUM_POPUP_CLASS;
  }

  getSuffixIconForm(): string {
    return SUFFIX_ICON_FORM;
  }

  initForm(fb: UntypedFormBuilder) {
    if (fb) {
      this.editForm = fb.group({
        'id': [''],
        'label': ['', [Validators.required]]
      })
    }
  }

  /**
   * permet de modifier un coût existant.
   */
  save() {
    console.log('update costs');
    this.isSaving = true;
    this.successSave = false;
    this.serverHasError = false;
    const c: Costs = this.createFromForm();
    console.log('## c: ', c);
    this.costsService.update(c, this.costsService.typesUrl)
      .subscribe(res => {
          console.log('## success update costs');
          this.serverHasError = false;
          this.responseFromServer();
          this.editForm.markAsPristine();
          this.successSave = true;
          this.costsService.getAllObjet();
          this.closeModal();
        },
        err => {
          console.log('## error update costs: ', err);
          this.responseFromServer();
          this.serverHasError = true;
          this.successSave = false;
        });
  }

  responseFromServer() {
    setTimeout(() => {
      this.isSaving = false;
    }, 300)
  }

  private createFromForm(): ICosts {
    return {
      ...new Costs(),
      id: this.editForm.get('id').value,
      label: this.editForm.get('label').value,
    };
  }

  closeModal() {
    console.log('## close costs update');
    this.dialogRef.close();
    this.successSave = false;
    this.serverHasError = false;
    this.editForm.reset();
  }

}
