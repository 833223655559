import { Injectable } from '@angular/core';

export interface IUsersColumnName {
  id: string;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  activated: string;
  langKey: string;
  autorities: string;
}

@Injectable({ providedIn: 'root' })
export class UsersColumnName {
  getColumnName(): IUsersColumnName{
    return {
      id:  'id',
      login: 'login',
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      activated: 'activated',
      langKey: 'langKey',
      autorities: 'autorities'
    }
  }
}
