import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { UIModule } from '../ui/ui.module';

import { BaseLayoutComponent } from './base-layout';
import { HorizontalLayoutComponent } from './horizontal';
import { VerticalLayoutComponent } from './vertical';
import { PublicLayoutComponent } from './public';
import { ErrorLayoutComponent } from './error';

import { NavbarComponent } from './components/navbar';
import { MenuComponent } from './components/menu';
import { FooterComponent } from './components/footer';
import { LogoComponent } from './components/logo';
import { SearchComponent } from './components/search';
import { ActionsComponent } from './components/actions';
import { LoginFormComponent } from './components/login-form';
import { RegisterFormComponent } from './components/register-form';
import { NavbarSkeletonComponent } from './components/navbar-skeleton';
import { SettingsComponent } from './components/settings';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { SharedModule } from '../shared.module';
import { ShortenPipe } from '../pipe/shorten.pipe';
import { MaterialModule } from '../material.module';
import { DynamicFormsComponent } from './components/dynamic-forms/dynamic-forms.component';
import { QuestionFormComponent } from './components/dynamic-forms/question-form/question-form.component';
import { ChooseLanguageComponent } from './components/choose-language/choose-language.component';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MalihuScrollbarModule.forRoot(),
    UIModule,
    MaterialModule
  ],
  declarations: [
    ShortenPipe,
    BaseLayoutComponent,
    HorizontalLayoutComponent,
    VerticalLayoutComponent,

    NavbarComponent,
    MenuComponent,
    FooterComponent,
    LogoComponent,
    SearchComponent,
    ActionsComponent,
    PublicLayoutComponent,
    LoginFormComponent,
    RegisterFormComponent,
    NavbarSkeletonComponent,
    SettingsComponent,
    ErrorLayoutComponent,
    ChooseLanguageComponent,
    
    
  ],
  exports: [
    ShortenPipe,
    TranslateModule,
    LoginFormComponent,
    RegisterFormComponent,
    SettingsComponent
  ]
})
export class LayoutModule { }
