import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { map } from 'rxjs/operators';




import { BASE_URL, BASE_URL_DYNAMIQ, BASE_URL_MANAGEMENT } from 'src/environments/environment';
import { ICode } from 'src/app/model/code.model';
import { createRequestOption } from 'src/app/util/request-util';
import { DATE_FORMAT } from 'src/app/shared/constant/input.constants';

type EntityResponseType = HttpResponse<ICode>;
type EntityArrayResponseType = HttpResponse<ICode[]>;

@Injectable({ providedIn: 'root' })
export class CodeService {
  public resourceUrl = BASE_URL + 'codes';
  public resourceUrl2 = BASE_URL + 'codes/valueCode';
  public verifCodeResourceUrl = BASE_URL + 'codes/verif';
  public resourceUrl3 = BASE_URL + 'codes/receiver';

  constructor(protected http: HttpClient) {}

  create(code: ICode): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(code);
    return this.http
      .post<ICode>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(code: ICode): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(code);
    return this.http
      .put<ICode>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICode>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findByReceiverId(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICode>(`${this.resourceUrl3}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  verifCode(code: string): Observable<EntityResponseType> {
    return this.http
      .get<ICode>(`${this.verifCodeResourceUrl}/${code}`, { observe: 'response' })
      /* .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res))) */;
  }

  findByCode(code: string): Observable<EntityResponseType> {
    return this.http
      .get<ICode>(`${this.resourceUrl2}/${code}`, { observe: 'response' })
      /* .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res))) */;
  }

  verifCodeDynamic(code: any): Observable<EntityResponseType> {
    return this.http
      .post<ICode>(`${BASE_URL_DYNAMIQ}`, code, { observe: 'response' })
      /* .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res))) */;
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICode[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(code: ICode): ICode {
    const copy: ICode = Object.assign({}, code, {
      dateStarted: code.dateStarted != null && code.dateStarted.isValid() ? code.dateStarted.format(DATE_FORMAT) : null,
      dateEnd: code.dateEnd != null && code.dateEnd.isValid() ? code.dateEnd.format(DATE_FORMAT) : null
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateStarted = res.body.dateStarted != null ? moment(res.body.dateStarted) : null;
      res.body.dateEnd = res.body.dateEnd != null ? moment(res.body.dateEnd) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((code: ICode) => {
        code.dateStarted = code.dateStarted != null ? moment(code.dateStarted) : null;
        code.dateEnd = code.dateEnd != null ? moment(code.dateEnd) : null;
      });
    }
    return res;
  }
}
